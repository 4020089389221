import axios, {AxiosResponse} from 'axios'

import {ListQueryResponse} from '.'

const APP_URL = process.env.REACT_APP_APP_API_URL
const X_API_KEY = process.env.REACT_APP_APP_API_KEY
const GET_ACTIVITIES_URL = `${APP_URL}/log/fetch-activity-list?`

const getList = (query: any): Promise<ListQueryResponse> => {
  return axios
    .get(`${GET_ACTIVITIES_URL}${query}`, {
      headers: {'X-API-KEY': `${X_API_KEY}`},
    })
    .then((d: AxiosResponse<ListQueryResponse>) => d.data)
}

export {getList}
