import {FC, useEffect, useState} from 'react'
import ReactJson from 'react-json-view'

import {NotAuthRedirectForm} from 'app/components'
import {getAuth} from 'app/modules/auth'

import {GetDataByIDModel} from '../../helpers'
import {useAction} from '../../providers'

type Props = {
  log: GetDataByIDModel
  modalID: any
}

const ViewModalForm: FC<Props> = ({log, modalID}) => {
  const auth = getAuth()
  const {logActivity} = useAction()
  const [isAuth, setIsAuth] = useState(true)
  const [payload, setPayload] = useState({})

  useEffect(() => {
    auth === undefined && setIsAuth(false)
  }, [auth])

  useEffect(() => {
    if (log.payload) {
      if (logActivity === 'transaction') {
        setPayload({
          log_id: log.log_id,
          payload: JSON.parse(log.payload),
        })
      } else {
        setPayload(log)
      }
    }
  }, [log, logActivity])

  return (
    <>
      {!isAuth && <NotAuthRedirectForm />}

      {/* Form */}
      <form id={`${modalID}-form`} className='form' noValidate>
        <div
          className='d-flex flex-column scroll-y me-n2 pe-2'
          id={`body-${modalID}`}
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies={`#${modalID}`}
          data-kt-scroll-wrappers={`#body-${modalID}`}
          data-kt-scroll-offset='300px'
        >
          <div className='row mb-6'>
            {/* <pre>{JSON.stringify(log.payload, null, 2)}</pre> */}
            <ReactJson src={payload} />
          </div>
        </div>
      </form>
      {/* Form */}
    </>
  )
}

export {ViewModalForm}
