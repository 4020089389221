import {FC, useState} from 'react'
import toast from 'react-hot-toast'
import {useQuery} from 'react-query'
import {Drawer} from 'rsuite'

import {QUERIES, isNotEmpty} from '_metronic/helpers'
import {removeAuth} from 'app/modules/auth'
import {isMobileDevice} from '_metronic/assets/ts/_utils'

import {useAction} from '../../providers'
import {get} from '../../helpers'
import {ViewDrawerForm} from '..'

type Props = {
  menuName?: any
  modalID?: any
  appMenuID: any
}

const ViewDrawerFormWrapper: FC<Props> = ({menuName, modalID, appMenuID}) => {
  const mobile = isMobileDevice()
  const [open, setOpen] = useState(true)
  const {action, isLoading, itemIdForUpdate, setItemIdForUpdate, setIsLoading} = useAction()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {data: discounts, error} = useQuery(
    [`${QUERIES.POS_SETTINGS_MOBILE_LIBRARY_GET_DISCOUNT}-${itemIdForUpdate}`],
    () => {
      setIsLoading(true)
      return get(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      refetchOnWindowFocus: false,
      onError: (err: any) => {
        if (err.response.status !== 200) {
          setIsLoading(false)
          if (err.response.status === 401) {
            toast.error(err.response.statusText)
            removeAuth()
          } else {
            toast.error(err.response.statusText)
          }
        }
      },
      onSuccess: () => {
        setIsLoading(false)
      },
    }
  )

  return (
    <Drawer
      open={open}
      onClose={() => {
        setOpen(false)
        setItemIdForUpdate(undefined)
      }}
      placement='right'
      backdrop='static'
      keyboard={action === 'view' ? true : false}
      style={{width: mobile ? '100%' : '50%'}}
    >
      {discounts === undefined && (
        <ViewDrawerForm
          menuName={menuName}
          isLoading={isLoading}
          discounts={{
            discount_id: undefined,
            holding_id: '',
            holding_name: '',
            company_id: '',
            company_name: '',
            brand_id: '',
            brand_name: '',
            format: 'percentage',
            amount: 0.0,
            status: 't',
            is_lifetime: 't',
            description: '',
          }}
          appMenuID={appMenuID}
          modalID={modalID}
        />
      )}
      {!error && discounts && (
        <ViewDrawerForm
          menuName={menuName}
          isLoading={isLoading}
          discounts={discounts.data}
          appMenuID={appMenuID}
          modalID={modalID}
        />
      )}
    </Drawer>
  )
}

export {ViewDrawerFormWrapper}
