/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'

import {toAbsoluteUrl} from '_metronic/helpers'

type Props = {
  avatarUrl?: any
  username?: any
  email?: any
}

const RowAvatarFormatter: FC<Props> = ({avatarUrl, username, email}) => {
  return (
    <div className='d-flex align-items-center'>
      {/* begin:: Avatar */}
      <div className='symbol symbol-circle symbol-50px overflow-hidden me-5'>
        <a href='#'>
          {avatarUrl ? (
            <div className='symbol-label'>
              <img src={toAbsoluteUrl(avatarUrl)} alt={username} className='w-100' />
            </div>
          ) : (
            avatarUrl !== undefined && (
              <div className='symbol-label'>
                <img
                  src={toAbsoluteUrl(`/media/avatars/blank.png`)}
                  alt={username}
                  className='w-100'
                />
              </div>
            )
          )}
        </a>
      </div>
      <div className='d-flex flex-column'>
        <a href='#' className='text-gray-800 text-hover-primary mb-1'>
          <strong>{username}</strong>
        </a>
        <span className='text-primary'>{email}</span>
      </div>
    </div>
  )
}

export {RowAvatarFormatter}
