import {FC} from 'react'
import {CustomProvider} from 'rsuite'

import {useThemeMode} from '_metronic/partials'

import {
  ESBSalesActionProvider,
  ESBSalesListQueryRequestProvider,
  ESBSalesListQueryResponseProvider,
} from './modal-content/providers'
import DataList from './modal-content/DataList'
import {ShiftDate} from '../../helpers'

type Props = {
  shift_date: ShiftDate
}

const ViewModalForm: FC<Props> = ({shift_date}) => {
  const {mode} = useThemeMode()

  return (
    <ESBSalesActionProvider>
      <ESBSalesListQueryRequestProvider>
        <ESBSalesListQueryResponseProvider>
          <CustomProvider theme={`${mode === 'light' ? 'light' : 'dark'}`}>
            <DataList shift_date={shift_date} />
          </CustomProvider>
        </ESBSalesListQueryResponseProvider>
      </ESBSalesListQueryRequestProvider>
    </ESBSalesActionProvider>
  )
}

export {ViewModalForm}
