import {FC} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'

import {App} from 'app/App'
import {AuthPage, Logout, useAuth} from 'app/modules/auth'
import {ErrorsPage} from 'app/modules/errors/ErrorsPage'
import {PrivateRoutes} from './PrivateRoutes'
import {
  UserGroupModulesProvider,
  UserGroupModulesInit,
  ShortcutMenusProvider,
  ShortcutMenusInit,
} from 'app/context/providers'

const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const {currentUser} = useAuth()
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {currentUser ? (
            <>
              <Route
                path='/*'
                element={
                  <UserGroupModulesProvider>
                    <UserGroupModulesInit>
                      <ShortcutMenusProvider>
                        <ShortcutMenusInit>
                          <PrivateRoutes />
                        </ShortcutMenusInit>
                      </ShortcutMenusProvider>
                    </UserGroupModulesInit>
                  </UserGroupModulesProvider>
                }
              />
              <Route index element={<Navigate to='/homepage' />} />
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
