/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */

const SidebarFooter = () => {
  return (
    <div className='app-sidebar-footer flex-column-auto pt-2 pb-6 px-6' id='kt_app_sidebar_footer'>
      <span className='text-muted fw-semibold me-1'>2023 &copy;</span>
      <a href='#' className='text-gray-800 text-hover-primary'>
        OMI IT Development
      </a>
    </div>
  )
}

export {SidebarFooter}
