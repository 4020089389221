import {useState} from 'react'
import {useIntl} from 'react-intl'
import {Drawer, InputPicker} from 'rsuite'

import {isMobileDevice} from '_metronic/assets/ts/_utils'
import {FilterAction, FilterButton} from 'app/components'

import {useAction, useListQueryRequest, useListQueryResponse} from '../../providers'

const ListFilter = () => {
  const intl = useIntl()
  const mobile = isMobileDevice()
  const {showFilter, setShowFilter} = useAction()
  const {state, updateState} = useListQueryRequest()
  const {isLoading} = useListQueryResponse()
  const optionStatus = [
    {
      value: 't',
      label: intl.formatMessage({id: 'LABEL_ACTIVE'}),
    },
    {
      value: 'f',
      label: intl.formatMessage({id: 'LABEL_INACTIVE'}),
    },
  ]
  const [status, setStatus] = useState(state?.filter !== undefined ? state?.filter.status : '')

  const resetData = () => {
    setStatus('')
    updateState({filter: undefined})
  }

  const filterData = () => {
    updateState({
      filter: {status},
    })
    setShowFilter(false)
  }

  return (
    <>
      <FilterButton
        isLoading={isLoading}
        state={state}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
      />
      <Drawer
        open={showFilter}
        onClose={() => {
          setShowFilter(false)
        }}
        placement='right'
        style={{width: mobile ? '100%' : '25%'}}
      >
        <Drawer.Header closeButton>
          <Drawer.Actions>
            {/* Actions */}
            <FilterAction
              filterData={filterData}
              isLoading={isLoading}
              resetData={resetData}
              setStatus={setStatus}
            />
            {/* Actions */}
          </Drawer.Actions>
        </Drawer.Header>

        {/* Content */}
        <div className='card-body table-responsive m-5'>
          {/* Input group */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Status :</label>
            <InputPicker
              block
              data={optionStatus}
              placeholder={`-- ${intl.formatMessage({id: 'LABEL_SELECT'})} --`}
              defaultValue={
                optionStatus
                  ? (optionStatus as unknown as any[]).find((e: any) => e.value === status)
                  : ''
              }
              onChange={(e) => {
                setStatus(e)
              }}
              value={status}
            />
          </div>
          {/* Input group */}
        </div>
        {/* Content */}
      </Drawer>
    </>
  )
}

export {ListFilter}
