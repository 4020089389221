import {FC, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {Cell, Column, HeaderCell, Table} from 'rsuite-table'
import {Loader, Placeholder} from 'rsuite'

import {isMobileDevice} from '_metronic/assets/ts/_utils'
import {useThemeMode} from '_metronic/partials'
import {
  RowLevelCellFormatter,
  RowNameLevelCellFormatter,
  RowStatusCellFormatter,
} from 'app/components'

import {ListActionFormatter} from './ListActionFormatter'
import {
  useListQueryRequest,
  useListQueryResponseLoading,
  useListQueryResponseData,
} from '../../providers'

const ListTable: FC = () => {
  const intl = useIntl()
  const isMobile = isMobileDevice()
  const {state, updateState} = useListQueryRequest()
  const isLoading = useListQueryResponseLoading()
  const users = useListQueryResponseData()
  const data = useMemo(() => users, [users])
  const [usePlaceholder] = useState(true)
  const [sortColumn, setSortColumn] = useState(state?.sort)
  const [sortType, setSortType] = useState(state?.order)
  const {mode} = useThemeMode()

  const sortData = () => {
    if (sortColumn && sortType) {
      return data.sort((a: any, b: any) => {
        let x = a[sortColumn]
        let y = b[sortColumn]

        if (sortType === 'asc') {
          return x - y
        } else {
          return y - x
        }
      })
    }
    return data
  }

  const handleSortColumn = (sortColumn: any, sortType: any) => {
    setSortColumn(sortColumn)
    setSortType(sortType)
    updateState({sort: sortColumn, order: sortType})
  }

  const renderLoading = () => {
    if (usePlaceholder) {
      return (
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            background: mode === 'light' ? '#fff' : '#252530',
            padding: 20,
            zIndex: 1,
          }}
        >
          <Placeholder.Grid rows={5} columns={7} active />
        </div>
      )
    }

    return <Loader center backdrop content={intl.formatMessage({id: 'BUTTON_LABEL_PLEASE_WAIT'})} />
  }

  return (
    <div>
      <Table
        wordWrap
        bordered
        cellBordered
        loading={isLoading}
        height={450}
        headerHeight={70}
        autoHeight={isMobile ? true : false}
        affixHeader={50}
        affixHorizontalScrollbar
        data={sortData()}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
        renderLoading={isLoading ? renderLoading : undefined}
        locale={{emptyMessage: intl.formatMessage({id: 'LABEL_EMPTY_DATA'})}}
      >
        {/* Name */}
        <Column fixed={isMobile ? false : true} sortable verticalAlign='middle' width={250}>
          <HeaderCell align='center'>
            <span
              className={`fw-bold fs-6 text-decoration-underline ${
                sortColumn === 'name_eng' ? 'text-primary' : 'text-dark'
              }`}
            >
              {intl.formatMessage({id: 'LABEL_NAME'})}
              {' (ENG)'}
            </span>
          </HeaderCell>
          <Cell dataKey='name_eng'>
            {(rowData: any) => (
              <RowNameLevelCellFormatter row={rowData.name_eng} level={rowData.level} />
            )}
          </Cell>
        </Column>
        <Column fixed={isMobile ? false : true} sortable verticalAlign='middle' width={250}>
          <HeaderCell align='center'>
            <span
              className={`fw-bold fs-6 text-decoration-underline ${
                sortColumn === 'name_idn' ? 'text-primary' : 'text-dark'
              }`}
            >
              {intl.formatMessage({id: 'LABEL_NAME'})}
              {' (IDN)'}
            </span>
          </HeaderCell>
          <Cell dataKey='name_idn'>
            {(rowData: any) => (
              <RowNameLevelCellFormatter row={rowData.name_idn} level={rowData.level} />
            )}
          </Cell>
        </Column>

        {/* Level */}
        <Column sortable verticalAlign='middle' width={125}>
          <HeaderCell align='center'>
            <span
              className={`fw-bold fs-6 text-decoration-underline ${
                sortColumn === 'level' ? 'text-primary' : 'text-dark'
              }`}
            >
              Level
            </span>
          </HeaderCell>
          <Cell dataKey='level' align='center'>
            {(rowData: any) => <RowLevelCellFormatter row={rowData.level} />}
          </Cell>
        </Column>

        {/* Directory */}
        <Column sortable verticalAlign='middle' width={450}>
          <HeaderCell align='center'>
            <span
              className={`fw-bold fs-6 text-decoration-underline ${
                sortColumn === 'path' ? 'text-primary' : 'text-dark'
              }`}
            >
              {intl.formatMessage({id: 'LABEL_DIRECTORY'})}
            </span>
          </HeaderCell>
          <Cell dataKey='path'>
            {(rowData: any) => (
              <RowNameLevelCellFormatter row={rowData.path} level={rowData.level} />
            )}
          </Cell>
        </Column>

        {/* Order */}
        <Column sortable verticalAlign='middle' width={80}>
          <HeaderCell align='center'>
            <span
              className={`fw-bold fs-6 text-decoration-underline ${
                sortColumn === 'for_order' ? 'text-primary' : 'text-dark'
              }`}
            >
              {intl.formatMessage({id: 'LABEL_ORDER'})}
            </span>
          </HeaderCell>
          <Cell dataKey='for_order' align='center'>
            {(rowData: any) => (
              <RowNameLevelCellFormatter row={rowData.for_order} level={rowData.level} />
            )}
          </Cell>
        </Column>

        {/* Status */}
        <Column sortable verticalAlign='middle' width={100}>
          <HeaderCell align='center'>
            <span
              className={`fw-bold fs-6 text-decoration-underline ${
                sortColumn === 'status' ? 'text-primary' : 'text-dark'
              }`}
            >
              Status
            </span>
          </HeaderCell>
          <Cell dataKey='status' align='center'>
            {(rowData: any) => <RowStatusCellFormatter row={rowData.status} />}
          </Cell>
        </Column>

        {/* Action */}
        <Column fixed='right' align='right' verticalAlign='middle' width={75}>
          <HeaderCell>
            <span className={`fw-bold fs-6 text-dark text-decoration-underline`}>
              {intl.formatMessage({id: 'LABEL_ACTION'})}
            </span>
          </HeaderCell>
          <Cell dataKey='action' style={{flexWrap: 'nowrap'}}>
            {(rowData: any) => (
              <ListActionFormatter
                menu_id={rowData.menu_id}
                status={rowData.status}
                level={rowData.level}
                directory={rowData.path}
              />
            )}
          </Cell>
        </Column>
      </Table>
    </div>
  )
}

export {ListTable}
