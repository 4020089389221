import {
  Dispatch,
  FC,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import toast from 'react-hot-toast'
import {useIntl} from 'react-intl'

import {LayoutSplashScreen} from '_metronic/layout/core'
import {useAuth} from 'app/modules/auth'
import {UserGroupModulesResponse} from 'app/context/models'
import {WithChildren} from '_metronic/helpers'
import {getUserGroupModulesByRoleID} from 'app/context/requests'

import * as contextHelper from 'app/context/state/UserGroupModulesStateContext'

import * as activityListState from 'app/modules/account-settings/content/activity/providers/ListQueryStateContext'
import {initialListQueryState as activityListQueryState} from 'app/modules/account-settings/content/activity/helpers/ActivityQueryModels'

type UserGroupModulesContextProps = {
  userGroupModules: UserGroupModulesResponse | undefined
  saveUserGroupModules: (userGroupModules: UserGroupModulesResponse | undefined) => void
  currentUserGroupModules: UserGroupModulesResponse | undefined
  setCurrentUserGroupModules: Dispatch<SetStateAction<UserGroupModulesResponse | undefined>>
  currentModuleLabel: string
  setCurrentModuleLabel: Dispatch<SetStateAction<string>>
  currentModulePath: string
  setCurrentModulePath: Dispatch<SetStateAction<string>>
  currentModuleID: string
  setCurrentModuleID: Dispatch<SetStateAction<string>>
  currentSiteLabel: string
  setCurrentSiteLabel: Dispatch<SetStateAction<string>>
  currentSitePath: string
  setCurrentSitePath: Dispatch<SetStateAction<string>>
  currentSiteID: string
  setCurrentSiteID: Dispatch<SetStateAction<string>>
  currentParentMenuLabel: string
  setCurrentParentMenuLabel: Dispatch<SetStateAction<string>>
  currentParentMenuPath: string
  setCurrentParentMenuPath: Dispatch<SetStateAction<string>>
  currentParentMenuID: string
  setCurrentParentMenuID: Dispatch<SetStateAction<string>>
  currentMenuLabel: string
  setCurrentMenuLabel: Dispatch<SetStateAction<string>>
  currentMenuPath: string
  setCurrentMenuPath: Dispatch<SetStateAction<string>>
  currentMenuID: string
  setCurrentMenuID: Dispatch<SetStateAction<string>>
}

const initUserGroupModulesContextPropsState = {
  userGroupModules: contextHelper.getUserGroupModules(),
  saveUserGroupModules: () => {},
  currentUserGroupModules: undefined,
  setCurrentUserGroupModules: () => {},
  currentModuleLabel: '',
  setCurrentModuleLabel: () => {},
  currentModulePath: '',
  setCurrentModulePath: () => {},
  currentModuleID: '',
  setCurrentModuleID: () => {},
  currentSiteLabel: '',
  setCurrentSiteLabel: () => {},
  currentSitePath: '',
  setCurrentSitePath: () => {},
  currentSiteID: '',
  setCurrentSiteID: () => {},
  currentParentMenuLabel: '',
  setCurrentParentMenuLabel: () => {},
  currentParentMenuPath: '',
  setCurrentParentMenuPath: () => {},
  currentParentMenuID: '',
  setCurrentParentMenuID: () => {},
  currentMenuLabel: '',
  setCurrentMenuLabel: () => {},
  currentMenuPath: '',
  setCurrentMenuPath: () => {},
  currentMenuID: '',
  setCurrentMenuID: () => {},
}

const UserGroupModulesContext = createContext<UserGroupModulesContextProps>(
  initUserGroupModulesContextPropsState
)

const useUserGroupModules = () => {
  return useContext(UserGroupModulesContext)
}

const UserGroupModulesProvider: FC<WithChildren> = ({children}) => {
  const [userGroupModules, setUserGroupModules] = useState<UserGroupModulesResponse | undefined>(
    contextHelper.getUserGroupModules()
  )
  const saveUserGroupModules = (userGroupModules: UserGroupModulesResponse | undefined) => {
    setUserGroupModules(userGroupModules)
    if (userGroupModules) {
      contextHelper.setUserGroupModules(userGroupModules)
    }
  }
  const [currentUserGroupModules, setCurrentUserGroupModules] = useState<
    UserGroupModulesResponse | undefined
  >()

  const [currentModuleLabel, setCurrentModuleLabel] = useState<string>('')
  const [currentModulePath, setCurrentModulePath] = useState<string>('')
  const [currentModuleID, setCurrentModuleID] = useState<string>('')
  const [currentSiteLabel, setCurrentSiteLabel] = useState<string>('')
  const [currentSitePath, setCurrentSitePath] = useState<string>('')
  const [currentSiteID, setCurrentSiteID] = useState<string>('')
  const [currentParentMenuLabel, setCurrentParentMenuLabel] = useState<string>('')
  const [currentParentMenuPath, setCurrentParentMenuPath] = useState<string>('')
  const [currentParentMenuID, setCurrentParentMenuID] = useState<string>('')
  const [currentMenuLabel, setCurrentMenuLabel] = useState<string>('')
  const [currentMenuPath, setCurrentMenuPath] = useState<string>('')
  const [currentMenuID, setCurrentMenuID] = useState<string>('')

  return (
    <UserGroupModulesContext.Provider
      value={{
        userGroupModules,
        saveUserGroupModules,
        currentUserGroupModules,
        setCurrentUserGroupModules,
        currentModuleLabel,
        setCurrentModuleLabel,
        currentModulePath,
        setCurrentModulePath,
        currentModuleID,
        setCurrentModuleID,
        currentSiteLabel,
        setCurrentSiteLabel,
        currentSitePath,
        setCurrentSitePath,
        currentSiteID,
        setCurrentSiteID,
        currentParentMenuLabel,
        setCurrentParentMenuLabel,
        currentParentMenuPath,
        setCurrentParentMenuPath,
        currentParentMenuID,
        setCurrentParentMenuID,
        currentMenuLabel,
        setCurrentMenuLabel,
        currentMenuPath,
        setCurrentMenuPath,
        currentMenuID,
        setCurrentMenuID,
      }}
    >
      {children}
    </UserGroupModulesContext.Provider>
  )
}

const UserGroupModulesInit: FC<WithChildren> = ({children}) => {
  const intl = useIntl()
  const {auth, currentUser} = useAuth()
  const {saveUserGroupModules, setCurrentUserGroupModules} = useUserGroupModules()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)

  useEffect(() => {
    const requestUserGroupModules = async () => {
      try {
        if (!didRequest.current) {
          try {
            const res = await getUserGroupModulesByRoleID(currentUser?.role.role_id)

            if (res.data.meta.code === 200) {
              // set user group modules
              setCurrentUserGroupModules(res.data.data)
              saveUserGroupModules(res.data.data)

              // set account settings activity state
              if (activityListState.getListState() === undefined) {
                activityListState.setListState(activityListQueryState)
              }
            }
          } catch (error) {
            console.error(error)
            toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth.auth_token) {
      requestUserGroupModules()
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {UserGroupModulesProvider, UserGroupModulesInit, useUserGroupModules}
