/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {useFormik} from 'formik'
import clsx from 'clsx'
import * as CurrencyFormat from 'react-currency-format'
import {Divider, Drawer} from 'rsuite'
import toast from 'react-hot-toast'
import {exportComponentAsJPEG} from 'react-component-export-image'

import {
  NotAuthRedirectForm,
  SpinnerIndicator,
  datetimeFormatter,
  getFormAction,
} from 'app/components'
import {getAuth, removeAuth, useAuth} from 'app/modules/auth'

import {
  DetailModel,
  DiscountBill,
  DiscountItem,
  Item,
  PaymentMethod,
  initialData,
  requestApproveVoidData,
  requestCancelVoidData,
} from '../../helpers'
import {useAction, useListQueryResponse} from '../../providers'

type Props = {
  isLoading: boolean
  reportVoid: DetailModel
  appMenuID: any
  modalID: any
  menuName: any
}

const ViewDrawerForm: FC<Props> = ({menuName, reportVoid, isLoading, appMenuID, modalID}) => {
  const auth = getAuth()
  const intl = useIntl()
  const [tab, setTab] = useState('void')
  const {currentUser} = useAuth()
  const {setItemIdForUpdate, action} = useAction()
  const {refetch} = useListQueryResponse()
  const [isAuth, setIsAuth] = useState(true)
  const [loading, setLoading] = useState(false)
  const [completed, setCompleted] = useState(true)
  const [reviewAction, setReviewAction] = useState('')
  const pdfRef = useRef(null)

  const [models] = useState<DetailModel>({
    ...reportVoid,
    void_id: reportVoid.void_id || initialData.void_id,
    menu_id: appMenuID,
    creator_id: currentUser?.user_id,
    creator_by: currentUser?.username,
  })

  const formik = useFormik({
    initialValues: models,
    validationSchema: null,
    onSubmit: async (values, {setSubmitting, setStatus}) => {
      reviewAction === 'cancel'
        ? requestCancel(values, setStatus, setSubmitting)
        : requestApprove(values, setStatus, setSubmitting)
    },
    onReset: (values) => {
      values = models
      return values
    },
  })

  const requestCancel = async (values: any, setStatus: any, setSubmitting: any) => {
    setLoading(true)
    setStatus(null)
    setSubmitting(true)

    // request delete to API
    try {
      const res = await requestCancelVoidData(
        values.void_id,
        values.menu_id,
        values.creator_id,
        values.creator_by
      )

      const data = res?.data

      // throw message error
      if (res?.meta.code === 200) {
        setLoading(false)
        toast.success(intl.formatMessage({id: 'RESPONSE_REPORT_CANCEL_VOID_SUCCESS'}))
        setStatus(intl.formatMessage({id: 'RESPONSE_REPORT_CANCEL_VOID_SUCCESS'}))
        setCompleted(true)
        setTimeout(function () {
          setItemIdForUpdate(undefined)
        }, 1e3)
        refetch()
      }

      return data
    } catch (error: any) {
      setLoading(false)
      console.error(error)
      if (error.response.status === 401) {
        setStatus(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
        removeAuth()
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
      }
      setSubmitting(false)
      setTimeout(function () {
        setStatus(null)
      }, 9e3)
      toast.error(<span>{error.response.data}</span>, {icon: '⛔️'})

      return false
    } finally {
      setTimeout(() => {
        setSubmitting(false)
        setReviewAction('')
      }, 5e2)
    }
  }
  const requestApprove = async (values: any, setStatus: any, setSubmitting: any) => {
    setLoading(true)
    setStatus(null)
    setSubmitting(true)

    // request delete to API
    try {
      const res = await requestApproveVoidData(
        values.void_id,
        values.menu_id,
        values.creator_id,
        values.creator_by
      )

      const data = res?.data

      // throw message error
      if (res?.meta.code === 200) {
        setLoading(false)
        toast.success(intl.formatMessage({id: 'RESPONSE_REPORT_APPROVED_VOID_SUCCESS'}))
        setStatus(intl.formatMessage({id: 'RESPONSE_REPORT_APPROVED_VOID_SUCCESS'}))
        setCompleted(true)
        setTimeout(function () {
          setItemIdForUpdate(undefined)
        }, 1e3)
        refetch()
      }

      return data
    } catch (error: any) {
      setLoading(false)
      console.error(error)
      if (error.response.status === 401) {
        setStatus(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
        removeAuth()
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
      }
      setSubmitting(false)
      setTimeout(function () {
        setStatus(null)
      }, 9e3)
      toast.error(<span>{error.response.data}</span>, {icon: '⛔️'})

      return false
    } finally {
      setTimeout(() => {
        setSubmitting(false)
        setReviewAction('')
      }, 5e2)
    }
  }

  useEffect(() => {
    auth === undefined && setIsAuth(false)
  }, [auth])

  // control submit button
  useEffect(() => {
    setTimeout(function () {
      setCompleted(false)
    }, 5e3)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {!isAuth && <NotAuthRedirectForm />}

      <Drawer.Header>
        <Drawer.Title className='fw-bolder'>
          {isLoading ? (
            <SpinnerIndicator label={intl.formatMessage({id: 'BUTTON_LABEL_PLEASE_WAIT'})} />
          ) : (
            <>
              {getFormAction(
                action,
                intl.formatMessage({id: 'BUTTON_LABEL_VIEW'}),
                intl.formatMessage({id: 'BUTTON_LABEL_EDIT'}),
                intl.formatMessage({id: 'BUTTON_LABEL_ADD'}),
                intl.formatMessage({id: 'BUTTON_LABEL_DELETE'}),
                '',
                '',
                '',
                intl.formatMessage({id: 'BUTTON_LABEL_FU'})
              )}{' '}
              {menuName}
            </>
          )}
        </Drawer.Title>
        <Drawer.Actions>
          {action !== 'view' && reportVoid.status === 'waiting approval' && !completed && (
            <>
              <button
                type='submit'
                className='btn btn-light me-10'
                data-kt-users-modal-action='cancel'
                onClick={() => {
                  setReviewAction('cancel')
                  formik.handleSubmit()
                }}
                disabled={loading}
              >
                {!loading && (
                  <span className='indicator-label'>
                    {intl.formatMessage({id: 'BUTTON_LABEL_REJECT'})}
                  </span>
                )}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    {intl.formatMessage({id: 'BUTTON_LABEL_SUBMITTING'})}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>

              <button
                type='submit'
                className='btn btn-primary'
                data-kt-users-modal-action='submit'
                onClick={() => {
                  setReviewAction('approve')
                  formik.handleSubmit()
                }}
                disabled={isLoading || loading || formik.isSubmitting || !formik.isValid}
              >
                {!loading && (
                  <span className='indicator-label'>
                    {intl.formatMessage({id: 'BUTTON_LABEL_APPROVE'})}
                  </span>
                )}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    {intl.formatMessage({id: 'BUTTON_LABEL_SUBMITTING'})}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </>
          )}
        </Drawer.Actions>
      </Drawer.Header>

      <Drawer.Body style={{padding: 0}}>
        {/* Response Message */}
        {formik.status &&
          formik.status !== intl.formatMessage({id: 'RESPONSE_REPORT_CANCEL_VOID_SUCCESS'}) &&
          formik.status !== intl.formatMessage({id: 'RESPONSE_REPORT_APPROVED_VOID_SUCCESS'}) && (
            <div className={`m-lg-5 p-2 alert alert-danger`}>
              <div className='alert-text text-center font-weight-bold fw-bolder'>
                {formik.status}
              </div>
            </div>
          )}
        {/* Response Message */}

        {/* Form */}
        <div className={`modal-body m-5 mt-0`}>
          <form id={`${modalID}-form`} className='form' onSubmit={formik.handleSubmit} noValidate>
            {/* Tab Header */}
            <div className='card-toolbar'>
              <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-2 mt-2' role='tablist'>
                <li className={`nav-item ${tab === 'void' ? 'fw-bolder' : ''}`}>
                  <a
                    className={clsx(`nav-link cursor-pointer`, {
                      active: tab === 'void',
                    })}
                    onClick={() => setTab('void')}
                    role='tab'
                  >
                    {menuName}
                  </a>
                </li>
                <li className={`nav-item ${tab === 'data' ? 'fw-bolder' : ''}`}>
                  <a
                    className={clsx(`nav-link cursor-pointer`, {
                      active: tab === 'data',
                    })}
                    onClick={() => setTab('data')}
                    role='tab'
                  >
                    Data
                  </a>
                </li>
              </ul>
            </div>
            {/* Tab Header */}

            {/* Tab Body */}
            <div className='card-body p-0 pt-2'>
              <div className='tab-content'>
                {/* Void Request */}
                <div
                  className={clsx('tab-pane', {
                    active: tab === 'void',
                  })}
                >
                  <div className='row'>
                    {/* Left Form */}
                    <div className='col-lg-6'>
                      {/* Brand */}
                      <div className='fv-row mb-5'>
                        <label className={`form-label fs-6 fw-bolder text-dark`}>
                          {intl.formatMessage({id: 'LABEL_BRAND'})}
                        </label>
                        <input
                          type='text'
                          className={'form-control form-control-solid'}
                          readOnly={true}
                          value={reportVoid.brand_name}
                        />
                      </div>
                      {/* Brand */}

                      {/* Outlet */}
                      <div className='fv-row mb-5'>
                        <label className={`form-label fs-6 fw-bolder text-dark`}>
                          {intl.formatMessage({id: 'LABEL_OUTLET'})}
                        </label>
                        <input
                          type='text'
                          className={'form-control form-control-solid'}
                          readOnly={true}
                          value={reportVoid.outlet_name}
                        />
                      </div>
                      {/* Outlet */}

                      {/* Void Date */}
                      <div className='fv-row mb-5'>
                        <label className={`form-label fs-6 fw-bolder text-dark`}>
                          {intl.formatMessage({id: 'LABEL_REQUEST_DATE'})}
                        </label>
                        <input
                          type='text'
                          className={'form-control form-control-solid'}
                          readOnly={true}
                          value={datetimeFormatter(reportVoid.created_at, '1', intl.locale)}
                        />
                      </div>
                      {/* Void Date */}
                    </div>
                    {/* Left Form */}

                    {/* Right Form */}
                    <div className='col-lg-6'>
                      {/* Status */}
                      <div className='fv-row mb-5'>
                        <label className={`form-label fs-6 fw-bolder text-dark`}>Status</label>
                        <input
                          type='text'
                          className={'form-control form-control-solid'}
                          readOnly={true}
                          value={
                            reportVoid.status === 'waiting approval'
                              ? intl.formatMessage({id: 'LABEL_WAITING_APPROVAL'})
                              : intl.formatMessage({id: 'LABEL_APPROVED'})
                          }
                        />
                      </div>
                      {/* Status */}

                      {/* Approved At */}
                      <div className='fv-row mb-5'>
                        <label className={`form-label fs-6 fw-bolder text-dark`}>
                          {intl.formatMessage({id: 'LABEL_APPROVED_AT'})}
                        </label>
                        <input
                          type='text'
                          className={'form-control form-control-solid'}
                          readOnly={true}
                          value={
                            reportVoid.approved_at === '0001-01-01T00:00:00Z'
                              ? '-'
                              : datetimeFormatter(reportVoid.approved_at, '1', intl.locale)
                          }
                        />
                      </div>
                      {/* Approved At */}

                      {/* Approved By */}
                      <div className='fv-row mb-5'>
                        <label className={`form-label fs-6 fw-bolder text-dark`}>
                          {intl.formatMessage({id: 'LABEL_APPROVED_BY'})}
                        </label>
                        <input
                          type='text'
                          className={'form-control form-control-solid'}
                          readOnly={true}
                          value={reportVoid.approved_by === '' ? '-' : reportVoid.approved_by}
                        />
                      </div>
                      {/* Approved By */}
                    </div>
                    {/* Right Form */}
                  </div>
                </div>
                {/* Void Request  */}

                {/* Data */}
                <div
                  className={clsx('tab-pane', {
                    active: tab === 'data',
                  })}
                >
                  <div className='row table-responsive' ref={pdfRef}>
                    <div className='d-flex flex-center flex-column p-1'>
                      <div className='symbol symbol-125px symbol-circle'>
                        <img src={reportVoid.receipt?.outlet.logo} alt='Logo' />
                      </div>
                      <h1>{reportVoid.receipt?.outlet.name}</h1>
                      <span>{reportVoid.receipt?.outlet.address}</span>
                      <span>{reportVoid.receipt?.outlet.phone_number}</span>
                    </div>

                    <Divider className='m-5' />

                    <div className='d-flex justify-content-between align-items-start flex-wrap mb-1'>
                      <span>
                        {datetimeFormatter(reportVoid.transaction?.created_at, '3', intl.locale)}
                      </span>
                      <span>
                        {datetimeFormatter(reportVoid.transaction?.created_at, '4', intl.locale)}
                      </span>
                    </div>

                    <div className='d-flex justify-content-between align-items-start flex-wrap mb-1'>
                      <span>{intl.formatMessage({id: 'LABEL_RECEIPT_NUMBER'})}</span>
                      <span>{reportVoid.transaction?.receipt_number}</span>
                    </div>

                    <div className='d-flex justify-content-between align-items-start flex-wrap mb-1'>
                      <span>{intl.formatMessage({id: 'LABEL_CREATED_BY'})}</span>
                      <span>{reportVoid.created_by}</span>
                    </div>

                    <Divider className='fw-bolder fs-4 text-muted'>
                      {intl.formatMessage({id: 'LABEL_REASON'})}
                    </Divider>

                    <div className='d-flex flex-center flex-column'>
                      <span className='badge badge-light-primary badge-lg'>{reportVoid.notes}</span>
                    </div>

                    <Divider className='fw-bolder fs-4 text-muted'>
                      {intl.formatMessage({id: 'LABEL_TRANSACTION_DETAIL'})}
                    </Divider>

                    <div className='d-flex flex-center flex-column mb-5'>
                      <span>{reportVoid.transaction?.sales_type}</span>
                    </div>

                    {reportVoid.transaction?.items.map((dt: Item, i: number) => (
                      <div className='row mb-5' key={`head-${i}`}>
                        <div className='col-sm-7 mb-1'>
                          <span>{dt.item_name} -</span>
                          <span>&nbsp;{dt.variant_name}</span>
                        </div>
                        <div className='col-sm-1 mb-1'>
                          <CurrencyFormat
                            value={dt.quantity}
                            displayType={'text'}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            prefix={'x'}
                          />
                        </div>
                        <div className='col-sm-2 mb-1 d-flex justify-content-end'>
                          <CurrencyFormat
                            value={dt.base_price_amount}
                            displayType={'text'}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            prefix={'Rp. '}
                          />
                        </div>
                        <div className='col-sm-2 mb-1 d-flex justify-content-end'>
                          <CurrencyFormat
                            value={dt.final_price_amount}
                            displayType={'text'}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            prefix={'Rp. '}
                          />
                        </div>

                        {dt.discount_items.length > 0 &&
                          dt.discount_items.map((dt2: DiscountItem, j: number) => (
                            <div className='row' key={`discount-item-${i}-${j}`}>
                              <div className='col-sm-1 mb-1' />
                              <div className='col-sm-5 mb-1'>
                                <span className='text-muted'>{dt2.name}</span>
                              </div>
                              <div className='col-sm-6 mb-1'>
                                <CurrencyFormat
                                  value={dt2.discount_price_amount}
                                  className='text-muted'
                                  displayType={'text'}
                                  thousandSeparator={'.'}
                                  decimalSeparator={','}
                                  prefix={'(Rp. '}
                                  suffix={')'}
                                />
                              </div>
                            </div>
                          ))}
                      </div>
                    ))}

                    {reportVoid.transaction?.discount_bill.map((dt: DiscountBill, i: number) => (
                      <div
                        className='row mb-5 d-flex justify-content-between'
                        key={`discount-bill-${i}`}
                      >
                        <div className='col-sm-10 mb-1'>
                          <span className='text-muted'>{dt.name}</span>
                        </div>
                        <div className='col-sm-2 mb-1 d-flex justify-content-end'>
                          <CurrencyFormat
                            className='text-muted'
                            value={dt.discount_price_amount}
                            displayType={'text'}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            prefix={'(Rp. '}
                            suffix={')'}
                          />
                        </div>
                      </div>
                    ))}

                    <Divider className='mt-1 mb-5' />

                    <div className='row d-flex justify-content-between' key={`sub-total`}>
                      <div className='col-sm-10 mb-1'>
                        <span>Sub Total</span>
                      </div>
                      <div className='col-sm-2 mb-1 d-flex justify-content-end'>
                        <CurrencyFormat
                          value={reportVoid.transaction?.sub_total_amount}
                          displayType={'text'}
                          thousandSeparator={'.'}
                          decimalSeparator={','}
                          prefix={'Rp. '}
                        />
                      </div>
                    </div>

                    <div className='row d-flex justify-content-between' key={`total-discount`}>
                      <div className='col-sm-10 mb-1'>
                        <span>{intl.formatMessage({id: 'LABEL_TOTAL_DISCOUNT_AMOUNT'})}</span>
                      </div>
                      <div className='col-sm-2 mb-1 d-flex justify-content-end'>
                        <CurrencyFormat
                          className='text-muted'
                          value={reportVoid.transaction?.total_discount_amount}
                          displayType={'text'}
                          thousandSeparator={'.'}
                          decimalSeparator={','}
                          prefix={'(Rp. '}
                          suffix={')'}
                        />
                      </div>
                    </div>

                    <div className='row d-flex justify-content-between' key={`ppn`}>
                      <div className='col-sm-10 mb-1'>
                        <span>PPN {reportVoid.transaction?.ppn_percentage}%</span>
                      </div>
                      <div className='col-sm-2 mb-1 d-flex justify-content-end'>
                        <CurrencyFormat
                          value={reportVoid.transaction?.ppn_amount}
                          displayType={'text'}
                          thousandSeparator={'.'}
                          decimalSeparator={','}
                          prefix={'Rp. '}
                        />
                      </div>
                    </div>

                    <div className='row d-flex justify-content-between' key={`rounding`}>
                      <div className='col-sm-10 mb-1'>
                        <span>{intl.formatMessage({id: 'LABEL_ROUNDING_AMOUNT'})}</span>
                      </div>
                      <div className='col-sm-2 mb-1 d-flex justify-content-end'>
                        <CurrencyFormat
                          value={reportVoid.transaction?.rounding_amount}
                          displayType={'text'}
                          thousandSeparator={'.'}
                          decimalSeparator={','}
                          prefix={'Rp. '}
                        />
                      </div>
                    </div>

                    <Divider className='mt-5 mb-5' />

                    <div className='row d-flex justify-content-between' key={`total`}>
                      <div className='col-sm-10 mb-1'>
                        <span className='fw-bolder'>Total</span>
                      </div>
                      <div className='col-sm-2 mb-1 d-flex justify-content-end'>
                        <CurrencyFormat
                          className='fw-bolder'
                          value={reportVoid.transaction?.total_price_amount}
                          displayType={'text'}
                          thousandSeparator={'.'}
                          decimalSeparator={','}
                          prefix={'Rp. '}
                        />
                      </div>
                    </div>

                    <Divider className='mt-5 mb-5' />

                    {reportVoid.transaction?.payment_method.map((dt: PaymentMethod, l: number) => (
                      <div
                        className='row d-flex justify-content-between'
                        key={`payment-method-${l}`}
                      >
                        <div className='col-sm-10 mb-1'>
                          <span>{dt.payment_method}</span>
                        </div>
                        <div className='col-sm-2 mb-1 d-flex justify-content-end'>
                          <CurrencyFormat
                            value={dt.payment_amount}
                            displayType={'text'}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            prefix={'Rp. '}
                          />
                        </div>
                        <div className='col-sm-10 mb-1'>
                          <span>{intl.formatMessage({id: 'LABEL_CHANGE_AMOUNT'})}</span>
                        </div>
                        <div className='col-sm-2 mb-1 d-flex justify-content-end'>
                          <CurrencyFormat
                            value={dt.change_amount}
                            displayType={'text'}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            prefix={'Rp. '}
                          />
                        </div>
                      </div>
                    ))}

                    <Divider className='mt-5 mb-5' />

                    <div className='row d-flex justify-content-between' key={`note`}>
                      <div className='col-sm-10 mb-1'>
                        <span>{intl.formatMessage({id: 'LABEL_NOTE'})}</span>
                      </div>
                      <div className='col-sm-2 mb-1 d-flex justify-content-end'>
                        <span>
                          {reportVoid.transaction?.notes === ''
                            ? '-'
                            : reportVoid.transaction?.notes}
                        </span>
                      </div>
                    </div>
                  </div>

                  <Divider className='mt-5 mb-5'>
                    <a
                      href='#'
                      className='fw-semibold'
                      onClick={() =>
                        exportComponentAsJPEG(pdfRef, {
                          fileName: `${reportVoid.status === 'waiting approval' ? 'Req-void-' : 'Void-'}${reportVoid.outlet_name}-${reportVoid.transaction?.receipt_number}`,
                          html2CanvasOptions: {width: 1200},
                        })
                      }
                    >
                      {`${reportVoid.status === 'waiting approval' ? 'Req-void-' : 'Void-'}${reportVoid.outlet_name}-${reportVoid.transaction?.receipt_number}.jpeg`}
                    </a>
                  </Divider>
                </div>
                {/* Data */}
              </div>
            </div>
            {/* Tab Body */}
          </form>
        </div>
        {/* Form */}
      </Drawer.Body>
    </>
  )
}

export {ViewDrawerForm}
