import {useState} from 'react'
import {useIntl} from 'react-intl'
import {Checkbox, Drawer, CheckPicker} from 'rsuite'

import {FilterAction, FilterButton, StringFirstToUpper} from 'app/components'

import {
  useAction,
  useListQueryRequest,
  useListQueryResponse,
  useListQueryResponseFilterMeta,
} from '../../providers'
import {useLang} from '_metronic/i18n/Metronici18n'

const footerStyles = {
  padding: '0px 2px',
  borderTop: '1px solid #e5e5e5',
}

const ListFilter = () => {
  const intl = useIntl()
  const local = useLang()
  const {showFilter, setShowFilter} = useAction()
  const {state, updateState} = useListQueryRequest()
  const {isLoading} = useListQueryResponse()
  const filterMeta = useListQueryResponseFilterMeta()
  const [activity, setActivity] = useState(
    state?.filter !== undefined ? state?.filter.activity : ''
  )
  const [module_id, setModuleID] = useState(
    state?.filter !== undefined ? state?.filter.module_id : []
  )
  const [menu_id, setMenuID] = useState(state?.filter !== undefined ? state?.filter.menu_id : [])
  const optionsActivity =
    filterMeta?.metaActivity === undefined
      ? []
      : (filterMeta?.metaActivity as unknown as any[]).map((activity, index) => ({
          value: activity.activity_name,
          label: <StringFirstToUpper key={index} string={activity.activity_name} />,
        }))
  const optionsModule =
    filterMeta?.metaModule === undefined
      ? []
      : (filterMeta?.metaModule as unknown as any[]).map((module) => ({
          value: module.module_id,
          label: local === 'id' ? module.name_idn : module.name_eng,
        }))
  const optionsMenu =
    filterMeta?.metaMenu === undefined
      ? []
      : (filterMeta?.metaMenu as unknown as any[]).map((menu) => ({
          value: menu.menu_id,
          label: local === 'id' ? menu.name_idn : menu.name_eng,
        }))

  const resetData = () => {
    setActivity([])
    setModuleID([])
    setMenuID([])
    updateState({filter: undefined})
  }

  const filterData = () => {
    updateState({
      filter: {activity, module_id, menu_id},
    })
    setShowFilter(false)
  }

  return (
    <>
      <FilterButton
        isLoading={isLoading}
        state={state}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
      />
      <Drawer
        open={showFilter}
        onClose={() => {
          setShowFilter(false)
        }}
        placement='right'
        size='xs'
      >
        <Drawer.Header closeButton>
          {/* Actions */}
          <FilterAction filterData={filterData} isLoading={isLoading} resetData={resetData} />
          {/* Actions */}
        </Drawer.Header>

        {/* Content */}
        <div className='card-body table-responsive m-5'>
          {/* Input group */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>
              {intl.formatMessage({id: 'LABEL_MODULE'})}
            </label>
            <CheckPicker
              block
              placeholder={`-- ${intl.formatMessage({id: 'LABEL_SELECT'})} --`}
              data={optionsModule}
              onChange={(val) => {
                if (val.length > 0) {
                  setModuleID(val)
                } else {
                  setModuleID([])
                }
              }}
              defaultValue={(optionsModule as unknown as any[]).map((dt: any) => ({
                value: dt.value,
                label: dt.label,
              }))}
              value={module_id}
              renderExtraFooter={() =>
                optionsModule.length > 0 ? (
                  <div style={footerStyles}>
                    <Checkbox
                      indeterminate={
                        module_id.length > 0 &&
                        module_id.length < (optionsModule as unknown as any[]).length
                      }
                      checked={module_id.length === (optionsModule as unknown as any[]).length}
                      onChange={(data, checked) => {
                        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                        if (checked) {
                          setModuleID((optionsModule as unknown as any[]).map((e) => e.value))
                        } else {
                          setModuleID([])
                        }
                      }}
                    >
                      {module_id.length === (optionsModule as unknown as any[]).length
                        ? intl.formatMessage({id: 'LABEL_UNSELECT_ALL'})
                        : intl.formatMessage({id: 'LABEL_SELECT_ALL'})}
                    </Checkbox>
                  </div>
                ) : (
                  <></>
                )
              }
            />
          </div>
          {/* Input group */}

          {/* Input group */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>
              {intl.formatMessage({id: 'LABEL_MENU'})}
            </label>
            <CheckPicker
              block
              placeholder={`-- ${intl.formatMessage({id: 'LABEL_SELECT'})} --`}
              data={optionsMenu}
              onChange={(val) => {
                if (val.length > 0) {
                  setMenuID(val)
                } else {
                  setMenuID([])
                }
              }}
              defaultValue={(optionsMenu as unknown as any[]).map((dt: any) => ({
                value: dt.value,
                label: dt.label,
              }))}
              value={menu_id}
              renderExtraFooter={() =>
                optionsMenu.length > 0 ? (
                  <div style={footerStyles}>
                    <Checkbox
                      indeterminate={
                        menu_id.length > 0 &&
                        menu_id.length < (optionsMenu as unknown as any[]).length
                      }
                      checked={menu_id.length === (optionsMenu as unknown as any[]).length}
                      onChange={(data, checked) => {
                        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                        if (checked) {
                          setMenuID((optionsMenu as unknown as any[]).map((e) => e.value))
                        } else {
                          setMenuID([])
                        }
                      }}
                    >
                      {menu_id.length === (optionsMenu as unknown as any[]).length
                        ? intl.formatMessage({id: 'LABEL_UNSELECT_ALL'})
                        : intl.formatMessage({id: 'LABEL_SELECT_ALL'})}
                    </Checkbox>
                  </div>
                ) : (
                  <></>
                )
              }
            />
          </div>
          {/* Input group */}

          {/* Input group */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>
              {intl.formatMessage({id: 'LABEL_ACTION'})}
            </label>
            <CheckPicker
              block
              placeholder={`-- ${intl.formatMessage({id: 'LABEL_SELECT'})} --`}
              data={optionsActivity}
              onChange={(val) => {
                if (val.length > 0) {
                  setActivity(val)
                } else {
                  setActivity([])
                }
              }}
              defaultValue={(optionsActivity as unknown as any[]).map((dt: any) => ({
                value: dt.value,
                label: dt.label,
              }))}
              value={activity}
              renderExtraFooter={() =>
                optionsActivity.length > 0 ? (
                  <div style={footerStyles}>
                    <Checkbox
                      indeterminate={
                        activity.length > 0 &&
                        activity.length < (optionsActivity as unknown as any[]).length
                      }
                      checked={activity.length === (optionsActivity as unknown as any[]).length}
                      onChange={(data, checked) => {
                        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                        if (checked) {
                          setActivity((optionsActivity as unknown as any[]).map((e) => e.value))
                        } else {
                          setActivity([])
                        }
                      }}
                    >
                      {activity.length === (optionsActivity as unknown as any[]).length
                        ? intl.formatMessage({id: 'LABEL_UNSELECT_ALL'})
                        : intl.formatMessage({id: 'LABEL_SELECT_ALL'})}
                    </Checkbox>
                  </div>
                ) : (
                  <></>
                )
              }
            />
          </div>
          {/* Input group */}
        </div>
        {/* Content */}

        {/* Actions */}
        <div className='modal-footer m-5'>
          <FilterAction filterData={filterData} isLoading={isLoading} resetData={resetData} />
        </div>
        {/* Actions */}
      </Drawer>
    </>
  )
}

export {ListFilter}
