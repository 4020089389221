import {VoidID, ListResponse} from '.'

export type ListModel = {
  void_id: string
  status?: string
}

export type ListQueryResponse = ListResponse<Array<ListModel>>

export const initialList: ListModel = {
  void_id: '',
  status: '',
}

export interface Receipt {
  notes: string
  social_media: SocialMedia[]
  outlet: Outlet
}

export interface SocialMedia {
  url: string
  logo: string
}

export interface Outlet {
  name: string
  address: string
  phone_number: string
  logo: string
}

export interface Transaction {
  order_id: string
  created_at: string
  created_by: string
  receipt_number: string
  total_discount_amount: number
  sub_total_amount: number
  ppn_percentage: number
  ppn_amount: number
  rounding_amount: number
  total_price_amount: number
  payment_amount: number
  change_amount: number
  sales_type: string
  status: string
  notes: string
  items: Item[]
  discount_bill: DiscountBill[]
  payment_method: PaymentMethod[]
}

export interface Item {
  for_order: number
  item_name: string
  variant_name: string
  quantity: number
  base_price_amount: number
  final_price_amount: number
  discount_items: DiscountItem[]
}

export interface DiscountItem {
  for_order: number
  format: string
  name: string
  discount_amount: number
  discount_price_amount: number
}

export interface DiscountBill {
  for_order: number
  format: string
  name: string
  discount_amount: number
  discount_price_amount: number
}

export interface PaymentMethod {
  payment_method: string
  total_price_amount: number
  payment_amount: number
  change_amount: number
}

export type DetailModel = {
  void_id?: VoidID
  created_at?: string
  approved_at?: string
  created_by?: string
  approved_by?: string
  notes?: string
  brand_name?: string
  outlet_name?: string
  status?: string
  transaction?: Transaction
  receipt?: Receipt
  menu_id?: string // modified payload for crud
  creator_id?: string // modified payload for crud
  creator_by?: string // modified payload for crud
}

export const initialData: DetailModel = {
  void_id: '',
  created_at: '',
  approved_at: '',
  created_by: '',
  approved_by: '',
  notes: '',
  brand_name: '',
  outlet_name: '',
  status: '',
  transaction: {
    order_id: '',
    created_at: '',
    created_by: '',
    receipt_number: '',
    total_discount_amount: 0,
    sub_total_amount: 0,
    ppn_percentage: 0,
    ppn_amount: 0,
    rounding_amount: 0,
    total_price_amount: 0,
    payment_amount: 0,
    change_amount: 0,
    sales_type: '0',
    status: '0',
    notes: '0',
    items: [
      {
        for_order: 0,
        item_name: '',
        variant_name: '',
        quantity: 0,
        base_price_amount: 0,
        final_price_amount: 0,
        discount_items: [
          {
            for_order: 0,
            format: '',
            name: '',
            discount_amount: 0,
            discount_price_amount: 0,
          },
        ],
      },
    ],
    discount_bill: [
      {
        for_order: 0,
        format: '',
        name: '',
        discount_amount: 0,
        discount_price_amount: 0,
      },
    ],
    payment_method: [
      {
        payment_method: '',
        total_price_amount: 0,
        payment_amount: 0,
        change_amount: 0,
      },
    ],
  },
  receipt: {
    notes: '',
    social_media: [
      {
        url: '',
        logo: '',
      },
      {
        url: '',
        logo: '',
      },
      {
        url: '',
        logo: '',
      },
    ],
    outlet: {
      name: '',
      address: '',
      phone_number: '',
      logo: '',
    },
  },
  menu_id: '', // modified payload for crud
  creator_id: '', // modified payload for crud
  creator_by: '', // modified payload for crud
}
