import {ReceiptID, ListResponse} from '.'

export type ListModel = {
  receipt_id?: string
  brand?: string
}

export type ListQueryResponse = ListResponse<Array<ListModel>>

export const initialList: ListModel = {
  receipt_id: '',
  brand: '',
}

export type HoldingModel = {
  holding_id: string
  name: string
  slug: string
}
export type CompanyModel = {
  company_id: string
  name: string
  slug: string
}
export type BrandModel = {
  brand_id: string
  name: string
  slug: string
}
export type SocialMediaModel = {
  social_media_id: string
  url: string
  logo: string
}

export type DetailModel = {
  receipt_id?: ReceiptID
  holding_id?: string // modified payload for crud
  holding_name?: string // modified payload for crud
  company_id?: string // modified payload for crud
  company_name?: string // modified payload for crud
  brand_id?: string // modified payload for crud
  brand_name?: string // modified payload for crud
  social_media?: SocialMediaModel[]
  holding?: HoldingModel
  company?: CompanyModel
  brand?: BrandModel
  notes?: string
  created_at?: string
  created_by?: string
  updated_at?: string
  updated_by?: string
  menu_id?: string // modified payload for crud
  creator_id?: string // modified payload for crud
  creator_by?: string // modified payload for crud
}

export const initialData: DetailModel = {
  receipt_id: '',
  holding_id: '', // modified payload for crud
  holding_name: '', // modified payload for crud
  company_id: '', // modified payload for crud
  company_name: '', // modified payload for crud
  brand_id: '', // modified payload for crud
  brand_name: '', // modified payload for crud
  holding: {
    holding_id: '',
    name: '',
    slug: '',
  },
  company: {
    company_id: '',
    name: '',
    slug: '',
  },
  brand: {
    brand_id: '',
    name: '',
    slug: '',
  },
  social_media: [
    {
      social_media_id: '',
      url: '',
      logo: '',
    },
  ],
  notes: '',
  created_at: '',
  created_by: '',
  updated_at: '',
  updated_by: '',
  menu_id: '', // modified payload for crud
  creator_id: '', // modified payload for crud
  creator_by: '', // modified payload for crud
}
