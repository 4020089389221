import {FC} from 'react'
import {useIntl} from 'react-intl'
import {Whisper, Tooltip} from 'rsuite'
import * as CurrencyFormat from 'react-currency-format'

type Props = {
  count: any
  data: any
  id: any
}

const RowTooltipCountReportShiftDifferentFormatter: FC<Props> = ({count, data, id}) => {
  const intl = useIntl()
  return (
    <Whisper
      key={`whisper-${id}`}
      placement='top'
      controlId='control-id-hover'
      trigger='hover'
      speaker={
        <Tooltip id={`tooltip-count-${id}`} key={`tooltip-count-${id}`}>
          {
            <div className='menu-item' key={`different-item-${id}`}>
              {data.map((item: any, index: number) => (
                <>
                  {index < 6 &&
                    (index !== 5 ? (
                      <div className='menu-link without-sub' key={`different-link-${index}`}>
                        <span className='menu-bullet' key={`different-bullet-${index}`}>
                          <span className='bullet bullet-dot' key={`bullet-dot-${index}`}></span>
                        </span>
                        <span className='menu-title' key={`different-title-${index}`}>
                          <CurrencyFormat
                            value={item.different}
                            displayType={'text'}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            prefix={'Rp. '}
                          />
                        </span>
                      </div>
                    ) : (
                      <div className='menu-link without-sub' key={`different-link-${index}`}>
                        <span className='menu-bullet' key={`different-bullet-${index}`}>
                          <span
                            className='bullet bullet-dot'
                            key={`different-bullet-dot-${index}`}
                          ></span>
                        </span>
                        <span className='menu-title' key={`different-title-${index}`}>
                          {count - 5} {intl.formatMessage({id: 'LABEL_OTHERS'})}
                        </span>
                      </div>
                    ))}
                </>
              ))}
            </div>
          }
        </Tooltip>
      }
    >
      <div className={`badge badge-secondary fw-bolder`} key={`count-${id}`}>
        {count}
      </div>
    </Whisper>
  )
}

export {RowTooltipCountReportShiftDifferentFormatter}
