import {FC} from 'react'
import {useIntl} from 'react-intl'

type Props = {
  row?: string
}

function coloringRows(rowLabel: any) {
  switch (rowLabel) {
    case 'waiting approval':
      return '-secondary'
    default:
      return '-success'
  }
}

const RowVoidStatusCellFormatter: FC<Props> = ({row}) => {
  const intl = useIntl()
  return (
    <div className={`badge badge${coloringRows(row)} fw-bolder`}>
      {row === 'waiting approval'
        ? intl.formatMessage({id: 'LABEL_WAITING_APPROVAL'})
        : intl.formatMessage({id: 'LABEL_APPROVED'})}
    </div>
  )
}

export {RowVoidStatusCellFormatter}
