/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {FormattedMessage} from 'react-intl'
import {Tooltip, Whisper} from 'rsuite'

import {Languages} from './Languages'
import {useAuth} from 'app/modules/auth'
import {toAbsoluteUrl} from '_metronic/helpers'

const HeaderUserMenu: FC = () => {
  const {currentUser, logout} = useAuth()
  const avatarUrl: any = currentUser?.avatar
  const tooltipLabel = (
    <Tooltip>
      <FormattedMessage id='TOOLTIP_COMING_SOON' />
    </Tooltip>
  )

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img
              alt='Logo'
              src={
                currentUser?.avatar === ''
                  ? toAbsoluteUrl('/media/avatars/blank.png')
                  : toAbsoluteUrl(avatarUrl)
              }
              loading='lazy'
            />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>{currentUser?.fullname}</div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              <span className='badge badge-light-success fw-bolder fs-8'>
                {currentUser?.position.name === ''
                  ? currentUser?.role.name
                  : currentUser?.position.name}
              </span>
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>
      <Whisper placement='top' controlId='control-id-hover' trigger='hover' speaker={tooltipLabel}>
        <div className='menu-item px-5'>
          <Link to={'#'} className='menu-link px-5'>
            <FormattedMessage id='LABEL_MY_PROFILE' />
          </Link>
        </div>
      </Whisper>

      <Whisper placement='top' controlId='control-id-hover' trigger='hover' speaker={tooltipLabel}>
        <div className='menu-item px-5'>
          <a href='#' className='menu-link px-5'>
            <span className='menu-text'>
              <FormattedMessage id='LABEL_MY_PROJECTS' />
            </span>
            <span className='menu-badge'>
              <span className='badge badge-light-danger badge-circle fw-bolder fs-7'>3</span>
            </span>
          </a>
        </div>
      </Whisper>

      <div className='separator my-2'></div>

      <Languages />

      <div className='menu-item px-5 my-1'>
        <Link to='/account/activity' className='menu-link px-5'>
          <FormattedMessage id='LABEL_ACCOUNT_SETTINGS' />
        </Link>
      </div>

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          <FormattedMessage id='BUTTON_LABEL_LOG_OUT' />
          <span className='menu-title position-relative'>
            <span className='fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0'>
              <i className='bi bi-power'></i>
            </span>
          </span>
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
