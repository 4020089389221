import {DataID, ListResponse} from '.'

export type ListModel = {
  data_id?: DataID
  parameter_id?: string
  parent_id?: string
  parent_name_eng?: string
  parent_name_idn?: string
  name_eng?: string
  name_idn?: string
  detail_eng?: string
  detail_idn?: string
  for_order?: number
  status?: string
}

export type ListQueryResponse = ListResponse<Array<ListModel>>

export type GetDataByIDModel = {
  created_at?: string
  created_by?: string
  updated_at?: string
  updated_by?: string
  data_id?: string
  parameter_id?: string
  parameter_parent_id?: string
  parent_id?: string
  parent_name_eng?: string
  parent_name_idn?: string
  name_eng?: string
  name_idn?: string
  detail_eng?: string
  detail_idn?: string
  for_order?: number
  status?: string
  app_menu_id?: string
}

export const initialData: GetDataByIDModel = {
  created_at: '',
  created_by: '',
  data_id: '',
  parameter_id: '',
  parameter_parent_id: '',
  parent_id: '',
  parent_name_eng: '',
  parent_name_idn: '',
  name_eng: '',
  name_idn: '',
  detail_eng: '',
  detail_idn: '',
  for_order: 0,
  status: '',
  app_menu_id: '',
}
