import {createContext} from 'react'
import qs from 'qs'

import {ListQueryResponseContextProps, ListQueryState} from '.'
import {isNotEmpty} from '_metronic/helpers'

function createListResponseContext<T>(initialState: ListQueryResponseContextProps<T>) {
  return createContext(initialState)
}

function stringifyListRequestQuery(state: ListQueryState): string {
  const pagination = qs.stringify(state, {
    filter: ['page', 'items_per_page'],
    skipNulls: true,
  })
  const sort = qs.stringify(state, {filter: ['sort', 'order'], skipNulls: true})
  const search = isNotEmpty(state?.search)
    ? qs.stringify(state, {filter: ['search'], skipNulls: true})
    : ''

  const filter = state?.filter
    ? Object.entries(state.filter as Object)
        .filter((obj) => isNotEmpty(obj[1]))
        .map((obj) => {
          return `filter_${obj[0]}=${obj[1]}`
        })
        .join('&')
    : ''

  return [pagination, sort, search, filter]
    .filter((f) => f)
    .join('&')
    .toLowerCase()
}

export {createListResponseContext, stringifyListRequestQuery}
