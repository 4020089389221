import {FC, useEffect, useRef, useState} from 'react'
import toast from 'react-hot-toast'
import {useQuery} from 'react-query'
import {useIntl} from 'react-intl'
import {Drawer} from 'rsuite'

import {QUERIES, isNotEmpty} from '_metronic/helpers'
import {isMobileDevice} from '_metronic/assets/ts/_utils'
import {useUserGroupModules} from 'app/context/providers'
import {removeAuth} from 'app/modules/auth'

import {useAction, useListQueryResponse} from '../../providers'
import {getDataByID, getParameterByMenuIDForInput} from '../../helpers'
import {ViewDrawerForm} from '..'

type Props = {
  menuName?: any
  modalID?: any
  appMenuID: any
}

const ViewDrawerFormWrapper: FC<Props> = ({menuName, modalID, appMenuID}) => {
  const intl = useIntl()
  const mobile = isMobileDevice()
  const [open, setOpen] = useState(true)
  const didRequest = useRef(false)
  const {action, isLoading, itemIdForUpdate, setItemIdForUpdate, setIsLoading} = useAction()
  const {currentModulePath} = useUserGroupModules()
  const {meta} = useListQueryResponse()
  const for_order = meta?.meta?.totalCount !== undefined ? meta.meta.totalCount + 1 : 1
  const [parameter_parent_id, setParameterParentID] = useState('')
  const [parent_name_eng, setParentNameEng] = useState('')
  const [parent_name_idn, setParentNameID] = useState('')
  const [parameter_id, setParameterID] = useState('')
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {data: masterData, error} = useQuery(
    [`${currentModulePath}-${QUERIES.GET_MASTER_DATA}-${itemIdForUpdate}`],
    () => {
      setIsLoading(true)
      return getDataByID(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      refetchOnWindowFocus: false,
      onError: (err: any) => {
        if (err.response.status !== 200) {
          setIsLoading(false)
          if (err.response.status === 401) {
            toast.error(err.response.statusText)
            removeAuth()
          } else {
            toast.error(err.response.statusText)
          }
        }
      },
      onSuccess: () => {
        setIsLoading(false)
      },
    }
  )

  // get parameter
  useEffect(() => {
    const requestParameterListForInput = async () => {
      try {
        if (!didRequest.current) {
          const parameter = await getParameterByMenuIDForInput(appMenuID)
          // throw message error
          if (parameter.data.meta.code === 200) {
            // set payload
            setParameterParentID(parameter.data.data.parent_id)
            setParentNameEng(parameter.data.data.parent_name_eng)
            setParentNameID(parameter.data.data.parent_name_idn)
            setParameterID(parameter.data.data.parameter_id)
          }
        }
      } catch (error: any) {
        console.error(error)
        if (error.response.status === 401) {
          toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
          removeAuth()
        } else {
          toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
        }
      } finally {
      }

      return () => (didRequest.current = true)
    }

    if (action === 'add') {
      requestParameterListForInput()
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Drawer
      open={open}
      onClose={() => {
        setOpen(false)
        setItemIdForUpdate(undefined)
      }}
      placement='right'
      backdrop='static'
      keyboard={action === 'view' ? true : false}
      style={{width: mobile ? '100%' : '50%'}}
    >
      {masterData === undefined && (
        <ViewDrawerForm
          menuName={menuName}
          isLoading={isLoading}
          masterData={{
            data_id: undefined,
            parameter_parent_id: parameter_parent_id,
            parent_name_eng: parent_name_eng,
            parent_name_idn: parent_name_idn,
            parameter_id: parameter_id,
            for_order: for_order,
            status: 't',
          }}
          appMenuID={appMenuID}
          modalID={modalID}
        />
      )}
      {!error && masterData && (
        <ViewDrawerForm
          menuName={menuName}
          isLoading={isLoading}
          masterData={masterData.data}
          appMenuID={appMenuID}
          modalID={modalID}
        />
      )}
    </Drawer>
  )
}

export {ViewDrawerFormWrapper}
