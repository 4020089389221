import axios, {AxiosResponse} from 'axios'

import {APIResponse} from 'app/components'

import {ListQueryResponse} from './_list_models'
import {RoleID} from '.'

const API_APP_URL = process.env.REACT_APP_APP_API_URL
const X_API_KEY = process.env.REACT_APP_APP_API_KEY
const GET_USER_ACCESS_LIST_URL = `${API_APP_URL}/user-group-access?`
const GET_USER_ACCESS_URL = `${API_APP_URL}/user-group-access/fetch-user-group-access?`
const UPDATE_USER_ACCESS_URL = `${API_APP_URL}/user-group-access?`

const getList = (query: any): Promise<ListQueryResponse> => {
  return axios
    .get(`${GET_USER_ACCESS_LIST_URL}${query}`, {
      headers: {'X-API-KEY': `${X_API_KEY}`},
    })
    .then((d: AxiosResponse<ListQueryResponse>) => d.data)
}

const getDataByID = (role_id: RoleID, module_id: string): Promise<APIResponse | undefined> => {
  return axios
    .get(`${GET_USER_ACCESS_URL}role_id=${role_id}&module_id=${module_id}`, {
      headers: {'X-API-KEY': `${X_API_KEY}`},
    })
    .then((response: AxiosResponse<APIResponse>) => response.data)
}

const updateData = (payload: any, appModuleID: any): Promise<APIResponse | undefined> => {
  return axios
    .put(
      `${UPDATE_USER_ACCESS_URL}role_id=${payload.role_id}&app_module_id=${appModuleID}`,
      {payload},
      {
        headers: {'X-API-KEY': `${X_API_KEY}`},
      }
    )
    .then((response: AxiosResponse<APIResponse>) => response.data)
}

export {getList, getDataByID, updateData}
