import {UserID, ListResponse} from '.'

export type ListModel = {
  email?: string
  fullname?: string
  user_id?: string
  role?: string
  status?: string
  username?: string
  last_login?: string
}

export type ListQueryResponse = ListResponse<Array<ListModel>>

export const initialList: ListModel = {
  email: '',
  fullname: '',
  user_id: '',
  role: '',
  status: '',
  username: '',
  last_login: '',
}

export type HoldingModel = {
  holding_id: string
  name: string
}
export type CompanyModel = {
  company_id: string
  name: string
}
export type BrandModel = {
  brand_id: string
  name: string
}
export type OutletModel = {
  outlet_id: string
  name: string
}
export type RoleModel = {
  role_id: string
  name: string
}

export type DetailModel = {
  user_id?: UserID
  holding_id?: any // modified payload for crud
  holding_name?: any // modified payload for crud
  company_id?: any // modified payload for crud
  company_name?: any // modified payload for crud
  brand_id?: any // modified payload for crud
  brand_name?: any // modified payload for crud
  outlet_id?: any // modified payload for crud
  outlet_name?: any // modified payload for crud
  role_id?: string // modified payload for crud
  role_name?: string // modified payload for crud
  holding?: HoldingModel[]
  company?: CompanyModel[]
  brand?: BrandModel[]
  outlet?: OutletModel[]
  role?: RoleModel
  username?: string
  fullname?: string
  email?: string
  password?: string
  status?: string
  is_event_user?: string
  note?: string
  last_login?: string
  created_at?: string
  created_by?: string
  updated_at?: string
  updated_by?: string
  menu_id?: string // modified payload for crud
  creator_id?: string // modified payload for crud
  creator_by?: string // modified payload for crud
}

export const initialData: DetailModel = {
  user_id: '',
  holding_id: [], // modified payload for crud
  holding_name: [], // modified payload for crud
  company_id: [], // modified payload for crud
  company_name: [], // modified payload for crud
  brand_id: [], // modified payload for crud
  brand_name: [], // modified payload for crud
  outlet_id: [], // modified payload for crud
  outlet_name: [], // modified payload for crud
  role_id: '', // modified payload for crud
  role_name: '', // modified payload for crud
  holding: [
    {
      holding_id: '',
      name: '',
    },
  ],

  company: [
    {
      company_id: '',
      name: '',
    },
  ],

  brand: [
    {
      brand_id: '',
      name: '',
    },
  ],
  outlet: [
    {
      outlet_id: '',
      name: '',
    },
  ],
  role: {
    role_id: '',
    name: '',
  },
  username: '',
  fullname: '',
  email: '',
  password: '',
  status: '',
  is_event_user: '',
  note: '',
  last_login: '',
  created_at: '',
  created_by: '',
  updated_at: '',
  updated_by: '',
  menu_id: '', // modified payload for crud
  creator_id: '', // modified payload for crud
  creator_by: '', // modified payload for crud
}
