import {Dispatch, SetStateAction} from 'react'

import {CurrentMenuAccessModel} from 'app/context/models'

import {MenuID} from '.'

export type ActionContextProps = {
  currentAccessMenu: CurrentMenuAccessModel | undefined
  setCurrentAccessMenu: Dispatch<SetStateAction<CurrentMenuAccessModel | undefined>>
  isLoading: boolean
  setIsLoading: Dispatch<SetStateAction<boolean>>
  showFilter: boolean
  setShowFilter: Dispatch<SetStateAction<boolean>>
  action: string
  setAction: Dispatch<SetStateAction<string>>
  itemIdForUpdate?: MenuID
  setItemIdForUpdate: Dispatch<SetStateAction<MenuID>>
  isSubMenu?: boolean
  setIsSubMenu: Dispatch<SetStateAction<boolean>>
  isParent?: boolean
  setIsParent: Dispatch<SetStateAction<boolean>>
  parentID?: string
  setParentID: Dispatch<SetStateAction<string>>
  directory?: string
  setDirectory: Dispatch<SetStateAction<string>>
}

export const initialAction: ActionContextProps = {
  currentAccessMenu: undefined,
  setCurrentAccessMenu: () => {},
  isLoading: false,
  setIsLoading: () => {},
  showFilter: false,
  setShowFilter: () => {},
  action: '',
  setAction: () => {},
  setItemIdForUpdate: () => {},
  setIsSubMenu: () => {},
  setIsParent: () => {},
  setParentID: () => {},
  setDirectory: () => {},
}
