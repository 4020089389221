import moment from 'moment'

export type ShiftDate = undefined | null | string

export type ListPaginationState = {
  page?: number
  items_per_page?: number
}

export type ListPeriodState = {
  period: string | null
}

export type ListSortState = {
  sort: string
  order: 'asc' | 'desc'
}

export type ListFilterState = {
  filter?: any
}

export type ListSearchState = {
  search?: any
}

export type ListResponse<T> = {
  data?: T
  meta?: {
    message?: string
    status?: boolean
    code?: number
    currentPage?: number
    prevPage?: number
    nextPage?: number
    perPage?: number
    totalCount?: number
    pageCount?: number
    grossSales?: number
    netSales?: number
  }
}

export type ListQueryState =
  | (ListPaginationState & ListPeriodState & ListSortState & ListFilterState & ListSearchState)
  | undefined

export type ListQueryRequestContextProps = {
  state: ListQueryState
  updateState: (updates: Partial<ListQueryState>) => void
}

export const initialListQueryState: ListQueryState = {
  page: 1,
  items_per_page: 5,
  sort: 'start_time',
  order: 'asc',
  period: moment.utc(moment().startOf('days')).format('YYYY-MM'),
}

export const initialListQueryRequest: ListQueryRequestContextProps = {
  state: initialListQueryState,
  updateState: () => {},
}

export type ListQueryResponseContextProps<T> = {
  response?: ListResponse<Array<T>> | undefined
  refetch: () => void
  isLoading: boolean
  query: string
  meta?: ListResponse<Array<T>> | undefined
  dataMeta?: ListResponse<Array<T>> | undefined
  lastUpdated?: any | undefined
}

export const initialListQueryResponse = {refetch: () => {}, isLoading: false, query: ''}
