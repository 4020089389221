import {initialListQueryState, ListQueryState} from '../helpers'

const LIST_STATE_KEY = 'backoffice-orins-pos/orins-pos/report/transactions'

const getListState = () => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null | undefined = localStorage.getItem(LIST_STATE_KEY)
  if (!lsValue) {
    return setListState(initialListQueryState)
  }

  try {
    const state = JSON.parse(lsValue)
    if (state) {
      return state
    }
  } catch (error) {
    console.log(LIST_STATE_KEY + ' STATE LOCAL STORAGE PARSE ERROR', error)
  }
}

const setListState = (state: ListQueryState) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(state)
    localStorage.setItem(LIST_STATE_KEY, lsValue)
  } catch (error) {
    console.error(LIST_STATE_KEY + ' STATE LOCAL STORAGE SAVE ERROR', error)
  }
}

export {getListState, setListState, LIST_STATE_KEY}
