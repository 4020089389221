/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import * as CurrencyFormat from 'react-currency-format'
import {Divider, Drawer} from 'rsuite'
import {exportComponentAsJPEG} from 'react-component-export-image'

import {
  NotAuthRedirectForm,
  SpinnerIndicator,
  datetimeFormatter,
  getFormAction,
} from 'app/components'
import {getAuth} from 'app/modules/auth'

import {DetailModel, DiscountBill, DiscountItem, Item, PaymentMethod} from '../../helpers'
import {useAction} from '../../providers'

type Props = {
  isLoading: boolean
  transaction: DetailModel | undefined
  appMenuID: any
  modalID: any
  menuName: any
}

const ViewDrawerForm: FC<Props> = ({menuName, transaction, isLoading, appMenuID, modalID}) => {
  const auth = getAuth()
  const intl = useIntl()
  const {action} = useAction()
  const [isAuth, setIsAuth] = useState(true)
  const [completed, setCompleted] = useState(true)
  const pdfRef = useRef(null)

  useEffect(() => {
    auth === undefined && setIsAuth(false)
  }, [auth])

  // control submit button
  useEffect(() => {
    setTimeout(function () {
      setCompleted(false)
    }, 5e3)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {!isAuth && <NotAuthRedirectForm />}

      <Drawer.Header>
        <Drawer.Title className='fw-bolder'>
          {isLoading ? (
            <SpinnerIndicator label={intl.formatMessage({id: 'BUTTON_LABEL_PLEASE_WAIT'})} />
          ) : (
            <>
              {getFormAction(
                action,
                intl.formatMessage({id: 'BUTTON_LABEL_VIEW'}),
                intl.formatMessage({id: 'BUTTON_LABEL_EDIT'}),
                intl.formatMessage({id: 'BUTTON_LABEL_ADD'}),
                intl.formatMessage({id: 'BUTTON_LABEL_DELETE'})
              )}{' '}
              {menuName}
            </>
          )}
        </Drawer.Title>
        <Drawer.Actions>{action !== 'view' && !completed && <></>}</Drawer.Actions>
      </Drawer.Header>

      <Drawer.Body style={{padding: 0}}>
        <div className={`modal-body m-5 mt-0`}>
          <div className='row table-responsive' ref={pdfRef}>
            <div className='d-flex flex-center flex-column p-1'>
              <div className='symbol symbol-125px symbol-circle'>
                <img src={transaction?.receipt?.outlet.logo} alt='Logo' />
              </div>
              <h1>{transaction?.receipt?.outlet.name}</h1>
              <span>{transaction?.receipt?.outlet.address}</span>
              <span>{transaction?.receipt?.outlet.phone_number}</span>
            </div>

            <Divider className='m-5' />

            <div className='d-flex justify-content-between align-items-start flex-wrap mb-1'>
              <span>{datetimeFormatter(transaction?.created_at, '3', intl.locale)}</span>
              <span>{datetimeFormatter(transaction?.created_at, '4', intl.locale)}</span>
            </div>

            <div className='d-flex justify-content-between align-items-start flex-wrap mb-1'>
              <span>{intl.formatMessage({id: 'LABEL_RECEIPT_NUMBER'})}</span>
              <span>{transaction?.receipt_number}</span>
            </div>

            <div className='d-flex justify-content-between align-items-start flex-wrap mb-1'>
              <span>{intl.formatMessage({id: 'LABEL_CREATED_BY'})}</span>
              <span>{transaction?.created_by}</span>
            </div>

            <Divider className='fw-bolder fs-4 text-muted'>
              {intl.formatMessage({id: 'LABEL_TRANSACTION_DETAIL'})}
            </Divider>

            <div className='d-flex flex-center flex-column mb-5'>
              <span>{transaction?.sales_type}</span>
            </div>

            {transaction?.items.map((dt: Item, i: number) => (
              <div className='row mb-5' key={`head-${i}`}>
                <div className='col-sm-7 mb-1'>
                  <span>{dt.item_name} -</span>
                  <span>&nbsp;{dt.variant_name}</span>
                </div>
                <div className='col-sm-1 mb-1'>
                  <CurrencyFormat
                    value={dt.quantity}
                    displayType={'text'}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    prefix={'x'}
                  />
                </div>
                <div className='col-sm-2 mb-1 d-flex justify-content-end'>
                  <CurrencyFormat
                    value={dt.base_price_amount}
                    displayType={'text'}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    prefix={'Rp. '}
                  />
                </div>
                <div className='col-sm-2 mb-1 d-flex justify-content-end'>
                  <CurrencyFormat
                    value={dt.final_price_amount}
                    displayType={'text'}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    prefix={'Rp. '}
                  />
                </div>

                {dt.discount_items.length > 0 &&
                  dt.discount_items.map((dt2: DiscountItem, j: number) => (
                    <div className='row' key={`discount-item-${i}-${j}`}>
                      <div className='col-sm-1 mb-1' />
                      <div className='col-sm-5 mb-1'>
                        <span className='text-muted'>{dt2.name}</span>
                      </div>
                      <div className='col-sm-6 mb-1'>
                        <CurrencyFormat
                          value={dt2.discount_price_amount}
                          className='text-muted'
                          displayType={'text'}
                          thousandSeparator={'.'}
                          decimalSeparator={','}
                          prefix={'(Rp. '}
                          suffix={')'}
                        />
                      </div>
                    </div>
                  ))}
              </div>
            ))}

            {transaction?.discount_bill.map((dt: DiscountBill, i: number) => (
              <div className='row mb-5 d-flex justify-content-between' key={`discount-bill-${i}`}>
                <div className='col-sm-10 mb-1'>
                  <span className='text-muted'>{dt.name}</span>
                </div>
                <div className='col-sm-2 mb-1 d-flex justify-content-end'>
                  <CurrencyFormat
                    className='text-muted'
                    value={dt.discount_price_amount}
                    displayType={'text'}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    prefix={'(Rp. '}
                    suffix={')'}
                  />
                </div>
              </div>
            ))}

            <Divider className='mt-1 mb-5' />

            <div className='row d-flex justify-content-between' key={`sub-total`}>
              <div className='col-sm-10 mb-1'>
                <span>Sub Total</span>
              </div>
              <div className='col-sm-2 mb-1 d-flex justify-content-end'>
                <CurrencyFormat
                  value={transaction?.sub_total_amount}
                  displayType={'text'}
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  prefix={'Rp. '}
                />
              </div>
            </div>

            <div className='row d-flex justify-content-between' key={`total-discount`}>
              <div className='col-sm-10 mb-1'>
                <span>{intl.formatMessage({id: 'LABEL_TOTAL_DISCOUNT_AMOUNT'})}</span>
              </div>
              <div className='col-sm-2 mb-1 d-flex justify-content-end'>
                <CurrencyFormat
                  className='text-muted'
                  value={transaction?.total_discount_amount}
                  displayType={'text'}
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  prefix={'(Rp. '}
                  suffix={')'}
                />
              </div>
            </div>

            <div className='row d-flex justify-content-between' key={`ppn`}>
              <div className='col-sm-10 mb-1'>
                <span>PPN {transaction?.ppn_percentage}%</span>
              </div>
              <div className='col-sm-2 mb-1 d-flex justify-content-end'>
                <CurrencyFormat
                  value={transaction?.ppn_amount}
                  displayType={'text'}
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  prefix={'Rp. '}
                />
              </div>
            </div>

            <div className='row d-flex justify-content-between' key={`rounding`}>
              <div className='col-sm-10 mb-1'>
                <span>{intl.formatMessage({id: 'LABEL_ROUNDING_AMOUNT'})}</span>
              </div>
              <div className='col-sm-2 mb-1 d-flex justify-content-end'>
                <CurrencyFormat
                  value={transaction?.rounding_amount}
                  displayType={'text'}
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  prefix={'Rp. '}
                />
              </div>
            </div>

            <Divider className='mt-5 mb-5' />

            <div className='row d-flex justify-content-between' key={`total`}>
              <div className='col-sm-10 mb-1'>
                <span className='fw-bolder'>Total</span>
              </div>
              <div className='col-sm-2 mb-1 d-flex justify-content-end'>
                <CurrencyFormat
                  className='fw-bolder'
                  value={transaction?.total_price_amount}
                  displayType={'text'}
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  prefix={'Rp. '}
                />
              </div>
            </div>

            <Divider className='mt-5 mb-5' />

            {transaction?.payment_method.map((dt: PaymentMethod, l: number) => (
              <div className='row d-flex justify-content-between' key={`payment-method-${l}`}>
                <div className='col-sm-10 mb-1'>
                  <span>{dt.payment_method}</span>
                </div>
                <div className='col-sm-2 mb-1 d-flex justify-content-end'>
                  <CurrencyFormat
                    value={dt.payment_amount}
                    displayType={'text'}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    prefix={'Rp. '}
                  />
                </div>
                <div className='col-sm-10 mb-1'>
                  <span>{intl.formatMessage({id: 'LABEL_CHANGE_AMOUNT'})}</span>
                </div>
                <div className='col-sm-2 mb-1 d-flex justify-content-end'>
                  <CurrencyFormat
                    value={dt.change_amount}
                    displayType={'text'}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    prefix={'Rp. '}
                  />
                </div>
              </div>
            ))}

            <Divider className='mt-5 mb-5' />

            <div className='row d-flex justify-content-between' key={`note`}>
              <div className='col-sm-10 mb-1'>
                <span>{intl.formatMessage({id: 'LABEL_NOTE'})}</span>
              </div>
              <div className='col-sm-2 mb-1 d-flex justify-content-end'>
                <span>{transaction?.notes === '' ? '-' : transaction?.notes}</span>
              </div>
            </div>
          </div>

          <Divider className='mt-5 mb-5'>
            <a
              href='#'
              className='fw-semibold'
              onClick={() =>
                exportComponentAsJPEG(pdfRef, {
                  fileName: `${transaction?.outlet_name}-${transaction?.receipt_number}`,
                  html2CanvasOptions: {width: 1200},
                })
              }
            >
              {`${transaction?.outlet_name}-${transaction?.receipt_number}.jpeg`}
            </a>
          </Divider>
        </div>
      </Drawer.Body>
    </>
  )
}

export {ViewDrawerForm}
