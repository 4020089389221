import {FC, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {Cell, Column, HeaderCell, Table} from 'rsuite-table'
import {Loader, Placeholder} from 'rsuite'

import {useThemeMode} from '_metronic/partials'
import {isMobileDevice} from '_metronic/assets/ts/_utils'
import {
  RowAvatarFormatter,
  RowStatusCellFormatter,
  RowTimeAgoCellFormatter,
  RowTooltipCellFormatter,
} from 'app/components'

import {ListActionFormatter} from './ListActionFormatter'
import {
  useListQueryRequest,
  useListQueryResponseLoading,
  useListQueryResponseData,
} from '../../providers'

const ListTable: FC = () => {
  const intl = useIntl()
  const isMobile = isMobileDevice()
  const {state, updateState} = useListQueryRequest()
  const isLoading = useListQueryResponseLoading()
  const users = useListQueryResponseData()
  const data = useMemo(() => users, [users])
  const [usePlaceholder] = useState(true)
  const [sortColumn, setSortColumn] = useState(state?.sort)
  const [sortType, setSortType] = useState(state?.order)
  const {mode} = useThemeMode()

  const sortData = () => {
    if (sortColumn && sortType) {
      return data.sort((a: any, b: any) => {
        let x = a[sortColumn]
        let y = b[sortColumn]

        if (sortType === 'asc') {
          return x - y
        } else {
          return y - x
        }
      })
    }
    return data
  }

  const handleSortColumn = (sortColumn: any, sortType: any) => {
    setSortColumn(sortColumn)
    setSortType(sortType)
    updateState({sort: sortColumn, order: sortType})
  }

  const renderLoading = () => {
    if (usePlaceholder) {
      return (
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            background: mode === 'light' ? '#fff' : '#252530',
            padding: 20,
            zIndex: 1,
          }}
        >
          <Placeholder.Grid rows={5} columns={6} active />
        </div>
      )
    }

    return <Loader center backdrop content={intl.formatMessage({id: 'BUTTON_LABEL_PLEASE_WAIT'})} />
  }

  return (
    <div>
      <Table
        wordWrap
        bordered
        cellBordered
        loading={isLoading}
        height={450}
        headerHeight={70}
        autoHeight={isMobile ? true : false}
        affixHeader={50}
        affixHorizontalScrollbar
        data={sortData()}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
        renderLoading={isLoading ? renderLoading : undefined}
        locale={{emptyMessage: intl.formatMessage({id: 'LABEL_EMPTY_DATA'})}}
      >
        {/* Username */}
        <Column fixed={isMobile ? false : true} sortable verticalAlign='middle' width={350}>
          <HeaderCell align='center'>
            <span
              className={`fw-bold fs-6 text-decoration-underline ${
                sortColumn === 'username' ? 'text-primary' : 'text-dark'
              }`}
            >
              {intl.formatMessage({id: 'LABEL_ACCOUNT_INFO'})}
            </span>
          </HeaderCell>
          <Cell dataKey='username'>
            {(rowData: any) => (
              <RowAvatarFormatter
                avatarUrl={rowData.avatar}
                email={rowData.email}
                username={rowData.username}
              />
            )}
          </Cell>
        </Column>

        {/* Fullname */}
        <Column sortable verticalAlign='middle' width={250}>
          <HeaderCell align='center'>
            <span
              className={`fw-bold fs-6 text-decoration-underline ${
                sortColumn === 'fullname' ? 'text-primary' : 'text-dark'
              }`}
            >
              {intl.formatMessage({id: 'LABEL_FULLNAME'})}
            </span>
          </HeaderCell>
          <Cell dataKey='fullname'>
            {(rowData: any) =>
              rowData.fullname.length < 25 ? (
                rowData.fullname
              ) : (
                <RowTooltipCellFormatter text={rowData.fullname} length={24} />
              )
            }
          </Cell>
        </Column>

        {/* User Group */}
        <Column sortable verticalAlign='middle' width={250}>
          <HeaderCell align='center'>
            <span
              className={`fw-bold fs-6 text-decoration-underline ${
                sortColumn === 'role.name' ? 'text-primary' : 'text-dark'
              }`}
            >
              {intl.formatMessage({id: 'LABEL_USER_GROUP'})}
            </span>
          </HeaderCell>
          <Cell dataKey='role.name' align='center'>
            {(rowData: any) =>
              rowData.role.name.length < 25 ? (
                rowData.role.name
              ) : (
                <RowTooltipCellFormatter text={rowData.role.name} length={24} />
              )
            }
          </Cell>
        </Column>

        {/* Status */}
        <Column sortable verticalAlign='middle' width={100}>
          <HeaderCell align='center'>
            <span
              className={`fw-bold fs-6 text-decoration-underline ${
                sortColumn === 'status' ? 'text-primary' : 'text-dark'
              }`}
            >
              Status
            </span>
          </HeaderCell>
          <Cell dataKey='status' align='center'>
            {(rowData: any) => <RowStatusCellFormatter row={rowData.status} />}
          </Cell>
        </Column>

        {/* Last Login */}
        <Column sortable verticalAlign='middle' width={300}>
          <HeaderCell align='center'>
            <span
              className={`fw-bold fs-6 text-decoration-underline ${
                sortColumn === 'last_login' ? 'text-primary' : 'text-dark'
              }`}
            >
              {intl.formatMessage({id: 'LABEL_LAST_LOGIN'})}
            </span>
          </HeaderCell>
          <Cell dataKey='last_login'>
            {(rowData: any) => (
              <RowTimeAgoCellFormatter datetime={rowData.last_login} local={intl.locale} />
            )}
          </Cell>
        </Column>

        {/* Action */}
        <Column fixed='right' align='right' verticalAlign='middle' width={75}>
          <HeaderCell>
            <span className={`fw-bold fs-6 text-dark text-decoration-underline`}>
              {intl.formatMessage({id: 'LABEL_ACTION'})}
            </span>
          </HeaderCell>
          <Cell dataKey='action' style={{flexWrap: 'nowrap'}}>
            {(rowData: any) => <ListActionFormatter user_id={rowData.user_id} />}
          </Cell>
        </Column>
      </Table>
    </div>
  )
}

export {ListTable}
