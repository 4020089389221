import {FC} from 'react'

type Props = {
  string?: string
}

function capitalizeTheFirstLetterOfEachWord(words: any) {
  var separateWord = words.toLowerCase().split(' ')
  for (var i = 0; i < separateWord.length; i++) {
    separateWord[i] = separateWord[i].charAt(0).toUpperCase() + separateWord[i].substring(1)
  }
  return separateWord.join(' ')
}

const StringFirstToUpper: FC<Props> = ({string}) => {
  return (
    <span>
      {string !== undefined && string !== '' ? capitalizeTheFirstLetterOfEachWord(string) : '-'}
    </span>
  )
}

export {StringFirstToUpper}
