import {CategoryID, ListResponse} from '.'

export type ListModel = {
  category_id?: string
  holding_slug?: string
  company_slug?: string
  brand_slug?: string
  name?: string
  status?: string
}

export type ListQueryResponse = ListResponse<Array<ListModel>>

export const initialList: ListModel = {
  category_id: '',
  holding_slug: '',
  company_slug: '',
  brand_slug: '',
  name: '',
  status: '',
}

export type HoldingModel = {
  holding_id: string
  name: string
}
export type CompanyModel = {
  company_id: string
  name: string
}
export type BrandModel = {
  brand_id: string
  name: string
}

export type DetailModel = {
  category_id?: CategoryID
  holding_id?: any // modified payload for crud
  holding_name?: any // modified payload for crud
  company_id?: any // modified payload for crud
  company_name?: any // modified payload for crud
  brand_id?: any // modified payload for crud
  brand_name?: any // modified payload for crud
  holding?: HoldingModel
  company?: CompanyModel
  brand?: BrandModel
  name?: string
  max?: number
  min?: number
  for_order?: number
  status?: string
  description?: string
  created_at?: string
  created_by?: string
  updated_at?: string
  updated_by?: string
  menu_id?: string // modified payload for crud
  creator_id?: string // modified payload for crud
  creator_by?: string // modified payload for crud
}

export const initialData: DetailModel = {
  category_id: '',
  holding_id: '', // modified payload for crud
  holding_name: '', // modified payload for crud
  company_id: '', // modified payload for crud
  company_name: '', // modified payload for crud
  brand_id: '', // modified payload for crud
  brand_name: '', // modified payload for crud
  holding: {
    holding_id: '',
    name: '',
  },
  company: {
    company_id: '',
    name: '',
  },
  brand: {
    brand_id: '',
    name: '',
  },
  name: '',
  max: 1,
  min: 1,
  for_order: 1,
  status: '',
  description: '',
  created_at: '',
  created_by: '',
  updated_at: '',
  updated_by: '',
  menu_id: '', // modified payload for crud
  creator_id: '', // modified payload for crud
  creator_by: '', // modified payload for crud
}
