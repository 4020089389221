/* eslint-disable @typescript-eslint/no-unused-vars */
import {FC} from 'react'

import {
  ListFilterHelper,
  SearchComponent,
  SearchComponentWithDate,
  SearchComponentWithDaterange,
  ShortcutMenuButton,
  ToolbarAction,
} from 'app/components'

type Props = {
  menuName: any
  menuID?: any
  parentMenuID?: any
  moduleID?: any
  siteID?: any
  useListQueryRequest: any
  useListQueryResponse: any
  useAction?: any
  labelUpper: any
}

const ContentHeader: FC<Props> = ({
  useListQueryRequest,
  useListQueryResponse,
  labelUpper,
  menuName,
  menuID,
  parentMenuID,
  siteID,
  moduleID,
  useAction,
}) => {
  const listSearchWithDaterange = [''] // menu with search & daterange
  const listSearchWithDate = ['000'] // menu with search & date
  const notListMenu = ['activity', '4', '51', '100', '102'] // menu with no add action

  return (
    <div className='card-header border-0 pt-2'>
      {listSearchWithDaterange.includes(menuID) ? (
        <SearchComponentWithDaterange
          useListQueryRequest={useListQueryRequest}
          useListQueryResponse={useListQueryResponse}
          labelUpper={labelUpper}
        />
      ) : listSearchWithDate.includes(menuID) ? (
        <SearchComponentWithDate
          useListQueryRequest={useListQueryRequest}
          useListQueryResponse={useListQueryResponse}
          labelUpper={labelUpper}
        />
      ) : (
        <SearchComponent
          useListQueryRequest={useListQueryRequest}
          useListQueryResponse={useListQueryResponse}
          labelUpper={labelUpper}
        />
      )}

      <div className='card-toolbar'>
        <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
          {menuID !== 'activity' && <ShortcutMenuButton />}

          <ListFilterHelper menuID={menuID} parentMenuID={parentMenuID} />

          {!notListMenu.includes(menuID) && (
            <ToolbarAction menuName={menuName} useAction={useAction} />
          )}
        </div>
      </div>
    </div>
  )
}

export {ContentHeader}
