import {FC} from 'react'
import {useIntl} from 'react-intl'

type Props = {
  row?: string
}

function coloringRows(rowLabel: any) {
  switch (rowLabel) {
    case 't':
      return '-success'
    case 'r':
      return '-secondary'
    default:
      return '-danger'
  }
}

const RowSalesOrderStatusCellFormatter: FC<Props> = ({row}) => {
  const intl = useIntl()
  return (
    <div className={`badge badge${coloringRows(row)} fw-bolder`}>
      {row === 't'
        ? intl.formatMessage({id: 'LABEL_SALES_STATUS_DONE'})
        : row === 'r'
          ? intl.formatMessage({id: 'LABEL_SALES_STATUS_REQUEST'})
          : intl.formatMessage({id: 'LABEL_SALES_STATUS_REFUND'})}
    </div>
  )
}

export {RowSalesOrderStatusCellFormatter}
