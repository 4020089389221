import {DiscountID, ListResponse} from '.'

export type ListModel = {
  discount_id?: string
  holding_slug?: string
  company_slug?: string
  brand_slug?: string
  name?: string
  status?: string
}

export type ListQueryResponse = ListResponse<Array<ListModel>>

export const initialList: ListModel = {
  discount_id: '',
  holding_slug: '',
  company_slug: '',
  brand_slug: '',
  name: '',
  status: '',
}

export type HoldingModel = {
  holding_id: string
  name: string
}
export type CompanyModel = {
  company_id: string
  name: string
}
export type BrandModel = {
  brand_id: string
  name: string
}
export type OutletModel = {
  outlet_id: string
  name: string
}

export type DetailModel = {
  discount_id?: DiscountID
  holding_id?: any // modified payload for crud
  holding_name?: any // modified payload for crud
  company_id?: any // modified payload for crud
  company_name?: any // modified payload for crud
  brand_id?: any // modified payload for crud
  brand_name?: any // modified payload for crud
  outlet_id?: any // modified payload for crud
  outlet_name?: any // modified payload for crud
  holding?: HoldingModel
  company?: CompanyModel
  brand?: BrandModel
  outlet?: OutletModel[]
  name?: string
  format?: 'percentage' | 'cash'
  amount?: number
  description?: string
  status?: string
  is_lifetime?: string
  start_period?: string
  end_period?: string
  start_hour?: string
  end_hour?: string
  days?: number
  created_at?: string
  created_by?: string
  updated_at?: string
  updated_by?: string
  menu_id?: string // modified payload for crud
  creator_id?: string // modified payload for crud
  creator_by?: string // modified payload for crud
}

export const initialData: DetailModel = {
  discount_id: '',
  holding_id: '', // modified payload for crud
  holding_name: '', // modified payload for crud
  company_id: '', // modified payload for crud
  company_name: '', // modified payload for crud
  brand_id: '', // modified payload for crud
  brand_name: '', // modified payload for crud
  holding: {
    holding_id: '',
    name: '',
  },
  company: {
    company_id: '',
    name: '',
  },
  brand: {
    brand_id: '',
    name: '',
  },
  outlet: [
    {
      outlet_id: '',
      name: '',
    },
  ],
  name: '',
  format: 'percentage',
  amount: 0,
  description: '',
  status: '',
  is_lifetime: '',
  start_period: '',
  end_period: '',
  start_hour: '',
  end_hour: '',
  days: 1111111,
  created_at: '',
  created_by: '',
  updated_at: '',
  updated_by: '',
  menu_id: '', // modified payload for crud
  creator_id: '', // modified payload for crud
  creator_by: '', // modified payload for crud
}
