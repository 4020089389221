import axios, {AxiosResponse} from 'axios'

import {APIResponse} from 'app/components'

import {ListQueryResponse, MenuID} from '.'

const MENU_API_URL = `${process.env.REACT_APP_MOBILE_APP_API_URL}/layout-menu`
const MOBILE_X_API_KEY = process.env.REACT_APP_MOBILE_APP_API_KEY

const fetch = (query: any): Promise<ListQueryResponse> => {
  return axios
    .get(`${MENU_API_URL}/list?${query}`, {
      headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
    })
    .then((response: AxiosResponse<ListQueryResponse>) => response.data)
}

const get = (menu_id: MenuID): Promise<APIResponse | undefined> => {
  return axios
    .get(`${MENU_API_URL}/${menu_id}/detail`, {
      headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
    })
    .then((response: AxiosResponse<APIResponse>) => response.data)
}

const addData = (payload: any): Promise<APIResponse | undefined> => {
  return axios
    .post(
      `${MENU_API_URL}`,
      {payload},
      {
        headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
      }
    )
    .then((response: AxiosResponse<APIResponse>) => response.data)
}

const updateData = (payload: any): Promise<APIResponse | undefined> => {
  return axios
    .put(
      `${MENU_API_URL}/${payload.menu_id}/update`,
      {payload},
      {
        headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
      }
    )
    .then((response: AxiosResponse<APIResponse>) => response.data)
}

const deleteData = (
  menu_id: any,
  app_menu_id: any,
  creator_id: any,
  creator_by: any
): Promise<APIResponse | undefined> => {
  return axios
    .delete(`${MENU_API_URL}/${menu_id}/${app_menu_id}/${creator_id}/${creator_by}/delete`, {
      headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
    })
    .then((response: AxiosResponse<APIResponse>) => response.data)
}

export {fetch, get, updateData, addData, deleteData}
