import TimeAgo from 'react-timeago'
import enFormat from 'react-timeago/lib/language-strings/en'
import idFormat from 'react-timeago/lib/language-strings/id'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'

type DatetimeFormatterProps = {
  date: any
  format: string
  locale?: string | undefined
  timeago?: boolean | undefined
  timeago2?: boolean | undefined
}

const days = ['Ahad', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu']
const daysEN = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
const months = [
  'Januari',
  'Februari',
  'Maret',
  'April',
  'Mei',
  'Juni',
  'Juli',
  'Agustus',
  'September',
  'Oktober',
  'November',
  'Desember',
]
const monthsEN = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
const monthsMinimum = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'Mei',
  'Jun',
  'Jul',
  'Ags',
  'Sep',
  'Okt',
  'Nov',
  'Des',
]
const monthsMinimumEN = [
  'Jan',
  'Fen',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

function timeAgoFormat(date: Date, locale: string | undefined) {
  const curDate = new Date()
  const convDate = new Date(date)
  const formatter = locale === 'id' ? buildFormatter(idFormat) : buildFormatter(enFormat)

  return curDate.getDate() === convDate.getDate() ? (
    <TimeAgo date={convDate} formatter={formatter} />
  ) : (
    ''
  )
}

function timeAgoFormat2(date: Date, locale: string | undefined) {
  const curDate = new Date()
  const convDate = new Date(date)

  return curDate.getDate() === convDate.getDate() ? (
    <TimeAgo
      date={convDate}
      formatter={(convDate: any, unit: any, suffix: any) =>
        `${convDate}${convDate < 60 && locale === 'id' ? 'd' : 's'} ${
          convDate < 60
            ? locale === 'id'
              ? 'yang lalu'
              : 'ago'
            : locale === 'id'
              ? 'lebih dari satu menit yang lalu'
              : 'more than one minute ago'
        }`
      }
    />
  ) : (
    ''
  )
}

function dateFormatter({date, format, locale, timeago}: DatetimeFormatterProps) {
  const today = new Date(date)
  const day = locale === 'id' ? days[today.getDay()] : daysEN[today.getDay()]
  const month = locale === 'id' ? months[today.getMonth()] : monthsEN[today.getMonth()]
  const monthMinimum =
    locale === 'id' ? monthsMinimum[today.getMonth()] : monthsMinimumEN[today.getMonth()]

  switch (format) {
    case '1':
      return day + ', ' + today.getDate() + ' ' + month + ' ' + today.getFullYear()
    case '2':
      return timeago
        ? timeAgoFormat(date, locale)
        : today.getDate() + ' ' + month + ' ' + today.getFullYear()
    case '3':
      return (
        day +
        ', ' +
        today.getDate() +
        ' ' +
        month +
        ' ' +
        today.getFullYear() +
        ' @' +
        (today.getHours() < 10 ? '0' + today.getHours() : today.getHours()) +
        ':' +
        (today.getMinutes() < 10 ? '0' + today.getMinutes() : today.getMinutes()) +
        ' WIB'
      )
    case '4':
      return (
        (today.getHours() < 10 ? '0' + today.getHours() : today.getHours()) +
        ':' +
        (today.getMinutes() < 10 ? '0' + today.getMinutes() : today.getMinutes()) +
        ':' +
        (today.getSeconds() < 10 ? '0' + today.getSeconds() : today.getSeconds()) +
        ' WIB'
      )
    case '5':
      return timeAgoFormat2(date, locale)
    case '6':
      return (
        day +
        ', ' +
        today.getDate() +
        ' ' +
        monthMinimum +
        ' ' +
        today.getFullYear() +
        ' @' +
        (today.getHours() < 10 ? '0' + today.getHours() : today.getHours()) +
        ':' +
        (today.getMinutes() < 10 ? '0' + today.getMinutes() : today.getMinutes()) +
        ' WIB'
      )
    default:
      return today.toString()
  }
}

const DatetimeFormatter = ({
  date,
  format,
  locale,
  timeago,
}: DatetimeFormatterProps): JSX.Element => {
  return <span>{dateFormatter({date, format, locale, timeago})}</span>
}

export {DatetimeFormatter}
