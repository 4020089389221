import {
  initialListQueryState,
  ListQueryState,
} from 'app/modules/settings-all-module/parameter/helpers'

const PARAMETER_LIST_STATE_KEY = 'backoffice-orins-pos/settings/conditional/parameter'

const getParameterListState = () => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null | undefined = localStorage.getItem(PARAMETER_LIST_STATE_KEY)
  if (!lsValue) {
    return setParameterListState(initialListQueryState)
  }

  try {
    const state = JSON.parse(lsValue)
    if (state) {
      return state
    }
  } catch (error) {
    console.log(PARAMETER_LIST_STATE_KEY + ' STATE LOCAL STORAGE PARSE ERROR', error)
  }
}

const setParameterListState = (state: ListQueryState) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(state)
    localStorage.setItem(PARAMETER_LIST_STATE_KEY, lsValue)
  } catch (error) {
    console.error(PARAMETER_LIST_STATE_KEY + ' STATE LOCAL STORAGE SAVE ERROR', error)
  }
}

export {getParameterListState, setParameterListState, PARAMETER_LIST_STATE_KEY}
