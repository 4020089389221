import {FC, useState} from 'react'
import toast from 'react-hot-toast'
import {useQuery} from 'react-query'
import {Drawer} from 'rsuite'

import {QUERIES, isNotEmpty} from '_metronic/helpers'
import {removeAuth} from 'app/modules/auth'

import {useAction} from '../../providers'
import {get} from '../../helpers'
import {ViewDrawerForm} from '..'

type Props = {
  menuName?: any
  modalID?: any
  appMenuID: any
}

const ViewDrawerFormWrapper: FC<Props> = ({menuName, modalID, appMenuID}) => {
  const [open, setOpen] = useState(true)
  const {action, isLoading, itemIdForUpdate, setItemIdForUpdate, setIsLoading} = useAction()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {data: item, error} = useQuery(
    [`${QUERIES.POS_SETTINGS_MOBILE_LIBRARY_GET_ITEM}-${itemIdForUpdate}`],
    () => {
      setIsLoading(true)
      return get(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      refetchOnWindowFocus: false,
      onError: (err: any) => {
        if (err.response.status !== 200) {
          setIsLoading(false)
          if (err.response.status === 401) {
            toast.error(err.response.statusText)
            removeAuth()
          } else {
            toast.error(err.response.statusText)
          }
        }
      },
      onSuccess: () => {
        setIsLoading(false)
      },
    }
  )

  return (
    <Drawer
      open={open}
      onClose={() => {
        setOpen(false)
        setItemIdForUpdate(undefined)
      }}
      placement='right'
      backdrop='static'
      keyboard={action === 'view' ? true : false}
      style={{width: '100%'}}
    >
      {item === undefined && (
        <ViewDrawerForm
          menuName={menuName}
          isLoading={isLoading}
          item={{
            item_id: undefined,
            category_id: '',
            holding_id: '',
            company_id: '',
            brand_id: '',
            is_default: 'f',
            has_variant: 'f',
            is_multiple_price_sales_type: 'f',
            is_lifetime: 't',
            ppn: 0,
            price: 0,
            ppn_price: 0,
            final_price: 0,
            sales_type_price: [],
            variant_items: [],
            tmp_variant_items: [],
            is_default_parent_category: {
              category_id: '',
              name: '',
            },
            sku: '',
            status: 't',
            description: '',
          }}
          appMenuID={appMenuID}
          modalID={modalID}
        />
      )}
      {!error && item && (
        <ViewDrawerForm
          menuName={menuName}
          isLoading={isLoading}
          item={item.data}
          appMenuID={appMenuID}
          modalID={modalID}
        />
      )}
    </Drawer>
  )
}

export {ViewDrawerFormWrapper}
