import {KTCardBody} from '_metronic/helpers'
import {PaginationHelpers} from 'app/components'

import {useAction, useListQueryRequest, useListQueryResponse} from '../../providers'
import {ListTable} from '..'

const ListTableWrapper = () => {
  const {currentAccessMenu} = useAction()

  return (
    <KTCardBody className='py-0'>
      {currentAccessMenu?.view?.access_role === 'view' && (
        <>
          <ListTable />
          <PaginationHelpers
            useListQueryRequest={useListQueryRequest}
            useListQueryResponse={useListQueryResponse}
          />
        </>
      )}
    </KTCardBody>
  )
}

export {ListTableWrapper}
