/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {Dialog} from '@headlessui/react'
import clsx from 'clsx'
import {Drawer, Toggle} from 'rsuite'

import {FormLog, NotAuthRedirectForm, getFormAction, SpinnerIndicator} from 'app/components'
import {getAuth, removeAuth} from 'app/modules/auth'

import {GetDataByIDModel, addData, deleteData, initialData, updateData} from '../../helpers'
import {useAction, useListQueryResponse} from '../../providers'

type Props = {
  isLoading: boolean
  module: GetDataByIDModel
  appMenuID: any
  modalID: any
  menuName: any
}

const ViewDrawerForm: FC<Props> = ({menuName, module, isLoading, appMenuID, modalID}) => {
  const [tab, setTab] = useState('data')
  const auth = getAuth()
  const intl = useIntl()
  const {setItemIdForUpdate, action} = useAction()
  const {refetch} = useListQueryResponse()
  const [isAuth, setIsAuth] = useState(true)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [completed, setCompleted] = useState(false)

  const validationSchema = Yup.object().shape({
    name_eng: Yup.string()
      .min(3, intl.formatMessage({id: 'VALIDATION_MIN_LENGTH_FIELD'}, {min: 3}))
      .max(25, intl.formatMessage({id: 'VALIDATION_MAX_LENGTH_FIELD'}, {max: 25}))
      .required(
        intl.formatMessage(
          {id: 'VALIDATION_REQUIRED_FIELD'},
          {name: intl.formatMessage({id: 'LABEL_NAME'})}
        )
      ),
    name_idn: Yup.string()
      .min(3, intl.formatMessage({id: 'VALIDATION_MIN_LENGTH_FIELD'}, {min: 3}))
      .max(25, intl.formatMessage({id: 'VALIDATION_MAX_LENGTH_FIELD'}, {max: 25}))
      .required(
        intl.formatMessage(
          {id: 'VALIDATION_REQUIRED_FIELD'},
          {name: intl.formatMessage({id: 'LABEL_NAME'})}
        )
      ),
    path: Yup.string()
      .min(3, intl.formatMessage({id: 'VALIDATION_MIN_LENGTH_FIELD'}, {min: 3}))
      .max(75, intl.formatMessage({id: 'VALIDATION_MAX_LENGTH_FIELD'}, {max: 75}))
      .required(
        intl.formatMessage(
          {id: 'VALIDATION_REQUIRED_FIELD'},
          {name: intl.formatMessage({id: 'LABEL_DIRECTORY'})}
        )
      ),
    icon: Yup.string().required(
      intl.formatMessage(
        {id: 'VALIDATION_REQUIRED_FIELD'},
        {name: intl.formatMessage({id: 'LABEL_ICON'})}
      )
    ),
    for_order: Yup.string().required(
      intl.formatMessage(
        {id: 'VALIDATION_REQUIRED_FIELD'},
        {name: intl.formatMessage({id: 'LABEL_ORDER'})}
      )
    ),
  })

  const [moduleModels] = useState<GetDataByIDModel>({
    ...module,
    detail_eng: module.detail_eng || initialData.detail_eng,
    detail_idn: module.detail_idn || initialData.detail_idn,
    for_order: module.for_order || initialData.for_order,
    icon: module.icon || initialData.icon,
    module_id: module.module_id || initialData.module_id,
    name_eng: module.name_eng || initialData.name_eng,
    name_idn: module.name_idn || initialData.name_idn,
    path: module.path || initialData.path,
    status: module.status || initialData.status,
    menu_id: appMenuID,
  })

  const requestUpdateData = async (
    values: any,
    setStatus: any,
    setSubmitting: any,
    setFieldError: any
  ) => {
    setLoading(true)
    setStatus(null)
    setSubmitting(true)

    // request update to API
    try {
      // fix for order is number
      if (Number.isInteger(values.for_order)) {
        values.for_order = values.for_order.toString()
      }

      const res = await updateData(values)

      // throw message error
      if (res?.meta.code !== 200) {
        if (res?.meta.message === 'name eng already used') {
          setStatus(intl.formatMessage({id: 'RESPONSE_MODULE_NAME_ALREADY_USED'}))
          setFieldError('name_eng', intl.formatMessage({id: 'RESPONSE_MODULE_NAME_ALREADY_USED'}))
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
        if (res?.meta.message === 'name idn already used') {
          setStatus(intl.formatMessage({id: 'RESPONSE_MODULE_NAME_ALREADY_USED'}))
          setFieldError('name_idn', intl.formatMessage({id: 'RESPONSE_MODULE_NAME_ALREADY_USED'}))
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
        if (res?.meta.message === 'directory already used') {
          setStatus(intl.formatMessage({id: 'RESPONSE_MODULE_DIRECTORY_ALREADY_USED'}))
          setFieldError('path', intl.formatMessage({id: 'RESPONSE_MODULE_DIRECTORY_ALREADY_USED'}))
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_MODULE_EDIT_SUCCESS'}))
        setCompleted(true)
        setTimeout(function () {
          setItemIdForUpdate(undefined)
        }, 1e3)
        refetch()
      }
    } catch (error: any) {
      console.error(error)
      if (error.response.status === 401) {
        setStatus(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
        removeAuth()
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
      }
      setTimeout(function () {
        setStatus(null)
      }, 9e3)

      return false
    } finally {
      setTimeout(() => {
        setLoading(false)
        setSubmitting(false)
      }, 5e2)
    }
  }
  const requestAddData = async (
    values: any,
    setStatus: any,
    setSubmitting: any,
    setFieldError: any
  ) => {
    setLoading(true)
    setStatus(null)
    setSubmitting(true)

    // request update to API
    try {
      const res = await addData(values)

      // throw message error
      if (res?.meta.code !== 200) {
        if (res?.meta.message === 'name eng already used') {
          setStatus(intl.formatMessage({id: 'RESPONSE_MODULE_NAME_ALREADY_USED'}))
          setFieldError('name_eng', intl.formatMessage({id: 'RESPONSE_MODULE_NAME_ALREADY_USED'}))
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
        if (res?.meta.message === 'name idn already used') {
          setStatus(intl.formatMessage({id: 'RESPONSE_MODULE_NAME_ALREADY_USED'}))
          setFieldError('name_idn', intl.formatMessage({id: 'RESPONSE_MODULE_NAME_ALREADY_USED'}))
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
        if (res?.meta.message === 'directory already used') {
          setStatus(intl.formatMessage({id: 'RESPONSE_MODULE_DIRECTORY_ALREADY_USED'}))
          setFieldError('path', intl.formatMessage({id: 'RESPONSE_MODULE_DIRECTORY_ALREADY_USED'}))
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_MODULE_ADD_SUCCESS'}))
        setCompleted(true)
        setTimeout(function () {
          setItemIdForUpdate(undefined)
        }, 1e3)
        refetch()
      }
    } catch (error: any) {
      console.error(error)
      if (error.response.status === 401) {
        setStatus(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
        removeAuth()
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
      }
      setTimeout(function () {
        setStatus(null)
      }, 9e3)

      return false
    } finally {
      setTimeout(() => {
        setLoading(false)
        setSubmitting(false)
      }, 5e2)
    }
  }
  const requestDeleteData = async (values: any, setStatus: any, setSubmitting: any) => {
    setLoading(true)
    setStatus(null)
    setSubmitting(true)

    // request update to API
    try {
      const res = await deleteData(values.module_id, values.menu_id)

      // throw message error
      if (res?.meta.code !== 200) {
        if (res?.meta.message === 'module is not inactive') {
          setStatus(intl.formatMessage({id: 'RESPONSE_MODULE_IS_ACTIVE'}))
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
        if (res?.meta.message === 'module inused by other data') {
          setStatus(intl.formatMessage({id: 'RESPONSE_MODULE_IS_INUSED'}))
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_MODULE_DELETE_SUCCESS'}))
        setCompleted(true)
        setTimeout(function () {
          setItemIdForUpdate(undefined)
        }, 1e3)
        refetch()
      }
    } catch (error: any) {
      console.error(error)
      if (error.response.status === 401) {
        setStatus(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
        removeAuth()
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
      }
      setSubmitting(false)
      setLoading(false)
      setTimeout(function () {
        setStatus(null)
      }, 9e3)

      return false
    } finally {
      setTimeout(() => {
        setLoading(false)
        setSubmitting(false)
      }, 5e2)
    }
  }
  const formik = useFormik({
    initialValues: moduleModels,
    validationSchema: validationSchema,
    onSubmit: async (values, {setSubmitting, setStatus, setFieldError}) => {
      setIsDialogOpen(false)
      action === 'edit'
        ? requestUpdateData(values, setStatus, setSubmitting, setFieldError)
        : action === 'add'
          ? requestAddData(values, setStatus, setSubmitting, setFieldError)
          : requestDeleteData(values, setStatus, setSubmitting)
    },
    onReset: (values) => {
      values = moduleModels
    },
  })

  useEffect(() => {
    auth === undefined && setIsAuth(false)
  }, [auth])

  return (
    <>
      {!isAuth && <NotAuthRedirectForm />}

      <Drawer.Header>
        <Drawer.Title className='fw-bolder'>
          {isLoading ? (
            <SpinnerIndicator label={intl.formatMessage({id: 'BUTTON_LABEL_PLEASE_WAIT'})} />
          ) : (
            <>
              {getFormAction(
                action,
                intl.formatMessage({id: 'BUTTON_LABEL_VIEW'}),
                intl.formatMessage({id: 'BUTTON_LABEL_EDIT'}),
                intl.formatMessage({id: 'BUTTON_LABEL_ADD'}),
                intl.formatMessage({id: 'BUTTON_LABEL_DELETE'})
              )}{' '}
              {menuName}
            </>
          )}
        </Drawer.Title>
        <Drawer.Actions>
          {action !== 'view' && moduleModels !== formik.values && !completed && (
            <button
              type='reset'
              className='btn btn-light me-3'
              data-kt-users-modal-action='reset'
              onClick={formik.handleReset}
              disabled={loading}
            >
              {!loading && (
                <span className='indicator-label'>
                  {intl.formatMessage({id: 'BUTTON_LABEL_RESET'})}
                </span>
              )}
            </button>
          )}

          {action !== 'view' && moduleModels !== formik.values && !completed && (
            <button
              type='submit'
              className='btn btn-primary'
              data-kt-users-modal-action='submit'
              onClick={() => {
                setIsDialogOpen(true)
                setLoading(true)
                setTimeout(function () {
                  setLoading(false)
                }, 2e3)
              }}
              disabled={isLoading || loading || formik.isSubmitting || !formik.isValid}
            >
              {!loading && (
                <span className='indicator-label'>
                  {intl.formatMessage({id: 'BUTTON_LABEL_SUBMIT'})}
                </span>
              )}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {intl.formatMessage({id: 'BUTTON_LABEL_SUBMITTING'})}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          )}

          {action === 'delete' && !completed && (
            <button
              type='submit'
              className='btn btn-danger'
              data-kt-users-modal-action='submit'
              onClick={() => formik.handleSubmit()}
              disabled={isLoading || loading || formik.isSubmitting || !formik.isValid}
            >
              {!loading && (
                <span className='indicator-label'>
                  {intl.formatMessage({id: 'BUTTON_LABEL_DELETE'})}
                </span>
              )}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {intl.formatMessage({id: 'BUTTON_LABEL_SUBMITTING'})}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          )}
        </Drawer.Actions>
      </Drawer.Header>

      <Drawer.Body style={{padding: 0}}>
        {/* Response Message */}
        {formik.status &&
          (formik.status === intl.formatMessage({id: 'RESPONSE_MODULE_ADD_SUCCESS'}) ||
          formik.status === intl.formatMessage({id: 'RESPONSE_MODULE_EDIT_SUCCESS'}) ||
          formik.status === intl.formatMessage({id: 'RESPONSE_MODULE_DELETE_SUCCESS'}) ? (
            <div className='m-lg-5 p-2 bg-light-success rounded'>
              <div className='text-success text-center font-weight-bold fw-bolder'>
                {formik.status}
              </div>
            </div>
          ) : (
            <div className={`m-lg-5 p-2 alert alert-danger`}>
              <div className='alert-text text-center font-weight-bold fw-bolder'>
                {formik.status}
              </div>
            </div>
          ))}
        {/* Response Message */}

        {/* Form */}
        <div className={`modal-body m-5 ${action === 'add' ? '' : ' mt-0'}`}>
          {action === 'add' ? (
            <form id={`${modalID}-form`} className='form' onSubmit={formik.handleSubmit} noValidate>
              <div className='row'>
                {/* Left Form */}
                <div className='col-lg-6'>
                  {/* Name */}
                  <div className='fv-row mb-5'>
                    {formik.values.name_eng !== '' && (
                      <label className='form-label fs-6 fw-bolder text-dark required'>
                        {intl.formatMessage({id: 'LABEL_NAME'})}
                        {' (ENG)'}
                      </label>
                    )}
                    <input
                      type='text'
                      className={clsx(
                        'form-control form-control-solid',
                        {'is-invalid': formik.touched.name_eng && formik.errors.name_eng},
                        {
                          'is-valid': formik.touched.name_eng && !formik.errors.name_eng,
                        }
                      )}
                      placeholder={intl.formatMessage({id: 'LABEL_NAME'}) + ' (ENG)'}
                      {...formik.getFieldProps('name_eng')}
                    />
                    {formik.touched.name_eng && formik.errors.name_eng ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger fw-bolder'>
                          {formik.errors.name_eng}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className='fv-row mb-5'>
                    {formik.values.name_idn !== '' && (
                      <label className='form-label fs-6 fw-bolder text-dark required'>
                        {intl.formatMessage({id: 'LABEL_NAME'})}
                        {' (IDN)'}
                      </label>
                    )}
                    <input
                      type='text'
                      className='form-control form-control-solid'
                      placeholder={intl.formatMessage({id: 'LABEL_NAME'}) + ' (IDN)'}
                      {...formik.getFieldProps('name_idn')}
                    />
                    {formik.touched.name_idn && formik.errors.name_idn ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger fw-bolder'>
                          {formik.errors.name_idn}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {/* Name */}

                  {/* Directory */}
                  <div className='fv-row mb-5'>
                    {formik.values.path !== '' && (
                      <label className='form-label fs-6 fw-bolder text-dark required'>
                        {intl.formatMessage({id: 'LABEL_DIRECTORY'})}
                      </label>
                    )}
                    <input
                      type='text'
                      className='form-control form-control-solid'
                      placeholder={intl.formatMessage({id: 'LABEL_DIRECTORY'})}
                      {...formik.getFieldProps('path')}
                      onChange={(e) => {
                        formik.setFieldValue('path', e.target.value.toLowerCase())
                      }}
                    />
                    {formik.touched.path && formik.errors.path ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger fw-bolder'>
                          {formik.errors.path}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {/* Directory */}

                  {/* Icon */}
                  <div className='fv-row mb-5'>
                    {formik.values.icon !== '' && (
                      <label className='form-label fs-6 fw-bolder text-dark required'>
                        {intl.formatMessage({id: 'LABEL_ICON'})}
                      </label>
                    )}
                    <input
                      type='text'
                      className='form-control form-control-solid'
                      placeholder={intl.formatMessage({id: 'LABEL_ICON'})}
                      {...formik.getFieldProps('icon')}
                    />
                    {formik.touched.icon && formik.errors.icon ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger fw-bolder'>
                          {formik.errors.icon}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {/* Icon */}

                  {/* Order */}
                  <div className='fv-row mb-5'>
                    {formik.values.for_order !== '' && (
                      <label className='form-label fs-6 fw-bolder text-dark required'>
                        {intl.formatMessage({id: 'LABEL_ORDER'})}
                      </label>
                    )}
                    <input
                      type='number'
                      className='form-control form-control-solid'
                      placeholder={intl.formatMessage({id: 'LABEL_ORDER'})}
                      {...formik.getFieldProps('for_order')}
                      onChange={(e) => {
                        formik.setFieldValue('for_order', e.target.value)
                      }}
                    />
                    {formik.touched.for_order && formik.errors.for_order ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger fw-bolder'>
                          {formik.errors.for_order}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {/* Order */}
                </div>
                {/* Left Form */}

                {/* Right Form */}
                <div className='col-lg-6'>
                  {/* Description */}
                  <div className='fv-row mb-5'>
                    {formik.values.detail_eng !== '' && (
                      <label className='form-label fs-6 fw-bolder text-dark'>
                        {intl.formatMessage({id: 'LABEL_DESCRIPTION'})}
                        {' (ENG)'}
                      </label>
                    )}
                    <textarea
                      className='form-control form-control-solid'
                      placeholder={intl.formatMessage({id: 'LABEL_DESCRIPTION'}) + ' (ENG)'}
                      {...formik.getFieldProps('detail_eng')}
                      rows={3}
                    />
                  </div>
                  <div className='fv-row mb-5'>
                    {formik.values.detail_idn !== '' && (
                      <label className='form-label fs-6 fw-bolder text-dark'>
                        {intl.formatMessage({id: 'LABEL_DESCRIPTION'})}
                        {' (IDN)'}
                      </label>
                    )}
                    <textarea
                      className='form-control form-control-solid'
                      placeholder={intl.formatMessage({id: 'LABEL_DESCRIPTION'}) + ' (IDN)'}
                      {...formik.getFieldProps('detail_idn')}
                      rows={3}
                    />
                  </div>
                  {/* Description */}

                  {/* Status */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark required'>Status</label>
                    <div className='form-check form-switch form-check-custom form-check-solid mt-1'>
                      <Toggle
                        size='lg'
                        checkedChildren={intl.formatMessage({id: 'LABEL_ACTIVE'})}
                        unCheckedChildren={intl.formatMessage({id: 'LABEL_INACTIVE'})}
                        checked={formik.values.status === 't' ? true : false}
                        onChange={() =>
                          formik.setFieldValue('status', formik.values.status === 't' ? 'f' : 't')
                        }
                      />
                    </div>
                  </div>
                  {/* Status */}
                </div>
                {/* Right Form */}
              </div>
            </form>
          ) : (
            <form id={`${modalID}-form`} className='form' onSubmit={formik.handleSubmit} noValidate>
              {/* Tab Header */}
              <div className='card-toolbar'>
                <ul
                  className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-2 mt-2'
                  role='tablist'
                >
                  <li className={`nav-item ${tab === 'data' ? 'fw-bolder' : ''}`}>
                    <a
                      className={clsx(`nav-link cursor-pointer`, {
                        active: tab === 'data',
                      })}
                      onClick={() => setTab('data')}
                      role='tab'
                    >
                      Data
                    </a>
                  </li>
                  <li className={`nav-item ${tab === 'log' ? 'fw-bolder' : ''}`}>
                    <a
                      className={clsx(`nav-link cursor-pointer`, {
                        active: tab === 'log',
                      })}
                      onClick={() => setTab('log')}
                      role='tab'
                    >
                      Log
                    </a>
                  </li>
                </ul>
              </div>
              {/* Tab Header */}

              {/* Tab Body */}
              <div className='card-body p-0 pt-2'>
                <div className='tab-content'>
                  {/* Data */}
                  <div
                    className={clsx('tab-pane', {
                      active: tab === 'data',
                    })}
                  >
                    <div className='row'>
                      {/* Left Form */}
                      <div className='col-lg-6'>
                        {/* Name */}
                        <div className='fv-row mb-5'>
                          {formik.values.name_eng !== '' && (
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action === 'edit' ? 'required' : ''
                              }`}
                            >
                              {intl.formatMessage({id: 'LABEL_NAME'})}
                              {' (ENG)'}
                            </label>
                          )}
                          <input
                            type='text'
                            className={clsx(
                              'form-control form-control-solid',
                              {'is-invalid': formik.touched.name_eng && formik.errors.name_eng},
                              {
                                'is-valid': formik.touched.name_eng && !formik.errors.name_eng,
                              }
                            )}
                            placeholder={intl.formatMessage({id: 'LABEL_NAME'}) + ' (ENG)'}
                            {...formik.getFieldProps('name_eng')}
                            readOnly={action === 'edit' ? false : true}
                          />
                          {formik.touched.name_eng && formik.errors.name_eng ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {formik.errors.name_eng}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        <div className='fv-row mb-5'>
                          {formik.values.name_idn !== '' && (
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action === 'edit' ? 'required' : ''
                              }`}
                            >
                              {intl.formatMessage({id: 'LABEL_NAME'})}
                              {' (IDN)'}
                            </label>
                          )}
                          <input
                            type='text'
                            className={clsx(
                              'form-control form-control-solid',
                              {'is-invalid': formik.touched.name_idn && formik.errors.name_idn},
                              {
                                'is-valid': formik.touched.name_idn && !formik.errors.name_idn,
                              }
                            )}
                            placeholder={intl.formatMessage({id: 'LABEL_NAME'}) + ' (IDN)'}
                            {...formik.getFieldProps('name_idn')}
                            readOnly={action === 'edit' ? false : true}
                          />
                          {formik.touched.name_idn && formik.errors.name_idn ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {formik.errors.name_idn}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Name */}

                        {/* Directory */}
                        <div className='fv-row mb-5'>
                          {formik.values.path !== '' && (
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action === 'edit' ? 'required' : ''
                              }`}
                            >
                              {intl.formatMessage({id: 'LABEL_DIRECTORY'})}
                            </label>
                          )}
                          <input
                            type='text'
                            className={clsx(
                              'form-control form-control-solid',
                              {'is-invalid': formik.touched.path && formik.errors.path},
                              {
                                'is-valid': formik.touched.path && !formik.errors.path,
                              }
                            )}
                            placeholder={intl.formatMessage({id: 'LABEL_DIRECTORY'})}
                            {...formik.getFieldProps('path')}
                            readOnly={action === 'edit' ? false : true}
                            onChange={(e) => {
                              formik.setFieldValue('path', e.target.value.toLowerCase())
                            }}
                          />
                          {formik.touched.path && formik.errors.path ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {formik.errors.path}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Directory */}

                        {/* Icon */}
                        <div className='fv-row mb-5'>
                          {formik.values.icon !== '' && (
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action === 'edit' ? 'required' : ''
                              }`}
                            >
                              {intl.formatMessage({id: 'LABEL_ICON'})}
                            </label>
                          )}
                          <input
                            type='text'
                            className={clsx(
                              'form-control form-control-solid',
                              {'is-invalid': formik.touched.icon && formik.errors.icon},
                              {
                                'is-valid': formik.touched.icon && !formik.errors.icon,
                              }
                            )}
                            placeholder={intl.formatMessage({id: 'LABEL_ICON'})}
                            {...formik.getFieldProps('icon')}
                            readOnly={action === 'edit' ? false : true}
                          />
                          {formik.touched.icon && formik.errors.icon ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {formik.errors.icon}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Icon */}

                        {/* Order */}
                        <div className='fv-row mb-5'>
                          {formik.values.for_order !== '' && (
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action === 'edit' ? 'required' : ''
                              }`}
                            >
                              {intl.formatMessage({id: 'LABEL_ORDER'})}
                            </label>
                          )}
                          <input
                            type={`${action === 'edit' ? 'number' : 'text'}`}
                            className={clsx(
                              'form-control form-control-solid',
                              {'is-invalid': formik.touched.for_order && formik.errors.for_order},
                              {
                                'is-valid': formik.touched.for_order && !formik.errors.for_order,
                              }
                            )}
                            placeholder={intl.formatMessage({id: 'LABEL_ORDER'})}
                            {...formik.getFieldProps('for_order')}
                            readOnly={action === 'edit' ? false : true}
                            onChange={(e) => {
                              formik.setFieldValue('for_order', e.target.value)
                            }}
                          />
                          {formik.touched.for_order && formik.errors.for_order ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {formik.errors.for_order}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Order */}
                      </div>
                      {/* Left Form */}

                      {/* Right Form */}
                      <div className='col-lg-6'>
                        {/* Description */}
                        <div className='fv-row mb-5'>
                          {formik.values.detail_eng !== '' && (
                            <label className='form-label fs-6 fw-bolder text-dark'>
                              {intl.formatMessage({id: 'LABEL_DESCRIPTION'})}
                              {' (ENG)'}
                            </label>
                          )}
                          <textarea
                            className={clsx(
                              'form-control form-control-solid',
                              {'is-invalid': formik.touched.detail_eng && formik.errors.detail_eng},
                              {
                                'is-valid': formik.touched.detail_eng && !formik.errors.detail_eng,
                              }
                            )}
                            placeholder={intl.formatMessage({id: 'LABEL_DESCRIPTION'}) + ' (ENG)'}
                            {...formik.getFieldProps('detail_eng')}
                            readOnly={action === 'edit' ? false : true}
                            rows={3}
                          />
                        </div>
                        <div className='fv-row mb-5'>
                          {formik.values.detail_idn !== '' && (
                            <label className='form-label fs-6 fw-bolder text-dark'>
                              {intl.formatMessage({id: 'LABEL_DESCRIPTION'})}
                              {' (IDN)'}
                            </label>
                          )}
                          <textarea
                            className={clsx(
                              'form-control form-control-solid',
                              {'is-invalid': formik.touched.detail_idn && formik.errors.detail_idn},
                              {
                                'is-valid': formik.touched.detail_idn && !formik.errors.detail_idn,
                              }
                            )}
                            placeholder={intl.formatMessage({id: 'LABEL_DESCRIPTION'}) + ' (IDN)'}
                            {...formik.getFieldProps('detail_idn')}
                            readOnly={action === 'edit' ? false : true}
                            rows={3}
                          />
                        </div>
                        {/* Description */}

                        {/* Status */}
                        <div className='fv-row mb-5'>
                          {formik.values.status !== '' && (
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action === 'edit' ? 'required' : ''
                              }`}
                            >
                              Status
                            </label>
                          )}
                          <div className='form-check form-switch form-check-custom form-check-solid mt-1'>
                            <Toggle
                              size='lg'
                              readOnly={action !== 'edit' ? true : false}
                              checkedChildren={intl.formatMessage({id: 'LABEL_ACTIVE'})}
                              unCheckedChildren={intl.formatMessage({id: 'LABEL_INACTIVE'})}
                              checked={formik.values.status === 't' ? true : false}
                              onChange={() =>
                                formik.setFieldValue(
                                  'status',
                                  formik.values.status === 't' ? 'f' : 't'
                                )
                              }
                            />
                          </div>
                          {formik.touched.status && formik.errors.status ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {formik.errors.status}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Status */}
                      </div>
                      {/* Right Form */}
                    </div>
                  </div>
                  {/* Data */}

                  {/* Detail */}
                  <div
                    className={clsx('tab-pane', {
                      active: tab === 'log',
                    })}
                  >
                    <FormLog payload={module} formik={formik} />
                  </div>
                  {/* Detail */}
                </div>
              </div>
              {/* Tab Body */}
            </form>
          )}
        </div>
        {/* Form */}
      </Drawer.Body>

      {/* Dialog */}
      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(true)}
        className='modal fade show d-block'
      >
        <Dialog.Panel className='modal-dialog modal-fullscreen'>
          <div className='modal-content'>
            <Dialog.Title className='modal-header'>
              {intl.formatMessage({id: 'DIALOG_HEADER_TITLE'})}
            </Dialog.Title>
            <div className='modal-body scroll-y mx-2 mx-xl-2 my-2'>
              {/* Name */}
              {moduleModels.name_eng !== formik.values.name_eng && (
                <div className='fv-row mb-5'>
                  <label className='form-label fs-6 fw-bolder text-dark'>
                    {intl.formatMessage({id: 'LABEL_NAME'})}
                    {' (ENG)'}
                  </label>
                  {action !== 'add' && (
                    <input
                      type='text'
                      className='form-control form-control-solid'
                      value={moduleModels.name_eng}
                      readOnly
                    />
                  )}
                  <input
                    type='text'
                    className='form-control form-control-solid text-primary is-valid'
                    value={formik.values.name_eng}
                    readOnly
                  />
                </div>
              )}

              {moduleModels.name_idn !== formik.values.name_idn && (
                <div className='fv-row mb-5'>
                  <label className='form-label fs-6 fw-bolder text-dark'>
                    {intl.formatMessage({id: 'LABEL_NAME'})}
                    {' (IDN)'}
                  </label>
                  {action !== 'add' && (
                    <input
                      type='text'
                      className='form-control form-control-solid'
                      value={moduleModels.name_idn}
                      readOnly
                    />
                  )}
                  <input
                    type='text'
                    className='form-control form-control-solid text-primary is-valid'
                    value={formik.values.name_idn}
                    readOnly
                  />
                </div>
              )}
              {/* Name */}

              {/* Path */}
              {moduleModels.path !== formik.values.path && (
                <div className='fv-row mb-5'>
                  <label className='form-label fs-6 fw-bolder text-dark'>
                    {intl.formatMessage({id: 'LABEL_DIRECTORY'})}
                  </label>
                  {action !== 'add' && (
                    <input
                      type='text'
                      className='form-control form-control-solid'
                      value={moduleModels.path}
                      readOnly
                    />
                  )}
                  <input
                    type='text'
                    className='form-control form-control-solid text-primary is-valid'
                    value={formik.values.path}
                    readOnly
                  />
                </div>
              )}
              {/* Path */}

              {/* Icon */}
              {moduleModels.icon !== formik.values.icon && (
                <div className='fv-row mb-5'>
                  <label className='form-label fs-6 fw-bolder text-dark'>
                    {intl.formatMessage({id: 'LABEL_ICON'})}
                  </label>
                  {action !== 'add' && (
                    <input
                      type='text'
                      className='form-control form-control-solid'
                      value={moduleModels.icon}
                      readOnly
                    />
                  )}
                  <input
                    type='text'
                    className='form-control form-control-solid text-primary is-valid'
                    value={formik.values.icon}
                    readOnly
                  />
                </div>
              )}
              {/* Icon */}

              {/* Order */}
              {moduleModels.for_order !== formik.values.for_order && (
                <div className='fv-row mb-5'>
                  <label className='form-label fs-6 fw-bolder text-dark'>
                    {intl.formatMessage({id: 'LABEL_ORDER'})}
                  </label>
                  {action !== 'add' && (
                    <input
                      type='text'
                      className='form-control form-control-solid'
                      value={moduleModels.for_order}
                      readOnly
                    />
                  )}
                  <input
                    type='text'
                    className='form-control form-control-solid text-primary is-valid'
                    value={formik.values.for_order}
                    readOnly
                  />
                </div>
              )}
              {/* Order */}

              {/* Status */}
              {action === 'add' ? (
                <div className='fv-row mb-5'>
                  <label className='form-label fs-6 fw-bolder text-dark'>Status</label>
                  <input
                    type='text'
                    className='form-control form-control-solid text-primary is-valid'
                    value={
                      formik.values.status === 't'
                        ? intl.formatMessage({id: 'LABEL_ACTIVE'})
                        : intl.formatMessage({id: 'LABEL_INACTIVE'})
                    }
                    readOnly
                  />
                </div>
              ) : (
                moduleModels.status !== formik.values.status && (
                  <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark'>Status</label>
                    <input
                      type='text'
                      className='form-control form-control-solid'
                      value={
                        moduleModels.status === 't'
                          ? intl.formatMessage({id: 'LABEL_ACTIVE'})
                          : intl.formatMessage({id: 'LABEL_INACTIVE'})
                      }
                      readOnly
                    />
                    <input
                      type='text'
                      className='form-control form-control-solid text-primary is-valid'
                      value={
                        formik.values.status === 't'
                          ? intl.formatMessage({id: 'LABEL_ACTIVE'})
                          : intl.formatMessage({id: 'LABEL_INACTIVE'})
                      }
                      readOnly
                    />
                  </div>
                )
              )}
              {/* Status */}

              {/* Description */}
              {moduleModels.detail_eng !== formik.values.detail_eng && (
                <div className='fv-row mb-5'>
                  <label className='form-label fs-6 fw-bolder text-dark'>
                    {intl.formatMessage({id: 'LABEL_DESCRIPTION'})}
                    {' (ENG)'}
                  </label>
                  {action !== 'add' && (
                    <textarea
                      className='form-control form-control-solid'
                      value={moduleModels.detail_eng}
                      readOnly
                    />
                  )}
                  <textarea
                    className='form-control form-control-solid text-primary is-valid'
                    value={formik.values.detail_eng}
                    readOnly
                  />
                </div>
              )}
              {moduleModels.detail_idn !== formik.values.detail_idn && (
                <div className='fv-row mb-5'>
                  <label className='form-label fs-6 fw-bolder text-dark'>
                    {intl.formatMessage({id: 'LABEL_DESCRIPTION'})}
                    {' (IDN)'}
                  </label>
                  {action !== 'add' && (
                    <textarea
                      className='form-control form-control-solid'
                      value={moduleModels.detail_idn}
                      readOnly
                    />
                  )}
                  <textarea
                    className='form-control form-control-solid text-primary is-valid'
                    value={formik.values.detail_idn}
                    readOnly
                  />
                </div>
              )}
              {/* Description */}
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-outline btn-btn-outline-info me-3'
                data-kt-users-modal-action='close'
                onClick={() => setIsDialogOpen(false)}
              >
                {intl.formatMessage({id: 'BUTTON_LABEL_BACK'})}
              </button>

              <button
                type='button'
                className={`btn ${loading ? 'btn-secondary' : 'btn-success'}`}
                data-kt-users-modal-action='submit'
                onClick={() => formik.handleSubmit()}
                disabled={loading ? true : false}
              >
                {!loading && (
                  <span className='indicator-label'>
                    {intl.formatMessage({id: 'BUTTON_LABEL_SUBMIT'})}
                  </span>
                )}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    {intl.formatMessage({id: 'BUTTON_LABEL_PLEASE_WAIT'})}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
      {/* Dialog */}
    </>
  )
}

export {ViewDrawerForm}
