import {isMobileDevice} from '_metronic/assets/ts/_utils'
import {useLang} from '_metronic/i18n/Metronici18n'
import {DatetimeFormatter, MyClock} from 'app/components'

const DatetimeString = () => {
  const lang = useLang()
  return (
    <div
      className={`d-flex flex-column align-items-start px-5 ${isMobileDevice() ? 'm-4' : 'mt-4'}`}
    >
      <h5 className='mb-1 text-primary fw-bolder'>
        <DatetimeFormatter date={MyClock().props.value} format='1' locale={lang} timeago={false} />
      </h5>
      <span className='mb-1 text-muted fw-bolder'>
        <DatetimeFormatter date={MyClock().props.value} format='4' locale={lang} timeago={false} />
      </span>
    </div>
  )
}

export {DatetimeString}
