import {FC, useEffect, useState} from 'react'

import {NotAuthRedirectForm} from 'app/components'
import {getAuth, useAuth} from 'app/modules/auth'

import {GetDataByIDModel} from '../../helpers'

type Props = {
  log: GetDataByIDModel
  modalID: any
}

const ViewModalForm: FC<Props> = ({log, modalID}) => {
  const auth = getAuth()
  const {currentUser} = useAuth()
  const [isAuth, setIsAuth] = useState(true)

  useEffect(() => {
    auth === undefined && setIsAuth(false)
  }, [auth])

  return (
    <>
      {!isAuth && <NotAuthRedirectForm />}

      {/* Form */}
      <form id={`${modalID}-form`} className='form' noValidate>
        <div
          className='d-flex flex-column scroll-y me-n2 pe-2'
          id={`body-${modalID}`}
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies={`#${modalID}`}
          data-kt-scroll-wrappers={`#body-${modalID}`}
          data-kt-scroll-offset='300px'
        >
          <div className='row mb-6'>
            {log.activity === 'activation' ||
            log.activity === 'reset password' ||
            log.activity === 'update password' ? (
              currentUser?.username === 'developer' ? (
                <pre>{JSON.stringify(log, null, 2)}</pre>
              ) : (
                '-'
              )
            ) : (
              <pre>{JSON.stringify(log, null, 2)}</pre>
            )}
          </div>
        </div>
      </form>
      {/* Form */}
    </>
  )
}

export {ViewModalForm}
