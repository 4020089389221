import {FC, Suspense} from 'react'
import TopBarProgress from 'react-topbar-progress-indicator'

import {getCSSVariableValue} from '_metronic/assets/ts/_utils'
import {WithChildren} from '_metronic/helpers'
import {useThemeMode} from '_metronic/partials'

const SuspendViewComponents: FC<WithChildren> = ({children}) => {
  const {mode} = useThemeMode()
  const baseColor = getCSSVariableValue(`--bs-${mode === 'light' ? 'info' : 'primary'}`)

  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 3,
    shadowBlur: 2,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {SuspendViewComponents}
