import axios from 'axios'

const USER_URL = process.env.REACT_APP_USER_API_URL

export const LOGIN_URL = `${USER_URL}/auth`
export const GET_USER_BY_ACCESSTOKEN_URL = `${USER_URL}/auth`
export const FORGOT_PASSWORD_URL = `${USER_URL}/auth/forgot-password`
export const RESET_PASSWORD_URL = `${USER_URL}/auth/reset-password`
export const ACTIVATION_URL = `${USER_URL}/auth/activation`

export function login(email: string, password: string) {
  return axios.post<any>(LOGIN_URL, {
    email,
    password,
  })
}

export function getUserByToken() {
  return axios.get<any>(GET_USER_BY_ACCESSTOKEN_URL)
}

export function requestPassword(email: string) {
  return axios.post<any>(FORGOT_PASSWORD_URL, {
    email,
  })
}

export function resetPassword(token: string | undefined, password: string) {
  return axios.post<any>(RESET_PASSWORD_URL, {
    token,
    password,
  })
}

export function activation(token: string | undefined, password: string) {
  return axios.post<any>(ACTIVATION_URL, {
    token,
    password,
  })
}
