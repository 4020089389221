import {DistrictID, ListResponse} from '.'

export type ListModel = {
  district_id?: string
  name?: string
  status?: string
  city?: string
}

export type ListQueryResponse = ListResponse<Array<ListModel>>

export const initialList: ListModel = {
  district_id: '',
  name: '',
  status: '',
  city: '',
}

export type CityModel = {
  city_id: string
  name: string
}

export type DetailModel = {
  district_id?: DistrictID
  city_id?: string
  city_name?: string
  name?: string
  status?: string
  city?: CityModel
  created_at?: string
  created_by?: string
  updated_at?: string
  updated_by?: string
  menu_id?: string // modified payload for crud
  creator_id?: string // modified payload for crud
  creator_by?: string // modified payload for crud
}

export const initialData: DetailModel = {
  district_id: '',
  city_id: '',
  city_name: '',
  name: '',
  status: '',
  city: {
    city_id: '',
    name: '',
  },
  created_at: '',
  created_by: '',
  updated_at: '',
  updated_by: '',
  menu_id: '', // modified payload for crud
  creator_id: '', // modified payload for crud
  creator_by: '', // modified payload for crud
}
