import {FC} from 'react'
import {useIntl} from 'react-intl'

import {useAuth} from 'app/modules/auth'

type Props = {
  isLoading?: any
}
const NotAuthRedirectForm: FC<Props> = ({isLoading}) => {
  const intl = useIntl()
  const {logout} = useAuth()

  return (
    <>
      <div className='modal fade show d-block' tabIndex={-1} aria-hidden='true'>
        <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable'>
          <div className='modal-content bg-white border border-the-danger'>
            <div className='modal-header justify-content-between'>
              <h1>
                <span>⛔️&nbsp;</span>
              </h1>
              <h2>
                <span className='text-muted'>
                  {intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'})}
                </span>
              </h2>
            </div>
            <div className='modal-body text-center py-4'>
              <p>
                <span className='text-muted'>
                  {intl.formatMessage({id: 'LABEL_UNAUTHORIZED_SESSION'})}
                </span>
              </p>
              <button
                type='button'
                onClick={() => logout()}
                className='btn btn-primary px-5'
                data-dismiss='modal'
              >
                {intl.formatMessage({id: 'BUTTON_LABEL_RESTART_SESSION'})}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {NotAuthRedirectForm}
