import {FC, useState} from 'react'
import toast from 'react-hot-toast'
import {useQuery} from 'react-query'
import {Drawer} from 'rsuite'

import {QUERIES, isNotEmpty} from '_metronic/helpers'
import {isMobileDevice} from '_metronic/assets/ts/_utils'
import {removeAuth} from 'app/modules/auth'
import {useUserGroupModules} from 'app/context/providers'

import {useAction} from '../../providers'
import {getDataByID} from '../../helpers'
import {ViewDrawerForm} from '..'

type Props = {
  menuName?: any
  modalID?: any
  appMenuID: any
}

const ViewDrawerFormWrapper: FC<Props> = ({menuName, modalID, appMenuID}) => {
  const mobile = isMobileDevice()
  const [open, setOpen] = useState(true)
  const {isLoading, itemIdForUpdate, setItemIdForUpdate, setIsLoading} = useAction()
  const {currentModulePath} = useUserGroupModules()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {data: user, error} = useQuery(
    [`${currentModulePath}-${QUERIES.GET_USER}-${itemIdForUpdate}`],
    () => {
      setIsLoading(true)
      return getDataByID(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      refetchOnWindowFocus: false,
      onError: (err: any) => {
        if (err.response.status !== 200) {
          setIsLoading(false)
          if (err.response.status === 401) {
            toast.error(err.response.statusText)
            removeAuth()
          } else {
            toast.error(err.response.statusText)
          }
        }
      },
      onSuccess: () => {
        setIsLoading(false)
      },
    }
  )

  return (
    // <Offcanvas
    //   show={open}
    //   onHide={() => {
    //     setOpen(false)
    //     setItemIdForUpdate(undefined)
    //   }}
    //   placement='end'
    //   backdrop='static'
    //   key={modalID}
    //   style={{width: mobile ? '100%' : '50%'}}
    // >
    <Drawer
      open={open}
      onClose={() => {
        setOpen(false)
        setItemIdForUpdate(undefined)
      }}
      placement='right'
      // size='xs'
      style={{width: mobile ? '100%' : '50%'}}
    >
      {/* <ViewDrawerHeader menuName={menuName} /> */}
      {!error && user && (
        <ViewDrawerForm
          menuName={menuName}
          isLoading={isLoading}
          user={user.data}
          appMenuID={appMenuID}
          modalID={modalID}
        />
      )}
    </Drawer>
  )
}

export {ViewDrawerFormWrapper}
