import {CityID, ListResponse} from '.'

export type ListModel = {
  city_id?: string
  name?: string
  status?: string
  province?: string
}

export type ListQueryResponse = ListResponse<Array<ListModel>>

export const initialList: ListModel = {
  city_id: '',
  name: '',
  status: '',
  province: '',
}

export type ProvinceModel = {
  province_id: string
  name: string
}

export type DetailModel = {
  city_id?: CityID
  province_id?: string
  province_name?: string
  name?: string
  status?: string
  province?: ProvinceModel
  created_at?: string
  created_by?: string
  updated_at?: string
  updated_by?: string
  menu_id?: string // modified payload for crud
  creator_id?: string // modified payload for crud
  creator_by?: string // modified payload for crud
}

export const initialData: DetailModel = {
  city_id: '',
  province_id: '',
  province_name: '',
  name: '',
  status: '',
  province: {
    province_id: '',
    name: '',
  },
  created_at: '',
  created_by: '',
  updated_at: '',
  updated_by: '',
  menu_id: '', // modified payload for crud
  creator_id: '', // modified payload for crud
  creator_by: '', // modified payload for crud
}
