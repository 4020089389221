import {FC} from 'react'
import {useIntl} from 'react-intl'

import {datetimeFormatter} from 'app/components'

import {BaseLogPayload} from './_model_form_log'

type Props = {
  payload: BaseLogPayload
  formik: any
}

const FormLog: FC<Props> = ({payload, formik}) => {
  const intl = useIntl()

  return (
    <div className='row'>
      {/* Left Form */}
      <div className='col-lg-6'>
        {/* Created At */}
        <div className='fv-row mb-5'>
          <label className='form-label fs-6 fw-bolder text-dark'>
            {intl.formatMessage({id: 'LABEL_CREATED_AT'})}
          </label>
          <input
            type='text'
            className='form-control form-control-solid'
            placeholder={intl.formatMessage({
              id: 'LABEL_CREATED_AT',
            })}
            {...formik.getFieldProps('created_at')}
            value={datetimeFormatter(payload.created_at, '1', intl.locale)}
            readOnly
          />
        </div>
        {/* Created At */}

        {/* Created By */}
        <div className='fv-row mb-5'>
          <label className='form-label fs-6 fw-bolder text-dark'>
            {intl.formatMessage({id: 'LABEL_CREATED_BY'})}
          </label>
          <input
            type='text'
            className='form-control form-control-solid'
            placeholder={intl.formatMessage({
              id: 'LABEL_CREATED_BY',
            })}
            {...formik.getFieldProps('created_by')}
            readOnly
          />
        </div>
        {/* Created By */}
      </div>
      {/* Left Form */}

      {/* Right Form */}
      <div className='col-lg-6'>
        {/* Updated At */}
        <div className='fv-row mb-5'>
          <label className='form-label fs-6 fw-bolder text-dark'>
            {intl.formatMessage({id: 'LABEL_UPDATED_AT'})}
          </label>
          <input
            type='text'
            className='form-control form-control-solid'
            placeholder={intl.formatMessage({
              id: 'LABEL_UPDATED_AT',
            })}
            {...formik.getFieldProps('updated_at')}
            value={
              payload.updated_by === '' || payload.updated_at === ''
                ? '-'
                : datetimeFormatter(payload.updated_at, '1', intl.locale)
            }
            readOnly
          />
        </div>
        {/* Updated At */}

        {/* Updated By */}
        <div className='fv-row mb-5'>
          <label className='form-label fs-6 fw-bolder text-dark'>
            {intl.formatMessage({id: 'LABEL_UPDATED_BY'})}
          </label>
          <input
            type='text'
            className='form-control form-control-solid'
            placeholder={intl.formatMessage({
              id: 'LABEL_UPDATED_BY',
            })}
            {...formik.getFieldProps('updated_by')}
            value={payload.updated_by === '' ? '-' : payload.updated_by}
            readOnly
          />
        </div>
        {/* Updated By */}
      </div>
      {/* Right Form */}
    </div>
  )
}

export {FormLog}
