import {ListResponse, RoleID} from '.'

export type ListModel = {
  role_id?: RoleID
  name?: string
  code?: string
  detail?: string
  status?: string
}

export type ListQueryResponse = ListResponse<Array<ListModel>>

export type ModuleModel = {
  module_id: any
  name_eng: any
  name_idn: any
}

export const initialList: ListModel = {
  role_id: '',
  name: '',
  code: '',
  detail: '',
  status: '',
}

export type GetDataByIDModel = {
  role_id?: RoleID
  name?: string
  created_at?: string
  created_by?: string
  code?: string
  detail?: string
  status?: string
  updated_at?: string
  updated_by?: string
  module: ModuleModel
  menu_id?: string | null | undefined
}

export const initialData: GetDataByIDModel = {
  role_id: '',
  name: '',
  created_at: '',
  created_by: '',
  detail: '',
  code: '',
  status: '',
  updated_at: '',
  updated_by: '',
  module: {
    module_id: '',
    name_eng: '',
    name_idn: '',
  },
}
