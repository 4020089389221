import {initialListQueryState, ListQueryState} from 'app/modules/settings-all-module/site/helpers'

const SITE_LIST_STATE_KEY = 'backoffice-orins-pos/settings/conditional/site'

const getSiteListState = () => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null | undefined = localStorage.getItem(SITE_LIST_STATE_KEY)
  if (!lsValue) {
    return setSiteListState(initialListQueryState)
  }

  try {
    const state = JSON.parse(lsValue)
    if (state) {
      return state
    }
  } catch (error) {
    console.log(SITE_LIST_STATE_KEY + ' STATE LOCAL STORAGE PARSE ERROR', error)
  }
}

const setSiteListState = (state: ListQueryState) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(state)
    localStorage.setItem(SITE_LIST_STATE_KEY, lsValue)
  } catch (error) {
    console.error(SITE_LIST_STATE_KEY + ' STATE LOCAL STORAGE SAVE ERROR', error)
  }
}

export {getSiteListState, setSiteListState, SITE_LIST_STATE_KEY}
