import {useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import toast from 'react-hot-toast'
import {Checkbox, InputPicker, CheckPicker, Drawer} from 'rsuite'

import {isMobileDevice} from '_metronic/assets/ts/_utils'
import {FilterAction, FilterButton} from 'app/components'
import {removeAuth} from 'app/modules/auth'

import {useAction, useListQueryRequest, useListQueryResponse} from '../../providers'
import {
  fetchBrandOptions,
  fetchCityOptions,
  fetchClusterOptions,
  fetchCompanyOptions,
  fetchDistrictOptions,
  fetchVillageOptions,
} from '../../helpers'

const footerStyles = {
  padding: '0px 2px',
  borderTop: '1px solid #e5e5e5',
}

const ListFilter = () => {
  const intl = useIntl()
  const mobile = isMobileDevice()
  const {state, updateState} = useListQueryRequest()
  const {isLoading} = useListQueryResponse()
  const {
    showFilter,
    setShowFilter,
    holdingOptions,
    companyOptions,
    setCompanyOptions,
    brandOptions,
    setBrandOptions,
    provinceOptions,
    cityOptions,
    setCityOptions,
    districtOptions,
    setDistrictOptions,
    villageOptions,
    setVillageOptions,
    areaOptions,
    clusterOptions,
    setClusterOptions,
  } = useAction()
  const [holdingID, setHoldingID] = useState(
    state?.filter !== undefined ? state?.filter.holding_id : []
  )
  const [holdingName, setHoldingName] = useState(
    state?.filter !== undefined ? state?.filter.holding_name : []
  )
  const [companyID, setCompanyID] = useState(
    state?.filter !== undefined ? state?.filter.company_id : []
  )
  const [companyName, setCompanyName] = useState(
    state?.filter !== undefined ? state?.filter.company_name : []
  )
  const [brandID, setBrandID] = useState(state?.filter !== undefined ? state?.filter.brand_id : [])
  const [brandName, setBrandName] = useState(
    state?.filter !== undefined ? state?.filter.brand_name : []
  )
  const [provinceID, setProvinceID] = useState(
    state?.filter !== undefined ? state?.filter.province_id : []
  )
  const [provinceName, setProvinceName] = useState(
    state?.filter !== undefined ? state?.filter.province_name : []
  )
  const [cityID, setCityID] = useState(state?.filter !== undefined ? state?.filter.city_id : [])
  const [cityName, setCityName] = useState(
    state?.filter !== undefined ? state?.filter.city_name : []
  )
  const [districtID, setDistrictID] = useState(
    state?.filter !== undefined ? state?.filter.district_id : []
  )
  const [districtName, setDistrictName] = useState(
    state?.filter !== undefined ? state?.filter.district_name : []
  )
  const [villageID, setVillageID] = useState(
    state?.filter !== undefined ? state?.filter.village_id : []
  )
  const [villageName, setVillageName] = useState(
    state?.filter !== undefined ? state?.filter.village_name : []
  )
  const [areaID, setAreaID] = useState(state?.filter !== undefined ? state?.filter.area_id : [])
  const [areaName, setAreaName] = useState(
    state?.filter !== undefined ? state?.filter.area_name : []
  )
  const [clusterID, setClusterID] = useState(
    state?.filter !== undefined ? state?.filter.cluster_id : []
  )
  const [clusterName, setClusterName] = useState(
    state?.filter !== undefined ? state?.filter.cluster_name : []
  )
  const optionsHolding =
    holdingOptions === undefined
      ? []
      : (holdingOptions as unknown as any[]).map((holdings: any) => ({
          value: holdings.holding_id,
          label: holdings.name,
        }))
  const optionsCompany =
    companyOptions === undefined
      ? []
      : (companyOptions as unknown as any[]).map((company: any) => ({
          value: company.company_id,
          label: company.name,
        }))
  const optionsBrand =
    brandOptions === undefined
      ? []
      : (brandOptions as unknown as any[]).map((brand: any) => ({
          value: brand.brand_id,
          label: brand.name,
        }))
  const optionsProvince =
    provinceOptions === undefined
      ? []
      : (provinceOptions as unknown as any[]).map((province: any) => ({
          value: province.province_id,
          label: province.name,
        }))
  const optionsCity =
    cityOptions === undefined
      ? []
      : (cityOptions as unknown as any[]).map((city: any) => ({
          value: city.city_id,
          label: city.name,
        }))
  const optionsDistrict =
    districtOptions === undefined
      ? []
      : (districtOptions as unknown as any[]).map((district: any) => ({
          value: district.district_id,
          label: district.name,
        }))
  const optionsVillage =
    villageOptions === undefined
      ? []
      : (villageOptions as unknown as any[]).map((village: any) => ({
          value: village.village_id,
          label: village.name,
        }))
  const optionsArea =
    areaOptions === undefined
      ? []
      : (areaOptions as unknown as any[]).map((area: any) => ({
          value: area.area_id,
          label: area.name,
        }))
  const optionsCluster =
    clusterOptions === undefined
      ? []
      : (clusterOptions as unknown as any[]).map((cluster: any) => ({
          value: cluster.cluster_id,
          label: cluster.name,
        }))
  const optionStatus = [
    {
      value: 't',
      label: intl.formatMessage({id: 'LABEL_ACTIVE'}),
    },
    {
      value: 'f',
      label: intl.formatMessage({id: 'LABEL_INACTIVE'}),
    },
  ]
  const [status, setStatus] = useState(state?.filter !== undefined ? state?.filter.status : '')
  const didRequestCompany = useRef(false)
  const didRequestBrand = useRef(false)
  const didRequestCity = useRef(false)
  const didRequestDistrict = useRef(false)
  const didRequestVillage = useRef(false)
  const didRequestCluster = useRef(false)

  const resetData = () => {
    setHoldingID([])
    setHoldingName([])
    setCompanyID([])
    setCompanyName([])
    setBrandID([])
    setBrandName([])
    setProvinceID([])
    setProvinceName([])
    setCityID([])
    setCityName([])
    setDistrictID([])
    setDistrictName([])
    setVillageID([])
    setVillageName([])
    setAreaID([])
    setAreaName([])
    setClusterID([])
    setClusterName([])
    setStatus('')
    updateState({filter: undefined})
  }

  const filterData = () => {
    updateState({
      filter: {
        holding_id: holdingID,
        company_id: companyID,
        brand_id: brandID,
        province_id: provinceID,
        city_id: cityID,
        district_id: districtID,
        village_id: villageID,
        area_id: areaID,
        cluster_id: clusterID,
        companyName,
        holdingName,
        brandName,
        provinceName,
        cityName,
        districtName,
        villageName,
        areaName,
        clusterName,
        status,
      },
    })
    setShowFilter(false)
  }

  useEffect(() => {
    const requestCompanyOptions = async () => {
      try {
        if (!didRequestCompany.current) {
          const options = await fetchCompanyOptions('filter', holdingID)
          // throw message error
          if (options.data.meta.code === 200) {
            // set options
            setCompanyOptions(options.data.data)
          }
        }
      } catch (error: any) {
        console.error(error)
        if (error.response.status === 401) {
          toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
          removeAuth()
        } else {
          toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
        }
      } finally {
      }

      return () => (didRequestCompany.current = true)
    }

    holdingID !== undefined && holdingID.length > 0
      ? requestCompanyOptions()
      : setCompanyOptions([])
    // eslint-disable-next-line
  }, [holdingID])

  useEffect(() => {
    const requestBrandOptions = async () => {
      try {
        if (!didRequestBrand.current) {
          const options = await fetchBrandOptions('filter', companyID)
          // throw message error
          if (options.data.meta.code === 200) {
            // set options
            setBrandOptions(options.data.data)
          }
        }
      } catch (error: any) {
        console.error(error)
        if (error.response.status === 401) {
          toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
          removeAuth()
        } else {
          toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
        }
      } finally {
      }

      return () => (didRequestBrand.current = true)
    }

    companyID !== undefined && companyID.length > 0 ? requestBrandOptions() : setBrandOptions([])
    // eslint-disable-next-line
  }, [companyID])

  useEffect(() => {
    const requestCityOptions = async () => {
      try {
        if (!didRequestCity.current) {
          const options = await fetchCityOptions('filter', provinceID)
          // throw message error
          if (options.data.meta.code === 200) {
            // set options
            setCityOptions(options.data.data)
          }
        }
      } catch (error: any) {
        console.error(error)
        if (error.response.status === 401) {
          toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
          removeAuth()
        } else {
          toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
        }
      } finally {
      }

      return () => (didRequestCity.current = true)
    }

    provinceID !== undefined && provinceID.length > 0 ? requestCityOptions() : setCityOptions([])
    // eslint-disable-next-line
  }, [provinceID])

  useEffect(() => {
    const requestDistrictOptions = async () => {
      try {
        if (!didRequestDistrict.current) {
          const options = await fetchDistrictOptions('filter', cityID)
          // throw message error
          if (options.data.meta.code === 200) {
            // set options
            setDistrictOptions(options.data.data)
          }
        }
      } catch (error: any) {
        console.error(error)
        if (error.response.status === 401) {
          toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
          removeAuth()
        } else {
          toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
        }
      } finally {
      }

      return () => (didRequestDistrict.current = true)
    }

    cityID !== undefined && cityID.length > 0 ? requestDistrictOptions() : setDistrictOptions([])
    // eslint-disable-next-line
  }, [cityID])

  useEffect(() => {
    const requestVillageOptions = async () => {
      try {
        if (!didRequestVillage.current) {
          const options = await fetchVillageOptions('filter', districtID)
          // throw message error
          if (options.data.meta.code === 200) {
            // set options
            setVillageOptions(options.data.data)
          }
        }
      } catch (error: any) {
        console.error(error)
        if (error.response.status === 401) {
          toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
          removeAuth()
        } else {
          toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
        }
      } finally {
      }

      return () => (didRequestVillage.current = true)
    }

    districtID !== undefined && districtID.length > 0
      ? requestVillageOptions()
      : setVillageOptions([])
    // eslint-disable-next-line
  }, [districtID])

  useEffect(() => {
    const requestClusterOptions = async () => {
      try {
        if (!didRequestCluster.current) {
          const options = await fetchClusterOptions('filter', areaID)
          // throw message error
          if (options.data.meta.code === 200) {
            // set options
            setClusterOptions(options.data.data)
          }
        }
      } catch (error: any) {
        console.error(error)
        if (error.response.status === 401) {
          toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
          removeAuth()
        } else {
          toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
        }
      } finally {
      }

      return () => (didRequestCluster.current = true)
    }

    areaID !== undefined && areaID.length > 0 ? requestClusterOptions() : setClusterOptions([])
    // eslint-disable-next-line
  }, [areaID])

  return (
    <>
      <FilterButton
        isLoading={isLoading}
        state={state}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
      />
      <Drawer
        open={showFilter}
        onClose={() => {
          setShowFilter(false)
        }}
        placement='right'
        style={{width: mobile ? '100%' : '50%'}}
      >
        <Drawer.Header closeButton>
          <Drawer.Actions>
            {/* Actions */}
            <FilterAction
              filterData={filterData}
              isLoading={isLoading}
              resetData={resetData}
              setStatus={setStatus}
            />
            {/* Actions */}
          </Drawer.Actions>
        </Drawer.Header>

        {/* Content */}
        <div className='card-body table-responsive m-5'>
          <div className='row mb-5'>
            {/* Left Form */}
            <div className='col-lg-6'>
              {/* Input group */}
              <div className='fv-row mb-5'>
                <label className='form-label fs-6 fw-bold'>{'Holding :'}</label>
                <CheckPicker
                  block
                  placeholder={`-- ${intl.formatMessage({id: 'LABEL_SELECT'})} --`}
                  data={optionsHolding}
                  onChange={(val, tr) => {
                    let tmp = []
                    let result: any[] = []
                    let label: any = holdingName

                    if (tr.type === 'keydown') {
                      // prevent user select/choice by using click enter
                      return
                    }

                    if (val.length > 0) {
                      if ((tr.target as unknown as any).checked === true) {
                        tmp = (optionsHolding as unknown as any[]).find(
                          (dt) => dt.label === (tr.target as unknown as any).labels[0].innerText
                        )
                        result = [...label, tmp.label]
                      } else {
                        if (tr.type === 'click') {
                          if ((tr.target as unknown as any).lastChild.nodeName === 'path') {
                            let tmp_result = label.filter(
                              (e: any) =>
                                e !==
                                (tr.target as unknown as any).parentElement.parentElement.innerText
                            )
                            result = tmp_result
                          }
                        } else {
                          let tmp_result = label.filter(
                            (e: any) => e !== (tr.target as unknown as any).labels[0].innerText
                          )
                          result = tmp_result
                        }
                      }
                      setHoldingID(val)
                      setHoldingName(result)
                    } else {
                      setHoldingID([])
                      setHoldingName([])
                    }

                    setCompanyID([])
                    setCompanyName([])
                    setBrandID([])
                    setBrandName([])
                  }}
                  onSelect={(val, ite) => {
                    let value: any = holdingID
                    let label: any = holdingName
                    let tmpValue: any = []
                    let tmpLabel: any = []
                    let resultValue: any[] = []
                    let resultLabel: any[] = []

                    if (val.length > 0) {
                      if (value.indexOf(ite.value) === -1) {
                        tmpValue = (optionsHolding as unknown as any[]).find(
                          (dt) => dt.value === ite.value
                        )

                        if (tmpValue === undefined) {
                          return
                        }

                        resultValue = [...value, tmpValue.value]
                        setHoldingID(resultValue)
                      } else {
                        tmpValue = value.filter((dt: any) => dt !== ite.value)
                        setHoldingID(tmpValue)
                      }

                      if (label.indexOf(ite.label) === -1) {
                        tmpLabel = (optionsHolding as unknown as any[]).find(
                          (dt) => dt.label === ite.label
                        )

                        if (tmpLabel === undefined) {
                          return
                        }

                        resultLabel = [...label, tmpLabel.label]
                        setHoldingName(resultLabel)
                      } else {
                        tmpLabel = label.filter((dt: any) => dt !== ite.label)
                        setHoldingName(tmpLabel)
                      }
                    } else {
                      setHoldingID([])
                      setHoldingName([])
                    }

                    setCompanyID([])
                    setCompanyName([])
                    setBrandID([])
                    setBrandName([])
                  }}
                  defaultValue={(optionsHolding as unknown as any[]).map((dt: any) => ({
                    value: dt.value,
                    label: dt.label,
                  }))}
                  value={holdingID}
                  renderExtraFooter={() => (
                    <div style={footerStyles}>
                      <Checkbox
                        indeterminate={
                          holdingID.length > 0 &&
                          holdingID.length < (optionsHolding as unknown as any[]).length
                        }
                        checked={holdingID.length === (optionsHolding as unknown as any[]).length}
                        onChange={(data, checked) => {
                          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                          if (checked) {
                            setHoldingID((optionsHolding as unknown as any[]).map((e) => e.value))
                            setHoldingName((optionsHolding as unknown as any[]).map((e) => e.label))
                          } else {
                            setHoldingID([])
                            setHoldingName([])
                          }
                        }}
                      >
                        {holdingID.length === (optionsHolding as unknown as any[]).length
                          ? intl.formatMessage({id: 'LABEL_UNSELECT_ALL'})
                          : intl.formatMessage({id: 'LABEL_SELECT_ALL'})}
                      </Checkbox>
                    </div>
                  )}
                />
              </div>
              {/* Input group */}

              {/* Input group */}
              <div className='fv-row mb-5'>
                <label className='form-label fs-6 fw-bold'>
                  {intl.formatMessage({id: 'LABEL_COMPANY'})}
                </label>
                <CheckPicker
                  block
                  placeholder={`-- ${intl.formatMessage({id: 'LABEL_SELECT'})} --`}
                  disabled={holdingID.length > 0 ? false : true}
                  data={optionsCompany}
                  onChange={(val, tr) => {
                    let tmp = []
                    let result: any[] = []
                    let label: any = companyName

                    if (tr.type === 'keydown') {
                      // prevent user select/choice by using click enter
                      return
                    }

                    if (val.length > 0) {
                      if ((tr.target as unknown as any).checked === true) {
                        tmp = (optionsCompany as unknown as any[]).find(
                          (dt) => dt.label === (tr.target as unknown as any).labels[0].innerText
                        )
                        result = [...label, tmp.label]
                      } else {
                        if (tr.type === 'click') {
                          if ((tr.target as unknown as any).lastChild.nodeName === 'path') {
                            let tmp_result = label.filter(
                              (e: any) =>
                                e !==
                                (tr.target as unknown as any).parentElement.parentElement.innerText
                            )
                            result = tmp_result
                          }
                        } else {
                          let tmp_result = label.filter(
                            (e: any) => e !== (tr.target as unknown as any).labels[0].innerText
                          )
                          result = tmp_result
                        }
                      }
                      setCompanyID(val)
                      setCompanyName(result)
                    } else {
                      setCompanyID([])
                      setCompanyName([])
                    }

                    setBrandID([])
                    setBrandName([])
                  }}
                  onSelect={(val, ite) => {
                    let value: any = companyID
                    let label: any = companyName
                    let tmpValue: any = []
                    let tmpLabel: any = []
                    let resultValue: any[] = []
                    let resultLabel: any[] = []

                    if (val.length > 0) {
                      if (value.indexOf(ite.value) === -1) {
                        tmpValue = (optionsCompany as unknown as any[]).find(
                          (dt) => dt.value === ite.value
                        )

                        if (tmpValue === undefined) {
                          return
                        }

                        resultValue = [...value, tmpValue.value]
                        setCompanyID(resultValue)
                      } else {
                        tmpValue = value.filter((dt: any) => dt !== ite.value)
                        setCompanyID(tmpValue)
                      }

                      if (label.indexOf(ite.label) === -1) {
                        tmpLabel = (optionsCompany as unknown as any[]).find(
                          (dt) => dt.label === ite.label
                        )

                        if (tmpLabel === undefined) {
                          return
                        }

                        resultLabel = [...label, tmpLabel.label]
                        setCompanyName(resultLabel)
                      } else {
                        tmpLabel = label.filter((dt: any) => dt !== ite.label)
                        setCompanyName(tmpLabel)
                      }
                    } else {
                      setCompanyID([])
                      setCompanyName([])
                    }

                    setBrandID([])
                    setBrandName([])
                  }}
                  defaultValue={(optionsCompany as unknown as any[]).map((dt: any) => ({
                    value: dt.value,
                    label: dt.label,
                  }))}
                  value={companyID}
                  renderExtraFooter={() => (
                    <div style={footerStyles}>
                      <Checkbox
                        indeterminate={
                          companyID.length > 0 &&
                          companyID.length < (optionsCompany as unknown as any[]).length
                        }
                        checked={companyID.length === (optionsCompany as unknown as any[]).length}
                        onChange={(data, checked) => {
                          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                          if (checked) {
                            setCompanyID((optionsCompany as unknown as any[]).map((e) => e.value))
                            setCompanyName((optionsCompany as unknown as any[]).map((e) => e.label))
                          } else {
                            setCompanyID([])
                            setCompanyName([])
                          }
                        }}
                      >
                        {companyID.length === (optionsCompany as unknown as any[]).length
                          ? intl.formatMessage({id: 'LABEL_UNSELECT_ALL'})
                          : intl.formatMessage({id: 'LABEL_SELECT_ALL'})}
                      </Checkbox>
                    </div>
                  )}
                />
              </div>
              {/* Input group */}

              {/* Input group */}
              <div className='fv-row mb-5'>
                <label className='form-label fs-6 fw-bold'>
                  {intl.formatMessage({id: 'LABEL_BRAND'})}
                </label>
                <CheckPicker
                  block
                  placeholder={`-- ${intl.formatMessage({id: 'LABEL_SELECT'})} --`}
                  disabled={companyID.length > 0 ? false : true}
                  data={optionsBrand}
                  onChange={(val, tr) => {
                    let tmp = []
                    let result: any[] = []
                    let label: any = brandName

                    if (tr.type === 'keydown') {
                      // prevent user select/choice by using click enter
                      return
                    }

                    if (val.length > 0) {
                      if ((tr.target as unknown as any).checked === true) {
                        tmp = (optionsBrand as unknown as any[]).find(
                          (dt) => dt.label === (tr.target as unknown as any).labels[0].innerText
                        )
                        result = [...label, tmp.label]
                      } else {
                        if (tr.type === 'click') {
                          if ((tr.target as unknown as any).lastChild.nodeName === 'path') {
                            let tmp_result = label.filter(
                              (e: any) =>
                                e !==
                                (tr.target as unknown as any).parentElement.parentElement.innerText
                            )
                            result = tmp_result
                          }
                        } else {
                          let tmp_result = label.filter(
                            (e: any) => e !== (tr.target as unknown as any).labels[0].innerText
                          )
                          result = tmp_result
                        }
                      }
                      setBrandID(val)
                      setBrandName(result)
                    } else {
                      setBrandID([])
                      setBrandName([])
                    }
                  }}
                  onSelect={(val, ite) => {
                    let value: any = brandID
                    let label: any = brandName
                    let tmpValue: any = []
                    let tmpLabel: any = []
                    let resultValue: any[] = []
                    let resultLabel: any[] = []

                    if (val.length > 0) {
                      if (value.indexOf(ite.value) === -1) {
                        tmpValue = (optionsBrand as unknown as any[]).find(
                          (dt) => dt.value === ite.value
                        )

                        if (tmpValue === undefined) {
                          return
                        }

                        resultValue = [...value, tmpValue.value]
                        setBrandID(resultValue)
                      } else {
                        tmpValue = value.filter((dt: any) => dt !== ite.value)
                        setBrandID(tmpValue)
                      }

                      if (label.indexOf(ite.label) === -1) {
                        tmpLabel = (optionsBrand as unknown as any[]).find(
                          (dt) => dt.label === ite.label
                        )

                        if (tmpLabel === undefined) {
                          return
                        }

                        resultLabel = [...label, tmpLabel.label]
                        setBrandName(resultLabel)
                      } else {
                        tmpLabel = label.filter((dt: any) => dt !== ite.label)
                        setBrandName(tmpLabel)
                      }
                    } else {
                      setBrandID([])
                      setBrandName([])
                    }
                  }}
                  defaultValue={(optionsBrand as unknown as any[]).map((dt: any) => ({
                    value: dt.value,
                    label: dt.label,
                  }))}
                  value={brandID}
                  renderExtraFooter={() => (
                    <div style={footerStyles}>
                      <Checkbox
                        indeterminate={
                          brandID.length > 0 &&
                          brandID.length < (optionsBrand as unknown as any[]).length
                        }
                        checked={brandID.length === (optionsBrand as unknown as any[]).length}
                        onChange={(data, checked) => {
                          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                          if (checked) {
                            setBrandID((optionsBrand as unknown as any[]).map((e) => e.value))
                            setBrandName((optionsBrand as unknown as any[]).map((e) => e.label))
                          } else {
                            setBrandID([])
                            setBrandName([])
                          }
                        }}
                      >
                        {brandID.length === (optionsBrand as unknown as any[]).length
                          ? intl.formatMessage({id: 'LABEL_UNSELECT_ALL'})
                          : intl.formatMessage({id: 'LABEL_SELECT_ALL'})}
                      </Checkbox>
                    </div>
                  )}
                />
              </div>
              {/* Input group */}
            </div>
            {/* Left Form */}

            {/* Right Form */}
            <div className='col-lg-6'>
              {/* Input group */}
              <div className='fv-row mb-5'>
                <label className='form-label fs-6 fw-bold'>
                  {intl.formatMessage({id: 'LABEL_PROVINCE'})}
                </label>
                <CheckPicker
                  block
                  placeholder={`-- ${intl.formatMessage({id: 'LABEL_SELECT'})} --`}
                  data={optionsProvince}
                  onChange={(val, tr) => {
                    let tmp = []
                    let result: any[] = []
                    let label: any = provinceName

                    if (tr.type === 'keydown') {
                      // prevent user select/choice by using click enter
                      return
                    }

                    if (val.length > 0) {
                      if ((tr.target as unknown as any).checked === true) {
                        tmp = (optionsProvince as unknown as any[]).find(
                          (dt) => dt.label === (tr.target as unknown as any).labels[0].innerText
                        )
                        result = [...label, tmp.label]
                      } else {
                        if (tr.type === 'click') {
                          if ((tr.target as unknown as any).lastChild.nodeName === 'path') {
                            let tmp_result = label.filter(
                              (e: any) =>
                                e !==
                                (tr.target as unknown as any).parentElement.parentElement.innerText
                            )
                            result = tmp_result
                          }
                        } else {
                          let tmp_result = label.filter(
                            (e: any) => e !== (tr.target as unknown as any).labels[0].innerText
                          )
                          result = tmp_result
                        }
                      }
                      setProvinceID(val)
                      setProvinceName(result)
                    } else {
                      setProvinceID([])
                      setProvinceName([])
                    }

                    setCityID([])
                    setCityName([])
                    setDistrictID([])
                    setDistrictName([])
                    setVillageID([])
                    setVillageName([])
                  }}
                  onSelect={(val, ite) => {
                    let value: any = provinceID
                    let label: any = provinceName
                    let tmpValue: any = []
                    let tmpLabel: any = []
                    let resultValue: any[] = []
                    let resultLabel: any[] = []

                    if (val.length > 0) {
                      if (value.indexOf(ite.value) === -1) {
                        tmpValue = (optionsProvince as unknown as any[]).find(
                          (dt) => dt.value === ite.value
                        )

                        if (tmpValue === undefined) {
                          return
                        }

                        resultValue = [...value, tmpValue.value]
                        setProvinceID(resultValue)
                      } else {
                        tmpValue = value.filter((dt: any) => dt !== ite.value)
                        setProvinceID(tmpValue)
                      }

                      if (label.indexOf(ite.label) === -1) {
                        tmpLabel = (optionsProvince as unknown as any[]).find(
                          (dt) => dt.label === ite.label
                        )

                        if (tmpLabel === undefined) {
                          return
                        }

                        resultLabel = [...label, tmpLabel.label]
                        setProvinceName(resultLabel)
                      } else {
                        tmpLabel = label.filter((dt: any) => dt !== ite.label)
                        setProvinceName(tmpLabel)
                      }
                    } else {
                      setProvinceID([])
                      setProvinceName([])
                    }

                    setCityID([])
                    setCityName([])
                    setDistrictID([])
                    setDistrictName([])
                    setVillageID([])
                    setVillageName([])
                  }}
                  defaultValue={(optionsProvince as unknown as any[]).map((dt: any) => ({
                    value: dt.value,
                    label: dt.label,
                  }))}
                  value={provinceID}
                  renderExtraFooter={() => (
                    <div style={footerStyles}>
                      <Checkbox
                        indeterminate={
                          provinceID.length > 0 &&
                          provinceID.length < (optionsProvince as unknown as any[]).length
                        }
                        checked={provinceID.length === (optionsProvince as unknown as any[]).length}
                        onChange={(data, checked) => {
                          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                          if (checked) {
                            setProvinceID((optionsProvince as unknown as any[]).map((e) => e.value))
                            setProvinceName(
                              (optionsProvince as unknown as any[]).map((e) => e.label)
                            )
                          } else {
                            setProvinceID([])
                            setProvinceName([])
                          }
                        }}
                      >
                        {provinceID.length === (optionsProvince as unknown as any[]).length
                          ? intl.formatMessage({id: 'LABEL_UNSELECT_ALL'})
                          : intl.formatMessage({id: 'LABEL_SELECT_ALL'})}
                      </Checkbox>
                    </div>
                  )}
                />
              </div>
              {/* Input group */}

              {/* Input group */}
              <div className='fv-row mb-5'>
                <label className='form-label fs-6 fw-bold'>
                  {intl.formatMessage({id: 'LABEL_CITY'})}
                </label>
                <CheckPicker
                  block
                  placeholder={`-- ${intl.formatMessage({id: 'LABEL_SELECT'})} --`}
                  disabled={provinceID.length > 0 ? false : true}
                  data={optionsCity}
                  onChange={(val, tr) => {
                    let tmp = []
                    let result: any[] = []
                    let label: any = cityName

                    if (tr.type === 'keydown') {
                      // prevent user select/choice by using click enter
                      return
                    }

                    if (val.length > 0) {
                      if ((tr.target as unknown as any).checked === true) {
                        tmp = (optionsCity as unknown as any[]).find(
                          (dt) => dt.label === (tr.target as unknown as any).labels[0].innerText
                        )
                        result = [...label, tmp.label]
                      } else {
                        if (tr.type === 'click') {
                          if ((tr.target as unknown as any).lastChild.nodeName === 'path') {
                            let tmp_result = label.filter(
                              (e: any) =>
                                e !==
                                (tr.target as unknown as any).parentElement.parentElement.innerText
                            )
                            result = tmp_result
                          }
                        } else {
                          let tmp_result = label.filter(
                            (e: any) => e !== (tr.target as unknown as any).labels[0].innerText
                          )
                          result = tmp_result
                        }
                      }
                      setCityID(val)
                      setCityName(result)
                    } else {
                      setCityID([])
                      setCityName([])
                    }

                    setDistrictID([])
                    setDistrictName([])
                    setVillageID([])
                    setVillageName([])
                  }}
                  onSelect={(val, ite) => {
                    let value: any = cityID
                    let label: any = cityName
                    let tmpValue: any = []
                    let tmpLabel: any = []
                    let resultValue: any[] = []
                    let resultLabel: any[] = []

                    if (val.length > 0) {
                      if (value.indexOf(ite.value) === -1) {
                        tmpValue = (optionsCity as unknown as any[]).find(
                          (dt) => dt.value === ite.value
                        )

                        if (tmpValue === undefined) {
                          return
                        }

                        resultValue = [...value, tmpValue.value]
                        setCityID(resultValue)
                      } else {
                        tmpValue = value.filter((dt: any) => dt !== ite.value)
                        setCityID(tmpValue)
                      }

                      if (label.indexOf(ite.label) === -1) {
                        tmpLabel = (optionsCity as unknown as any[]).find(
                          (dt) => dt.label === ite.label
                        )

                        if (tmpLabel === undefined) {
                          return
                        }

                        resultLabel = [...label, tmpLabel.label]
                        setCityName(resultLabel)
                      } else {
                        tmpLabel = label.filter((dt: any) => dt !== ite.label)
                        setCityName(tmpLabel)
                      }
                    } else {
                      setCityID([])
                      setCityName([])
                    }

                    setDistrictID([])
                    setDistrictName([])
                    setVillageID([])
                    setVillageName([])
                  }}
                  defaultValue={(optionsCity as unknown as any[]).map((dt: any) => ({
                    value: dt.value,
                    label: dt.label,
                  }))}
                  value={cityID}
                  renderExtraFooter={() => (
                    <div style={footerStyles}>
                      <Checkbox
                        indeterminate={
                          cityID.length > 0 &&
                          cityID.length < (optionsCity as unknown as any[]).length
                        }
                        checked={cityID.length === (optionsCity as unknown as any[]).length}
                        onChange={(data, checked) => {
                          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                          if (checked) {
                            setCityID((optionsCity as unknown as any[]).map((e) => e.value))
                            setCityName((optionsCity as unknown as any[]).map((e) => e.label))
                          } else {
                            setCityID([])
                            setCityName([])
                          }
                        }}
                      >
                        {cityID.length === (optionsCity as unknown as any[]).length
                          ? intl.formatMessage({id: 'LABEL_UNSELECT_ALL'})
                          : intl.formatMessage({id: 'LABEL_SELECT_ALL'})}
                      </Checkbox>
                    </div>
                  )}
                />
              </div>
              {/* Input group */}

              {/* Input group */}
              <div className='fv-row mb-5'>
                <label className='form-label fs-6 fw-bold'>
                  {intl.formatMessage({id: 'LABEL_DISTRICTS'})}
                </label>
                <CheckPicker
                  block
                  placeholder={`-- ${intl.formatMessage({id: 'LABEL_SELECT'})} --`}
                  disabled={cityID.length > 0 ? false : true}
                  data={optionsDistrict}
                  onChange={(val, tr) => {
                    let tmp = []
                    let result: any[] = []
                    let label: any = districtName

                    if (tr.type === 'keydown') {
                      // prevent user select/choice by using click enter
                      return
                    }

                    if (val.length > 0) {
                      if ((tr.target as unknown as any).checked === true) {
                        tmp = (optionsDistrict as unknown as any[]).find(
                          (dt) => dt.label === (tr.target as unknown as any).labels[0].innerText
                        )
                        result = [...label, tmp.label]
                      } else {
                        if (tr.type === 'click') {
                          if ((tr.target as unknown as any).lastChild.nodeName === 'path') {
                            let tmp_result = label.filter(
                              (e: any) =>
                                e !==
                                (tr.target as unknown as any).parentElement.parentElement.innerText
                            )
                            result = tmp_result
                          }
                        } else {
                          let tmp_result = label.filter(
                            (e: any) => e !== (tr.target as unknown as any).labels[0].innerText
                          )
                          result = tmp_result
                        }
                      }
                      setDistrictID(val)
                      setDistrictName(result)
                    } else {
                      setDistrictID([])
                      setDistrictName([])
                    }

                    setVillageID([])
                    setVillageName([])
                  }}
                  onSelect={(val, ite) => {
                    let value: any = districtID
                    let label: any = districtName
                    let tmpValue: any = []
                    let tmpLabel: any = []
                    let resultValue: any[] = []
                    let resultLabel: any[] = []

                    if (val.length > 0) {
                      if (value.indexOf(ite.value) === -1) {
                        tmpValue = (optionsDistrict as unknown as any[]).find(
                          (dt) => dt.value === ite.value
                        )

                        if (tmpValue === undefined) {
                          return
                        }

                        resultValue = [...value, tmpValue.value]
                        setDistrictID(resultValue)
                      } else {
                        tmpValue = value.filter((dt: any) => dt !== ite.value)
                        setDistrictID(tmpValue)
                      }

                      if (label.indexOf(ite.label) === -1) {
                        tmpLabel = (optionsDistrict as unknown as any[]).find(
                          (dt) => dt.label === ite.label
                        )

                        if (tmpLabel === undefined) {
                          return
                        }

                        resultLabel = [...label, tmpLabel.label]
                        setDistrictName(resultLabel)
                      } else {
                        tmpLabel = label.filter((dt: any) => dt !== ite.label)
                        setDistrictName(tmpLabel)
                      }
                    } else {
                      setDistrictID([])
                      setDistrictName([])
                    }

                    setVillageID([])
                    setVillageName([])
                  }}
                  defaultValue={(optionsDistrict as unknown as any[]).map((dt: any) => ({
                    value: dt.value,
                    label: dt.label,
                  }))}
                  value={districtID}
                  renderExtraFooter={() => (
                    <div style={footerStyles}>
                      <Checkbox
                        indeterminate={
                          districtID.length > 0 &&
                          districtID.length < (optionsDistrict as unknown as any[]).length
                        }
                        checked={districtID.length === (optionsDistrict as unknown as any[]).length}
                        onChange={(data, checked) => {
                          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                          if (checked) {
                            setDistrictID((optionsDistrict as unknown as any[]).map((e) => e.value))
                            setDistrictName(
                              (optionsDistrict as unknown as any[]).map((e) => e.label)
                            )
                          } else {
                            setDistrictID([])
                            setDistrictName([])
                          }
                        }}
                      >
                        {districtID.length === (optionsDistrict as unknown as any[]).length
                          ? intl.formatMessage({id: 'LABEL_UNSELECT_ALL'})
                          : intl.formatMessage({id: 'LABEL_SELECT_ALL'})}
                      </Checkbox>
                    </div>
                  )}
                />
              </div>
              {/* Input group */}

              {/* Input group */}
              <div className='fv-row mb-5'>
                <label className='form-label fs-6 fw-bold'>
                  {intl.formatMessage({id: 'LABEL_VILLAGES'})}
                </label>
                <CheckPicker
                  block
                  placeholder={`-- ${intl.formatMessage({id: 'LABEL_SELECT'})} --`}
                  disabled={districtID.length > 0 ? false : true}
                  data={optionsVillage}
                  onChange={(val, tr) => {
                    let tmp = []
                    let result: any[] = []
                    let label: any = villageName

                    if (tr.type === 'keydown') {
                      // prevent user select/choice by using click enter
                      return
                    }

                    if (val.length > 0) {
                      if ((tr.target as unknown as any).checked === true) {
                        tmp = (optionsVillage as unknown as any[]).find(
                          (dt) => dt.label === (tr.target as unknown as any).labels[0].innerText
                        )
                        result = [...label, tmp.label]
                      } else {
                        if (tr.type === 'click') {
                          if ((tr.target as unknown as any).lastChild.nodeName === 'path') {
                            let tmp_result = label.filter(
                              (e: any) =>
                                e !==
                                (tr.target as unknown as any).parentElement.parentElement.innerText
                            )
                            result = tmp_result
                          }
                        } else {
                          let tmp_result = label.filter(
                            (e: any) => e !== (tr.target as unknown as any).labels[0].innerText
                          )
                          result = tmp_result
                        }
                      }
                      setVillageID(val)
                      setVillageName(result)
                    } else {
                      setVillageID([])
                      setVillageName([])
                    }
                  }}
                  onSelect={(val, ite) => {
                    let value: any = villageID
                    let label: any = villageName
                    let tmpValue: any = []
                    let tmpLabel: any = []
                    let resultValue: any[] = []
                    let resultLabel: any[] = []

                    if (val.length > 0) {
                      if (value.indexOf(ite.value) === -1) {
                        tmpValue = (optionsVillage as unknown as any[]).find(
                          (dt) => dt.value === ite.value
                        )

                        if (tmpValue === undefined) {
                          return
                        }

                        resultValue = [...value, tmpValue.value]
                        setVillageID(resultValue)
                      } else {
                        tmpValue = value.filter((dt: any) => dt !== ite.value)
                        setVillageID(tmpValue)
                      }

                      if (label.indexOf(ite.label) === -1) {
                        tmpLabel = (optionsVillage as unknown as any[]).find(
                          (dt) => dt.label === ite.label
                        )

                        if (tmpLabel === undefined) {
                          return
                        }

                        resultLabel = [...label, tmpLabel.label]
                        setVillageName(resultLabel)
                      } else {
                        tmpLabel = label.filter((dt: any) => dt !== ite.label)
                        setVillageName(tmpLabel)
                      }
                    } else {
                      setVillageID([])
                      setVillageName([])
                    }
                  }}
                  defaultValue={(optionsVillage as unknown as any[]).map((dt: any) => ({
                    value: dt.value,
                    label: dt.label,
                  }))}
                  value={villageID}
                  renderExtraFooter={() => (
                    <div style={footerStyles}>
                      <Checkbox
                        indeterminate={
                          villageID.length > 0 &&
                          villageID.length < (optionsVillage as unknown as any[]).length
                        }
                        checked={villageID.length === (optionsVillage as unknown as any[]).length}
                        onChange={(data, checked) => {
                          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                          if (checked) {
                            setVillageID((optionsVillage as unknown as any[]).map((e) => e.value))
                            setVillageName((optionsVillage as unknown as any[]).map((e) => e.label))
                          } else {
                            setVillageID([])
                            setVillageName([])
                          }
                        }}
                      >
                        {villageID.length === (optionsVillage as unknown as any[]).length
                          ? intl.formatMessage({id: 'LABEL_UNSELECT_ALL'})
                          : intl.formatMessage({id: 'LABEL_SELECT_ALL'})}
                      </Checkbox>
                    </div>
                  )}
                />
              </div>
              {/* Input group */}
            </div>
          </div>

          <div className='row mb-5'>
            {/* Left Form */}
            <div className='col-lg-6'>
              {/* Input group */}
              <div className='fv-row mb-5'>
                <label className='form-label fs-6 fw-bold'>
                  {intl.formatMessage({id: 'LABEL_AREA'})}
                </label>
                <CheckPicker
                  block
                  placeholder={`-- ${intl.formatMessage({id: 'LABEL_SELECT'})} --`}
                  data={optionsArea}
                  onChange={(val, tr) => {
                    let tmp = []
                    let result: any[] = []
                    let label: any = areaName

                    if (tr.type === 'keydown') {
                      // prevent user select/choice by using click enter
                      return
                    }

                    if (val.length > 0) {
                      if ((tr.target as unknown as any).checked === true) {
                        tmp = (optionsArea as unknown as any[]).find(
                          (dt) => dt.label === (tr.target as unknown as any).labels[0].innerText
                        )
                        result = [...label, tmp.label]
                      } else {
                        if (tr.type === 'click') {
                          if ((tr.target as unknown as any).lastChild.nodeName === 'path') {
                            let tmp_result = label.filter(
                              (e: any) =>
                                e !==
                                (tr.target as unknown as any).parentElement.parentElement.innerText
                            )
                            result = tmp_result
                          }
                        } else {
                          let tmp_result = label.filter(
                            (e: any) => e !== (tr.target as unknown as any).labels[0].innerText
                          )
                          result = tmp_result
                        }
                      }
                      setAreaID(val)
                      setAreaName(result)
                    } else {
                      setAreaID([])
                      setAreaName([])
                    }

                    setClusterID([])
                    setClusterName([])
                  }}
                  onSelect={(val, ite) => {
                    let value: any = areaID
                    let label: any = areaName
                    let tmpValue: any = []
                    let tmpLabel: any = []
                    let resultValue: any[] = []
                    let resultLabel: any[] = []

                    if (val.length > 0) {
                      if (value.indexOf(ite.value) === -1) {
                        tmpValue = (optionsArea as unknown as any[]).find(
                          (dt) => dt.value === ite.value
                        )

                        if (tmpValue === undefined) {
                          return
                        }

                        resultValue = [...value, tmpValue.value]
                        setAreaID(resultValue)
                      } else {
                        tmpValue = value.filter((dt: any) => dt !== ite.value)
                        setAreaID(tmpValue)
                      }

                      if (label.indexOf(ite.label) === -1) {
                        tmpLabel = (optionsArea as unknown as any[]).find(
                          (dt) => dt.label === ite.label
                        )

                        if (tmpLabel === undefined) {
                          return
                        }

                        resultLabel = [...label, tmpLabel.label]
                        setAreaName(resultLabel)
                      } else {
                        tmpLabel = label.filter((dt: any) => dt !== ite.label)
                        setAreaName(tmpLabel)
                      }
                    } else {
                      setAreaID([])
                      setAreaName([])
                    }

                    setClusterID([])
                    setClusterName([])
                  }}
                  defaultValue={(optionsArea as unknown as any[]).map((dt: any) => ({
                    value: dt.value,
                    label: dt.label,
                  }))}
                  value={areaID}
                  renderExtraFooter={() => (
                    <div style={footerStyles}>
                      <Checkbox
                        indeterminate={
                          areaID.length > 0 &&
                          areaID.length < (optionsArea as unknown as any[]).length
                        }
                        checked={areaID.length === (optionsArea as unknown as any[]).length}
                        onChange={(data, checked) => {
                          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                          if (checked) {
                            setAreaID((optionsArea as unknown as any[]).map((e) => e.value))
                            setAreaName((optionsArea as unknown as any[]).map((e) => e.label))
                          } else {
                            setAreaID([])
                            setAreaName([])
                          }
                        }}
                      >
                        {areaID.length === (optionsArea as unknown as any[]).length
                          ? intl.formatMessage({id: 'LABEL_UNSELECT_ALL'})
                          : intl.formatMessage({id: 'LABEL_SELECT_ALL'})}
                      </Checkbox>
                    </div>
                  )}
                />
              </div>
              {/* Input group */}

              {/* Input group */}
              <div className='fv-row mb-5'>
                <label className='form-label fs-6 fw-bold'>
                  {intl.formatMessage({id: 'LABEL_CLUSTER'})}
                </label>
                <CheckPicker
                  block
                  placeholder={`-- ${intl.formatMessage({id: 'LABEL_SELECT'})} --`}
                  disabled={areaID.length > 0 ? false : true}
                  data={optionsCluster}
                  onChange={(val, tr) => {
                    let tmp = []
                    let result: any[] = []
                    let label: any = clusterName

                    if (tr.type === 'keydown') {
                      // prevent user select/choice by using click enter
                      return
                    }

                    if (val.length > 0) {
                      if ((tr.target as unknown as any).checked === true) {
                        tmp = (optionsCluster as unknown as any[]).find(
                          (dt) => dt.label === (tr.target as unknown as any).labels[0].innerText
                        )
                        result = [...label, tmp.label]
                      } else {
                        if (tr.type === 'click') {
                          if ((tr.target as unknown as any).lastChild.nodeName === 'path') {
                            let tmp_result = label.filter(
                              (e: any) =>
                                e !==
                                (tr.target as unknown as any).parentElement.parentElement.innerText
                            )
                            result = tmp_result
                          }
                        } else {
                          let tmp_result = label.filter(
                            (e: any) => e !== (tr.target as unknown as any).labels[0].innerText
                          )
                          result = tmp_result
                        }
                      }
                      setClusterID(val)
                      setClusterName(result)
                    } else {
                      setClusterID([])
                      setClusterName([])
                    }
                  }}
                  onSelect={(val, ite) => {
                    let value: any = clusterID
                    let label: any = clusterName
                    let tmpValue: any = []
                    let tmpLabel: any = []
                    let resultValue: any[] = []
                    let resultLabel: any[] = []

                    if (val.length > 0) {
                      if (value.indexOf(ite.value) === -1) {
                        tmpValue = (optionsCluster as unknown as any[]).find(
                          (dt) => dt.value === ite.value
                        )

                        if (tmpValue === undefined) {
                          return
                        }

                        resultValue = [...value, tmpValue.value]
                        setClusterID(resultValue)
                      } else {
                        tmpValue = value.filter((dt: any) => dt !== ite.value)
                        setClusterID(tmpValue)
                      }

                      if (label.indexOf(ite.label) === -1) {
                        tmpLabel = (optionsCluster as unknown as any[]).find(
                          (dt) => dt.label === ite.label
                        )

                        if (tmpLabel === undefined) {
                          return
                        }

                        resultLabel = [...label, tmpLabel.label]
                        setClusterName(resultLabel)
                      } else {
                        tmpLabel = label.filter((dt: any) => dt !== ite.label)
                        setClusterName(tmpLabel)
                      }
                    } else {
                      setClusterID([])
                      setClusterName([])
                    }
                  }}
                  defaultValue={(optionsCluster as unknown as any[]).map((dt: any) => ({
                    value: dt.value,
                    label: dt.label,
                  }))}
                  value={clusterID}
                  renderExtraFooter={() => (
                    <div style={footerStyles}>
                      <Checkbox
                        indeterminate={
                          clusterID.length > 0 &&
                          clusterID.length < (optionsCluster as unknown as any[]).length
                        }
                        checked={clusterID.length === (optionsCluster as unknown as any[]).length}
                        onChange={(data, checked) => {
                          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                          if (checked) {
                            setClusterID((optionsCluster as unknown as any[]).map((e) => e.value))
                            setClusterName((optionsCluster as unknown as any[]).map((e) => e.label))
                          } else {
                            setClusterID([])
                            setClusterName([])
                          }
                        }}
                      >
                        {clusterID.length === (optionsCluster as unknown as any[]).length
                          ? intl.formatMessage({id: 'LABEL_UNSELECT_ALL'})
                          : intl.formatMessage({id: 'LABEL_SELECT_ALL'})}
                      </Checkbox>
                    </div>
                  )}
                />
              </div>
              {/* Input group */}
            </div>
            {/* Left Form */}

            {/* Right Form */}
            <div className='col-lg-6'>
              {/* Input group */}
              <div className='fv-row mb-5'>
                <label className='form-label fs-6 fw-bold'>Status :</label>
                <InputPicker
                  block
                  data={optionStatus}
                  placeholder={`-- ${intl.formatMessage({id: 'LABEL_SELECT'})} --`}
                  defaultValue={
                    optionStatus
                      ? (optionStatus as unknown as any[]).find((e: any) => e.value === status)
                      : ''
                  }
                  onChange={(e) => {
                    setStatus(e)
                  }}
                  value={status}
                />
              </div>
              {/* Input group */}
            </div>
          </div>
        </div>
        {/* Content */}
      </Drawer>
    </>
  )
}

export {ListFilter}
