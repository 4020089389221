/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {useIntl} from 'react-intl'
import {Accordion, Divider, Drawer} from 'rsuite'
import clsx from 'clsx'
import * as CurrencyFormat from 'react-currency-format'

import {
  DatetimeFormatter,
  NotAuthRedirectForm,
  SpinnerIndicator,
  getFormAction,
} from 'app/components'
import {useThemeMode} from '_metronic/partials'

import {BankPayment, Category, DetailModel, Item, Online, Other, Shift} from '../../helpers'
import {useAction} from '../../providers'

type Props = {
  isLoading: boolean
  reportShift: DetailModel | undefined
  appMenuID: any
  modalID: any
  menuName: any
}

const ViewDrawerForm: FC<Props> = ({menuName, reportShift, isLoading}) => {
  const intl = useIntl()
  const {mode} = useThemeMode()
  const [tab, setTab] = useState(
    reportShift && reportShift.shift.length === 0
      ? ''
      : reportShift && reportShift.shift[0].shift_id
  )
  const {action, outletName} = useAction()
  const [isAuth] = useState(true)

  return (
    <>
      {!isAuth && <NotAuthRedirectForm />}

      <Drawer.Header>
        <Drawer.Title className='fw-bolder'>
          {isLoading ? (
            <SpinnerIndicator label={intl.formatMessage({id: 'BUTTON_LABEL_PLEASE_WAIT'})} />
          ) : (
            <>
              {getFormAction(
                action,
                intl.formatMessage({id: 'BUTTON_LABEL_VIEW'}),
                intl.formatMessage({id: 'BUTTON_LABEL_EDIT'}),
                intl.formatMessage({id: 'BUTTON_LABEL_ADD'}),
                intl.formatMessage({id: 'BUTTON_LABEL_DELETE'})
              )}{' '}
              {menuName}
              {' - '}
              {outletName}
            </>
          )}
        </Drawer.Title>
        <Drawer.Actions></Drawer.Actions>
      </Drawer.Header>

      <Drawer.Body style={{padding: 0}}>
        <div className={`modal-body m-5 mt-0`}>
          {/* Tab Header */}
          <div className='card-toolbar'>
            <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-2 mt-2' role='tablist'>
              {reportShift &&
                reportShift.shift &&
                reportShift.shift.map((shift: Shift, i: number) => (
                  <li
                    className={`nav-item ${tab === 'void' ? 'fw-bolder' : ''}`}
                    key={`li-${shift.shift_id}`}
                  >
                    <a
                      className={clsx(`nav-link cursor-pointer`, {
                        active: tab === shift.shift_id,
                      })}
                      onClick={() => setTab(shift.shift_id && shift.shift_id)}
                      role='tab'
                    >
                      {menuName}
                      {'-'}
                      {i + 1}
                    </a>
                  </li>
                ))}
            </ul>
          </div>
          {/* Tab Header */}

          {/* Tab Body */}
          <div className='card-body p-0 pt-2'>
            <div className='tab-content'>
              {reportShift &&
                reportShift.shift &&
                reportShift.shift.map((shift: Shift, i: number) => (
                  <>
                    {/* Data */}
                    <div
                      className={clsx('tab-pane', {
                        active: tab === shift.shift_id,
                      })}
                      key={`data-${shift.shift_id}-${i}`}
                    >
                      <div className='row table-responsive' key={`sub-data-${shift.shift_id}`}>
                        {/* Detail Shift */}
                        <div className='d-flex justify-content-between align-items-start flex-wrap mb-1'>
                          <span className='fw-bolder fs-5'>
                            {intl.formatMessage({id: 'LABEL_DETAIL'})} Shift
                          </span>
                        </div>
                        <div className='d-flex justify-content-between align-items-start flex-wrap mb-1'>
                          <span>{reportShift.outlet_name}</span>
                          <span>{shift.user.fullname}</span>
                        </div>
                        <div className='d-flex justify-content-between align-items-start flex-wrap mb-1'>
                          <span>{intl.formatMessage({id: 'LABEL_STARTING_SHIFT'})}</span>
                          <span>
                            <DatetimeFormatter
                              date={shift.start_time}
                              format='6'
                              locale={intl.locale}
                              timeago={false}
                            />
                          </span>
                        </div>
                        <div className='d-flex justify-content-between align-items-start flex-wrap'>
                          <span>Ending Shift</span>
                          <span>
                            {shift.end_time === '0001-01-01T00:00:00Z' ? (
                              '-'
                            ) : (
                              <DatetimeFormatter
                                date={shift.end_time}
                                format='6'
                                locale={intl.locale}
                                timeago={false}
                              />
                            )}
                          </span>
                        </div>

                        <Divider className='m-2' />

                        {/* Order Detail */}
                        <div className='d-flex justify-content-between align-items-start flex-wrap mb-1'>
                          <span className='fw-bolder fs-5'>
                            {intl.formatMessage({id: 'LABEL_ORDER_SUMMARY'})}
                          </span>
                        </div>
                        {/* Categories */}
                        <div className='d-flex justify-content-between alignU-items-start flex-wrap mb-1'>
                          <span>{intl.formatMessage({id: 'LABEL_SOLD_CATEGORY'})}</span>
                          <span>{shift.sold_categories.count}</span>
                        </div>
                        {shift.sold_categories.count > 0 && (
                          <Accordion>
                            <Accordion.Panel header={`🔎`} className='fw-lighter'>
                              <div className='table-responsive-sm'>
                                <table
                                  className='table table-bordered table-striped gy-0'
                                  style={{maxHeight: '150px'}}
                                >
                                  <thead>
                                    <tr
                                      className='fw-bold fs-7 text-gray-800'
                                      style={{
                                        backgroundColor: mode === 'light' ? '#e8e8e8' : '#4f545d',
                                      }}
                                    >
                                      <th style={{width: '50%', textAlign: 'center'}}>
                                        {intl.formatMessage({id: 'LABEL_CATEGORY'})}
                                      </th>
                                      <th style={{width: '35%', textAlign: 'center'}}>
                                        {intl.formatMessage({id: 'LABEL_IS_VARIANT'})}
                                      </th>
                                      <th style={{width: '15%', textAlign: 'center'}}>
                                        {intl.formatMessage({id: 'LABEL_QUANTITY'})}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {shift.sold_categories.categories.map(
                                      (ct: Category, i: number) => (
                                        <tr key={`tbd-${i}`} className='fw-lighter'>
                                          <td>{ct.name}</td>
                                          <td style={{textAlign: 'center'}}>{ct.variant_name}</td>
                                          <td style={{textAlign: 'right'}}>{ct.count}</td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </Accordion.Panel>
                          </Accordion>
                        )}

                        <div
                          className={`d-flex justify-content-between align-items-start flex-wrap ${shift.refund_categories.count === 0 ? 'mb-5' : 'mb-1'}`}
                        >
                          <span>{intl.formatMessage({id: 'LABEL_REFUND_CATEGORY'})}</span>
                          <span>{shift.refund_categories.count}</span>
                        </div>
                        {shift.refund_categories.count > 0 && (
                          <Accordion className='mb-5'>
                            <Accordion.Panel header={`🔎`} className='fw-lighter'>
                              <div className='table-responsive-sm'>
                                <table className='table table-bordered table-striped gy-0'>
                                  <thead>
                                    <tr
                                      className='fw-bold fs-7 text-gray-800'
                                      style={{
                                        backgroundColor: mode === 'light' ? '#e8e8e8' : '#4f545d',
                                      }}
                                    >
                                      <th style={{width: '50%', textAlign: 'center'}}>
                                        {intl.formatMessage({id: 'LABEL_CATEGORY'})}
                                      </th>
                                      <th style={{width: '35%', textAlign: 'center'}}>
                                        {intl.formatMessage({id: 'LABEL_IS_VARIANT'})}
                                      </th>
                                      <th style={{width: '15%', textAlign: 'center'}}>
                                        {intl.formatMessage({id: 'LABEL_QUANTITY'})}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {shift.refund_categories.categories.map(
                                      (ct: Category, i: number) => (
                                        <tr key={`tbd-${i}`} className='fw-lighter'>
                                          <td>{ct.name}</td>
                                          <td style={{textAlign: 'center'}}>{ct.variant_name}</td>
                                          <td style={{textAlign: 'right'}}>{ct.count}</td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </Accordion.Panel>
                          </Accordion>
                        )}

                        {/* Items */}
                        <div className='d-flex justify-content-between align-items-start flex-wrap mb-1'>
                          <span>{intl.formatMessage({id: 'LABEL_SOLD_ITEM'})}</span>
                          <span>{shift.sold_items.count}</span>
                        </div>
                        {shift.sold_items.count > 0 && (
                          <Accordion>
                            <Accordion.Panel header={`🔎`} className='fw-lighter'>
                              <div className='table-responsive-sm'>
                                <table className='table table-bordered table-striped gy-0'>
                                  <thead>
                                    <tr
                                      className='fw-bold fs-7 text-gray-800'
                                      style={{
                                        backgroundColor: mode === 'light' ? '#e8e8e8' : '#4f545d',
                                      }}
                                    >
                                      <th style={{width: '50%', textAlign: 'center'}}>
                                        {intl.formatMessage({id: 'LABEL_ITEM'})}
                                      </th>
                                      <th style={{width: '35%', textAlign: 'center'}}>
                                        {intl.formatMessage({id: 'LABEL_IS_VARIANT'})}
                                      </th>
                                      <th style={{width: '15%', textAlign: 'center'}}>
                                        {intl.formatMessage({id: 'LABEL_QUANTITY'})}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {shift.sold_items.items.map((ct: Item, i: number) => (
                                      <tr key={`tbd-${i}`} className='fw-lighter'>
                                        <td>{ct.name}</td>
                                        <td style={{textAlign: 'center'}}>{ct.variant_name}</td>
                                        <td style={{textAlign: 'right'}}>{ct.count}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </Accordion.Panel>
                          </Accordion>
                        )}

                        <div className='d-flex justify-content-between align-items-start flex-wrap mb-1'>
                          <span>{intl.formatMessage({id: 'LABEL_REFUND_ITEM'})}</span>
                          <span>{shift.refund_items.count}</span>
                        </div>
                        {shift.refund_items.count > 0 && (
                          <Accordion>
                            <Accordion.Panel header={`🔎`} className='fw-lighter'>
                              <div className='table-responsive-sm'>
                                <table className='table table-bordered table-striped gy-0'>
                                  <thead>
                                    <tr
                                      className='fw-bold fs-7 text-gray-800'
                                      style={{
                                        backgroundColor: mode === 'light' ? '#e8e8e8' : '#4f545d',
                                      }}
                                    >
                                      <th style={{width: '50%', textAlign: 'center'}}>
                                        {intl.formatMessage({id: 'LABEL_CATEGORY'})}
                                      </th>
                                      <th style={{width: '35%', textAlign: 'center'}}>
                                        {intl.formatMessage({id: 'LABEL_IS_VARIANT'})}
                                      </th>
                                      <th style={{width: '15%', textAlign: 'center'}}>
                                        {intl.formatMessage({id: 'LABEL_QUANTITY'})}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {shift.refund_items.items.map((ct: Item, i: number) => (
                                      <tr key={`tbd-${i}`} className='fw-lighter'>
                                        <td>{ct.name}</td>
                                        <td style={{textAlign: 'center'}}>{ct.variant_name}</td>
                                        <td style={{textAlign: 'right'}}>{ct.count}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </Accordion.Panel>
                          </Accordion>
                        )}

                        <Divider className='m-2' />

                        {/* Cash */}
                        <div className='d-flex justify-content-between align-items-start flex-wrap mb-1'>
                          <span className='fw-bolder fs-5'>
                            {intl.formatMessage({id: 'LABEL_CASH'})}
                          </span>
                        </div>
                        <div className='d-flex justify-content-between align-items-start flex-wrap mb-1'>
                          <span>{intl.formatMessage({id: 'LABEL_STARTING_CASH'})}</span>
                          <span>
                            <CurrencyFormat
                              value={shift.starting_cash}
                              displayType={'text'}
                              thousandSeparator={'.'}
                              decimalSeparator={','}
                              prefix={'Rp. '}
                            />
                          </span>
                        </div>
                        <div className='d-flex justify-content-between align-items-start flex-wrap mb-1'>
                          <span>{intl.formatMessage({id: 'LABEL_ENDING_CASH'})}</span>
                          <span>
                            <CurrencyFormat
                              value={shift.ending_cash}
                              displayType={'text'}
                              thousandSeparator={'.'}
                              decimalSeparator={','}
                              prefix={'Rp. '}
                            />
                          </span>
                        </div>
                        <div className='d-flex justify-content-between align-items-start flex-wrap mb-1'>
                          <span>{intl.formatMessage({id: 'LABEL_CASH_SALES'})}</span>
                          <span>
                            <CurrencyFormat
                              value={shift.cash_sold}
                              displayType={'text'}
                              thousandSeparator={'.'}
                              decimalSeparator={','}
                              prefix={'Rp. '}
                            />
                          </span>
                        </div>
                        <div className='d-flex justify-content-between align-items-start flex-wrap mb-1'>
                          <span>{intl.formatMessage({id: 'LABEL_CASH_REFUND'})}</span>
                          <span>
                            <CurrencyFormat
                              value={shift.cash_refund}
                              displayType={'text'}
                              thousandSeparator={'.'}
                              decimalSeparator={','}
                              prefix={'Rp. '}
                            />
                          </span>
                        </div>
                        <div className='d-flex justify-content-between align-items-start flex-wrap mb-1'>
                          <span>{intl.formatMessage({id: 'LABEL_EXPECTED_ENDING_CASH'})}</span>
                          <span>
                            <CurrencyFormat
                              value={shift.cash_expected}
                              displayType={'text'}
                              thousandSeparator={'.'}
                              decimalSeparator={','}
                              prefix={'Rp. '}
                            />
                          </span>
                        </div>
                        <div className='d-flex justify-content-between align-items-start flex-wrap'>
                          <span>{intl.formatMessage({id: 'LABEL_ACTUAL_ENDING_CASH'})}</span>
                          <span>
                            <CurrencyFormat
                              value={shift.cash_actual}
                              displayType={'text'}
                              thousandSeparator={'.'}
                              decimalSeparator={','}
                              prefix={'Rp. '}
                            />
                          </span>
                        </div>

                        <Divider className='m-2' />

                        {/* Bank  */}
                        <div className='d-flex justify-content-between align-items-start flex-wrap mb-1'>
                          <span className='fw-bolder fs-5'>
                            {intl.formatMessage({id: 'LABEL_BANK'})}
                          </span>
                        </div>
                        {shift.bank_payment_sold !== null &&
                          shift.bank_payment_sold.list !== null &&
                          shift.bank_payment_sold.list.length > 0 && (
                            <>
                              {shift.bank_payment_sold.list.map((bn: BankPayment, i: number) => (
                                <div
                                  className='d-flex justify-content-between align-items-start flex-wrap mb-1'
                                  key={`bank-${i}`}
                                >
                                  <span>{bn.name}</span>
                                  <span>
                                    <CurrencyFormat
                                      value={bn.amount}
                                      displayType={'text'}
                                      thousandSeparator={'.'}
                                      decimalSeparator={','}
                                      prefix={'Rp. '}
                                    />
                                  </span>
                                </div>
                              ))}
                            </>
                          )}

                        {shift.bank_payment_refund !== null &&
                          shift.bank_payment_refund.list !== null &&
                          shift.bank_payment_refund.list.length > 0 && (
                            <>
                              {shift.bank_payment_refund.list.map((bn: BankPayment, i: number) => (
                                <div
                                  className='d-flex justify-content-between align-items-start flex-wrap mb-1'
                                  key={`bank-${i}`}
                                >
                                  <span>
                                    {bn.name} {intl.formatMessage({id: 'LABEL_REFUND'})}
                                  </span>
                                  <span>
                                    <CurrencyFormat
                                      value={bn.amount}
                                      displayType={'text'}
                                      thousandSeparator={'.'}
                                      decimalSeparator={','}
                                      prefix={'Rp. '}
                                    />
                                  </span>
                                </div>
                              ))}
                            </>
                          )}
                        <div className='d-flex justify-content-between align-items-start flex-wrap mb-1'>
                          <span>
                            {intl.formatMessage({id: 'LABEL_EXPECTED_ENDING_BANK_PAYMENT'})}
                          </span>
                          <span>
                            <CurrencyFormat
                              value={shift.bank_expected}
                              displayType={'text'}
                              thousandSeparator={'.'}
                              decimalSeparator={','}
                              prefix={'Rp. '}
                            />
                          </span>
                        </div>
                        <div className='d-flex justify-content-between align-items-start flex-wrap'>
                          <span>
                            {intl.formatMessage({id: 'LABEL_ACTUAL_ENDING_BANK_PAYMENT'})}
                          </span>
                          <span>
                            <CurrencyFormat
                              value={shift.bank_actual}
                              displayType={'text'}
                              thousandSeparator={'.'}
                              decimalSeparator={','}
                              prefix={'Rp. '}
                            />
                          </span>
                        </div>

                        <Divider className='m-2' />

                        {/* Online Channel  */}
                        <div className='d-flex justify-content-between align-items-start flex-wrap mb-1'>
                          <span className='fw-bolder fs-5'>Online Channel</span>
                        </div>
                        {shift.online_sold !== null &&
                          shift.online_sold.list !== null &&
                          shift.online_sold.list.length > 0 && (
                            <>
                              {shift.online_sold.list.map((bn: Online, i: number) => (
                                <div
                                  className='d-flex justify-content-between align-items-start flex-wrap mb-1'
                                  key={`bank-${i}`}
                                >
                                  <span>{bn.name}</span>
                                  <span>
                                    <CurrencyFormat
                                      value={bn.amount}
                                      displayType={'text'}
                                      thousandSeparator={'.'}
                                      decimalSeparator={','}
                                      prefix={'Rp. '}
                                    />
                                  </span>
                                </div>
                              ))}
                            </>
                          )}

                        {shift.online_refund !== null &&
                          shift.online_refund.list !== null &&
                          shift.online_refund.list.length > 0 && (
                            <>
                              {shift.online_refund.list.map((bn: Online, i: number) => (
                                <div
                                  className='d-flex justify-content-between align-items-start flex-wrap mb-1'
                                  key={`online-${i}`}
                                >
                                  <span>
                                    {bn.name} {intl.formatMessage({id: 'LABEL_REFUND'})}
                                  </span>
                                  <span>
                                    <CurrencyFormat
                                      value={bn.amount}
                                      displayType={'text'}
                                      thousandSeparator={'.'}
                                      decimalSeparator={','}
                                      prefix={'Rp. '}
                                    />
                                  </span>
                                </div>
                              ))}
                            </>
                          )}
                        <div className='d-flex justify-content-between align-items-start flex-wrap mb-1'>
                          <span>
                            {intl.formatMessage({
                              id: 'LABEL_EXPECTED_ENDING_ONLINE_PAYMENT',
                            })}
                          </span>
                          <span>
                            <CurrencyFormat
                              value={shift.online_expected}
                              displayType={'text'}
                              thousandSeparator={'.'}
                              decimalSeparator={','}
                              prefix={'Rp. '}
                            />
                          </span>
                        </div>
                        <div className='d-flex justify-content-between align-items-start flex-wrap'>
                          <span>
                            {intl.formatMessage({id: 'LABEL_ACTUAL_ENDING_ONLINE_PAYMENT'})}
                          </span>
                          <span>
                            <CurrencyFormat
                              value={shift.online_actual}
                              displayType={'text'}
                              thousandSeparator={'.'}
                              decimalSeparator={','}
                              prefix={'Rp. '}
                            />
                          </span>
                        </div>

                        <Divider className='m-2' />

                        {/* Other  */}
                        <div className='d-flex justify-content-between align-items-start flex-wrap mb-1'>
                          <span className='fw-bolder fs-5'>
                            {intl.formatMessage({id: 'LABEL_OTHER'})}
                          </span>
                        </div>
                        {shift.other_sold !== null &&
                          shift.other_sold.list !== null &&
                          shift.other_sold.list.length > 0 && (
                            <>
                              {shift.other_sold.list.map((bn: Other, i: number) => (
                                <div
                                  className='d-flex justify-content-between align-items-start flex-wrap mb-1'
                                  key={`other-${i}`}
                                >
                                  <span>{bn.name}</span>
                                  <span>
                                    <CurrencyFormat
                                      value={bn.amount}
                                      displayType={'text'}
                                      thousandSeparator={'.'}
                                      decimalSeparator={','}
                                      prefix={'Rp. '}
                                    />
                                  </span>
                                </div>
                              ))}
                            </>
                          )}

                        {shift.other_refund !== null &&
                          shift.other_refund.list !== null &&
                          shift.other_refund.list.length > 0 && (
                            <>
                              {shift.other_refund.list.map((bn: Other, i: number) => (
                                <div
                                  className='d-flex justify-content-between align-items-start flex-wrap mb-1'
                                  key={`other-${i}`}
                                >
                                  <span>
                                    {bn.name} {intl.formatMessage({id: 'LABEL_REFUND'})}
                                  </span>
                                  <span>
                                    <CurrencyFormat
                                      value={bn.amount}
                                      displayType={'text'}
                                      thousandSeparator={'.'}
                                      decimalSeparator={','}
                                      prefix={'Rp. '}
                                    />
                                  </span>
                                </div>
                              ))}
                            </>
                          )}
                        <div className='d-flex justify-content-between align-items-start flex-wrap mb-1'>
                          <span>
                            {intl.formatMessage({
                              id: 'LABEL_EXPECTED_ENDING_OTHER_PAYMENT',
                            })}
                          </span>
                          <span>
                            <CurrencyFormat
                              value={shift.other_expected}
                              displayType={'text'}
                              thousandSeparator={'.'}
                              decimalSeparator={','}
                              prefix={'Rp. '}
                            />
                          </span>
                        </div>
                        <div className='d-flex justify-content-between align-items-start flex-wrap'>
                          <span>
                            {intl.formatMessage({id: 'LABEL_ACTUAL_ENDING_OTHER_PAYMENT'})}
                          </span>
                          <span>
                            <CurrencyFormat
                              value={shift.other_actual}
                              displayType={'text'}
                              thousandSeparator={'.'}
                              decimalSeparator={','}
                              prefix={'Rp. '}
                            />
                          </span>
                        </div>

                        <Divider className='m-2' />

                        {/* Total  */}
                        <div className='d-flex justify-content-between align-items-start flex-wrap mb-1'>
                          <span className='fw-bolder fs-5'>Total</span>
                        </div>
                        <div className='d-flex justify-content-between align-items-start flex-wrap mb-1'>
                          <span>{intl.formatMessage({id: 'LABEL_EXPECTED_TOTAL'})}</span>
                          <span>
                            <CurrencyFormat
                              value={shift.total_expected}
                              displayType={'text'}
                              thousandSeparator={'.'}
                              decimalSeparator={','}
                              prefix={'Rp. '}
                            />
                          </span>
                        </div>
                        <div className='d-flex justify-content-between align-items-start flex-wrap mb-1'>
                          <span>{intl.formatMessage({id: 'LABEL_ACTUAL_TOTAL'})}</span>
                          <span>
                            <CurrencyFormat
                              value={shift.total_actual}
                              displayType={'text'}
                              thousandSeparator={'.'}
                              decimalSeparator={','}
                              prefix={'Rp. '}
                            />
                          </span>
                        </div>
                        <div className='d-flex justify-content-between align-items-start flex-wrap'>
                          <span>{intl.formatMessage({id: 'LABEL_DIFFERENCE'})}</span>
                          <span>
                            <CurrencyFormat
                              value={shift.different}
                              displayType={'text'}
                              thousandSeparator={'.'}
                              decimalSeparator={','}
                              prefix={'Rp. '}
                            />
                          </span>
                        </div>

                        <Divider className='m-2' />
                      </div>
                    </div>
                    {/* Data */}
                  </>
                ))}
            </div>
          </div>
          {/* Tab Body */}
        </div>
      </Drawer.Body>
    </>
  )
}

export {ViewDrawerForm}
