import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'

import {PageTitle} from '_metronic/layout/core'
import {toAbsoluteUrl} from '_metronic/helpers'
import {useLang} from '_metronic/i18n/Metronici18n'

import * as contextHelper from 'app/context/state/UserGroupModulesStateContext'
import {SuspendViewComponents} from 'app/components'

type Props = {
  moduleName: string | undefined
  moduleCount: number
  icon: string | undefined
  path: string
  desc: string | undefined
}

const HomepagePage: FC<Props> = ({moduleName, moduleCount, icon, path, desc}) => {
  return (
    <div className={`col-xl-${moduleCount > 3 ? '4' : 12 / moduleCount}`}>
      <Link to={path} className='fs-4'>
        <div
          className={`card bgi-no-repeat card-xl-stretch mb-xl-8`}
          style={{
            backgroundPosition: 'right top',
            backgroundSize: moduleCount === 1 ? '15% auto' : '25% auto',
            backgroundImage: `url(${toAbsoluteUrl(icon ? icon : '')})`,
          }}
        >
          <div className='card-body fw-bold text-dark text-hover-primary'>
            {moduleName}

            <div className='my-6'>&nbsp;</div>

            <span
              className='text-muted fw-semibold fs-5 m-0'
              dangerouslySetInnerHTML={{__html: desc ? desc : ''}}
            ></span>
          </div>
        </div>
      </Link>
    </div>
  )
}

const HomepageWrapper: FC = () => {
  const intl = useIntl()
  const userGroupModules = contextHelper.getUserGroupModules()
  const [moduleCount, setModuleCount] = useState(0)
  const locale = useLang()

  useEffect(() => {
    return setModuleCount((userGroupModules as unknown as any[]).length)
  }, [userGroupModules])

  return (
    <>
      {userGroupModules && (
        <SuspendViewComponents>
          <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'LABEL_HOMEPAGE'})}</PageTitle>
          <div className='row g-5 g-xl-8'>
            {(userGroupModules as unknown as any[]).map((module: any, i: number) => (
              // <div key={`mod-${i}`}>
              <HomepagePage
                moduleName={
                  module ? (locale === 'id' ? module.name_idn : module.name_eng) : undefined
                }
                icon={module ? module.icon : undefined}
                path={module ? (module.path ? '/' + module.path : '') : ''}
                desc={
                  module ? (locale === 'id' ? module.detail_idn : module.detail_eng) : undefined
                }
                moduleCount={moduleCount}
                key={i}
              />
              // </div>
            ))}
          </div>
        </SuspendViewComponents>
      )}
    </>
  )
}

export {HomepageWrapper}
