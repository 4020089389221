import {UserID, ListResponse, MenuID} from '.'

export type UserRoleModel = {
  role_id: string
  name: string
}

export type ListModel = {
  avatar?: string | null
  email?: string
  fullname?: string
  user_id?: string
  role?: UserRoleModel
  status?: string
  username?: string
  last_login?: string
}

export type ListQueryResponse = ListResponse<Array<ListModel>>

export const initialList: ListModel = {
  avatar: '',
  email: '',
  fullname: '',
  user_id: '',
  role: {
    role_id: '',
    name: '',
  },
  status: '',
  username: '',
  last_login: '',
}

export type GetDataByIDModel = {
  user_id?: UserID
  avatar?: string
  created_at?: string
  created_by?: string
  email?: string
  fullname?: string
  last_login?: string
  role: UserRoleModel
  status?: string
  updated_at?: string
  updated_by?: string
  username?: string
  username_prefix?: string
  menu_id?: MenuID
}

export const initialData: GetDataByIDModel = {
  user_id: '',
  avatar: '',
  created_at: '',
  created_by: '',
  email: '',
  fullname: '',
  last_login: '',
  status: '',
  updated_at: '',
  updated_by: '',
  username: '',
  username_prefix: '',
  role: {
    role_id: '',
    name: '',
  },
}
