import {confirmAlert} from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css'

import {UserModel} from 'app/modules/auth'

import {
  addDataArea,
  addDataCity,
  addDataCluster,
  addDataDistrict,
  addDataProvince,
  addDataVillage,
} from '.'

type Props = {
  parent_id: string
  value: string
  label: string
  appMenuID: string
  currentUser: UserModel | undefined
}

function confirmBox(
  title: string,
  message: string,
  confirmLabel: string,
  cancelLabel: string,
  test: void
) {
  return confirmAlert({
    title: title,
    message: message,
    buttons: [
      {
        label: cancelLabel,
        onClick: () => {
          return false
        },
      },
      {
        label: confirmLabel,
        onClick: () => {
          return test
        },
      },
    ],
    closeOnClickOutside: false,
    closeOnEscape: false,
  })
}

async function addProvinceFromSelect(
  parent_id: string,
  value: string,
  appMenuID: string,
  currentUser: UserModel | undefined
) {
  const payload = {
    name: value,
    status: 't',
    menu_id: appMenuID,
    creator_id: currentUser?.user_id,
    creator_by: currentUser?.username,
  }
  return await addDataProvince(payload)
}

async function addCityFromSelect(
  parent_id: string,
  value: string,
  appMenuID: string,
  currentUser: UserModel | undefined
) {
  const payload = {
    province_id: parent_id === '' ? '' : parent_id,
    name: value,
    status: 't',
    menu_id: appMenuID,
    creator_id: currentUser?.user_id,
    creator_by: currentUser?.username,
  }
  return await addDataCity(payload)
}

async function addDistrictFromSelect(
  parent_id: string,
  value: string,
  appMenuID: string,
  currentUser: UserModel | undefined
) {
  const payload = {
    city_id: parent_id === '' ? '' : parent_id,
    name: value,
    status: 't',
    menu_id: appMenuID,
    creator_id: currentUser?.user_id,
    creator_by: currentUser?.username,
  }
  return await addDataDistrict(payload)
}

async function addVillageFromSelect(
  parent_id: string,
  value: string,
  appMenuID: string,
  currentUser: UserModel | undefined
) {
  const payload = {
    district_id: parent_id === '' ? '' : parent_id,
    name: value,
    status: 't',
    menu_id: appMenuID,
    creator_id: currentUser?.user_id,
    creator_by: currentUser?.username,
  }
  return await addDataVillage(payload)
}

async function addAreaFromSelect(
  parent_id: string,
  value: string,
  appMenuID: string,
  currentUser: UserModel | undefined
) {
  const payload = {
    name: value,
    status: 't',
    menu_id: appMenuID,
    creator_id: currentUser?.user_id,
    creator_by: currentUser?.username,
  }
  return await addDataArea(payload)
}

async function addClusterFromSelect(
  parent_id: string,
  value: string,
  appMenuID: string,
  currentUser: UserModel | undefined
) {
  const payload = {
    area_id: parent_id === '' ? '' : parent_id,
    name: value,
    status: 't',
    menu_id: appMenuID,
    creator_id: currentUser?.user_id,
    creator_by: currentUser?.username,
  }
  return await addDataCluster(payload)
}

const CreateSelect = ({value, label, parent_id, appMenuID, currentUser}: Props): any => {
  switch (label) {
    case 'province':
      return addProvinceFromSelect(parent_id, value, appMenuID, currentUser)
    case 'city':
      return addCityFromSelect(parent_id, value, appMenuID, currentUser)
    case 'district':
      return addDistrictFromSelect(parent_id, value, appMenuID, currentUser)
    case 'village':
      return addVillageFromSelect(parent_id, value, appMenuID, currentUser)
    case 'area':
      return addAreaFromSelect(parent_id, value, appMenuID, currentUser)
    case 'cluster':
      return addClusterFromSelect(parent_id, value, appMenuID, currentUser)
  }
}

export {CreateSelect, confirmBox}
