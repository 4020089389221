import {FC, lazy, useEffect, useRef, useState} from 'react'
import toast from 'react-hot-toast'
import {useIntl} from 'react-intl'
import {Navigate, Outlet, Route, Routes, useLocation} from 'react-router-dom'

import {PageLink, PageTitle} from '_metronic/layout/core'
import {SuspendViewComponents} from 'app/components'
import {CurrentMenuAccessModel} from 'app/context/models'
import {useUserGroupModules} from 'app/context/providers'
import {getCurrentMenuAccessByRoleIDAndMenuID} from 'app/context/requests'
import {removeAuth, useAuth} from 'app/modules/auth'

import * as backOfficeOrinsPOSListStateContext from 'app/pages/settings-all-module/page-provider/backoffice-orins-pos/state-context'

import {initialListQueryState as userListQueryState} from 'app/modules/settings-all-module/user/helpers'
import {initialListQueryState as accessListQueryState} from 'app/modules/settings-all-module/access/helpers'
import {initialListQueryState as siteListQueryState} from 'app/modules/settings-all-module/site/helpers'
import {initialListQueryState as menuListQueryState} from 'app/modules/settings-all-module/menu/helpers'
import {initialListQueryState as parameterListQueryState} from 'app/modules/settings-all-module/parameter/helpers'
import {initialListQueryState as datasListQueryState} from 'app/modules/settings-all-module/data/helpers'

const BackofficeOrinsPOSUserPageProvider = lazy(
  () => import('./page-provider/backoffice-orins-pos/BackofficeOrinsPOSUserPageProvider')
)
const BackofficeOrinsPOSAccessPageProvider = lazy(
  () => import('./page-provider/backoffice-orins-pos/BackofficeOrinsPOSAccessPageProvider')
)

const ModuleUserManagementWrapper: FC = () => {
  const intl = useIntl()
  const {pathname} = useLocation()
  const {
    currentModuleLabel,
    currentModulePath,
    currentSiteLabel,
    currentSitePath,
    currentParentMenuLabel,
    currentParentMenuPath,
    currentMenuLabel,
    currentMenuID,
  } = useUserGroupModules()
  const didRequest = useRef(false)
  const {currentUser} = useAuth()
  const [access, setAccess] = useState<CurrentMenuAccessModel | undefined>(undefined)

  const breadCrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({id: 'LABEL_HOMEPAGE'}),
      path: 'homepage',
      isSeparator: false,
      isActive: false,
    },
    {
      title: currentModuleLabel,
      path: currentModulePath,
      isSeparator: false,
      isActive: false,
    },
    {
      title: currentSiteLabel,
      path: currentSitePath,
      isSeparator: false,
      isActive: false,
    },
    {
      title: currentParentMenuLabel,
      path: currentParentMenuPath,
      isSeparator: false,
      isActive: false,
    },
  ]

  useEffect(() => {
    if (currentModulePath === 'backoffice-orins-pos') {
      if (backOfficeOrinsPOSListStateContext.getUserListState() === undefined) {
        backOfficeOrinsPOSListStateContext.setUserListState(userListQueryState)
      }
      if (backOfficeOrinsPOSListStateContext.getAccessListState() === undefined) {
        backOfficeOrinsPOSListStateContext.setAccessListState(accessListQueryState)
      }
      if (backOfficeOrinsPOSListStateContext.getSiteListState() === undefined) {
        backOfficeOrinsPOSListStateContext.setSiteListState(siteListQueryState)
      }
      if (backOfficeOrinsPOSListStateContext.getMenuListState() === undefined) {
        backOfficeOrinsPOSListStateContext.setMenuListState(menuListQueryState)
      }
      if (backOfficeOrinsPOSListStateContext.getParameterListState() === undefined) {
        backOfficeOrinsPOSListStateContext.setParameterListState(parameterListQueryState)
      }
      if (backOfficeOrinsPOSListStateContext.getDataListState() === undefined) {
        backOfficeOrinsPOSListStateContext.setDataListState(datasListQueryState)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentModulePath])

  useEffect(() => {
    const requestCurrentMenuAccess = async () => {
      try {
        if (!didRequest.current) {
          const access = await getCurrentMenuAccessByRoleIDAndMenuID(
            currentUser?.role.role_id,
            currentMenuID
          )
          // throw message error
          if (access.data.meta.code === 200) {
            setAccess(access.data.data)
          }
          if (access.data.meta.code === 404 || access.data.meta.code === 500) {
            toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
          }
          if (access.data.meta.code === 401) {
            toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
            removeAuth()
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
        }
      } finally {
      }

      return () => (didRequest.current = true)
    }

    if (
      currentMenuID !== undefined &&
      currentMenuID !== '' &&
      pathname.split('/')[3] === currentParentMenuPath.split('/')[3]
    ) {
      requestCurrentMenuAccess()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMenuID])

  return (
    <>
      {currentModulePath === 'backoffice-orins-pos' && (
        <>
          <Routes>
            <Route element={<Outlet />}>
              <Route
                path='app_users'
                element={
                  <SuspendViewComponents>
                    <PageTitle breadcrumbs={breadCrumbs}>{currentMenuLabel}</PageTitle>
                    <BackofficeOrinsPOSUserPageProvider access={access} />
                  </SuspendViewComponents>
                }
              />
              <Route
                path='app_access'
                element={
                  <SuspendViewComponents>
                    <PageTitle breadcrumbs={breadCrumbs}>{currentMenuLabel}</PageTitle>
                    <BackofficeOrinsPOSAccessPageProvider access={access} />
                  </SuspendViewComponents>
                }
              />
              <Route
                index
                element={<Navigate to='/backoffice-orins-pos/settings/users/app_users' />}
              />
            </Route>
          </Routes>
        </>
      )}
    </>
  )
}

export {ModuleUserManagementWrapper}
