/* eslint-disable jsx-a11y/anchor-is-valid */
import {useMemo} from 'react'
import {useIntl} from 'react-intl'
import {Placeholder} from 'rsuite'
import * as CurrencyFormat from 'react-currency-format'

import {KTIcon} from '_metronic/helpers'

import {useAction, useListQueryResponse, useListQueryResponseData} from '../providers'
import {DatetimeFormatter} from 'app/components'
import {ListModel} from '../helpers'

const Content = () => {
  const intl = useIntl()
  const {isLoading} = useListQueryResponse()
  const {setAction, setItemIdForUpdate} = useAction()
  const res = useListQueryResponseData()
  const data = useMemo(() => res, [res])
  return (
    <>
      <div className='card-body p-5 pt-0'>
        {isLoading ? (
          <Placeholder.Paragraph active />
        ) : (
          <div className='row g-3 mb-5'>
            {(data as unknown as any).map((dt: ListModel, i: number) => (
              <div className='col-lg-3' key={i}>
                <div className='card border border-grey h-100 p-4'>
                  <a href='#' className='fs-4 text-gray-800 text-hover-primary fw-bolder mb-5'>
                    <DatetimeFormatter
                      date={dt.shift_date}
                      format='1'
                      locale={intl.locale}
                      timeago={false}
                    />
                  </a>

                  {/* <div className='fw-bold text-gray-500 mb-6'></div> */}

                  <div className='d-flex flex-center flex-wrap mb-5'>
                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mx-3 mb-3'>
                      <div className='fs-6 fw-bolder text-gray-700'>
                        <CurrencyFormat
                          className='fw-bolder fs-4'
                          value={dt.count_data?.c_sync_ok}
                          displayType={'text'}
                          thousandSeparator={'.'}
                          decimalSeparator={','}
                        />
                      </div>
                      <div className='fw-bold text-gray-500'>
                        {intl.formatMessage({id: 'LABEL_ESB_SYNC_DATA_OK'})}
                      </div>
                    </div>

                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 mx-3 px-4 mb-3'>
                      <div className='fs-6 fw-bolder text-gray-700'>
                        <CurrencyFormat
                          className='fw-bolder fs-4'
                          value={dt.count_data?.c_sync_error}
                          displayType={'text'}
                          thousandSeparator={'.'}
                          decimalSeparator={','}
                        />
                      </div>
                      <div className='fw-bold text-gray-500'>
                        {intl.formatMessage({id: 'LABEL_ESB_SYNC_DATA_ERROR'})}
                      </div>
                    </div>
                  </div>

                  <a
                    href='#'
                    className='btn btn-sm btn-outline btn-outline-dashed btn-outline-secondary btn-active-light-secondary hover-scale'
                    onClick={() => {
                      setItemIdForUpdate(dt.shift_date)
                      setAction('apv1')
                    }}
                  >
                    <KTIcon iconName='magnifier' className='fs-3' />
                  </a>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  )
}

export {Content}
