import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {Tooltip, Whisper} from 'rsuite'

import {KTIcon} from '_metronic/helpers'
import {useShortcutMenus, useUserGroupModules} from 'app/context/providers'
import {ShortcutMenuStateModels} from 'app/context/models'

const ShortcutMenuButton: FC = () => {
  const intl = useIntl()
  const [isPinMenu, setIsPinMenu] = useState(false)
  const {state: shortcuts, updateState} = useShortcutMenus()
  const {
    currentMenuID,
    currentParentMenuID,
    currentModuleID,
    currentModuleLabel,
    currentSiteID,
    currentSiteLabel,
    currentMenuLabel,
    currentMenuPath,
  } = useUserGroupModules()
  const pinMenus: ShortcutMenuStateModels = {
    menu_id: currentMenuID,
    menu_name: currentMenuLabel,
    path: currentMenuPath,
    parent_menu_id: currentParentMenuID,
    module_id: currentModuleID,
    module_name: currentModuleLabel,
    site_id: currentSiteID,
    site_name: currentSiteLabel,
  }

  useEffect(() => {
    shortcuts !== undefined &&
      shortcuts.length > 0 &&
      setIsPinMenu(shortcuts.map((e) => e.menu_id).includes(currentMenuID))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shortcuts, currentMenuID, isPinMenu])

  return (
    <Whisper
      placement='top'
      controlId='control-id-hover'
      trigger='hover'
      speaker={
        <Tooltip id='tooltip-shortcut-menu'>
          {isPinMenu
            ? intl.formatMessage({id: 'LABEL_REMOVE_TO_SHORTCUT_MENU'})
            : intl.formatMessage({id: 'LABEL_ADD_TO_SHORTCUT_MENU'})}
        </Tooltip>
      }
    >
      <button
        type='button'
        className={`btn btn-icon btn-${isPinMenu ? 'light-success' : 'bg-light'} hover-scale me-4`}
        onClick={() => {
          if (isPinMenu === false) {
            updateState(pinMenus, 'pin')
            setIsPinMenu(true)
          } else {
            updateState(pinMenus, 'unpin')
            setIsPinMenu(false)
          }
        }}
        style={{marginRight: '15px'}}
      >
        <KTIcon iconName={`${isPinMenu ? 'archive-tick' : 'pin'}`} className='fs-2x' />
      </button>
    </Whisper>
  )
}

export {ShortcutMenuButton}
