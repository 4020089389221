import {FC, lazy, useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {Routes, Route, Outlet, Navigate, useLocation} from 'react-router-dom'
import toast from 'react-hot-toast'

import {PageLink, PageTitle} from '_metronic/layout/core'
import {useUserGroupModules} from 'app/context/providers'
import {removeAuth, useAuth} from 'app/modules/auth'
import {CurrentMenuAccessModel} from 'app/context/models'
import {getCurrentMenuAccessByRoleIDAndMenuID} from 'app/context/requests'
import {SuspendViewComponents} from 'app/components'

import * as userListState from 'app/modules/general/settings/user-management/user/providers/ListQueryStateContext'
import {initialListQueryState as userListQueryState} from 'app/modules/general/settings/user-management/user/helpers/ListQueryModel'

import * as userGroupListState from 'app/modules/general/settings/user-management/group/providers/ListQueryStateContext'
import {initialListQueryState as userGroupListQueryState} from 'app/modules/general/settings/user-management/group/helpers/ListQueryModel'

import * as userGroupAccessListState from 'app/modules/general/settings/user-management/access/providers/ListQueryStateContext'
import {initialListQueryState as userGroupAccessListQueryState} from 'app/modules/general/settings/user-management/access/helpers/ListQueryModel'

import * as logListState from 'app/modules/general/settings/user-management/log/providers/ListQueryStateContext'
import {initialListQueryState as logListQueryState} from 'app/modules/general/settings/user-management/log/helpers/ListQueryModel'

import * as moduleListState from 'app/modules/general/settings/conditional/module/providers/ListQueryStateContext'
import {initialListQueryState as moduleListQueryState} from 'app/modules/general/settings/conditional/module/helpers/ListQueryModel'

import * as menuListState from 'app/modules/general/settings/conditional/menu/providers/ListQueryStateContext'
import {initialListQueryState as menuListQueryState} from 'app/modules/general/settings/conditional/menu/helpers/ListQueryModel'

import * as parameterListState from 'app/modules/general/settings/conditional/parameter/providers/ListQueryStateContext'
import {initialListQueryState as parameterListQueryState} from 'app/modules/general/settings/conditional/parameter/helpers/ListQueryModel'

import * as masterDataListState from 'app/modules/general/settings/data/providers/ListQueryStateContext'
import {initialListQueryState as masterDataListQueryState} from 'app/modules/general/settings/data/helpers/ListQueryModel'

const UserPageProviderWrapper = lazy(() => import('./user/UserPageProviderWrapper'))
const GroupPageProviderWrapper = lazy(() => import('./group/GroupPageProviderWrapper'))
const AccessPageProviderWrapper = lazy(() => import('./access/AccessPageProviderWrapper'))
const LogPageProviderWrapper = lazy(() => import('./log/LogPageProviderWrapper'))

const UserManagementWrapper: FC = () => {
  const intl = useIntl()
  const {pathname} = useLocation()
  const {
    currentModuleLabel,
    currentModulePath,
    currentSiteLabel,
    currentSitePath,
    currentParentMenuLabel,
    currentParentMenuPath,
    currentMenuLabel,
    currentMenuID,
  } = useUserGroupModules()
  const didRequest = useRef(false)
  const {currentUser} = useAuth()
  const [access, setAccess] = useState<CurrentMenuAccessModel | undefined>(undefined)

  const breadCrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({id: 'LABEL_HOMEPAGE'}),
      path: 'homepage',
      isSeparator: false,
      isActive: false,
    },
    {
      title: currentModuleLabel,
      path: currentModulePath,
      isSeparator: false,
      isActive: false,
    },
    {
      title: currentSiteLabel,
      path: currentSitePath,
      isSeparator: false,
      isActive: false,
    },
    {
      title: currentParentMenuLabel,
      path: currentParentMenuPath,
      isSeparator: false,
      isActive: false,
    },
  ]

  useEffect(() => {
    if (userListState.getListState() === undefined) {
      userListState.setListState(userListQueryState)
    }
    if (userGroupListState.getListState() === undefined) {
      userGroupListState.setListState(userGroupListQueryState)
    }
    if (userGroupAccessListState.getListState() === undefined) {
      userGroupAccessListState.setListState(userGroupAccessListQueryState)
    }
    if (logListState.getListState() === undefined) {
      logListState.setListState(logListQueryState)
    }
    if (moduleListState.getListState() === undefined) {
      moduleListState.setListState(moduleListQueryState)
    }
    if (menuListState.getListState() === undefined) {
      menuListState.setListState(menuListQueryState)
    }
    if (parameterListState.getListState() === undefined) {
      parameterListState.setListState(parameterListQueryState)
    }
    if (masterDataListState.getListState() === undefined) {
      masterDataListState.setListState(masterDataListQueryState)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const requestCurrentMenuAccess = async () => {
      try {
        if (!didRequest.current) {
          const access = await getCurrentMenuAccessByRoleIDAndMenuID(
            currentUser?.role.role_id,
            currentMenuID
          )
          // throw message error
          if (access.data.meta.code === 200) {
            setAccess(access.data.data)
          }
          if (access.data.meta.code === 404 || access.data.meta.code === 500) {
            toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
          }
          if (access.data.meta.code === 401) {
            toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
            removeAuth()
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
        }
      } finally {
      }

      return () => (didRequest.current = true)
    }

    if (
      currentMenuID !== undefined &&
      currentMenuID !== '' &&
      pathname.split('/')[3] === currentParentMenuPath.split('/')[3]
    ) {
      requestCurrentMenuAccess()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMenuID])

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='app_users'
          element={
            <SuspendViewComponents>
              <PageTitle breadcrumbs={breadCrumbs}>{currentMenuLabel}</PageTitle>
              <UserPageProviderWrapper access={access} />
            </SuspendViewComponents>
          }
        />
        <Route
          path='app_groups'
          element={
            <SuspendViewComponents>
              <PageTitle breadcrumbs={breadCrumbs}>{currentMenuLabel}</PageTitle>
              <GroupPageProviderWrapper access={access} />
            </SuspendViewComponents>
          }
        />
        <Route
          path='app_access'
          element={
            <SuspendViewComponents>
              <PageTitle breadcrumbs={breadCrumbs}>{currentMenuLabel}</PageTitle>
              <AccessPageProviderWrapper access={access} />
            </SuspendViewComponents>
          }
        />
        <Route
          path='app_logs'
          element={
            <SuspendViewComponents>
              <PageTitle breadcrumbs={breadCrumbs}>{currentMenuLabel}</PageTitle>
              <LogPageProviderWrapper access={access} />
            </SuspendViewComponents>
          }
        />
        <Route index element={<Navigate to='/general/settings/users/app_users' />} />
      </Route>
    </Routes>
  )
}

export {UserManagementWrapper}
