/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {Dialog} from '@headlessui/react'
import toast from 'react-hot-toast'
import clsx from 'clsx'
import {Drawer, InputPicker, Toggle} from 'rsuite'

import {useLang} from '_metronic/i18n/Metronici18n'
import {FormLog, NotAuthRedirectForm, getFormAction, SpinnerIndicator} from 'app/components'
import {getAuth, removeAuth} from 'app/modules/auth'

import {
  GetDataByIDModel,
  addData,
  deleteData,
  getParameterListForInput,
  initialData,
  updateData,
} from '../../helpers'
import {useAction, useListQueryResponse} from '../../providers'

type Props = {
  isLoading: boolean
  parameter: GetDataByIDModel
  appMenuID: any
  modalID: any
  menuName: any
}

const ViewDrawerForm: FC<Props> = ({menuName, parameter, isLoading, appMenuID, modalID}) => {
  const [tab, setTab] = useState('data')
  const auth = getAuth()
  const local = useLang()
  const intl = useIntl()
  const {setItemIdForUpdate, action} = useAction()
  const {refetch} = useListQueryResponse()
  const [loading, setLoading] = useState(false)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [isAuth, setIsAuth] = useState(true)
  const [completed, setCompleted] = useState(false)
  const didRequest = useRef(false)
  const [parameterListForInput, setParameterListForInput] = useState(undefined)
  const optionParents =
    parameterListForInput === undefined
      ? []
      : (parameterListForInput as unknown as any).map((parameter: any) => ({
          value: parameter.parameter_id,
          label: local === 'id' ? parameter.name_idn : parameter.name_eng,
        }))

  const validationSchema = Yup.object().shape({
    name_eng: Yup.string()
      .min(3, intl.formatMessage({id: 'VALIDATION_MIN_LENGTH_FIELD'}, {min: 3}))
      .max(25, intl.formatMessage({id: 'VALIDATION_MAX_LENGTH_FIELD'}, {max: 25}))
      .required(
        intl.formatMessage(
          {id: 'VALIDATION_REQUIRED_FIELD'},
          {name: intl.formatMessage({id: 'LABEL_NAME'})}
        )
      ),
    name_idn: Yup.string()
      .min(3, intl.formatMessage({id: 'VALIDATION_MIN_LENGTH_FIELD'}, {min: 3}))
      .max(25, intl.formatMessage({id: 'VALIDATION_MAX_LENGTH_FIELD'}, {max: 25}))
      .required(
        intl.formatMessage(
          {id: 'VALIDATION_REQUIRED_FIELD'},
          {name: intl.formatMessage({id: 'LABEL_NAME'})}
        )
      ),
    code: Yup.string()
      .min(3, intl.formatMessage({id: 'VALIDATION_MIN_LENGTH_FIELD'}, {min: 3}))
      .max(10, intl.formatMessage({id: 'VALIDATION_MAX_LENGTH_FIELD'}, {max: 10}))
      .required(
        intl.formatMessage(
          {id: 'VALIDATION_REQUIRED_FIELD'},
          {name: intl.formatMessage({id: 'LABEL_CODE'})}
        )
      ),
  })
  const requestUpdateData = async (
    values: any,
    setStatus: any,
    setSubmitting: any,
    setFieldError: any
  ) => {
    setLoading(true)
    setStatus(null)
    setSubmitting(true)

    // request API
    try {
      const res = await updateData(values)

      // throw message error
      if (res?.meta.code !== 200) {
        if (res?.meta.message === 'name eng already used') {
          setStatus(intl.formatMessage({id: 'RESPONSE_PARAMETER_NAME_ALREADY_USED'}))
          setFieldError(
            'name_eng',
            intl.formatMessage({id: 'RESPONSE_PARAMETER_NAME_ALREADY_USED'})
          )
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
        if (res?.meta.message === 'name idn already used') {
          setStatus(intl.formatMessage({id: 'RESPONSE_PARAMETER_NAME_ALREADY_USED'}))
          setFieldError(
            'name_idn',
            intl.formatMessage({id: 'RESPONSE_PARAMETER_NAME_ALREADY_USED'})
          )
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
        if (res?.meta.message === 'code already used') {
          setStatus(intl.formatMessage({id: 'RESPONSE_PARAMETER_CODE_ALREADY_USED'}))
          setFieldError('code', intl.formatMessage({id: 'RESPONSE_PARAMETER_CODE_ALREADY_USED'}))
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_PARAMETER_EDIT_SUCCESS'}))
        setCompleted(true)
        setTimeout(function () {
          setItemIdForUpdate(undefined)
        }, 1e3)
        refetch()
      }
    } catch (error: any) {
      console.error(error)
      if (error.response.status === 401) {
        setStatus(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
        removeAuth()
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
      }
      setTimeout(function () {
        setStatus(null)
      }, 9e3)

      return false
    } finally {
      setTimeout(() => {
        setLoading(false)
        setSubmitting(false)
      }, 5e2)
    }
  }
  const requestAddData = async (
    values: any,
    setStatus: any,
    setSubmitting: any,
    setFieldError: any
  ) => {
    setLoading(true)
    setStatus(null)
    setSubmitting(true)

    // request API
    try {
      const res = await addData(values)

      // throw message error
      if (res?.meta.code !== 200) {
        if (res?.meta.message === 'name eng already used') {
          setStatus(intl.formatMessage({id: 'RESPONSE_PARAMETER_NAME_ALREADY_USED'}))
          setFieldError(
            'name_eng',
            intl.formatMessage({id: 'RESPONSE_PARAMETER_NAME_ALREADY_USED'})
          )
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
        if (res?.meta.message === 'name idn already used') {
          setStatus(intl.formatMessage({id: 'RESPONSE_PARAMETER_NAME_ALREADY_USED'}))
          setFieldError(
            'name_idn',
            intl.formatMessage({id: 'RESPONSE_PARAMETER_NAME_ALREADY_USED'})
          )
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
        if (res?.meta.message === 'code already used') {
          setStatus(intl.formatMessage({id: 'RESPONSE_PARAMETER_CODE_ALREADY_USED'}))
          setFieldError('code', intl.formatMessage({id: 'RESPONSE_PARAMETER_CODE_ALREADY_USED'}))
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_PARAMETER_ADD_SUCCESS'}))
        setCompleted(true)
        setTimeout(function () {
          setItemIdForUpdate(undefined)
        }, 1e3)
        refetch()
      }
    } catch (error: any) {
      console.error(error)
      if (error.response.status === 401) {
        setStatus(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
        removeAuth()
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
      }
      setTimeout(function () {
        setStatus(null)
      }, 9e3)

      return false
    } finally {
      setTimeout(() => {
        setLoading(false)
        setSubmitting(false)
      }, 5e2)
    }
  }
  const requestDeleteData = async (values: any, setStatus: any, setSubmitting: any) => {
    setLoading(true)
    setStatus(null)
    setSubmitting(true)

    // request API
    try {
      const res = await deleteData(values.parameter_id, values.app_menu_id)

      // throw message error
      if (res?.meta.code !== 200) {
        if (res?.meta.message === 'parameter is not inactive') {
          setStatus(intl.formatMessage({id: 'RESPONSE_PARAMETER_IS_ACTIVE'}))
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
        if (res?.meta.message === 'parameter inused by other data') {
          setStatus(intl.formatMessage({id: 'RESPONSE_PARAMETER_IS_INUSED'}))
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_PARAMETER_DELETE_SUCCESS'}))
        setCompleted(true)
        setTimeout(function () {
          setItemIdForUpdate(undefined)
        }, 1e3)
        refetch()
      }
    } catch (error: any) {
      console.error(error)
      if (error.response.status === 401) {
        setStatus(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
        removeAuth()
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
      }
      setSubmitting(false)
      setLoading(false)
      setTimeout(function () {
        setStatus(null)
      }, 9e3)

      return false
    } finally {
      setTimeout(() => {
        setLoading(false)
        setSubmitting(false)
      }, 5e2)
    }
  }
  const [parameterModels] = useState<GetDataByIDModel>({
    ...parameter,
    parameter_id: parameter.parameter_id || initialData.parameter_id,
    code: parameter.code || initialData.code,
    detail_eng: parameter.detail_eng || initialData.detail_eng,
    detail_idn: parameter.detail_idn || initialData.detail_idn,
    menu_id: parameter.menu_id || initialData.menu_id,
    module_id: parameter.module_id || initialData.module_id,
    site_id: parameter.site_id || initialData.site_id,
    name_eng: parameter.name_eng || initialData.name_eng,
    name_idn: parameter.name_idn || initialData.name_idn,
    parent_id: parameter.parent_id || initialData.parent_id,
    parent_name_eng: parameter.parent_name_eng || initialData.parent_name_eng,
    parent_name_idn: parameter.parent_name_idn || initialData.parent_name_idn,
    app_menu_id: appMenuID,
  })
  const formik: any = useFormik({
    initialValues: parameterModels,
    validationSchema: validationSchema,
    onSubmit: async (values, {setSubmitting, setStatus, setFieldError}) => {
      setIsDialogOpen(false)
      action === 'edit'
        ? requestUpdateData(values, setStatus, setSubmitting, setFieldError)
        : action === 'add'
          ? requestAddData(values, setStatus, setSubmitting, setFieldError)
          : requestDeleteData(values, setStatus, setSubmitting)
    },
    onReset: (values) => {
      values = parameterModels
    },
  })

  useEffect(() => {
    const requestParameterListForInput = async () => {
      try {
        if (!didRequest.current) {
          const parameterList = await getParameterListForInput(parameter.module_id)
          // throw message error
          if (parameterList.data.meta.code === 200) {
            // set option parameter list for input
            setParameterListForInput(parameterList.data.data)
          }
        }
      } catch (error: any) {
        console.error(error)
        if (error.response.status === 401) {
          toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
          removeAuth()
        } else {
          toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
        }
      } finally {
      }

      return () => (didRequest.current = true)
    }

    requestParameterListForInput()

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    auth === undefined && setIsAuth(false)
  }, [auth])

  return (
    <>
      {!isAuth && <NotAuthRedirectForm />}

      <Drawer.Header>
        <Drawer.Title className='fw-bolder'>
          {isLoading ? (
            <SpinnerIndicator label={intl.formatMessage({id: 'BUTTON_LABEL_PLEASE_WAIT'})} />
          ) : (
            <>
              {getFormAction(
                action,
                intl.formatMessage({id: 'BUTTON_LABEL_VIEW'}),
                intl.formatMessage({id: 'BUTTON_LABEL_EDIT'}),
                intl.formatMessage({id: 'BUTTON_LABEL_ADD'}),
                intl.formatMessage({id: 'BUTTON_LABEL_DELETE'})
              )}{' '}
              {menuName}
            </>
          )}
        </Drawer.Title>
        <Drawer.Actions>
          {action !== 'view' && parameterModels !== formik.values && !completed && (
            <button
              type='reset'
              className='btn btn-light me-3'
              data-kt-users-modal-action='reset'
              onClick={formik.handleReset}
              disabled={loading}
            >
              {!loading && (
                <span className='indicator-label'>
                  {intl.formatMessage({id: 'BUTTON_LABEL_RESET'})}
                </span>
              )}
            </button>
          )}

          {action !== 'view' && parameterModels !== formik.values && !completed && (
            <button
              type='submit'
              className='btn btn-primary'
              data-kt-users-modal-action='submit'
              onClick={() => {
                setIsDialogOpen(true)
                setLoading(true)
                setTimeout(function () {
                  setLoading(false)
                }, 2e3)
              }}
              disabled={isLoading || loading || formik.isSubmitting || !formik.isValid}
            >
              {!loading && (
                <span className='indicator-label'>
                  {intl.formatMessage({id: 'BUTTON_LABEL_SUBMIT'})}
                </span>
              )}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {intl.formatMessage({id: 'BUTTON_LABEL_SUBMITTING'})}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          )}

          {action === 'delete' && !completed && (
            <button
              type='submit'
              className='btn btn-danger'
              data-kt-users-modal-action='submit'
              onClick={() => formik.handleSubmit()}
              disabled={isLoading || loading || formik.isSubmitting || !formik.isValid}
            >
              {!loading && (
                <span className='indicator-label'>
                  {intl.formatMessage({id: 'BUTTON_LABEL_DELETE'})}
                </span>
              )}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {intl.formatMessage({id: 'BUTTON_LABEL_SUBMITTING'})}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          )}
        </Drawer.Actions>
      </Drawer.Header>

      <Drawer.Body style={{padding: 0}}>
        {/* Response Message */}
        {formik.status &&
          (formik.status === intl.formatMessage({id: 'RESPONSE_PARAMETER_ADD_SUCCESS'}) ||
          formik.status === intl.formatMessage({id: 'RESPONSE_PARAMETER_EDIT_SUCCESS'}) ||
          formik.status === intl.formatMessage({id: 'RESPONSE_PARAMETER_DELETE_SUCCESS'}) ? (
            <div className='m-lg-5 p-2 bg-light-success rounded'>
              <div className='text-success text-center font-weight-bold fw-bolder'>
                {formik.status}
              </div>
            </div>
          ) : (
            <div className={`m-lg-5 p-2 alert alert-danger`}>
              <div className='alert-text text-center font-weight-bold fw-bolder'>
                {formik.status}
              </div>
            </div>
          ))}
        {/* Response Message */}

        {/* Form */}
        <div className={`modal-body m-5 ${action === 'add' ? '' : ' mt-0'}`}>
          {action === 'add' ? (
            <form id={`${modalID}-form`} className='form' onSubmit={formik.handleSubmit} noValidate>
              <div className='row'>
                {/* Left Form */}
                <div className='col-lg-6'>
                  {/* Parent */}
                  <div className='fv-row mb-5'>
                    {formik.values.parent_id !== '' && (
                      <label className='form-label fs-6 fw-bolder text-dark'>
                        {intl.formatMessage({id: 'LABEL_PARENT'})}
                      </label>
                    )}
                    <InputPicker
                      block
                      data={optionParents}
                      placeholder={`-- ${intl.formatMessage({id: 'LABEL_PARENT'})} --`}
                      defaultValue={
                        optionParents
                          ? (optionParents as unknown as any[]).find(
                              (e: any) => e.value === formik.values.parent_id
                            )
                          : ''
                      }
                      onChange={(e) => {
                        formik.setFieldValue('parent_id', e === null ? '' : e)
                      }}
                      onSelect={(val, itm) => {
                        formik.setFieldValue('parent_name', val === null ? '' : itm.label)
                      }}
                      value={formik.values.parent_id}
                    />
                  </div>
                  {/* Parent */}

                  {/* Name */}
                  <div className='fv-row mb-5'>
                    {formik.values.name_eng !== '' && (
                      <label className='form-label fs-6 fw-bolder text-dark required'>
                        {intl.formatMessage({id: 'LABEL_NAME'})}
                        {' (ENG)'}
                      </label>
                    )}
                    <input
                      type='text'
                      className={clsx(
                        'form-control form-control-solid',
                        {'is-invalid': formik.touched.name_eng && formik.errors.name_eng},
                        {
                          'is-valid': formik.touched.name_eng && !formik.errors.name_eng,
                        }
                      )}
                      placeholder={intl.formatMessage({id: 'LABEL_NAME'}) + ' (ENG)'}
                      {...formik.getFieldProps('name_eng')}
                    />
                    {formik.touched.name_eng && formik.errors.name_eng ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger'>{formik.errors.name_eng}</div>
                      </div>
                    ) : null}
                  </div>
                  <div className='fv-row mb-5'>
                    {formik.values.name_idn !== '' && (
                      <label className='form-label fs-6 fw-bolder text-dark required'>
                        {intl.formatMessage({id: 'LABEL_NAME'})}
                        {' (IDN)'}
                      </label>
                    )}
                    <input
                      type='text'
                      className={clsx(
                        'form-control form-control-solid',
                        {'is-invalid': formik.touched.name_idn && formik.errors.name_idn},
                        {
                          'is-valid': formik.touched.name_idn && !formik.errors.name_idn,
                        }
                      )}
                      placeholder={intl.formatMessage({id: 'LABEL_NAME'}) + ' (IDN)'}
                      {...formik.getFieldProps('name_idn')}
                    />
                    {formik.touched.name_idn && formik.errors.name_idn ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger'>{formik.errors.name_idn}</div>
                      </div>
                    ) : null}
                  </div>
                  {/* Name */}

                  {/* Code */}
                  <div className='fv-row mb-5'>
                    {formik.values.code !== '' && (
                      <label className='form-label fs-6 fw-bolder text-dark required'>
                        {intl.formatMessage({id: 'LABEL_CODE'})}
                      </label>
                    )}
                    <input
                      type='text'
                      className={clsx(
                        'form-control form-control-solid',
                        {'is-invalid': formik.touched.code && formik.errors.code},
                        {
                          'is-valid': formik.touched.code && !formik.errors.code,
                        }
                      )}
                      placeholder={intl.formatMessage({id: 'LABEL_CODE'})}
                      {...formik.getFieldProps('code')}
                      onChange={(e) => {
                        formik.setFieldValue('code', e.target.value.toUpperCase())
                      }}
                    />
                    {formik.touched.code && formik.errors.code ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger'>{formik.errors.code}</div>
                      </div>
                    ) : null}
                  </div>
                  {/* Code */}
                </div>
                {/* Left Form */}

                {/* Right Form */}
                <div className='col-lg-6'>
                  {/* Description */}
                  <div className='fv-row mb-5'>
                    {formik.values.detail_eng !== '' && (
                      <label className='form-label fs-6 fw-bolder text-dark'>
                        {intl.formatMessage({id: 'LABEL_DESCRIPTION'})}
                        {' (ENG)'}
                      </label>
                    )}
                    <textarea
                      className={clsx(
                        'form-control form-control-solid',
                        {'is-invalid': formik.touched.detail_eng && formik.errors.detail_eng},
                        {
                          'is-valid': formik.touched.detail_eng && !formik.errors.detail_eng,
                        }
                      )}
                      placeholder={intl.formatMessage({id: 'LABEL_DESCRIPTION'}) + ' (ENG)'}
                      {...formik.getFieldProps('detail_eng')}
                      rows={3}
                    />
                  </div>
                  <div className='fv-row mb-5'>
                    {formik.values.detail_idn !== '' && (
                      <label className='form-label fs-6 fw-bolder text-dark'>
                        {intl.formatMessage({id: 'LABEL_DESCRIPTION'})}
                        {' (IDN)'}
                      </label>
                    )}
                    <textarea
                      className={clsx(
                        'form-control form-control-solid',
                        {'is-invalid': formik.touched.detail_idn && formik.errors.detail_idn},
                        {
                          'is-valid': formik.touched.detail_idn && !formik.errors.detail_idn,
                        }
                      )}
                      placeholder={intl.formatMessage({id: 'LABEL_DESCRIPTION'}) + ' (IDN)'}
                      {...formik.getFieldProps('detail_idn')}
                      rows={3}
                    />
                  </div>
                  {/* Description */}

                  {/* Status */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark required'>Status</label>
                    <div className='form-check form-switch form-check-custom form-check-solid mt-1'>
                      <Toggle
                        size='lg'
                        checkedChildren={intl.formatMessage({id: 'LABEL_ACTIVE'})}
                        unCheckedChildren={intl.formatMessage({id: 'LABEL_INACTIVE'})}
                        checked={formik.values.status === 't' ? true : false}
                        onChange={() =>
                          formik.setFieldValue('status', formik.values.status === 't' ? 'f' : 't')
                        }
                      />
                    </div>
                  </div>
                  {/* Status */}
                </div>
                {/* Right Form */}
              </div>
            </form>
          ) : (
            <form id={`${modalID}-form`} className='form' onSubmit={formik.handleSubmit} noValidate>
              {/* Tab Header */}
              <div className='card-toolbar'>
                <ul
                  className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-2 mt-2'
                  role='tablist'
                >
                  <li className={`nav-item ${tab === 'data' ? 'fw-bolder' : ''}`}>
                    <a
                      className={clsx(`nav-link cursor-pointer`, {
                        active: tab === 'data',
                      })}
                      onClick={() => setTab('data')}
                      role='tab'
                    >
                      Data
                    </a>
                  </li>
                  <li className={`nav-item ${tab === 'log' ? 'fw-bolder' : ''}`}>
                    <a
                      className={clsx(`nav-link cursor-pointer`, {
                        active: tab === 'log',
                      })}
                      onClick={() => setTab('log')}
                      role='tab'
                    >
                      Log
                    </a>
                  </li>
                </ul>
              </div>
              {/* Tab Header */}

              {/* Tab Body */}
              <div className='card-body p-0 pt-2'>
                <div className='tab-content'>
                  {/* Data */}
                  <div
                    className={clsx('tab-pane', {
                      active: tab === 'data',
                    })}
                  >
                    <div className='row'>
                      {/* Left Form */}
                      <div className='col-lg-6'>
                        {/* Parent */}
                        <div className='fv-row mb-5'>
                          {formik.values.parent_id !== '' && (
                            <label className='form-label fs-6 fw-bolder text-dark'>
                              {intl.formatMessage({id: 'LABEL_PARENT'})}
                            </label>
                          )}
                          <InputPicker
                            block
                            readOnly={action !== 'edit' ? true : false}
                            data={optionParents}
                            placeholder={`-- ${intl.formatMessage({id: 'LABEL_PARENT'})} --`}
                            defaultValue={
                              optionParents
                                ? (optionParents as unknown as any[]).find(
                                    (e: any) => e.value === formik.values.parent_id
                                  )
                                : ''
                            }
                            onChange={(e) => {
                              formik.setFieldValue('parent_id', e === null ? '' : e)
                            }}
                            onSelect={(val, itm) => {
                              formik.setFieldValue('parent_name', val === null ? '' : itm.label)
                            }}
                            value={formik.values.parent_id}
                          />
                          {formik.errors.parent_id ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger'>
                                {intl.formatMessage(
                                  {id: 'VALIDATION_REQUIRED_FIELD'},
                                  {name: intl.formatMessage({id: 'LABEL_PARENT'})}
                                )}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Parent */}

                        {/* Name */}
                        <div className='fv-row mb-5'>
                          {formik.values.name_eng !== '' && (
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action === 'edit' ? 'required' : ''
                              }`}
                            >
                              {intl.formatMessage({id: 'LABEL_NAME'})}
                              {' (ENG)'}
                            </label>
                          )}
                          <input
                            type='text'
                            className={clsx(
                              'form-control form-control-solid',
                              {'is-invalid': formik.touched.name_eng && formik.errors.name_eng},
                              {
                                'is-valid': formik.touched.name_eng && !formik.errors.name_eng,
                              }
                            )}
                            placeholder={intl.formatMessage({id: 'LABEL_NAME'}) + ' (ENG)'}
                            {...formik.getFieldProps('name_eng')}
                            readOnly={action === 'edit' ? false : true}
                          />
                          {formik.touched.name_eng && formik.errors.name_eng ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger'>
                                {formik.errors.name_eng}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        <div className='fv-row mb-5'>
                          {formik.values.name_idn !== '' && (
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action === 'edit' ? 'required' : ''
                              }`}
                            >
                              {intl.formatMessage({id: 'LABEL_NAME'})}
                              {' (IDN)'}
                            </label>
                          )}
                          <input
                            type='text'
                            className={clsx(
                              'form-control form-control-solid',
                              {'is-invalid': formik.touched.name_idn && formik.errors.name_idn},
                              {
                                'is-valid': formik.touched.name_idn && !formik.errors.name_idn,
                              }
                            )}
                            placeholder={intl.formatMessage({id: 'LABEL_NAME'}) + ' (IDN)'}
                            {...formik.getFieldProps('name_idn')}
                            readOnly={action === 'edit' ? false : true}
                          />
                          {formik.touched.name_idn && formik.errors.name_idn ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger'>
                                {formik.errors.name_idn}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Name */}

                        {/* Code */}
                        <div className='fv-row mb-5'>
                          {formik.values.code !== '' && (
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action === 'edit' ? 'required' : ''
                              }`}
                            >
                              {intl.formatMessage({id: 'LABEL_CODE'})}
                            </label>
                          )}
                          <input
                            type='text'
                            className={clsx(
                              'form-control form-control-solid',
                              {'is-invalid': formik.touched.code && formik.errors.code},
                              {
                                'is-valid': formik.touched.code && !formik.errors.code,
                              }
                            )}
                            placeholder={intl.formatMessage({id: 'LABEL_CODE'})}
                            {...formik.getFieldProps('code')}
                            readOnly={action === 'edit' ? false : true}
                            onChange={(e) => {
                              formik.setFieldValue('code', e.target.value.toUpperCase())
                            }}
                          />
                          {formik.touched.code && formik.errors.code ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger'>{formik.errors.code}</div>
                            </div>
                          ) : null}
                        </div>
                        {/* Code */}
                      </div>
                      {/* Left Form */}

                      {/* Right Form */}
                      <div className='col-lg-6'>
                        {/* Description */}
                        <div className='fv-row mb-5'>
                          {formik.values.detail_eng !== '' && (
                            <label className='form-label fs-6 fw-bolder text-dark'>
                              {intl.formatMessage({id: 'LABEL_DESCRIPTION'})}
                              {' (ENG)'}
                            </label>
                          )}
                          <textarea
                            className={clsx(
                              'form-control form-control-solid',
                              {'is-invalid': formik.touched.detail_eng && formik.errors.detail_eng},
                              {
                                'is-valid': formik.touched.detail_eng && !formik.errors.detail_eng,
                              }
                            )}
                            placeholder={intl.formatMessage({id: 'LABEL_DESCRIPTION'}) + ' (ENG)'}
                            {...formik.getFieldProps('detail_eng')}
                            readOnly={action === 'edit' ? false : true}
                            rows={3}
                          />
                        </div>
                        <div className='fv-row mb-5'>
                          {formik.values.detail_idn !== '' && (
                            <label className='form-label fs-6 fw-bolder text-dark'>
                              {intl.formatMessage({id: 'LABEL_DESCRIPTION'})}
                              {' (IDN)'}
                            </label>
                          )}
                          <textarea
                            className={clsx(
                              'form-control form-control-solid',
                              {'is-invalid': formik.touched.detail_idn && formik.errors.detail_idn},
                              {
                                'is-valid': formik.touched.detail_idn && !formik.errors.detail_idn,
                              }
                            )}
                            placeholder={intl.formatMessage({id: 'LABEL_DESCRIPTION'}) + ' (IDN)'}
                            {...formik.getFieldProps('detail_idn')}
                            readOnly={action === 'edit' ? false : true}
                            rows={3}
                          />
                        </div>
                        {/* Description */}

                        {/* Status */}
                        <div className='fv-row mb-5'>
                          {formik.values.status !== '' && (
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action === 'edit' ? 'required' : ''
                              }`}
                            >
                              Status
                            </label>
                          )}
                          <div className='form-check form-switch form-check-custom form-check-solid mt-1'>
                            <Toggle
                              size='lg'
                              readOnly={action !== 'edit' ? true : false}
                              checkedChildren={intl.formatMessage({id: 'LABEL_ACTIVE'})}
                              unCheckedChildren={intl.formatMessage({id: 'LABEL_INACTIVE'})}
                              checked={formik.values.status === 't' ? true : false}
                              onChange={() =>
                                formik.setFieldValue(
                                  'status',
                                  formik.values.status === 't' ? 'f' : 't'
                                )
                              }
                            />
                          </div>
                          {formik.touched.status && formik.errors.status ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {formik.errors.status}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Status */}
                      </div>
                      {/* Right Form */}
                    </div>
                  </div>
                  {/* Data */}

                  {/* Detail */}
                  <div
                    className={clsx('tab-pane', {
                      active: tab === 'log',
                    })}
                  >
                    <FormLog payload={parameter} formik={formik} />
                  </div>
                  {/* Detail */}
                </div>
              </div>
              {/* Tab Body */}
            </form>
          )}
        </div>
        {/* Form */}
      </Drawer.Body>

      {/* Dialog */}
      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(true)}
        className='modal fade show d-block'
      >
        <Dialog.Panel className='modal-dialog modal-fullscreen'>
          <div className='modal-content'>
            <Dialog.Title className='modal-header'>
              {intl.formatMessage({id: 'DIALOG_HEADER_TITLE'})}
            </Dialog.Title>
            <div className='modal-body scroll-y mx-2 mx-xl-2 my-2'>
              {/* Name */}
              {parameterModels.name_eng !== formik.values.name_eng && (
                <div className='fv-row mb-5'>
                  <label className='form-label fs-6 fw-bolder text-dark'>
                    {intl.formatMessage({id: 'LABEL_NAME'})}
                    {' (ENG)'}
                  </label>
                  {action !== 'add' && (
                    <input
                      type='text'
                      className='form-control form-control-solid'
                      value={parameterModels.name_eng}
                      readOnly
                    />
                  )}
                  <input
                    type='text'
                    className='form-control form-control-solid text-primary is-valid'
                    value={formik.values.name_eng}
                    readOnly
                  />
                </div>
              )}
              {parameterModels.name_idn !== formik.values.name_idn && (
                <div className='fv-row mb-5'>
                  <label className='form-label fs-6 fw-bolder text-dark'>
                    {intl.formatMessage({id: 'LABEL_NAME'})}
                    {' (IDN)'}
                  </label>
                  {action !== 'add' && (
                    <input
                      type='text'
                      className='form-control form-control-solid'
                      value={parameterModels.name_idn}
                      readOnly
                    />
                  )}
                  <input
                    type='text'
                    className='form-control form-control-solid text-primary is-valid'
                    value={formik.values.name_idn}
                    readOnly
                  />
                </div>
              )}
              {/* Name */}

              {/* Code */}
              {parameterModels.code !== formik.values.code && (
                <div className='fv-row mb-5'>
                  <label className='form-label fs-6 fw-bolder text-dark'>
                    {intl.formatMessage({id: 'LABEL_CODE'})}
                  </label>
                  {action !== 'add' && (
                    <input
                      type='text'
                      className='form-control form-control-solid'
                      value={parameterModels.code}
                      readOnly
                    />
                  )}
                  <input
                    type='text'
                    className='form-control form-control-solid text-primary is-valid'
                    value={formik.values.code}
                    readOnly
                  />
                </div>
              )}
              {/* Code */}

              {/* Parent */}
              {parameterModels.parent_id !== formik.values.parent_id && (
                <div className='fv-row mb-5'>
                  <label className='form-label fs-6 fw-bolder text-dark'>
                    {intl.formatMessage({id: 'LABEL_PARENT'})}
                  </label>
                  {action !== 'add' && (
                    <input
                      className='form-control form-control-solid'
                      value={
                        local === 'id'
                          ? parameterModels.parent_name_idn
                          : parameterModels.parent_name_eng
                      }
                      readOnly
                    />
                  )}
                  <input
                    className='form-control form-control-solid text-primary is-valid'
                    value={
                      local === 'id' ? formik.values.parent_name_idn : formik.values.parent_name_eng
                    }
                    readOnly
                  />
                </div>
              )}
              {/* Parent */}

              {/* Status */}
              {action === 'add' ? (
                <div className='fv-row mb-5'>
                  <label className='form-label fs-6 fw-bolder text-dark'>Status</label>
                  <input
                    type='text'
                    className='form-control form-control-solid text-primary is-valid'
                    value={
                      formik.values.status === 't'
                        ? intl.formatMessage({id: 'LABEL_ACTIVE'})
                        : intl.formatMessage({id: 'LABEL_INACTIVE'})
                    }
                    readOnly
                  />
                </div>
              ) : (
                parameterModels.status !== formik.values.status && (
                  <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark'>Status</label>
                    <input
                      type='text'
                      className='form-control form-control-solid'
                      value={
                        parameterModels.status === 't'
                          ? intl.formatMessage({id: 'LABEL_ACTIVE'})
                          : intl.formatMessage({id: 'LABEL_INACTIVE'})
                      }
                      readOnly
                    />
                    <input
                      type='text'
                      className='form-control form-control-solid text-primary is-valid'
                      value={
                        formik.values.status === 't'
                          ? intl.formatMessage({id: 'LABEL_ACTIVE'})
                          : intl.formatMessage({id: 'LABEL_INACTIVE'})
                      }
                      readOnly
                    />
                  </div>
                )
              )}
              {/* Status */}

              {/* Description */}
              {parameterModels.detail_eng !== formik.values.detail_eng && (
                <div className='fv-row mb-5'>
                  <label className='form-label fs-6 fw-bolder text-dark'>
                    {intl.formatMessage({id: 'LABEL_DESCRIPTION'})}
                    {' (ENG)'}
                  </label>
                  {action !== 'add' && (
                    <textarea
                      className='form-control form-control-solid'
                      value={parameterModels.detail_eng}
                      readOnly
                    />
                  )}
                  <textarea
                    className='form-control form-control-solid text-primary is-valid'
                    value={formik.values.detail_eng}
                    readOnly
                  />
                </div>
              )}
              {parameterModels.detail_idn !== formik.values.detail_idn && (
                <div className='fv-row mb-5'>
                  <label className='form-label fs-6 fw-bolder text-dark'>
                    {intl.formatMessage({id: 'LABEL_DESCRIPTION'})}
                    {' (IDN)'}
                  </label>
                  {action !== 'add' && (
                    <textarea
                      className='form-control form-control-solid'
                      value={parameterModels.detail_idn}
                      readOnly
                    />
                  )}
                  <textarea
                    className='form-control form-control-solid text-primary is-valid'
                    value={formik.values.detail_idn}
                    readOnly
                  />
                </div>
              )}
              {/* Description */}
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-outline btn-btn-outline-info me-3'
                data-kt-users-modal-action='close'
                onClick={() => setIsDialogOpen(false)}
              >
                {intl.formatMessage({id: 'BUTTON_LABEL_BACK'})}
              </button>

              <button
                type='button'
                className={`btn ${loading ? 'btn-secondary' : 'btn-success'}`}
                data-kt-users-modal-action='submit'
                onClick={() => formik.handleSubmit()}
                disabled={loading ? true : false}
              >
                {!loading && (
                  <span className='indicator-label'>
                    {intl.formatMessage({id: 'BUTTON_LABEL_SUBMIT'})}
                  </span>
                )}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    {intl.formatMessage({id: 'BUTTON_LABEL_PLEASE_WAIT'})}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
      {/* Dialog */}
    </>
  )
}

export {ViewDrawerForm}
