import {MenuID, ListResponse} from '.'

export type ListModel = {
  menu_id?: MenuID
  module_id?: string
  site_id?: string
  parent_id?: string
  access?: number
  for_order?: number
  level?: number
  has_child?: string
  icon?: string
  name_eng?: string
  name_idn?: string
  path?: string
  status?: string
}

export type ListQueryResponse = ListResponse<Array<ListModel>>

export type GetDataByIDModel = {
  created_at?: string
  created_by?: string
  updated_at?: string
  updated_by?: string
  access?: number
  for_order?: number
  level?: number
  has_child?: string
  icon?: string
  name_eng?: string
  name_idn?: string
  path?: string
  status?: string
  module_id?: string
  site_id?: string
  menu_id?: string
  parent_id?: string
  menu_menu_id?: string
}

export const initialData: GetDataByIDModel = {
  created_at: '',
  created_by: '',
  access: 1000000,
  for_order: 1,
  level: 1,
  has_child: '',
  icon: '',
  name_eng: '',
  name_idn: '',
  path: '',
  status: '',
  updated_at: '',
  updated_by: '',
  module_id: '',
  site_id: '',
  parent_id: '',
  menu_id: '',
  menu_menu_id: '',
}
