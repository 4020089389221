import axios, {AxiosResponse} from 'axios'

import {APIResponse} from 'app/components'

import {ListQueryResponse} from './_list_models'
import {LogID} from '.'

const API_APP_URL = process.env.REACT_APP_APP_API_URL
const X_API_KEY = process.env.REACT_APP_APP_API_KEY
const GET_LOGS_LIST_URL = `${API_APP_URL}/log?`
const GET_LOG_URL = `${API_APP_URL}/log/fetch-log?`

const getList = (query: any): Promise<ListQueryResponse> => {
  return axios
    .get(`${GET_LOGS_LIST_URL}${query}`, {
      headers: {'X-API-KEY': `${X_API_KEY}`},
    })
    .then((d: AxiosResponse<ListQueryResponse>) => d.data)
}

const getDataByID = (log_id: LogID): Promise<APIResponse | undefined> => {
  return axios
    .get(`${GET_LOG_URL}log_id=${log_id}`, {
      headers: {'X-API-KEY': `${X_API_KEY}`},
    })
    .then((response: AxiosResponse<APIResponse>) => response.data)
}

export {getList, getDataByID}
