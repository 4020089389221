import {FC} from 'react'
import {useIntl} from 'react-intl'

type Props = {
  row?: number
}

function coloringRows(rowLabel: any) {
  switch (rowLabel) {
    case 1:
      return 'btn-primary'
    default:
      return 'btn-outline btn-outline-dashed btn-outline-primary'
  }
}

const RowLevelCellFormatter: FC<Props> = ({row}) => {
  const intl = useIntl()
  return (
    <div className={`btn ${coloringRows(row)} btn-sm p-2`}>
      {row === 1
        ? intl.formatMessage({id: 'LABEL_LEVEL_PARENT'})
        : intl.formatMessage({id: 'LABEL_LEVEL_CHILD'})}
    </div>
  )
}

export {RowLevelCellFormatter}
