import {FC} from 'react'

type Props = {
  row?: string
  level?: number
}

function paddingLevel(level?: number) {
  switch (level) {
    case 1:
      return ''
    case 2:
      return 'text-muted ml-3'
    case 3:
      return 'text-muted ml-6'
    case 4:
      return 'text-muted ml-9'
    case 5:
      return 'text-muted ml-12'
    case 6:
      return 'text-muted ml-15'
    case 7:
      return 'text-muted ml-18'
    case 8:
      return 'text-muted ml-21'
    case 9:
      return 'text-muted ml-24'
    case 10:
      return 'text-muted ml-27'
    default:
      return ''
  }
}

function paddingName(level?: number, name?: string) {
  switch (level) {
    case 1:
      return name
    default:
      return '.' + name
  }
}

const RowNameLevelCellFormatter: FC<Props> = ({row, level}) => {
  return (
    <div className={`${paddingLevel(level)}`} style={{paddingLeft: level === 1 ? '1px' : '10px'}}>
      {paddingName(level, row)}
    </div>
  )
}

export {RowNameLevelCellFormatter}
