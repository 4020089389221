import {FC} from 'react'
import {Whisper, Tooltip} from 'rsuite'

type Props = {
  count: any
  data: any
  id: any
}

const RowTooltipSalesOrderCountFormatter: FC<Props> = ({count, data, id}) => {
  return (
    <Whisper
      key={`whisper-${id}`}
      placement='top'
      controlId='control-id-hover'
      trigger='hover'
      speaker={
        <Tooltip id={`tooltip-count-${id}`} key={`tooltip-count-key-${id}`}>
          {
            <div className='menu-item' key={`menu-item-${id}`}>
              {data.map((item: any, index: number) => (
                <>
                  <div className='menu-link without-sub' key={`menu-link-${index}`}>
                    <span className='menu-bullet' key={`menu-bullet-${index}`}>
                      <span className='bullet bullet-dot' key={`bullet-dot-${index}`}></span>
                    </span>
                    <span className='menu-title' key={`menu-title-${index}`}>
                      {item.name}
                    </span>
                  </div>
                </>
              ))}
            </div>
          }
        </Tooltip>
      }
    >
      <div className={`badge badge-secondary fw-bolder`} key={`count-${id}`}>
        {count}
      </div>
    </Whisper>
  )
}

export {RowTooltipSalesOrderCountFormatter}
