import {useState} from 'react'
import {useIntl} from 'react-intl'
import {DateRangePicker, CheckPicker, Drawer} from 'rsuite'
import {FormattedMessage} from 'react-intl'
import moment from 'moment'
import subDays from 'date-fns/subDays'
import startOfWeek from 'date-fns/startOfWeek'
import endOfWeek from 'date-fns/endOfWeek'
import addDays from 'date-fns/addDays'
import startOfMonth from 'date-fns/startOfMonth'
import endOfMonth from 'date-fns/endOfMonth'
import addMonths from 'date-fns/addMonths'
import {Checkbox} from 'rsuite'

import {isMobileDevice} from '_metronic/assets/ts/_utils'
import {FilterAction, FilterButton} from 'app/components'

import {
  useAction,
  useListQueryRequest,
  useListQueryResponse,
  useListQueryResponseFilterMeta,
} from '../../providers'

const footerStyles = {
  padding: '0px 2px',
  borderTop: '1px solid #e5e5e5',
}

const ListFilter = () => {
  const intl = useIntl()
  const mobile = isMobileDevice()
  const {showFilter, setShowFilter} = useAction()
  const {state, updateState} = useListQueryRequest()
  const {isLoading} = useListQueryResponse()
  const filterMeta = useListQueryResponseFilterMeta()
  const [activity, setActivity] = useState(
    state?.filter !== undefined ? state?.filter.activity : []
  )
  const [user_id, setUserID] = useState(state?.filter !== undefined ? state?.filter.user_id : [])
  const optionsActivity =
    filterMeta?.metaActivity === undefined
      ? []
      : (filterMeta?.metaActivity as unknown as any[]).map((activity, index) => ({
          value: activity.activity,
          label: activity.activity,
        }))
  const optionsUser =
    filterMeta?.metaUser === undefined
      ? []
      : (filterMeta?.metaUser as unknown as any[]).map((user) => ({
          value: user.user_id,
          label: user.username,
        }))

  const [daterange, setDaterange] = useState(state?.daterange === null ? null : state?.daterange)
  const handleCallback = (period: any) => {
    if (period !== null) {
      setDaterange(
        moment(period[0]).subtract(1, 'days').format('YYYY-MM-DD') +
          ' 17:00:00' +
          '[-]' +
          moment(period[1]).format('YYYY-MM-DD') +
          ' 16:59:59'
      )
    } else {
      setDaterange(null)
    }
  }

  const resetData = () => {
    setActivity([])
    setUserID([])
    updateState({filter: undefined})
  }

  const filterData = () => {
    updateState({
      filter: {activity, user_id},
    })

    if (daterange) {
      updateState({
        daterange,
        filter: {activity, user_id},
      })
    }
    setShowFilter(false)
  }

  return (
    <>
      <FilterButton
        isLoading={isLoading}
        state={state}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
      />
      <Drawer
        open={showFilter}
        onClose={() => {
          setShowFilter(false)
        }}
        placement='right'
        style={mobile ? {width: '100%'} : {}}
      >
        <Drawer.Header closeButton>
          <Drawer.Actions>
            {/* Actions */}
            <FilterAction filterData={filterData} isLoading={isLoading} resetData={resetData} />
            {/* Actions */}
          </Drawer.Actions>
        </Drawer.Header>

        {/* Content */}
        <div className='card-body table-responsive m-5'>
          {/* Input group */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>
              {intl.formatMessage({id: 'LABEL_FILTER_DATERANGE'})}
            </label>
            <DateRangePicker
              showOneCalendar={mobile ? true : false}
              defaultValue={
                daterange === null
                  ? undefined
                  : [
                      new Date(
                        moment(daterange?.split('[-]')[0])
                          .add(1, 'days')
                          .toDate()
                      ),
                      new Date(moment(daterange?.split('[-]')[1]).toDate()),
                    ]
              }
              isoWeek
              block
              cleanable={false}
              format='yyyy-MM-dd'
              ranges={[
                {
                  label: <FormattedMessage id='LABEL_TODAY' />,
                  value: [new Date(), new Date()],
                },
                {
                  label: <FormattedMessage id='LABEL_YESTERDAY' />,
                  value: [addDays(new Date(), -1), addDays(new Date(), -1)],
                },
                {
                  label: <FormattedMessage id='LABEL_THIS_WEEK' />,
                  value: [startOfWeek(new Date()), endOfWeek(new Date())],
                },
                {
                  label: <FormattedMessage id='LABEL_LAST_7_DAYS' />,
                  value: [subDays(new Date(), 6), new Date()],
                },
                {
                  label: <FormattedMessage id='LABEL_LAST_30_DAYS' />,
                  value: [subDays(new Date(), 29), new Date()],
                },
                {
                  label: <FormattedMessage id='LABEL_THIS_MONTH' />,
                  value: [startOfMonth(new Date()), new Date()],
                },
                {
                  label: <FormattedMessage id='LABEL_LAST_MONTH' />,
                  value: [
                    startOfMonth(addMonths(new Date(), -1)),
                    endOfMonth(addMonths(new Date(), -1)),
                  ],
                },
                {
                  label: <FormattedMessage id='LABEL_THIS_YEAR' />,
                  value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
                },
                {
                  label: <FormattedMessage id='LABEL_LAST_YEAR' />,
                  value: [
                    new Date(new Date().getFullYear() - 1, 0, 1),
                    new Date(new Date().getFullYear(), 0, 0),
                  ],
                },
              ]}
              placement={`bottomStart`}
              placeholder={`-- ${intl.formatMessage({id: 'LABEL_SELECT'})} --`}
              onChange={handleCallback}
            />
          </div>
          {/* Input group */}

          {/* Input group */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>
              {intl.formatMessage({id: 'LABEL_ACTION'})}
            </label>
            <CheckPicker
              block
              placeholder={`-- ${intl.formatMessage({id: 'LABEL_SELECT'})} --`}
              data={optionsActivity}
              onChange={(val) => {
                if (val.length > 0) {
                  setActivity(val)
                } else {
                  setActivity([])
                }
              }}
              defaultValue={(optionsActivity as unknown as any[]).map((dt: any) => ({
                value: dt.value,
                label: dt.label,
              }))}
              value={activity}
              renderExtraFooter={() =>
                optionsActivity.length > 0 ? (
                  <div style={footerStyles}>
                    <Checkbox
                      indeterminate={
                        activity.length > 0 &&
                        activity.length < (optionsActivity as unknown as any[]).length
                      }
                      checked={activity.length === (optionsActivity as unknown as any[]).length}
                      onChange={(data, checked) => {
                        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                        if (checked) {
                          setActivity((optionsActivity as unknown as any[]).map((e) => e.value))
                        } else {
                          setActivity([])
                        }
                      }}
                    >
                      {activity.length === (optionsActivity as unknown as any[]).length
                        ? intl.formatMessage({id: 'LABEL_UNSELECT_ALL'})
                        : intl.formatMessage({id: 'LABEL_SELECT_ALL'})}
                    </Checkbox>
                  </div>
                ) : (
                  <></>
                )
              }
            />
          </div>
          {/* Input group */}

          {/* Input group */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>
              {intl.formatMessage({id: 'LABEL_USERNAME'})}
            </label>
            <CheckPicker
              block
              placeholder={`-- ${intl.formatMessage({id: 'LABEL_SELECT'})} --`}
              data={optionsUser}
              onChange={(val) => {
                if (val.length > 0) {
                  setUserID(val)
                } else {
                  setUserID([])
                }
              }}
              defaultValue={(optionsUser as unknown as any[]).map((dt: any) => ({
                value: dt.value,
                label: dt.label,
              }))}
              value={user_id}
              renderExtraFooter={() =>
                optionsUser.length > 0 ? (
                  <div style={footerStyles}>
                    <Checkbox
                      indeterminate={
                        user_id.length > 0 &&
                        user_id.length < (optionsUser as unknown as any[]).length
                      }
                      checked={user_id.length === (optionsUser as unknown as any[]).length}
                      onChange={(data, checked) => {
                        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                        if (checked) {
                          setUserID((optionsUser as unknown as any[]).map((e) => e.value))
                        } else {
                          setUserID([])
                        }
                      }}
                    >
                      {user_id.length === (optionsUser as unknown as any[]).length
                        ? intl.formatMessage({id: 'LABEL_UNSELECT_ALL'})
                        : intl.formatMessage({id: 'LABEL_SELECT_ALL'})}
                    </Checkbox>
                  </div>
                ) : (
                  <></>
                )
              }
            />
          </div>
          {/* Input group */}
        </div>
        {/* Content */}
      </Drawer>
    </>
  )
}

export {ListFilter}
