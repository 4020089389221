import {FC} from 'react'
import {useIntl} from 'react-intl'

type Props = {
  row?: string
}

function coloringRows(rowLabel: any) {
  switch (rowLabel) {
    case '00':
      return '-success'
    case '':
      return '-secondary'
    default:
      return '-danger'
  }
}

const RowESBSalesStatusCellFormatter: FC<Props> = ({row}) => {
  const intl = useIntl()
  return (
    <div className={`badge badge${coloringRows(row)} fw-bolder`}>
      {row === '00'
        ? intl.formatMessage({id: 'LABEL_ESB_SYNC_SUCCESS'})
        : row === '01'
          ? intl.formatMessage({id: 'LABEL_ESB_SYNC_ERROR'})
          : intl.formatMessage({id: 'LABEL_ESB_SYNC_NULL'})}
    </div>
  )
}

export {RowESBSalesStatusCellFormatter}
