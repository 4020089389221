import {FC, lazy, useEffect, useRef, useState} from 'react'
import toast from 'react-hot-toast'
import {useIntl} from 'react-intl'
import {Navigate, Outlet, Route, Routes, useLocation} from 'react-router-dom'

import {PageLink, PageTitle} from '_metronic/layout/core'
import {SuspendViewComponents} from 'app/components'
import {CurrentMenuAccessModel} from 'app/context/models'
import {useUserGroupModules} from 'app/context/providers'
import {getCurrentMenuAccessByRoleIDAndMenuID} from 'app/context/requests'
import {removeAuth, useAuth} from 'app/modules/auth'

import * as holdingListState from 'app/modules/backoffice-orins-pos/pos-settings/masters/holding/providers/ListQueryStateContext'
import {initialListQueryState as holdingListQueryState} from 'app/modules/backoffice-orins-pos/pos-settings/masters/holding/helpers/ListQueryModel'
import * as companyListState from 'app/modules/backoffice-orins-pos/pos-settings/masters/company/providers/ListQueryStateContext'
import {initialListQueryState as companyListQueryState} from 'app/modules/backoffice-orins-pos/pos-settings/masters/company/helpers/ListQueryModel'
import * as brandListState from 'app/modules/backoffice-orins-pos/pos-settings/masters/brand/providers/ListQueryStateContext'
import {initialListQueryState as brandListQueryState} from 'app/modules/backoffice-orins-pos/pos-settings/masters/brand/helpers/ListQueryModel'
import * as provinceListState from 'app/modules/backoffice-orins-pos/pos-settings/masters/province/providers/ListQueryStateContext'
import {initialListQueryState as provinceListQueryState} from 'app/modules/backoffice-orins-pos/pos-settings/masters/province/helpers/ListQueryModel'
import * as cityListState from 'app/modules/backoffice-orins-pos/pos-settings/masters/city/providers/ListQueryStateContext'
import {initialListQueryState as cityListQueryState} from 'app/modules/backoffice-orins-pos/pos-settings/masters/city/helpers/ListQueryModel'
import * as districtListState from 'app/modules/backoffice-orins-pos/pos-settings/masters/districts/providers/ListQueryStateContext'
import {initialListQueryState as districtListQueryState} from 'app/modules/backoffice-orins-pos/pos-settings/masters/districts/helpers/ListQueryModel'
import * as villageListState from 'app/modules/backoffice-orins-pos/pos-settings/masters/villages/providers/ListQueryStateContext'
import {initialListQueryState as villageListQueryState} from 'app/modules/backoffice-orins-pos/pos-settings/masters/villages/helpers/ListQueryModel'
import * as areaListState from 'app/modules/backoffice-orins-pos/pos-settings/masters/area/providers/ListQueryStateContext'
import {initialListQueryState as areaListQueryState} from 'app/modules/backoffice-orins-pos/pos-settings/masters/area/helpers/ListQueryModel'
import * as clusterListState from 'app/modules/backoffice-orins-pos/pos-settings/masters/cluster/providers/ListQueryStateContext'
import {initialListQueryState as clusterListQueryState} from 'app/modules/backoffice-orins-pos/pos-settings/masters/cluster/helpers/ListQueryModel'
import * as outletListState from 'app/modules/backoffice-orins-pos/pos-settings/masters/outlet/providers/ListQueryStateContext'
import {initialListQueryState as outletListQueryState} from 'app/modules/backoffice-orins-pos/pos-settings/masters/outlet/helpers/ListQueryModel'

const HoldingPageProvider = lazy(() => import('./holding/HoldingPageProvider'))
const BrandPageProvider = lazy(() => import('./brand/BrandPageProvider'))
const OutletPageProvider = lazy(() => import('./outlet/OutletPageProvider'))
const CompanyPageProvider = lazy(() => import('./company/CompanyPageProvider'))
const ProvincePageProvider = lazy(() => import('./province/ProvincePageProvider'))
const CityPageProvider = lazy(() => import('./city/CityPageProvider'))
const DistrictPageProvider = lazy(() => import('./districts/DistrictPageProvider'))
const VillagesPageProvider = lazy(() => import('./villages/VillagesPageProvider'))
const GradePageProvider = lazy(() => import('./grade/GradePageProvider'))
const AreaPageProvider = lazy(() => import('./area/AreaPageProvider'))
const ClusterPageProvider = lazy(() => import('./cluster/ClusterPageProvider'))

const BackOfficeOrinsPOSMastersWrapper: FC = () => {
  const intl = useIntl()
  const {pathname} = useLocation()
  const {
    currentModuleLabel,
    currentModulePath,
    currentSiteLabel,
    currentSitePath,
    currentParentMenuLabel,
    currentParentMenuPath,
    currentMenuLabel,
    currentMenuID,
  } = useUserGroupModules()
  const didRequest = useRef(false)
  const {currentUser} = useAuth()
  const [access, setAccess] = useState<CurrentMenuAccessModel | undefined>(undefined)

  const breadCrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({id: 'LABEL_HOMEPAGE'}),
      path: 'homepage',
      isSeparator: false,
      isActive: false,
    },
    {
      title: currentModuleLabel,
      path: currentModulePath,
      isSeparator: false,
      isActive: false,
    },
    {
      title: currentSiteLabel,
      path: currentSitePath,
      isSeparator: false,
      isActive: false,
    },
    {
      title: currentParentMenuLabel,
      path: currentParentMenuPath,
      isSeparator: false,
      isActive: false,
    },
  ]

  useEffect(() => {
    if (holdingListState.getListState() === undefined) {
      holdingListState.setListState(holdingListQueryState)
    }
    if (companyListState.getListState() === undefined) {
      companyListState.setListState(companyListQueryState)
    }
    if (brandListState.getListState() === undefined) {
      brandListState.setListState(brandListQueryState)
    }
    if (provinceListState.getListState() === undefined) {
      provinceListState.setListState(provinceListQueryState)
    }
    if (cityListState.getListState() === undefined) {
      cityListState.setListState(cityListQueryState)
    }
    if (districtListState.getListState() === undefined) {
      districtListState.setListState(districtListQueryState)
    }
    if (villageListState.getListState() === undefined) {
      villageListState.setListState(villageListQueryState)
    }
    if (areaListState.getListState() === undefined) {
      areaListState.setListState(areaListQueryState)
    }
    if (clusterListState.getListState() === undefined) {
      clusterListState.setListState(clusterListQueryState)
    }
    if (outletListState.getListState() === undefined) {
      outletListState.setListState(outletListQueryState)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const requestCurrentMenuAccess = async () => {
      try {
        if (!didRequest.current) {
          const access = await getCurrentMenuAccessByRoleIDAndMenuID(
            currentUser?.role.role_id,
            currentMenuID
          )
          // throw message error
          if (access.data.meta.code === 200) {
            setAccess(access.data.data)
          }
          if (access.data.meta.code === 404 || access.data.meta.code === 500) {
            toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
          }
          if (access.data.meta.code === 401) {
            toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
            removeAuth()
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          if ((error as unknown as any).response.data.meta.code === 401) {
            toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
            removeAuth()
          } else {
            toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
          }
        }
      } finally {
      }

      return () => (didRequest.current = true)
    }

    if (
      currentMenuID !== undefined &&
      currentMenuID !== '' &&
      pathname.split('/')[3] === currentParentMenuPath.split('/')[3]
    ) {
      requestCurrentMenuAccess()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMenuID])

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='holding'
          element={
            <SuspendViewComponents>
              <PageTitle breadcrumbs={breadCrumbs}>{currentMenuLabel}</PageTitle>
              <HoldingPageProvider access={access} />
            </SuspendViewComponents>
          }
        />
        <Route
          path='company'
          element={
            <SuspendViewComponents>
              <PageTitle breadcrumbs={breadCrumbs}>{currentMenuLabel}</PageTitle>
              <CompanyPageProvider access={access} />
            </SuspendViewComponents>
          }
        />
        <Route
          path='brand'
          element={
            <SuspendViewComponents>
              <PageTitle breadcrumbs={breadCrumbs}>{currentMenuLabel}</PageTitle>
              <BrandPageProvider access={access} />
            </SuspendViewComponents>
          }
        />
        <Route
          path='outlet'
          element={
            <SuspendViewComponents>
              <PageTitle breadcrumbs={breadCrumbs}>{currentMenuLabel}</PageTitle>
              <OutletPageProvider access={access} />
            </SuspendViewComponents>
          }
        />
        <Route
          path='province'
          element={
            <SuspendViewComponents>
              <PageTitle breadcrumbs={breadCrumbs}>{currentMenuLabel}</PageTitle>
              <ProvincePageProvider access={access} />
            </SuspendViewComponents>
          }
        />
        <Route
          path='city'
          element={
            <SuspendViewComponents>
              <PageTitle breadcrumbs={breadCrumbs}>{currentMenuLabel}</PageTitle>
              <CityPageProvider access={access} />
            </SuspendViewComponents>
          }
        />
        <Route
          path='districts'
          element={
            <SuspendViewComponents>
              <PageTitle breadcrumbs={breadCrumbs}>{currentMenuLabel}</PageTitle>
              <DistrictPageProvider access={access} />
            </SuspendViewComponents>
          }
        />
        <Route
          path='villages'
          element={
            <SuspendViewComponents>
              <PageTitle breadcrumbs={breadCrumbs}>{currentMenuLabel}</PageTitle>
              <VillagesPageProvider access={access} />
            </SuspendViewComponents>
          }
        />
        <Route
          path='grade'
          element={
            <SuspendViewComponents>
              <PageTitle breadcrumbs={breadCrumbs}>{currentMenuLabel}</PageTitle>
              <GradePageProvider access={access} />
            </SuspendViewComponents>
          }
        />
        <Route
          path='area'
          element={
            <SuspendViewComponents>
              <PageTitle breadcrumbs={breadCrumbs}>{currentMenuLabel}</PageTitle>
              <AreaPageProvider access={access} />
            </SuspendViewComponents>
          }
        />
        <Route
          path='cluster'
          element={
            <SuspendViewComponents>
              <PageTitle breadcrumbs={breadCrumbs}>{currentMenuLabel}</PageTitle>
              <ClusterPageProvider access={access} />
            </SuspendViewComponents>
          }
        />
        <Route
          index
          element={<Navigate to='/backoffice-orins-pos/pos-settings/masters/holding' />}
        />
      </Route>
    </Routes>
  )
}

export {BackOfficeOrinsPOSMastersWrapper}
