import {useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import toast from 'react-hot-toast'
import {Checkbox, InputPicker, CheckPicker, Drawer} from 'rsuite'

import {isMobileDevice} from '_metronic/assets/ts/_utils'
import {FilterAction, FilterButton} from 'app/components'
import {removeAuth} from 'app/modules/auth'

import {useAction, useListQueryRequest, useListQueryResponse} from '../../providers'
import {
  fetchBrandOptions,
  fetchCompanyOptions,
  fetchCategoriesOptions,
  fetchOutletOptions,
} from '../../helpers'

const footerStyles = {
  padding: '0px 2px',
  borderTop: '1px solid #e5e5e5',
}

const ListFilter = () => {
  const intl = useIntl()
  const mobile = isMobileDevice()
  const {state, updateState} = useListQueryRequest()
  const {isLoading} = useListQueryResponse()
  const {
    showFilter,
    setShowFilter,
    holdingOptions,
    companyOptions,
    setCompanyOptions,
    brandOptions,
    setBrandOptions,
    categoriesOptions,
    setCategoriesOptions,
    outletOptions,
    setOutletOptions,
  } = useAction()

  const [holdingID, setHoldingID] = useState(
    state?.filter !== undefined ? state?.filter.holding_id : []
  )
  const [holdingName, setHoldingName] = useState(
    state?.filter !== undefined ? state?.filter.holding_name : []
  )
  const [companyID, setCompanyID] = useState(
    state?.filter !== undefined ? state?.filter.company_id : []
  )
  const [companyName, setCompanyName] = useState(
    state?.filter !== undefined ? state?.filter.company_name : []
  )
  const [brandID, setBrandID] = useState(state?.filter !== undefined ? state?.filter.brand_id : [])
  const [brandName, setBrandName] = useState(
    state?.filter !== undefined ? state?.filter.brand_name : []
  )
  const [outletID, setOutletID] = useState(
    state?.filter !== undefined ? state?.filter.outlet_id : []
  )
  const [outletName, setOutletName] = useState(
    state?.filter !== undefined ? state?.filter.outlet_name : []
  )
  const [categoriesID, setCategoriesID] = useState(
    state?.filter !== undefined ? state?.filter.category_id : []
  )
  const [categoriesName, setCategoriesName] = useState(
    state?.filter !== undefined ? state?.filter.category_name : []
  )
  const optionsHolding =
    holdingOptions === undefined
      ? []
      : (holdingOptions as unknown as any[]).map((holdings: any) => ({
          value: holdings.holding_id,
          label: holdings.name,
        }))
  const optionsCompany =
    companyOptions === undefined
      ? []
      : (companyOptions as unknown as any[]).map((company: any) => ({
          value: company.company_id,
          label: company.name,
        }))
  const optionsBrand =
    brandOptions === undefined
      ? []
      : (brandOptions as unknown as any[]).map((brand: any) => ({
          value: brand.brand_id,
          label: brand.name,
        }))
  const optionsOutlet =
    outletOptions === undefined
      ? []
      : (outletOptions as unknown as any[]).map((outlet: any) => ({
          value: outlet.outlet_id,
          label: outlet.name,
        }))
  const optionsCategories =
    categoriesOptions === undefined
      ? []
      : (categoriesOptions as unknown as any[]).map((category: any) => ({
          value: category.category_id,
          label: category.name,
        }))
  const optionStatus = [
    {
      value: 't',
      label: intl.formatMessage({id: 'LABEL_ACTIVE'}),
    },
    {
      value: 'f',
      label: intl.formatMessage({id: 'LABEL_INACTIVE'}),
    },
  ]
  const optionYesNo = [
    {
      value: 't',
      label: intl.formatMessage({id: 'LABEL_YES'}),
    },
    {
      value: 'f',
      label: intl.formatMessage({id: 'LABEL_NO'}),
    },
  ]
  const optionNoYes = [
    {
      value: 'f',
      label: intl.formatMessage({id: 'LABEL_YES'}),
    },
    {
      value: 't',
      label: intl.formatMessage({id: 'LABEL_NO'}),
    },
  ]
  const [status, setStatus] = useState(state?.filter !== undefined ? state?.filter.status : '')
  const [isDefault, setIsDefault] = useState(
    state?.filter !== undefined ? state?.filter.is_default : ''
  )
  const [hasVariant, setHasVariant] = useState(
    state?.filter !== undefined ? state?.filter.has_variant : ''
  )
  const [isMultiplePrice, setIsMultiplePrice] = useState(
    state?.filter !== undefined ? state?.filter.is_multiple_price_sales_type : ''
  )
  const [isLifetime, setIsLifetime] = useState(
    state?.filter !== undefined ? state?.filter.is_lifetime : ''
  )

  const didRequestCompany = useRef(false)
  const didRequestBrand = useRef(false)
  const didRequestOutlet = useRef(false)
  const didRequestCategories = useRef(false)
  const [allowCompany, setAllowCompany] = useState(true)
  const [allowBrand, setAllowBrand] = useState(true)

  const resetData = () => {
    setHoldingID([])
    setHoldingName([])
    setCompanyID([])
    setCompanyName([])
    setBrandID([])
    setBrandName([])
    setOutletID([])
    setOutletName([])
    setCategoriesID([])
    setCategoriesName([])
    setStatus('')
    setIsDefault('')
    setHasVariant('')
    setIsMultiplePrice('')
    setIsLifetime('')
    updateState({filter: undefined})
  }

  const filterData = () => {
    if (holdingID.length > 0) {
      if (holdingID.length > 0 && companyID.length === 0) {
        setAllowCompany(false)
        setTimeout(function () {
          setAllowCompany(true)
        }, 5e3)
        return
      }
    }

    if (companyID.length > 0) {
      if (companyID.length > 0 && brandID.length === 0) {
        setAllowBrand(false)
        setTimeout(function () {
          setAllowBrand(true)
        }, 5e3)
        return
      }
    }

    updateState({
      filter: {
        holding_id: holdingID,
        company_id: companyID,
        brand_id: brandID,
        category_id: categoriesID,
        outlet_id: outletID,
        companyName,
        holdingName,
        brandName,
        categoriesName,
        outletName,
        status,
        is_default: isDefault,
        has_variant: hasVariant,
        is_multiple_price_sales_type: isMultiplePrice,
        is_lifetime: isLifetime,
      },
    })
    setShowFilter(false)
  }

  useEffect(() => {
    const requestCompanyOptions = async () => {
      try {
        if (!didRequestCompany.current) {
          const options = await fetchCompanyOptions('filter', holdingID)
          // throw message error
          if (options.data.meta.code === 200) {
            // set options
            setCompanyOptions(options.data.data)
          }
        }
      } catch (error: any) {
        console.error(error)
        if (error.response.status === 401) {
          toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
          removeAuth()
        } else {
          toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
        }
      } finally {
      }

      return () => (didRequestCompany.current = true)
    }

    holdingID !== undefined && holdingID.length > 0
      ? requestCompanyOptions()
      : setCompanyOptions([])
    // eslint-disable-next-line
  }, [holdingID])

  useEffect(() => {
    const requestBrandOptions = async () => {
      try {
        if (!didRequestBrand.current) {
          const options = await fetchBrandOptions('filter', companyID)
          // throw message error
          if (options.data.meta.code === 200) {
            // set options
            setBrandOptions(options.data.data)
          }
        }
      } catch (error: any) {
        console.error(error)
        if (error.response.status === 401) {
          toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
          removeAuth()
        } else {
          toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
        }
      } finally {
      }

      return () => (didRequestBrand.current = true)
    }

    companyID !== undefined && companyID.length > 0 ? requestBrandOptions() : setBrandOptions([])
    // eslint-disable-next-line
  }, [companyID])

  useEffect(() => {
    const requestOutletOptions = async () => {
      try {
        if (!didRequestOutlet.current) {
          const options = await fetchOutletOptions('filter', brandID)
          // throw message error
          if (options.data.meta.code === 200) {
            // set options
            setOutletOptions(options.data.data)
          }
        }
      } catch (error: any) {
        console.error(error)
        if (error.response.status === 401) {
          toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
          removeAuth()
        } else {
          toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
        }
      } finally {
      }

      return () => (didRequestOutlet.current = true)
    }
    const requestCategoriesOptions = async () => {
      try {
        if (!didRequestCategories.current) {
          const options = await fetchCategoriesOptions('filter', brandID)
          // throw message error
          if (options.data.meta.code === 200) {
            // set options
            setCategoriesOptions(options.data.data)
          }
        }
      } catch (error: any) {
        console.error(error)
        if (error.response.status === 401) {
          toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
          removeAuth()
        } else {
          toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
        }
      } finally {
      }

      return () => (didRequestCategories.current = true)
    }

    if (brandID !== undefined && brandID.length > 0) {
      requestOutletOptions()
      requestCategoriesOptions()
    } else {
      setOutletOptions([])
      setCategoriesOptions([])
    }
    // eslint-disable-next-line
  }, [brandID])

  return (
    <>
      <FilterButton
        isLoading={isLoading}
        state={state}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
      />
      <Drawer
        open={showFilter}
        onClose={() => {
          setShowFilter(false)
        }}
        placement='right'
        style={{width: mobile ? '100%' : '50%'}}
      >
        <Drawer.Header closeButton>
          <Drawer.Actions>
            {/* Actions */}
            <FilterAction
              filterData={filterData}
              isLoading={isLoading}
              resetData={resetData}
              setStatus={setStatus}
            />
            {/* Actions */}
          </Drawer.Actions>
        </Drawer.Header>

        {/* Alert Message */}
        {allowCompany === false && (
          <div className='m-lg-5 p-2 bg-light-warning rounded'>
            <div className='text-warning text-center font-weight-bold fw-bolder'>
              {intl.formatMessage(
                {id: 'VALIDATION_REQUIRED_FIELD'},
                {name: intl.formatMessage({id: 'LABEL_COMPANY'})}
              )}
            </div>
          </div>
        )}
        {allowBrand === false && (
          <div className='m-lg-5 p-2 bg-light-warning rounded'>
            <div className='text-warning text-center font-weight-bold fw-bolder'>
              {intl.formatMessage(
                {id: 'VALIDATION_REQUIRED_FIELD'},
                {name: intl.formatMessage({id: 'LABEL_BRAND'})}
              )}
            </div>
          </div>
        )}
        {/* Alert Message */}

        {/* Content */}
        <div className='card-body table-responsive m-5'>
          <div className='row mb-5'>
            {/* Left Form */}
            <div className='col-lg-6'>
              {/* Input group */}
              <div className='fv-row mb-5'>
                <label className='form-label fs-6 fw-bold'>{'Holding :'}</label>
                <CheckPicker
                  block
                  placeholder={`-- ${intl.formatMessage({id: 'LABEL_SELECT'})} --`}
                  data={optionsHolding}
                  onChange={(val, tr) => {
                    let tmp = []
                    let result: any[] = []
                    let label: any = holdingName

                    if (tr.type === 'keydown') {
                      // prevent user select/choice by using click enter
                      return
                    }

                    if (val.length > 0) {
                      if ((tr.target as unknown as any).checked === true) {
                        tmp = (optionsHolding as unknown as any[]).find(
                          (dt) => dt.label === (tr.target as unknown as any).labels[0].innerText
                        )
                        result = [...label, tmp.label]
                      } else {
                        if (tr.type === 'click') {
                          if ((tr.target as unknown as any).lastChild.nodeName === 'path') {
                            let tmp_result = label.filter(
                              (e: any) =>
                                e !==
                                (tr.target as unknown as any).parentElement.parentElement.innerText
                            )
                            result = tmp_result
                          }
                        } else {
                          let tmp_result = label.filter(
                            (e: any) => e !== (tr.target as unknown as any).labels[0].innerText
                          )
                          result = tmp_result
                        }
                      }
                      setHoldingID(val)
                      setHoldingName(result)
                    } else {
                      setHoldingID([])
                      setHoldingName([])
                    }

                    setCompanyID([])
                    setCompanyName([])
                    setBrandID([])
                    setBrandName([])
                    setOutletID([])
                    setOutletName([])
                    setCategoriesID([])
                    setCategoriesName([])
                  }}
                  onSelect={(val, ite) => {
                    let value: any = holdingID
                    let label: any = holdingName
                    let tmpValue: any = []
                    let tmpLabel: any = []
                    let resultValue: any[] = []
                    let resultLabel: any[] = []

                    if (val.length > 0) {
                      if (value.indexOf(ite.value) === -1) {
                        tmpValue = (optionsHolding as unknown as any[]).find(
                          (dt) => dt.value === ite.value
                        )

                        if (tmpValue === undefined) {
                          return
                        }

                        resultValue = [...value, tmpValue.value]
                        setHoldingID(resultValue)
                      } else {
                        tmpValue = value.filter((dt: any) => dt !== ite.value)
                        setHoldingID(tmpValue)
                      }

                      if (label.indexOf(ite.label) === -1) {
                        tmpLabel = (optionsHolding as unknown as any[]).find(
                          (dt) => dt.label === ite.label
                        )

                        if (tmpLabel === undefined) {
                          return
                        }

                        resultLabel = [...label, tmpLabel.label]
                        setHoldingName(resultLabel)
                      } else {
                        tmpLabel = label.filter((dt: any) => dt !== ite.label)
                        setHoldingName(tmpLabel)
                      }
                    } else {
                      setHoldingID([])
                      setHoldingName([])
                    }

                    setCompanyID([])
                    setCompanyName([])
                    setBrandID([])
                    setBrandName([])
                    setOutletID([])
                    setOutletName([])
                    setCategoriesID([])
                    setCategoriesName([])
                  }}
                  defaultValue={(optionsHolding as unknown as any[]).map((dt: any) => ({
                    value: dt.value,
                    label: dt.label,
                  }))}
                  value={holdingID}
                  renderExtraFooter={() => (
                    <div style={footerStyles}>
                      <Checkbox
                        indeterminate={
                          holdingID.length > 0 &&
                          holdingID.length < (optionsHolding as unknown as any[]).length
                        }
                        checked={holdingID.length === (optionsHolding as unknown as any[]).length}
                        onChange={(data, checked) => {
                          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                          if (checked) {
                            setHoldingID((optionsHolding as unknown as any[]).map((e) => e.value))
                            setHoldingName((optionsHolding as unknown as any[]).map((e) => e.label))
                          } else {
                            setHoldingID([])
                            setHoldingName([])
                          }
                        }}
                      >
                        {holdingID.length === (optionsHolding as unknown as any[]).length
                          ? intl.formatMessage({id: 'LABEL_UNSELECT_ALL'})
                          : intl.formatMessage({id: 'LABEL_SELECT_ALL'})}
                      </Checkbox>
                    </div>
                  )}
                />
              </div>
              {/* Input group */}

              {/* Input group */}
              <div className='fv-row mb-5'>
                <label className='form-label fs-6 fw-bold'>
                  {intl.formatMessage({id: 'LABEL_COMPANY'})} :
                </label>
                <CheckPicker
                  block
                  placeholder={`-- ${intl.formatMessage({id: 'LABEL_SELECT'})} --`}
                  disabled={holdingID.length > 0 ? false : true}
                  data={optionsCompany}
                  onChange={(val, tr) => {
                    let tmp = []
                    let result: any[] = []
                    let label: any = companyName

                    if (tr.type === 'keydown') {
                      // prevent user select/choice by using click enter
                      return
                    }

                    if (val.length > 0) {
                      if ((tr.target as unknown as any).checked === true) {
                        tmp = (optionsCompany as unknown as any[]).find(
                          (dt) => dt.label === (tr.target as unknown as any).labels[0].innerText
                        )
                        result = [...label, tmp.label]
                      } else {
                        if (tr.type === 'click') {
                          if ((tr.target as unknown as any).lastChild.nodeName === 'path') {
                            let tmp_result = label.filter(
                              (e: any) =>
                                e !==
                                (tr.target as unknown as any).parentElement.parentElement.innerText
                            )
                            result = tmp_result
                          }
                        } else {
                          let tmp_result = label.filter(
                            (e: any) => e !== (tr.target as unknown as any).labels[0].innerText
                          )
                          result = tmp_result
                        }
                      }
                      setCompanyID(val)
                      setCompanyName(result)
                    } else {
                      setCompanyID([])
                      setCompanyName([])
                    }

                    setBrandID([])
                    setBrandName([])
                    setOutletID([])
                    setOutletName([])
                    setCategoriesID([])
                    setCategoriesName([])
                  }}
                  onSelect={(val, ite) => {
                    let value: any = companyID
                    let label: any = companyName
                    let tmpValue: any = []
                    let tmpLabel: any = []
                    let resultValue: any[] = []
                    let resultLabel: any[] = []

                    if (val.length > 0) {
                      if (value.indexOf(ite.value) === -1) {
                        tmpValue = (optionsCompany as unknown as any[]).find(
                          (dt) => dt.value === ite.value
                        )

                        if (tmpValue === undefined) {
                          return
                        }

                        resultValue = [...value, tmpValue.value]
                        setCompanyID(resultValue)
                      } else {
                        tmpValue = value.filter((dt: any) => dt !== ite.value)
                        setCompanyID(tmpValue)
                      }

                      if (label.indexOf(ite.label) === -1) {
                        tmpLabel = (optionsCompany as unknown as any[]).find(
                          (dt) => dt.label === ite.label
                        )

                        if (tmpLabel === undefined) {
                          return
                        }

                        resultLabel = [...label, tmpLabel.label]
                        setCompanyName(resultLabel)
                      } else {
                        tmpLabel = label.filter((dt: any) => dt !== ite.label)
                        setCompanyName(tmpLabel)
                      }
                    } else {
                      setCompanyID([])
                      setCompanyName([])
                    }

                    setBrandID([])
                    setBrandName([])
                    setOutletID([])
                    setOutletName([])
                    setCategoriesID([])
                    setCategoriesName([])
                  }}
                  defaultValue={(optionsCompany as unknown as any[]).map((dt: any) => ({
                    value: dt.value,
                    label: dt.label,
                  }))}
                  value={companyID}
                  renderExtraFooter={() => (
                    <div style={footerStyles}>
                      <Checkbox
                        indeterminate={
                          companyID.length > 0 &&
                          companyID.length < (optionsCompany as unknown as any[]).length
                        }
                        checked={companyID.length === (optionsCompany as unknown as any[]).length}
                        onChange={(data, checked) => {
                          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                          if (checked) {
                            setCompanyID((optionsCompany as unknown as any[]).map((e) => e.value))
                            setCompanyName((optionsCompany as unknown as any[]).map((e) => e.label))
                          } else {
                            setCompanyID([])
                            setCompanyName([])
                          }
                        }}
                      >
                        {companyID.length === (optionsCompany as unknown as any[]).length
                          ? intl.formatMessage({id: 'LABEL_UNSELECT_ALL'})
                          : intl.formatMessage({id: 'LABEL_SELECT_ALL'})}
                      </Checkbox>
                    </div>
                  )}
                />
              </div>
              {/* Input group */}

              {/* Input group */}
              <div className='fv-row mb-5'>
                <label className='form-label fs-6 fw-bold'>
                  {intl.formatMessage({id: 'LABEL_BRAND'})} :
                </label>
                <CheckPicker
                  block
                  placeholder={`-- ${intl.formatMessage({id: 'LABEL_SELECT'})} --`}
                  disabled={companyID.length > 0 ? false : true}
                  data={optionsBrand}
                  onChange={(val, tr) => {
                    let tmp = []
                    let result: any[] = []
                    let label: any = brandName

                    if (tr.type === 'keydown') {
                      // prevent user select/choice by using click enter
                      return
                    }

                    if (val.length > 0) {
                      if ((tr.target as unknown as any).checked === true) {
                        tmp = (optionsBrand as unknown as any[]).find(
                          (dt) => dt.label === (tr.target as unknown as any).labels[0].innerText
                        )
                        result = [...label, tmp.label]
                      } else {
                        if (tr.type === 'click') {
                          if ((tr.target as unknown as any).lastChild.nodeName === 'path') {
                            let tmp_result = label.filter(
                              (e: any) =>
                                e !==
                                (tr.target as unknown as any).parentElement.parentElement.innerText
                            )
                            result = tmp_result
                          }
                        } else {
                          let tmp_result = label.filter(
                            (e: any) => e !== (tr.target as unknown as any).labels[0].innerText
                          )
                          result = tmp_result
                        }
                      }
                      setBrandID(val)
                      setBrandName(result)
                    } else {
                      setBrandID([])
                      setBrandName([])
                    }

                    setOutletID([])
                    setOutletName([])
                    setCategoriesID([])
                    setCategoriesName([])
                  }}
                  onSelect={(val, ite) => {
                    let value: any = brandID
                    let label: any = brandName
                    let tmpValue: any = []
                    let tmpLabel: any = []
                    let resultValue: any[] = []
                    let resultLabel: any[] = []

                    if (val.length > 0) {
                      if (value.indexOf(ite.value) === -1) {
                        tmpValue = (optionsBrand as unknown as any[]).find(
                          (dt) => dt.value === ite.value
                        )

                        if (tmpValue === undefined) {
                          return
                        }

                        resultValue = [...value, tmpValue.value]
                        setBrandID(resultValue)
                      } else {
                        tmpValue = value.filter((dt: any) => dt !== ite.value)
                        setBrandID(tmpValue)
                      }

                      if (label.indexOf(ite.label) === -1) {
                        tmpLabel = (optionsBrand as unknown as any[]).find(
                          (dt) => dt.label === ite.label
                        )

                        if (tmpLabel === undefined) {
                          return
                        }

                        resultLabel = [...label, tmpLabel.label]
                        setBrandName(resultLabel)
                      } else {
                        tmpLabel = label.filter((dt: any) => dt !== ite.label)
                        setBrandName(tmpLabel)
                      }
                    } else {
                      setBrandID([])
                      setBrandName([])
                    }
                    setOutletID([])
                    setOutletName([])
                    setCategoriesID([])
                    setCategoriesName([])
                  }}
                  defaultValue={(optionsBrand as unknown as any[]).map((dt: any) => ({
                    value: dt.value,
                    label: dt.label,
                  }))}
                  value={brandID}
                  renderExtraFooter={() => (
                    <div style={footerStyles}>
                      <Checkbox
                        indeterminate={
                          brandID.length > 0 &&
                          brandID.length < (optionsBrand as unknown as any[]).length
                        }
                        checked={brandID.length === (optionsBrand as unknown as any[]).length}
                        onChange={(data, checked) => {
                          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                          if (checked) {
                            setBrandID((optionsBrand as unknown as any[]).map((e) => e.value))
                            setBrandName((optionsBrand as unknown as any[]).map((e) => e.label))
                          } else {
                            setBrandID([])
                            setBrandName([])
                          }
                        }}
                      >
                        {brandID.length === (optionsBrand as unknown as any[]).length
                          ? intl.formatMessage({id: 'LABEL_UNSELECT_ALL'})
                          : intl.formatMessage({id: 'LABEL_SELECT_ALL'})}
                      </Checkbox>
                    </div>
                  )}
                />
              </div>
              {/* Input group */}
            </div>
            {/* Left Form */}

            {/* Right Form */}
            <div className='col-lg-6'>
              {/* Input group */}
              <div className='fv-row mb-5'>
                <label className='form-label fs-6 fw-bold'>
                  {intl.formatMessage({id: 'LABEL_OUTLET'})}&nbsp; :
                </label>
                <CheckPicker
                  block
                  placeholder={`-- ${intl.formatMessage({id: 'LABEL_SELECT'})} --`}
                  disabled={brandID.length > 0 ? false : true}
                  data={optionsOutlet}
                  onChange={(val, tr) => {
                    let tmp = []
                    let result: any[] = []
                    let label: any = outletName

                    if (tr.type === 'keydown') {
                      // prevent user select/choice by using click enter
                      return
                    }

                    if (val.length > 0) {
                      if ((tr.target as unknown as any).checked === true) {
                        tmp = (optionsOutlet as unknown as any[]).find(
                          (dt) => dt.label === (tr.target as unknown as any).labels[0].innerText
                        )
                        result = [...label, tmp.label]
                      } else {
                        if (tr.type === 'click') {
                          if ((tr.target as unknown as any).lastChild.nodeName === 'path') {
                            let tmp_result = label.filter(
                              (e: any) =>
                                e !==
                                (tr.target as unknown as any).parentElement.parentElement.innerText
                            )
                            result = tmp_result
                          }
                        } else {
                          let tmp_result = label.filter(
                            (e: any) => e !== (tr.target as unknown as any).labels[0].innerText
                          )
                          result = tmp_result
                        }
                      }
                      setOutletID(val)
                      setOutletName(result)
                    } else {
                      setOutletID([])
                      setOutletName([])
                    }
                  }}
                  onSelect={(val, ite) => {
                    let value: any = outletID
                    let label: any = outletName
                    let tmpValue: any = []
                    let tmpLabel: any = []
                    let resultValue: any[] = []
                    let resultLabel: any[] = []

                    if (val.length > 0) {
                      if (value.indexOf(ite.value) === -1) {
                        tmpValue = (optionsOutlet as unknown as any[]).find(
                          (dt) => dt.value === ite.value
                        )

                        if (tmpValue === undefined) {
                          return
                        }

                        resultValue = [...value, tmpValue.value]
                        setOutletID(resultValue)
                      } else {
                        tmpValue = value.filter((dt: any) => dt !== ite.value)
                        setOutletID(tmpValue)
                      }

                      if (label.indexOf(ite.label) === -1) {
                        tmpLabel = (optionsOutlet as unknown as any[]).find(
                          (dt) => dt.label === ite.label
                        )

                        if (tmpLabel === undefined) {
                          return
                        }

                        resultLabel = [...label, tmpLabel.label]
                        setOutletName(resultLabel)
                      } else {
                        tmpLabel = label.filter((dt: any) => dt !== ite.label)
                        setOutletName(tmpLabel)
                      }
                    } else {
                      setOutletID([])
                      setOutletName([])
                    }
                  }}
                  defaultValue={(optionsOutlet as unknown as any[]).map((dt: any) => ({
                    value: dt.value,
                    label: dt.label,
                  }))}
                  value={outletID}
                  renderExtraFooter={() => (
                    <div style={footerStyles}>
                      <Checkbox
                        indeterminate={
                          outletID.length > 0 &&
                          outletID.length < (optionsOutlet as unknown as any[]).length
                        }
                        checked={outletID.length === (optionsOutlet as unknown as any[]).length}
                        onChange={(data, checked) => {
                          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                          if (checked) {
                            setOutletID((optionsOutlet as unknown as any[]).map((e) => e.value))
                            setOutletName((optionsOutlet as unknown as any[]).map((e) => e.label))
                          } else {
                            setOutletID([])
                            setOutletName([])
                          }
                        }}
                      >
                        {outletID.length === (optionsOutlet as unknown as any[]).length
                          ? intl.formatMessage({id: 'LABEL_UNSELECT_ALL'})
                          : intl.formatMessage({id: 'LABEL_SELECT_ALL'})}
                      </Checkbox>
                    </div>
                  )}
                />
              </div>
              {/* Input group */}

              {/* Input group */}
              <div className='fv-row mb-5'>
                <label className='form-label fs-6 fw-bold'>
                  {intl.formatMessage({id: 'LABEL_CATEGORY'})}&nbsp; :
                </label>
                <CheckPicker
                  block
                  placeholder={`-- ${intl.formatMessage({id: 'LABEL_SELECT'})} --`}
                  disabled={brandID.length > 0 ? false : true}
                  data={optionsCategories}
                  onChange={(val, tr) => {
                    let tmp = []
                    let result: any[] = []
                    let label: any = categoriesName

                    if (tr.type === 'keydown') {
                      // prevent user select/choice by using click enter
                      return
                    }

                    if (val.length > 0) {
                      if ((tr.target as unknown as any).checked === true) {
                        tmp = (optionsCategories as unknown as any[]).find(
                          (dt) => dt.label === (tr.target as unknown as any).labels[0].innerText
                        )
                        result = [...label, tmp.label]
                      } else {
                        if (tr.type === 'click') {
                          if ((tr.target as unknown as any).lastChild.nodeName === 'path') {
                            let tmp_result = label.filter(
                              (e: any) =>
                                e !==
                                (tr.target as unknown as any).parentElement.parentElement.innerText
                            )
                            result = tmp_result
                          }
                        } else {
                          let tmp_result = label.filter(
                            (e: any) => e !== (tr.target as unknown as any).labels[0].innerText
                          )
                          result = tmp_result
                        }
                      }
                      setCategoriesID(val)
                      setCategoriesName(result)
                    } else {
                      setCategoriesID([])
                      setCategoriesName([])
                    }
                  }}
                  onSelect={(val, ite) => {
                    let value: any = categoriesID
                    let label: any = categoriesName
                    let tmpValue: any = []
                    let tmpLabel: any = []
                    let resultValue: any[] = []
                    let resultLabel: any[] = []

                    if (val.length > 0) {
                      if (value.indexOf(ite.value) === -1) {
                        tmpValue = (optionsCategories as unknown as any[]).find(
                          (dt) => dt.value === ite.value
                        )

                        if (tmpValue === undefined) {
                          return
                        }

                        resultValue = [...value, tmpValue.value]
                        setCategoriesID(resultValue)
                      } else {
                        tmpValue = value.filter((dt: any) => dt !== ite.value)
                        setCategoriesID(tmpValue)
                      }

                      if (label.indexOf(ite.label) === -1) {
                        tmpLabel = (optionsCategories as unknown as any[]).find(
                          (dt) => dt.label === ite.label
                        )

                        if (tmpLabel === undefined) {
                          return
                        }

                        resultLabel = [...label, tmpLabel.label]
                        setCategoriesName(resultLabel)
                      } else {
                        tmpLabel = label.filter((dt: any) => dt !== ite.label)
                        setCategoriesName(tmpLabel)
                      }
                    } else {
                      setCategoriesID([])
                      setCategoriesName([])
                    }
                  }}
                  defaultValue={(optionsCategories as unknown as any[]).map((dt: any) => ({
                    value: dt.value,
                    label: dt.label,
                  }))}
                  value={categoriesID}
                  renderExtraFooter={() => (
                    <div style={footerStyles}>
                      <Checkbox
                        indeterminate={
                          categoriesID.length > 0 &&
                          categoriesID.length < (optionsCategories as unknown as any[]).length
                        }
                        checked={
                          categoriesID.length === (optionsCategories as unknown as any[]).length
                        }
                        onChange={(data, checked) => {
                          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                          if (checked) {
                            setCategoriesID(
                              (optionsCategories as unknown as any[]).map((e) => e.value)
                            )
                            setCategoriesName(
                              (optionsCategories as unknown as any[]).map((e) => e.label)
                            )
                          } else {
                            setCategoriesID([])
                            setCategoriesName([])
                          }
                        }}
                      >
                        {categoriesID.length === (optionsCategories as unknown as any[]).length
                          ? intl.formatMessage({id: 'LABEL_UNSELECT_ALL'})
                          : intl.formatMessage({id: 'LABEL_SELECT_ALL'})}
                      </Checkbox>
                    </div>
                  )}
                />
              </div>
              {/* Input group */}
            </div>
          </div>

          <div className='row mb-5'>
            {/* Left Form */}
            <div className='col-lg-6'>
              {/* Input group */}
              <div className='fv-row mb-5'>
                <label className='form-label fs-6 fw-bold'>
                  {intl.formatMessage({id: 'LABEL_HAS_VARIANT'})}&nbsp; :
                </label>
                <InputPicker
                  block
                  data={optionYesNo}
                  placeholder={`-- ${intl.formatMessage({id: 'LABEL_SELECT'})} --`}
                  defaultValue={
                    optionYesNo
                      ? (optionYesNo as unknown as any[]).find((e: any) => e.value === hasVariant)
                      : ''
                  }
                  onChange={(e) => {
                    setHasVariant(e)
                  }}
                  value={hasVariant}
                />
              </div>
              {/* Input group */}

              {/* Input group */}
              <div className='fv-row mb-5'>
                <label className='form-label fs-6 fw-bold'>
                  {intl.formatMessage({id: 'LABEL_IS_DEFAULT'})}&nbsp; :
                </label>
                <InputPicker
                  block
                  data={optionYesNo}
                  placeholder={`-- ${intl.formatMessage({id: 'LABEL_SELECT'})} --`}
                  defaultValue={
                    optionYesNo
                      ? (optionYesNo as unknown as any[]).find((e: any) => e.value === isDefault)
                      : ''
                  }
                  onChange={(e) => {
                    setIsDefault(e)
                  }}
                  value={isDefault}
                />
              </div>
              {/* Input group */}
            </div>
            {/* Left Form */}

            {/* Right Form */}
            <div className='col-lg-6'>
              {/* Input group */}
              <div className='fv-row mb-5'>
                <label className='form-label fs-6 fw-bold'>
                  {intl.formatMessage({id: 'LABEL_IS_MULTIPLE_SALES_TYPE'})}&nbsp; :
                </label>
                <InputPicker
                  block
                  data={optionYesNo}
                  placeholder={`-- ${intl.formatMessage({id: 'LABEL_SELECT'})} --`}
                  defaultValue={
                    optionYesNo
                      ? (optionYesNo as unknown as any[]).find(
                          (e: any) => e.value === isMultiplePrice
                        )
                      : ''
                  }
                  onChange={(e) => {
                    setIsMultiplePrice(e)
                  }}
                  value={isMultiplePrice}
                />
              </div>
              {/* Input group */}

              {/* Input group */}
              <div className='fv-row mb-5'>
                <label className='form-label fs-6 fw-bold'>
                  {intl.formatMessage({id: 'LABEL_IS_LIFETIME'})}&nbsp; :
                </label>
                <InputPicker
                  block
                  data={optionNoYes}
                  placeholder={`-- ${intl.formatMessage({id: 'LABEL_SELECT'})} --`}
                  defaultValue={
                    optionNoYes
                      ? (optionNoYes as unknown as any[]).find((e: any) => e.value === isLifetime)
                      : ''
                  }
                  onChange={(e) => {
                    setIsLifetime(e)
                  }}
                  value={isLifetime}
                />
              </div>
              {/* Input group */}

              {/* Input group */}
              <div className='fv-row mb-5'>
                <label className='form-label fs-6 fw-bold'>Status :</label>
                <InputPicker
                  block
                  data={optionStatus}
                  placeholder={`-- ${intl.formatMessage({id: 'LABEL_SELECT'})} --`}
                  defaultValue={
                    optionStatus
                      ? (optionStatus as unknown as any[]).find((e: any) => e.value === status)
                      : ''
                  }
                  onChange={(e) => {
                    setStatus(e)
                  }}
                  value={status}
                />
              </div>
              {/* Input group */}
            </div>
          </div>
        </div>
        {/* Content */}
      </Drawer>
    </>
  )
}

export {ListFilter}
