import {FC} from 'react'
import {useIntl} from 'react-intl'

type Props = {
  row?: string
}

function coloringRows(rowLabel: any) {
  switch (rowLabel) {
    case 't':
      return '-primary'
    default:
      return '-secondary'
  }
}

const RowYesNoCellFormatter: FC<Props> = ({row}) => {
  const intl = useIntl()
  return (
    <div className={`badge badge${coloringRows(row)} fw-bolder`}>
      {row === 't' ? intl.formatMessage({id: 'LABEL_YES'}) : intl.formatMessage({id: 'LABEL_NO'})}
    </div>
  )
}

export {RowYesNoCellFormatter}
