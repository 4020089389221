import React from 'react'

type Props = {
  label: string
}

const SpinnerIndicator: React.FC<Props> = ({label}) => {
  return (
    <span className='indicator-progress text-default' style={{display: 'block'}}>
      <span className='spinner-border spinner-border-lg'></span> &nbsp;{label}
    </span>
  )
}

export {SpinnerIndicator}
