import {FC} from 'react'

import {ListModalFormWrapperHelper, ListModalHelper} from 'app/components'

type Props = {
  menuName: any
  modalID: any
  appMenuID: any
  parentMenuID: any
  appModuleID?: any
  appSiteID?: any
}

const ViewModal: FC<Props> = ({
  menuName,
  modalID,
  appMenuID,
  parentMenuID,
  appModuleID,
  appSiteID,
}) => {
  const listMenuWithTabContent = ['4'] // menu group access
  return (
    <>
      <div
        className='modal fade show d-block'
        id={modalID}
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-fullscreen'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            <ListModalHelper
              menuName={menuName}
              appMenuID={appMenuID}
              appParentMenuID={parentMenuID}
            />
            {/* begin::Modal body */}
            <div
              className={`modal-body scroll-y ${
                listMenuWithTabContent.includes(appMenuID) ? 'p-0 m-0' : ''
              }`}
            >
              <ListModalFormWrapperHelper
                appMenuID={appMenuID}
                appParentMenuID={parentMenuID}
                modalID={modalID}
                appModuleID={appModuleID}
                appSiteID={appSiteID}
              />
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

export {ViewModal}
