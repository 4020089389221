import {FC} from 'react'
import {useIntl} from 'react-intl'
import {Tooltip, Whisper} from 'rsuite'

import {KTIcon} from '_metronic/helpers'

type Props = {
  menuName: any
  useAction: any
}

const ExportAction: FC<Props> = ({menuName, useAction}) => {
  const intl = useIntl()
  const {setAction, setItemIdForUpdate} = useAction()

  const openModal = (action: any) => {
    setAction(action)
    setItemIdForUpdate(null)
  }

  return (
    <Whisper
      placement='top'
      controlId='control-id-hover'
      trigger='hover'
      speaker={
        <Tooltip id='tooltip-shortcut-menu'>{intl.formatMessage({id: 'LABEL_EXPORT'})}</Tooltip>
      }
    >
      <button
        type='button'
        className='btn btn-icon btn-bg-light hover-scale me-4'
        onClick={() => openModal('export')}
      >
        <KTIcon iconName='folder-up' className='fs-2x' />
      </button>
    </Whisper>
  )
}

export {ExportAction}
