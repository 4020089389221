import axios from 'axios'

const API_URL = process.env.REACT_APP_APP_API_URL
const X_API_KEY = process.env.REACT_APP_APP_API_KEY

export const GET_USER_GROUP_MODULES = `${API_URL}/module/get-user-group-modules?`

export function getUserGroupModulesByRoleID(role_id: any) {
  return axios.get<any>(`${GET_USER_GROUP_MODULES}role_id=${role_id}`, {
    headers: {'X-API-KEY': `${X_API_KEY}`},
  })
}
