import {FC} from 'react'
import {useIntl} from 'react-intl'

import {DatetimeFormatter} from 'app/components'

type Props = {
  datetime?: string
}

const RowDatetimeCellFormatter: FC<Props> = ({datetime}) => {
  const intl = useIntl()

  return (
    <div>
      {datetime === '' ? (
        <span>{'-'}</span>
      ) : (
        <DatetimeFormatter date={datetime} format='6' locale={intl.locale} timeago={false} />
      )}
    </div>
  )
}

export {RowDatetimeCellFormatter}
