import {useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import toast from 'react-hot-toast'
import {Checkbox, InputPicker, CheckPicker, Drawer} from 'rsuite'

import {isMobileDevice} from '_metronic/assets/ts/_utils'
import {FilterAction, FilterButton} from 'app/components'
import {removeAuth} from 'app/modules/auth'

import {useAction, useListQueryRequest, useListQueryResponse} from '../../providers'
import {fetchBrandOptions, fetchCompanyOptions, fetchPaymentMethodOptions} from '../../helpers'

const footerStyles = {
  padding: '0px 2px',
  borderTop: '1px solid #e5e5e5',
}

const ListFilter = () => {
  const intl = useIntl()
  const mobile = isMobileDevice()
  const {state, updateState} = useListQueryRequest()
  const {isLoading} = useListQueryResponse()
  const {
    showFilter,
    setShowFilter,
    holdingOptions,
    companyOptions,
    setCompanyOptions,
    brandOptions,
    setBrandOptions,
    paymentMethodOptions,
    setPaymentMethodOptions,
  } = useAction()

  const [holdingID, setHoldingID] = useState(
    state?.filter !== undefined ? state?.filter.holding_id : []
  )
  const [holdingName, setHoldingName] = useState(
    state?.filter !== undefined ? state?.filter.holding_name : []
  )
  const [companyID, setCompanyID] = useState(
    state?.filter !== undefined ? state?.filter.company_id : []
  )
  const [companyName, setCompanyName] = useState(
    state?.filter !== undefined ? state?.filter.company_name : []
  )
  const [brandID, setBrandID] = useState(state?.filter !== undefined ? state?.filter.brand_id : [])
  const [brandName, setBrandName] = useState(
    state?.filter !== undefined ? state?.filter.brand_name : []
  )
  const [paymentMethodID, setPaymentMethodID] = useState(
    state?.filter !== undefined ? state?.filter.payment_method_id : []
  )
  const [paymentMethodName, setPaymentMethodName] = useState(
    state?.filter !== undefined ? state?.filter.payment_method_name : []
  )
  const optionsHolding =
    holdingOptions === undefined
      ? []
      : (holdingOptions as unknown as any[]).map((holdings: any) => ({
          value: holdings.holding_id,
          label: holdings.name,
        }))
  const optionsCompany =
    companyOptions === undefined
      ? []
      : (companyOptions as unknown as any[]).map((company: any) => ({
          value: company.company_id,
          label: company.name,
        }))
  const optionsBrand =
    brandOptions === undefined
      ? []
      : (brandOptions as unknown as any[]).map((brand: any) => ({
          value: brand.brand_id,
          label: brand.name,
        }))
  const optionsPaymentMethod =
    paymentMethodOptions === undefined
      ? []
      : (paymentMethodOptions as unknown as any[]).map((payment_method: any) => ({
          value: payment_method.payment_method_id,
          label: payment_method.name,
        }))
  const optionStatus = [
    {
      value: 't',
      label: intl.formatMessage({id: 'LABEL_ACTIVE'}),
    },
    {
      value: 'f',
      label: intl.formatMessage({id: 'LABEL_INACTIVE'}),
    },
  ]
  const [status, setStatus] = useState(state?.filter !== undefined ? state?.filter.status : '')

  const didRequestCompany = useRef(false)
  const didRequestBrand = useRef(false)
  const didRequestPaymentMethod = useRef(false)

  const resetData = () => {
    setHoldingID([])
    setHoldingName([])
    setCompanyID([])
    setCompanyName([])
    setBrandID([])
    setBrandName([])
    setPaymentMethodID([])
    setPaymentMethodName([])
    setStatus('')
    updateState({filter: undefined})
  }

  const filterData = () => {
    updateState({
      filter: {
        holding_id: holdingID,
        company_id: companyID,
        brand_id: brandID,
        payment_method_id: paymentMethodID,
        companyName,
        holdingName,
        brandName,
        paymentMethodName,
        status,
      },
    })
    setShowFilter(false)
  }

  useEffect(() => {
    const requestCompanyOptions = async () => {
      try {
        if (!didRequestCompany.current) {
          const options = await fetchCompanyOptions('filter', holdingID)
          // throw message error
          if (options.data.meta.code === 200) {
            // set options
            setCompanyOptions(options.data.data)
          }
        }
      } catch (error: any) {
        console.error(error)
        if (error.response.status === 401) {
          toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
          removeAuth()
        } else {
          toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
        }
      } finally {
      }

      return () => (didRequestCompany.current = true)
    }

    holdingID !== undefined && holdingID.length > 0
      ? requestCompanyOptions()
      : setCompanyOptions([])
    // eslint-disable-next-line
  }, [holdingID])

  useEffect(() => {
    const requestBrandOptions = async () => {
      try {
        if (!didRequestBrand.current) {
          const options = await fetchBrandOptions('filter', companyID)
          // throw message error
          if (options.data.meta.code === 200) {
            // set options
            setBrandOptions(options.data.data)
          }
        }
      } catch (error: any) {
        console.error(error)
        if (error.response.status === 401) {
          toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
          removeAuth()
        } else {
          toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
        }
      } finally {
      }

      return () => (didRequestBrand.current = true)
    }

    companyID !== undefined && companyID.length > 0 ? requestBrandOptions() : setBrandOptions([])
    // eslint-disable-next-line
  }, [companyID])

  useEffect(() => {
    const requestPaymentMethodOptions = async () => {
      try {
        if (!didRequestPaymentMethod.current) {
          const options = await fetchPaymentMethodOptions('filter', brandID)
          // throw message error
          if (options.data.meta.code === 200) {
            // set options
            setPaymentMethodOptions(options.data.data)
          }
        }
      } catch (error: any) {
        console.error(error)
        if (error.response.status === 401) {
          toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
          removeAuth()
        } else {
          toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
        }
      } finally {
      }

      return () => (didRequestPaymentMethod.current = true)
    }

    brandID !== undefined && brandID.length > 0
      ? requestPaymentMethodOptions()
      : setPaymentMethodOptions([])
    // eslint-disable-next-line
  }, [brandID])

  return (
    <>
      <FilterButton
        isLoading={isLoading}
        state={state}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
      />
      <Drawer
        open={showFilter}
        onClose={() => {
          setShowFilter(false)
        }}
        placement='right'
        style={{width: mobile ? '100%' : '25%'}}
      >
        <Drawer.Header closeButton>
          <Drawer.Actions>
            {/* Actions */}
            <FilterAction
              filterData={filterData}
              isLoading={isLoading}
              resetData={resetData}
              setStatus={setStatus}
            />
            {/* Actions */}
          </Drawer.Actions>
        </Drawer.Header>

        {/* Content */}
        <div className='card-body table-responsive m-5'>
          {/* Input group */}
          <div className='fv-row mb-5'>
            <label className='form-label fs-6 fw-bold'>{'Holding :'}</label>
            <CheckPicker
              block
              placeholder={`-- ${intl.formatMessage({id: 'LABEL_SELECT'})} --`}
              data={optionsHolding}
              onChange={(val, tr) => {
                let tmp = []
                let result: any[] = []
                let label: any = holdingName

                if (tr.type === 'keydown') {
                  // prevent user select/choice by using click enter
                  return
                }

                if (val.length > 0) {
                  if ((tr.target as unknown as any).checked === true) {
                    tmp = (optionsHolding as unknown as any[]).find(
                      (dt) => dt.label === (tr.target as unknown as any).labels[0].innerText
                    )
                    result = [...label, tmp.label]
                  } else {
                    if (tr.type === 'click') {
                      if ((tr.target as unknown as any).lastChild.nodeName === 'path') {
                        let tmp_result = label.filter(
                          (e: any) =>
                            e !==
                            (tr.target as unknown as any).parentElement.parentElement.innerText
                        )
                        result = tmp_result
                      }
                    } else {
                      let tmp_result = label.filter(
                        (e: any) => e !== (tr.target as unknown as any).labels[0].innerText
                      )
                      result = tmp_result
                    }
                  }
                  setHoldingID(val)
                  setHoldingName(result)
                } else {
                  setHoldingID([])
                  setHoldingName([])
                }

                setCompanyID([])
                setCompanyName([])
                setBrandID([])
                setBrandName([])
                setPaymentMethodID([])
                setPaymentMethodName([])
              }}
              onSelect={(val, ite) => {
                let value: any = holdingID
                let label: any = holdingName
                let tmpValue: any = []
                let tmpLabel: any = []
                let resultValue: any[] = []
                let resultLabel: any[] = []

                if (val.length > 0) {
                  if (value.indexOf(ite.value) === -1) {
                    tmpValue = (optionsHolding as unknown as any[]).find(
                      (dt) => dt.value === ite.value
                    )

                    if (tmpValue === undefined) {
                      return
                    }

                    resultValue = [...value, tmpValue.value]
                    setHoldingID(resultValue)
                  } else {
                    tmpValue = value.filter((dt: any) => dt !== ite.value)
                    setHoldingID(tmpValue)
                  }

                  if (label.indexOf(ite.label) === -1) {
                    tmpLabel = (optionsHolding as unknown as any[]).find(
                      (dt) => dt.label === ite.label
                    )

                    if (tmpLabel === undefined) {
                      return
                    }

                    resultLabel = [...label, tmpLabel.label]
                    setHoldingName(resultLabel)
                  } else {
                    tmpLabel = label.filter((dt: any) => dt !== ite.label)
                    setHoldingName(tmpLabel)
                  }
                } else {
                  setHoldingID([])
                  setHoldingName([])
                }

                setCompanyID([])
                setCompanyName([])
                setBrandID([])
                setBrandName([])
                setPaymentMethodID([])
                setPaymentMethodName([])
              }}
              defaultValue={(optionsHolding as unknown as any[]).map((dt: any) => ({
                value: dt.value,
                label: dt.label,
              }))}
              value={holdingID}
              renderExtraFooter={() => (
                <div style={footerStyles}>
                  <Checkbox
                    indeterminate={
                      holdingID.length > 0 &&
                      holdingID.length < (optionsHolding as unknown as any[]).length
                    }
                    checked={holdingID.length === (optionsHolding as unknown as any[]).length}
                    onChange={(data, checked) => {
                      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                      if (checked) {
                        setHoldingID((optionsHolding as unknown as any[]).map((e) => e.value))
                        setHoldingName((optionsHolding as unknown as any[]).map((e) => e.label))
                      } else {
                        setHoldingID([])
                        setHoldingName([])
                      }
                    }}
                  >
                    {holdingID.length === (optionsHolding as unknown as any[]).length
                      ? intl.formatMessage({id: 'LABEL_UNSELECT_ALL'})
                      : intl.formatMessage({id: 'LABEL_SELECT_ALL'})}
                  </Checkbox>
                </div>
              )}
            />
          </div>
          {/* Input group */}

          {/* Input group */}
          <div className='fv-row mb-5'>
            <label className='form-label fs-6 fw-bold'>
              {intl.formatMessage({id: 'LABEL_COMPANY'})} :
            </label>
            <CheckPicker
              block
              placeholder={`-- ${intl.formatMessage({id: 'LABEL_SELECT'})} --`}
              disabled={holdingID.length > 0 ? false : true}
              data={optionsCompany}
              onChange={(val, tr) => {
                let tmp = []
                let result: any[] = []
                let label: any = companyName

                if (tr.type === 'keydown') {
                  // prevent user select/choice by using click enter
                  return
                }

                if (val.length > 0) {
                  if ((tr.target as unknown as any).checked === true) {
                    tmp = (optionsCompany as unknown as any[]).find(
                      (dt) => dt.label === (tr.target as unknown as any).labels[0].innerText
                    )
                    result = [...label, tmp.label]
                  } else {
                    if (tr.type === 'click') {
                      if ((tr.target as unknown as any).lastChild.nodeName === 'path') {
                        let tmp_result = label.filter(
                          (e: any) =>
                            e !==
                            (tr.target as unknown as any).parentElement.parentElement.innerText
                        )
                        result = tmp_result
                      }
                    } else {
                      let tmp_result = label.filter(
                        (e: any) => e !== (tr.target as unknown as any).labels[0].innerText
                      )
                      result = tmp_result
                    }
                  }
                  setCompanyID(val)
                  setCompanyName(result)
                } else {
                  setCompanyID([])
                  setCompanyName([])
                }

                setBrandID([])
                setBrandName([])
                setPaymentMethodID([])
                setPaymentMethodName([])
              }}
              onSelect={(val, ite) => {
                let value: any = companyID
                let label: any = companyName
                let tmpValue: any = []
                let tmpLabel: any = []
                let resultValue: any[] = []
                let resultLabel: any[] = []

                if (val.length > 0) {
                  if (value.indexOf(ite.value) === -1) {
                    tmpValue = (optionsCompany as unknown as any[]).find(
                      (dt) => dt.value === ite.value
                    )

                    if (tmpValue === undefined) {
                      return
                    }

                    resultValue = [...value, tmpValue.value]
                    setCompanyID(resultValue)
                  } else {
                    tmpValue = value.filter((dt: any) => dt !== ite.value)
                    setCompanyID(tmpValue)
                  }

                  if (label.indexOf(ite.label) === -1) {
                    tmpLabel = (optionsCompany as unknown as any[]).find(
                      (dt) => dt.label === ite.label
                    )

                    if (tmpLabel === undefined) {
                      return
                    }

                    resultLabel = [...label, tmpLabel.label]
                    setCompanyName(resultLabel)
                  } else {
                    tmpLabel = label.filter((dt: any) => dt !== ite.label)
                    setCompanyName(tmpLabel)
                  }
                } else {
                  setCompanyID([])
                  setCompanyName([])
                }

                setBrandID([])
                setBrandName([])
                setPaymentMethodID([])
                setPaymentMethodName([])
              }}
              defaultValue={(optionsCompany as unknown as any[]).map((dt: any) => ({
                value: dt.value,
                label: dt.label,
              }))}
              value={companyID}
              renderExtraFooter={() => (
                <div style={footerStyles}>
                  <Checkbox
                    indeterminate={
                      companyID.length > 0 &&
                      companyID.length < (optionsCompany as unknown as any[]).length
                    }
                    checked={companyID.length === (optionsCompany as unknown as any[]).length}
                    onChange={(data, checked) => {
                      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                      if (checked) {
                        setCompanyID((optionsCompany as unknown as any[]).map((e) => e.value))
                        setCompanyName((optionsCompany as unknown as any[]).map((e) => e.label))
                      } else {
                        setCompanyID([])
                        setCompanyName([])
                      }
                    }}
                  >
                    {companyID.length === (optionsCompany as unknown as any[]).length
                      ? intl.formatMessage({id: 'LABEL_UNSELECT_ALL'})
                      : intl.formatMessage({id: 'LABEL_SELECT_ALL'})}
                  </Checkbox>
                </div>
              )}
            />
          </div>
          {/* Input group */}

          {/* Input group */}
          <div className='fv-row mb-5'>
            <label className='form-label fs-6 fw-bold'>
              {intl.formatMessage({id: 'LABEL_BRAND'})} :
            </label>
            <CheckPicker
              block
              placeholder={`-- ${intl.formatMessage({id: 'LABEL_SELECT'})} --`}
              disabled={companyID.length > 0 ? false : true}
              data={optionsBrand}
              onChange={(val, tr) => {
                let tmp = []
                let result: any[] = []
                let label: any = brandName

                if (tr.type === 'keydown') {
                  // prevent user select/choice by using click enter
                  return
                }

                if (val.length > 0) {
                  if ((tr.target as unknown as any).checked === true) {
                    tmp = (optionsBrand as unknown as any[]).find(
                      (dt) => dt.label === (tr.target as unknown as any).labels[0].innerText
                    )
                    result = [...label, tmp.label]
                  } else {
                    if (tr.type === 'click') {
                      if ((tr.target as unknown as any).lastChild.nodeName === 'path') {
                        let tmp_result = label.filter(
                          (e: any) =>
                            e !==
                            (tr.target as unknown as any).parentElement.parentElement.innerText
                        )
                        result = tmp_result
                      }
                    } else {
                      let tmp_result = label.filter(
                        (e: any) => e !== (tr.target as unknown as any).labels[0].innerText
                      )
                      result = tmp_result
                    }
                  }
                  setBrandID(val)
                  setBrandName(result)
                } else {
                  setBrandID([])
                  setBrandName([])
                }
                setPaymentMethodID([])
                setPaymentMethodName([])
              }}
              onSelect={(val, ite) => {
                let value: any = brandID
                let label: any = brandName
                let tmpValue: any = []
                let tmpLabel: any = []
                let resultValue: any[] = []
                let resultLabel: any[] = []

                if (val.length > 0) {
                  if (value.indexOf(ite.value) === -1) {
                    tmpValue = (optionsBrand as unknown as any[]).find(
                      (dt) => dt.value === ite.value
                    )

                    if (tmpValue === undefined) {
                      return
                    }

                    resultValue = [...value, tmpValue.value]
                    setBrandID(resultValue)
                  } else {
                    tmpValue = value.filter((dt: any) => dt !== ite.value)
                    setBrandID(tmpValue)
                  }

                  if (label.indexOf(ite.label) === -1) {
                    tmpLabel = (optionsBrand as unknown as any[]).find(
                      (dt) => dt.label === ite.label
                    )

                    if (tmpLabel === undefined) {
                      return
                    }

                    resultLabel = [...label, tmpLabel.label]
                    setBrandName(resultLabel)
                  } else {
                    tmpLabel = label.filter((dt: any) => dt !== ite.label)
                    setBrandName(tmpLabel)
                  }
                } else {
                  setBrandID([])
                  setBrandName([])
                }
                setPaymentMethodID([])
                setPaymentMethodName([])
              }}
              defaultValue={(optionsBrand as unknown as any[]).map((dt: any) => ({
                value: dt.value,
                label: dt.label,
              }))}
              value={brandID}
              renderExtraFooter={() => (
                <div style={footerStyles}>
                  <Checkbox
                    indeterminate={
                      brandID.length > 0 &&
                      brandID.length < (optionsBrand as unknown as any[]).length
                    }
                    checked={brandID.length === (optionsBrand as unknown as any[]).length}
                    onChange={(data, checked) => {
                      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                      if (checked) {
                        setBrandID((optionsBrand as unknown as any[]).map((e) => e.value))
                        setBrandName((optionsBrand as unknown as any[]).map((e) => e.label))
                      } else {
                        setBrandID([])
                        setBrandName([])
                      }
                    }}
                  >
                    {brandID.length === (optionsBrand as unknown as any[]).length
                      ? intl.formatMessage({id: 'LABEL_UNSELECT_ALL'})
                      : intl.formatMessage({id: 'LABEL_SELECT_ALL'})}
                  </Checkbox>
                </div>
              )}
            />
          </div>
          {/* Input group */}

          {/* Input group */}
          <div className='fv-row mb-5'>
            <label className='form-label fs-6 fw-bold'>
              {intl.formatMessage({id: 'LABEL_PAYMENT_METHOD'})} :
            </label>
            <CheckPicker
              block
              placeholder={`-- ${intl.formatMessage({id: 'LABEL_SELECT'})} --`}
              disabled={brandID.length > 0 ? false : true}
              data={optionsPaymentMethod}
              onChange={(val, tr) => {
                let tmp = []
                let result: any[] = []
                let label: any = paymentMethodName

                if (tr.type === 'keydown') {
                  // prevent user select/choice by using click enter
                  return
                }

                if (val.length > 0) {
                  if ((tr.target as unknown as any).checked === true) {
                    tmp = (optionsPaymentMethod as unknown as any[]).find(
                      (dt) => dt.label === (tr.target as unknown as any).labels[0].innerText
                    )
                    result = [...label, tmp.label]
                  } else {
                    if (tr.type === 'click') {
                      if ((tr.target as unknown as any).lastChild.nodeName === 'path') {
                        let tmp_result = label.filter(
                          (e: any) =>
                            e !==
                            (tr.target as unknown as any).parentElement.parentElement.innerText
                        )
                        result = tmp_result
                      }
                    } else {
                      let tmp_result = label.filter(
                        (e: any) => e !== (tr.target as unknown as any).labels[0].innerText
                      )
                      result = tmp_result
                    }
                  }
                  setPaymentMethodID(val)
                  setPaymentMethodName(result)
                } else {
                  setPaymentMethodID([])
                  setPaymentMethodName([])
                }
              }}
              onSelect={(val, ite) => {
                let value: any = paymentMethodID
                let label: any = paymentMethodName
                let tmpValue: any = []
                let tmpLabel: any = []
                let resultValue: any[] = []
                let resultLabel: any[] = []

                if (val.length > 0) {
                  if (value.indexOf(ite.value) === -1) {
                    tmpValue = (optionsPaymentMethod as unknown as any[]).find(
                      (dt) => dt.value === ite.value
                    )

                    if (tmpValue === undefined) {
                      return
                    }

                    resultValue = [...value, tmpValue.value]
                    setPaymentMethodID(resultValue)
                  } else {
                    tmpValue = value.filter((dt: any) => dt !== ite.value)
                    setPaymentMethodID(tmpValue)
                  }

                  if (label.indexOf(ite.label) === -1) {
                    tmpLabel = (optionsPaymentMethod as unknown as any[]).find(
                      (dt) => dt.label === ite.label
                    )

                    if (tmpLabel === undefined) {
                      return
                    }

                    resultLabel = [...label, tmpLabel.label]
                    setPaymentMethodName(resultLabel)
                  } else {
                    tmpLabel = label.filter((dt: any) => dt !== ite.label)
                    setPaymentMethodName(tmpLabel)
                  }
                } else {
                  setPaymentMethodID([])
                  setPaymentMethodName([])
                }
              }}
              defaultValue={(optionsPaymentMethod as unknown as any[]).map((dt: any) => ({
                value: dt.value,
                label: dt.label,
              }))}
              value={paymentMethodID}
              renderExtraFooter={() => (
                <div style={footerStyles}>
                  <Checkbox
                    indeterminate={
                      paymentMethodID.length > 0 &&
                      paymentMethodID.length < (optionsPaymentMethod as unknown as any[]).length
                    }
                    checked={
                      paymentMethodID.length === (optionsPaymentMethod as unknown as any[]).length
                    }
                    onChange={(data, checked) => {
                      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                      if (checked) {
                        setPaymentMethodID(
                          (optionsPaymentMethod as unknown as any[]).map((e) => e.value)
                        )
                        setPaymentMethodName(
                          (optionsPaymentMethod as unknown as any[]).map((e) => e.label)
                        )
                      } else {
                        setPaymentMethodID([])
                        setPaymentMethodName([])
                      }
                    }}
                  >
                    {paymentMethodID.length === (optionsPaymentMethod as unknown as any[]).length
                      ? intl.formatMessage({id: 'LABEL_UNSELECT_ALL'})
                      : intl.formatMessage({id: 'LABEL_SELECT_ALL'})}
                  </Checkbox>
                </div>
              )}
            />
          </div>
          {/* Input group */}

          {/* Input group */}
          <div className='fv-row mb-5'>
            <label className='form-label fs-6 fw-bold'>Status :</label>
            <InputPicker
              block
              data={optionStatus}
              placeholder={`-- ${intl.formatMessage({id: 'LABEL_SELECT'})} --`}
              defaultValue={
                optionStatus
                  ? (optionStatus as unknown as any[]).find((e: any) => e.value === status)
                  : ''
              }
              onChange={(e) => {
                setStatus(e)
              }}
              value={status}
            />
          </div>
          {/* Input group */}
        </div>
        {/* Content */}
      </Drawer>
    </>
  )
}

export {ListFilter}
