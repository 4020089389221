import {FC, useEffect, useState} from 'react'
import {Pagination} from 'rsuite'

import 'rsuite/dist/rsuite.min.css'

import {KTCardBody} from '_metronic/helpers'

type Props = {
  useListQueryRequest: any
  useListQueryResponse: any
}

const limitOptions = [5, 50, 250]

const PaginationHelpers: FC<Props> = ({useListQueryRequest, useListQueryResponse}) => {
  const [prev] = useState(true)
  const [next] = useState(true)
  const [first] = useState(true)
  const [last] = useState(true)
  const {updateState, state} = useListQueryRequest()
  const [activePage, setActivePage] = useState(state?.page)
  const [maxButtons] = useState(3)
  const [limit, setLimit] = useState(state?.items_per_page)
  const {response} = useListQueryResponse()

  const handlePerRowsChange = async (newPerPage: number) => {
    setLimit(newPerPage)
    setActivePage(1)
    updateState({page: 1, items_per_page: newPerPage})
  }

  const handlePageChange = (newPage: number) => {
    setActivePage(newPage)
    updateState({page: newPage, items_per_page: limit})
  }

  const handleFixPage = () => {
    updateState({page: 1})
    setActivePage(1)
  }

  useEffect(() => {
    if (response?.meta?.totalCount !== 0 && response?.data?.length === 0) {
      handleFixPage()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response])

  return (
    <KTCardBody>
      <Pagination
        layout={['total', '-', 'limit', '-', 'pager']}
        size={'sm'}
        prev={prev}
        next={next}
        first={first}
        last={last}
        ellipsis={true}
        boundaryLinks={false}
        total={response?.meta?.totalCount ? response?.meta?.totalCount : 0}
        limit={limit}
        limitOptions={limitOptions}
        maxButtons={maxButtons}
        activePage={activePage}
        onChangePage={(e) => {
          handlePageChange(e)
        }}
        onChangeLimit={(e) => handlePerRowsChange(e)}
      />
    </KTCardBody>
  )
}

export {PaginationHelpers}
