import {FC, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {Cell, Column, HeaderCell, Table} from 'rsuite-table'
import {Loader, Placeholder} from 'rsuite'

import {useThemeMode} from '_metronic/partials'
import {isMobileDevice} from '_metronic/assets/ts/_utils'
import {
  RowDatetimeCellFormatter,
  RowTooltipCellFormatter,
  RowVoidStatusCellFormatter,
} from 'app/components'

import {
  useListQueryRequest,
  useListQueryResponseLoading,
  useListQueryResponseData,
  useAction,
} from '../../providers'

const ListTable: FC = () => {
  const intl = useIntl()
  const isMobile = isMobileDevice()
  const {setItemIdForUpdate, currentAccessMenu, setAction} = useAction()
  const {state, updateState} = useListQueryRequest()
  const isLoading = useListQueryResponseLoading()
  const users = useListQueryResponseData()
  const data = useMemo(() => users, [users])
  const [usePlaceholder] = useState(true)
  const [sortColumn, setSortColumn] = useState(state?.sort)
  const [sortType, setSortType] = useState(state?.order)
  const {mode} = useThemeMode()

  const sortData = () => {
    if (sortColumn && sortType) {
      return data.sort((a: any, b: any) => {
        let x = a[sortColumn]
        let y = b[sortColumn]

        if (sortType === 'asc') {
          return x - y
        } else {
          return y - x
        }
      })
    }
    return data
  }

  const handleSortColumn = (sortColumn: any, sortType: any) => {
    setSortColumn(sortColumn)
    setSortType(sortType)
    updateState({sort: sortColumn, order: sortType})
  }

  const renderLoading = () => {
    if (usePlaceholder) {
      return (
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            background: mode === 'light' ? '#fff' : '#252530',
            padding: 20,
            zIndex: 1,
          }}
        >
          <Placeholder.Grid rows={5} columns={4} active />
        </div>
      )
    }

    return <Loader center backdrop content={intl.formatMessage({id: 'BUTTON_LABEL_PLEASE_WAIT'})} />
  }

  return (
    <div>
      <Table
        wordWrap={isMobile ? true : false}
        bordered
        cellBordered
        loading={isLoading}
        height={450}
        headerHeight={70}
        autoHeight={isMobile ? true : false}
        affixHeader={50}
        affixHorizontalScrollbar
        data={sortData()}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
        renderLoading={isLoading ? renderLoading : undefined}
        locale={{emptyMessage: intl.formatMessage({id: 'LABEL_EMPTY_DATA'})}}
        onRowClick={(dt) => {
          if (dt.status === 'waiting approval') {
            if (currentAccessMenu?.apv1?.access_role === 'apv1') {
              setAction('apv1')
              setItemIdForUpdate(dt.void_id)
            }
          } else {
            setAction('view')
            setItemIdForUpdate(dt.void_id)
          }
        }}
      >
        {/* Void Date */}
        <Column fixed={isMobile ? false : true} sortable verticalAlign='middle' width={300}>
          <HeaderCell align='center'>
            <span
              className={`fw-bolder fs-6 text-decoration-underline ${
                sortColumn === 'created_at' ? 'text-primary' : 'text-dark'
              }`}
            >
              {intl.formatMessage({id: 'LABEL_REQUEST_DATE'}).length < 20 ? (
                intl.formatMessage({id: 'LABEL_REQUEST_DATE'})
              ) : (
                <RowTooltipCellFormatter
                  text={intl.formatMessage({id: 'LABEL_REQUEST_DATE'})}
                  length={19}
                />
              )}
            </span>
          </HeaderCell>
          <Cell dataKey='created_at' style={{cursor: 'pointer'}}>
            {(rowData: any) => <RowDatetimeCellFormatter datetime={rowData.created_at} />}
          </Cell>
        </Column>

        {/* Order Date */}
        <Column fixed={isMobile ? false : true} sortable verticalAlign='middle' width={300}>
          <HeaderCell align='center'>
            <span
              className={`fw-bolder fs-6 text-decoration-underline ${
                sortColumn === 'created_at_order' ? 'text-primary' : 'text-dark'
              }`}
            >
              {intl.formatMessage({id: 'LABEL_ORDER_DATE'}).length < 20 ? (
                intl.formatMessage({id: 'LABEL_ORDER_DATE'})
              ) : (
                <RowTooltipCellFormatter
                  text={intl.formatMessage({id: 'LABEL_ORDER_DATE'})}
                  length={19}
                />
              )}
            </span>
          </HeaderCell>
          <Cell dataKey='created_at_order' style={{cursor: 'pointer'}}>
            {(rowData: any) => <RowDatetimeCellFormatter datetime={rowData.created_at_order} />}
          </Cell>
        </Column>

        {/* Status */}
        <Column sortable verticalAlign='middle' width={165}>
          <HeaderCell align='center'>
            <span
              className={`fw-bolder fs-6 text-decoration-underline ${
                sortColumn === 'status' ? 'text-primary' : 'text-dark'
              }`}
            >
              Status
            </span>
          </HeaderCell>
          <Cell dataKey='status' align='center' style={{cursor: 'pointer'}}>
            {(rowData: any) => <RowVoidStatusCellFormatter row={rowData.status} />}
          </Cell>
        </Column>

        {/* Brand */}
        <Column sortable verticalAlign='middle' width={100}>
          <HeaderCell align='center'>
            <span
              className={`fw-bolder fs-6 text-decoration-underline ${
                sortColumn === 'brand_name' ? 'text-primary' : 'text-dark'
              }`}
            >
              {intl.formatMessage({id: 'LABEL_BRAND'}).length < 20 ? (
                intl.formatMessage({id: 'LABEL_BRAND'})
              ) : (
                <RowTooltipCellFormatter
                  text={intl.formatMessage({id: 'LABEL_BRAND'})}
                  length={19}
                />
              )}
            </span>
          </HeaderCell>
          <Cell dataKey='brand_name' align='center' style={{cursor: 'pointer'}}>
            {(rowData: any) =>
              rowData.brand_name.length < 7 ? (
                rowData.brand_name
              ) : (
                <RowTooltipCellFormatter text={rowData.brand_name} length={6} />
              )
            }
          </Cell>
        </Column>

        {/* Outlet */}
        <Column sortable verticalAlign='middle' width={250}>
          <HeaderCell align='center'>
            <span
              className={`fw-bolder fs-6 text-decoration-underline ${
                sortColumn === 'outlet_name' ? 'text-primary' : 'text-dark'
              }`}
            >
              {intl.formatMessage({id: 'LABEL_OUTLET'}).length < 20 ? (
                intl.formatMessage({id: 'LABEL_OUTLET'})
              ) : (
                <RowTooltipCellFormatter
                  text={intl.formatMessage({id: 'LABEL_OUTLET'})}
                  length={19}
                />
              )}
            </span>
          </HeaderCell>
          <Cell dataKey='outlet_name' align='center' style={{cursor: 'pointer'}}>
            {(rowData: any) =>
              rowData.outlet_name.length < 35 ? (
                rowData.outlet_name
              ) : (
                <RowTooltipCellFormatter text={rowData.outlet_name} length={34} />
              )
            }
          </Cell>
        </Column>

        {/* Created By */}
        <Column sortable verticalAlign='middle' width={120}>
          <HeaderCell align='center'>
            <span
              className={`fw-bolder fs-6 text-decoration-underline ${
                sortColumn === 'created_by' ? 'text-primary' : 'text-dark'
              }`}
            >
              {intl.formatMessage({id: 'LABEL_CREATED_BY'}).length < 9 ? (
                intl.formatMessage({id: 'LABEL_CREATED_BY'})
              ) : (
                <RowTooltipCellFormatter
                  text={intl.formatMessage({id: 'LABEL_CREATED_BY'})}
                  length={8}
                />
              )}
            </span>
          </HeaderCell>
          <Cell dataKey='created_by' align='center' style={{cursor: 'pointer'}}>
            {(rowData: any) =>
              rowData.created_by.length < 10 ? (
                rowData.created_by
              ) : (
                <RowTooltipCellFormatter text={rowData.created_by} length={9} />
              )
            }
          </Cell>
        </Column>

        {/* Reason */}
        <Column sortable verticalAlign='middle' width={150}>
          <HeaderCell align='center'>
            <span
              className={`fw-bolder fs-6 text-decoration-underline ${
                sortColumn === 'notes' ? 'text-primary' : 'text-dark'
              }`}
            >
              {intl.formatMessage({id: 'LABEL_REASON'}).length < 15 ? (
                intl.formatMessage({id: 'LABEL_REASON'})
              ) : (
                <RowTooltipCellFormatter
                  text={intl.formatMessage({id: 'LABEL_REASON'})}
                  length={14}
                />
              )}
            </span>
          </HeaderCell>
          <Cell dataKey='notes' style={{cursor: 'pointer'}}>
            {(rowData: any) =>
              rowData.notes.length < 20 ? (
                rowData.notes
              ) : (
                <RowTooltipCellFormatter text={rowData.notes} length={19} />
              )
            }
          </Cell>
        </Column>

        {/* Receipt Number */}
        <Column sortable verticalAlign='middle' width={150}>
          <HeaderCell align='center'>
            <span
              className={`fw-bolder fs-6 text-decoration-underline ${
                sortColumn === 'receipt_number' ? 'text-primary' : 'text-dark'
              }`}
            >
              {intl.formatMessage({id: 'LABEL_RECEIPT_NUMBER'}).length < 10 ? (
                intl.formatMessage({id: 'LABEL_RECEIPT_NUMBER'})
              ) : (
                <RowTooltipCellFormatter
                  text={intl.formatMessage({id: 'LABEL_RECEIPT_NUMBER'})}
                  length={9}
                />
              )}
            </span>
          </HeaderCell>
          <Cell dataKey='receipt_number' align='center' style={{cursor: 'pointer'}}>
            {(rowData: any) =>
              rowData.receipt_number.length < 15 ? (
                rowData.receipt_number
              ) : (
                <RowTooltipCellFormatter text={rowData.receipt_number} length={14} />
              )
            }
          </Cell>
        </Column>

        {/* Approved By */}
        <Column sortable verticalAlign='middle' width={120}>
          <HeaderCell align='center'>
            <span
              className={`fw-bolder fs-6 text-decoration-underline ${
                sortColumn === 'approved_by' ? 'text-primary' : 'text-dark'
              }`}
            >
              {intl.formatMessage({id: 'LABEL_APPROVED_BY'}).length < 9 ? (
                intl.formatMessage({id: 'LABEL_APPROVED_BY'})
              ) : (
                <RowTooltipCellFormatter
                  text={intl.formatMessage({id: 'LABEL_APPROVED_BY'})}
                  length={8}
                />
              )}
            </span>
          </HeaderCell>
          <Cell dataKey='approved_by' align='center' style={{cursor: 'pointer'}}>
            {(rowData: any) =>
              rowData.approved_by.length < 10 ? (
                rowData.approved_by === '' ? (
                  '-'
                ) : (
                  rowData.approved_by
                )
              ) : (
                <RowTooltipCellFormatter text={rowData.approved_by} length={9} />
              )
            }
          </Cell>
        </Column>

        {/* Approved At */}
        <Column sortable verticalAlign='middle' width={300}>
          <HeaderCell align='center'>
            <span
              className={`fw-bolder fs-6 text-decoration-underline ${
                sortColumn === 'approved_at' ? 'text-primary' : 'text-dark'
              }`}
            >
              {intl.formatMessage({id: 'LABEL_APPROVED_AT'}).length < 20 ? (
                intl.formatMessage({id: 'LABEL_APPROVED_AT'})
              ) : (
                <RowTooltipCellFormatter
                  text={intl.formatMessage({id: 'LABEL_APPROVED_AT'})}
                  length={19}
                />
              )}
            </span>
          </HeaderCell>
          <Cell dataKey='approved_at' style={{cursor: 'pointer'}}>
            {(rowData: any) =>
              rowData.approved_at === '0001-01-01T00:00:00Z' ? (
                '-'
              ) : (
                <RowDatetimeCellFormatter datetime={rowData.approved_at} />
              )
            }
          </Cell>
        </Column>
      </Table>
    </div>
  )
}

export {ListTable}
