import {FC, lazy, useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {Routes, Route, Outlet, Navigate, useLocation} from 'react-router-dom'
import toast from 'react-hot-toast'

import {PageLink, PageTitle} from '_metronic/layout/core'
import {useUserGroupModules} from 'app/context/providers'
import {CurrentMenuAccessModel} from 'app/context/models'
import {getCurrentMenuAccessByRoleIDAndMenuID} from 'app/context/requests'
import {removeAuth, useAuth} from 'app/modules/auth'
import {SuspendViewComponents} from 'app/components'

const DataPageProviderWrapper = lazy(() => import('./DataPageProviderWrapper'))

const DatasPageWrapper: FC = () => {
  const intl = useIntl()
  const {pathname} = useLocation()
  const {
    currentModuleLabel,
    currentModulePath,
    currentSiteLabel,
    currentSitePath,
    currentParentMenuLabel,
    currentParentMenuPath,
    currentMenuLabel,
    currentMenuID,
  } = useUserGroupModules()
  const didRequest = useRef(false)
  const {currentUser} = useAuth()
  const [access, setAccess] = useState<CurrentMenuAccessModel | undefined>(undefined)

  const breadCrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({id: 'LABEL_HOMEPAGE'}),
      path: 'homepage',
      isSeparator: false,
      isActive: false,
    },
    {
      title: currentModuleLabel,
      path: currentModulePath,
      isSeparator: false,
      isActive: false,
    },
    {
      title: currentSiteLabel,
      path: currentSitePath,
      isSeparator: false,
      isActive: false,
    },
    {
      title: currentParentMenuLabel,
      path: currentParentMenuPath + '/idk',
      isSeparator: false,
      isActive: false,
    },
  ]

  useEffect(() => {
    const requestCurrentMenuAccess = async () => {
      try {
        if (!didRequest.current) {
          const access = await getCurrentMenuAccessByRoleIDAndMenuID(
            currentUser?.role.role_id,
            currentMenuID
          )
          // throw message error
          if (access.data.meta.code === 200) {
            setAccess(access.data.data)
          }
          if (access.data.meta.code === 404 || access.data.meta.code === 500) {
            toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
          }
          if (access.data.meta.code === 401) {
            toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
            removeAuth()
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
        }
      } finally {
      }

      return () => (didRequest.current = true)
    }

    if (
      currentMenuID !== undefined &&
      currentMenuID !== '' &&
      pathname.split('/')[3] === currentParentMenuPath.split('/')[3]
    ) {
      requestCurrentMenuAccess()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMenuID])

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path={pathname.split('/')[4]}
          element={
            <SuspendViewComponents>
              <PageTitle breadcrumbs={breadCrumbs}>{currentMenuLabel}</PageTitle>
              <DataPageProviderWrapper access={access} />
            </SuspendViewComponents>
          }
        />
        <Route index element={<Navigate to='/general/settings/datas/' />} />
      </Route>
    </Routes>
  )
}

export {DatasPageWrapper}
