import {FC, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {Cell, Column, HeaderCell, Table} from 'rsuite-table'
import {Loader, Placeholder} from 'rsuite'

import {useThemeMode} from '_metronic/partials'
import {isMobileDevice} from '_metronic/assets/ts/_utils'
import {RowStatusCellFormatter, RowTooltipCellFormatter} from 'app/components'

import {ListActionFormatter} from './ListActionFormatter'
import {
  useListQueryRequest,
  useListQueryResponseLoading,
  useListQueryResponseData,
} from '../../providers'

const ListTable: FC = () => {
  const intl = useIntl()
  const isMobile = isMobileDevice()
  const {state, updateState} = useListQueryRequest()
  const isLoading = useListQueryResponseLoading()
  const users = useListQueryResponseData()
  const data = useMemo(() => users, [users])
  const [usePlaceholder] = useState(true)
  const [sortColumn, setSortColumn] = useState(state?.sort)
  const [sortType, setSortType] = useState(state?.order)
  const {mode} = useThemeMode()

  const sortData = () => {
    if (sortColumn && sortType) {
      return data.sort((a: any, b: any) => {
        let x = a[sortColumn]
        let y = b[sortColumn]

        if (sortType === 'asc') {
          return x - y
        } else {
          return y - x
        }
      })
    }
    return data
  }

  const handleSortColumn = (sortColumn: any, sortType: any) => {
    setSortColumn(sortColumn)
    setSortType(sortType)
    updateState({sort: sortColumn, order: sortType})
  }

  const renderLoading = () => {
    if (usePlaceholder) {
      return (
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            background: mode === 'light' ? '#fff' : '#252530',
            padding: 20,
            zIndex: 1,
          }}
        >
          <Placeholder.Grid rows={5} columns={4} active />
        </div>
      )
    }

    return <Loader center backdrop content={intl.formatMessage({id: 'BUTTON_LABEL_PLEASE_WAIT'})} />
  }

  return (
    <div>
      <Table
        wordWrap={isMobile ? true : false}
        bordered
        cellBordered
        loading={isLoading}
        height={450}
        headerHeight={70}
        autoHeight={isMobile ? true : false}
        affixHeader={50}
        affixHorizontalScrollbar
        data={sortData()}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
        renderLoading={isLoading ? renderLoading : undefined}
        locale={{emptyMessage: intl.formatMessage({id: 'LABEL_EMPTY_DATA'})}}
      >
        {/* Name */}
        <Column fixed={isMobile ? false : true} sortable verticalAlign='middle' width={500}>
          <HeaderCell align='center'>
            <span
              className={`fw-bold fs-6 text-decoration-underline ${
                sortColumn === 'name' ? 'text-primary' : 'text-dark'
              }`}
            >
              {intl.formatMessage({id: 'LABEL_NAME'})}
            </span>
          </HeaderCell>
          <Cell dataKey='name'>
            {(rowData: any) =>
              rowData.name.length < 65 ? (
                rowData.name
              ) : (
                <RowTooltipCellFormatter text={rowData.name} length={64} />
              )
            }
          </Cell>
        </Column>

        {/* City */}
        <Column sortable verticalAlign='middle' width={500}>
          <HeaderCell align='center'>
            <span
              className={`fw-bold fs-6 text-decoration-underline ${
                sortColumn === 'city' ? 'text-primary' : 'text-dark'
              }`}
            >
              {intl.formatMessage({id: 'LABEL_CITY'})}
            </span>
          </HeaderCell>
          <Cell dataKey='city' align='center'>
            {(rowData: any) =>
              rowData.city.length < 64 ? (
                rowData.city
              ) : (
                <RowTooltipCellFormatter text={rowData.city} length={64} />
              )
            }
          </Cell>
        </Column>

        {/* Status */}
        <Column sortable verticalAlign='middle' width={200}>
          <HeaderCell align='center'>
            <span
              className={`fw-bold fs-6 text-decoration-underline ${
                sortColumn === 'status' ? 'text-primary' : 'text-dark'
              }`}
            >
              Status
            </span>
          </HeaderCell>
          <Cell dataKey='status' align='center'>
            {(rowData: any) => <RowStatusCellFormatter row={rowData.status} />}
          </Cell>
        </Column>

        {/* Action */}
        <Column fixed='right' align='right' verticalAlign='middle' width={135}>
          <HeaderCell>
            <span className={`fw-bold fs-6 text-dark text-decoration-underline`}>
              {intl.formatMessage({id: 'LABEL_ACTION'})}
            </span>
          </HeaderCell>
          <Cell dataKey='action' style={{flexWrap: 'nowrap'}}>
            {(rowData: any) => (
              <ListActionFormatter district_id={rowData.district_id} status={rowData.status} />
            )}
          </Cell>
        </Column>
      </Table>
    </div>
  )
}

export {ListTable}
