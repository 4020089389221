import {FC} from 'react'
import {useIntl} from 'react-intl'

type Props = {
  row?: string
}

function coloringRows(rowLabel: any) {
  switch (rowLabel) {
    case 's':
      return '-primary'
    case 'c':
      return '-success'
    case 'f':
      return '-danger'
    default:
      return '-secondary'
  }
}

const RowStatusScheduleCellFormatter: FC<Props> = ({row}) => {
  const intl = useIntl()
  return (
    <div className={`badge badge${coloringRows(row)} fw-bolder`}>
      {row === 'p'
        ? intl.formatMessage({id: 'GENERAL_SCHEDULE.PENDING.LABEL'})
        : row === 's'
        ? intl.formatMessage({id: 'GENERAL_SCHEDULE.START.LABEL'})
        : row === 'c'
        ? intl.formatMessage({id: 'GENERAL_SCHEDULE.COMPLETE.LABEL'})
        : intl.formatMessage({id: 'GENERAL_SCHEDULE.CANCEL.LABEL'})}
    </div>
  )
}

export {RowStatusScheduleCellFormatter}
