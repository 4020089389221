import {FC} from 'react'

import {ViewModalForm} from '..'
import {useAction} from '../../providers'

type Props = {appMenuID: any; modalID: any}

const ViewModalFormWrapper: FC<Props> = ({modalID}) => {
  const {itemIdForUpdate} = useAction()
  return <ViewModalForm shift_date={itemIdForUpdate} />
}

export {ViewModalFormWrapper}
