import axios, {AxiosResponse} from 'axios'

import {APIResponse} from 'app/components'

import {ListQueryResponse} from './_list_models'
import {DataID} from '.'

const API_APP_URL = process.env.REACT_APP_APP_API_URL
const X_API_KEY = process.env.REACT_APP_APP_API_KEY
const GET_MASTER_DATA_LIST_URL = `${API_APP_URL}/master-data?`
const GET_MASTER_DATA_URL = `${API_APP_URL}/master-data/fetch-master-data?`
const GET_PARAMETER_BY_MENU_ID_FOR_INPUT_URL = `${API_APP_URL}/master-data/get-parameter-by-menu-id?`
const ADD_UPDATE_MASTER_DATA_URL = `${API_APP_URL}/master-data?`
const DELETE_MASTER_DATA_URL = `${API_APP_URL}/master-data`

const getList = (query: any): Promise<ListQueryResponse> => {
  return axios
    .get(`${GET_MASTER_DATA_LIST_URL}${query}`, {
      headers: {'X-API-KEY': `${X_API_KEY}`},
    })
    .then((d: AxiosResponse<ListQueryResponse>) => d.data)
}

const getParameterByMenuIDForInput = (menu_id: string | undefined) => {
  return axios.get(`${GET_PARAMETER_BY_MENU_ID_FOR_INPUT_URL}menu_id=${menu_id}`, {
    headers: {'X-API-KEY': `${X_API_KEY}`},
  })
}

const getDataByID = (data_id: DataID): Promise<APIResponse | undefined> => {
  return axios
    .get(`${GET_MASTER_DATA_URL}data_id=${data_id}`, {
      headers: {'X-API-KEY': `${X_API_KEY}`},
    })
    .then((response: AxiosResponse<APIResponse>) => response.data)
}

const updateData = (payload: any): Promise<APIResponse | undefined> => {
  return axios
    .put(
      `${ADD_UPDATE_MASTER_DATA_URL}`,
      {payload},
      {
        headers: {'X-API-KEY': `${X_API_KEY}`},
      }
    )
    .then((response: AxiosResponse<APIResponse>) => response.data)
}

const addData = (payload: any): Promise<APIResponse | undefined> => {
  return axios
    .post(
      `${ADD_UPDATE_MASTER_DATA_URL}`,
      {payload},
      {
        headers: {'X-API-KEY': `${X_API_KEY}`},
      }
    )
    .then((response: AxiosResponse<APIResponse>) => response.data)
}

const deleteData = (data_id: any, menu_id: any): Promise<APIResponse | undefined> => {
  return axios
    .delete(`${DELETE_MASTER_DATA_URL}/${data_id}/${menu_id}`, {
      headers: {'X-API-KEY': `${X_API_KEY}`},
    })
    .then((response: AxiosResponse<APIResponse>) => response.data)
}

export {getList, getParameterByMenuIDForInput, getDataByID, updateData, addData, deleteData}
