import {SiteID, ListResponse} from '.'

export type ListModel = {
  site_id?: SiteID
  module_id?: string
  for_order?: string
  icon?: string
  name_eng?: string
  name_idn?: string
  path?: string
  status?: string
}

export type ListQueryResponse = ListResponse<Array<ListModel>>

export type GetDataByIDModel = {
  created_at?: string
  created_by?: string
  site_id?: string
  module_id?: string
  for_order?: string
  icon?: string
  name_eng?: string
  name_idn?: string
  path?: string
  status?: string
  updated_at?: string
  updated_by?: string
  menu_id?: string
}

export const initialData: GetDataByIDModel = {
  created_at: '',
  created_by: '',
  for_order: '',
  icon: '',
  site_id: '',
  module_id: '',
  name_eng: '',
  name_idn: '',
  path: '',
  status: '',
  updated_at: '',
  updated_by: '',
  menu_id: '',
}
