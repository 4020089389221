import {HoldingID, ListResponse} from '.'

export type ListModel = {
  holding_id?: string
  name?: string
  slug?: string
  email?: string
  description?: string
  status?: string
}

export type ListQueryResponse = ListResponse<Array<ListModel>>

export const initialList: ListModel = {
  holding_id: '',
  name: '',
  slug: '',
  email: '',
  description: '',
  status: '',
}

export type DetailModel = {
  holding_id?: HoldingID
  name?: string
  slug?: string
  email?: string
  description?: string
  status?: string
  created_at?: string
  created_by?: string
  updated_at?: string
  updated_by?: string
  menu_id?: string // modified payload for crud
  creator_id?: string // modified payload for crud
  creator_by?: string // modified payload for crud
}

export const initialData: DetailModel = {
  holding_id: '',
  name: '',
  slug: '',
  email: '',
  description: '',
  status: '',
  created_at: '',
  created_by: '',
  updated_at: '',
  updated_by: '',
  menu_id: '', // modified payload for crud
  creator_id: '', // modified payload for crud
  creator_by: '', // modified payload for crud
}
