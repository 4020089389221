import {FC} from 'react'
import toast from 'react-hot-toast'
import {useQuery} from 'react-query'

import {QUERIES, isNotEmpty} from '_metronic/helpers'
import {useUserGroupModules} from 'app/context/providers'
import {removeAuth} from 'app/modules/auth'

import {useAction} from '../../providers'
import {getDataByID} from '../../helpers'
import {ViewModalForm} from '..'

type Props = {appMenuID: any; modalID: any}

const ViewModalFormWrapper: FC<Props> = ({appMenuID, modalID}) => {
  const {itemIdForUpdate, isLoading, setIsLoading} = useAction()
  const {currentModulePath, currentModuleID} = useUserGroupModules()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {data: userGroupAccess, error} = useQuery(
    [`${currentModulePath}-${QUERIES.GET_USER_GROUP_ACCESS}-${itemIdForUpdate}`],
    () => {
      setIsLoading(true)
      return getDataByID(itemIdForUpdate, currentModuleID)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      refetchOnWindowFocus: false,
      onError: (err: any) => {
        if (err.response.status !== 200) {
          setIsLoading(false)
          if (err.response.status === 401) {
            toast.error(err.response.statusText)
            removeAuth()
          } else {
            toast.error(err.response.statusText)
          }
        }
      },
      onSuccess: () => {
        setIsLoading(false)
      },
    }
  )

  if (!error && userGroupAccess) {
    return (
      <ViewModalForm
        isLoading={isLoading}
        userGroupAccess={userGroupAccess.data}
        appMenuID={appMenuID}
        modalID={modalID}
        appModuleID={currentModuleID}
      />
    )
  }

  return null
}

export {ViewModalFormWrapper}
