import {OutletID, ListResponse} from '.'
import {areaModel} from '../../cluster/helpers'

export type ListModel = {
  outlet_id?: string
  name?: string
  brand?: string
  address?: string
  postal_code?: string
  phone_number?: string
  status?: string
}

export type ListQueryResponse = ListResponse<Array<ListModel>>

export const initialList: ListModel = {
  outlet_id: '',
  name: '',
  brand: '',
  address: '',
  postal_code: '',
  phone_number: '',
  status: '',
}

export type HoldingModel = {
  holding_id: string
  name: string
  slug: string
}
export type CompanyModel = {
  company_id: string
  name: string
  slug: string
}
export type BrandModel = {
  brand_id: string
  name: string
  slug: string
}
export type ProvinceModel = {
  province_id: string
  name: string
}
export type CityModel = {
  city_id: string
  name: string
}
export type DistrictModel = {
  district_id: string
  name: string
}
export type VillageModel = {
  village_id: string
  name: string
}
export type AreaModel = {
  area_id: string
  name: string
}
export type ClusterModel = {
  cluster_id: string
  name: string
}

export type DetailModel = {
  outlet_id?: OutletID
  holding_id?: string // modified payload for crud
  holding_name?: string // modified payload for crud
  company_id?: string // modified payload for crud
  company_name?: string // modified payload for crud
  brand_id?: string // modified payload for crud
  brand_name?: string // modified payload for crud
  province_id?: string // modified payload for crud
  province_name?: string // modified payload for crud
  city_id?: string // modified payload for crud
  city_name?: string // modified payload for crud
  district_id?: string // modified payload for crud
  district_name?: string // modified payload for crud
  village_id?: string // modified payload for crud
  village_name?: string // modified payload for crud
  area_id?: string // modified payload for crud
  area_name?: string // modified payload for crud
  cluster_id?: string // modified payload for crud
  cluster_name?: string // modified payload for crud
  name?: string
  address?: string
  postal_code?: string
  phone_number?: string
  status?: string
  holding?: HoldingModel
  company?: CompanyModel
  brand?: BrandModel
  province?: ProvinceModel
  city?: CityModel
  district?: DistrictModel
  village?: VillageModel
  area?: areaModel
  cluster?: ClusterModel
  created_at?: string
  created_by?: string
  updated_at?: string
  updated_by?: string
  menu_id?: string // modified payload for crud
  creator_id?: string // modified payload for crud
  creator_by?: string // modified payload for crud
}

export const initialData: DetailModel = {
  outlet_id: '',
  holding_id: '', // modified payload for crud
  holding_name: '', // modified payload for crud
  company_id: '', // modified payload for crud
  company_name: '', // modified payload for crud
  brand_id: '', // modified payload for crud
  brand_name: '', // modified payload for crud
  province_id: '', // modified payload for crud
  province_name: '', // modified payload for crud
  city_id: '', // modified payload for crud
  city_name: '', // modified payload for crud
  district_id: '', // modified payload for crud
  district_name: '', // modified payload for crud
  village_id: '', // modified payload for crud
  village_name: '', // modified payload for crud
  area_id: '', // modified payload for crud
  area_name: '', // modified payload for crud
  cluster_id: '', // modified payload for crud
  cluster_name: '', // modified payload for crud
  name: '',
  address: '',
  postal_code: '',
  phone_number: '',
  status: '',
  holding: {
    holding_id: '',
    name: '',
    slug: '',
  },
  company: {
    company_id: '',
    name: '',
    slug: '',
  },
  brand: {
    brand_id: '',
    name: '',
    slug: '',
  },
  province: {
    province_id: '',
    name: '',
  },
  city: {
    city_id: '',
    name: '',
  },
  district: {
    district_id: '',
    name: '',
  },
  village: {
    village_id: '',
    name: '',
  },
  area: {
    area_id: '',
    name: '',
  },
  cluster: {
    cluster_id: '',
    name: '',
  },
  created_at: '',
  created_by: '',
  updated_at: '',
  updated_by: '',
  menu_id: '', // modified payload for crud
  creator_id: '', // modified payload for crud
  creator_by: '', // modified payload for crud
}
