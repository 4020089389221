/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {Dialog} from '@headlessui/react'
import clsx from 'clsx'
import toast from 'react-hot-toast'
import {Drawer, InputPicker, Toggle} from 'rsuite'
import {confirmAlert} from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'

import {
  NotAuthRedirectForm,
  inputPhoneNumbers,
  inputPostalCode,
  CreateSelect,
  FormLog,
  getFormAction,
  SpinnerIndicator,
} from 'app/components'
import {getAuth, removeAuth, useAuth} from 'app/modules/auth'

import {
  DetailModel,
  addData,
  deleteData,
  fetchAreaOptions,
  fetchBrandOptions,
  fetchCityOptions,
  fetchClusterOptions,
  fetchCompanyOptions,
  fetchDistrictOptions,
  fetchHoldingOptions,
  fetchProvinceOptions,
  fetchVillageOptions,
  initialData,
  updateData,
} from '../../helpers'
import {useAction, useListQueryResponse} from '../../providers'

type Props = {
  isLoading: boolean
  outlet: DetailModel
  appMenuID: any
  modalID: any
  menuName: any
}

const ViewDrawerForm: FC<Props> = ({menuName, outlet, isLoading, appMenuID, modalID}) => {
  const auth = getAuth()
  const intl = useIntl()
  const [tab, setTab] = useState('data')
  const {currentUser} = useAuth()
  const {
    setItemIdForUpdate,
    action,
    holdingOptions,
    companyOptions,
    brandOptions,
    provinceOptions,
    cityOptions,
    districtOptions,
    villageOptions,
    areaOptions,
    clusterOptions,
    setHoldingOptions,
    setCompanyOptions,
    setBrandOptions,
    setProvinceOptions,
    setCityOptions,
    setDistrictOptions,
    setVillageOptions,
    setAreaOptions,
    setClusterOptions,
  } = useAction()
  const [isSelectDisable, setIsSelectDisable] = useState(true)
  const {refetch} = useListQueryResponse()
  const [isAuth, setIsAuth] = useState(true)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [completed, setCompleted] = useState(false)
  const didRequestHolding = useRef(false)
  const didRequestCompany = useRef(false)
  const didRequestBrand = useRef(false)
  const didRequestProvince = useRef(false)
  const didRequestCity = useRef(false)
  const didRequestDistrict = useRef(false)
  const didRequestVillage = useRef(false)
  const didRequestArea = useRef(false)
  const didRequestCluster = useRef(false)
  const optionsHolding =
    holdingOptions === undefined
      ? []
      : (holdingOptions as unknown as any[]).map((holdings: any) => ({
          value: holdings.holding_id,
          label: holdings.name,
        }))
  const optionsCompany =
    companyOptions === undefined
      ? []
      : (companyOptions as unknown as any[]).map((company: any) => ({
          value: company.company_id,
          label: company.name,
        }))
  const optionsBrand =
    brandOptions === undefined
      ? []
      : (brandOptions as unknown as any[]).map((brand: any) => ({
          value: brand.brand_id,
          label: brand.name,
        }))
  const optionsProvince =
    provinceOptions === undefined
      ? []
      : (provinceOptions as unknown as any[]).map((province: any) => ({
          value: province.province_id,
          label: province.name,
        }))
  const optionsCity =
    cityOptions === undefined
      ? []
      : (cityOptions as unknown as any[]).map((city: any) => ({
          value: city.city_id,
          label: city.name,
        }))
  const optionsDistrict =
    districtOptions === undefined
      ? []
      : (districtOptions as unknown as any[]).map((district: any) => ({
          value: district.district_id,
          label: district.name,
        }))
  const optionsVillage =
    villageOptions === undefined
      ? []
      : (villageOptions as unknown as any[]).map((village: any) => ({
          value: village.village_id,
          label: village.name,
        }))
  const optionsArea =
    areaOptions === undefined
      ? []
      : (areaOptions as unknown as any[]).map((area: any) => ({
          value: area.area_id,
          label: area.name,
        }))
  const optionsCluster =
    clusterOptions === undefined
      ? []
      : (clusterOptions as unknown as any[]).map((cluster: any) => ({
          value: cluster.cluster_id,
          label: cluster.name,
        }))

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(5, intl.formatMessage({id: 'VALIDATION_MIN_LENGTH_FIELD'}, {min: 5}))
      .max(225, intl.formatMessage({id: 'VALIDATION_MAX_LENGTH_FIELD'}, {max: 225}))
      .required(
        intl.formatMessage(
          {id: 'VALIDATION_REQUIRED_FIELD'},
          {name: intl.formatMessage({id: 'LABEL_NAME'})}
        )
      ),
    address: Yup.string().required(
      intl.formatMessage(
        {id: 'VALIDATION_REQUIRED_FIELD'},
        {name: intl.formatMessage({id: 'LABEL_ADDRESS'})}
      )
    ),
    postal_code: Yup.string()
      .min(4, intl.formatMessage({id: 'VALIDATION_MIN_LENGTH_FIELD'}, {min: 4}))
      .max(10, intl.formatMessage({id: 'VALIDATION_MAX_LENGTH_FIELD'}, {max: 10}))
      .required(
        intl.formatMessage(
          {id: 'VALIDATION_REQUIRED_FIELD'},
          {name: intl.formatMessage({id: 'LABEL_POSTAL_CODE'})}
        )
      ),
    phone_number: Yup.string()
      .matches(
        /^(?:\+62|62|0)[2-9]\d{7,13}$/,
        intl.formatMessage({id: 'VALIDATION_INPUT_PHONE_NUMBER'})
      )
      .required(
        intl.formatMessage(
          {id: 'VALIDATION_REQUIRED_FIELD'},
          {name: intl.formatMessage({id: 'LABEL_MOBILE_PHONE'})}
        )
      ),
  })

  const [models] = useState<DetailModel>({
    ...outlet,
    outlet_id: outlet.outlet_id || initialData.outlet_id,
    company_id: outlet.company_id || initialData.company_id,
    company_name: outlet.company?.name || initialData.company?.name,
    holding_id: outlet.holding_id || initialData.holding_id,
    holding_name: outlet.holding?.name || initialData.holding?.name,
    brand_id: outlet.brand_id || initialData.brand_id,
    brand_name: outlet.brand?.name || initialData.brand?.name,
    province_id: outlet.province_id || initialData.province_id,
    province_name: outlet.province?.name || initialData.province?.name,
    city_id: outlet.city_id || initialData.city_id,
    city_name: outlet.city?.name || initialData.city?.name,
    district_id: outlet.district_id || initialData.district_id,
    district_name: outlet.district?.name || initialData.district?.name,
    village_id: outlet.village_id || initialData.village_id,
    village_name: outlet.village?.name || initialData.village?.name,
    area_id: outlet.area_id || initialData.area_id,
    area_name: outlet.area?.name || initialData.area?.name,
    cluster_id: outlet.cluster_id || initialData.cluster_id,
    cluster_name: outlet.cluster?.name || initialData.cluster?.name,
    name: outlet.name || initialData.name,
    status: outlet.status || initialData.status,
    created_at: outlet.created_at || initialData.created_at,
    created_by: outlet.created_by || initialData.created_by,
    updated_at: outlet.updated_at || initialData.updated_at,
    updated_by: outlet.updated_by || initialData.updated_by,
    menu_id: appMenuID,
    creator_id: currentUser?.user_id,
    creator_by: currentUser?.username,
  })

  const formik = useFormik({
    initialValues: models,
    validationSchema: validationSchema,
    onSubmit: async (values, {setSubmitting, setStatus, setFieldError}) => {
      setIsDialogOpen(false)
      action === 'edit'
        ? requestUpdateData(values, setStatus, setSubmitting, setFieldError)
        : action === 'add'
          ? requestAddData(values, setStatus, setSubmitting, setFieldError)
          : requestDeleteData(values, setStatus, setSubmitting)
    },
    onReset: (values) => {
      values = models
    },
  })

  const requestUpdateData = async (
    values: any,
    setStatus: any,
    setSubmitting: any,
    setFieldError: any
  ) => {
    setLoading(true)
    setStatus(null)
    setSubmitting(true)

    // request update to API
    try {
      const res = await updateData(values)

      // throw message error
      if (res?.meta.code !== 200) {
        if (res?.meta.message === 'name already used') {
          setStatus(intl.formatMessage({id: 'RESPONSE_MASTER_OUTLET_NAME_ALREADY_EXIST'}))
          setFieldError(
            'name',
            intl.formatMessage({id: 'RESPONSE_MASTER_OUTLET_NAME_ALREADY_EXIST'})
          )
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_MASTER_OUTLET_EDIT_SUCCESS'}))
        setCompleted(true)
        setTimeout(function () {
          setItemIdForUpdate(undefined)
        }, 1e3)
        refetch()
      }
    } catch (error: any) {
      console.error(error)
      if (error.response.status === 401) {
        setStatus(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
        removeAuth()
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
      }
      setSubmitting(false)
      setLoading(false)
      setTimeout(function () {
        setStatus(null)
      }, 9e3)

      return false
    } finally {
      setTimeout(() => {
        setLoading(false)
        setSubmitting(false)
      }, 5e2)
    }
  }
  const requestAddData = async (
    values: any,
    setStatus: any,
    setSubmitting: any,
    setFieldError: any
  ) => {
    setLoading(true)
    setStatus(null)
    setSubmitting(true)

    // request add to API
    try {
      const res = await addData(values)

      // throw message error
      if (res?.meta.code !== 200) {
        if (res?.meta.message === 'name already used') {
          setStatus(intl.formatMessage({id: 'RESPONSE_MASTER_OUTLET_NAME_ALREADY_EXIST'}))
          setFieldError(
            'name',
            intl.formatMessage({id: 'RESPONSE_MASTER_OUTLET_NAME_ALREADY_EXIST'})
          )
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_MASTER_OUTLET_ADD_SUCCESS'}))
        setCompleted(true)
        setTimeout(function () {
          setItemIdForUpdate(undefined)
        }, 1e3)
        refetch()
      }
    } catch (error: any) {
      console.error(error)
      if (error.response.status === 401) {
        setStatus(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
        removeAuth()
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
      }
      setSubmitting(false)
      setLoading(false)
      setTimeout(function () {
        setStatus(null)
      }, 9e3)

      return false
    } finally {
      setTimeout(() => {
        setLoading(false)
        setSubmitting(false)
      }, 5e2)
    }
  }
  const requestDeleteData = async (values: any, setStatus: any, setSubmitting: any) => {
    setLoading(true)
    setStatus(null)
    setSubmitting(true)

    // request delete to API
    try {
      const res = await deleteData(
        values.outlet_id,
        values.menu_id,
        values.creator_id,
        values.creator_by
      )

      // throw message error
      if (res?.meta.code !== 200) {
        if (res?.meta.message === 'not inactive') {
          setStatus(intl.formatMessage({id: 'RESPONSE_MASTER_OUTLET_IS_ACTIVE'}))
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
        if (res?.meta.message === 'inused by other data') {
          setStatus(intl.formatMessage({id: 'RESPONSE_MASTER_OUTLET_IS_INUSED'}))
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_MASTER_OUTLET_DELETE_SUCCESS'}))
        setCompleted(true)
        setTimeout(function () {
          setItemIdForUpdate(undefined)
        }, 1e3)
        refetch()
      }
    } catch (error: any) {
      console.error(error)
      if (error.response.status === 401) {
        setStatus(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
        removeAuth()
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
      }
      setSubmitting(false)
      setLoading(false)
      setTimeout(function () {
        setStatus(null)
      }, 9e3)

      return false
    } finally {
      setTimeout(() => {
        setLoading(false)
        setSubmitting(false)
      }, 5e2)
    }
  }

  const handleCreateFinish = (label: string) => {
    switch (label) {
      case 'province':
        return requestProvinceOptions()
      case 'city':
        return requestCityOptions()
      case 'district':
        return requestDistrictOptions()
      case 'village':
        return requestVillageOptions()
      case 'area':
        return requestAreaOptions()
      case 'cluster':
        return requestClusterOptions()
    }
  }

  const createNewMasterData = async (value: any, label: any, parent_id: any) => {
    try {
      setIsSelectDisable(true)

      const response = await CreateSelect({parent_id, value, label, appMenuID, currentUser})
      const data = response?.data

      return data
    } catch (err: any) {
      console.error(err.response.data.meta.message)
      toast.error(<span>{err.response.data.meta.message}</span>, {icon: '⛔️'})
    } finally {
      // eslint-disable-next-line
      handleCreateFinish(label)
    }
  }

  const handleCreate = (
    value: string,
    label: string,
    parent_id: string | undefined,
    intl_label: string
  ) => {
    const saveNewMasterData = createNewMasterData(value, label, parent_id)

    toast.promise(
      saveNewMasterData,
      {
        loading: '⏳ ' + intl.formatMessage({id: 'RESPONSE_SELECT_MASTER_SUBMITTING'}),
        success: () => (
          <span>
            {intl.formatMessage({id: 'RESPONSE_SELECT_MASTER_ADD_SUCCESS'})}
            <br />
            <strong>{intl_label}: </strong>
            <strong>{value}</strong>
          </span>
        ),
        error: (err) =>
          `${intl.formatMessage({
            id: 'RESPONSE_SELECT_MASTER_ADD_ERROR',
          })} ${label} ${err.toString()}`,
      },
      {
        style: {
          minWidth: '250px',
        },
        success: {
          duration: 5000,
          icon: '🔥',
        },
      }
    )
  }

  function confirmBox(
    title: string,
    message: string,
    confirmLabel: string,
    cancelLabel: string,
    label: string,
    value: string,
    parent_id: string | undefined,
    master: string
  ) {
    return confirmAlert({
      title: title,
      message: message,
      buttons: [
        {
          label: cancelLabel,
          onClick: () => {
            return false
          },
        },
        {
          label: confirmLabel,
          onClick: () => {
            return handleCreate(value, label, parent_id, master)
          },
        },
      ],
      closeOnClickOutside: false,
      closeOnEscape: false,
    })
  }

  useEffect(() => {
    auth === undefined && setIsAuth(false)
  }, [auth])

  useEffect(() => {
    const requestHoldingOptions = async () => {
      try {
        if (!didRequestHolding.current) {
          const options = await fetchHoldingOptions('input')
          // throw message error
          if (options.data.meta.code === 200) {
            // set option
            setHoldingOptions(options.data.data)
          }
        }
      } catch (error: any) {
        console.error(error)
        if (error.response.status === 401) {
          toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
          removeAuth()
        } else {
          toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
        }
      } finally {
        setIsSelectDisable(false)
      }

      return () => (didRequestHolding.current = true)
    }
    const requestProvinceOptions = async () => {
      try {
        if (!didRequestProvince.current) {
          const options = await fetchProvinceOptions('input')
          // throw message error
          if (options.data.meta.code === 200) {
            // set option
            setProvinceOptions(options.data.data)
          }
        }
      } catch (error: any) {
        console.error(error)
        if (error.response.status === 401) {
          toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
          removeAuth()
        } else {
          toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
        }
      } finally {
        setIsSelectDisable(false)
      }

      return () => (didRequestProvince.current = true)
    }
    const requestAreaOptions = async () => {
      try {
        if (!didRequestArea.current) {
          const options = await fetchAreaOptions('input')
          // throw message error
          if (options.data.meta.code === 200) {
            // set option
            setAreaOptions(options.data.data)
          }
        }
      } catch (error: any) {
        console.error(error)
        if (error.response.status === 401) {
          toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
          removeAuth()
        } else {
          toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
        }
      } finally {
        setIsSelectDisable(false)
      }

      return () => (didRequestArea.current = true)
    }

    requestHoldingOptions()
    requestProvinceOptions()
    requestAreaOptions()

    // eslint-disable-next-line
  }, [])

  const requestProvinceOptions = async () => {
    try {
      if (!didRequestProvince.current) {
        const options = await fetchProvinceOptions('input')
        // throw message error
        if (options.data.meta.code === 200) {
          // set options
          setProvinceOptions(options.data.data)
        }
      }
    } catch (error: any) {
      console.error(error)
      if (error.response.status === 401) {
        toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
        removeAuth()
      } else {
        toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
      }
    } finally {
      setIsSelectDisable(false)
    }

    return () => (didRequestProvince.current = true)
  }
  const requestCityOptions = async () => {
    try {
      if (!didRequestCity.current) {
        const options = await fetchCityOptions('input', formik.values.province_id)
        // throw message error
        if (options.data.meta.code === 200) {
          // set options
          setCityOptions(options.data.data)
        }
      }
    } catch (error: any) {
      console.error(error)
      if (error.response.status === 401) {
        toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
        removeAuth()
      } else {
        toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
      }
    } finally {
      setIsSelectDisable(false)
    }

    return () => (didRequestCity.current = true)
  }
  const requestDistrictOptions = async () => {
    try {
      if (!didRequestDistrict.current) {
        const options = await fetchDistrictOptions('input', formik.values.city_id)
        // throw message error
        if (options.data.meta.code === 200) {
          // set options
          setDistrictOptions(options.data.data)
        }
      }
    } catch (error: any) {
      console.error(error)
      if (error.response.status === 401) {
        toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
        removeAuth()
      } else {
        toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
      }
    } finally {
      setIsSelectDisable(false)
    }

    return () => (didRequestDistrict.current = true)
  }
  const requestVillageOptions = async () => {
    try {
      if (!didRequestVillage.current) {
        const options = await fetchVillageOptions('input', formik.values.district_id)
        // throw message error
        if (options.data.meta.code === 200) {
          // set options
          setVillageOptions(options.data.data)
        }
      }
    } catch (error: any) {
      console.error(error)
      if (error.response.status === 401) {
        toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
        removeAuth()
      } else {
        toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
      }
    } finally {
      setIsSelectDisable(false)
    }

    return () => (didRequestVillage.current = true)
  }
  const requestAreaOptions = async () => {
    try {
      if (!didRequestArea.current) {
        const options = await fetchAreaOptions('input')
        // throw message error
        if (options.data.meta.code === 200) {
          // set options
          setAreaOptions(options.data.data)
        }
      }
    } catch (error: any) {
      console.error(error)
      if (error.response.status === 401) {
        toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
        removeAuth()
      } else {
        toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
      }
    } finally {
      setIsSelectDisable(false)
    }

    return () => (didRequestArea.current = true)
  }
  const requestClusterOptions = async () => {
    try {
      if (!didRequestCluster.current) {
        const options = await fetchClusterOptions('input', formik.values.area_id)
        // throw message error
        if (options.data.meta.code === 200) {
          // set options
          setClusterOptions(options.data.data)
        }
      }
    } catch (error: any) {
      console.error(error)
      if (error.response.status === 401) {
        toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
        removeAuth()
      } else {
        toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
      }
    } finally {
      setIsSelectDisable(false)
    }

    return () => (didRequestCluster.current = true)
  }

  useEffect(() => {
    const requestCompanyOptions = async () => {
      try {
        if (!didRequestCompany.current) {
          const options = await fetchCompanyOptions('input', formik.values.holding_id)
          // throw message error
          if (options.data.meta.code === 200) {
            // set options
            setCompanyOptions(options.data.data)
          }
        }
      } catch (error: any) {
        console.error(error)
        if (error.response.status === 401) {
          toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
          removeAuth()
        } else {
          toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
        }
      } finally {
        setIsSelectDisable(false)
      }

      return () => (didRequestCompany.current = true)
    }

    formik.values.holding_id !== undefined && formik.values.holding_id !== ''
      ? requestCompanyOptions()
      : setCompanyOptions(undefined)

    // eslint-disable-next-line
  }, [formik.values.holding_id])

  useEffect(() => {
    const requestBrandOptions = async () => {
      try {
        if (!didRequestBrand.current) {
          const options = await fetchBrandOptions('input', formik.values.company_id)
          // throw message error
          if (options.data.meta.code === 200) {
            // set options
            setBrandOptions(options.data.data)
          }
        }
      } catch (error: any) {
        console.error(error)
        if (error.response.status === 401) {
          toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
          removeAuth()
        } else {
          toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
        }
      } finally {
        setIsSelectDisable(false)
      }

      return () => (didRequestBrand.current = true)
    }

    formik.values.company_id !== undefined && formik.values.company_id !== ''
      ? requestBrandOptions()
      : setBrandOptions(undefined)

    // eslint-disable-next-line
  }, [formik.values.company_id])

  useEffect(() => {
    formik.values.province_id !== undefined && formik.values.province_id !== ''
      ? requestCityOptions()
      : setCityOptions(undefined)

    // eslint-disable-next-line
  }, [formik.values.province_id])

  useEffect(() => {
    formik.values.city_id !== undefined && formik.values.city_id !== ''
      ? requestDistrictOptions()
      : setDistrictOptions(undefined)

    // eslint-disable-next-line
  }, [formik.values.city_id])

  useEffect(() => {
    formik.values.district_id !== undefined && formik.values.district_id !== ''
      ? requestVillageOptions()
      : setVillageOptions(undefined)

    // eslint-disable-next-line
  }, [formik.values.district_id])

  useEffect(() => {
    formik.values.area_id !== undefined && formik.values.area_id !== ''
      ? requestClusterOptions()
      : setClusterOptions(undefined)

    // eslint-disable-next-line
  }, [formik.values.area_id])

  return (
    <>
      {!isAuth && <NotAuthRedirectForm />}

      <Drawer.Header>
        <Drawer.Title className='fw-bolder'>
          {isLoading ? (
            <SpinnerIndicator label={intl.formatMessage({id: 'BUTTON_LABEL_PLEASE_WAIT'})} />
          ) : (
            <>
              {getFormAction(
                action,
                intl.formatMessage({id: 'BUTTON_LABEL_VIEW'}),
                intl.formatMessage({id: 'BUTTON_LABEL_EDIT'}),
                intl.formatMessage({id: 'BUTTON_LABEL_ADD'}),
                intl.formatMessage({id: 'BUTTON_LABEL_DELETE'})
              )}{' '}
              {menuName}
            </>
          )}
        </Drawer.Title>
        <Drawer.Actions>
          {action !== 'view' && models !== formik.values && !completed && (
            <button
              type='reset'
              className='btn btn-light me-3'
              data-kt-users-modal-action='reset'
              onClick={formik.handleReset}
              disabled={loading}
            >
              {!loading && (
                <span className='indicator-label'>
                  {intl.formatMessage({id: 'BUTTON_LABEL_RESET'})}
                </span>
              )}
            </button>
          )}

          {action !== 'view' && models !== formik.values && !completed && (
            <button
              type='submit'
              className='btn btn-primary'
              data-kt-users-modal-action='submit'
              onClick={() => {
                if (formik.values.holding_id?.toString().replaceAll(',', ', ') === '') {
                  formik.setFieldError('holding_id', 'validation errors')
                  return false
                }
                if (formik.values.company_id?.toString().replaceAll(',', ', ') === '') {
                  formik.setFieldError('company_id', 'validation errors')
                  return false
                }
                if (formik.values.brand_id?.toString().replaceAll(',', ', ') === '') {
                  formik.setFieldError('brand_id', 'validation errors')
                  return false
                }
                if (formik.values.province_id?.toString().replaceAll(',', ', ') === '') {
                  formik.setFieldError('province_id', 'validation errors')
                  return false
                }
                if (formik.values.city_id?.toString().replaceAll(',', ', ') === '') {
                  formik.setFieldError('city_id', 'validation errors')
                  return false
                }
                if (formik.values.district_id?.toString().replaceAll(',', ', ') === '') {
                  formik.setFieldError('district_id', 'validation errors')
                  return false
                }
                if (formik.values.village_id?.toString().replaceAll(',', ', ') === '') {
                  formik.setFieldError('village_id', 'validation errors')
                  return false
                }
                if (formik.values.area_id?.toString().replaceAll(',', ', ') === '') {
                  formik.setFieldError('area_id', 'validation errors')
                  return false
                }
                setIsDialogOpen(true)
                setLoading(true)
                setTimeout(function () {
                  setLoading(false)
                }, 2e3)
              }}
              disabled={isLoading || loading || formik.isSubmitting || !formik.isValid}
            >
              {!loading && (
                <span className='indicator-label'>
                  {intl.formatMessage({id: 'BUTTON_LABEL_SUBMIT'})}
                </span>
              )}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {intl.formatMessage({id: 'BUTTON_LABEL_SUBMITTING'})}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          )}

          {action === 'delete' && !completed && (
            <button
              type='submit'
              className='btn btn-danger'
              data-kt-users-modal-action='submit'
              onClick={() => formik.handleSubmit()}
              disabled={isLoading || loading || formik.isSubmitting || !formik.isValid}
            >
              {!loading && (
                <span className='indicator-label'>
                  {intl.formatMessage({id: 'BUTTON_LABEL_DELETE'})}
                </span>
              )}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {intl.formatMessage({id: 'BUTTON_LABEL_SUBMITTING'})}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          )}
        </Drawer.Actions>
      </Drawer.Header>

      <Drawer.Body style={{padding: 0}}>
        {/* Response Message */}
        {formik.status &&
          (formik.status === intl.formatMessage({id: 'RESPONSE_MASTER_OUTLET_ADD_SUCCESS'}) ||
          formik.status === intl.formatMessage({id: 'RESPONSE_MASTER_OUTLET_EDIT_SUCCESS'}) ||
          formik.status === intl.formatMessage({id: 'RESPONSE_MASTER_OUTLET_DELETE_SUCCESS'}) ? (
            <div className='m-lg-5 p-2 bg-light-success rounded'>
              <div className='text-success text-center font-weight-bold fw-bolder'>
                {formik.status}
              </div>
            </div>
          ) : (
            <div className={`m-lg-5 p-2 alert alert-danger`}>
              <div className='alert-text text-center font-weight-bold fw-bolder'>
                {formik.status}
              </div>
            </div>
          ))}
        {/* Response Message */}

        {/* Form */}
        <div className={`modal-body m-5 ${action === 'add' ? '' : ' mt-0'}`}>
          {action === 'add' ? (
            <form id={`${modalID}-form`} className='form' onSubmit={formik.handleSubmit} noValidate>
              <div className='row'>
                {/* First Col */}
                <div className='col-lg-4'>
                  {/* Holding */}
                  <div className='fv-row mb-5'>
                    {formik.values.holding_id !== '' && (
                      <>
                        <label className='form-label fs-6 fw-bolder text-dark required'>
                          Holding
                        </label>
                        <br />
                      </>
                    )}
                    <InputPicker
                      data={optionsHolding}
                      style={{width: 450}}
                      placeholder={`-- Holding --`}
                      defaultValue={
                        optionsHolding
                          ? (optionsHolding as unknown as any[]).find(
                              (e: any) => e.value === formik.values.holding_id
                            )
                          : ''
                      }
                      onChange={(e) => {
                        formik.setFieldValue('holding_id', e === null ? '' : e)
                        formik.setFieldValue('company_id', '')
                        formik.setFieldValue('brand_id', '')
                      }}
                      onSelect={(val, itm) => {
                        formik.setFieldValue('holding_name', val === null ? '' : itm.label)
                        formik.setFieldValue('company_name', '')
                        formik.setFieldValue('brand_name', '')
                      }}
                      value={formik.values.holding_id}
                    />
                    {formik.errors.holding_id ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger fw-bolder'>
                          {intl.formatMessage(
                            {id: 'VALIDATION_REQUIRED_FIELD'},
                            {
                              name: 'Holding',
                            }
                          )}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {/* Holding */}

                  {/* Company */}
                  <div className='fv-row mb-5'>
                    {formik.values.company_id !== '' && (
                      <>
                        <label className='form-label fs-6 fw-bolder text-dark required'>
                          {intl.formatMessage({id: 'LABEL_COMPANY'})}
                        </label>
                        <br />
                      </>
                    )}
                    <InputPicker
                      data={optionsCompany}
                      style={{width: 450}}
                      disabled={formik.values.holding_id === '' ? true : false}
                      placeholder={`-- ${intl.formatMessage({id: 'LABEL_COMPANY'})} --`}
                      defaultValue={
                        optionsCompany
                          ? (optionsCompany as unknown as any[]).find(
                              (e: any) => e.value === formik.values.company_id
                            )
                          : ''
                      }
                      onChange={(e) => {
                        formik.setFieldValue('company_id', e === null ? '' : e)
                        formik.setFieldValue('brand_id', '')
                      }}
                      onSelect={(val, itm) => {
                        formik.setFieldValue('company_name', val === null ? '' : itm.label)
                        formik.setFieldValue('brand_name', '')
                      }}
                      value={formik.values.company_id}
                    />
                    {formik.errors.company_id ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger fw-bolder'>
                          {intl.formatMessage(
                            {id: 'VALIDATION_REQUIRED_FIELD'},
                            {
                              name: intl.formatMessage({id: 'LABEL_COMPANY'}),
                            }
                          )}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {/* Company */}

                  {/* Brand */}
                  <div className='fv-row mb-5'>
                    {formik.values.brand_id !== '' && (
                      <>
                        <label className='form-label fs-6 fw-bolder text-dark required'>
                          {intl.formatMessage({id: 'LABEL_BRAND'})}
                        </label>
                        <br />
                      </>
                    )}
                    <InputPicker
                      data={optionsBrand}
                      style={{width: 450}}
                      disabled={formik.values.company_id === '' ? true : false}
                      placeholder={`-- ${intl.formatMessage({id: 'LABEL_BRAND'})} --`}
                      defaultValue={
                        optionsBrand
                          ? (optionsBrand as unknown as any[]).find(
                              (e: any) => e.value === formik.values.brand_id
                            )
                          : ''
                      }
                      onChange={(e) => {
                        formik.setFieldValue('brand_id', e === null ? '' : e)
                      }}
                      onSelect={(val, itm) => {
                        formik.setFieldValue('brand_name', val === null ? '' : itm.label)
                      }}
                      value={formik.values.brand_id}
                    />
                    {formik.errors.brand_id ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger fw-bolder'>
                          {intl.formatMessage(
                            {id: 'VALIDATION_REQUIRED_FIELD'},
                            {
                              name: intl.formatMessage({id: 'LABEL_BRAND'}),
                            }
                          )}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {/* Brand */}
                </div>
                {/* First Col */}

                {/* Second Col */}
                <div className='col-lg-4'>
                  {/* Province */}
                  <div className='fv-row mb-5'>
                    {formik.values.province_id !== '' && (
                      <>
                        <label className='form-label fs-6 fw-bolder text-dark required'>
                          {intl.formatMessage({id: 'LABEL_PROVINCE'})}
                        </label>
                        <br />
                      </>
                    )}
                    <InputPicker
                      creatable={true}
                      data={optionsProvince}
                      style={{width: 450}}
                      disabled={isSelectDisable}
                      placeholder={`-- ${intl.formatMessage({id: 'LABEL_PROVINCE'})} --`}
                      defaultValue={
                        optionsProvince
                          ? (optionsProvince as unknown as any[]).find(
                              (e: any) => e.value === formik.values.province_id
                            )
                          : ''
                      }
                      onChange={(e) => {
                        formik.setFieldValue('province_id', e === null ? '' : e)
                        formik.setFieldValue('city_id', '')
                        formik.setFieldValue('district_id', '')
                        formik.setFieldValue('village_id', '')
                      }}
                      onSelect={(val, itm) => {
                        formik.setFieldValue('province_name', val === null ? '' : itm.label)
                        formik.setFieldValue('city_name', '')
                        formik.setFieldValue('district_name', '')
                        formik.setFieldValue('village_name', '')
                      }}
                      onCreate={(val, evt) =>
                        confirmBox(
                          `${intl.formatMessage({id: 'BUTTON_LABEL_ADD'})} ${intl.formatMessage({
                            id: 'LABEL_PROVINCE',
                          })}`,
                          `${intl.formatMessage({
                            id: 'LABEL_PROVINCE',
                          })} "${val}" ${intl.formatMessage({id: 'LABEL_ADDING'})}`,
                          `${intl.formatMessage({id: 'BUTTON_LABEL_ADD'})}`,
                          `${intl.formatMessage({id: 'BUTTON_LABEL_CANCEL'})}`,
                          'province',
                          val,
                          '',
                          intl.formatMessage({id: 'LABEL_PROVINCE'})
                        )
                      }
                      value={formik.values.province_id}
                    />
                    {formik.errors.province_id ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-anger fw-bolder'>
                          {intl.formatMessage(
                            {id: 'VALIDATION_REQUIRED_FIELD'},
                            {
                              name: intl.formatMessage({id: 'LABEL_PROVINCE'}),
                            }
                          )}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {/* Province */}

                  {/* City */}
                  <div className='fv-row mb-5'>
                    {formik.values.city_id !== '' && (
                      <>
                        <label className='form-label fs-6 fw-bolder text-dark required'>
                          {intl.formatMessage({id: 'LABEL_CITY'})}
                        </label>
                        <br />
                      </>
                    )}
                    <InputPicker
                      creatable={true}
                      data={optionsCity}
                      style={{width: 450}}
                      disabled={formik.values.province_id !== '' || isSelectDisable ? false : true}
                      placeholder={`-- ${intl.formatMessage({id: 'LABEL_CITY'})} --`}
                      defaultValue={
                        optionsCity
                          ? (optionsCity as unknown as any[]).find(
                              (e: any) => e.value === formik.values.city_id
                            )
                          : ''
                      }
                      onChange={(e) => {
                        formik.setFieldValue('city_id', e === null ? '' : e)
                        formik.setFieldValue('district_id', '')
                        formik.setFieldValue('village_id', '')
                      }}
                      onSelect={(val, itm) => {
                        formik.setFieldValue('city_name', val === null ? '' : itm.label)
                        formik.setFieldValue('district_name', '')
                        formik.setFieldValue('village_name', '')
                      }}
                      onCreate={(val, evt) =>
                        confirmBox(
                          `${intl.formatMessage({id: 'BUTTON_LABEL_ADD'})} ${intl.formatMessage({
                            id: 'LABEL_CITY',
                          })}`,
                          `${intl.formatMessage({
                            id: 'LABEL_CITY',
                          })} "${val}" ${intl.formatMessage({id: 'LABEL_ADDING'})}`,
                          `${intl.formatMessage({id: 'BUTTON_LABEL_ADD'})}`,
                          `${intl.formatMessage({id: 'BUTTON_LABEL_CANCEL'})}`,
                          'city',
                          val,
                          formik.values.province_id,
                          intl.formatMessage({id: 'LABEL_CITY'})
                        )
                      }
                      value={formik.values.city_id}
                    />
                    {formik.errors.city_id ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger fw-bolder'>
                          {intl.formatMessage(
                            {id: 'VALIDATION_REQUIRED_FIELD'},
                            {
                              name: intl.formatMessage({id: 'LABEL_CITY'}),
                            }
                          )}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {/* City */}

                  {/* District */}
                  <div className='fv-row mb-5'>
                    {formik.values.district_id !== '' && (
                      <>
                        <label className='form-label fs-6 fw-bolder text-dark required'>
                          {intl.formatMessage({id: 'LABEL_DISTRICTS'})}
                        </label>
                        <br />
                      </>
                    )}
                    <InputPicker
                      creatable={true}
                      data={optionsDistrict}
                      style={{width: 450}}
                      disabled={formik.values.city_id !== '' || isSelectDisable ? false : true}
                      placeholder={`-- ${intl.formatMessage({id: 'LABEL_DISTRICTS'})} --`}
                      defaultValue={
                        optionsDistrict
                          ? (optionsDistrict as unknown as any[]).find(
                              (e: any) => e.value === formik.values.district_id
                            )
                          : ''
                      }
                      onChange={(e) => {
                        formik.setFieldValue('district_id', e === null ? '' : e)
                        formik.setFieldValue('village_id', '')
                      }}
                      onSelect={(val, itm) => {
                        formik.setFieldValue('district_name', val === null ? '' : itm.label)
                        formik.setFieldValue('village_name', '')
                      }}
                      onCreate={(val, evt) =>
                        confirmBox(
                          `${intl.formatMessage({id: 'BUTTON_LABEL_ADD'})} ${intl.formatMessage({
                            id: 'LABEL_DISTRICTS',
                          })}`,
                          `${intl.formatMessage({
                            id: 'LABEL_DISTRICTS',
                          })} "${val}" ${intl.formatMessage({id: 'LABEL_ADDING'})}`,
                          `${intl.formatMessage({id: 'BUTTON_LABEL_ADD'})}`,
                          `${intl.formatMessage({id: 'BUTTON_LABEL_CANCEL'})}`,
                          'district',
                          val,
                          formik.values.city_id,
                          intl.formatMessage({id: 'LABEL_DISTRICTS'})
                        )
                      }
                      value={formik.values.district_id}
                    />
                    {formik.errors.district_id ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger fw-bolder'>
                          {intl.formatMessage(
                            {id: 'VALIDATION_REQUIRED_FIELD'},
                            {
                              name: intl.formatMessage({id: 'LABEL_DISTRICTS'}),
                            }
                          )}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {/* District */}

                  {/* Village */}
                  <div className='fv-row mb-5'>
                    {formik.values.village_id !== '' && (
                      <>
                        <label className='form-label fs-6 fw-bolder text-dark required'>
                          {intl.formatMessage({id: 'LABEL_VILLAGES'})}
                        </label>
                        <br />
                      </>
                    )}
                    <InputPicker
                      creatable={true}
                      data={optionsVillage}
                      style={{width: 450}}
                      disabled={formik.values.district_id !== '' || isSelectDisable ? false : true}
                      placeholder={`-- ${intl.formatMessage({id: 'LABEL_VILLAGES'})} --`}
                      defaultValue={
                        optionsVillage
                          ? (optionsVillage as unknown as any[]).find(
                              (e: any) => e.value === formik.values.village_id
                            )
                          : ''
                      }
                      onChange={(e) => {
                        formik.setFieldValue('village_id', e === null ? '' : e)
                      }}
                      onSelect={(val, itm) => {
                        formik.setFieldValue('village_name', val === null ? '' : itm.label)
                      }}
                      onCreate={(val, evt) =>
                        confirmBox(
                          `${intl.formatMessage({id: 'BUTTON_LABEL_ADD'})} ${intl.formatMessage({
                            id: 'LABEL_VILLAGES',
                          })}`,
                          `${intl.formatMessage({
                            id: 'LABEL_VILLAGES',
                          })} "${val}" ${intl.formatMessage({id: 'LABEL_ADDING'})}`,
                          `${intl.formatMessage({id: 'BUTTON_LABEL_ADD'})}`,
                          `${intl.formatMessage({id: 'BUTTON_LABEL_CANCEL'})}`,
                          'village',
                          val,
                          formik.values.district_id,
                          intl.formatMessage({id: 'LABEL_VILLAGES'})
                        )
                      }
                      value={formik.values.village_id}
                    />
                    {formik.errors.village_id ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger fw-bolder'>
                          {intl.formatMessage(
                            {id: 'VALIDATION_REQUIRED_FIELD'},
                            {
                              name: intl.formatMessage({id: 'LABEL_VILLAGES'}),
                            }
                          )}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {/* Village */}
                </div>
                {/* Second Col */}

                {/* Third Col */}
                <div className='col-lg-4'>
                  {/* Area */}
                  <div className='fv-row mb-5'>
                    {formik.values.area_id !== '' && (
                      <>
                        <label className='form-label fs-6 fw-bolder text-dark required'>
                          {intl.formatMessage({id: 'LABEL_AREA'})}
                        </label>
                        <br />
                      </>
                    )}
                    <InputPicker
                      creatable={true}
                      data={optionsArea}
                      style={{width: 450}}
                      disabled={isSelectDisable}
                      placeholder={`-- ${intl.formatMessage({id: 'LABEL_AREA'})} --`}
                      defaultValue={
                        optionsArea
                          ? (optionsArea as unknown as any[]).find(
                              (e: any) => e.value === formik.values.area_id
                            )
                          : ''
                      }
                      onChange={(e) => {
                        formik.setFieldValue('area_id', e === null ? '' : e)
                        formik.setFieldValue('cluster_id', '')
                      }}
                      onSelect={(val, itm) => {
                        formik.setFieldValue('area_name', val === null ? '' : itm.label)
                        formik.setFieldValue('cluster_name', '')
                      }}
                      onCreate={(val, evt) =>
                        confirmBox(
                          `${intl.formatMessage({id: 'BUTTON_LABEL_ADD'})} ${intl.formatMessage({
                            id: 'LABEL_AREA',
                          })}`,
                          `${intl.formatMessage({
                            id: 'LABEL_AREA',
                          })} "${val}" ${intl.formatMessage({id: 'LABEL_ADDING'})}`,
                          `${intl.formatMessage({id: 'BUTTON_LABEL_ADD'})}`,
                          `${intl.formatMessage({id: 'BUTTON_LABEL_CANCEL'})}`,
                          'area',
                          val,
                          '',
                          intl.formatMessage({id: 'LABEL_AREA'})
                        )
                      }
                      value={formik.values.area_id}
                    />
                    {formik.errors.area_id ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger fw-bolder'>
                          {intl.formatMessage(
                            {id: 'VALIDATION_REQUIRED_FIELD'},
                            {
                              name: intl.formatMessage({id: 'LABEL_AREA'}),
                            }
                          )}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {/* Area */}

                  {/* Cluster */}
                  <div className='fv-row mb-5'>
                    {formik.values.cluster_id !== '' && (
                      <>
                        <label className='form-label fs-6 fw-bolder text-dark required'>
                          {intl.formatMessage({id: 'LABEL_CLUSTER'})}
                        </label>
                        <br />
                      </>
                    )}
                    <InputPicker
                      creatable={true}
                      data={optionsCluster}
                      style={{width: 450}}
                      disabled={formik.values.area_id !== '' || isSelectDisable ? false : true}
                      placeholder={`-- ${intl.formatMessage({id: 'LABEL_CLUSTER'})} --`}
                      defaultValue={
                        optionsCluster
                          ? (optionsCluster as unknown as any[]).find(
                              (e: any) => e.value === formik.values.cluster_id
                            )
                          : ''
                      }
                      onChange={(e) => {
                        formik.setFieldValue('cluster_id', e === null ? '' : e)
                      }}
                      onSelect={(val, itm) => {
                        formik.setFieldValue('cluster_name', val === null ? '' : itm.label)
                      }}
                      onCreate={(val, evt) =>
                        confirmBox(
                          `${intl.formatMessage({id: 'BUTTON_LABEL_ADD'})} ${intl.formatMessage({
                            id: 'LABEL_CLUSTER',
                          })}`,
                          `${intl.formatMessage({
                            id: 'LABEL_CLUSTER',
                          })} "${val}" ${intl.formatMessage({id: 'LABEL_ADDING'})}`,
                          `${intl.formatMessage({id: 'BUTTON_LABEL_ADD'})}`,
                          `${intl.formatMessage({id: 'BUTTON_LABEL_CANCEL'})}`,
                          'cluster',
                          val,
                          formik.values.area_id,
                          intl.formatMessage({id: 'LABEL_CLUSTER'})
                        )
                      }
                      value={formik.values.cluster_id}
                    />
                  </div>
                  {/* Cluster */}
                </div>
                {/* Third Col */}
              </div>

              <div className='separator my-10'></div>

              <div className='row mt-10'>
                {/* Fourth Col */}
                <div className='col-lg-4'>
                  {/* Status */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark required'>Status</label>
                    <div className='form-check form-switch form-check-custom form-check-solid mt-1'>
                      <Toggle
                        size='lg'
                        checkedChildren={intl.formatMessage({id: 'LABEL_ACTIVE'})}
                        unCheckedChildren={intl.formatMessage({id: 'LABEL_INACTIVE'})}
                        checked={formik.values.status === 't' ? true : false}
                        onChange={() =>
                          formik.setFieldValue('status', formik.values.status === 't' ? 'f' : 't')
                        }
                      />
                    </div>
                  </div>
                  {/* Status */}
                </div>
                {/* Fourth Col */}

                {/* Fifth Col */}
                <div className='col-lg-8'>
                  {/* Name */}
                  <div className='fv-row mb-5'>
                    {formik.values.name !== '' && (
                      <label className='form-label fs-6 fw-bolder text-dark required'>
                        {intl.formatMessage({id: 'LABEL_NAME'})}
                      </label>
                    )}
                    <input
                      type='text'
                      className={clsx(
                        'form-control form-control-solid',
                        {'is-invalid': formik.touched.name && formik.errors.name},
                        {
                          'is-valid': formik.touched.name && !formik.errors.name,
                        }
                      )}
                      placeholder={intl.formatMessage({id: 'LABEL_NAME'})}
                      {...formik.getFieldProps('name')}
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger fw-bolder'>
                          {formik.errors.name}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {/* Name */}

                  {/* Mobile Phone */}
                  <div className='fv-row mb-5'>
                    {formik.values.phone_number !== '' && (
                      <label className='form-label fs-6 fw-bolder text-dark required'>
                        {intl.formatMessage({id: 'LABEL_MOBILE_PHONE'})}
                      </label>
                    )}
                    <input
                      type='text'
                      className={clsx(
                        'form-control form-control-solid',
                        {'is-invalid': formik.touched.phone_number && formik.errors.phone_number},
                        {
                          'is-valid': formik.touched.phone_number && !formik.errors.phone_number,
                        }
                      )}
                      {...formik.getFieldProps('phone_number')}
                      placeholder={intl.formatMessage({id: 'LABEL_MOBILE_PHONE'})}
                      onKeyUp={(e) =>
                        formik.setFieldValue(
                          'phone_number',
                          e.currentTarget.value === null
                            ? ''
                            : inputPhoneNumbers(e.currentTarget.value)
                        )
                      }
                    />
                    {formik.touched.phone_number && formik.errors.phone_number ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger fw-bolder'>
                          {formik.errors.phone_number}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {/* Mobile Phone */}

                  {/* Postal Code */}
                  <div className='fv-row mb-5'>
                    {formik.values.postal_code !== '' && (
                      <label className='form-label fs-6 fw-bolder text-dark required'>
                        {intl.formatMessage({id: 'LABEL_POSTAL_CODE'})}
                      </label>
                    )}
                    <input
                      type='text'
                      className={clsx(
                        'form-control form-control-solid',
                        {'is-invalid': formik.touched.postal_code && formik.errors.postal_code},
                        {
                          'is-valid': formik.touched.postal_code && !formik.errors.postal_code,
                        }
                      )}
                      placeholder={intl.formatMessage({id: 'LABEL_POSTAL_CODE'})}
                      {...formik.getFieldProps('postal_code')}
                      onKeyUp={(e) =>
                        formik.setFieldValue(
                          'postal_code',
                          e.currentTarget.value === null
                            ? ''
                            : inputPostalCode(e.currentTarget.value)
                        )
                      }
                    />
                    {formik.touched.postal_code && formik.errors.postal_code ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger fw-bolder'>
                          {formik.errors.postal_code}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {/* Postal Code */}

                  {/* Address */}
                  <div className='fv-row mb-5'>
                    {formik.values.address !== '' && (
                      <label className='form-label fs-6 fw-bolder text-dark required'>
                        {intl.formatMessage({id: 'LABEL_ADDRESS'})}
                      </label>
                    )}
                    <textarea
                      className={clsx(
                        'form-control form-control-solid',
                        {'is-invalid': formik.touched.address && formik.errors.address},
                        {
                          'is-valid': formik.touched.address && !formik.errors.address,
                        }
                      )}
                      placeholder={intl.formatMessage({id: 'LABEL_ADDRESS'})}
                      {...formik.getFieldProps('address')}
                      rows={3}
                    />
                    {formik.touched.address && formik.errors.address ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger fw-bolder'>
                          {formik.errors.address}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {/* Address */}
                </div>
                {/* Fifth Col */}
              </div>
            </form>
          ) : (
            <form id={`${modalID}-form`} className='form' onSubmit={formik.handleSubmit} noValidate>
              {/* Tab Header */}
              <div className='card-toolbar'>
                <ul
                  className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-2 mt-2'
                  role='tablist'
                >
                  <li className={`nav-item ${tab === 'data' ? 'fw-bolder' : ''}`}>
                    <a
                      className={clsx(`nav-link cursor-pointer`, {
                        active: tab === 'data',
                      })}
                      onClick={() => setTab('data')}
                      role='tab'
                    >
                      Data
                    </a>
                  </li>
                  <li className={`nav-item ${tab === 'log' ? 'fw-bolder' : ''}`}>
                    <a
                      className={clsx(`nav-link cursor-pointer`, {
                        active: tab === 'log',
                      })}
                      onClick={() => setTab('log')}
                      role='tab'
                    >
                      Log
                    </a>
                  </li>
                </ul>
              </div>
              {/* Tab Header */}

              {/* Tab Body */}
              <div className='card-body p-0 pt-2'>
                <div className='tab-content'>
                  {/* Data */}
                  <div
                    className={clsx('tab-pane', {
                      active: tab === 'data',
                    })}
                  >
                    <div className='row'>
                      {/* First Form */}
                      <div className='col-lg-4'>
                        {/* Holding */}
                        <div className='fv-row mb-5'>
                          {formik.values.holding_id !== '' && (
                            <>
                              <label
                                className={`form-label fs-6 fw-bolder text-dark ${
                                  action === 'edit' ? ' required' : ''
                                }`}
                              >
                                Holding
                              </label>
                              <br />
                            </>
                          )}
                          <InputPicker
                            readOnly={action !== 'edit' ? true : false}
                            data={optionsHolding}
                            style={{width: 450}}
                            placeholder={`-- Holding --`}
                            defaultValue={
                              optionsHolding
                                ? (optionsHolding as unknown as any[]).find(
                                    (e: any) => e.value === formik.values.holding_id
                                  )
                                : ''
                            }
                            onChange={(e) => {
                              formik.setFieldValue('holding_id', e === null ? '' : e)
                              formik.setFieldValue('company_id', '')
                              formik.setFieldValue('brand_id', '')
                            }}
                            onSelect={(val, itm) => {
                              formik.setFieldValue('holding_name', val === null ? '' : itm.label)
                              formik.setFieldValue('company_name', '')
                              formik.setFieldValue('brand_name', '')
                            }}
                            value={formik.values.holding_id}
                          />
                          {formik.errors.holding_id ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {intl.formatMessage(
                                  {id: 'VALIDATION_REQUIRED_FIELD'},
                                  {
                                    name: 'Holding',
                                  }
                                )}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Holding */}

                        {/* Company */}
                        <div className='fv-row mb-5'>
                          {formik.values.company_id !== '' && (
                            <>
                              <label
                                className={`form-label fs-6 fw-bolder text-dark ${
                                  action === 'edit' ? 'required' : ''
                                }`}
                              >
                                {intl.formatMessage({id: 'LABEL_COMPANY'})}
                              </label>
                              <br />
                            </>
                          )}
                          <InputPicker
                            data={optionsCompany}
                            readOnly={action !== 'edit' ? true : false}
                            style={{width: 450}}
                            disabled={formik.values.holding_id === '' ? true : false}
                            placeholder={`-- ${intl.formatMessage({id: 'LABEL_COMPANY'})} --`}
                            defaultValue={
                              optionsCompany
                                ? (optionsCompany as unknown as any[]).find(
                                    (e: any) => e.value === formik.values.company_id
                                  )
                                : ''
                            }
                            onChange={(e) => {
                              formik.setFieldValue('company_id', e === null ? '' : e)
                              formik.setFieldValue('brand_id', '')
                            }}
                            onSelect={(val, itm) => {
                              formik.setFieldValue('company_name', val === null ? '' : itm.label)
                              formik.setFieldValue('brand_name', '')
                            }}
                            value={formik.values.company_id}
                          />
                          {formik.errors.company_id ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {intl.formatMessage(
                                  {id: 'VALIDATION_REQUIRED_FIELD'},
                                  {
                                    name: intl.formatMessage({id: 'LABEL_COMPANY'}),
                                  }
                                )}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Company */}

                        {/* Brand */}
                        <div className='fv-row mb-5'>
                          {formik.values.brand_id !== '' && (
                            <>
                              <label
                                className={`form-label fs-6 fw-bolder text-dark ${
                                  action === 'edit' ? 'required' : ''
                                }`}
                              >
                                {intl.formatMessage({id: 'LABEL_BRAND'})}
                              </label>
                              <br />
                            </>
                          )}
                          <InputPicker
                            data={optionsBrand}
                            readOnly={action !== 'edit' ? true : false}
                            style={{width: 450}}
                            disabled={formik.values.company_id === '' ? true : false}
                            placeholder={`-- ${intl.formatMessage({id: 'LABEL_BRAND'})} --`}
                            defaultValue={
                              optionsBrand
                                ? (optionsBrand as unknown as any[]).find(
                                    (e: any) => e.value === formik.values.brand_id
                                  )
                                : ''
                            }
                            onChange={(e) => {
                              formik.setFieldValue('brand_id', e === null ? '' : e)
                            }}
                            onSelect={(val, itm) => {
                              formik.setFieldValue('brand_name', val === null ? '' : itm.label)
                            }}
                            value={formik.values.brand_id}
                          />
                          {formik.errors.brand_id ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {intl.formatMessage(
                                  {id: 'VALIDATION_REQUIRED_FIELD'},
                                  {
                                    name: intl.formatMessage({id: 'LABEL_BRAND'}),
                                  }
                                )}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Brand */}
                      </div>
                      {/* First Form */}

                      {/* Second Form */}
                      <div className='col-lg-4'>
                        {/* Province */}
                        <div className='fv-row mb-5'>
                          {formik.values.province_id !== '' && (
                            <>
                              <label
                                className={`form-label fs-6 fw-bolder text-dark ${
                                  action === 'edit' ? 'required' : ''
                                }`}
                              >
                                {intl.formatMessage({id: 'LABEL_PROVINCE'})}
                              </label>
                              <br />
                            </>
                          )}
                          <InputPicker
                            creatable={true}
                            readOnly={action !== 'edit' ? true : false}
                            data={optionsProvince}
                            style={{width: 450}}
                            disabled={isSelectDisable}
                            placeholder={`-- ${intl.formatMessage({id: 'LABEL_PROVINCE'})} --`}
                            defaultValue={
                              optionsProvince
                                ? (optionsProvince as unknown as any[]).find(
                                    (e: any) => e.value === formik.values.province_id
                                  )
                                : ''
                            }
                            onChange={(e) => {
                              formik.setFieldValue('province_id', e === null ? '' : e)
                              formik.setFieldValue('city_id', '')
                              formik.setFieldValue('district_id', '')
                              formik.setFieldValue('village_id', '')
                            }}
                            onSelect={(val, itm) => {
                              formik.setFieldValue('province_name', val === null ? '' : itm.label)
                              formik.setFieldValue('city_name', '')
                              formik.setFieldValue('district_name', '')
                              formik.setFieldValue('village_name', '')
                            }}
                            onCreate={(val, evt) =>
                              confirmBox(
                                `${intl.formatMessage({
                                  id: 'BUTTON_LABEL_ADD',
                                })} ${intl.formatMessage({
                                  id: 'LABEL_PROVINCE',
                                })}`,
                                `${intl.formatMessage({
                                  id: 'LABEL_PROVINCE',
                                })} "${val}" ${intl.formatMessage({id: 'LABEL_ADDING'})}`,
                                `${intl.formatMessage({id: 'BUTTON_LABEL_ADD'})}`,
                                `${intl.formatMessage({id: 'BUTTON_LABEL_CANCEL'})}`,
                                'province',
                                val,
                                '',
                                intl.formatMessage({id: 'LABEL_PROVINCE'})
                              )
                            }
                            value={formik.values.province_id}
                          />
                          {formik.errors.province_id ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {intl.formatMessage(
                                  {id: 'VALIDATION_REQUIRED_FIELD'},
                                  {
                                    name: intl.formatMessage({id: 'LABEL_PROVINCE'}),
                                  }
                                )}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Province */}

                        {/* City */}
                        <div className='fv-row mb-5'>
                          {formik.values.city_id !== '' && (
                            <>
                              <label
                                className={`form-label fs-6 fw-bolder text-dark ${
                                  action === 'edit' ? 'required' : ''
                                }`}
                              >
                                {intl.formatMessage({id: 'LABEL_CITY'})}
                              </label>
                              <br />
                            </>
                          )}
                          <InputPicker
                            readOnly={action !== 'edit' ? true : false}
                            creatable={true}
                            data={optionsCity}
                            style={{width: 450}}
                            disabled={
                              formik.values.province_id !== '' || isSelectDisable ? false : true
                            }
                            placeholder={`-- ${intl.formatMessage({id: 'LABEL_CITY'})} --`}
                            defaultValue={
                              optionsCity
                                ? (optionsCity as unknown as any[]).find(
                                    (e: any) => e.value === formik.values.city_id
                                  )
                                : ''
                            }
                            onChange={(e) => {
                              formik.setFieldValue('city_id', e === null ? '' : e)
                              formik.setFieldValue('district_id', '')
                              formik.setFieldValue('village_id', '')
                            }}
                            onSelect={(val, itm) => {
                              formik.setFieldValue('city_name', val === null ? '' : itm.label)
                              formik.setFieldValue('district_name', '')
                              formik.setFieldValue('village_name', '')
                            }}
                            onCreate={(val, evt) =>
                              confirmBox(
                                `${intl.formatMessage({
                                  id: 'BUTTON_LABEL_ADD',
                                })} ${intl.formatMessage({
                                  id: 'LABEL_CITY',
                                })}`,
                                `${intl.formatMessage({
                                  id: 'LABEL_CITY',
                                })} "${val}" ${intl.formatMessage({id: 'LABEL_ADDING'})}`,
                                `${intl.formatMessage({id: 'BUTTON_LABEL_ADD'})}`,
                                `${intl.formatMessage({id: 'BUTTON_LABEL_CANCEL'})}`,
                                'city',
                                val,
                                formik.values.province_id,
                                intl.formatMessage({id: 'LABEL_CITY'})
                              )
                            }
                            value={formik.values.city_id}
                          />
                          {formik.errors.city_id ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {intl.formatMessage(
                                  {id: 'VALIDATION_REQUIRED_FIELD'},
                                  {
                                    name: intl.formatMessage({id: 'LABEL_CITY'}),
                                  }
                                )}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* City */}

                        {/* District */}
                        <div className='fv-row mb-5'>
                          {formik.values.district_id !== '' && (
                            <>
                              <label
                                className={`form-label fs-6 fw-bolder text-dark ${
                                  action === 'edit' ? 'required' : ''
                                }`}
                              >
                                {intl.formatMessage({id: 'LABEL_DISTRICTS'})}
                              </label>
                              <br />
                            </>
                          )}
                          <InputPicker
                            readOnly={action !== 'edit' ? true : false}
                            creatable={true}
                            data={optionsDistrict}
                            style={{width: 450}}
                            disabled={
                              formik.values.city_id !== '' || isSelectDisable ? false : true
                            }
                            placeholder={`-- ${intl.formatMessage({id: 'LABEL_DISTRICTS'})} --`}
                            defaultValue={
                              optionsDistrict
                                ? (optionsDistrict as unknown as any[]).find(
                                    (e: any) => e.value === formik.values.district_id
                                  )
                                : ''
                            }
                            onChange={(e) => {
                              formik.setFieldValue('district_id', e === null ? '' : e)
                              formik.setFieldValue('village_id', '')
                            }}
                            onSelect={(val, itm) => {
                              formik.setFieldValue('district_name', val === null ? '' : itm.label)
                              formik.setFieldValue('village_name', '')
                            }}
                            onCreate={(val, evt) =>
                              confirmBox(
                                `${intl.formatMessage({
                                  id: 'BUTTON_LABEL_ADD',
                                })} ${intl.formatMessage({
                                  id: 'LABEL_DISTRICTS',
                                })}`,
                                `${intl.formatMessage({
                                  id: 'LABEL_DISTRICTS',
                                })} "${val}" ${intl.formatMessage({id: 'LABEL_ADDING'})}`,
                                `${intl.formatMessage({id: 'BUTTON_LABEL_ADD'})}`,
                                `${intl.formatMessage({id: 'BUTTON_LABEL_CANCEL'})}`,
                                'district',
                                val,
                                formik.values.city_id,
                                intl.formatMessage({id: 'LABEL_DISTRICTS'})
                              )
                            }
                            value={formik.values.district_id}
                          />
                          {formik.errors.district_id ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {intl.formatMessage(
                                  {id: 'VALIDATION_REQUIRED_FIELD'},
                                  {
                                    name: intl.formatMessage({id: 'LABEL_DISTRICTS'}),
                                  }
                                )}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* District */}

                        {/* Village */}
                        <div className='fv-row mb-5'>
                          {formik.values.village_id !== '' && (
                            <>
                              <label
                                className={`form-label fs-6 fw-bolder text-dark ${
                                  action === 'edit' ? 'required' : ''
                                }`}
                              >
                                {intl.formatMessage({id: 'LABEL_VILLAGES'})}
                              </label>
                              <br />
                            </>
                          )}
                          <InputPicker
                            readOnly={action !== 'edit' ? true : false}
                            creatable={true}
                            data={optionsVillage}
                            style={{width: 450}}
                            disabled={
                              formik.values.district_id !== '' || isSelectDisable ? false : true
                            }
                            placeholder={`-- ${intl.formatMessage({id: 'LABEL_VILLAGES'})} --`}
                            defaultValue={
                              optionsVillage
                                ? (optionsVillage as unknown as any[]).find(
                                    (e: any) => e.value === formik.values.village_id
                                  )
                                : ''
                            }
                            onChange={(e) => {
                              formik.setFieldValue('village_id', e === null ? '' : e)
                            }}
                            onSelect={(val, itm) => {
                              formik.setFieldValue('village_name', val === null ? '' : itm.label)
                            }}
                            onCreate={(val, evt) =>
                              confirmBox(
                                `${intl.formatMessage({
                                  id: 'BUTTON_LABEL_ADD',
                                })} ${intl.formatMessage({
                                  id: 'LABEL_VILLAGES',
                                })}`,
                                `${intl.formatMessage({
                                  id: 'LABEL_VILLAGES',
                                })} "${val}" ${intl.formatMessage({id: 'LABEL_ADDING'})}`,
                                `${intl.formatMessage({id: 'BUTTON_LABEL_ADD'})}`,
                                `${intl.formatMessage({id: 'BUTTON_LABEL_CANCEL'})}`,
                                'village',
                                val,
                                formik.values.district_id,
                                intl.formatMessage({id: 'LABEL_VILLAGES'})
                              )
                            }
                            value={formik.values.village_id}
                          />
                          {formik.errors.village_id ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {intl.formatMessage(
                                  {id: 'VALIDATION_REQUIRED_FIELD'},
                                  {
                                    name: intl.formatMessage({id: 'LABEL_VILLAGES'}),
                                  }
                                )}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Village */}
                      </div>
                      {/* Second Form */}

                      {/* Third Form */}
                      <div className='col-lg-4'>
                        {/* Area */}
                        <div className='fv-row mb-5'>
                          {formik.values.area_id !== '' && (
                            <>
                              <label
                                className={`form-label fs-6 fw-bolder text-dark ${
                                  action === 'edit' ? 'required' : ''
                                }`}
                              >
                                {intl.formatMessage({id: 'LABEL_AREA'})}
                              </label>
                              <br />
                            </>
                          )}
                          <InputPicker
                            readOnly={action !== 'edit' ? true : false}
                            creatable={true}
                            data={optionsArea}
                            style={{width: 450}}
                            disabled={isSelectDisable}
                            placeholder={`-- ${intl.formatMessage({id: 'LABEL_AREA'})} --`}
                            defaultValue={
                              optionsArea
                                ? (optionsArea as unknown as any[]).find(
                                    (e: any) => e.value === formik.values.area_id
                                  )
                                : ''
                            }
                            onChange={(e) => {
                              formik.setFieldValue('area_id', e === null ? '' : e)
                              formik.setFieldValue('cluster_id', '')
                            }}
                            onSelect={(val, itm) => {
                              formik.setFieldValue('area_name', val === null ? '' : itm.label)
                              formik.setFieldValue('cluster_name', '')
                            }}
                            onCreate={(val, evt) =>
                              confirmBox(
                                `${intl.formatMessage({
                                  id: 'BUTTON_LABEL_ADD',
                                })} ${intl.formatMessage({
                                  id: 'LABEL_AREA',
                                })}`,
                                `${intl.formatMessage({
                                  id: 'LABEL_AREA',
                                })} "${val}" ${intl.formatMessage({id: 'LABEL_ADDING'})}`,
                                `${intl.formatMessage({id: 'BUTTON_LABEL_ADD'})}`,
                                `${intl.formatMessage({id: 'BUTTON_LABEL_CANCEL'})}`,
                                'area',
                                val,
                                '',
                                intl.formatMessage({id: 'LABEL_AREA'})
                              )
                            }
                            value={formik.values.area_id}
                          />
                          {formik.errors.area_id ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {intl.formatMessage(
                                  {id: 'VALIDATION_REQUIRED_FIELD'},
                                  {
                                    name: intl.formatMessage({id: 'LABEL_AREA'}),
                                  }
                                )}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Area */}

                        {/* Cluster */}
                        <div className='fv-row mb-5'>
                          {formik.values.cluster_id !== '' && (
                            <>
                              <label className={`form-label fs-6 fw-bolder text-dark`}>
                                {intl.formatMessage({id: 'LABEL_CLUSTER'})}
                              </label>
                              <br />
                            </>
                          )}
                          <InputPicker
                            readOnly={action !== 'edit' ? true : false}
                            creatable={true}
                            data={optionsCluster}
                            style={{width: 450}}
                            disabled={
                              formik.values.area_id !== '' || isSelectDisable ? false : true
                            }
                            placeholder={`-- ${intl.formatMessage({id: 'LABEL_CLUSTER'})} --`}
                            defaultValue={
                              optionsCluster
                                ? (optionsCluster as unknown as any[]).find(
                                    (e: any) => e.value === formik.values.cluster_id
                                  )
                                : ''
                            }
                            onChange={(e) => {
                              formik.setFieldValue('cluster_id', e === null ? '' : e)
                            }}
                            onSelect={(val, itm) => {
                              formik.setFieldValue('cluster_name', val === null ? '' : itm.label)
                            }}
                            onCreate={(val, evt) =>
                              confirmBox(
                                `${intl.formatMessage({
                                  id: 'BUTTON_LABEL_ADD',
                                })} ${intl.formatMessage({
                                  id: 'LABEL_CLUSTER',
                                })}`,
                                `${intl.formatMessage({
                                  id: 'LABEL_CLUSTER',
                                })} "${val}" ${intl.formatMessage({id: 'LABEL_ADDING'})}`,
                                `${intl.formatMessage({id: 'BUTTON_LABEL_ADD'})}`,
                                `${intl.formatMessage({id: 'BUTTON_LABEL_CANCEL'})}`,
                                'cluster',
                                val,
                                formik.values.area_id,
                                intl.formatMessage({id: 'LABEL_CLUSTER'})
                              )
                            }
                            value={formik.values.cluster_id}
                          />
                          {formik.errors.cluster_id ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {intl.formatMessage(
                                  {id: 'VALIDATION_REQUIRED_FIELD'},
                                  {
                                    name: intl.formatMessage({id: 'LABEL_CLUSTER'}),
                                  }
                                )}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Cluster */}
                      </div>
                      {/* Third Form */}
                    </div>

                    <div className='separator my-10'></div>

                    <div className='row mt-10'>
                      {/* Fourth Form */}
                      <div className='col-lg-4'>
                        {/* Status */}
                        <div className='fv-row mb-5'>
                          {formik.values.status !== '' && (
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action === 'edit' ? 'required' : ''
                              }`}
                            >
                              Status
                            </label>
                          )}
                          <div className='form-check form-switch form-check-custom form-check-solid mt-1'>
                            <Toggle
                              size='lg'
                              readOnly={action !== 'edit' ? true : false}
                              checkedChildren={intl.formatMessage({id: 'LABEL_ACTIVE'})}
                              unCheckedChildren={intl.formatMessage({id: 'LABEL_INACTIVE'})}
                              checked={formik.values.status === 't' ? true : false}
                              onChange={() =>
                                formik.setFieldValue(
                                  'status',
                                  formik.values.status === 't' ? 'f' : 't'
                                )
                              }
                            />
                          </div>
                          {formik.touched.status && formik.errors.status ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {formik.errors.status}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Status */}
                      </div>
                      {/* Fourth Form */}

                      {/* Fifth Form */}
                      <div className='col-lg-8'>
                        {/* Name */}
                        <div className='fv-row mb-5'>
                          {formik.values.name !== '' && (
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action === 'edit' ? 'required' : ''
                              }`}
                            >
                              {intl.formatMessage({id: 'LABEL_NAME'})}
                            </label>
                          )}
                          <input
                            type='text'
                            className={clsx(
                              'form-control form-control-solid',
                              {'is-invalid': formik.touched.name && formik.errors.name},
                              {
                                'is-valid': formik.touched.name && !formik.errors.name,
                              }
                            )}
                            placeholder={intl.formatMessage({id: 'LABEL_NAME'})}
                            {...formik.getFieldProps('name')}
                            readOnly={action === 'edit' ? false : true}
                          />
                          {formik.touched.name && formik.errors.name ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {formik.errors.name}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Name */}

                        {/* Mobile Phone */}
                        <div className='fv-row mb-5'>
                          {formik.values.phone_number !== '' && (
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action === 'edit' ? 'required' : ''
                              }`}
                            >
                              {intl.formatMessage({id: 'LABEL_MOBILE_PHONE'})}
                            </label>
                          )}
                          <input
                            type='text'
                            className={clsx(
                              'form-control form-control-solid',
                              {
                                'is-invalid':
                                  formik.touched.phone_number && formik.errors.phone_number,
                              },
                              {
                                'is-valid':
                                  formik.touched.phone_number && !formik.errors.phone_number,
                              }
                            )}
                            placeholder={intl.formatMessage({id: 'LABEL_MOBILE_PHONE'})}
                            {...formik.getFieldProps('phone_number')}
                            onKeyUp={(e) =>
                              formik.setFieldValue(
                                'phone_number',
                                e.currentTarget.value === null
                                  ? ''
                                  : inputPhoneNumbers(e.currentTarget.value)
                              )
                            }
                            readOnly={action === 'edit' ? false : true}
                          />
                          {formik.touched.phone_number && formik.errors.phone_number ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {formik.errors.phone_number}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Mobile Phone */}

                        {/* Postal Code */}
                        <div className='fv-row mb-5'>
                          {formik.values.postal_code !== '' && (
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action === 'edit' ? 'required' : ''
                              }`}
                            >
                              {intl.formatMessage({id: 'LABEL_POSTAL_CODE'})}
                            </label>
                          )}
                          <input
                            type='text'
                            className={clsx(
                              'form-control form-control-solid',
                              {
                                'is-invalid':
                                  formik.touched.postal_code && formik.errors.postal_code,
                              },
                              {
                                'is-valid':
                                  formik.touched.postal_code && !formik.errors.postal_code,
                              }
                            )}
                            placeholder={intl.formatMessage({id: 'LABEL_POSTAL_CODE'})}
                            {...formik.getFieldProps('postal_code')}
                            onKeyUp={(e) =>
                              formik.setFieldValue(
                                'postal_code',
                                e.currentTarget.value === null
                                  ? ''
                                  : inputPostalCode(e.currentTarget.value)
                              )
                            }
                            readOnly={action === 'edit' ? false : true}
                          />
                          {formik.touched.postal_code && formik.errors.postal_code ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {formik.errors.postal_code}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Postal Code */}

                        {/* Address */}
                        <div className='fv-row mb-5'>
                          {formik.values.address !== '' && (
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action === 'edit' ? 'required' : ''
                              }`}
                            >
                              {intl.formatMessage({id: 'LABEL_ADDRESS'})}
                            </label>
                          )}
                          <textarea
                            className={clsx(
                              'form-control form-control-solid',
                              {'is-invalid': formik.touched.address && formik.errors.address},
                              {
                                'is-valid': formik.touched.address && !formik.errors.address,
                              }
                            )}
                            placeholder={intl.formatMessage({id: 'LABEL_ADDRESS'})}
                            {...formik.getFieldProps('address')}
                            rows={3}
                            readOnly={action === 'view' ? true : false}
                          />
                          {formik.touched.address && formik.errors.address ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {formik.errors.address}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Address */}
                      </div>
                      {/* Fifth Form */}
                    </div>
                  </div>
                  {/* Data */}

                  {/* Detail */}
                  <div
                    className={clsx('tab-pane', {
                      active: tab === 'log',
                    })}
                  >
                    <FormLog payload={outlet} formik={formik} />
                  </div>
                  {/* Detail */}
                </div>
              </div>
              {/* Tab Body */}
            </form>
          )}
        </div>
        {/* Form */}
      </Drawer.Body>

      {/* Dialog */}
      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(true)}
        className='modal fade show d-block'
      >
        <Dialog.Panel className='modal-dialog modal-fullscreen'>
          <div className='modal-content'>
            <Dialog.Title className='modal-header'>
              {intl.formatMessage({id: 'DIALOG_HEADER_TITLE'})}
            </Dialog.Title>
            <div className='modal-body scroll-y mx-2 mx-xl-2 my-2'>
              <div className='row'>
                {/* First Col */}
                <div className='col-lg-4'>
                  {/* Holding */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark'>Holding</label>
                    {models.holding_id !== formik.values.holding_id ? (
                      <>
                        {action !== 'add' && (
                          <input
                            type='text'
                            className='form-control form-control-solid'
                            value={models.holding_name}
                            readOnly
                          />
                        )}
                        <input
                          type='text'
                          className='form-control form-control-solid text-primary is-valid'
                          value={formik.values.holding_name}
                          readOnly
                        />
                      </>
                    ) : (
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        value={models.holding_name}
                        readOnly
                      />
                    )}
                  </div>
                  {/* Holding */}

                  {/* Company */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark'>
                      {' '}
                      {intl.formatMessage({id: 'LABEL_COMPANY'})}
                    </label>
                    {models.company_id !== formik.values.company_id ? (
                      <>
                        {action !== 'add' && (
                          <input
                            type='text'
                            className='form-control form-control-solid'
                            value={models.company_name}
                            readOnly
                          />
                        )}
                        <input
                          type='text'
                          className='form-control form-control-solid text-primary is-valid'
                          value={formik.values.company_name}
                          readOnly
                        />
                      </>
                    ) : (
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        value={models.company_name}
                        readOnly
                      />
                    )}
                  </div>
                  {/* Company */}

                  {/* Brand */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark'>
                      {' '}
                      {intl.formatMessage({id: 'LABEL_BRAND'})}
                    </label>
                    {models.brand_id !== formik.values.brand_id ? (
                      <>
                        {action !== 'add' && (
                          <input
                            type='text'
                            className='form-control form-control-solid'
                            value={models.brand_name}
                            readOnly
                          />
                        )}
                        <input
                          type='text'
                          className='form-control form-control-solid text-primary is-valid'
                          value={formik.values.brand_name}
                          readOnly
                        />
                      </>
                    ) : (
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        value={models.brand_name}
                        readOnly
                      />
                    )}
                  </div>
                  {/* Brand */}
                </div>
                {/* First Col */}

                {/* Second Col */}
                <div className='col-lg-4'>
                  {/* Province */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark'>
                      {' '}
                      {intl.formatMessage({id: 'LABEL_PROVINCE'})}
                    </label>
                    {models.province_id !== formik.values.province_id ? (
                      <>
                        {action !== 'add' && (
                          <input
                            type='text'
                            className='form-control form-control-solid'
                            value={models.province_name}
                            readOnly
                          />
                        )}
                        <input
                          type='text'
                          className='form-control form-control-solid text-primary is-valid'
                          value={formik.values.province_name}
                          readOnly
                        />
                      </>
                    ) : (
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        value={models.province_name}
                        readOnly
                      />
                    )}
                  </div>
                  {/* Province */}

                  {/* City */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark'>
                      {' '}
                      {intl.formatMessage({id: 'LABEL_CITY'})}
                    </label>
                    {models.city_id !== formik.values.city_id ? (
                      <>
                        {action !== 'add' && (
                          <input
                            type='text'
                            className='form-control form-control-solid'
                            value={models.city_name}
                            readOnly
                          />
                        )}
                        <input
                          type='text'
                          className='form-control form-control-solid text-primary is-valid'
                          value={formik.values.city_name}
                          readOnly
                        />
                      </>
                    ) : (
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        value={models.city_name}
                        readOnly
                      />
                    )}
                  </div>
                  {/* City */}

                  {/* District */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark'>
                      {' '}
                      {intl.formatMessage({id: 'LABEL_DISTRICTS'})}
                    </label>
                    {models.district_id !== formik.values.district_id ? (
                      <>
                        {action !== 'add' && (
                          <input
                            type='text'
                            className='form-control form-control-solid'
                            value={models.district_name}
                            readOnly
                          />
                        )}
                        <input
                          type='text'
                          className='form-control form-control-solid text-primary is-valid'
                          value={formik.values.district_name}
                          readOnly
                        />
                      </>
                    ) : (
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        value={models.district_name}
                        readOnly
                      />
                    )}
                  </div>
                  {/* District */}

                  {/* Village */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark'>
                      {intl.formatMessage({id: 'LABEL_VILLAGES'})}
                    </label>
                    {models.village_id !== formik.values.village_id ? (
                      <>
                        {action !== 'add' && (
                          <input
                            type='text'
                            className='form-control form-control-solid'
                            value={models.village_name}
                            readOnly
                          />
                        )}
                        <input
                          type='text'
                          className='form-control form-control-solid text-primary is-valid'
                          value={formik.values.village_name}
                          readOnly
                        />
                      </>
                    ) : (
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        value={models.village_name}
                        readOnly
                      />
                    )}
                  </div>
                  {/* Village */}
                </div>
                {/* Second Col */}

                {/* Third Col */}
                <div className='col-lg-4'>
                  {/* Area */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark'>
                      {' '}
                      {intl.formatMessage({id: 'LABEL_AREA'})}
                    </label>
                    {models.area_id !== formik.values.area_id ? (
                      <>
                        {action !== 'add' && (
                          <input
                            type='text'
                            className='form-control form-control-solid'
                            value={models.area_name}
                            readOnly
                          />
                        )}
                        <input
                          type='text'
                          className='form-control form-control-solid text-primary is-valid'
                          value={formik.values.area_name}
                          readOnly
                        />
                      </>
                    ) : (
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        value={models.area_name}
                        readOnly
                      />
                    )}
                  </div>
                  {/* Area */}

                  {/* Cluster */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark'>
                      {' '}
                      {intl.formatMessage({id: 'LABEL_CLUSTER'})}
                    </label>
                    {models.cluster_id !== formik.values.cluster_id ? (
                      <>
                        {action !== 'add' && (
                          <input
                            type='text'
                            className='form-control form-control-solid'
                            value={models.cluster_name}
                            readOnly
                          />
                        )}
                        <input
                          type='text'
                          className='form-control form-control-solid text-primary is-valid'
                          value={formik.values.cluster_name}
                          readOnly
                        />
                      </>
                    ) : (
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        value={models.cluster_name}
                        readOnly
                      />
                    )}
                  </div>
                  {/* Cluster */}
                </div>
                {/* Third Col */}
              </div>

              <div className='separator my-10'></div>

              <div className='row mt-0'>
                {/* Fourth Col */}
                <div className='col-lg-4'>
                  {/* Status */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark'>
                      <label className='form-label fs-6 fw-bolder text-dark'>Status</label>
                    </label>
                    {models.status !== formik.values.status ? (
                      <>
                        {action !== 'add' && (
                          <input
                            type='text'
                            className='form-control form-control-solid'
                            value={
                              models.status === 't'
                                ? intl.formatMessage({id: 'LABEL_ACTIVE'})
                                : intl.formatMessage({id: 'LABEL_INACTIVE'})
                            }
                            readOnly
                          />
                        )}
                        <input
                          type='text'
                          className='form-control form-control-solid text-primary is-valid'
                          value={
                            formik.values.status === 't'
                              ? intl.formatMessage({id: 'LABEL_ACTIVE'})
                              : intl.formatMessage({id: 'LABEL_INACTIVE'})
                          }
                          readOnly
                        />
                      </>
                    ) : (
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        value={
                          models.status === 't'
                            ? intl.formatMessage({id: 'LABEL_ACTIVE'})
                            : intl.formatMessage({id: 'LABEL_INACTIVE'})
                        }
                        readOnly
                      />
                    )}
                  </div>
                  {/* Status */}
                </div>
                {/* Fourth Col */}

                {/* Fifth Col */}
                <div className='col-lg-8'>
                  {/* Name */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark'>
                      {intl.formatMessage({id: 'LABEL_NAME'})}
                    </label>
                    {models.name !== formik.values.name ? (
                      <>
                        {action !== 'add' && (
                          <input
                            type='text'
                            className='form-control form-control-solid'
                            value={models.name}
                            readOnly
                          />
                        )}
                        <input
                          type='text'
                          className='form-control form-control-solid text-primary is-valid'
                          value={formik.values.name}
                          readOnly
                        />
                      </>
                    ) : (
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        value={models.name}
                        readOnly
                      />
                    )}
                  </div>
                  {/* Name */}

                  {/* Mobile Phone */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark'>
                      {intl.formatMessage({id: 'LABEL_MOBILE_PHONE'})}
                    </label>
                    {models.phone_number !== formik.values.phone_number ? (
                      <>
                        {action !== 'add' && (
                          <input
                            type='text'
                            className='form-control form-control-solid'
                            value={models.phone_number}
                            readOnly
                          />
                        )}
                        <input
                          type='text'
                          className='form-control form-control-solid text-primary is-valid'
                          value={formik.values.phone_number}
                          readOnly
                        />
                      </>
                    ) : (
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        value={models.phone_number}
                        readOnly
                      />
                    )}
                  </div>
                  {/* Mobile Phone */}

                  {/* Postal Code */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark'>
                      {intl.formatMessage({id: 'LABEL_POSTAL_CODE'})}
                    </label>
                    {models.postal_code !== formik.values.postal_code ? (
                      <>
                        {action !== 'add' && (
                          <input
                            type='text'
                            className='form-control form-control-solid'
                            value={models.postal_code}
                            readOnly
                          />
                        )}
                        <input
                          type='text'
                          className='form-control form-control-solid text-primary is-valid'
                          value={formik.values.postal_code}
                          readOnly
                        />
                      </>
                    ) : (
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        value={models.postal_code}
                        readOnly
                      />
                    )}
                  </div>
                  {/* Postal Code */}

                  {/* Address */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark'>
                      {intl.formatMessage({id: 'LABEL_ADDRESS'})}
                    </label>
                    {models.address !== formik.values.address ? (
                      <>
                        {action !== 'add' && (
                          <textarea
                            className='form-control form-control-solid'
                            value={models.address}
                            readOnly
                          />
                        )}
                        <textarea
                          className='form-control form-control-solid text-primary is-valid'
                          value={formik.values.address}
                          readOnly
                        />
                      </>
                    ) : (
                      <textarea
                        className='form-control form-control-solid'
                        value={formik.values.address}
                        readOnly
                      />
                    )}
                  </div>
                  {/* Address */}
                </div>
                {/* Fifth Col */}
              </div>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-outline btn-btn-outline-info me-3'
                data-kt-users-modal-action='close'
                onClick={() => setIsDialogOpen(false)}
              >
                {intl.formatMessage({id: 'BUTTON_LABEL_BACK'})}
              </button>

              <button
                type='button'
                className={`btn ${loading ? 'btn-secondary' : 'btn-success'}`}
                data-kt-users-modal-action='submit'
                onClick={() => formik.handleSubmit()}
                disabled={loading ? true : false}
              >
                {!loading && (
                  <span className='indicator-label'>
                    {intl.formatMessage({id: 'BUTTON_LABEL_SUBMIT'})}
                  </span>
                )}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    {intl.formatMessage({id: 'BUTTON_LABEL_PLEASE_WAIT'})}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
      {/* Dialog */}
    </>
  )
}

export {ViewDrawerForm}
