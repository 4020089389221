import moment from 'moment'

export type LogID = undefined | null | string

export type ListPaginationState = {
  page?: number
  items_per_page?: number
}

export type ListDaterangeState = {
  daterange: string
}

export type ListSortState = {
  sort: string
  order: 'asc' | 'desc'
}

export type ListFilterState = {
  filter?: any
}

export type ListSearchState = {
  search?: any
}

export type ListMetaStatusState = {
  status: string
}

export type ListMetaActivitiesState = {
  activity?: string
}

export type ListMetaUsersState = {
  user_id?: string
  username?: string
}

export type ListPaginationDataFilterState = {
  metaActivity?: Array<ListMetaActivitiesState>
  metaUser?: Array<ListMetaUsersState>
}

export type ListResponse<T> = {
  data?: T
  meta?: {
    message?: string
    status?: boolean
    code?: number
    currentPage?: number
    prevPage?: number
    nextPage?: number
    perPage?: number
    totalCount?: number
    pageCount?: number
    dataFilter?: ListPaginationDataFilterState
  }
}

export type ListQueryState =
  | (ListPaginationState &
      ListDaterangeState &
      ListSortState &
      ListFilterState &
      ListSearchState &
      ListPaginationDataFilterState)
  | undefined

export type ListQueryRequestContextProps = {
  state: ListQueryState
  updateState: (updates: Partial<ListQueryState>) => void
}

export const initialListQueryState: ListQueryState = {
  page: 1,
  items_per_page: 5,
  sort: 'created_at',
  order: 'desc',
  daterange:
    moment.utc(moment().startOf('days')).format('YYYY-MM-DD HH:mm:ss') +
    '[-]' +
    moment.utc(moment().endOf('days')).format('YYYY-MM-DD HH:mm:ss'),
}

export const initialListQueryRequest: ListQueryRequestContextProps = {
  state: initialListQueryState,
  updateState: () => {},
}

export type ListQueryResponseContextProps<T> = {
  response?: ListResponse<Array<T>> | undefined
  refetch: () => void
  isLoading: boolean
  query: string
  meta?: ListResponse<Array<T>> | undefined
  dataMeta?: ListResponse<Array<T>> | undefined
  lastUpdated?: any | undefined
}

export const initialListQueryResponse = {refetch: () => {}, isLoading: false, query: ''}
