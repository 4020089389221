import {FC, useContext, useEffect, useMemo, useState} from 'react'
import {useQuery, useQueryClient} from 'react-query'
import toast from 'react-hot-toast'

import {QUERIES, WithChildren} from '_metronic/helpers'
import {removeAuth} from 'app/modules/auth'

import {
  createListResponseContext,
  fetch,
  initialListQueryResponse,
  ListModel,
  stringifyListRequestQuery,
} from '../helpers'

import {useListQueryRequest} from '.'

const ListQueryResponseContext = createListResponseContext<ListModel>(initialListQueryResponse)

const ListQueryResponseProvider: FC<WithChildren> = ({children}) => {
  const queryClient = useQueryClient()
  const {state} = useListQueryRequest()
  const [query, setQuery] = useState<string>(stringifyListRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyListRequestQuery(state), [state])
  const queryKey = QUERIES.POS_REPORT_SHIFT_LIST + '-' + query

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedQuery])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    [queryKey],
    () => {
      return fetch(query)
    },
    {
      // staleTime: 15000,
      // refetchInterval: 15000,
      // keepPreviousData: true,
      refetchOnWindowFocus: true,
      onError: (err: any) => {
        if (err.response.status !== 200) {
          if (err.response.status === 401) {
            toast.error(err.response.statusText)
            removeAuth()
          } else {
            toast.error(err.response.statusText)
          }
        }
      },
    }
  )

  const _lastUpdated = queryClient.getQueryState([queryKey])

  return (
    <ListQueryResponseContext.Provider
      value={{
        isLoading: isFetching,
        refetch,
        response,
        query,
        meta: response,
        dataMeta: response,
        lastUpdated: _lastUpdated?.dataUpdatedAt,
      }}
    >
      {children}
    </ListQueryResponseContext.Provider>
  )
}

const useListQueryResponse = () => useContext(ListQueryResponseContext)

const useListQueryResponseData = () => {
  const {response} = useListQueryResponse()
  if (!response) {
    return []
  }

  return response?.data || []
}

const useQueryResponsePagination = () => {
  const {response} = useListQueryResponse()
  if (!response) {
    return []
  }

  return response?.meta || []
}

const useListQueryResponseLoading = (): boolean => {
  const {isLoading} = useListQueryResponse()
  return isLoading
}

export {
  ListQueryResponseProvider,
  useListQueryResponse,
  useListQueryResponseData,
  useQueryResponsePagination,
  useListQueryResponseLoading,
}
