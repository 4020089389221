import {initialListQueryState, ListQueryState} from 'app/modules/settings-all-module/user/helpers'

const USER_LIST_STATE_KEY = 'backoffice-orins-pos/settings/user-management/user'

const getUserListState = () => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null | undefined = localStorage.getItem(USER_LIST_STATE_KEY)
  if (!lsValue) {
    return setUserListState(initialListQueryState)
  }

  try {
    const state = JSON.parse(lsValue)
    if (state) {
      return state
    }
  } catch (error) {
    console.log(USER_LIST_STATE_KEY + ' STATE LOCAL STORAGE PARSE ERROR', error)
  }
}

const setUserListState = (state: ListQueryState) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(state)
    localStorage.setItem(USER_LIST_STATE_KEY, lsValue)
  } catch (error) {
    console.error(USER_LIST_STATE_KEY + ' STATE LOCAL STORAGE SAVE ERROR', error)
  }
}

export {getUserListState, setUserListState, USER_LIST_STATE_KEY}
