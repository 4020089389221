/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useFormik} from 'formik'

import {NotAuthRedirectForm} from 'app/components'
import {getAuth, removeAuth} from 'app/modules/auth'
import {useUserGroupModules} from 'app/context/providers'
import {useLang} from '_metronic/i18n/Metronici18n'

import * as contextHelper from 'app/context/state/UserGroupModulesStateContext'

import {GetDataByIDModel, initialData, updateData} from '../../helpers'
import {useAction, useListQueryResponse} from '../../providers'

type CheckBoxProps = {
  name: string
  value: string
  currentValue: any
  currentPayload: any
  formik: any
  action: string
}

const Checkbox: FC<CheckBoxProps> = ({
  name,
  value,
  currentValue,
  currentPayload,
  formik,
  action,
}) => {
  return (
    <div className='form-check form-check-custom form-check-solid'>
      <input
        type='checkbox'
        id={`${name}-${value}`}
        checked={currentValue.includes(value)}
        className={`form-check-input ${action !== 'view' && 'cursor-pointer'}`}
        onChange={() => {
          if (action !== 'view') {
            if (currentValue.includes(value)) {
              const nextValue = currentValue.filter((dt: any) => dt !== value)
              formik.setFieldValue(name, nextValue)
            } else {
              const nextValue = currentValue.concat(value)
              formik.setFieldValue(name, nextValue)
            }
          }
        }}
        disabled={action === 'view' ? true : false}
      />
      <label
        className={`form-check-label ${
          currentPayload.includes(value)
            ? currentPayload.includes(value) && !currentValue.includes(value) && 'text-info fw-bold'
            : !currentPayload.includes(value) && currentValue.includes(value) && 'text-info fw-bold'
        }`}
        htmlFor={`${name}-${value}`}
      >
        {name.charAt(0).toUpperCase() + name.slice(1)}
      </label>
    </div>
  )
}

type Props = {
  isLoading: boolean
  userGroupAccess: GetDataByIDModel
  appMenuID: any
  modalID: any
  appModuleID: any
}

const ViewModalForm: FC<Props> = ({
  userGroupAccess,
  isLoading,
  appMenuID,
  modalID,
  appModuleID,
}) => {
  const local = useLang()
  const auth = getAuth()
  const intl = useIntl()
  const {setItemIdForUpdate, action} = useAction()
  const {currentSiteLabel} = useUserGroupModules()
  const {refetch} = useListQueryResponse()
  const [loading, setLoading] = useState(false)
  const [isAuth, setIsAuth] = useState(true)
  const [completed, setCompleted] = useState(false)
  const userGroupAccessModules = contextHelper.getUserGroupModules()
  const [tab, setTab] = useState(currentSiteLabel)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const modules =
    userGroupAccessModules === undefined
      ? []
      : (userGroupAccessModules as unknown as any[]).filter((module: any) => {
          return module.module_id === appModuleID
        })

  const [userGroupAccessModels] = useState<GetDataByIDModel>({
    ...userGroupAccess,
    role_id: userGroupAccess.role_id || initialData.role_id,
    add: userGroupAccess.add || initialData.add,
    apv1: userGroupAccess.apv1 || initialData.apv1,
    apv2: userGroupAccess.apv2 || initialData.apv2,
    apv3: userGroupAccess.apv3 || initialData.apv3,
    delete: userGroupAccess.delete || initialData.delete,
    edit: userGroupAccess.edit || initialData.edit,
    view: userGroupAccess.view || initialData.view,
    menu_id: appMenuID,
  })
  const requestUpdateUserData = async (values: any, setStatus: any, setSubmitting: any) => {
    setLoading(true)
    setSubmitting(true)
    setStatus(null)

    // request update to API
    try {
      const res = await updateData(values, appModuleID)

      // throw message error
      if (res?.meta.code === 200) {
        refetch()
        setStatus(intl.formatMessage({id: 'RESPONSE_USER_GROUP_ACCESS_EDIT_SUCCESS'}))
        setCompleted(true)
        setTimeout(function () {
          setItemIdForUpdate(undefined)
        }, 1e3)
      }
    } catch (error: any) {
      console.error(error)
      if (error.response.status === 401) {
        setStatus(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
        removeAuth()
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
      }
      setTimeout(function () {
        setStatus(null)
      }, 9e3)

      return false
    } finally {
      setTimeout(() => {
        setLoading(false)
        setSubmitting(false)
      }, 5e2)
    }
  }
  const formik = useFormik({
    initialValues: userGroupAccessModels,
    onSubmit: async (values, {setSubmitting, setStatus}) => {
      requestUpdateUserData(values, setStatus, setSubmitting)
    },
    onReset: (values) => {
      values = userGroupAccessModels
    },
  })

  useEffect(() => {
    auth === undefined && setIsAuth(false)
  }, [auth])

  return (
    <>
      {!isAuth && <NotAuthRedirectForm />}
      {/* Response Message */}
      {formik.status &&
        (formik.status === intl.formatMessage({id: 'RESPONSE_USER_GROUP_ACCESS_EDIT_SUCCESS'}) ? (
          <div className='m-lg-5 p-2 bg-light-success rounded'>
            <div className='text-success text-center font-weight-bold fw-bolder'>
              {formik.status}
            </div>
          </div>
        ) : (
          <div className={`m-lg-5 p-2 alert alert-danger`}>
            <div className='alert-text text-center font-weight-bold fw-bolder'>{formik.status}</div>
          </div>
        ))}
      {/* Response Message */}

      {/* Form */}
      <form id={`${modalID}-form`} className='form' onSubmit={formik.handleSubmit} noValidate>
        <div
          className='d-flex flex-column scroll-y me-n2'
          id={`body-${modalID}`}
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies={`#${modalID}`}
          data-kt-scroll-wrappers={`#body-${modalID}`}
          data-kt-scroll-offset='300px'
        >
          <div className='card card-custom'>
            <div className='card-header card-header-stretch overflow-auto'>
              <ul
                className='nav nav-stretch nav-line-tabs nav-line-tabs-2x fw-bold border-transparent flex-nowrap'
                role='tablist'
              >
                {(modules as unknown as any).map((module: any) =>
                  module.sites.map((site: any, i: any) => (
                    <li className='nav-item' key={`li-${i}`}>
                      <a
                        className={clsx(
                          `nav-link cursor-pointer`,
                          {
                            active: tab === (local === 'id' ? site.name_idn : site.name_eng),
                          },
                          {
                            'text-primary':
                              tab === (local === 'id' ? site.name_idn : site.name_eng),
                          }
                        )}
                        onClick={() => setTab(local === 'id' ? site.name_idn : site.name_eng)}
                        role='tab'
                      >
                        {local === 'id' ? site.name_idn : site.name_eng}
                      </a>
                    </li>
                  ))
                )}
              </ul>
            </div>
            {/* end::Header */}

            {/* begin::Body */}
            <div className='card-body'>
              <div className='tab-content'>
                {(modules as unknown as any).map((module: any) =>
                  module.sites.map((site: any, i: any) => (
                    <div
                      className={clsx('tab-pane', {
                        active: tab === (local === 'id' ? site.name_idn : site.name_eng),
                      })}
                      key={`${tab}-list-${i}`}
                    >
                      <table className='table table-rounded table-responsive table-hover border gy-4 gs-4'>
                        <tbody>
                          {site.menus.map((menu: any) => (
                            <tr key={`${tab}-tr-${menu.menu_id}`}>
                              <td
                                key={`${tab}-td-${menu.menu_id}`}
                                style={menu.parent_id === '' ? undefined : {paddingLeft: 30}}
                              >
                                {menu.parent_id === '' ? (
                                  <strong>
                                    {' '}
                                    {local === 'id' ? menu.name_idn : menu.name_eng}{' '}
                                  </strong>
                                ) : (
                                  <i>. {local === 'id' ? menu.name_idn : menu.name_eng}</i>
                                )}
                              </td>
                              {menu.access !== '0000000' && (
                                <>
                                  {String(menu.access).charAt(0) === '1' ? (
                                    <td
                                      key={`${tab}-view-${menu.menu_id}`}
                                      style={{textAlign: 'center'}}
                                    >
                                      <Checkbox
                                        name='view'
                                        value={menu.menu_id}
                                        currentValue={formik.values.view}
                                        currentPayload={userGroupAccessModels.view}
                                        formik={formik}
                                        action={action}
                                      />
                                    </td>
                                  ) : (
                                    <td />
                                  )}
                                  {String(menu.access).charAt(1) === '1' ? (
                                    <td
                                      key={`${tab}-add-${menu.menu_id}`}
                                      style={{textAlign: 'center'}}
                                    >
                                      <Checkbox
                                        name='add'
                                        value={menu.menu_id}
                                        currentValue={formik.values.add}
                                        currentPayload={userGroupAccessModels.add}
                                        formik={formik}
                                        action={action}
                                      />
                                    </td>
                                  ) : (
                                    <td />
                                  )}
                                  {String(menu.access).charAt(2) === '1' ? (
                                    <td
                                      key={`${tab}edit-${menu.menu_id}`}
                                      style={{textAlign: 'center'}}
                                    >
                                      <Checkbox
                                        name='edit'
                                        value={menu.menu_id}
                                        currentValue={formik.values.edit}
                                        currentPayload={userGroupAccessModels.edit}
                                        formik={formik}
                                        action={action}
                                      />
                                    </td>
                                  ) : (
                                    <td />
                                  )}
                                  {String(menu.access).charAt(3) === '1' ? (
                                    <td
                                      key={`${tab}delete-${menu.menu_id}`}
                                      style={{textAlign: 'center'}}
                                    >
                                      <Checkbox
                                        name='delete'
                                        value={menu.menu_id}
                                        currentValue={formik.values.delete}
                                        currentPayload={userGroupAccessModels.delete}
                                        formik={formik}
                                        action={action}
                                      />
                                    </td>
                                  ) : (
                                    <td />
                                  )}
                                  {String(menu.access).charAt(4) === '1' ? (
                                    <td
                                      key={`${tab}-apv1-${menu.menu_id}`}
                                      style={{textAlign: 'center'}}
                                    >
                                      <Checkbox
                                        name='apv1'
                                        value={menu.menu_id}
                                        currentValue={formik.values.apv1}
                                        currentPayload={userGroupAccessModels.apv1}
                                        formik={formik}
                                        action={action}
                                      />
                                    </td>
                                  ) : (
                                    <td />
                                  )}
                                  {String(menu.access).charAt(5) === '1' ? (
                                    <td
                                      key={`${tab}-apv2-${menu.menu_id}`}
                                      style={{textAlign: 'center'}}
                                    >
                                      <Checkbox
                                        name='apv2'
                                        value={menu.menu_id}
                                        currentValue={formik.values.apv2}
                                        currentPayload={userGroupAccessModels.apv2}
                                        formik={formik}
                                        action={action}
                                      />
                                    </td>
                                  ) : (
                                    <td />
                                  )}
                                  {String(menu.access).charAt(6) === '1' ? (
                                    <td
                                      key={`${tab}-apv3-${menu.menu_id}`}
                                      style={{textAlign: 'center'}}
                                    >
                                      <Checkbox
                                        name='apv3'
                                        value={menu.menu_id}
                                        currentValue={formik.values.apv3}
                                        currentPayload={userGroupAccessModels.apv3}
                                        formik={formik}
                                        action={action}
                                      />
                                    </td>
                                  ) : (
                                    <td />
                                  )}
                                </>
                              )}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ))
                )}
              </div>
            </div>
            {/* end::Body */}
          </div>
        </div>
      </form>
      {/* Form */}

      {/* Footer */}
      <div className='modal-footer'>
        {action !== 'view' && userGroupAccessModels !== formik.values && !completed && (
          <button
            type='reset'
            className='btn btn-light me-3'
            data-kt-users-modal-action='reset'
            onClick={formik.handleReset}
            disabled={loading}
          >
            {!loading && (
              <span className='indicator-label'>
                {intl.formatMessage({id: 'BUTTON_LABEL_RESET'})}
              </span>
            )}
          </button>
        )}

        {action !== 'view' && userGroupAccessModels !== formik.values && !completed && (
          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            onClick={() => formik.handleSubmit()}
            disabled={isLoading || loading || formik.isSubmitting || !formik.isValid}
          >
            {!loading && (
              <span className='indicator-label'>
                {intl.formatMessage({id: 'BUTTON_LABEL_SUBMIT'})}
              </span>
            )}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                {intl.formatMessage({id: 'BUTTON_LABEL_PLEASE_WAIT'})}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        )}
      </div>
      {/* Footer */}
    </>
  )
}

export {ViewModalForm}
