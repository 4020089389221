/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {Whisper, Tooltip} from 'rsuite'

import {MenuComponent} from '_metronic/assets/ts/components'
import {KTIcon} from '_metronic/helpers'

import {useAction} from '../../providers'

type Props = {
  menu_id: string
  status: string
  level: number
  directory: string
}

const ListActionFormatter: FC<Props> = ({menu_id, status, level, directory}) => {
  const intl = useIntl()
  const {
    setAction,
    setItemIdForUpdate,
    currentAccessMenu,
    setIsSubMenu,
    setIsParent,
    setParentID,
    setDirectory,
  } = useAction()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openModal = (action: any) => {
    setAction(action)
    setItemIdForUpdate(menu_id)
    setIsSubMenu(level === 1 ? false : true)
    setIsParent(level === 1 ? true : false)
    setParentID('')
    setDirectory('')
  }

  const openModalForSubmenu = () => {
    setAction('add')
    setIsSubMenu(true)
    setItemIdForUpdate(null)
    setParentID(menu_id)
    setDirectory(directory)
  }

  return (
    <>
      <a
        href='#'
        className='btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px'
        data-kt-menu-trigger='hover'
        data-kt-menu-placement='left'
      >
        &nbsp;
        <KTIcon iconName='dots-square-vertical' className='fs-2x' />
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-row menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-225px'
        data-kt-menu='true'
      >
        {/* View */}
        <div className='menu-item'>
          <Whisper
            placement='top'
            controlId='control-id-hover'
            trigger='hover'
            speaker={
              <Tooltip id='tooltip-user-name'>
                <span className='menu-title fw-bolder'>
                  {intl.formatMessage({id: 'BUTTON_LABEL_VIEW'})}
                </span>
              </Tooltip>
            }
          >
            <a href='#' className='menu-link' onClick={() => openModal('view')}>
              <span className='menu-icon' data-kt-element='icon'>
                <KTIcon iconName='magnifier' className='fs-2' />
              </span>
            </a>
          </Whisper>
        </div>
        {/* View */}

        {/* Add Sub Menu */}
        {currentAccessMenu?.add?.access_role === 'add' && level === 1 && (
          <div className='menu-item'>
            <Whisper
              placement='top'
              controlId='control-id-hover'
              trigger='hover'
              speaker={
                <Tooltip id='tooltip-user-name'>
                  <span className='menu-title text-primary fw-bolder'>
                    {intl.formatMessage({id: 'BUTTON_LABEL_ADD'})}
                    {' Sub Menu'}
                  </span>
                </Tooltip>
              }
            >
              <a href='#' className='menu-link' onClick={() => openModalForSubmenu()}>
                <span className='menu-icon' data-kt-element='icon'>
                  <KTIcon iconName='add-item' className='fs-2 text-primary ' />
                </span>
              </a>
            </Whisper>
          </div>
        )}
        {/* Add Sub Menu */}

        {/* Edit */}
        {currentAccessMenu?.edit?.access_role === 'edit' && (
          <div className='menu-item'>
            <Whisper
              placement='top'
              controlId='control-id-hover'
              trigger='hover'
              speaker={
                <Tooltip id='tooltip-user-name'>
                  <span className='menu-title text-warning fw-bolder'>
                    {intl.formatMessage({id: 'BUTTON_LABEL_EDIT'})}
                  </span>
                </Tooltip>
              }
            >
              <a href='#' className='menu-link' onClick={() => openModal('edit')}>
                <span className='menu-icon' data-kt-element='icon'>
                  <KTIcon iconName='notepad-edit' className='fs-2 text-warning' />
                </span>
              </a>
            </Whisper>
          </div>
        )}
        {/* Edit */}

        {/* Delete */}
        {currentAccessMenu?.delete?.access_role === 'delete' && status === 'f' && (
          <div className='menu-item'>
            <Whisper
              placement='top'
              controlId='control-id-hover'
              trigger='hover'
              speaker={
                <Tooltip id='tooltip-user-name'>
                  <span className='menu-title text-danger fw-bolder'>
                    {intl.formatMessage({id: 'BUTTON_LABEL_DELETE'})}
                  </span>
                </Tooltip>
              }
            >
              <a href='#' className='menu-link' onClick={() => openModal('delete')}>
                <span className='menu-icon' data-kt-element='icon'>
                  <KTIcon iconName='tablet-delete' className='fs-2 text-danger' />
                </span>
              </a>
            </Whisper>
          </div>
        )}
        {/* Delete */}
      </div>
      {/* end::Menu */}
    </>
  )
}

export {ListActionFormatter}
