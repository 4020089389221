import axios, {AxiosResponse} from 'axios'

import {APIResponse} from 'app/components'

import {ListQueryResponse} from './_list_models'
import {ModuleID} from '.'

const API_APP_URL = process.env.REACT_APP_APP_API_URL
const X_API_KEY = process.env.REACT_APP_APP_API_KEY
const GET_MODULE_LIST_URL = `${API_APP_URL}/module?`
const GET_MODULE_URL = `${API_APP_URL}/module/fetch-module?`
const ADD_UPDATE_MODULE_URL = `${API_APP_URL}/module?`
const DELETE_MODULE_URL = `${API_APP_URL}/module`

const getList = (query: any): Promise<ListQueryResponse> => {
  return axios
    .get(`${GET_MODULE_LIST_URL}${query}`, {
      headers: {'X-API-KEY': `${X_API_KEY}`},
    })
    .then((d: AxiosResponse<ListQueryResponse>) => d.data)
}

const getDataByID = (module_id: ModuleID): Promise<APIResponse | undefined> => {
  return axios
    .get(`${GET_MODULE_URL}module_id=${module_id}`, {
      headers: {'X-API-KEY': `${X_API_KEY}`},
    })
    .then((response: AxiosResponse<APIResponse>) => response.data)
}

const addData = (payload: any): Promise<APIResponse | undefined> => {
  return axios
    .post(
      `${ADD_UPDATE_MODULE_URL}`,
      {payload},
      {
        headers: {'X-API-KEY': `${X_API_KEY}`},
      }
    )
    .then((response: AxiosResponse<APIResponse>) => response.data)
}

const updateData = (payload: any): Promise<APIResponse | undefined> => {
  return axios
    .put(
      `${ADD_UPDATE_MODULE_URL}`,
      {payload},
      {
        headers: {'X-API-KEY': `${X_API_KEY}`},
      }
    )
    .then((response: AxiosResponse<APIResponse>) => response.data)
}

const deleteData = (module_id: any, menu_id: any): Promise<APIResponse | undefined> => {
  return axios
    .delete(`${DELETE_MODULE_URL}/${module_id}/${menu_id}`, {
      headers: {'X-API-KEY': `${X_API_KEY}`},
    })
    .then((response: AxiosResponse<APIResponse>) => response.data)
}

export {getList, getDataByID, addData, updateData, deleteData}
