import {KTCardBody} from '_metronic/helpers'
import {PaginationHelpers} from 'app/components'

import {useListQueryRequest, useListQueryResponse} from '../../providers'
import {ListTable} from './ListTable'

const ListTableWrapper = () => {
  return (
    <KTCardBody className='py-0'>
      <ListTable />
      <PaginationHelpers
        useListQueryRequest={useListQueryRequest}
        useListQueryResponse={useListQueryResponse}
      />
    </KTCardBody>
  )
}

export {ListTableWrapper}
