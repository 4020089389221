import {Dispatch, SetStateAction} from 'react'

import {CurrentMenuAccessModel} from 'app/context/models'

import {ItemID} from '.'

export type ActionContextProps = {
  currentAccessMenu: CurrentMenuAccessModel | undefined
  setCurrentAccessMenu: Dispatch<SetStateAction<CurrentMenuAccessModel | undefined>>
  isLoading: boolean
  setIsLoading: Dispatch<SetStateAction<boolean>>
  showFilter: boolean
  setShowFilter: Dispatch<SetStateAction<boolean>>
  action: string
  setAction: Dispatch<SetStateAction<string>>
  itemIdForUpdate?: ItemID
  setItemIdForUpdate: Dispatch<SetStateAction<ItemID>>
  holdingOptions: any
  setHoldingOptions: Dispatch<SetStateAction<any>>
  companyOptions: any
  setCompanyOptions: Dispatch<SetStateAction<any>>
  brandOptions: any
  setBrandOptions: Dispatch<SetStateAction<any>>
  outletOptions: any
  setOutletOptions: Dispatch<SetStateAction<any>>
  categoriesOptions: any
  setCategoriesOptions: Dispatch<SetStateAction<any>>
  isItemCategory: boolean
  setIsItemCategory: Dispatch<SetStateAction<boolean>>
  categoryName: string
  setCategoryName: Dispatch<SetStateAction<string>>
  itemName: string
  setItemName: Dispatch<SetStateAction<string>>
}

export const initialAction: ActionContextProps = {
  currentAccessMenu: undefined,
  setCurrentAccessMenu: () => {},
  isLoading: false,
  setIsLoading: () => {},
  showFilter: false,
  setShowFilter: () => {},
  action: '',
  setAction: () => {},
  setItemIdForUpdate: () => {},
  holdingOptions: undefined,
  setHoldingOptions: () => {},
  companyOptions: undefined,
  setCompanyOptions: () => {},
  brandOptions: undefined,
  setBrandOptions: () => {},
  outletOptions: undefined,
  setOutletOptions: () => {},
  categoriesOptions: undefined,
  setCategoriesOptions: () => {},
  isItemCategory: false,
  setIsItemCategory: () => {},
  categoryName: '',
  setCategoryName: () => {},
  itemName: '',
  setItemName: () => {},
}
