export type DataID = undefined | null | string

export type ListPaginationState = {
  page?: number
  items_per_page?: number
  menu_id?: string | null
}

export type ListSortState = {
  sort: string
  order: 'asc' | 'desc'
}

export type ListFilterState = {
  filter?: any
}

export type ListSearchState = {
  search?: any
}

export type ListMetaParentNameState = {
  name_eng?: string
  name_idn?: string
}

export type ListMetaParentListState = {
  parent_id?: string
  parent_name_eng?: string
  parent_name_idn?: string
}

export type ListPaginationDataFilterState = {
  metaParentName?: ListMetaParentNameState
  metaParentList?: Array<ListMetaParentListState>
}

export type ListResponse<T> = {
  data?: T
  meta?: {
    message?: string
    status?: boolean
    code?: number
    currentPage?: number
    prevPage?: number
    nextPage?: number
    perPage?: number
    totalCount?: number
    pageCount?: number
    dataFilter?: ListPaginationDataFilterState
  }
}

export type ListQueryState =
  | (ListPaginationState &
      ListSortState &
      ListFilterState &
      ListSearchState &
      ListPaginationDataFilterState)
  | undefined

export type ListQueryRequestContextProps = {
  state: ListQueryState
  updateState: (updates: Partial<ListQueryState>) => void
}

export const initialListQueryState: ListQueryState = {
  page: 1,
  items_per_page: 5,
  menu_id: '1',
  sort: 'for_order',
  order: 'asc',
}

export const initialListQueryRequest: ListQueryRequestContextProps = {
  state: initialListQueryState,
  updateState: () => {},
}

export type ListQueryResponseContextProps<T> = {
  response?: ListResponse<Array<T>> | undefined
  refetch: () => void
  isLoading: boolean
  query: string
  meta?: ListResponse<Array<T>> | undefined
  dataMeta?: ListResponse<Array<T>> | undefined
  lastUpdated?: any | undefined
}

export const initialListQueryResponse = {refetch: () => {}, isLoading: false, query: ''}
