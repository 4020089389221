/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {InputPicker} from 'rsuite'
import toast from 'react-hot-toast'

import {FilterAction, SearchComponent} from 'app/components'
import {removeAuth} from 'app/modules/auth'

import {useAction, useListQueryRequest, useListQueryResponse} from '../../providers'
import {stringifyListRequestQuery, triggerSyncToESB} from '../../helpers'

const ListFilter = () => {
  const intl = useIntl()
  const didRequest = useRef(false)
  const {state, updateState} = useListQueryRequest()
  const [query] = useState<string>(stringifyListRequestQuery(state))
  const {isLoading} = useListQueryResponse()
  const {setShowFilter, shiftDate} = useAction()
  const [isTriggerLoading, setIsTriggerLoading] = useState(false)
  const notif = `Periksa notifikasi untuk memeriksa status sinkronisasi`

  const optionStatus = [
    {
      value: 'null',
      label: intl.formatMessage({id: 'LABEL_ESB_SYNC_NULL'}),
    },
    {
      value: '00',
      label: intl.formatMessage({id: 'LABEL_ESB_SYNC_SUCCESS'}),
    },
    {
      value: '01',
      label: intl.formatMessage({id: 'LABEL_ESB_SYNC_ERROR'}),
    },
  ]
  const [status, setStatus] = useState(state?.filter !== undefined ? state?.filter.status : '')

  const resetData = () => {
    setStatus('')
    updateState({filter: undefined, shift_date: shiftDate})
  }

  const filterData = () => {
    updateState({
      shift_date: shiftDate,
      filter: {
        status,
      },
    })
    setShowFilter(false)
  }

  const triggerSyncESB = async () => {
    setIsTriggerLoading(true)

    // request add to API
    try {
      if (!didRequest.current) {
        const res = await triggerSyncToESB(shiftDate, query)
        if (res?.meta?.code !== 200) {
          if (res?.meta?.message === 'sync is already running') {
            toast.error(intl.formatMessage({id: 'RESPONSE_SYNC_TO_ESB_SYNC_ALREADY_RUNNING'}))
          }
        } else {
          toast.success(notif, {
            duration: 4e3,
          })
          // setItemIdForUpdate(undefined)
        }
      }
    } catch (error: any) {
      console.error(error)
      if (error.response.status === 401) {
        toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
        removeAuth()
      } else {
        toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
      }

      return false
    } finally {
      setTimeout(function () {
        setIsTriggerLoading(false)
      }, 3e3)
    }

    return () => (didRequest.current = true)
  }

  useEffect(() => {
    updateState({
      shift_date: shiftDate,
      filter: {
        status,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shiftDate])

  return (
    <>
      <div className='card-header border-0 mb-5'>
        <div className='card-body p-0 pt-5'>
          <div className='row mb-1'>
            {/* 1 Form */}
            <div className='col-lg-4'>
              <div className='fv-row mb-5'>
                <SearchComponent
                  useListQueryRequest={useListQueryRequest}
                  useListQueryResponse={useListQueryResponse}
                  labelUpper={intl.formatMessage({id: 'POS_REPORT_TRANSACTION_LIST_KEYWORD_LABEL'})}
                />
              </div>

              <div className='fv-row'>
                <button
                  type='button'
                  disabled={isLoading || isTriggerLoading}
                  onClick={() => triggerSyncESB()}
                  className='btn btn-secondary btn-active-info fw-bold me-2 px-6'
                  data-kt-menu-dismiss='true'
                >
                  {intl.formatMessage({id: 'BUTTON_LABEL_START_SYNCHRONIZED'})}

                  {isTriggerLoading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      {intl.formatMessage({id: 'BUTTON_LABEL_SUBMITTING'})}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
            {/* 1 Form */}

            {/* 2 Form */}
            <div className='col-lg-4'>
              {/* Input group */}
              <div className='fv-row mb-10'>
                <label className='form-label fs-6 fw-bold'>Status :</label>
                <InputPicker
                  block
                  data={optionStatus}
                  placeholder={`${intl.formatMessage({id: 'TOOLTIP_SELECT_ALL'})}`}
                  defaultValue={
                    optionStatus
                      ? (optionStatus as unknown as any[]).find((e: any) => e.value === status)
                      : ''
                  }
                  onChange={(e) => {
                    setStatus(e)
                  }}
                  value={status}
                />
              </div>
              {/* Input group */}
            </div>
            {/* 2 Form */}

            {/* 3 Form */}
            <div className='col-lg-4'>
              <div className='fv-row mb-5'></div>

              <div className='fv-row mb-5 d-flex justify-content-end'>
                <FilterAction
                  filterData={filterData}
                  isLoading={isLoading}
                  resetData={resetData}
                  setStatus={setStatus}
                />
              </div>
            </div>
            {/* 3 Form */}
          </div>
        </div>
      </div>
    </>
  )
}

export {ListFilter}
