import {FC, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {Cell, Column, HeaderCell, Table} from 'rsuite-table'
import {Loader, Placeholder} from 'rsuite'
import * as CurrencyFormat from 'react-currency-format'

import {useThemeMode} from '_metronic/partials'
import {isMobileDevice} from '_metronic/assets/ts/_utils'
import {
  RowStatusCellFormatter,
  RowTooltipCellFormatter,
  RowTooltipCountFormatter,
} from 'app/components'

import {ListActionFormatter} from './ListActionFormatter'
import {
  useListQueryRequest,
  useListQueryResponseLoading,
  useListQueryResponseData,
} from '../../providers'

const ListTable: FC = () => {
  const intl = useIntl()
  const isMobile = isMobileDevice()
  const {state, updateState} = useListQueryRequest()
  const isLoading = useListQueryResponseLoading()
  const users = useListQueryResponseData()
  const data = useMemo(() => users, [users])
  const [usePlaceholder] = useState(true)
  const [sortColumn, setSortColumn] = useState(state?.sort)
  const [sortType, setSortType] = useState(state?.order)
  const {mode} = useThemeMode()

  const sortData = () => {
    if (sortColumn && sortType) {
      return data.sort((a: any, b: any) => {
        let x = a[sortColumn]
        let y = b[sortColumn]

        if (sortType === 'asc') {
          return x - y
        } else {
          return y - x
        }
      })
    }
    return data
  }

  const handleSortColumn = (sortColumn: any, sortType: any) => {
    setSortColumn(sortColumn)
    setSortType(sortType)
    updateState({sort: sortColumn, order: sortType})
  }

  const renderLoading = () => {
    if (usePlaceholder) {
      return (
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            background: mode === 'light' ? '#fff' : '#252530',
            padding: 20,
            zIndex: 1,
          }}
        >
          <Placeholder.Grid rows={5} columns={5} active />
        </div>
      )
    }

    return <Loader center backdrop content={intl.formatMessage({id: 'BUTTON_LABEL_PLEASE_WAIT'})} />
  }

  return (
    <div>
      <Table
        // isTree
        // defaultExpandAllRows
        // rowKey='discount_id'
        // shouldUpdateScroll={true}
        wordWrap={isMobile ? true : false}
        bordered
        cellBordered
        loading={isLoading}
        height={450}
        headerHeight={70}
        autoHeight={isMobile ? true : false}
        affixHeader={50}
        affixHorizontalScrollbar
        data={sortData()}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
        renderLoading={isLoading ? renderLoading : undefined}
        locale={{emptyMessage: intl.formatMessage({id: 'LABEL_EMPTY_DATA'})}}
      >
        {/* Name */}
        <Column fixed={isMobile ? false : true} verticalAlign='middle' width={630}>
          <HeaderCell align='center'>
            <span
              className={`fw-bold fs-6 text-decoration-underline ${
                sortColumn === 'name' ? 'text-primary' : 'text-dark'
              }`}
            >
              {intl.formatMessage({id: 'LABEL_NAME'})}
            </span>
          </HeaderCell>
          <Cell dataKey='name'>
            {(rowData: any) =>
              rowData.name.length < 55 ? (
                rowData.name
              ) : (
                <RowTooltipCellFormatter text={rowData.name} length={54} />
              )
            }
          </Cell>
        </Column>

        {/* Amount */}
        <Column verticalAlign='middle' width={200}>
          <HeaderCell align='center'>
            <span
              className={`fw-bold fs-6 text-decoration-underline ${
                sortColumn === 'amount' ? 'text-primary' : 'text-dark'
              }`}
            >
              {intl.formatMessage({id: 'LABEL_DISCOUNT_AMOUNT'})}
            </span>
          </HeaderCell>
          <Cell dataKey='amount' align='center'>
            {(rowData: any) =>
              rowData.format === 'percentage' ? (
                <CurrencyFormat
                  value={rowData.amount}
                  displayType={'text'}
                  isNumericString={true}
                  decimalScale={2}
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  suffix=' %'
                />
              ) : (
                <CurrencyFormat
                  value={rowData.amount}
                  displayType={'text'}
                  isNumericString={true}
                  decimalScale={2}
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  prefix='Rp. '
                />
              )
            }
          </Cell>
        </Column>

        {/* Brand */}
        <Column verticalAlign='middle' width={200}>
          <HeaderCell align='center'>
            <span
              className={`fw-bold fs-6 text-decoration-underline ${
                sortColumn === 'brand_slug' ? 'text-primary' : 'text-dark'
              }`}
            >
              {intl.formatMessage({id: 'LABEL_BRAND'})}
            </span>
          </HeaderCell>
          <Cell dataKey='brand_slug' align='center'>
            {(rowData: any) =>
              rowData.brand_slug.length < 15 ? (
                rowData.brand_slug
              ) : (
                <span className='fw-bolder'>
                  <RowTooltipCellFormatter text={rowData.brand_slug} length={14} />
                </span>
              )
            }
          </Cell>
        </Column>

        {/* Count Outlet */}
        <Column verticalAlign='middle' width={120}>
          <HeaderCell align='center'>
            <span
              className={`fw-bold fs-6 text-decoration-underline ${
                sortColumn === 'c_outlet' ? 'text-primary' : 'text-dark'
              }`}
            >
              {intl.formatMessage({id: 'LABEL_COUNT_DISCOUNT_OUTLETS'})}
            </span>
          </HeaderCell>
          <Cell dataKey='c_outlet' align='center'>
            {(rowData: any) => (
              <RowTooltipCountFormatter
                count={rowData.c_outlet}
                data={rowData.outlets}
                id={rowData.discount_id}
              />
            )}
          </Cell>
        </Column>

        {/* Status */}
        <Column verticalAlign='middle' width={130}>
          <HeaderCell align='center'>
            <span
              className={`fw-bold fs-6 text-decoration-underline ${
                sortColumn === 'status' ? 'text-primary' : 'text-dark'
              }`}
            >
              Status
            </span>
          </HeaderCell>
          <Cell dataKey='status' align='center'>
            {(rowData: any) => <RowStatusCellFormatter row={rowData.status} />}
          </Cell>
        </Column>

        {/* Action */}
        <Column fixed='right' align='right' verticalAlign='middle' width={55}>
          <HeaderCell>
            <span className={`fw-bold fs-6 text-dark text-decoration-underline`}>
              {intl.formatMessage({id: 'LABEL_ACTION'})}
            </span>
          </HeaderCell>
          <Cell dataKey='action' style={{flexWrap: 'nowrap'}}>
            {(rowData: any) => (
              <ListActionFormatter discount_id={rowData.discount_id} status={rowData.status} />
            )}
          </Cell>
        </Column>
      </Table>
    </div>
  )
}

export {ListTable}
