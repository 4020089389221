import {FC} from 'react'
import {RowTooltipCellFormatter} from './RowTooltipCellFormatter'

type Props = {
  row?: string
  level?: number
}

function paddingName(level?: number, name?: string) {
  switch (level) {
    case 1:
      return name
    default:
      return name
  }
}

const RowCategoriesLevelCellFormatter: FC<Props> = ({row, level}) => {
  return (
    <div
      style={{
        paddingLeft:
          level === 1
            ? '1px'
            : level === 2
              ? '10px'
              : level === 3
                ? '20px'
                : level === 4
                  ? '30px'
                  : '50px',
      }}
    >
      {row && row.length < 45 ? (
        paddingName(level, row)
      ) : (
        <RowTooltipCellFormatter text={paddingName(level, row)} length={44} />
      )}
    </div>
  )
}

export {RowCategoriesLevelCellFormatter}
