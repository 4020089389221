import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import toast from 'react-hot-toast'
import {useIntl} from 'react-intl'

import {WithChildren} from '_metronic/helpers'
import {LayoutSplashScreen} from '_metronic/layout/core'
import {AuthModel, UserModel} from './_models'
import {getUserByToken} from './_requests'
import * as authHelper from './AuthHelpers'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
  }

  return (
    <AuthContext.Provider value={{auth, saveAuth, currentUser, setCurrentUser, logout}}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const intl = useIntl()
  const {auth, logout, setCurrentUser} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)

  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          try {
            const res = await getUserByToken()
            // throw message error
            if (res.data.meta.code !== 200) {
              toast.error(intl.formatMessage({id: 'RESPONSE_AUTH_USER_IS_INVALID'}))
            } else {
              // set current user
              setCurrentUser(res.data.data)
              toast.success(intl.formatMessage({id: 'RESPONSE_AUTHORIZED'}))
            }
          } catch (error) {
            console.error(error)
            toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
          toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth.auth_token) {
      requestUser()
    } else {
      toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
