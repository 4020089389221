import {FC, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {Cell, Column, HeaderCell, Table} from 'rsuite-table'
import {Loader, Placeholder} from 'rsuite'

import {useLang} from '_metronic/i18n/Metronici18n'
import {isMobileDevice} from '_metronic/assets/ts/_utils'
import {useThemeMode} from '_metronic/partials'
import {RowDatetimeCellFormatter, RowTooltipCellFormatter} from 'app/components'

import {ListActionFormatter} from './ListActionFormatter'
import {
  useListQueryRequest,
  useListQueryResponseLoading,
  useListQueryResponseData,
} from '../../providers'

const ListTable: FC = () => {
  const intl = useIntl()
  const local = useLang()
  const isMobile = isMobileDevice()
  const {state, updateState} = useListQueryRequest()
  const isLoading = useListQueryResponseLoading()
  const users = useListQueryResponseData()
  const data = useMemo(() => users, [users])
  const [usePlaceholder] = useState(true)
  const [sortColumn, setSortColumn] = useState(state?.sort)
  const [sortType, setSortType] = useState(state?.order)
  const {mode} = useThemeMode()

  const sortData = () => {
    if (sortColumn && sortType) {
      return data.sort((a: any, b: any) => {
        let x = a[sortColumn]
        let y = b[sortColumn]

        if (sortType === 'asc') {
          return x - y
        } else {
          return y - x
        }
      })
    }
    return data
  }

  const handleSortColumn = (sortColumn: any, sortType: any) => {
    setSortColumn(sortColumn)
    setSortType(sortType)
    updateState({sort: sortColumn, order: sortType})
  }

  const renderLoading = () => {
    if (usePlaceholder) {
      return (
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            background: mode === 'light' ? '#fff' : '#252530',
            padding: 20,
            zIndex: 1,
          }}
        >
          <Placeholder.Grid rows={5} columns={6} active />
        </div>
      )
    }

    return <Loader center backdrop content={intl.formatMessage({id: 'BUTTON_LABEL_PLEASE_WAIT'})} />
  }

  return (
    <div>
      <Table
        wordWrap={isMobile ? true : false}
        bordered
        cellBordered
        loading={isLoading}
        height={450}
        headerHeight={70}
        autoHeight={isMobile ? true : false}
        affixHeader={50}
        affixHorizontalScrollbar
        data={sortData()}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
        renderLoading={isLoading ? renderLoading : undefined}
        locale={{emptyMessage: intl.formatMessage({id: 'LABEL_EMPTY_DATA'})}}
      >
        {/* Datetime */}
        <Column fixed={isMobile ? false : true} sortable verticalAlign='middle' width={400}>
          <HeaderCell align='center'>
            <span
              className={`fw-bold fs-6 text-decoration-underline ${
                sortColumn === 'datetime' ? 'text-primary' : 'text-dark'
              }`}
            >
              {intl.formatMessage({id: 'LABEL_DATETIME'})}
            </span>
          </HeaderCell>
          <Cell dataKey='datetime'>
            {(rowData: any) => <RowDatetimeCellFormatter datetime={rowData.datetime} />}
          </Cell>
        </Column>

        {/* Module & Menu*/}
        {local === 'id' ? (
          <>
            <Column sortable verticalAlign='middle' width={225}>
              <HeaderCell align='center'>
                <span
                  className={`fw-bold fs-6 text-decoration-underline ${
                    sortColumn === 'module_idn' ? 'text-primary' : 'text-dark'
                  }`}
                >
                  {intl.formatMessage({id: 'LABEL_MODULE'})}
                </span>
              </HeaderCell>
              <Cell dataKey='module_idn' align='center'>
                {(rowData: any) =>
                  rowData.module_idn.length < 30 ? (
                    rowData.module_idn
                  ) : (
                    <RowTooltipCellFormatter text={rowData.module_idn} length={29} />
                  )
                }
              </Cell>
            </Column>
            <Column sortable verticalAlign='middle' width={225}>
              <HeaderCell align='center'>
                <span
                  className={`fw-bold fs-6 text-decoration-underline ${
                    sortColumn === 'menu_idn' ? 'text-primary' : 'text-dark'
                  }`}
                >
                  {intl.formatMessage({id: 'LABEL_MENU'})}
                </span>
              </HeaderCell>
              <Cell dataKey='menu_idn' align='center'>
                {(rowData: any) =>
                  rowData.menu_idn.length < 30 ? (
                    rowData.menu_idn
                  ) : (
                    <RowTooltipCellFormatter text={rowData.menu_idn} length={29} />
                  )
                }
              </Cell>
            </Column>
          </>
        ) : (
          <>
            <Column sortable verticalAlign='middle' width={225}>
              <HeaderCell align='center'>
                <span
                  className={`fw-bold fs-6 text-decoration-underline ${
                    sortColumn === 'module_eng' ? 'text-primary' : 'text-dark'
                  }`}
                >
                  {intl.formatMessage({id: 'LABEL_MODULE'})}
                </span>
              </HeaderCell>
              <Cell dataKey='module_eng' align='center'>
                {(rowData: any) =>
                  rowData.module_eng.length < 30 ? (
                    rowData.module_eng
                  ) : (
                    <RowTooltipCellFormatter text={rowData.module_eng} length={29} />
                  )
                }
              </Cell>
            </Column>
            <Column sortable verticalAlign='middle' width={225}>
              <HeaderCell align='center'>
                <span
                  className={`fw-bold fs-6 text-decoration-underline ${
                    sortColumn === 'menu_eng' ? 'text-primary' : 'text-dark'
                  }`}
                >
                  {intl.formatMessage({id: 'LABEL_MENU'})}
                </span>
              </HeaderCell>
              <Cell dataKey='menu_eng' align='center'>
                {(rowData: any) =>
                  rowData.menu_eng.length < 30 ? (
                    rowData.menu_eng
                  ) : (
                    <RowTooltipCellFormatter text={rowData.menu_eng} length={29} />
                  )
                }
              </Cell>
            </Column>
          </>
        )}

        {/* Action Log */}
        <Column sortable verticalAlign='middle' width={150}>
          <HeaderCell align='center'>
            <span
              className={`fw-bold fs-6 text-decoration-underline ${
                sortColumn === 'activity' ? 'text-primary' : 'text-dark'
              }`}
            >
              {intl.formatMessage({id: 'LABEL_ACTION'})}
            </span>
          </HeaderCell>
          <Cell dataKey='activity' align='center'>
            {(rowData: any) =>
              rowData.activity.length < 25 ? (
                rowData.activity
              ) : (
                <RowTooltipCellFormatter text={rowData.activity} length={24} />
              )
            }
          </Cell>
        </Column>

        {/* User */}
        <Column sortable verticalAlign='middle' width={250}>
          <HeaderCell align='center'>
            <span
              className={`fw-bold fs-6 text-decoration-underline ${
                sortColumn === 'user' ? 'text-primary' : 'text-dark'
              }`}
            >
              {intl.formatMessage({id: 'LABEL_USERNAME'})}
            </span>
          </HeaderCell>
          <Cell dataKey='user' align='center'>
            {(rowData: any) =>
              rowData.user.length < 15 ? (
                rowData.user
              ) : (
                <RowTooltipCellFormatter text={rowData.user} length={14} />
              )
            }
          </Cell>
        </Column>

        {/* Action */}
        <Column fixed='right' align='right' verticalAlign='middle' width={75}>
          <HeaderCell>
            <span className={`fw-bold fs-6 text-dark text-decoration-underline`}>
              {intl.formatMessage({id: 'LABEL_DETAIL'})}
            </span>
          </HeaderCell>
          <Cell dataKey='action' style={{flexWrap: 'nowrap'}}>
            {(rowData: any) => <ListActionFormatter log_id={rowData.log_id} />}
          </Cell>
        </Column>
      </Table>
    </div>
  )
}

export {ListTable}
