import {FC} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'

import {WithChildren, checkIsActive, KTIcon} from '_metronic/helpers'
import {useLayout} from '_metronic/layout/core'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  idx: string
  moduleID?: string
  siteID?: string
  menuID?: string
  parentMenuID?: string
}

const SidebarSubModuleMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
  idx,
  moduleID,
  siteID,
  menuID,
  parentMenuID,
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const {config} = useLayout()
  const {app} = config

  return (
    <div className='menu-item' key={idx}>
      <Link
        className={clsx('menu-link without-sub', {active: isActive})}
        to={to}
        state={{
          menu_id: menuID,
          parent_menu_id: parentMenuID,
          menu_name: title,
          module_id: moduleID,
          site_id: siteID,
          path: to,
        }}
      >
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
          <span className='menu-icon'>
            <KTIcon iconName={icon} className='fs-2 fw-bolder' />
          </span>
        )}
        {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
          <i className={clsx('bi fs-3 fw-bolder', fontIcon)}></i>
        )}
        <span className={clsx('menu-title', {'fw-bolder': isActive})}>{title}</span>
      </Link>
      {children}
    </div>
  )
}

export {SidebarSubModuleMenuItem}
