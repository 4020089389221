/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {Dialog} from '@headlessui/react'
import clsx from 'clsx'

import {FormLog, NotAuthRedirectForm, SpinnerIndicator, getFormAction} from 'app/components'
import {getAuth, removeAuth, useAuth} from 'app/modules/auth'

import {DetailModel, addData, deleteData, initialData, updateData} from '../../helpers'
import {useAction, useListQueryResponse} from '../../providers'
import {Drawer, Toggle} from 'rsuite'

type Props = {
  isLoading: boolean
  holding: DetailModel
  appMenuID: any
  modalID: any
  menuName: any
}

const ViewDrawerForm: FC<Props> = ({menuName, holding, isLoading, appMenuID, modalID}) => {
  const auth = getAuth()
  const intl = useIntl()
  const [tab, setTab] = useState('data')
  const {currentUser} = useAuth()
  const {setItemIdForUpdate, action} = useAction()
  const {refetch} = useListQueryResponse()
  const [isAuth, setIsAuth] = useState(true)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [completed, setCompleted] = useState(false)

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(5, intl.formatMessage({id: 'VALIDATION_MIN_LENGTH_FIELD'}, {min: 5}))
      .max(100, intl.formatMessage({id: 'VALIDATION_MAX_LENGTH_FIELD'}, {max: 100}))
      .required(
        intl.formatMessage(
          {id: 'VALIDATION_REQUIRED_FIELD'},
          {name: intl.formatMessage({id: 'LABEL_NAME'})}
        )
      ),
    slug: Yup.string()
      .min(2, intl.formatMessage({id: 'VALIDATION_MIN_LENGTH_FIELD'}, {min: 2}))
      .max(10, intl.formatMessage({id: 'VALIDATION_MAX_LENGTH_FIELD'}, {max: 10}))
      .required(
        intl.formatMessage(
          {id: 'VALIDATION_REQUIRED_FIELD'},
          {name: intl.formatMessage({id: 'LABEL_SLUG'})}
        )
      ),
  })

  const [models] = useState<DetailModel>({
    ...holding,
    holding_id: holding.holding_id || initialData.holding_id,
    name: holding.name || initialData.name,
    slug: holding.slug || initialData.slug,
    email: holding.email || initialData.email,
    description: holding.description || initialData.description,
    status: holding.status || initialData.status,
    created_at: holding.created_at || initialData.created_at,
    created_by: holding.created_by || initialData.created_by,
    updated_at: holding.updated_at || initialData.updated_at,
    updated_by: holding.updated_by || initialData.updated_by,
    menu_id: appMenuID,
    creator_id: currentUser?.user_id,
    creator_by: currentUser?.username,
  })

  const formik = useFormik({
    initialValues: models,
    validationSchema: validationSchema,
    onSubmit: async (values, {setSubmitting, setStatus, setFieldError}) => {
      setIsDialogOpen(false)
      action === 'edit'
        ? requestUpdateData(values, setStatus, setSubmitting, setFieldError)
        : action === 'add'
          ? requestAddData(values, setStatus, setSubmitting, setFieldError)
          : requestDeleteData(values, setStatus, setSubmitting)
    },
    onReset: (values) => {
      values = models
    },
  })

  const requestUpdateData = async (
    values: any,
    setStatus: any,
    setSubmitting: any,
    setFieldError: any
  ) => {
    setLoading(true)
    setStatus(null)
    setSubmitting(true)

    // request update to API
    try {
      const res = await updateData(values)

      // throw message error
      if (res?.meta.code !== 200) {
        if (res?.meta.message === 'name already used') {
          setStatus(intl.formatMessage({id: 'RESPONSE_MASTER_HOLDING_NAME_ALREADY_USED'}))
          setFieldError(
            'name',
            intl.formatMessage({id: 'RESPONSE_MASTER_HOLDING_NAME_ALREADY_USED'})
          )
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
        if (res?.meta.message === 'slug already used') {
          setStatus(intl.formatMessage({id: 'RESPONSE_MASTER_HOLDING_SLUG_ALREADY_USED'}))
          setFieldError(
            'slug',
            intl.formatMessage({id: 'RESPONSE_MASTER_HOLDING_SLUG_ALREADY_USED'})
          )
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
        if (res?.meta.message === 'email already used') {
          setStatus(intl.formatMessage({id: 'RESPONSE_MASTER_HOLDING_EMAIL_ALREADY_USED'}))
          setFieldError(
            'email',
            intl.formatMessage({id: 'RESPONSE_MASTER_HOLDING_EMAIL_ALREADY_USED'})
          )
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_MASTER_HOLDING_EDIT_SUCCESS'}))
        setCompleted(true)
        setTimeout(function () {
          setItemIdForUpdate(undefined)
        }, 1e3)
        refetch()
      }
    } catch (error: any) {
      console.error(error)
      if (error.response.status === 401) {
        setStatus(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
        removeAuth()
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
      }
      setSubmitting(false)
      setLoading(false)
      setTimeout(function () {
        setStatus(null)
      }, 9e3)

      return false
    } finally {
      setTimeout(() => {
        setLoading(false)
        setSubmitting(false)
      }, 5e2)
    }
  }
  const requestAddData = async (
    values: any,
    setStatus: any,
    setSubmitting: any,
    setFieldError: any
  ) => {
    setLoading(true)
    setStatus(null)
    setSubmitting(true)

    // request add to API
    try {
      const res = await addData(values)

      // throw message error
      if (res?.meta.code !== 200) {
        if (res?.meta.message === 'name already used') {
          setStatus(intl.formatMessage({id: 'RESPONSE_MASTER_HOLDING_NAME_ALREADY_USED'}))
          setFieldError(
            'name',
            intl.formatMessage({id: 'RESPONSE_MASTER_HOLDING_NAME_ALREADY_USED'})
          )
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
        if (res?.meta.message === 'slug already used') {
          setStatus(intl.formatMessage({id: 'RESPONSE_MASTER_HOLDING_SLUG_ALREADY_USED'}))
          setFieldError(
            'slug',
            intl.formatMessage({id: 'RESPONSE_MASTER_HOLDING_SLUG_ALREADY_USED'})
          )
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
        if (res?.meta.message === 'email already used') {
          setStatus(intl.formatMessage({id: 'RESPONSE_MASTER_HOLDING_EMAIL_ALREADY_USED'}))
          setFieldError(
            'email',
            intl.formatMessage({id: 'RESPONSE_MASTER_HOLDING_EMAIL_ALREADY_USED'})
          )
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_MASTER_HOLDING_ADD_SUCCESS'}))
        setCompleted(true)
        setTimeout(function () {
          setItemIdForUpdate(undefined)
        }, 1e3)
        refetch()
      }
    } catch (error: any) {
      console.error(error)
      if (error.response.status === 401) {
        setStatus(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
        removeAuth()
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
      }
      setSubmitting(false)
      setLoading(false)
      setTimeout(function () {
        setStatus(null)
      }, 9e3)

      return false
    } finally {
      setTimeout(() => {
        setLoading(false)
        setSubmitting(false)
      }, 5e2)
    }
  }
  const requestDeleteData = async (values: any, setStatus: any, setSubmitting: any) => {
    setLoading(true)
    setStatus(null)
    setSubmitting(true)

    // request delete to API
    try {
      const res = await deleteData(
        values.holding_id,
        values.menu_id,
        values.creator_id,
        values.creator_by
      )

      // throw message error
      if (res?.meta.code !== 200) {
        if (res?.meta.message === 'not inactive') {
          setStatus(intl.formatMessage({id: 'RESPONSE_MASTER_HOLDING_IS_ACTIVE'}))
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
        if (res?.meta.message === 'inused by other data') {
          setStatus(intl.formatMessage({id: 'RESPONSE_MASTER_HOLDING_IS_INUSED'}))
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_MASTER_HOLDING_DELETE_SUCCESS'}))
        setCompleted(true)
        setTimeout(function () {
          setItemIdForUpdate(undefined)
        }, 1e3)
        refetch()
      }
    } catch (error: any) {
      console.error(error)
      if (error.response.status === 401) {
        setStatus(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
        removeAuth()
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
      }
      setSubmitting(false)
      setLoading(false)
      setTimeout(function () {
        setStatus(null)
      }, 9e3)

      return false
    } finally {
      setTimeout(() => {
        setLoading(false)
        setSubmitting(false)
      }, 5e2)
    }
  }

  const handleSlugKeyUp = (e: any) => {
    formik.setFieldValue('slug', e.target.value.toUpperCase())
  }

  useEffect(() => {
    auth === undefined && setIsAuth(false)
  }, [auth])

  return (
    <>
      {!isAuth && <NotAuthRedirectForm />}

      <Drawer.Header>
        <Drawer.Title className='fw-bolder'>
          {isLoading ? (
            <SpinnerIndicator label={intl.formatMessage({id: 'BUTTON_LABEL_PLEASE_WAIT'})} />
          ) : (
            <>
              {getFormAction(
                action,
                intl.formatMessage({id: 'BUTTON_LABEL_VIEW'}),
                intl.formatMessage({id: 'BUTTON_LABEL_EDIT'}),
                intl.formatMessage({id: 'BUTTON_LABEL_ADD'}),
                intl.formatMessage({id: 'BUTTON_LABEL_DELETE'})
              )}{' '}
              {menuName}
            </>
          )}
        </Drawer.Title>
        <Drawer.Actions>
          {action !== 'view' && models !== formik.values && !completed && (
            <button
              type='reset'
              className='btn btn-light me-3'
              data-kt-users-modal-action='reset'
              onClick={formik.handleReset}
              disabled={loading}
            >
              {!loading && (
                <span className='indicator-label'>
                  {intl.formatMessage({id: 'BUTTON_LABEL_RESET'})}
                </span>
              )}
            </button>
          )}

          {action !== 'view' && models !== formik.values && !completed && (
            <button
              type='submit'
              className='btn btn-primary'
              data-kt-users-modal-action='submit'
              onClick={() => {
                setIsDialogOpen(true)
                setLoading(true)
                setTimeout(function () {
                  setLoading(false)
                }, 2e3)
              }}
              disabled={isLoading || loading || formik.isSubmitting || !formik.isValid}
            >
              {!loading && (
                <span className='indicator-label'>
                  {intl.formatMessage({id: 'BUTTON_LABEL_SUBMIT'})}
                </span>
              )}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {intl.formatMessage({id: 'BUTTON_LABEL_SUBMITTING'})}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          )}

          {action === 'delete' && !completed && (
            <button
              type='submit'
              className='btn btn-danger'
              data-kt-users-modal-action='submit'
              onClick={() => formik.handleSubmit()}
              disabled={isLoading || loading || formik.isSubmitting || !formik.isValid}
            >
              {!loading && (
                <span className='indicator-label'>
                  {intl.formatMessage({id: 'BUTTON_LABEL_DELETE'})}
                </span>
              )}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {intl.formatMessage({id: 'BUTTON_LABEL_SUBMITTING'})}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          )}
        </Drawer.Actions>
      </Drawer.Header>

      <Drawer.Body style={{padding: 0}}>
        {/* Response Message */}
        {formik.status &&
          (formik.status === intl.formatMessage({id: 'RESPONSE_MASTER_HOLDING_ADD_SUCCESS'}) ||
          formik.status === intl.formatMessage({id: 'RESPONSE_MASTER_HOLDING_EDIT_SUCCESS'}) ||
          formik.status === intl.formatMessage({id: 'RESPONSE_MASTER_HOLDING_DELETE_SUCCESS'}) ? (
            <div className='m-lg-5 p-2 bg-light-success rounded'>
              <div className='text-success text-center font-weight-bold fw-bolder'>
                {formik.status}
              </div>
            </div>
          ) : (
            <div className={`m-lg-5 p-2 alert alert-danger`}>
              <div className='alert-text text-center font-weight-bold fw-bolder'>
                {formik.status}
              </div>
            </div>
          ))}
        {/* Response Message */}

        {/* Form */}
        <div className={`modal-body m-5 ${action === 'add' ? '' : ' mt-0'}`}>
          {action === 'add' ? (
            <form id={`${modalID}-form`} className='form' onSubmit={formik.handleSubmit} noValidate>
              {/* Name */}
              <div className='fv-row mb-5'>
                {formik.values.name !== '' && (
                  <label className='form-label fs-6 fw-bolder text-dark required'>
                    {intl.formatMessage({id: 'LABEL_NAME'})}
                  </label>
                )}
                <input
                  type='text'
                  className={clsx(
                    'form-control form-control-solid',
                    {'is-invalid': formik.touched.name && formik.errors.name},
                    {
                      'is-valid': formik.touched.name && !formik.errors.name,
                    }
                  )}
                  placeholder={intl.formatMessage({id: 'LABEL_NAME'})}
                  {...formik.getFieldProps('name')}
                />
                {formik.touched.name && formik.errors.name ? (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block text-danger fw-bolder'>{formik.errors.name}</div>
                  </div>
                ) : null}
              </div>
              {/* Name */}

              {/* Slug */}
              <div className='fv-row mb-5'>
                {formik.values.slug !== '' && (
                  <label className='form-label fs-6 fw-bolder text-dark required'>
                    {intl.formatMessage({id: 'LABEL_SLUG'})}
                  </label>
                )}
                <input
                  type='text'
                  className={clsx(
                    'form-control form-control-solid',
                    {'is-invalid': formik.touched.slug && formik.errors.slug},
                    {
                      'is-valid': formik.touched.slug && !formik.errors.slug,
                    }
                  )}
                  placeholder={intl.formatMessage({id: 'LABEL_SLUG'})}
                  {...formik.getFieldProps('slug')}
                  onKeyUp={handleSlugKeyUp}
                />
                {formik.touched.slug && formik.errors.slug ? (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block text-danger fw-bolder'>{formik.errors.slug}</div>
                  </div>
                ) : null}
              </div>
              {/* Slug */}

              {/* Email */}
              <div className='fv-row mb-5'>
                {formik.values.email !== '' && (
                  <label className='form-label fs-6 fw-bolder text-dark'>
                    {intl.formatMessage({id: 'LABEL_EMAIL'})}
                  </label>
                )}
                <input
                  type='text'
                  className={clsx(
                    'form-control form-control-solid',
                    {'is-invalid': formik.touched.email && formik.errors.email},
                    {
                      'is-valid': formik.touched.email && !formik.errors.email,
                    }
                  )}
                  placeholder={intl.formatMessage({id: 'LABEL_EMAIL'})}
                  {...formik.getFieldProps('email')}
                />
              </div>
              {/* Email */}

              {/* Description */}
              <div className='fv-row mb-5'>
                {formik.values.description !== '' && (
                  <label className='form-label fs-6 fw-bolder text-dark'>
                    {intl.formatMessage({id: 'LABEL_DESCRIPTION'})}
                  </label>
                )}
                <textarea
                  className={clsx(
                    'form-control form-control-solid',
                    {'is-invalid': formik.touched.description && formik.errors.description},
                    {
                      'is-valid': formik.touched.description && !formik.errors.description,
                    }
                  )}
                  placeholder={intl.formatMessage({id: 'LABEL_DESCRIPTION'})}
                  {...formik.getFieldProps('description')}
                  rows={3}
                />
              </div>
              {/* Description */}

              {/* Status */}
              <div className='fv-row mb-5'>
                <label className='form-label fs-6 fw-bolder text-dark required'>Status</label>
                <div className='form-check form-switch form-check-custom form-check-solid mt-1'>
                  <Toggle
                    size='lg'
                    checkedChildren={intl.formatMessage({id: 'LABEL_ACTIVE'})}
                    unCheckedChildren={intl.formatMessage({id: 'LABEL_INACTIVE'})}
                    checked={formik.values.status === 't' ? true : false}
                    onChange={() =>
                      formik.setFieldValue('status', formik.values.status === 't' ? 'f' : 't')
                    }
                  />
                </div>
              </div>
              {/* Status */}
            </form>
          ) : (
            <form id={`${modalID}-form`} className='form' onSubmit={formik.handleSubmit} noValidate>
              {/* Tab Header */}
              <div className='card-toolbar'>
                <ul
                  className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-2 mt-2'
                  role='tablist'
                >
                  <li className={`nav-item ${tab === 'data' ? 'fw-bolder' : ''}`}>
                    <a
                      className={clsx(`nav-link cursor-pointer`, {
                        active: tab === 'data',
                      })}
                      onClick={() => setTab('data')}
                      role='tab'
                    >
                      Data
                    </a>
                  </li>
                  <li className={`nav-item ${tab === 'log' ? 'fw-bolder' : ''}`}>
                    <a
                      className={clsx(`nav-link cursor-pointer`, {
                        active: tab === 'log',
                      })}
                      onClick={() => setTab('log')}
                      role='tab'
                    >
                      Log
                    </a>
                  </li>
                </ul>
              </div>
              {/* Tab Header */}

              {/* Tab Body */}
              <div className='card-body p-0 pt-2'>
                <div className='tab-content'>
                  {/* Data */}
                  <div
                    className={clsx('tab-pane', {
                      active: tab === 'data',
                    })}
                  >
                    <div className='row'>
                      {/* Name */}
                      <div className='fv-row mb-5'>
                        {formik.values.name !== '' && (
                          <label
                            className={`form-label fs-6 fw-bolder text-dark ${
                              action === 'edit' ? 'required' : ''
                            }`}
                          >
                            {intl.formatMessage({id: 'LABEL_NAME'})}
                          </label>
                        )}
                        <input
                          type='text'
                          className={clsx(
                            'form-control form-control-solid',
                            {'is-invalid': formik.touched.name && formik.errors.name},
                            {
                              'is-valid': formik.touched.name && !formik.errors.name,
                            }
                          )}
                          placeholder={intl.formatMessage({id: 'LABEL_NAME'})}
                          {...formik.getFieldProps('name')}
                          readOnly={action === 'edit' ? false : true}
                        />
                        {formik.touched.name && formik.errors.name ? (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block text-danger fw-bolder'>
                              {formik.errors.name}
                            </div>
                          </div>
                        ) : null}
                      </div>
                      {/* Name */}

                      {/* Slug */}
                      <div className='fv-row mb-5'>
                        {formik.values.slug !== '' && (
                          <label
                            className={`form-label fs-6 fw-bolder text-dark ${
                              action === 'edit' ? 'required' : ''
                            }`}
                          >
                            {intl.formatMessage({id: 'LABEL_SLUG'})}
                          </label>
                        )}
                        <input
                          type='text'
                          className={clsx(
                            'form-control form-control-solid',
                            {'is-invalid': formik.touched.slug && formik.errors.slug},
                            {
                              'is-valid': formik.touched.slug && !formik.errors.slug,
                            }
                          )}
                          placeholder={intl.formatMessage({id: 'LABEL_SLUG'})}
                          {...formik.getFieldProps('slug')}
                          readOnly={action === 'edit' ? false : true}
                          onKeyUp={handleSlugKeyUp}
                        />
                        {formik.touched.slug && formik.errors.slug ? (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block text-danger fw-bolder'>
                              {formik.errors.slug}
                            </div>
                          </div>
                        ) : null}
                      </div>
                      {/* Slug */}

                      {/* Email */}
                      <div className='fv-row mb-5'>
                        {formik.values.email !== '' && (
                          <label
                            className={`form-label fs-6 fw-bolder text-dark ${
                              action === 'edit' ? 'required' : ''
                            }`}
                          >
                            {intl.formatMessage({id: 'LABEL_EMAIL'})}
                          </label>
                        )}
                        <input
                          type='text'
                          className={clsx(
                            'form-control form-control-solid',
                            {'is-invalid': formik.touched.email && formik.errors.email},
                            {
                              'is-valid': formik.touched.email && !formik.errors.email,
                            }
                          )}
                          placeholder={intl.formatMessage({id: 'LABEL_EMAIL'})}
                          {...formik.getFieldProps('email')}
                          readOnly={action === 'edit' ? false : true}
                        />
                      </div>
                      {/* Email */}

                      {/* Description */}
                      <div className='fv-row mb-5'>
                        {formik.values.description !== '' && (
                          <label className='form-label fs-6 fw-bolder text-dark'>
                            {intl.formatMessage({id: 'LABEL_DESCRIPTION'})}
                          </label>
                        )}
                        <textarea
                          className={clsx(
                            'form-control form-control-solid',
                            {'is-invalid': formik.touched.description && formik.errors.description},
                            {
                              'is-valid': formik.touched.description && !formik.errors.description,
                            }
                          )}
                          placeholder={intl.formatMessage({id: 'LABEL_DESCRIPTION'})}
                          {...formik.getFieldProps('description')}
                          readOnly={action === 'edit' ? false : true}
                          rows={3}
                        />
                      </div>
                      {/* Description */}

                      {/* Status */}
                      <div className='fv-row mb-5'>
                        {formik.values.status !== '' && (
                          <label
                            className={`form-label fs-6 fw-bolder text-dark ${
                              action === 'edit' ? 'required' : ''
                            }`}
                          >
                            Status
                          </label>
                        )}
                        <div className='form-check form-switch form-check-custom form-check-solid mt-1'>
                          <Toggle
                            size='lg'
                            readOnly={action !== 'edit' ? true : false}
                            checkedChildren={intl.formatMessage({id: 'LABEL_ACTIVE'})}
                            unCheckedChildren={intl.formatMessage({id: 'LABEL_INACTIVE'})}
                            checked={formik.values.status === 't' ? true : false}
                            onChange={() =>
                              formik.setFieldValue(
                                'status',
                                formik.values.status === 't' ? 'f' : 't'
                              )
                            }
                          />
                        </div>
                        {formik.touched.status && formik.errors.status ? (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block text-danger fw-bolder'>
                              {formik.errors.status}
                            </div>
                          </div>
                        ) : null}
                      </div>
                      {/* Status */}
                    </div>
                  </div>
                  {/* Data */}

                  {/* Detail */}
                  <div
                    className={clsx('tab-pane', {
                      active: tab === 'log',
                    })}
                  >
                    <FormLog payload={holding} formik={formik} />
                  </div>
                  {/* Detail */}
                </div>
              </div>
              {/* Tab Body */}
            </form>
          )}
        </div>
        {/* Form */}
      </Drawer.Body>

      {/* Dialog */}
      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(true)}
        className='modal fade show d-block'
      >
        <Dialog.Panel className='modal-dialog modal-fullscreen'>
          <div className='modal-content'>
            <Dialog.Title className='modal-header'>
              {intl.formatMessage({id: 'DIALOG_HEADER_TITLE'})}
            </Dialog.Title>
            <div className='modal-body scroll-y mx-2 mx-xl-2 my-2'>
              {/* Name */}
              <div className='fv-row mb-5'>
                <label className='form-label fs-6 fw-bolder text-dark'>
                  {intl.formatMessage({id: 'LABEL_NAME'})}
                </label>
                {models.name !== formik.values.name ? (
                  <>
                    {action !== 'add' && (
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        value={models.name}
                        readOnly
                      />
                    )}
                    <input
                      type='text'
                      className='form-control form-control-solid text-primary is-valid'
                      value={formik.values.name}
                      readOnly
                    />
                  </>
                ) : (
                  <input
                    type='text'
                    className='form-control form-control-solid'
                    value={models.name}
                    readOnly
                  />
                )}
              </div>
              {/* Name */}

              {/* Slug */}
              <div className='fv-row mb-5'>
                <label className='form-label fs-6 fw-bolder text-dark'>
                  {intl.formatMessage({id: 'LABEL_SLUG'})}
                </label>
                {models.slug !== formik.values.slug ? (
                  <>
                    {action !== 'add' && (
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        value={models.slug}
                        readOnly
                      />
                    )}
                    <input
                      type='text'
                      className='form-control form-control-solid text-primary is-valid'
                      value={formik.values.slug}
                      readOnly
                    />
                  </>
                ) : (
                  <input
                    type='text'
                    className='form-control form-control-solid'
                    value={models.slug}
                    readOnly
                  />
                )}
              </div>
              {/* Slug */}

              {/* Email */}
              <div className='fv-row mb-5'>
                <label className='form-label fs-6 fw-bolder text-dark'>
                  {intl.formatMessage({id: 'LABEL_EMAIL'})}
                </label>
                {models.email !== formik.values.email ? (
                  <>
                    {action !== 'add' && (
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        value={models.email}
                        readOnly
                      />
                    )}
                    <input
                      type='text'
                      className='form-control form-control-solid text-primary is-valid'
                      value={formik.values.email}
                      readOnly
                    />
                  </>
                ) : (
                  <input
                    type='text'
                    className='form-control form-control-solid'
                    value={models.email}
                    readOnly
                  />
                )}
              </div>
              {/* Email */}

              {/* Description */}
              <div className='fv-row mb-5'>
                <label className='form-label fs-6 fw-bolder text-dark'>
                  {intl.formatMessage({id: 'LABEL_DESCRIPTION'})}
                </label>
                {models.description !== formik.values.description ? (
                  <>
                    {action !== 'add' && (
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        value={models.description}
                        readOnly
                      />
                    )}
                    <input
                      type='text'
                      className='form-control form-control-solid text-primary is-valid'
                      value={formik.values.description}
                      readOnly
                    />
                  </>
                ) : (
                  <input
                    type='text'
                    className='form-control form-control-solid'
                    value={models.description}
                    readOnly
                  />
                )}
              </div>
              {/* Description */}

              {/* Status */}
              <div className='fv-row mb-5'>
                <label className='form-label fs-6 fw-bolder text-dark'>
                  <label className='form-label fs-6 fw-bolder text-dark'>Status</label>
                </label>
                {models.status !== formik.values.status ? (
                  <>
                    {action !== 'add' && (
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        value={
                          models.status === 't'
                            ? intl.formatMessage({id: 'LABEL_ACTIVE'})
                            : intl.formatMessage({id: 'LABEL_INACTIVE'})
                        }
                        readOnly
                      />
                    )}
                    <input
                      type='text'
                      className='form-control form-control-solid text-primary is-valid'
                      value={
                        formik.values.status === 't'
                          ? intl.formatMessage({id: 'LABEL_ACTIVE'})
                          : intl.formatMessage({id: 'LABEL_INACTIVE'})
                      }
                      readOnly
                    />
                  </>
                ) : (
                  <input
                    type='text'
                    className='form-control form-control-solid'
                    value={
                      models.status === 't'
                        ? intl.formatMessage({id: 'LABEL_ACTIVE'})
                        : intl.formatMessage({id: 'LABEL_INACTIVE'})
                    }
                    readOnly
                  />
                )}
              </div>
              {/* Status */}
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-outline btn-btn-outline-info me-3'
                data-kt-users-modal-action='close'
                onClick={() => setIsDialogOpen(false)}
              >
                {intl.formatMessage({id: 'BUTTON_LABEL_BACK'})}
              </button>

              <button
                type='button'
                className={`btn ${loading ? 'btn-secondary' : 'btn-success'}`}
                data-kt-users-modal-action='submit'
                onClick={() => formik.handleSubmit()}
                disabled={loading ? true : false}
              >
                {!loading && (
                  <span className='indicator-label'>
                    {intl.formatMessage({id: 'BUTTON_LABEL_SUBMIT'})}
                  </span>
                )}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    {intl.formatMessage({id: 'BUTTON_LABEL_PLEASE_WAIT'})}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
      {/* Dialog */}
    </>
  )
}

export {ViewDrawerForm}
