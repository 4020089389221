import {FC} from 'react'
import {useIntl} from 'react-intl'

import {SpinnerIndicator, getFormAction} from 'app/components'
import {KTIcon} from '_metronic/helpers'

import {useAction} from '../../providers'

type Props = {
  menuName?: any
}

const ViewModalHeader: FC<Props> = ({menuName}) => {
  const intl = useIntl()
  const {setItemIdForUpdate, action, isLoading, itemNameForUpdate, itemCodeForUpdate} = useAction()

  return (
    <div className='modal-header justify-content-between'>
      {/* begin::Modal title */}
      {isLoading ? (
        <SpinnerIndicator label={intl.formatMessage({id: 'BUTTON_LABEL_PLEASE_WAIT'})} />
      ) : (
        <>
          <h2 className='fw-bolder'>
            {getFormAction(
              action,
              intl.formatMessage({id: 'BUTTON_LABEL_VIEW'}),
              intl.formatMessage({id: 'BUTTON_LABEL_EDIT'}),
              intl.formatMessage({id: 'BUTTON_LABEL_ADD'}),
              intl.formatMessage({id: 'BUTTON_LABEL_DELETE'})
            )}{' '}
            {menuName}: {itemNameForUpdate}
            {' ['}
            {itemCodeForUpdate}
            {']'}
          </h2>
        </>
      )}
      {/* end::Modal title */}

      {/* begin::Close */}
      <button
        type='button'
        className='btn btn-outline btn-btn-outline-info me-3'
        onClick={() => setItemIdForUpdate(undefined)}
      >
        <KTIcon iconName='arrow-circle-left' className='fs-2' />
        {intl.formatMessage({id: 'BUTTON_LABEL_BACK'})}
      </button>
      {/* end::Close */}
    </div>
  )
}

export {ViewModalHeader}
