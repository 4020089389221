import {FC, useState} from 'react'
import toast from 'react-hot-toast'
import {useQuery} from 'react-query'
import {Drawer} from 'rsuite'

import {QUERIES, isNotEmpty} from '_metronic/helpers'
import {isMobileDevice} from '_metronic/assets/ts/_utils'
import {removeAuth} from 'app/modules/auth'

import {useAction} from '../../providers'
import {getDataByID} from '../../helpers'
import {ViewDrawerForm} from '..'

type Props = {
  menuName?: any
  modalID?: any
  appMenuID: any
}

const ViewDrawerFormWrapper: FC<Props> = ({menuName, modalID, appMenuID}) => {
  const mobile = isMobileDevice()
  const [open, setOpen] = useState(true)
  const {
    action,
    isLoading,
    itemIdForUpdate,
    directory,
    setItemIdForUpdate,
    setIsLoading,
    setIsSubMenu,
    setIsParent,
    setParentID,
    setDirectory,
  } = useAction()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {data: menu, error} = useQuery(
    [`${QUERIES.GET_MENU}-${itemIdForUpdate}`],
    () => {
      setIsLoading(true)
      return getDataByID(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      refetchOnWindowFocus: false,
      onError: (err: any) => {
        if (err.response.status !== 200) {
          setIsLoading(false)
          if (err.response.status === 401) {
            toast.error(err.response.statusText)
            removeAuth()
          } else {
            toast.error(err.response.statusText)
          }
        }
      },
      onSuccess: () => {
        setIsLoading(false)
      },
    }
  )

  return (
    <Drawer
      open={open}
      onClose={() => {
        setOpen(false)
        setItemIdForUpdate(undefined)
        setIsSubMenu(false)
        setIsParent(false)
        setParentID('')
        setDirectory('')
      }}
      placement='right'
      backdrop='static'
      keyboard={action === 'view' ? true : false}
      style={{width: mobile ? '100%' : '50%'}}
    >
      {menu === undefined && (
        <ViewDrawerForm
          menuName={menuName}
          isLoading={isLoading}
          menu={{
            menu_id: undefined,
            module_id: '1',
            path: directory,
            site_id: '1',
            access: 1000000,
            status: 't',
          }}
          appMenuID={appMenuID}
          modalID={modalID}
        />
      )}
      {!error && menu && (
        <ViewDrawerForm
          menuName={menuName}
          isLoading={isLoading}
          menu={menu.data}
          appMenuID={appMenuID}
          modalID={modalID}
        />
      )}
    </Drawer>
  )
}

export {ViewDrawerFormWrapper}
