import { lazy } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'

import { MasterLayout } from '_metronic/layout/MasterLayout'
import { SuspendViewComponents } from 'app/components'
import { HomepageWrapper } from 'app/pages/homepage/HomepageWrapper'
import { UserManagementWrapper } from 'app/pages/general/settings/user-management/UserManagementWrapper'
import { ConditionalsPageWrapper } from 'app/pages/general/settings/conditional/ConditionalsPageWrapper'
import { DatasPageWrapper } from 'app/pages/general/settings/data/DatasPageWrapper'
import { ModuleUserManagementWrapper } from 'app/pages/settings-all-module/ModuleUserManagementWrapper'
import { ModuleConditionalManagementWrapper } from 'app/pages/settings-all-module/ModuleConditionalManagementWrapper'
import { ModuleDatasPageWrapper } from 'app/pages/settings-all-module/ModuleDatasPageWrapper'
import { BackOfficeOrinsPOSDashboardWrapper } from 'app/pages/backoffice-orins-pos/orins-pos/dashboard/BackOfficeOrinsPOSDashboardWrapper'
import { BackOfficeOrinsPOSReportWrapper } from 'app/pages/backoffice-orins-pos/orins-pos/report/BackOfficeOrinsPOSReportWrapper'
import { BackOfficeOrinsPOSESBWrapper } from 'app/pages/backoffice-orins-pos/orins-pos/esb/BackOfficeOrinsPOSESBWrapper'
import { BackOfficeOrinsPOSUserManagementWrapper } from 'app/pages/backoffice-orins-pos/pos-settings/user-management/BackOfficeOrinsPOSUserManagementWrapper'
import { BackOfficeOrinsPOSMobileLayoutWrapper } from 'app/pages/backoffice-orins-pos/pos-settings/mobile-layout/BackOfficeOrinsPOSMobileLayoutWrapper'
import { BackOfficeOrinsPOSMastersWrapper } from 'app/pages/backoffice-orins-pos/pos-settings/masters/BackOfficeOrinsPOSMastersWrapper'
import { BackOfficeOrinsPOSReceiptWrapper } from 'app/pages/backoffice-orins-pos/pos-settings/receipt/BackOfficeOrinsPOSReceiptWrapper'
import { BackOfficeOrinsPOSLibraryWrapper } from 'app/pages/backoffice-orins-pos/pos-settings/library/BackOfficeOrinsPOSLibraryWrapper'

const AccountWrapper = lazy(() => import('app/pages/account/AccountWrapper'))

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='auth/*' element={<Navigate to='/homepage' />} />
        <Route path='homepage' element={<HomepageWrapper />} />

        {/* Lazy Modules */}
        <Route
          path='account/*'
          element={
            <SuspendViewComponents>
              <AccountWrapper />
            </SuspendViewComponents>
          }
        />
        {/* Lazy Modules */}

        {/*** General Settings Module ***/}
        <Route path='general/*' element={<UserManagementWrapper />} />
        <Route path='general/settings/*' element={<UserManagementWrapper />} />
        <Route path='general/settings/users/*' element={<UserManagementWrapper />} />
        <Route path='general/settings/conditionals/*' element={<ConditionalsPageWrapper />} />
        <Route path='general/settings/datas/*' element={<DatasPageWrapper />} />
        {/*** General Settings Module ***/}

        {/*** Backoffice Orins POS Module ***/}
        {/* Orins POS Sub Module */}
        <Route path='backoffice-orins-pos/*' element={<BackOfficeOrinsPOSDashboardWrapper />} />
        <Route
          path='backoffice-orins-pos/orins-pos/*'
          element={<BackOfficeOrinsPOSDashboardWrapper />}
        />
        <Route
          path='backoffice-orins-pos/orins-pos/dashboard/*'
          element={<BackOfficeOrinsPOSDashboardWrapper />}
        />
        <Route
          path='backoffice-orins-pos/orins-pos/report/*'
          element={<BackOfficeOrinsPOSReportWrapper />}
        />
        <Route
          path='backoffice-orins-pos/orins-pos/esb/*'
          element={<BackOfficeOrinsPOSESBWrapper />}
        />
        {/* Orins POS Sub Module */}

        {/* POS Setting Sub Module */}
        <Route
          path='backoffice-orins-pos/pos-settings/*'
          element={<BackOfficeOrinsPOSUserManagementWrapper />}
        />
        <Route
          path='backoffice-orins-pos/pos-settings/users/*'
          element={<BackOfficeOrinsPOSUserManagementWrapper />}
        />
        <Route
          path='backoffice-orins-pos/pos-settings/mobile-layout/*'
          element={<BackOfficeOrinsPOSMobileLayoutWrapper />}
        />
        <Route
          path='backoffice-orins-pos/pos-settings/masters/*'
          element={<BackOfficeOrinsPOSMastersWrapper />}
        />
        <Route
          path='backoffice-orins-pos/pos-settings/receipt/*'
          element={<BackOfficeOrinsPOSReceiptWrapper />}
        />
        <Route
          path='backoffice-orins-pos/pos-settings/library/*'
          element={<BackOfficeOrinsPOSLibraryWrapper />}
        />
        {/* POS Setting Sub Module */}

        {/* Settings Sub Module */}
        <Route path='backoffice-orins-pos/settings/*' element={<ModuleUserManagementWrapper />} />
        <Route
          path='backoffice-orins-pos/settings/users/*'
          element={<ModuleUserManagementWrapper />}
        />
        <Route
          path='backoffice-orins-pos/settings/conditionals/*'
          element={<ModuleConditionalManagementWrapper />}
        />
        <Route path='backoffice-orins-pos/settings/datas/*' element={<ModuleDatasPageWrapper />} />
        {/* Settings Sub Module */}
        {/*** Backoffice Orins POS Module ***/}

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

export { PrivateRoutes }
