import axios, {AxiosResponse} from 'axios'

import {APIResponse} from 'app/components'

import {ListQueryResponse, CityID} from '.'

const PROVINCE_API_URL = `${process.env.REACT_APP_MOBILE_APP_API_URL}/province`
const CITY_API_URL = `${process.env.REACT_APP_MOBILE_APP_API_URL}/city`
const MOBILE_X_API_KEY = process.env.REACT_APP_MOBILE_APP_API_KEY

const fetch = (query: any): Promise<ListQueryResponse> => {
  return axios
    .get(`${CITY_API_URL}/list?${query}`, {
      headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
    })
    .then((response: AxiosResponse<ListQueryResponse>) => response.data)
}

const fetchProvinceOptions = (mode: any) => {
  return axios.get(`${PROVINCE_API_URL}/list-options/${mode}`, {
    headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
  })
}

const get = (city_id: CityID): Promise<APIResponse | undefined> => {
  return axios
    .get(`${CITY_API_URL}/${city_id}/detail`, {
      headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
    })
    .then((response: AxiosResponse<APIResponse>) => response.data)
}

const addData = (payload: any): Promise<APIResponse | undefined> => {
  return axios
    .post(
      `${CITY_API_URL}`,
      {payload},
      {
        headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
      }
    )
    .then((response: AxiosResponse<APIResponse>) => response.data)
}

const updateData = (payload: any): Promise<APIResponse | undefined> => {
  return axios
    .put(
      `${CITY_API_URL}/${payload.city_id}/update`,
      {payload},
      {
        headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
      }
    )
    .then((response: AxiosResponse<APIResponse>) => response.data)
}

const deleteData = (
  city_id: any,
  menu_id: any,
  creator_id: any,
  creator_by: any
): Promise<APIResponse | undefined> => {
  return axios
    .delete(`${CITY_API_URL}/${city_id}/${menu_id}/${creator_id}/${creator_by}/delete`, {
      headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
    })
    .then((response: AxiosResponse<APIResponse>) => response.data)
}

export {fetch, fetchProvinceOptions, get, updateData, addData, deleteData}
