/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {Dialog} from '@headlessui/react'
import clsx from 'clsx'
import {Drawer, Toggle} from 'rsuite'

import {FormLog, NotAuthRedirectForm, getFormAction, SpinnerIndicator} from 'app/components'
import {getAuth, removeAuth} from 'app/modules/auth'

import {GetDataByIDModel, addData, deleteData, initialData, updateData} from '../../helpers'
import {useAction, useListQueryResponse} from '../../providers'

type CheckBoxProps = {
  name: string
  value: boolean
  currentValue: any
  currentPayload: any
  formik: any
  action: string
  disabled: boolean
}

const Checkbox: FC<CheckBoxProps> = ({
  name,
  value,
  currentValue,
  currentPayload,
  formik,
  action,
  disabled,
}) => {
  return (
    <div className='form-check form-check-custom form-check-solid'>
      <input
        type='checkbox'
        id={`${name}`}
        disabled={disabled}
        checked={value}
        className={`form-check-input ${action !== 'view' && 'cursor-pointer'}`}
        onChange={(e) => {
          if (name === 'add') {
            e.target.checked
              ? formik.setFieldValue('access', formik.values.access + 100000)
              : formik.setFieldValue('access', formik.values.access - 100000)
          } else if (name === 'edit') {
            e.target.checked
              ? formik.setFieldValue('access', formik.values.access + 10000)
              : formik.setFieldValue('access', formik.values.access - 10000)
          } else if (name === 'delete') {
            e.target.checked
              ? formik.setFieldValue('access', formik.values.access + 1000)
              : formik.setFieldValue('access', formik.values.access - 1000)
          } else if (name === 'apv1') {
            e.target.checked
              ? formik.setFieldValue('access', formik.values.access + 100)
              : formik.setFieldValue('access', formik.values.access - 100)
          } else if (name === 'apv2') {
            e.target.checked
              ? formik.setFieldValue('access', formik.values.access + 10)
              : formik.setFieldValue('access', formik.values.access - 10)
          } else {
            e.target.checked
              ? formik.setFieldValue('access', formik.values.access + 1)
              : formik.setFieldValue('access', formik.values.access - 1)
          }
        }}
      />
      <label
        className={`form-check-label ${
          currentValue !== currentPayload && name !== 'view' && 'text-info fw-bold'
        }`}
        htmlFor={`${name}`}
      >
        {name.charAt(0).toUpperCase() + name.slice(1)}&nbsp;&nbsp;
      </label>
    </div>
  )
}

type Props = {
  isLoading: boolean
  menu: GetDataByIDModel
  appMenuID: any
  modalID: any
  menuName: any
}

const ViewDrawerForm: FC<Props> = ({menuName, menu, isLoading, appMenuID, modalID}) => {
  const [tab, setTab] = useState('data')
  const auth = getAuth()
  const intl = useIntl()
  const {setItemIdForUpdate, action, isParent, parentID, isSubMenu} = useAction()
  const {refetch} = useListQueryResponse()
  const [isAuth, setIsAuth] = useState(true)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [completed, setCompleted] = useState(false)

  const validationSchema = Yup.object().shape({
    name_eng: Yup.string()
      .min(3, intl.formatMessage({id: 'VALIDATION_MIN_LENGTH_FIELD'}, {min: 3}))
      .max(125, intl.formatMessage({id: 'VALIDATION_MAX_LENGTH_FIELD'}, {max: 125}))
      .required(
        intl.formatMessage(
          {id: 'VALIDATION_REQUIRED_FIELD'},
          {name: intl.formatMessage({id: 'LABEL_NAME'})}
        )
      ),
    name_idn: Yup.string()
      .min(3, intl.formatMessage({id: 'VALIDATION_MIN_LENGTH_FIELD'}, {min: 3}))
      .max(125, intl.formatMessage({id: 'VALIDATION_MAX_LENGTH_FIELD'}, {max: 125}))
      .required(
        intl.formatMessage(
          {id: 'VALIDATION_REQUIRED_FIELD'},
          {name: intl.formatMessage({id: 'LABEL_NAME'})}
        )
      ),
    path: Yup.string()
      .min(3, intl.formatMessage({id: 'VALIDATION_MIN_LENGTH_FIELD'}, {min: 3}))
      .max(250, intl.formatMessage({id: 'VALIDATION_MAX_LENGTH_FIELD'}, {max: 250}))
      .required(
        intl.formatMessage(
          {id: 'VALIDATION_REQUIRED_FIELD'},
          {name: intl.formatMessage({id: 'LABEL_DIRECTORY'})}
        )
      ),
    for_order: Yup.string().required(
      intl.formatMessage(
        {id: 'VALIDATION_REQUIRED_FIELD'},
        {name: intl.formatMessage({id: 'LABEL_ORDER'})}
      )
    ),
  })

  const requestUpdateData = async (
    values: any,
    setStatus: any,
    setSubmitting: any,
    setFieldError: any
  ) => {
    setLoading(true)
    setStatus(null)
    setSubmitting(true)

    // request API
    try {
      // fix for order is number
      values.for_order = parseInt(values.for_order)

      const res = await updateData(values)

      // throw message error
      if (res?.meta.code !== 200) {
        if (res?.meta.message === 'name eng already used') {
          setStatus(intl.formatMessage({id: 'RESPONSE_MENU_NAME_ALREADY_USED'}))
          setFieldError('name_eng', intl.formatMessage({id: 'RESPONSE_MENU_NAME_ALREADY_USED'}))
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
        if (res?.meta.message === 'name idn already used') {
          setStatus(intl.formatMessage({id: 'RESPONSE_MENU_NAME_ALREADY_USED'}))
          setFieldError('name_idn', intl.formatMessage({id: 'RESPONSE_MENU_NAME_ALREADY_USED'}))
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
        if (res?.meta.message === 'directory already used') {
          setStatus(intl.formatMessage({id: 'RESPONSE_MENU_DIRECTORY_ALREADY_USED'}))
          setFieldError('path', intl.formatMessage({id: 'RESPONSE_MENU_DIRECTORY_ALREADY_USED'}))
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_MENU_EDIT_SUCCESS'}))
        setCompleted(true)
        setTimeout(function () {
          setItemIdForUpdate(undefined)
        }, 1e3)
        refetch()
      }
    } catch (error: any) {
      console.error(error)
      if (error.response.status === 401) {
        setStatus(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
        removeAuth()
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
      }
      setTimeout(function () {
        setStatus(null)
      }, 9e3)

      return false
    } finally {
      setTimeout(() => {
        setLoading(false)
        setSubmitting(false)
      }, 5e2)
    }
  }
  const requestAddData = async (
    values: any,
    setStatus: any,
    setSubmitting: any,
    setFieldError: any
  ) => {
    setLoading(true)
    setStatus(null)
    setSubmitting(true)

    // request API
    try {
      const res = await addData(values)

      // throw message error
      if (res?.meta.code !== 200) {
        if (res?.meta.message === 'name eng already used') {
          setStatus(intl.formatMessage({id: 'RESPONSE_MENU_NAME_ALREADY_USED'}))
          setFieldError('name_eng', intl.formatMessage({id: 'RESPONSE_MENU_NAME_ALREADY_USED'}))
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
        if (res?.meta.message === 'name idn already used') {
          setStatus(intl.formatMessage({id: 'RESPONSE_MENU_NAME_ALREADY_USED'}))
          setFieldError('name_idn', intl.formatMessage({id: 'RESPONSE_MENU_NAME_ALREADY_USED'}))
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
        if (res?.meta.message === 'directory already used') {
          setStatus(intl.formatMessage({id: 'RESPONSE_MENU_DIRECTORY_ALREADY_USED'}))
          setFieldError('path', intl.formatMessage({id: 'RESPONSE_MENU_DIRECTORY_ALREADY_USED'}))
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_MENU_ADD_SUCCESS'}))
        setCompleted(true)
        setTimeout(function () {
          setItemIdForUpdate(undefined)
        }, 1e3)
        refetch()
      }
    } catch (error: any) {
      console.error(error)
      if (error.response.status === 401) {
        setStatus(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
        removeAuth()
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
      }
      setTimeout(function () {
        setStatus(null)
      }, 9e3)

      return false
    } finally {
      setTimeout(() => {
        setLoading(false)
        setSubmitting(false)
      }, 5e2)
    }
  }
  const requestDeleteData = async (values: any, setStatus: any, setSubmitting: any) => {
    setLoading(true)
    setStatus(null)
    setSubmitting(true)

    // request API
    try {
      const res = await deleteData(values.menu_id, values.menu_menu_id)

      // throw message error
      if (res?.meta.code !== 200) {
        if (res?.meta.message === 'menu is not inactive') {
          setStatus(intl.formatMessage({id: 'RESPONSE_MENU_IS_ACTIVE'}))
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
        if (res?.meta.message === 'menu inused by other data') {
          setStatus(intl.formatMessage({id: 'RESPONSE_MENU_IS_INUSED'}))
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_MENU_DELETE_SUCCESS'}))
        setCompleted(true)
        setTimeout(function () {
          setItemIdForUpdate(undefined)
        }, 1e3)
        refetch()
      }
    } catch (error: any) {
      console.error(error)
      if (error.response.status === 401) {
        setStatus(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
        removeAuth()
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
      }
      setSubmitting(false)
      setLoading(false)
      setTimeout(function () {
        setStatus(null)
      }, 9e3)

      return false
    } finally {
      setTimeout(() => {
        setLoading(false)
        setSubmitting(false)
      }, 5e2)
    }
  }
  const [menuModels] = useState<GetDataByIDModel>({
    ...menu,
    access: menu.access || initialData.access,
    for_order: menu.for_order || initialData.for_order,
    level: menu.level || isParent ? 1 : 2,
    has_child: menu.has_child || isParent ? 't' : 'f',
    parent_id: menu.parent_id || isParent ? '' : parentID,
    icon: menu.icon || initialData.icon,
    module_id: menu.module_id || initialData.module_id,
    site_id: menu.site_id || initialData.site_id,
    menu_id: menu.menu_id || initialData.menu_id,
    name_eng: menu.name_eng || initialData.name_eng,
    name_idn: menu.name_idn || initialData.name_idn,
    path: menu.path || initialData.path,
    status: menu.status || initialData.status,
    menu_menu_id: appMenuID,
  })
  const formik: any = useFormik({
    initialValues: menuModels,
    validationSchema: validationSchema,
    onSubmit: async (values, {setSubmitting, setStatus, setFieldError}) => {
      setIsDialogOpen(false)
      action === 'edit'
        ? requestUpdateData(values, setStatus, setSubmitting, setFieldError)
        : action === 'add'
          ? requestAddData(values, setStatus, setSubmitting, setFieldError)
          : requestDeleteData(values, setStatus, setSubmitting)
    },
    onReset: (values) => {
      values = menuModels
    },
  })

  useEffect(() => {
    auth === undefined && setIsAuth(false)
  }, [auth])

  return (
    <>
      {!isAuth && <NotAuthRedirectForm />}

      <Drawer.Header>
        <Drawer.Title className='fw-bolder'>
          {isLoading ? (
            <SpinnerIndicator label={intl.formatMessage({id: 'BUTTON_LABEL_PLEASE_WAIT'})} />
          ) : (
            <>
              {getFormAction(
                action,
                intl.formatMessage({id: 'BUTTON_LABEL_VIEW'}),
                intl.formatMessage({id: 'BUTTON_LABEL_EDIT'}),
                intl.formatMessage({id: 'BUTTON_LABEL_ADD'}),
                intl.formatMessage({id: 'BUTTON_LABEL_DELETE'})
              )}{' '}
              {isSubMenu && 'Sub '}
              {menuName}
            </>
          )}
        </Drawer.Title>
        <Drawer.Actions>
          {action !== 'view' && menuModels !== formik.values && !completed && (
            <button
              type='reset'
              className='btn btn-light me-3'
              data-kt-users-modal-action='reset'
              onClick={formik.handleReset}
              disabled={loading}
            >
              {!loading && (
                <span className='indicator-label'>
                  {intl.formatMessage({id: 'BUTTON_LABEL_RESET'})}
                </span>
              )}
            </button>
          )}

          {action !== 'view' && menuModels !== formik.values && !completed && (
            <button
              type='submit'
              className='btn btn-primary'
              data-kt-users-modal-action='submit'
              onClick={() => {
                setIsDialogOpen(true)
                setLoading(true)
                setTimeout(function () {
                  setLoading(false)
                }, 2e3)
              }}
              disabled={isLoading || loading || formik.isSubmitting || !formik.isValid}
            >
              {!loading && (
                <span className='indicator-label'>
                  {intl.formatMessage({id: 'BUTTON_LABEL_SUBMIT'})}
                </span>
              )}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {intl.formatMessage({id: 'BUTTON_LABEL_SUBMITTING'})}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          )}

          {action === 'delete' && !completed && (
            <button
              type='submit'
              className='btn btn-danger'
              data-kt-users-modal-action='submit'
              onClick={() => formik.handleSubmit()}
              disabled={isLoading || loading || formik.isSubmitting || !formik.isValid}
            >
              {!loading && (
                <span className='indicator-label'>
                  {intl.formatMessage({id: 'BUTTON_LABEL_DELETE'})}
                </span>
              )}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {intl.formatMessage({id: 'BUTTON_LABEL_SUBMITTING'})}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          )}
        </Drawer.Actions>
      </Drawer.Header>

      <Drawer.Body style={{padding: 0}}>
        {/* Response Message */}
        {formik.status &&
          (formik.status === intl.formatMessage({id: 'RESPONSE_MENU_ADD_SUCCESS'}) ||
          formik.status === intl.formatMessage({id: 'RESPONSE_MENU_EDIT_SUCCESS'}) ||
          formik.status === intl.formatMessage({id: 'RESPONSE_MENU_DELETE_SUCCESS'}) ? (
            <div className='m-lg-5 p-2 bg-light-success rounded'>
              <div className='text-success text-center font-weight-bold fw-bolder'>
                {formik.status}
              </div>
            </div>
          ) : (
            <div className={`m-lg-5 p-2 alert alert-danger`}>
              <div className='alert-text text-center font-weight-bold fw-bolder'>
                {formik.status}
              </div>
            </div>
          ))}
        {/* Response Message */}

        {/* Form */}
        <div className={`modal-body m-5 ${action === 'add' ? '' : ' mt-0'}`}>
          {action === 'add' ? (
            <form id={`${modalID}-form`} className='form' onSubmit={formik.handleSubmit} noValidate>
              <div className='row'>
                {/* Left Form */}
                <div className='col-lg-6'>
                  {/* Name */}
                  <div className='fv-row mb-5'>
                    {formik.values.name_eng !== '' && (
                      <label className='form-label fs-6 fw-bolder text-dark required'>
                        {intl.formatMessage({id: 'LABEL_NAME'})}
                        {' (ENG)'}
                      </label>
                    )}
                    <input
                      type='text'
                      className={clsx(
                        'form-control form-control-solid',
                        {'is-invalid': formik.touched.name_eng && formik.errors.name_eng},
                        {
                          'is-valid': formik.touched.name_eng && !formik.errors.name_eng,
                        }
                      )}
                      placeholder={intl.formatMessage({id: 'LABEL_NAME'}) + ' (ENG)'}
                      {...formik.getFieldProps('name_eng')}
                    />
                    {formik.touched.name_eng && formik.errors.name_eng ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger'>{formik.errors.name_eng}</div>
                      </div>
                    ) : null}
                  </div>
                  <div className='fv-row mb-5'>
                    {formik.values.name_idn !== '' && (
                      <label className='form-label fs-6 fw-bolder text-dark required'>
                        {intl.formatMessage({id: 'LABEL_NAME'})}
                        {' (IDN)'}
                      </label>
                    )}
                    <input
                      type='text'
                      className={clsx(
                        'form-control form-control-solid',
                        {'is-invalid': formik.touched.name_idn && formik.errors.name_idn},
                        {
                          'is-valid': formik.touched.name_idn && !formik.errors.name_idn,
                        }
                      )}
                      placeholder={intl.formatMessage({id: 'LABEL_NAME'}) + ' (IDN)'}
                      {...formik.getFieldProps('name_idn')}
                    />
                    {formik.touched.name_idn && formik.errors.name_idn ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger'>{formik.errors.name_idn}</div>
                      </div>
                    ) : null}
                  </div>
                  {/* Name */}

                  {/* Directory */}
                  <div className='fv-row mb-5'>
                    {formik.values.path !== '' && (
                      <label className='form-label fs-6 fw-bolder text-dark required'>
                        {intl.formatMessage({id: 'LABEL_DIRECTORY'})}
                      </label>
                    )}
                    <input
                      type='text'
                      className={clsx(
                        'form-control form-control-solid',
                        {'is-invalid': formik.touched.path && formik.errors.path},
                        {
                          'is-valid': formik.touched.path && !formik.errors.path,
                        }
                      )}
                      placeholder={intl.formatMessage({id: 'LABEL_DIRECTORY'})}
                      {...formik.getFieldProps('path')}
                      onChange={(e) => {
                        formik.setFieldValue('path', e.target.value.toLowerCase())
                      }}
                    />
                    {formik.touched.path && formik.errors.path ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger'>{formik.errors.path}</div>
                      </div>
                    ) : null}
                  </div>
                  {/* Directory */}

                  {/* Icon */}
                  <div className='fv-row mb-5'>
                    {formik.values.icon !== '' && (
                      <label className='form-label fs-6 fw-bolder text-dark'>
                        {intl.formatMessage({id: 'LABEL_ICON'})}
                      </label>
                    )}
                    <input
                      type='text'
                      className={clsx(
                        'form-control form-control-solid',
                        {'is-invalid': formik.touched.icon && formik.errors.icon},
                        {
                          'is-valid': formik.touched.icon && !formik.errors.icon,
                        }
                      )}
                      placeholder={intl.formatMessage({id: 'LABEL_ICON'})}
                      {...formik.getFieldProps('icon')}
                    />
                    {formik.touched.icon && formik.errors.icon ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger'>{formik.errors.icon}</div>
                      </div>
                    ) : null}
                  </div>
                  {/* Icon */}

                  {/* Order */}
                  <div className='fv-row mb-5'>
                    {formik.values.for_order !== '' && (
                      <label className='form-label fs-6 fw-bolder text-dark required'>
                        {intl.formatMessage({id: 'LABEL_ORDER'})}
                      </label>
                    )}
                    <input
                      type='number'
                      className={clsx(
                        'form-control form-control-solid',
                        {'is-invalid': formik.touched.for_order && formik.errors.for_order},
                        {
                          'is-valid': formik.touched.for_order && !formik.errors.for_order,
                        }
                      )}
                      placeholder={intl.formatMessage({id: 'LABEL_ORDER'})}
                      {...formik.getFieldProps('for_order')}
                      onChange={(e) => {
                        formik.setFieldValue('for_order', parseInt(e.target.value))
                      }}
                    />
                    {formik.touched.for_order && formik.errors.for_order ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger'>{formik.errors.for_order}</div>
                      </div>
                    ) : null}
                  </div>
                  {/* Order */}
                </div>
                {/* Left Form */}

                {/* Right Form */}
                <div className='col-lg-6'>
                  {/* Access */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark'>
                      {intl.formatMessage({id: 'LABEL_ACCESS'})}
                    </label>
                    <div className='checkbox-inline'>
                      <label className='checkbox'>
                        <Checkbox
                          name='view'
                          value={String(formik.values.access).charAt(0) === '1' ? true : false}
                          currentValue={String(formik.values.access).charAt(0)}
                          currentPayload={menuModels.access}
                          formik={formik}
                          action={action}
                          disabled={true}
                        />
                      </label>
                      <label className='checkbox'>
                        <Checkbox
                          name='add'
                          value={String(formik.values.access).charAt(1) === '1' ? true : false}
                          currentValue={String(formik.values.access).charAt(1)}
                          currentPayload={String(menuModels.access).charAt(1)}
                          formik={formik}
                          action={action}
                          disabled={false}
                        />
                      </label>
                      <label className='checkbox'>
                        <Checkbox
                          name='edit'
                          value={String(formik.values.access).charAt(2) === '1' ? true : false}
                          currentValue={String(formik.values.access).charAt(2)}
                          currentPayload={String(menuModels.access).charAt(2)}
                          formik={formik}
                          action={action}
                          disabled={false}
                        />
                      </label>
                      <label className='checkbox'>
                        <Checkbox
                          name='delete'
                          value={String(formik.values.access).charAt(3) === '1' ? true : false}
                          currentValue={String(formik.values.access).charAt(3)}
                          currentPayload={String(menuModels.access).charAt(3)}
                          formik={formik}
                          action={action}
                          disabled={false}
                        />
                      </label>
                      <label className='checkbox'>
                        <Checkbox
                          name='apv1'
                          value={String(formik.values.access).charAt(4) === '1' ? true : false}
                          currentValue={String(formik.values.access).charAt(4)}
                          currentPayload={String(menuModels.access).charAt(4)}
                          formik={formik}
                          action={action}
                          disabled={false}
                        />
                      </label>
                      <label className='checkbox'>
                        <Checkbox
                          name='apv2'
                          value={String(formik.values.access).charAt(5) === '1' ? true : false}
                          currentValue={String(formik.values.access).charAt(5)}
                          currentPayload={String(menuModels.access).charAt(5)}
                          formik={formik}
                          action={action}
                          disabled={false}
                        />
                      </label>
                      <label className='checkbox'>
                        <Checkbox
                          name='apv3'
                          value={String(formik.values.access).charAt(6) === '1' ? true : false}
                          currentValue={String(formik.values.access).charAt(6)}
                          currentPayload={String(menuModels.access).charAt(6)}
                          formik={formik}
                          action={action}
                          disabled={false}
                        />
                      </label>
                    </div>
                  </div>
                  {/* Access */}

                  {/* Status */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark required'>Status</label>
                    <div className='form-check form-switch form-check-custom form-check-solid mt-1'>
                      <Toggle
                        size='lg'
                        checkedChildren={intl.formatMessage({id: 'LABEL_ACTIVE'})}
                        unCheckedChildren={intl.formatMessage({id: 'LABEL_INACTIVE'})}
                        checked={formik.values.status === 't' ? true : false}
                        onChange={() =>
                          formik.setFieldValue('status', formik.values.status === 't' ? 'f' : 't')
                        }
                      />
                    </div>
                  </div>
                  {/* Status */}
                </div>
                {/* Right Form */}
              </div>
            </form>
          ) : (
            <form id={`${modalID}-form`} className='form' onSubmit={formik.handleSubmit} noValidate>
              {/* Tab Header */}
              <div className='card-toolbar'>
                <ul
                  className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-2 mt-2'
                  role='tablist'
                >
                  <li className={`nav-item ${tab === 'data' ? 'fw-bolder' : ''}`}>
                    <a
                      className={clsx(`nav-link cursor-pointer`, {
                        active: tab === 'data',
                      })}
                      onClick={() => setTab('data')}
                      role='tab'
                    >
                      Data
                    </a>
                  </li>
                  <li className={`nav-item ${tab === 'log' ? 'fw-bolder' : ''}`}>
                    <a
                      className={clsx(`nav-link cursor-pointer`, {
                        active: tab === 'log',
                      })}
                      onClick={() => setTab('log')}
                      role='tab'
                    >
                      Log
                    </a>
                  </li>
                </ul>
              </div>
              {/* Tab Header */}

              {/* Tab Body */}
              <div className='card-body p-0 pt-2'>
                <div className='tab-content'>
                  {/* Data */}
                  <div
                    className={clsx('tab-pane', {
                      active: tab === 'data',
                    })}
                  >
                    <div className='row'>
                      {/* Left Form */}
                      <div className='col-lg-6'>
                        {/* Name */}
                        <div className='fv-row mb-5'>
                          {formik.values.name_eng !== '' && (
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action === 'edit' ? 'required' : ''
                              }`}
                            >
                              {intl.formatMessage({id: 'LABEL_NAME'})}
                              {' (ENG)'}
                            </label>
                          )}
                          <input
                            type='text'
                            className={clsx(
                              'form-control form-control-solid',
                              {'is-invalid': formik.touched.name_eng && formik.errors.name_eng},
                              {
                                'is-valid': formik.touched.name_eng && !formik.errors.name_eng,
                              }
                            )}
                            placeholder={intl.formatMessage({id: 'LABEL_NAME'}) + ' (ENG)'}
                            {...formik.getFieldProps('name_eng')}
                            readOnly={action === 'edit' ? false : true}
                          />
                          {formik.touched.name_eng && formik.errors.name_eng ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger'>
                                {formik.errors.name_eng}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        <div className='fv-row mb-5'>
                          {formik.values.name_idn !== '' && (
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action === 'edit' ? 'required' : ''
                              }`}
                            >
                              {intl.formatMessage({id: 'LABEL_NAME'})}
                              {' (IDN)'}
                            </label>
                          )}
                          <input
                            type='text'
                            className={clsx(
                              'form-control form-control-solid',
                              {'is-invalid': formik.touched.name_idn && formik.errors.name_idn},
                              {
                                'is-valid': formik.touched.name_idn && !formik.errors.name_idn,
                              }
                            )}
                            placeholder={intl.formatMessage({id: 'LABEL_NAME'}) + ' (IDN)'}
                            {...formik.getFieldProps('name_idn')}
                            readOnly={action === 'edit' ? false : true}
                          />
                          {formik.touched.name_idn && formik.errors.name_idn ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger'>
                                {formik.errors.name_idn}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Name */}

                        {/* Directory */}
                        <div className='fv-row mb-5'>
                          {formik.values.path !== '' && (
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action === 'edit' ? 'required' : ''
                              }`}
                            >
                              {intl.formatMessage({id: 'LABEL_DIRECTORY'})}
                            </label>
                          )}
                          <input
                            type='text'
                            className={clsx(
                              'form-control form-control-solid',
                              {'is-invalid': formik.touched.path && formik.errors.path},
                              {
                                'is-valid': formik.touched.path && !formik.errors.path,
                              }
                            )}
                            placeholder={intl.formatMessage({id: 'LABEL_DIRECTORY'})}
                            {...formik.getFieldProps('path')}
                            readOnly={action === 'edit' ? false : true}
                            onChange={(e) => {
                              formik.setFieldValue('path', e.target.value.toLowerCase())
                            }}
                          />
                          {formik.touched.path && formik.errors.path ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger'>{formik.errors.path}</div>
                            </div>
                          ) : null}
                        </div>
                        {/* Directory */}

                        {/* Icon */}
                        <div className='fv-row mb-5'>
                          {formik.values.icon !== '' && (
                            <label className='form-label fs-6 fw-bolder text-dark'>
                              {intl.formatMessage({id: 'LABEL_ICON'})}
                            </label>
                          )}
                          <input
                            type='text'
                            className={clsx(
                              'form-control form-control-solid',
                              {'is-invalid': formik.touched.icon && formik.errors.icon},
                              {
                                'is-valid': formik.touched.icon && !formik.errors.icon,
                              }
                            )}
                            placeholder={intl.formatMessage({id: 'LABEL_ICON'})}
                            {...formik.getFieldProps('icon')}
                            readOnly={action === 'edit' ? false : true}
                          />
                        </div>
                        {/* Icon */}

                        {/* Order */}
                        <div className='fv-row mb-5'>
                          {formik.values.for_order !== '' && (
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action === 'edit' ? 'required' : ''
                              }`}
                            >
                              {intl.formatMessage({id: 'LABEL_ORDER'})}
                            </label>
                          )}
                          <input
                            type={`${action === 'edit' ? 'number' : 'text'}`}
                            className={clsx(
                              'form-control form-control-solid',
                              {'is-invalid': formik.touched.for_order && formik.errors.for_order},
                              {
                                'is-valid': formik.touched.for_order && !formik.errors.for_order,
                              }
                            )}
                            placeholder={intl.formatMessage({id: 'LABEL_ORDER'})}
                            {...formik.getFieldProps('for_order')}
                            readOnly={action === 'edit' ? false : true}
                            onChange={(e) => {
                              formik.setFieldValue('for_order', e.target.value)
                            }}
                          />
                          {formik.touched.for_order && formik.errors.for_order ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger'>
                                {formik.errors.for_order}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Order */}
                      </div>
                      {/* Left Form */}

                      {/* Right Form */}
                      <div className='col-lg-6'>
                        {/* Access */}
                        <div className='fv-row mb-5'>
                          <label className='form-label fs-6 fw-bolder text-dark'>
                            {intl.formatMessage({id: 'LABEL_ACCESS'})}
                          </label>
                          <div className='checkbox-inline'>
                            <label className='checkbox'>
                              <Checkbox
                                name='view'
                                value={
                                  String(formik.values.access).charAt(0) === '1' ? true : false
                                }
                                currentValue={String(formik.values.access).charAt(0)}
                                currentPayload={menuModels.access}
                                formik={formik}
                                action={action}
                                disabled={true}
                              />
                            </label>
                            <label className='checkbox'>
                              <Checkbox
                                name='add'
                                value={
                                  String(formik.values.access).charAt(1) === '1' ? true : false
                                }
                                currentValue={String(formik.values.access).charAt(1)}
                                currentPayload={String(menuModels.access).charAt(1)}
                                formik={formik}
                                action={action}
                                disabled={action !== 'edit' ? true : false}
                              />
                            </label>
                            <label className='checkbox'>
                              <Checkbox
                                name='edit'
                                value={
                                  String(formik.values.access).charAt(2) === '1' ? true : false
                                }
                                currentValue={String(formik.values.access).charAt(2)}
                                currentPayload={String(menuModels.access).charAt(2)}
                                formik={formik}
                                action={action}
                                disabled={action !== 'edit' ? true : false}
                              />
                            </label>
                            <label className='checkbox'>
                              <Checkbox
                                name='delete'
                                value={
                                  String(formik.values.access).charAt(3) === '1' ? true : false
                                }
                                currentValue={String(formik.values.access).charAt(3)}
                                currentPayload={String(menuModels.access).charAt(3)}
                                formik={formik}
                                action={action}
                                disabled={action !== 'edit' ? true : false}
                              />
                            </label>
                            <label className='checkbox'>
                              <Checkbox
                                name='apv1'
                                value={
                                  String(formik.values.access).charAt(4) === '1' ? true : false
                                }
                                currentValue={String(formik.values.access).charAt(4)}
                                currentPayload={String(menuModels.access).charAt(4)}
                                formik={formik}
                                action={action}
                                disabled={action !== 'edit' ? true : false}
                              />
                            </label>
                            <label className='checkbox'>
                              <Checkbox
                                name='apv2'
                                value={
                                  String(formik.values.access).charAt(5) === '1' ? true : false
                                }
                                currentValue={String(formik.values.access).charAt(5)}
                                currentPayload={String(menuModels.access).charAt(5)}
                                formik={formik}
                                action={action}
                                disabled={action !== 'edit' ? true : false}
                              />
                            </label>
                            <label className='checkbox'>
                              <Checkbox
                                name='apv3'
                                value={
                                  String(formik.values.access).charAt(6) === '1' ? true : false
                                }
                                currentValue={String(formik.values.access).charAt(6)}
                                currentPayload={String(menuModels.access).charAt(6)}
                                formik={formik}
                                action={action}
                                disabled={action !== 'edit' ? true : false}
                              />
                            </label>
                          </div>
                        </div>
                        {/* Access */}

                        {/* Status */}
                        <div className='fv-row mb-5'>
                          {formik.values.status !== '' && (
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action === 'edit' ? 'required' : ''
                              }`}
                            >
                              Status
                            </label>
                          )}
                          <div className='form-check form-switch form-check-custom form-check-solid mt-1'>
                            <Toggle
                              size='lg'
                              readOnly={action !== 'edit' ? true : false}
                              checkedChildren={intl.formatMessage({id: 'LABEL_ACTIVE'})}
                              unCheckedChildren={intl.formatMessage({id: 'LABEL_INACTIVE'})}
                              checked={formik.values.status === 't' ? true : false}
                              onChange={() =>
                                formik.setFieldValue(
                                  'status',
                                  formik.values.status === 't' ? 'f' : 't'
                                )
                              }
                            />
                          </div>
                          {formik.touched.status && formik.errors.status ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {formik.errors.status}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Status */}
                      </div>
                      {/* Right Form */}
                    </div>
                  </div>
                  {/* Data */}

                  {/* Detail */}
                  <div
                    className={clsx('tab-pane', {
                      active: tab === 'log',
                    })}
                  >
                    <FormLog payload={menu} formik={formik} />
                  </div>
                  {/* Detail */}
                </div>
              </div>
              {/* Tab Body */}
            </form>
          )}
        </div>
        {/* Form */}
      </Drawer.Body>

      {/* Dialog */}
      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(true)}
        className='modal fade show d-block'
      >
        <Dialog.Panel className='modal-dialog modal-fullscreen'>
          <div className='modal-content'>
            <Dialog.Title className='modal-header'>
              {intl.formatMessage({id: 'DIALOG_HEADER_TITLE'})}
            </Dialog.Title>
            <div className='modal-body scroll-y mx-2 mx-xl-2 my-2'>
              {/* Name */}
              {menuModels.name_eng !== formik.values.name_eng && (
                <div className='fv-row mb-5'>
                  <label className='form-label fs-6 fw-bolder text-dark'>
                    {intl.formatMessage({id: 'LABEL_NAME'})}
                    {' (ENG)'}
                  </label>
                  {action !== 'add' && (
                    <input
                      type='text'
                      className='form-control form-control-solid'
                      value={menuModels.name_eng}
                      readOnly
                    />
                  )}
                  <input
                    type='text'
                    className='form-control form-control-solid text-primary is-valid'
                    value={formik.values.name_eng}
                    readOnly
                  />
                </div>
              )}
              {menuModels.name_idn !== formik.values.name_idn && (
                <div className='fv-row mb-5'>
                  <label className='form-label fs-6 fw-bolder text-dark'>
                    {intl.formatMessage({id: 'LABEL_NAME'})}
                    {' (IDN)'}
                  </label>
                  {action !== 'add' && (
                    <input
                      type='text'
                      className='form-control form-control-solid'
                      value={menuModels.name_idn}
                      readOnly
                    />
                  )}
                  <input
                    type='text'
                    className='form-control form-control-solid text-primary is-valid'
                    value={formik.values.name_idn}
                    readOnly
                  />
                </div>
              )}
              {/* Name */}

              {/* Path */}
              {menuModels.path !== formik.values.path && (
                <div className='fv-row mb-5'>
                  <label className='form-label fs-6 fw-bolder text-dark'>
                    {intl.formatMessage({id: 'LABEL_DIRECTORY'})}
                  </label>
                  {action !== 'add' && (
                    <input
                      type='text'
                      className='form-control form-control-solid'
                      value={menuModels.path}
                      readOnly
                    />
                  )}
                  <input
                    type='text'
                    className='form-control form-control-solid text-primary is-valid'
                    value={formik.values.path}
                    readOnly
                  />
                </div>
              )}
              {/* Path */}

              {/* Icon */}
              {menuModels.icon !== formik.values.icon && (
                <div className='fv-row mb-5'>
                  <label className='form-label fs-6 fw-bolder text-dark'>
                    {intl.formatMessage({id: 'LABEL_ICON'})}
                  </label>
                  {action !== 'add' && (
                    <input
                      type='text'
                      className='form-control form-control-solid'
                      value={menuModels.icon}
                      readOnly
                    />
                  )}
                  <input
                    type='text'
                    className='form-control form-control-solid text-primary'
                    value={formik.values.icon}
                    readOnly
                  />
                </div>
              )}
              {/* Icon */}

              {/* Order */}
              {menuModels.for_order !== formik.values.for_order && (
                <div className='fv-row mb-5'>
                  <label className='form-label fs-6 fw-bolder text-dark'>
                    {intl.formatMessage({id: 'LABEL_ORDER'})}
                  </label>
                  {action !== 'add' && (
                    <input
                      type='text'
                      className='form-control form-control-solid'
                      value={menuModels.for_order}
                      readOnly
                    />
                  )}
                  <input
                    type='text'
                    className='form-control form-control-solid text-primary is-valid'
                    value={formik.values.for_order}
                    readOnly
                  />
                </div>
              )}
              {/* Order */}

              {/* Status */}
              {action === 'add' ? (
                <div className='fv-row mb-5'>
                  <label className='form-label fs-6 fw-bolder text-dark'>Status</label>
                  <input
                    type='text'
                    className='form-control form-control-solid text-primary is-valid'
                    value={
                      formik.values.status === 't'
                        ? intl.formatMessage({id: 'LABEL_ACTIVE'})
                        : intl.formatMessage({id: 'LABEL_INACTIVE'})
                    }
                    readOnly
                  />
                </div>
              ) : (
                menuModels.status !== formik.values.status && (
                  <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark'>Status</label>
                    <input
                      type='text'
                      className='form-control form-control-solid'
                      value={
                        menuModels.status === 't'
                          ? intl.formatMessage({id: 'LABEL_ACTIVE'})
                          : intl.formatMessage({id: 'LABEL_INACTIVE'})
                      }
                      readOnly
                    />
                    <input
                      type='text'
                      className='form-control form-control-solid text-primary is-valid'
                      value={
                        formik.values.status === 't'
                          ? intl.formatMessage({id: 'LABEL_ACTIVE'})
                          : intl.formatMessage({id: 'LABEL_INACTIVE'})
                      }
                      readOnly
                    />
                  </div>
                )
              )}
              {/* Status */}

              {/* Access */}
              <div className='fv-row mb-5'>
                <label className='form-label fs-6 fw-bolder text-dark'>
                  {intl.formatMessage({id: 'LABEL_ACCESS'})}
                </label>
                <div className='checkbox-inline'>
                  <label className='checkbox'>
                    <Checkbox
                      name='view'
                      value={String(formik.values.access).charAt(0) === '1' ? true : false}
                      currentValue={String(formik.values.access).charAt(0)}
                      currentPayload={menuModels.access}
                      formik={formik}
                      action={action}
                      disabled={true}
                    />
                  </label>
                  <label className='checkbox'>
                    <Checkbox
                      name='add'
                      value={String(formik.values.access).charAt(1) === '1' ? true : false}
                      currentValue={String(formik.values.access).charAt(1)}
                      currentPayload={String(menuModels.access).charAt(1)}
                      formik={formik}
                      action={action}
                      disabled={true}
                    />
                  </label>
                  <label className='checkbox'>
                    <Checkbox
                      name='edit'
                      value={String(formik.values.access).charAt(2) === '1' ? true : false}
                      currentValue={String(formik.values.access).charAt(2)}
                      currentPayload={String(menuModels.access).charAt(2)}
                      formik={formik}
                      action={action}
                      disabled={true}
                    />
                  </label>
                  <label className='checkbox'>
                    <Checkbox
                      name='delete'
                      value={String(formik.values.access).charAt(3) === '1' ? true : false}
                      currentValue={String(formik.values.access).charAt(3)}
                      currentPayload={String(menuModels.access).charAt(3)}
                      formik={formik}
                      action={action}
                      disabled={true}
                    />
                  </label>
                  <label className='checkbox'>
                    <Checkbox
                      name='apv1'
                      value={String(formik.values.access).charAt(4) === '1' ? true : false}
                      currentValue={String(formik.values.access).charAt(4)}
                      currentPayload={String(menuModels.access).charAt(4)}
                      formik={formik}
                      action={action}
                      disabled={true}
                    />
                  </label>
                  <label className='checkbox'>
                    <Checkbox
                      name='apv2'
                      value={String(formik.values.access).charAt(5) === '1' ? true : false}
                      currentValue={String(formik.values.access).charAt(5)}
                      currentPayload={String(menuModels.access).charAt(5)}
                      formik={formik}
                      action={action}
                      disabled={true}
                    />
                  </label>
                  <label className='checkbox'>
                    <Checkbox
                      name='apv3'
                      value={String(formik.values.access).charAt(6) === '1' ? true : false}
                      currentValue={String(formik.values.access).charAt(6)}
                      currentPayload={String(menuModels.access).charAt(6)}
                      formik={formik}
                      action={action}
                      disabled={true}
                    />
                  </label>
                </div>
              </div>
              {/* Access */}
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-outline btn-btn-outline-info me-3'
                data-kt-users-modal-action='close'
                onClick={() => setIsDialogOpen(false)}
              >
                {intl.formatMessage({id: 'BUTTON_LABEL_BACK'})}
              </button>

              <button
                type='button'
                className={`btn ${loading ? 'btn-secondary' : 'btn-success'}`}
                data-kt-users-modal-action='submit'
                onClick={() => formik.handleSubmit()}
                disabled={loading ? true : false}
              >
                {!loading && (
                  <span className='indicator-label'>
                    {intl.formatMessage({id: 'BUTTON_LABEL_SUBMIT'})}
                  </span>
                )}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    {intl.formatMessage({id: 'BUTTON_LABEL_PLEASE_WAIT'})}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
      {/* Dialog */}
    </>
  )
}

export {ViewDrawerForm}
