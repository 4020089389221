import axios, {AxiosResponse} from 'axios'

import {APIResponse} from 'app/components'

import {ListQueryResponse, ItemID} from '.'

const ITEM_API_URL = `${process.env.REACT_APP_MOBILE_LIBRARY_API_URL}/item`
const CATEGORIES_API_URL = `${process.env.REACT_APP_MOBILE_LIBRARY_API_URL}/categories`
const SALES_TYPE_API_URL = `${process.env.REACT_APP_MOBILE_LIBRARY_API_URL}/sales-type`
const HOLDING_API_URL = `${process.env.REACT_APP_MOBILE_APP_API_URL}/holding`
const COMPANY_API_URL = `${process.env.REACT_APP_MOBILE_APP_API_URL}/company`
const BRAND_API_URL = `${process.env.REACT_APP_MOBILE_APP_API_URL}/brand`
const OUTLET_API_URL = `${process.env.REACT_APP_MOBILE_APP_API_URL}/outlet`
const MOBILE_X_API_KEY = process.env.REACT_APP_MOBILE_APP_API_KEY

const fetch = (query: any): Promise<ListQueryResponse> => {
  return axios
    .get(`${ITEM_API_URL}/list?${query}`, {
      headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
    })
    .then((response: AxiosResponse<ListQueryResponse>) => response.data)
}

const fetchCategoriesOptions = (mode: any, brand_id: string) => {
  return axios.get(`${CATEGORIES_API_URL}/list-options/${mode}/${brand_id}`, {
    headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
  })
}

const fetchHoldingOptions = (mode: any) => {
  return axios.get(`${HOLDING_API_URL}/list-options/${mode}`, {
    headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
  })
}

const fetchCompanyOptions = (mode: any, holding_id: string | undefined) => {
  return axios.get(`${COMPANY_API_URL}/list-options/${mode}/${holding_id}`, {
    headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
  })
}

const fetchBrandOptions = (mode: any, company_id: string | undefined) => {
  return axios.get(`${BRAND_API_URL}/list-options/${mode}/${company_id}`, {
    headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
  })
}

const fetchOutletOptions = (mode: any, brand: string | undefined) => {
  return axios.get(`${OUTLET_API_URL}/list-options/${mode}/${brand}`, {
    headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
  })
}

const fetchSalesTypeOptions = (mode: any, brand: string | undefined) => {
  return axios.get(`${SALES_TYPE_API_URL}/list-options/${mode}/${brand}`, {
    headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
  })
}

const getCountCategoryItems = (category_id: ItemID) => {
  return axios.get(`${ITEM_API_URL}/category/${category_id}/count`, {
    headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
  })
}

const get = (item_id: ItemID): Promise<APIResponse | undefined> => {
  return axios
    .get(`${ITEM_API_URL}/${item_id}/detail`, {
      headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
    })
    .then((response: AxiosResponse<APIResponse>) => response.data)
}

const getCategoryDetails = (category_id: string): Promise<APIResponse | undefined> => {
  return axios
    .get(`${ITEM_API_URL}/category/${category_id}/detail`, {
      headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
    })
    .then((response: AxiosResponse<APIResponse>) => response.data)
}

const addData = (payload: any): Promise<APIResponse | undefined> => {
  return axios
    .post(
      `${ITEM_API_URL}`,
      {payload},
      {
        headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
      }
    )
    .then((response: AxiosResponse<APIResponse>) => response.data)
}

const updateData = (payload: any): Promise<APIResponse | undefined> => {
  return axios
    .put(
      `${ITEM_API_URL}/${payload.item_id}/update`,
      {payload},
      {
        headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
      }
    )
    .then((response: AxiosResponse<APIResponse>) => response.data)
}

const deleteData = (
  item_id: any,
  menu_id: any,
  creator_id: any,
  creator_by: any
): Promise<APIResponse | undefined> => {
  return axios
    .delete(`${ITEM_API_URL}/${item_id}/${menu_id}/${creator_id}/${creator_by}/delete`, {
      headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
    })
    .then((response: AxiosResponse<APIResponse>) => response.data)
}

export {
  fetch,
  fetchCategoriesOptions,
  fetchHoldingOptions,
  fetchCompanyOptions,
  fetchBrandOptions,
  fetchSalesTypeOptions,
  fetchOutletOptions,
  getCountCategoryItems,
  get,
  getCategoryDetails,
  updateData,
  addData,
  deleteData,
}
