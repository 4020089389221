/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import {useIntl} from 'react-intl'
import {DatePicker} from 'rsuite'
import moment from 'moment'
import isAfter from 'date-fns/isAfter'

import {ShortcutMenuButton} from 'app/components'

import {useListQueryRequest} from '../providers'

const ListFilter = () => {
  const intl = useIntl()
  const {state, updateState} = useListQueryRequest()

  const [period, setPeriod] = useState(state?.period === null ? null : state?.period)
  const handleCallback = (period: any) => {
    if (period !== null) {
      setPeriod(moment.utc(period).format('YYYY-MM'))
      updateState({
        period: moment.utc(period).format('YYYY-MM'),
      })
    }
  }

  return (
    <>
      <div className='card-header border-0 mb-5'>
        <div className='card-body p-0 pt-5'>
          <div className='row mb-1'>
            <div className='fv-row d-flex justify-content-between'>
              {/* 1 Form */}
              <div className='col-sm-2'>
                {/* Input group */}
                <div className='fv-row'>
                  <label className='form-label fs-6 fw-bold'>
                    {intl.formatMessage({id: 'LABEL_FILTER_DATERANGE'})}
                  </label>
                  <DatePicker
                    oneTap
                    value={
                      period === null
                        ? null
                        : new Date(moment(period?.split('[-]')[0]).add(1, 'days').toDate())
                    }
                    isoWeek
                    block
                    cleanable={false}
                    format='yyyy-MM'
                    placement={`bottomStart`}
                    placeholder={intl.formatMessage({id: 'TOOLTIP_SELECT_ALL'})}
                    onChange={handleCallback}
                    shouldDisableDate={(date) => isAfter(date, new Date())}
                  />
                </div>
                {/* Input group */}
              </div>
              {/* 1 Form */}

              {/* 2 Form */}
              <div className='col-sm-2'>
                <div className='fv-row mb-5'></div>

                <ShortcutMenuButton />
              </div>
              {/* 2 Form */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {ListFilter}
