import {FC} from 'react'
import {useIntl} from 'react-intl'

type Props = {
  row?: string
}

function coloringRows(rowLabel: any) {
  switch (rowLabel) {
    case 't':
      return '-primary'
    default:
      return '-secondary'
  }
}

const RowStatusCellFormatter: FC<Props> = ({row}) => {
  const intl = useIntl()
  return (
    <div className={`badge badge${coloringRows(row)} fw-bolder`}>
      {row === 't'
        ? intl.formatMessage({id: 'LABEL_ACTIVE'})
        : intl.formatMessage({id: 'LABEL_INACTIVE'})}
    </div>
  )
}

export {RowStatusCellFormatter}
