import {Dispatch, SetStateAction} from 'react'

import {CurrentMenuAccessModel} from 'app/context/models'

import {OutletID} from '.'

export type ActionContextProps = {
  currentAccessMenu: CurrentMenuAccessModel | undefined
  setCurrentAccessMenu: Dispatch<SetStateAction<CurrentMenuAccessModel | undefined>>
  isLoading: boolean
  setIsLoading: Dispatch<SetStateAction<boolean>>
  showFilter: boolean
  setShowFilter: Dispatch<SetStateAction<boolean>>
  action: string
  setAction: Dispatch<SetStateAction<string>>
  itemIdForUpdate?: OutletID
  setItemIdForUpdate: Dispatch<SetStateAction<OutletID>>
  holdingOptions: any
  setHoldingOptions: Dispatch<SetStateAction<any>>
  companyOptions: any
  setCompanyOptions: Dispatch<SetStateAction<any>>
  brandOptions: any
  setBrandOptions: Dispatch<SetStateAction<any>>
  provinceOptions: any
  setProvinceOptions: Dispatch<SetStateAction<any>>
  cityOptions: any
  setCityOptions: Dispatch<SetStateAction<any>>
  districtOptions: any
  setDistrictOptions: Dispatch<SetStateAction<any>>
  villageOptions: any
  setVillageOptions: Dispatch<SetStateAction<any>>
  areaOptions: any
  setAreaOptions: Dispatch<SetStateAction<any>>
  clusterOptions: any
  setClusterOptions: Dispatch<SetStateAction<any>>
}

export const initialAction: ActionContextProps = {
  currentAccessMenu: undefined,
  setCurrentAccessMenu: () => {},
  isLoading: false,
  setIsLoading: () => {},
  showFilter: false,
  setShowFilter: () => {},
  action: '',
  setAction: () => {},
  setItemIdForUpdate: () => {},
  holdingOptions: undefined,
  setHoldingOptions: () => {},
  companyOptions: undefined,
  setCompanyOptions: () => {},
  brandOptions: undefined,
  setBrandOptions: () => {},
  provinceOptions: undefined,
  setProvinceOptions: () => {},
  cityOptions: undefined,
  setCityOptions: () => {},
  districtOptions: undefined,
  setDistrictOptions: () => {},
  villageOptions: undefined,
  setVillageOptions: () => {},
  areaOptions: undefined,
  setAreaOptions: () => {},
  clusterOptions: undefined,
  setClusterOptions: () => {},
}
