import axios, {AxiosResponse} from 'axios'

import {APIResponse} from 'app/components'

import {ListQueryResponse, BrandID} from '.'

const HOLDING_API_URL = `${process.env.REACT_APP_MOBILE_APP_API_URL}/holding`
const COMPANY_API_URL = `${process.env.REACT_APP_MOBILE_APP_API_URL}/company`
const BRAND_API_URL = `${process.env.REACT_APP_MOBILE_APP_API_URL}/brand`
const MOBILE_X_API_KEY = process.env.REACT_APP_MOBILE_APP_API_KEY

const fetch = (query: any): Promise<ListQueryResponse> => {
  return axios
    .get(`${BRAND_API_URL}/list?${query}`, {
      headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
    })
    .then((response: AxiosResponse<ListQueryResponse>) => response.data)
}

const fetchHoldingOptions = (mode: any) => {
  return axios.get(`${HOLDING_API_URL}/list-options/${mode}`, {
    headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
  })
}

const fetchCompanyOptions = (mode: any, holding_id: string | undefined) => {
  return axios.get(`${COMPANY_API_URL}/list-options/${mode}/${holding_id}`, {
    headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
  })
}

const get = (brand_id: BrandID): Promise<APIResponse | undefined> => {
  return axios
    .get(`${BRAND_API_URL}/${brand_id}/detail`, {
      headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
    })
    .then((response: AxiosResponse<APIResponse>) => response.data)
}

const addData = (payload: any): Promise<APIResponse | undefined> => {
  const formData = new FormData()

  formData.append('company_id', payload.company_id)
  formData.append('creator_by', payload.creator_by)
  formData.append('creator_id', payload.creator_id)
  formData.append('description', payload.description)
  formData.append('email', payload.email)
  formData.append(`file`, payload.file)
  formData.append('holding_id', payload.holding_id)
  formData.append('menu_id', payload.menu_id)
  formData.append('name', payload.name)
  formData.append('slug', payload.slug)
  formData.append('status', payload.status)

  return axios.post(`${BRAND_API_URL}`, formData, {
    headers: {'x-api-key': `${MOBILE_X_API_KEY}`, 'content-type': 'multipart/form-data'},
  })
}

const updateData = (payload: any): Promise<APIResponse | undefined> => {
  const formData = new FormData()

  formData.append('brand_id', payload.brand_id)
  formData.append('company_id', payload.company_id)
  formData.append('creator_by', payload.creator_by)
  formData.append('creator_id', payload.creator_id)
  formData.append('description', payload.description)
  formData.append('email', payload.email)
  formData.append(`file`, payload.file)
  formData.append('holding_id', payload.holding_id)
  formData.append('menu_id', payload.menu_id)
  formData.append('name', payload.name)
  formData.append('slug', payload.slug)
  formData.append('status', payload.status)

  return axios.put(`${BRAND_API_URL}/${payload.brand_id}/update`, formData, {
    headers: {'x-api-key': `${MOBILE_X_API_KEY}`, 'content-type': 'multipart/form-data'},
  })
}

const deleteData = (
  brand_id: any,
  menu_id: any,
  creator_id: any,
  creator_by: any
): Promise<APIResponse | undefined> => {
  return axios
    .delete(`${BRAND_API_URL}/${brand_id}/${menu_id}/${creator_id}/${creator_by}/delete`, {
      headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
    })
    .then((response: AxiosResponse<APIResponse>) => response.data)
}

export {fetch, fetchHoldingOptions, fetchCompanyOptions, get, updateData, addData, deleteData}
