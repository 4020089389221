/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {Dialog} from '@headlessui/react'
import clsx from 'clsx'
import {Drawer, Toggle} from 'rsuite'

import {
  NotAuthRedirectForm,
  FormLog,
  getFormAction,
  SpinnerIndicator,
  RowFileFormatter,
  inputTrimSpace,
} from 'app/components'
import {getAuth, removeAuth, useAuth} from 'app/modules/auth'

import {DetailModel, addData, deleteData, initialData, updateData} from '../../helpers'
import {useAction, useListQueryResponse} from '../../providers'

type Props = {
  isLoading: boolean
  media: DetailModel
  appMenuID: any
  modalID: any
  menuName: any
}

const ViewDrawerForm: FC<Props> = ({menuName, media, isLoading, appMenuID, modalID}) => {
  const auth = getAuth()
  const intl = useIntl()
  const [tab, setTab] = useState('data')
  const {currentUser} = useAuth()
  const {setItemIdForUpdate, action} = useAction()
  const {refetch} = useListQueryResponse()
  const [isAuth, setIsAuth] = useState(true)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [completed, setCompleted] = useState(false)
  const fileRef = useRef(null)
  const MAX_SIZED = 0.3
  const [baseSize, setBaseSize] = useState(0)
  const [size, setSize] = useState(0)
  const [preview, setPreview] = useState<string | ArrayBuffer | null>(null) // MB

  const baseValidation = Yup.object().shape({
    name: Yup.string()
      .min(3, intl.formatMessage({id: 'VALIDATION_MIN_LENGTH_FIELD'}, {min: 3}))
      .max(125, intl.formatMessage({id: 'VALIDATION_MAX_LENGTH_FIELD'}, {max: 125}))
      .required(
        intl.formatMessage(
          {id: 'VALIDATION_REQUIRED_FIELD'},
          {name: intl.formatMessage({id: 'LABEL_NAME'})}
        )
      ),
    file: Yup.string().required(
      intl.formatMessage({id: 'VALIDATION_REQUIRED_FIELD'}, {name: 'Logo'})
    ),
    for_order: Yup.string().required(
      intl.formatMessage(
        {id: 'VALIDATION_REQUIRED_FIELD'},
        {name: intl.formatMessage({id: 'LABEL_ORDER'})}
      )
    ),
  })

  const [models] = useState<DetailModel>({
    ...media,
    social_media_id: media.social_media_id || initialData.social_media_id,
    name: media.name || initialData.name,
    status: media.status || initialData.status,
    url: media.url || initialData.url,
    for_order: media.for_order || initialData.for_order,
    logo: media.logo || initialData.logo,
    file: media.file || initialData.file,
    created_at: media.created_at || initialData.created_at,
    created_by: media.created_by || initialData.created_by,
    updated_at: media.updated_at || initialData.updated_at,
    updated_by: media.updated_by || initialData.updated_by,
    menu_id: appMenuID,
    creator_id: currentUser?.user_id,
    creator_by: currentUser?.username,
  })

  const formik = useFormik({
    initialValues: models,
    validationSchema: action !== 'delete' ? baseValidation : undefined,
    onSubmit: async (values, {setSubmitting, setStatus, setFieldError}) => {
      setIsDialogOpen(false)
      action === 'edit'
        ? requestUpdateData(values, setStatus, setSubmitting, setFieldError)
        : action === 'add'
          ? requestAddData(values, setStatus, setSubmitting, setFieldError)
          : requestDeleteData(values, setStatus, setSubmitting)
    },
    onReset: (values) => {
      values = models

      if (fileRef && fileRef.current) {
        const curRef: any = fileRef.current
        curRef.value = null
        setSize(0)
      }
    },
  })

  const requestUpdateData = async (
    values: any,
    setStatus: any,
    setSubmitting: any,
    setFieldError: any
  ) => {
    setLoading(true)
    setStatus(null)
    setSubmitting(true)

    // request update to API
    try {
      // fix for order is number
      values.for_order = parseInt(values.for_order)

      const res = await updateData(values)

      // throw message error
      if (res?.data.meta.code !== 200) {
        if (res?.data.meta.message === 'name already used') {
          setStatus(intl.formatMessage({id: 'RESPONSE_RECEIPT_MEDIA_NAME_ALREADY_USED'}))
          setFieldError(
            'name',
            intl.formatMessage({id: 'RESPONSE_RECEIPT_MEDIA_NAME_ALREADY_USED'})
          )
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_RECEIPT_MEDIA_EDIT_SUCCESS'}))
        setCompleted(true)
        setTimeout(function () {
          setItemIdForUpdate(undefined)
          refetch()
        }, 2e3)
      }
    } catch (error: any) {
      console.error(error)
      if (error.response.status === 401) {
        setStatus(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
        removeAuth()
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
      }
      setSubmitting(false)
      setLoading(false)
      setTimeout(function () {
        setStatus(null)
      }, 9e3)

      return false
    } finally {
      setTimeout(() => {
        setLoading(false)
        setSubmitting(false)
      }, 5e2)
    }
  }
  const requestAddData = async (
    values: any,
    setStatus: any,
    setSubmitting: any,
    setFieldError: any
  ) => {
    setLoading(true)
    setStatus(null)
    setSubmitting(true)

    // request add to API
    try {
      const res = await addData(values)

      // throw message error
      if (res?.data.meta.code !== 200) {
        if (res?.data.meta.message === 'name already used') {
          setStatus(intl.formatMessage({id: 'RESPONSE_RECEIPT_MEDIA_NAME_ALREADY_USED'}))
          setFieldError(
            'name',
            intl.formatMessage({id: 'RESPONSE_RECEIPT_MEDIA_NAME_ALREADY_USED'})
          )
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_RECEIPT_MEDIA_ADD_SUCCESS'}))
        setCompleted(true)
        setTimeout(function () {
          setItemIdForUpdate(undefined)
          refetch()
        }, 2e3)
      }
    } catch (error: any) {
      console.error(error)
      if (error.response.status === 401) {
        setStatus(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
        removeAuth()
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
      }
      setSubmitting(false)
      setLoading(false)
      setTimeout(function () {
        setStatus(null)
      }, 9e3)

      return false
    } finally {
      setTimeout(() => {
        setLoading(false)
        setSubmitting(false)
      }, 5e2)
    }
  }
  const requestDeleteData = async (values: any, setStatus: any, setSubmitting: any) => {
    setLoading(true)
    setStatus(null)
    setSubmitting(true)

    // request delete to API
    try {
      const res = await deleteData(
        values.social_media_id,
        values.menu_id,
        values.creator_id,
        values.creator_by
      )

      // throw message error
      if (res?.meta.code !== 200) {
        if (res?.meta.message === 'not inactive') {
          setStatus(intl.formatMessage({id: 'RESPONSE_RECEIPT_MEDIA_IS_ACTIVE'}))
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
        if (res?.meta.message === 'inused by other data') {
          setStatus(intl.formatMessage({id: 'RESPONSE_RECEIPT_MEDIA_IS_INUSED'}))
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_RECEIPT_MEDIA_DELETE_SUCCESS'}))
        setCompleted(true)
        setTimeout(function () {
          setItemIdForUpdate(undefined)
          refetch()
        }, 2e3)
      }
    } catch (error: any) {
      console.error(error)
      if (error.response.status === 401) {
        setStatus(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
        removeAuth()
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
      }
      setSubmitting(false)
      setLoading(false)
      setTimeout(function () {
        setStatus(null)
      }, 9e3)

      return false
    } finally {
      setTimeout(() => {
        setLoading(false)
        setSubmitting(false)
      }, 5e2)
    }
  }

  useEffect(() => {
    auth === undefined && setIsAuth(false)
  }, [auth])

  const handleInputFile = (event: any) => {
    // Update the state
    formik.setFieldValue('file', null)

    const file = event.target.files

    if (file[0] && file[0].size > 0) {
      formik.setFieldValue('file', event.target.files[0] === null ? '' : event.target.files[0])
      setSize(Number((file[0].size / 1024 / 1000).toFixed(1)))
      setBaseSize(file[0].size)

      var reader = new FileReader()
      reader.readAsDataURL(file[0])
      reader.onloadend = function (e: any) {
        setPreview(reader.result)
      }

      if (Number((file[0].size / 1024 / 1000).toFixed(1)) > Number(MAX_SIZED)) {
        const curRef: any = fileRef.current
        curRef.value = null
        return false
      }
    } else {
      setSize(0)
      return false
    }
  }

  return (
    <>
      {!isAuth && <NotAuthRedirectForm />}

      <Drawer.Header>
        <Drawer.Title className='fw-bolder'>
          {isLoading ? (
            <SpinnerIndicator label={intl.formatMessage({id: 'BUTTON_LABEL_PLEASE_WAIT'})} />
          ) : (
            <>
              {getFormAction(
                action,
                intl.formatMessage({id: 'BUTTON_LABEL_VIEW'}),
                intl.formatMessage({id: 'BUTTON_LABEL_EDIT'}),
                intl.formatMessage({id: 'BUTTON_LABEL_ADD'}),
                intl.formatMessage({id: 'BUTTON_LABEL_DELETE'})
              )}{' '}
              {menuName}
            </>
          )}
        </Drawer.Title>
        <Drawer.Actions>
          {action !== 'view' && models !== formik.values && !completed && (
            <button
              type='reset'
              className='btn btn-light me-3'
              data-kt-users-modal-action='reset'
              onClick={formik.handleReset}
              disabled={loading}
            >
              {!loading && (
                <span className='indicator-label'>
                  {intl.formatMessage({id: 'BUTTON_LABEL_RESET'})}
                </span>
              )}
            </button>
          )}

          {action !== 'view' && models !== formik.values && !completed && (
            <button
              type='submit'
              className='btn btn-primary'
              data-kt-users-modal-action='submit'
              onClick={() => {
                if (action === 'add' && (baseSize === 0 || size > Number(MAX_SIZED))) {
                  formik.setFieldError('file', 'validation errors')
                  return false
                }
                if (action === 'edit' && size > -1 && size > Number(MAX_SIZED)) {
                  formik.setFieldError('file', 'validation errors')
                  return false
                }
                setIsDialogOpen(true)
                setLoading(true)
                setTimeout(function () {
                  setLoading(false)
                }, 2e3)
              }}
              disabled={isLoading || loading || formik.isSubmitting || !formik.isValid}
            >
              {!loading && (
                <span className='indicator-label'>
                  {intl.formatMessage({id: 'BUTTON_LABEL_SUBMIT'})}
                </span>
              )}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {intl.formatMessage({id: 'BUTTON_LABEL_SUBMITTING'})}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          )}

          {action === 'delete' && !completed && (
            <button
              type='submit'
              className='btn btn-danger'
              data-kt-users-modal-action='submit'
              onClick={() => formik.handleSubmit()}
              disabled={isLoading || loading || formik.isSubmitting || !formik.isValid}
            >
              {!loading && (
                <span className='indicator-label'>
                  {intl.formatMessage({id: 'BUTTON_LABEL_DELETE'})}
                </span>
              )}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {intl.formatMessage({id: 'BUTTON_LABEL_SUBMITTING'})}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          )}
        </Drawer.Actions>
      </Drawer.Header>

      <Drawer.Body style={{padding: 0}}>
        {/* Response Message */}
        {formik.status &&
          (formik.status === intl.formatMessage({id: 'RESPONSE_RECEIPT_MEDIA_ADD_SUCCESS'}) ||
          formik.status === intl.formatMessage({id: 'RESPONSE_RECEIPT_MEDIA_EDIT_SUCCESS'}) ||
          formik.status === intl.formatMessage({id: 'RESPONSE_RECEIPT_MEDIA_DELETE_SUCCESS'}) ? (
            <div className='m-lg-5 p-2 bg-light-success rounded'>
              <div className='text-success text-center font-weight-bold fw-bolder'>
                {formik.status}
              </div>
            </div>
          ) : (
            <div className={`m-lg-5 p-2 alert alert-danger`}>
              <div className='alert-text text-center font-weight-bold fw-bolder'>
                {formik.status}
              </div>
            </div>
          ))}
        {/* Response Message */}

        {/* Form */}
        <div className={`modal-body m-5 ${action === 'add' ? '' : ' mt-0'}`}>
          {action === 'add' ? (
            <form id={`${modalID}-form`} className='form' onSubmit={formik.handleSubmit} noValidate>
              <div className='row'>
                {/* First Col */}
                <div className='col-lg-6'>
                  {/* Name */}
                  <div className='fv-row mb-5'>
                    {formik.values.name !== '' && (
                      <label className='form-label fs-6 fw-bolder text-dark required'>
                        {intl.formatMessage({id: 'LABEL_NAME'})}
                      </label>
                    )}
                    <input
                      type='text'
                      className={clsx(
                        'form-control form-control-solid',
                        {'is-invalid': formik.touched.name && formik.errors.name},
                        {
                          'is-valid': formik.touched.name && !formik.errors.name,
                        }
                      )}
                      placeholder={intl.formatMessage({id: 'LABEL_NAME'})}
                      {...formik.getFieldProps('name')}
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger fw-bolder'>
                          {formik.errors.name}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {/* Name */}

                  {/* URL */}
                  <div className='fv-row mb-5'>
                    {formik.values.url !== '' && (
                      <label className='form-label fs-6 fw-bolder text-dark required'>
                        {intl.formatMessage({id: 'LABEL_LINK'})}
                      </label>
                    )}
                    <input
                      type='text'
                      className={clsx(
                        'form-control form-control-solid mb-5',
                        {'is-invalid': formik.touched.url && formik.errors.url},
                        {
                          'is-valid': formik.touched.url && !formik.errors.url,
                        }
                      )}
                      placeholder={intl.formatMessage({id: 'LABEL_LINK'})}
                      {...formik.getFieldProps('url')}
                      onChange={(e) => formik.setFieldValue('url', inputTrimSpace(e.target.value))}
                    />
                  </div>
                  {/* URL */}

                  {/* Logo */}
                  <div className='fv-row mb-5'>
                    <span className='d-flex flex-column'>
                      <label className='form-label fs-6 fw-bolder text-dark required'>Logo</label>
                      <span className='fs-7 text-muted mb-5'>
                        {intl.formatMessage({id: 'LABEL_INPUT_FILE_IMAGE_DESC'})}
                      </span>
                      <input
                        ref={fileRef}
                        type='file'
                        className={clsx(
                          'form-control form-control-solid',
                          {'is-invalid': formik.touched.file && formik.errors.file},
                          {
                            'is-valid': formik.touched.file && !formik.errors.file,
                          }
                        )}
                        name='file'
                        onChange={handleInputFile}
                        multiple={false}
                        accept='image/*'
                      />
                      {formik.errors.file ? (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block text-danger fw-bolder'>
                            {intl.formatMessage(
                              {id: 'VALIDATION_REQUIRED_FIELD'},
                              {
                                name: 'Logo',
                              }
                            )}
                          </div>
                        </div>
                      ) : null}

                      {size > Number(MAX_SIZED) ? (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block text-danger fw-bolder'>
                            {intl.formatMessage(
                              {id: 'VALIDATION_MAX_FILE_SIZE'},
                              {
                                max: Number(MAX_SIZED),
                              }
                            )}
                          </div>
                        </div>
                      ) : null}
                    </span>
                  </div>
                  {/* Logo */}
                </div>
                {/* First Col */}

                {/* Second Col */}
                <div className='col-lg-6'>
                  {/* Order */}
                  <div className='fv-row mb-5'>
                    {formik.values.for_order && formik.values.for_order > 0 && (
                      <label className='form-label fs-6 fw-bolder text-dark required'>
                        {intl.formatMessage({id: 'LABEL_ORDER'})}
                      </label>
                    )}
                    <input
                      type='number'
                      className={clsx(
                        'form-control form-control-solid',
                        {'is-invalid': formik.touched.for_order && formik.errors.for_order},
                        {
                          'is-valid': formik.touched.for_order && !formik.errors.for_order,
                        }
                      )}
                      placeholder={intl.formatMessage({id: 'LABEL_ORDER'})}
                      {...formik.getFieldProps('for_order')}
                      onChange={(e) => {
                        formik.setFieldValue('for_order', parseInt(e.target.value))
                      }}
                    />
                    {formik.touched.for_order && formik.errors.for_order ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger'>{formik.errors.for_order}</div>
                      </div>
                    ) : null}
                  </div>
                  {/* Order */}

                  {/* Status */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark required'>Status</label>
                    <div className='form-check form-switch form-check-custom form-check-solid mt-1'>
                      <Toggle
                        size='lg'
                        checkedChildren={intl.formatMessage({id: 'LABEL_ACTIVE'})}
                        unCheckedChildren={intl.formatMessage({id: 'LABEL_INACTIVE'})}
                        checked={formik.values.status === 't' ? true : false}
                        onChange={() =>
                          formik.setFieldValue('status', formik.values.status === 't' ? 'f' : 't')
                        }
                      />
                    </div>
                  </div>
                  {/* Status */}
                </div>
                {/* Second Col */}
              </div>
            </form>
          ) : (
            <form id={`${modalID}-form`} className='form' onSubmit={formik.handleSubmit} noValidate>
              {/* Tab Header */}
              <div className='card-toolbar'>
                <ul
                  className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-2 mt-2'
                  role='tablist'
                >
                  <li className={`nav-item ${tab === 'data' ? 'fw-bolder' : ''}`}>
                    <a
                      className={clsx(`nav-link cursor-pointer`, {
                        active: tab === 'data',
                      })}
                      onClick={() => setTab('data')}
                      role='tab'
                    >
                      Data
                    </a>
                  </li>
                  <li className={`nav-item ${tab === 'log' ? 'fw-bolder' : ''}`}>
                    <a
                      className={clsx(`nav-link cursor-pointer`, {
                        active: tab === 'log',
                      })}
                      onClick={() => setTab('log')}
                      role='tab'
                    >
                      Log
                    </a>
                  </li>
                </ul>
              </div>
              {/* Tab Header */}

              {/* Tab Body */}
              <div className='card-body p-0 pt-2'>
                <div className='tab-content'>
                  {/* Data */}
                  <div
                    className={clsx('tab-pane', {
                      active: tab === 'data',
                    })}
                  >
                    <div className='row'>
                      {/* First Col */}
                      <div className='col-lg-6'>
                        {/* Name */}
                        <div className='fv-row mb-5'>
                          {formik.values.name !== '' && (
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action === 'edit' ? 'required' : ''
                              }`}
                            >
                              {intl.formatMessage({id: 'LABEL_NAME'})}
                            </label>
                          )}
                          <input
                            type='text'
                            className={clsx(
                              'form-control form-control-solid',
                              {'is-invalid': formik.touched.name && formik.errors.name},
                              {
                                'is-valid': formik.touched.name && !formik.errors.name,
                              }
                            )}
                            placeholder={intl.formatMessage({id: 'LABEL_NAME'})}
                            {...formik.getFieldProps('name')}
                            readOnly={action === 'edit' ? false : true}
                          />
                          {formik.touched.name && formik.errors.name ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {formik.errors.name}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Name */}

                        {/* URL */}
                        <div className='fv-row mb-5'>
                          {formik.values.url !== '' && (
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action === 'edit' ? 'required' : ''
                              }`}
                            >
                              {intl.formatMessage({id: 'LABEL_LINK'})}
                            </label>
                          )}
                          <input
                            type='text'
                            className={clsx(
                              'form-control form-control-solid mb-5',
                              {'is-invalid': formik.touched.url && formik.errors.url},
                              {
                                'is-valid': formik.touched.url && !formik.errors.url,
                              }
                            )}
                            placeholder={intl.formatMessage({id: 'LABEL_LINK'})}
                            {...formik.getFieldProps('url')}
                            onChange={(e) =>
                              formik.setFieldValue('url', inputTrimSpace(e.target.value))
                            }
                            readOnly={action === 'edit' ? false : true}
                          />
                        </div>
                        {/* URL */}

                        {/* Logo */}
                        <div className='fv-row mb-5'>
                          <span className='d-flex flex-column'>
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action === 'edit' ? ' required' : ''
                              }`}
                            >
                              Logo
                            </label>
                            {action === 'edit' && (
                              <span className='fs-7 text-muted mb-5'>
                                {intl.formatMessage({id: 'LABEL_INPUT_FILE_IMAGE_DESC'})}
                              </span>
                            )}
                            {action === 'edit' ? (
                              <>
                                <input
                                  ref={fileRef}
                                  type='file'
                                  className={clsx(
                                    'form-control form-control-solid',
                                    {'is-invalid': formik.touched.file && formik.errors.file},
                                    {
                                      'is-valid': formik.touched.file && !formik.errors.file,
                                    }
                                  )}
                                  name='file'
                                  onChange={handleInputFile}
                                  multiple={false}
                                  accept='image/*'
                                />
                                {formik.errors.file ? (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block text-danger fw-bolder'>
                                      {intl.formatMessage(
                                        {id: 'VALIDATION_REQUIRED_FIELD'},
                                        {
                                          name: 'Logo',
                                        }
                                      )}
                                    </div>
                                  </div>
                                ) : null}

                                {size > Number(MAX_SIZED) ? (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block text-danger fw-bolder'>
                                      {intl.formatMessage(
                                        {id: 'VALIDATION_MAX_FILE_SIZE'},
                                        {
                                          max: Number(MAX_SIZED),
                                        }
                                      )}
                                    </div>
                                  </div>
                                ) : null}
                              </>
                            ) : (
                              <div className='d-flex align-items-center'>
                                <RowFileFormatter
                                  size={200}
                                  fileUrl={models.logo}
                                  fileID={models.social_media_id}
                                />
                              </div>
                            )}
                          </span>
                        </div>
                        {/* Logo */}
                      </div>
                      {/* First Col */}

                      {/* Second Col */}
                      <div className='col-lg-6'>
                        {/* Order */}
                        <div className='fv-row mb-5'>
                          {formik.values.for_order && formik.values.for_order > 0 && (
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action === 'edit' ? 'required' : ''
                              }`}
                            >
                              {intl.formatMessage({id: 'LABEL_ORDER'})}
                            </label>
                          )}
                          <input
                            type={`${action === 'edit' ? 'number' : 'text'}`}
                            className={clsx(
                              'form-control form-control-solid',
                              {'is-invalid': formik.touched.for_order && formik.errors.for_order},
                              {
                                'is-valid': formik.touched.for_order && !formik.errors.for_order,
                              }
                            )}
                            placeholder={intl.formatMessage({id: 'LABEL_ORDER'})}
                            {...formik.getFieldProps('for_order')}
                            readOnly={action === 'edit' ? false : true}
                            onChange={(e) => {
                              formik.setFieldValue('for_order', e.target.value)
                            }}
                          />
                          {formik.touched.for_order && formik.errors.for_order ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger'>
                                {formik.errors.for_order}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Order */}

                        {/* Status */}
                        <div className='fv-row mb-5'>
                          {formik.values.status !== '' && (
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action === 'edit' ? 'required' : ''
                              }`}
                            >
                              Status
                            </label>
                          )}
                          <div className='form-check form-switch form-check-custom form-check-solid mt-1'>
                            <Toggle
                              size='lg'
                              readOnly={action !== 'edit' ? true : false}
                              checkedChildren={intl.formatMessage({id: 'LABEL_ACTIVE'})}
                              unCheckedChildren={intl.formatMessage({id: 'LABEL_INACTIVE'})}
                              checked={formik.values.status === 't' ? true : false}
                              onChange={() =>
                                formik.setFieldValue(
                                  'status',
                                  formik.values.status === 't' ? 'f' : 't'
                                )
                              }
                            />
                          </div>
                          {formik.touched.status && formik.errors.status ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {formik.errors.status}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Status */}
                      </div>
                      {/* Second Col */}
                    </div>
                  </div>
                  {/* Data */}

                  {/* Detail */}
                  <div
                    className={clsx('tab-pane', {
                      active: tab === 'log',
                    })}
                  >
                    <FormLog payload={media} formik={formik} />
                  </div>
                  {/* Detail */}
                </div>
              </div>
              {/* Tab Body */}
            </form>
          )}
        </div>
        {/* Form */}
      </Drawer.Body>

      {/* Dialog */}
      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(true)}
        className='modal fade show d-block'
      >
        <Dialog.Panel className='modal-dialog modal-fullscreen'>
          <div className='modal-content'>
            <Dialog.Title className='modal-header'>
              {intl.formatMessage({id: 'DIALOG_HEADER_TITLE'})}
            </Dialog.Title>
            <div className='modal-body scroll-y mx-2 mx-xl-2 my-2'>
              <div className='row'>
                {/* First Col */}
                <div className='col-lg-6'>
                  {/* Name */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark'>
                      {intl.formatMessage({id: 'LABEL_NAME'})}
                    </label>
                    {models.name !== formik.values.name ? (
                      <>
                        {action !== 'add' && (
                          <input
                            type='text'
                            className='form-control form-control-solid'
                            value={models.name}
                            readOnly
                          />
                        )}
                        <input
                          type='text'
                          className='form-control form-control-solid text-primary is-valid'
                          value={formik.values.name}
                          readOnly
                        />
                      </>
                    ) : (
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        value={models.name}
                        readOnly
                      />
                    )}
                  </div>
                  {/* Name */}

                  {/* URL */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark'>
                      {intl.formatMessage({id: 'LABEL_LINK'})}
                    </label>
                    {models.url !== formik.values.url ? (
                      <>
                        {action !== 'add' && (
                          <input
                            type='text'
                            className='form-control form-control-solid'
                            value={models.url}
                            readOnly
                          />
                        )}
                        <input
                          type='text'
                          className='form-control form-control-solid'
                          value={formik.values.url}
                          readOnly
                        />
                      </>
                    ) : (
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        value={models.url}
                        readOnly
                      />
                    )}
                  </div>
                  {/* URL */}

                  {/* LOGO */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark'>Logo</label>
                    {models.file !== formik.values.file ? (
                      <>
                        {action !== 'add' && (
                          <div className='d-flex align-items-center mb-10'>
                            <RowFileFormatter
                              size={200}
                              fileUrl={models.logo}
                              fileID={models.social_media_id}
                            />
                          </div>
                        )}
                        <div className='d-flex align-items-center'>
                          <RowFileFormatter
                            size={200}
                            fileUrl={preview}
                            fileID={formik.values.social_media_id}
                          />
                        </div>
                      </>
                    ) : (
                      <div className='d-flex align-items-center'>
                        <RowFileFormatter
                          size={200}
                          fileUrl={models.logo}
                          fileID={models.social_media_id}
                        />
                      </div>
                    )}
                  </div>
                  {/* LOGO */}
                </div>
                {/* First Col */}

                {/* Second Col */}
                <div className='col-lg-6'>
                  {/* Order */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark'>
                      {intl.formatMessage({id: 'LABEL_ORDER'})}
                    </label>
                    {models.for_order?.toString() !== formik.values?.for_order?.toString() ? (
                      <>
                        {action !== 'add' && (
                          <input
                            type='text'
                            className='form-control form-control-solid'
                            value={models.for_order}
                            readOnly
                          />
                        )}
                        <input
                          type='text'
                          className='form-control form-control-solid text-primary is-valid'
                          value={formik.values.for_order}
                          readOnly
                        />
                      </>
                    ) : (
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        value={models.for_order}
                        readOnly
                      />
                    )}
                  </div>
                  {/* Order */}

                  {/* Status */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark'>
                      <label className='form-label fs-6 fw-bolder text-dark'>Status</label>
                    </label>
                    {models.status !== formik.values.status ? (
                      <>
                        {action !== 'add' && (
                          <input
                            type='text'
                            className='form-control form-control-solid'
                            value={
                              models.status === 't'
                                ? intl.formatMessage({id: 'LABEL_ACTIVE'})
                                : intl.formatMessage({id: 'LABEL_INACTIVE'})
                            }
                            readOnly
                          />
                        )}
                        <input
                          type='text'
                          className='form-control form-control-solid text-primary is-valid'
                          value={
                            formik.values.status === 't'
                              ? intl.formatMessage({id: 'LABEL_ACTIVE'})
                              : intl.formatMessage({id: 'LABEL_INACTIVE'})
                          }
                          readOnly
                        />
                      </>
                    ) : (
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        value={
                          models.status === 't'
                            ? intl.formatMessage({id: 'LABEL_ACTIVE'})
                            : intl.formatMessage({id: 'LABEL_INACTIVE'})
                        }
                        readOnly
                      />
                    )}
                  </div>
                  {/* Status */}
                </div>
                {/* Second Col */}
              </div>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-outline btn-btn-outline-info me-3'
                data-kt-users-modal-action='close'
                onClick={() => setIsDialogOpen(false)}
              >
                {intl.formatMessage({id: 'BUTTON_LABEL_BACK'})}
              </button>

              <button
                type='button'
                className={`btn ${loading ? 'btn-secondary' : 'btn-success'}`}
                data-kt-users-modal-action='submit'
                onClick={() => formik.handleSubmit()}
                disabled={loading ? true : false}
              >
                {!loading && (
                  <span className='indicator-label'>
                    {intl.formatMessage({id: 'BUTTON_LABEL_SUBMIT'})}
                  </span>
                )}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    {intl.formatMessage({id: 'BUTTON_LABEL_PLEASE_WAIT'})}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
      {/* Dialog */}
    </>
  )
}

export {ViewDrawerForm}
