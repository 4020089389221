import {FC, lazy, useEffect, useRef, useState} from 'react'
import toast from 'react-hot-toast'
import {useIntl} from 'react-intl'
import {Navigate, Outlet, Route, Routes, useLocation} from 'react-router-dom'

import {PageLink, PageTitle} from '_metronic/layout/core'
import {SuspendViewComponents} from 'app/components'
import {CurrentMenuAccessModel} from 'app/context/models'
import {useUserGroupModules} from 'app/context/providers'
import {getCurrentMenuAccessByRoleIDAndMenuID} from 'app/context/requests'
import {removeAuth, useAuth} from 'app/modules/auth'

import * as receiptMediaListState from 'app/modules/backoffice-orins-pos/pos-settings/receipt/media/providers/ListQueryStateContext'
import {initialListQueryState as receiptMediaListQueryState} from 'app/modules/backoffice-orins-pos/pos-settings/receipt/media/helpers/ListQueryModel'
import * as receiptDataListState from 'app/modules/backoffice-orins-pos/pos-settings/receipt/data/providers/ListQueryStateContext'
import {initialListQueryState as receiptDataListQueryState} from 'app/modules/backoffice-orins-pos/pos-settings/receipt/data/helpers/ListQueryModel'

const MediaPageProvider = lazy(() => import('./media/MediaPageProvider'))
const DataPageProvider = lazy(() => import('./data/DataPageProvider'))

const BackOfficeOrinsPOSReceiptWrapper: FC = () => {
  const intl = useIntl()
  const {pathname} = useLocation()
  const {
    currentModuleLabel,
    currentModulePath,
    currentSiteLabel,
    currentSitePath,
    currentParentMenuLabel,
    currentParentMenuPath,
    currentMenuLabel,
    currentMenuID,
  } = useUserGroupModules()
  const didRequest = useRef(false)
  const {currentUser} = useAuth()
  const [access, setAccess] = useState<CurrentMenuAccessModel | undefined>(undefined)

  const breadCrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({id: 'LABEL_HOMEPAGE'}),
      path: 'homepage',
      isSeparator: false,
      isActive: false,
    },
    {
      title: currentModuleLabel,
      path: currentModulePath,
      isSeparator: false,
      isActive: false,
    },
    {
      title: currentSiteLabel,
      path: currentSitePath,
      isSeparator: false,
      isActive: false,
    },
    {
      title: currentParentMenuLabel,
      path: currentParentMenuPath,
      isSeparator: false,
      isActive: false,
    },
  ]

  useEffect(() => {
    if (receiptMediaListState.getListState() === undefined) {
      receiptMediaListState.setListState(receiptMediaListQueryState)
    }
    if (receiptDataListState.getListState() === undefined) {
      receiptDataListState.setListState(receiptDataListQueryState)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const requestCurrentMenuAccess = async () => {
      try {
        if (!didRequest.current) {
          const access = await getCurrentMenuAccessByRoleIDAndMenuID(
            currentUser?.role.role_id,
            currentMenuID
          )
          // throw message error
          if (access.data.meta.code === 200) {
            setAccess(access.data.data)
          }
          if (access.data.meta.code === 404 || access.data.meta.code === 500) {
            toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
          }
          if (access.data.meta.code === 401) {
            toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
            removeAuth()
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          if ((error as unknown as any).response.data.meta.code === 401) {
            toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
            removeAuth()
          } else {
            toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
          }
        }
      } finally {
      }

      return () => (didRequest.current = true)
    }

    if (
      currentMenuID !== undefined &&
      currentMenuID !== '' &&
      pathname.split('/')[3] === currentParentMenuPath.split('/')[3]
    ) {
      requestCurrentMenuAccess()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMenuID])

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='media'
          element={
            <SuspendViewComponents>
              <PageTitle breadcrumbs={breadCrumbs}>{currentMenuLabel}</PageTitle>
              <MediaPageProvider access={access} />
            </SuspendViewComponents>
          }
        />
        <Route
          path='data'
          element={
            <SuspendViewComponents>
              <PageTitle breadcrumbs={breadCrumbs}>{currentMenuLabel}</PageTitle>
              <DataPageProvider access={access} />
            </SuspendViewComponents>
          }
        />
        <Route index element={<Navigate to='/backoffice-orins-pos/pos-settings/receipt/media' />} />
      </Route>
    </Routes>
  )
}

export {BackOfficeOrinsPOSReceiptWrapper}
