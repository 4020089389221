import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {Drawer, InputPicker} from 'rsuite'

import {useLang} from '_metronic/i18n/Metronici18n'
import {isMobileDevice} from '_metronic/assets/ts/_utils'
import {useUserGroupModules} from 'app/context/providers'
import {FilterAction, FilterButton} from 'app/components'

import {
  useAction,
  useListQueryRequest,
  useListQueryResponse,
  useListQueryResponseFilterMeta,
} from '../../providers'

const ListFilter = () => {
  const intl = useIntl()
  const mobile = isMobileDevice()
  const local = useLang()
  const {showFilter, setShowFilter} = useAction()
  const {state, updateState} = useListQueryRequest()
  const filterMeta = useListQueryResponseFilterMeta()
  const {isLoading} = useListQueryResponse()
  const {currentMenuID} = useUserGroupModules()
  const optionStatus = [
    {
      value: 't',
      label: intl.formatMessage({id: 'LABEL_ACTIVE'}),
    },
    {
      value: 'f',
      label: intl.formatMessage({id: 'LABEL_INACTIVE'}),
    },
  ]
  const [status, setStatus] = useState(state?.filter !== undefined ? state?.filter.status : '')
  const [name, setParentName] = useState<string | undefined>('')
  const [parent_id, setParentID] = useState(
    state?.filter !== undefined ? state?.filter.parent_id : ''
  )
  const optionsParent =
    filterMeta?.metaParentList === undefined || filterMeta?.metaParentList === null
      ? []
      : (filterMeta.metaParentList as unknown as any[]).length > 0
        ? (filterMeta.metaParentList as unknown as any[]).map((parent: any) => ({
            value: parent.parent_id,
            label: local === 'id' ? parent.parent_name_idn : parent.parent_name_eng,
          }))
        : []

  const resetData = () => {
    setParentID('')
    setParentName('')
    setStatus('')
    updateState({filter: {parent_id: undefined, status: undefined}})
  }

  const filterData = () => {
    updateState({
      filter: {parent_id, status},
    })
    setShowFilter(false)
  }

  useEffect(() => {
    setParentName(
      local === 'id' ? filterMeta?.metaParentName?.name_idn : filterMeta?.metaParentName?.name_eng
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterMeta])

  useEffect(() => {
    resetData()
    setStatus('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMenuID])

  return (
    <>
      <FilterButton
        isLoading={isLoading}
        state={state}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
      />
      <Drawer
        open={showFilter}
        onClose={() => {
          setShowFilter(false)
        }}
        placement='right'
        style={{width: mobile ? '100%' : '25%'}}
      >
        <Drawer.Header closeButton>
          <Drawer.Actions>
            {/* Actions */}
            <FilterAction
              filterData={filterData}
              isLoading={isLoading}
              resetData={resetData}
              setStatus={setStatus}
              setParentID={setParentID}
            />
            {/* Actions */}
          </Drawer.Actions>
        </Drawer.Header>

        {/* Content */}
        <div className='card-body table-responsive m-5'>
          {/* Input group */}
          {name !== '' && (
            <div className='mb-10'>
              <label className='form-label fs-6 fw-bold'>
                {intl.formatMessage({id: 'LABEL_PARENT'})} :
              </label>
              <InputPicker
                block
                data={optionsParent}
                placeholder={`-- ${intl.formatMessage({id: 'LABEL_SELECT'})} --`}
                defaultValue={
                  optionsParent
                    ? (optionsParent as unknown as any[]).find((e: any) => e.value === parent_id)
                    : ''
                }
                onChange={(e) => {
                  setParentID(e)
                }}
                value={parent_id}
              />
            </div>
          )}
          {/* Input group */}

          {/* Input group */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Status :</label>
            <InputPicker
              block
              data={optionStatus}
              placeholder={`-- ${intl.formatMessage({id: 'LABEL_SELECT'})} --`}
              defaultValue={
                optionStatus
                  ? (optionStatus as unknown as any[]).find((e: any) => e.value === status)
                  : ''
              }
              onChange={(e) => {
                setStatus(e)
              }}
              value={status}
            />
          </div>
          {/* Input group */}
        </div>
        {/* Content */}
      </Drawer>
    </>
  )
}

export {ListFilter}
