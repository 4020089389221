import axios from 'axios'

const API_URL = process.env.REACT_APP_APP_API_URL
const X_API_KEY = process.env.REACT_APP_APP_API_KEY

export const GET_ROLE_MENUS_ACCESS = `${API_URL}/role-menu/get-access?`

export function getCurrentMenuAccessByRoleIDAndMenuID(role_id: any, menu_id: any) {
  return axios.get<any>(`${GET_ROLE_MENUS_ACCESS}role_id=${role_id}&menu_id=${menu_id}`, {
    headers: {'X-API-KEY': `${X_API_KEY}`},
  })
}
