import {createContext, FC, useContext, useState} from 'react'

import {WithChildren} from '_metronic/helpers'
import {initialListQueryRequest, ListQueryRequestContextProps, ListQueryState} from '../helpers'

import * as stateContext from '.'

const ListQueryRequestContext = createContext<ListQueryRequestContextProps>(initialListQueryRequest)

const ListQueryRequestProvider: FC<WithChildren> = ({children}) => {
  const [state, setState] = useState<ListQueryState>(stateContext.getListState())

  const updateState = (updates: Partial<ListQueryState>) => {
    const updatedState = {...state, ...updates} as ListQueryState
    setState(updatedState)
    stateContext.setListState(updatedState)
  }

  return (
    <ListQueryRequestContext.Provider value={{state, updateState}}>
      {children}
    </ListQueryRequestContext.Provider>
  )
}

const useListQueryRequest = () => useContext(ListQueryRequestContext)

export {ListQueryRequestProvider, useListQueryRequest}
