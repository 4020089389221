import axios, {AxiosResponse} from 'axios'

import {APIResponse} from 'app/components'

import {ListQueryResponse, OrderID} from '.'

const REPORT_API_URL = `${process.env.REACT_APP_MOBILE_REPORT_API_URL}/web/report/transaction`
const HOLDING_API_URL = `${process.env.REACT_APP_MOBILE_APP_API_URL}/holding`
const COMPANY_API_URL = `${process.env.REACT_APP_MOBILE_APP_API_URL}/company`
const BRAND_API_URL = `${process.env.REACT_APP_MOBILE_APP_API_URL}/brand`
const SALES_TYPE_API_URL = `${process.env.REACT_APP_MOBILE_LIBRARY_API_URL}/sales-type`
const OUTLET_API_URL = `${process.env.REACT_APP_MOBILE_APP_API_URL}/outlet`
const MOBILE_X_API_KEY = process.env.REACT_APP_MOBILE_APP_API_KEY

const fetch = (query: any): Promise<ListQueryResponse> => {
  return axios
    .get(`${REPORT_API_URL}/list?${query}`, {
      headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
    })
    .then((response: AxiosResponse<ListQueryResponse>) => response.data)
}

const getUrlReportTransactionsDownloadFile = (query: any, local: string, mode: string) => {
  return axios.get(`${REPORT_API_URL}/export/${local}/${mode}?${query}`, {
    headers: {'X-API-KEY': `${MOBILE_X_API_KEY}`},
  })
}

const fetchSummary = (query: any): Promise<ListQueryResponse> => {
  return axios
    .get(`${REPORT_API_URL}/summary-list?${query}`, {
      headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
    })
    .then((response: AxiosResponse<ListQueryResponse>) => response.data)
}

const fetchHoldingOptions = (mode: any) => {
  return axios.get(`${HOLDING_API_URL}/list-options/${mode}`, {
    headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
  })
}

const fetchCompanyOptions = (mode: any, holding_id: string | undefined) => {
  return axios.get(`${COMPANY_API_URL}/list-options/${mode}/${holding_id}`, {
    headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
  })
}

const fetchBrandOptions = (mode: any, company_id: string | undefined) => {
  return axios.get(`${BRAND_API_URL}/list-options/${mode}/${company_id}`, {
    headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
  })
}

const fetchSalesTypeOptions = (mode: any, brand_id: string | undefined) => {
  return axios.get(`${SALES_TYPE_API_URL}/list-options/${mode}/${brand_id}`, {
    headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
  })
}

const fetchOutletOptions = (mode: any, brand: string | undefined) => {
  return axios.get(`${OUTLET_API_URL}/list-options/${mode}/${brand}`, {
    headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
  })
}

const get = (order_id: OrderID): Promise<APIResponse | undefined> => {
  return axios
    .get(`${REPORT_API_URL}/${order_id}/detail`, {
      headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
    })
    .then((response: AxiosResponse<APIResponse>) => response.data)
}

const requestCancelVoidData = (
  order_id: any,
  menu_id: any,
  creator_id: any,
  creator_by: any
): Promise<APIResponse | undefined> => {
  return axios
    .put(
      `${REPORT_API_URL}/${order_id}/${menu_id}/${creator_id}/${creator_by}/cancel`,
      {},
      {
        headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
      }
    )
    .then((response: AxiosResponse<APIResponse>) => response.data)
}

const requestApproveVoidData = (
  order_id: any,
  menu_id: any,
  creator_id: any,
  creator_by: any
): Promise<APIResponse | undefined> => {
  return axios
    .put(
      `${REPORT_API_URL}/${order_id}/${menu_id}/${creator_id}/${creator_by}/approve`,
      {},
      {
        headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
      }
    )
    .then((response: AxiosResponse<APIResponse>) => response.data)
}

export {
  fetch,
  getUrlReportTransactionsDownloadFile,
  fetchSummary,
  fetchHoldingOptions,
  fetchCompanyOptions,
  fetchBrandOptions,
  fetchSalesTypeOptions,
  fetchOutletOptions,
  get,
  requestCancelVoidData,
  requestApproveVoidData,
}
