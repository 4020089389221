import {FC} from 'react'

import {ViewModalFormWrapper as UserGroupAccessModalWrapper} from 'app/modules/general/settings/user-management/access/components'
import {ViewModalFormWrapper as LogModalWrapper} from 'app/modules/general/settings/user-management/log/components'
import {ViewModalFormWrapper as ModuleAccessModalWrapper} from 'app/modules/settings-all-module/access/components'
import {ViewModalFormWrapper as LogPOSModalWrapper} from 'app/modules/backoffice-orins-pos/pos-settings/user-management/log/components'
import {ViewModalFormWrapper as ESBSalesDataModalWrapper} from 'app/modules/backoffice-orins-pos/orins-pos/esb/sales/components'

type Props = {
  appMenuID: any
  appParentMenuID: any
  modalID: any
  appModuleID?: any
  appSiteID?: any
}

const ListModalFormWrapperHelper: FC<Props> = ({
  appMenuID,
  appParentMenuID,
  modalID,
  appModuleID,
  appSiteID,
}) => {
  switch (appMenuID) {
    case '4':
      return <UserGroupAccessModalWrapper appMenuID={appMenuID} modalID={modalID} />
    case '5':
      return <LogModalWrapper appMenuID={appMenuID} modalID={modalID} />
    case '51':
      return <ModuleAccessModalWrapper appMenuID={appMenuID} modalID={modalID} />
    case '102':
      return <LogPOSModalWrapper appMenuID={appMenuID} modalID={modalID} />
    case '144':
      return <ESBSalesDataModalWrapper appMenuID={appMenuID} modalID={modalID} />
  }

  return <></>
}

export {ListModalFormWrapperHelper}
