/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {Dialog} from '@headlessui/react'
import toast from 'react-hot-toast'
import clsx from 'clsx'

import {
  FormLog,
  NotAuthRedirectForm,
  SpinnerIndicator,
  datetimeFormatter,
  getFormAction,
  inputTrimSpace,
} from 'app/components'
import {getAuth, removeAuth, useAuth} from 'app/modules/auth'
import {toAbsoluteUrl} from '_metronic/helpers'
import {Drawer, InputPicker, Toggle} from 'rsuite'

import {
  GetDataByIDModel,
  addData,
  deleteData,
  getUserRolesByRoleID,
  initialData,
  updateData,
} from '../../helpers'
import {useAction, useListQueryResponse} from '../../providers'

type Props = {
  isLoading: boolean
  user: GetDataByIDModel
  appMenuID: any
  modalID: any
  menuName: any
}

const ViewDrawerForm: FC<Props> = ({menuName, user, isLoading, appMenuID, modalID}) => {
  const [tab, setTab] = useState('data')
  const auth = getAuth()
  const {currentUser} = useAuth()
  const intl = useIntl()
  const {setItemIdForUpdate, action} = useAction()
  const {refetch} = useListQueryResponse()
  const [isAuth, setIsAuth] = useState(true)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [completed, setCompleted] = useState(false)
  const [userRoles, setUserRoles] = useState(undefined)
  const didRequest = useRef(false)
  const avatarUrl =
    user.avatar === ''
      ? toAbsoluteUrl('/media/avatars/blank.png')
      : toAbsoluteUrl(user.avatar ? user.avatar : '')
  const optionUserRoles =
    userRoles === undefined
      ? []
      : (userRoles as unknown as any[]).map((roles: any) => ({
          value: roles.role_id,
          label: roles.name,
        }))

  const addSchema = Yup.object().shape({
    username: Yup.string()
      .min(3, intl.formatMessage({id: 'VALIDATION_MIN_LENGTH_FIELD'}, {min: 3}))
      .max(15, intl.formatMessage({id: 'VALIDATION_MAX_LENGTH_FIELD'}, {max: 15}))
      .required(
        intl.formatMessage(
          {id: 'VALIDATION_REQUIRED_FIELD'},
          {name: intl.formatMessage({id: 'LABEL_USERNAME'})}
        )
      ),
    fullname: Yup.string()
      .min(3, intl.formatMessage({id: 'VALIDATION_MIN_LENGTH_FIELD'}, {min: 3}))
      .max(50, intl.formatMessage({id: 'VALIDATION_MAX_LENGTH_FIELD'}, {max: 50}))
      .required(
        intl.formatMessage(
          {id: 'VALIDATION_REQUIRED_FIELD'},
          {name: intl.formatMessage({id: 'LABEL_FULLNAME'})}
        )
      ),
    email: Yup.string()
      .email(
        intl.formatMessage(
          {id: 'VALIDATION_FIELD_NOT_VALID'},
          {name: intl.formatMessage({id: 'LABEL_EMAIL'})}
        )
      )
      .min(13, intl.formatMessage({id: 'VALIDATION_MIN_LENGTH_FIELD'}, {min: 13}))
      .max(50, intl.formatMessage({id: 'VALIDATION_MAX_LENGTH_FIELD'}, {max: 50}))
      .required(
        intl.formatMessage(
          {id: 'VALIDATION_REQUIRED_FIELD'},
          {name: intl.formatMessage({id: 'LABEL_EMAIL'})}
        )
      ),
  })
  const updateSchema = Yup.object().shape({
    fullname: Yup.string()
      .min(3, intl.formatMessage({id: 'VALIDATION_MIN_LENGTH_FIELD'}, {min: 3}))
      .max(50, intl.formatMessage({id: 'VALIDATION_MAX_LENGTH_FIELD'}, {max: 50}))
      .required(
        intl.formatMessage(
          {id: 'VALIDATION_REQUIRED_FIELD'},
          {name: intl.formatMessage({id: 'LABEL_FULLNAME'})}
        )
      ),
    email: Yup.string()
      .email(
        intl.formatMessage(
          {id: 'VALIDATION_FIELD_NOT_VALID'},
          {name: intl.formatMessage({id: 'LABEL_EMAIL'})}
        )
      )
      .min(13, intl.formatMessage({id: 'VALIDATION_MIN_LENGTH_FIELD'}, {min: 13}))
      .max(50, intl.formatMessage({id: 'VALIDATION_MAX_LENGTH_FIELD'}, {max: 50}))
      .required(
        intl.formatMessage(
          {id: 'VALIDATION_REQUIRED_FIELD'},
          {name: intl.formatMessage({id: 'LABEL_EMAIL'})}
        )
      ),
    role: Yup.object({
      role_id: Yup.string().required(
        intl.formatMessage(
          {id: 'VALIDATION_REQUIRED_FIELD'},
          {name: intl.formatMessage({id: 'LABEL_USER_GROUP'})}
        )
      ),
    }),
  })

  const [userModels] = useState<GetDataByIDModel>({
    ...user,
    user_id: user.user_id || initialData.user_id,
    avatar: user.avatar || initialData.avatar,
    created_at: user.created_at || initialData.created_at,
    created_by: user.created_by || initialData.created_by,
    email: user.email || initialData.email,
    fullname: user.fullname || initialData.fullname,
    last_login: user.last_login || initialData.last_login,
    status: user.status || initialData.status,
    updated_at: user.updated_at || initialData.updated_at,
    updated_by: user.updated_by || initialData.updated_by,
    username: user.username || initialData.username,
    username_prefix: user.username_prefix || initialData.username_prefix,
    role: user.role || initialData.role,
    menu_id: appMenuID,
  })

  const formik = useFormik({
    initialValues: userModels,
    validationSchema: action !== 'add' ? updateSchema : addSchema,
    onSubmit: async (values, {setSubmitting, setStatus, setFieldError}) => {
      setIsDialogOpen(false)
      action === 'edit'
        ? requestUpdateData(values, setStatus, setSubmitting, setFieldError)
        : action === 'add'
          ? requestAddData(values, setStatus, setSubmitting, setFieldError)
          : requestDeleteData(values, setStatus, setSubmitting)
    },
    onReset: (values) => {
      values = userModels
    },
  })

  const requestUpdateData = async (
    values: any,
    setStatus: any,
    setSubmitting: any,
    setFieldError: any
  ) => {
    setLoading(true)
    setStatus(null)
    setSubmitting(true)

    // request update to API
    try {
      const res = await updateData(values, currentUser?.role.role_id)

      // throw message error
      if (res?.meta.code !== 200) {
        if (res?.meta.message === 'email already used') {
          setStatus(intl.formatMessage({id: 'RESPONSE_USER_EMAIL_ALREADY_USED'}))
          setFieldError('email', intl.formatMessage({id: 'RESPONSE_USER_EMAIL_ALREADY_USED'}))
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_USER_EDIT_SUCCESS'}))
        setCompleted(true)
        setTimeout(function () {
          setItemIdForUpdate(undefined)
        }, 1e3)
        refetch()
      }
    } catch (error: any) {
      console.error(error)
      if (error.response.status === 401) {
        setStatus(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
        removeAuth()
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
      }
      setSubmitting(false)
      setLoading(false)
      setTimeout(function () {
        setStatus(null)
      }, 9e3)

      return false
    } finally {
      setTimeout(() => {
        setLoading(false)
        setSubmitting(false)
      }, 5e2)
    }
  }
  const requestAddData = async (
    values: any,
    setStatus: any,
    setSubmitting: any,
    setFieldError: any
  ) => {
    setLoading(true)
    setStatus(null)
    setSubmitting(true)

    // request add to API
    try {
      const res = await addData(values, currentUser?.role.role_id)

      // throw message error
      if (res?.meta.code !== 200) {
        if (res?.meta.message === 'username already used') {
          setStatus(intl.formatMessage({id: 'RESPONSE_USER_USERNAME_ALREADY_USED'}))
          setFieldError('username', intl.formatMessage({id: 'RESPONSE_USER_USERNAME_ALREADY_USED'}))
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
        if (res?.meta.message === 'email already used') {
          setStatus(intl.formatMessage({id: 'RESPONSE_USER_EMAIL_ALREADY_USED'}))
          setFieldError('email', intl.formatMessage({id: 'RESPONSE_USER_EMAIL_ALREADY_USED'}))
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_USER_ADD_SUCCESS'}))
        setCompleted(true)
        setTimeout(function () {
          setItemIdForUpdate(undefined)
        }, 1e3)
        refetch()
      }
    } catch (error: any) {
      console.error(error)
      if (error.response.status === 401) {
        setStatus(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
        removeAuth()
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
      }
      setSubmitting(false)
      setLoading(false)
      setTimeout(function () {
        setStatus(null)
      }, 9e3)

      return false
    } finally {
      setTimeout(() => {
        setLoading(false)
        setSubmitting(false)
      }, 5e2)
    }
  }
  const requestDeleteData = async (values: any, setStatus: any, setSubmitting: any) => {
    setLoading(true)
    setStatus(null)
    setSubmitting(true)

    // request delete to API
    try {
      const res = await deleteData(values.user_id, appMenuID, currentUser?.role.role_id)

      // throw message error
      if (res?.meta.code !== 200) {
        if (res?.meta.message === 'user is not inactive') {
          setStatus(intl.formatMessage({id: 'RESPONSE_USER_IS_ACTIVE'}))
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_USER_DELETE_SUCCESS'}))
        setCompleted(true)
        setTimeout(function () {
          setItemIdForUpdate(undefined)
        }, 1e3)
        refetch()
      }
    } catch (error: any) {
      console.error(error)
      if (error.response.status === 401) {
        setStatus(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
        removeAuth()
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
      }
      setSubmitting(false)
      setLoading(false)
      setTimeout(function () {
        setStatus(null)
      }, 9e3)

      return false
    } finally {
      setTimeout(() => {
        setLoading(false)
        setSubmitting(false)
      }, 5e2)
    }
  }

  const handleUsernameChange = (e: any) => {
    formik.setFieldValue('username', inputTrimSpace(e.target.value))
  }

  useEffect(() => {
    auth === undefined && setIsAuth(false)
  }, [auth])

  useEffect(() => {
    const requestUserRolesForInput = async () => {
      try {
        if (!didRequest.current) {
          const userRoles = await getUserRolesByRoleID('input', currentUser?.role.role_id)
          // throw message error
          if (userRoles.data.meta.code === 200) {
            // set option user roles
            setUserRoles(userRoles.data.data)
          }
        }
      } catch (error: any) {
        console.error(error)
        if (error.response.status === 401) {
          toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
          removeAuth()
        } else {
          toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
        }
      } finally {
      }

      return () => (didRequest.current = true)
    }

    requestUserRolesForInput()

    // eslint-disable-next-line
  }, [])

  return (
    <>
      {!isAuth && <NotAuthRedirectForm />}

      <Drawer.Header>
        <Drawer.Title className='fw-bolder'>
          {isLoading ? (
            <SpinnerIndicator label={intl.formatMessage({id: 'BUTTON_LABEL_PLEASE_WAIT'})} />
          ) : (
            <>
              {getFormAction(
                action,
                intl.formatMessage({id: 'BUTTON_LABEL_VIEW'}),
                intl.formatMessage({id: 'BUTTON_LABEL_EDIT'}),
                intl.formatMessage({id: 'BUTTON_LABEL_ADD'}),
                intl.formatMessage({id: 'BUTTON_LABEL_DELETE'})
              )}{' '}
              {menuName}
            </>
          )}
        </Drawer.Title>
        <Drawer.Actions>
          {action !== 'view' && userModels !== formik.values && !completed && (
            <button
              type='reset'
              className='btn btn-light me-3'
              data-kt-users-modal-action='reset'
              onClick={formik.handleReset}
              disabled={loading}
            >
              {!loading && (
                <span className='indicator-label'>
                  {intl.formatMessage({id: 'BUTTON_LABEL_RESET'})}
                </span>
              )}
            </button>
          )}

          {action !== 'view' && userModels !== formik.values && !completed && (
            <button
              type='submit'
              className='btn btn-primary'
              data-kt-users-modal-action='submit'
              onClick={() => {
                if (formik.values.role?.name?.toString().replaceAll(',', ', ') === '') {
                  formik.setFieldError('role', 'validation errors')
                  return false
                }
                setIsDialogOpen(true)
                setLoading(true)
                setTimeout(function () {
                  setLoading(false)
                }, 2e3)
              }}
              disabled={isLoading || loading || formik.isSubmitting || !formik.isValid}
            >
              {!loading && (
                <span className='indicator-label'>
                  {intl.formatMessage({id: 'BUTTON_LABEL_SUBMIT'})}
                </span>
              )}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {intl.formatMessage({id: 'BUTTON_LABEL_SUBMITTING'})}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          )}

          {action === 'delete' && !completed && (
            <button
              type='submit'
              className='btn btn-danger'
              data-kt-users-modal-action='submit'
              onClick={() => formik.handleSubmit()}
              disabled={isLoading || loading || formik.isSubmitting || !formik.isValid}
            >
              {!loading && (
                <span className='indicator-label'>
                  {intl.formatMessage({id: 'BUTTON_LABEL_DELETE'})}
                </span>
              )}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {intl.formatMessage({id: 'BUTTON_LABEL_SUBMITTING'})}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          )}
        </Drawer.Actions>
      </Drawer.Header>

      <Drawer.Body style={{padding: 0}}>
        {/* Response Message */}
        {formik.status &&
          (formik.status === intl.formatMessage({id: 'RESPONSE_USER_ADD_SUCCESS'}) ||
          formik.status === intl.formatMessage({id: 'RESPONSE_USER_EDIT_SUCCESS'}) ||
          formik.status === intl.formatMessage({id: 'RESPONSE_USER_DELETE_SUCCESS'}) ? (
            <div className='m-lg-5 p-2 bg-light-success rounded'>
              <div className='text-success text-center font-weight-bold fw-bolder'>
                {formik.status}
              </div>
            </div>
          ) : (
            <div className={`m-lg-5 p-2 alert alert-danger`}>
              <div className='alert-text text-center font-weight-bold fw-bolder'>
                {formik.status}
              </div>
            </div>
          ))}
        {/* Response Message */}

        {/* Form */}
        <div className={`modal-body m-5 ${action === 'add' ? '' : ' mt-0'}`}>
          {action === 'add' ? (
            <form id={`${modalID}-form`} className='form' onSubmit={formik.handleSubmit} noValidate>
              {/* User Group */}
              <div className='fv-row mb-5'>
                {formik.values.role.role_id !== '' && (
                  <label className='form-label fs-6 fw-bolder text-dark required'>
                    {intl.formatMessage({id: 'LABEL_USER_GROUP'})}
                  </label>
                )}
                <InputPicker
                  block
                  data={optionUserRoles}
                  placeholder={`-- ${intl.formatMessage({id: 'LABEL_USER_GROUP'})} --`}
                  defaultValue={
                    optionUserRoles
                      ? (optionUserRoles as unknown as any[]).find(
                          (e: any) => e.value === formik.values.role.role_id
                        )
                      : ''
                  }
                  onChange={(e) => {
                    formik.setFieldValue('role.role_id', e === null ? '' : e)
                  }}
                  onSelect={(val, itm) => {
                    formik.setFieldValue('role.name', val === null ? '' : itm.label)
                  }}
                  value={formik.values.role.role_id}
                />
                {formik.errors.role ? (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block text-danger fw-bolder'>
                      {intl.formatMessage(
                        {id: 'VALIDATION_REQUIRED_FIELD'},
                        {
                          name: intl.formatMessage({
                            id: 'LABEL_USER_GROUP',
                          }),
                        }
                      )}
                    </div>
                  </div>
                ) : null}
              </div>
              {/* User Group */}

              {/* Username */}
              <div className='fv-row mb-5'>
                {formik.values.username !== '' && (
                  <label className='form-label fs-6 fw-bolder text-dark required'>
                    {intl.formatMessage({id: 'LABEL_USERNAME'})}
                  </label>
                )}
                <input
                  type='text'
                  className={clsx(
                    'form-control form-control-solid',
                    {'is-invalid': formik.touched.username && formik.errors.username},
                    {
                      'is-valid': formik.touched.username && !formik.errors.username,
                    }
                  )}
                  placeholder={intl.formatMessage({id: 'LABEL_USERNAME'})}
                  {...formik.getFieldProps('username')}
                  onKeyUp={handleUsernameChange}
                />
                {formik.touched.username && formik.errors.username ? (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block text-danger fw-bolder'>
                      {formik.errors.username}
                    </div>
                  </div>
                ) : null}
              </div>
              {/* Username */}

              {/* Fullname */}
              <div className='fv-row mb-5'>
                {formik.values.fullname !== '' && (
                  <label className='form-label fs-6 fw-bolder text-dark required'>
                    {intl.formatMessage({id: 'LABEL_FULLNAME'})}
                  </label>
                )}
                <input
                  type='text'
                  className={clsx(
                    'form-control form-control-solid',
                    {'is-invalid': formik.touched.fullname && formik.errors.fullname},
                    {
                      'is-valid': formik.touched.fullname && !formik.errors.fullname,
                    }
                  )}
                  placeholder={intl.formatMessage({id: 'LABEL_FULLNAME'})}
                  {...formik.getFieldProps('fullname')}
                />
                {formik.touched.fullname && formik.errors.fullname ? (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block text-danger fw-bolder'>
                      {formik.errors.fullname}
                    </div>
                  </div>
                ) : null}
              </div>
              {/* Fullname */}

              {/* Email */}
              <div className='fv-row mb-5'>
                {formik.values.email !== '' && (
                  <label className='form-label fs-6 fw-bolder text-dark required'>
                    {intl.formatMessage({id: 'LABEL_EMAIL'})}
                  </label>
                )}
                <input
                  type='text'
                  className={clsx(
                    'form-control form-control-solid',
                    {'is-invalid': formik.touched.email && formik.errors.email},
                    {
                      'is-valid': formik.touched.email && !formik.errors.email,
                    }
                  )}
                  placeholder={intl.formatMessage({id: 'LABEL_EMAIL'})}
                  {...formik.getFieldProps('email')}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block text-danger fw-bolder'>{formik.errors.email}</div>
                  </div>
                ) : null}
              </div>
              {/* Email */}
            </form>
          ) : (
            <form id={`${modalID}-form`} className='form' onSubmit={formik.handleSubmit} noValidate>
              {/* Tab Header */}
              <div className='card-toolbar'>
                <ul
                  className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-2 mt-2'
                  role='tablist'
                >
                  <li className={`nav-item ${tab === 'data' ? 'fw-bolder' : ''}`}>
                    <a
                      className={clsx(`nav-link cursor-pointer`, {
                        active: tab === 'data',
                      })}
                      onClick={() => setTab('data')}
                      role='tab'
                    >
                      Data
                    </a>
                  </li>
                  <li className={`nav-item ${tab === 'log' ? 'fw-bolder' : ''}`}>
                    <a
                      className={clsx(`nav-link cursor-pointer`, {
                        active: tab === 'log',
                      })}
                      onClick={() => setTab('log')}
                      role='tab'
                    >
                      Log
                    </a>
                  </li>
                </ul>
              </div>
              {/* Tab Header */}

              {/* Tab Body */}
              <div className='card-body p-0 pt-2'>
                <div className='tab-content'>
                  {/* Data */}
                  <div
                    className={clsx('tab-pane', {
                      active: tab === 'data',
                    })}
                  >
                    <div className='row'>
                      {/* Left Form */}
                      <div className='col-lg-6'>
                        {/* Avatar */}
                        <div className='fv-row mb-5'>
                          <label className='form-label fs-6 fw-bolder text-dark'>Avatar</label>
                          <br />
                          <div className='symbol symbol-50px me-5'>
                            <img
                              src={avatarUrl}
                              alt='User-Avatar'
                              style={{objectFit: 'cover'}}
                              loading='lazy'
                            />
                          </div>
                        </div>
                        {/* Avatar */}

                        {/* User Group */}
                        <div className='fv-row mb-5'>
                          {formik.values.role.role_id !== '' && (
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action === 'edit' ? 'required' : ''
                              }`}
                            >
                              {intl.formatMessage({id: 'LABEL_USER_GROUP'})}
                            </label>
                          )}
                          <InputPicker
                            block
                            readOnly={action !== 'edit' ? true : false}
                            data={optionUserRoles}
                            placeholder={`-- ${intl.formatMessage({id: 'LABEL_USER_GROUP'})} --`}
                            defaultValue={
                              optionUserRoles
                                ? (optionUserRoles as unknown as any[]).find(
                                    (e: any) => e.value === formik.values.role.role_id
                                  )
                                : ''
                            }
                            onChange={(e) => {
                              formik.setFieldValue('role.role_id', e === null ? '' : e)
                            }}
                            onSelect={(val, itm) => {
                              formik.setFieldValue('role.name', val === null ? '' : itm.label)
                            }}
                            value={formik.values.role.role_id}
                          />
                          {formik.errors.role ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {intl.formatMessage(
                                  {id: 'VALIDATION_REQUIRED_FIELD'},
                                  {
                                    name: intl.formatMessage({
                                      id: 'LABEL_USER_GROUP',
                                    }),
                                  }
                                )}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* User Group */}

                        {/* Status */}
                        <div className='fv-row mb-5'>
                          {formik.values.status !== '' && (
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action === 'edit' ? 'required' : ''
                              }`}
                            >
                              Status
                            </label>
                          )}
                          <div className='form-check form-switch form-check-custom form-check-solid mt-1'>
                            <Toggle
                              size='lg'
                              readOnly={action !== 'edit' ? true : false}
                              checkedChildren={intl.formatMessage({id: 'LABEL_ACTIVE'})}
                              unCheckedChildren={intl.formatMessage({id: 'LABEL_INACTIVE'})}
                              checked={formik.values.status === 't' ? true : false}
                              onChange={() =>
                                formik.setFieldValue(
                                  'status',
                                  formik.values.status === 't' ? 'f' : 't'
                                )
                              }
                            />
                          </div>
                          {formik.touched.status && formik.errors.status ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {formik.errors.status}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Status */}
                      </div>
                      {/* Left Form */}

                      {/* Right Form */}
                      <div className='col-lg-6'>
                        {/* Username */}
                        <div className='fv-row mb-5'>
                          {formik.values.username !== '' && (
                            <label className='form-label fs-6 fw-bolder text-dark'>
                              {intl.formatMessage({id: 'LABEL_USERNAME'})}
                            </label>
                          )}
                          <input
                            type='text'
                            className={clsx(
                              'form-control form-control-solid',
                              {'is-invalid': formik.touched.username && formik.errors.username},
                              {
                                'is-valid': formik.touched.username && !formik.errors.username,
                              }
                            )}
                            placeholder={intl.formatMessage({id: 'LABEL_USERNAME'})}
                            {...formik.getFieldProps('username')}
                            readOnly
                          />
                        </div>
                        {/* Username */}

                        {/* Fullname */}
                        <div className='fv-row mb-5'>
                          {formik.values.fullname !== '' && (
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action !== 'view' ? 'required' : ''
                              }`}
                            >
                              {intl.formatMessage({id: 'LABEL_FULLNAME'})}
                            </label>
                          )}
                          <input
                            type='text'
                            className={clsx(
                              'form-control form-control-solid',
                              {'is-invalid': formik.touched.fullname && formik.errors.fullname},
                              {
                                'is-valid': formik.touched.fullname && !formik.errors.fullname,
                              }
                            )}
                            placeholder={intl.formatMessage({id: 'LABEL_FULLNAME'})}
                            {...formik.getFieldProps('fullname')}
                            readOnly={action === 'edit' ? false : true}
                          />
                          {formik.touched.fullname && formik.errors.fullname ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {formik.errors.fullname}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Fullname */}

                        {/* Email */}
                        <div className='fv-row mb-5'>
                          {formik.values.email !== '' && (
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action === 'edit' ? 'required' : ''
                              }`}
                            >
                              {intl.formatMessage({id: 'LABEL_EMAIL'})}
                            </label>
                          )}
                          <input
                            type='text'
                            className={clsx(
                              'form-control form-control-solid',
                              {'is-invalid': formik.touched.email && formik.errors.email},
                              {
                                'is-valid': formik.touched.email && !formik.errors.email,
                              }
                            )}
                            placeholder={intl.formatMessage({id: 'LABEL_EMAIL'})}
                            {...formik.getFieldProps('email')}
                            readOnly={action === 'edit' ? false : true}
                          />
                          {formik.touched.email && formik.errors.email ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {formik.errors.email}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Email */}

                        {/* Last Login */}
                        {action === 'view' && (
                          <div className='fv-row mb-5'>
                            <label className={`form-label fs-6 fw-bolder text-dark`}>
                              {intl.formatMessage({id: 'LABEL_LAST_LOGIN'})}
                            </label>
                            <input
                              type='text'
                              className='form-control form-control-solid'
                              placeholder={intl.formatMessage({
                                id: 'LABEL_LAST_LOGIN',
                              })}
                              value={
                                user.last_login === '0001-01-01T00:00:00Z'
                                  ? '-'
                                  : datetimeFormatter(user.last_login, '1', intl.locale)
                              }
                              readOnly
                            />
                          </div>
                        )}

                        {/* Last Login */}
                      </div>
                      {/* Right Form */}
                    </div>
                  </div>
                  {/* Data */}

                  {/* Detail */}
                  <div
                    className={clsx('tab-pane', {
                      active: tab === 'log',
                    })}
                  >
                    <FormLog payload={user} formik={formik} />
                  </div>
                  {/* Detail */}
                </div>
              </div>
              {/* Tab Body */}
            </form>
          )}
        </div>
        {/* Form */}
      </Drawer.Body>

      {/* Dialog */}
      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(true)}
        className='modal fade show d-block'
      >
        <Dialog.Panel className='modal-dialog modal-fullscreen'>
          <div className='modal-content'>
            <Dialog.Title className='modal-header'>
              {intl.formatMessage({id: 'DIALOG_HEADER_TITLE'})}
            </Dialog.Title>
            <div className='modal-body scroll-y mx-2 mx-xl-2 my-2'>
              {/* Username */}
              {userModels.username !== formik.values.username && action === 'add' && (
                <div className='fv-row mb-5'>
                  <label className='form-label fs-6 fw-bolder text-dark'>
                    {intl.formatMessage({id: 'LABEL_USERNAME'})}
                  </label>
                  <input
                    type='text'
                    className='form-control form-control-solid text-primary'
                    value={formik.values.username}
                    readOnly
                  />
                </div>
              )}
              {/* Username */}

              {/* Fullname */}
              {userModels.fullname !== formik.values.fullname && (
                <div className='fv-row mb-5'>
                  <label className='form-label fs-6 fw-bolder text-dark'>
                    {intl.formatMessage({id: 'LABEL_FULLNAME'})}
                  </label>
                  {action !== 'add' && (
                    <input
                      type='text'
                      className='form-control form-control-solid'
                      value={userModels.fullname}
                      readOnly
                    />
                  )}
                  <input
                    type='text'
                    className='form-control form-control-solid text-primary is-valid'
                    value={formik.values.fullname}
                    readOnly
                  />
                </div>
              )}
              {/* Fullname */}

              {/* Email */}
              {userModels.email !== formik.values.email && (
                <div className='fv-row mb-5'>
                  <label className='form-label fs-6 fw-bolder text-dark'>
                    {intl.formatMessage({id: 'LABEL_EMAIL'})}
                  </label>
                  {action !== 'add' && (
                    <input
                      type='text'
                      className='form-control form-control-solid'
                      value={userModels.email}
                      readOnly
                    />
                  )}
                  <input
                    type='text'
                    className='form-control form-control-solid text-primary is-valid'
                    value={formik.values.email}
                    readOnly
                  />
                </div>
              )}
              {/* Email */}

              {/* User Group */}
              {userModels.role !== formik.values.role && (
                <div className='fv-row mb-5'>
                  <label className='form-label fs-6 fw-bolder text-dark'>
                    {intl.formatMessage({id: 'LABEL_USER_GROUP'})}
                  </label>
                  {action !== 'add' && (
                    <input
                      type='text'
                      className='form-control form-control-solid'
                      value={userModels.role?.name}
                      readOnly
                    />
                  )}
                  <input
                    type='text'
                    className='form-control form-control-solid text-primary is-valid'
                    value={formik.values.role?.name}
                    readOnly
                  />
                </div>
              )}
              {/* User Group */}

              {/* Status */}
              {userModels.status !== formik.values.status && (
                <div className='fv-row mb-5'>
                  <label className='form-label fs-6 fw-bolder text-dark'>Status</label>
                  <input
                    type='text'
                    className='form-control form-control-solid'
                    value={
                      userModels.status === 't'
                        ? intl.formatMessage({id: 'LABEL_ACTIVE'})
                        : intl.formatMessage({id: 'LABEL_INACTIVE'})
                    }
                    readOnly
                  />
                  <input
                    type='text'
                    className='form-control form-control-solid text-primary is-valid'
                    value={
                      formik.values.status === 't'
                        ? intl.formatMessage({id: 'LABEL_ACTIVE'})
                        : intl.formatMessage({id: 'LABEL_INACTIVE'})
                    }
                    readOnly
                  />
                </div>
              )}
              {/* Status */}
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-outline btn-btn-outline-info me-3'
                data-kt-users-modal-action='close'
                onClick={() => setIsDialogOpen(false)}
              >
                {intl.formatMessage({id: 'BUTTON_LABEL_BACK'})}
              </button>

              <button
                type='button'
                className={`btn ${loading ? 'btn-secondary' : 'btn-success'}`}
                data-kt-users-modal-action='submit'
                onClick={() => formik.handleSubmit()}
                disabled={loading ? true : false}
              >
                {!loading && (
                  <span className='indicator-label'>
                    {intl.formatMessage({id: 'BUTTON_LABEL_SUBMIT'})}
                  </span>
                )}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    {intl.formatMessage({id: 'BUTTON_LABEL_PLEASE_WAIT'})}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
      {/* Dialog */}
    </>
  )
}

export {ViewDrawerForm}
