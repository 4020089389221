import axios, {AxiosResponse} from 'axios'

import {APIResponse} from 'app/components'

import {ListQueryResponse, SocialMediaID} from '.'

const SOCIAL_MEDIA_API_URL = `${process.env.REACT_APP_MOBILE_LIBRARY_API_URL}/social-media`
const MOBILE_X_API_KEY = process.env.REACT_APP_MOBILE_APP_API_KEY

const fetch = (query: any): Promise<ListQueryResponse> => {
  return axios
    .get(`${SOCIAL_MEDIA_API_URL}/list?${query}`, {
      headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
    })
    .then((response: AxiosResponse<ListQueryResponse>) => response.data)
}

const get = (social_media_id: SocialMediaID): Promise<APIResponse | undefined> => {
  return axios
    .get(`${SOCIAL_MEDIA_API_URL}/${social_media_id}/detail`, {
      headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
    })
    .then((response: AxiosResponse<APIResponse>) => response.data)
}

const addData = (payload: any): Promise<APIResponse | undefined> => {
  const formData = new FormData()

  formData.append('name', payload.name)
  formData.append(`file`, payload.file)
  formData.append('status', payload.status)
  formData.append('url', payload.url)
  formData.append('for_order', payload.for_order)
  formData.append('menu_id', payload.menu_id)
  formData.append('creator_by', payload.creator_by)
  formData.append('creator_id', payload.creator_id)

  return axios.post(`${SOCIAL_MEDIA_API_URL}`, formData, {
    headers: {'x-api-key': `${MOBILE_X_API_KEY}`, 'content-type': 'multipart/form-data'},
  })
}

const updateData = (payload: any): Promise<APIResponse | undefined> => {
  const formData = new FormData()

  formData.append('social_media_id', payload.social_media_id)
  formData.append('name', payload.name)
  formData.append(`file`, payload.file)
  formData.append('status', payload.status)
  formData.append('url', payload.url)
  formData.append('for_order', payload.for_order)
  formData.append('menu_id', payload.menu_id)
  formData.append('creator_by', payload.creator_by)
  formData.append('creator_id', payload.creator_id)

  return axios.put(`${SOCIAL_MEDIA_API_URL}/${payload.social_media_id}/update`, formData, {
    headers: {'x-api-key': `${MOBILE_X_API_KEY}`, 'content-type': 'multipart/form-data'},
  })
}

const deleteData = (
  social_media_id: any,
  menu_id: any,
  creator_id: any,
  creator_by: any
): Promise<APIResponse | undefined> => {
  return axios
    .delete(
      `${SOCIAL_MEDIA_API_URL}/${social_media_id}/${menu_id}/${creator_id}/${creator_by}/delete`,
      {
        headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
      }
    )
    .then((response: AxiosResponse<APIResponse>) => response.data)
}

export {fetch, get, updateData, addData, deleteData}
