import {RoleID, ListResponse} from '.'

export type ListModel = {
  role_id?: string
  name?: string
  code?: string
  description?: string
  status?: string
}

export type ListQueryResponse = ListResponse<Array<ListModel>>

export const initialList: ListModel = {
  role_id: '',
  name: '',
  code: '',
  description: '',
  status: '',
}

export type DetailModel = {
  role_id?: RoleID
  add: Array<string>
  apv1: Array<string>
  apv2: Array<string>
  apv3: Array<string>
  delete: Array<string>
  edit: Array<string>
  view: Array<string>
  created_at?: string
  created_by?: string
  updated_at?: string
  updated_by?: string
  menu_id?: string // modified payload for crud
  creator_id?: string // modified payload for crud
  creator_by?: string // modified payload for crud
}

export const initialData: DetailModel = {
  add: [''],
  apv1: [''],
  apv2: [''],
  apv3: [''],
  delete: [''],
  edit: [''],
  view: [''],
  created_at: '',
  created_by: '',
  updated_at: '',
  updated_by: '',
  menu_id: '', // modified payload for crud
  creator_id: '', // modified payload for crud
  creator_by: '', // modified payload for crud
}
