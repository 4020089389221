import {FC} from 'react'
import toast from 'react-hot-toast'
import {useQuery} from 'react-query'

import {QUERIES, isNotEmpty} from '_metronic/helpers'
import {removeAuth} from 'app/modules/auth'

import {useAction} from '../../providers'
import {get} from '../../helpers'
import {ViewModalForm} from '..'

type Props = {appMenuID: any; modalID: any}

const ViewModalFormWrapper: FC<Props> = ({modalID}) => {
  const {itemIdForUpdate, setIsLoading} = useAction()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {data: log, error} = useQuery(
    [`${QUERIES.POS_SETTINGS_USER_MANAGEMENT_GET_LOG}-${itemIdForUpdate}`],
    () => {
      setIsLoading(true)
      return get(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      refetchOnWindowFocus: false,
      onError: (err: any) => {
        if (err.response.status !== 200) {
          setIsLoading(false)
          if (err.response.status === 401) {
            toast.error(err.response.statusText)
            removeAuth()
          } else {
            toast.error(err.response.statusText)
          }
        }
      },
      onSuccess: () => {
        setIsLoading(false)
      },
    }
  )

  if (!error && log) {
    return <ViewModalForm log={log.data} modalID={modalID} />
  }

  return null
}

export {ViewModalFormWrapper}
