import {useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {Checkbox, InputPicker, CheckPicker, DatePicker, DateRangePicker} from 'rsuite'
import toast from 'react-hot-toast'
import moment from 'moment'

import {FilterAction, SearchComponent, ShortcutMenuButton} from 'app/components'
import {removeAuth} from 'app/modules/auth'

import {useAction, useListQueryRequest, useListQueryResponse} from '../../providers'
import {fetchBrandOptions, fetchCompanyOptions, fetchOutletOptions} from '../../helpers'

const footerStyles = {
  padding: '0px 2px',
  borderTop: '1px solid #e5e5e5',
}

const ListFilter = () => {
  const {afterToday} = DateRangePicker
  const intl = useIntl()
  const {state, updateState} = useListQueryRequest()
  const {isLoading} = useListQueryResponse()
  const {
    setShowFilter,
    holdingOptions,
    companyOptions,
    setCompanyOptions,
    brandOptions,
    setBrandOptions,
    outletOptions,
    setOutletOptions,
  } = useAction()
  const didRequestCompany = useRef(false)
  const didRequestBrand = useRef(false)
  const didRequestOutlet = useRef(false)
  const [allowCompany, setAllowCompany] = useState(true)
  const [allowBrand, setAllowBrand] = useState(true)
  const [allowCompanyError, setAllowCompanyError] = useState(false)
  const [allowBrandError, setAllowBrandError] = useState(false)

  const [holdingID, setHoldingID] = useState(
    state?.filter !== undefined ? state?.filter.holding_id : []
  )
  const [holdingName, setHoldingName] = useState(
    state?.filter !== undefined ? state?.filter.holding_name : []
  )
  const [companyID, setCompanyID] = useState(
    state?.filter !== undefined ? state?.filter.company_id : []
  )
  const [companyName, setCompanyName] = useState(
    state?.filter !== undefined ? state?.filter.company_name : []
  )
  const [brandID, setBrandID] = useState(state?.filter !== undefined ? state?.filter.brand_id : [])
  const [brandName, setBrandName] = useState(
    state?.filter !== undefined ? state?.filter.brand_name : []
  )
  const [outletID, setOutletID] = useState(
    state?.filter !== undefined ? state?.filter.outlet_id : []
  )
  const [outletName, setOutletName] = useState(
    state?.filter !== undefined ? state?.filter.outlet_name : []
  )

  const optionsHolding =
    holdingOptions === undefined
      ? []
      : (holdingOptions as unknown as any[]).map((holdings: any) => ({
          value: holdings.holding_id,
          label: holdings.name,
        }))
  const optionsCompany =
    companyOptions === undefined
      ? []
      : (companyOptions as unknown as any[]).map((company: any) => ({
          value: company.company_id,
          label: company.name,
        }))
  const optionsBrand =
    brandOptions === undefined
      ? []
      : (brandOptions as unknown as any[]).map((brand: any) => ({
          value: brand.brand_id,
          label: brand.name,
        }))
  const optionsOutlet =
    outletOptions === undefined
      ? []
      : (outletOptions as unknown as any[]).map((outlet: any) => ({
          value: outlet.outlet_id,
          label: outlet.name,
        }))

  const optionStatus = [
    {
      value: '1',
      label: intl.formatMessage({id: 'LABEL_IS_SHIFT_OPEN'}),
    },
    {
      value: '2',
      label: intl.formatMessage({id: 'LABEL_IS_SHIFT_CLOSE'}),
    },
  ]
  const [status, setStatus] = useState(state?.filter !== undefined ? state?.filter.status : '')

  const [date, setDate] = useState(state?.date === null ? null : state?.date)
  const handleCallback = (period: any) => {
    if (period !== null) {
      setDate(moment(period).format('YYYY-MM-DD'))
    } else {
      setDate(null)
    }
  }

  const resetData = () => {
    setHoldingID([])
    setHoldingName([])
    setCompanyID([])
    setCompanyName([])
    setBrandID([])
    setBrandName([])
    setOutletID([])
    setOutletName([])
    setStatus('')
    setDate(moment().format('YYYY-MM-DD'))
    setAllowCompanyError(false)
    setAllowBrandError(false)
    updateState({date: moment().format('YYYY-MM-DD'), filter: undefined})
  }

  const filterData = () => {
    if (holdingID.length > 0) {
      if (holdingID.length > 0 && companyID.length === 0) {
        setAllowCompany(false)
        setTimeout(function () {
          setAllowCompany(true)
        }, 5e3)
        return
      }
    }

    if (companyID.length > 0) {
      if (companyID.length > 0 && brandID.length === 0) {
        setAllowBrand(false)
        setTimeout(function () {
          setAllowBrand(true)
        }, 5e3)
        return
      }
    }
    updateState({
      date: date ? date : moment().format('YYYY-MM-DD'),
      filter: {
        holding_id: holdingID,
        company_id: companyID,
        brand_id: brandID,
        outlet_id: outletID,
        status,
      },
    })
    setShowFilter(false)
  }

  useEffect(() => {
    const requestCompanyOptions = async () => {
      try {
        if (!didRequestCompany.current) {
          const options = await fetchCompanyOptions('filter', holdingID)
          // throw message error
          if (options.data.meta.code === 200) {
            // set options
            setCompanyOptions(options.data.data)
          }
        }
      } catch (error: any) {
        console.error(error)
        if (error.response.status === 401) {
          toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
          removeAuth()
        } else {
          toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
        }
      } finally {
      }

      return () => (didRequestCompany.current = true)
    }

    holdingID !== undefined && holdingID.length > 0
      ? requestCompanyOptions()
      : setCompanyOptions([])
    // eslint-disable-next-line
  }, [holdingID])

  useEffect(() => {
    const requestBrandOptions = async () => {
      try {
        if (!didRequestBrand.current) {
          const options = await fetchBrandOptions('filter', companyID)
          // throw message error
          if (options.data.meta.code === 200) {
            // set options
            setBrandOptions(options.data.data)
          }
        }
      } catch (error: any) {
        console.error(error)
        if (error.response.status === 401) {
          toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
          removeAuth()
        } else {
          toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
        }
      } finally {
      }

      return () => (didRequestBrand.current = true)
    }

    companyID !== undefined && companyID.length > 0 ? requestBrandOptions() : setBrandOptions([])
    // eslint-disable-next-line
  }, [companyID])

  useEffect(() => {
    const requestOutletOptions = async () => {
      try {
        if (!didRequestOutlet.current) {
          const options = await fetchOutletOptions('filter', brandID)
          // throw message error
          if (options.data.meta.code === 200) {
            // set options
            setOutletOptions(options.data.data)
          }
        }
      } catch (error: any) {
        console.error(error)
        if (error.response.status === 401) {
          toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
          removeAuth()
        } else {
          toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
        }
      } finally {
      }

      return () => (didRequestOutlet.current = true)
    }

    if (brandID !== undefined && brandID.length > 0) {
      requestOutletOptions()
    } else {
      setOutletOptions([])
    }
    // eslint-disable-next-line
  }, [brandID])

  useEffect(() => {
    if (allowCompany === false) {
      setAllowCompanyError(true)
      toast(
        intl.formatMessage(
          {id: 'VALIDATION_REQUIRED_FIELD'},
          {name: intl.formatMessage({id: 'LABEL_COMPANY'})}
        ),
        {
          icon: '⚠️',
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowCompany])

  useEffect(() => {
    if (allowBrand === false) {
      setAllowBrandError(true)
      toast(
        intl.formatMessage(
          {id: 'VALIDATION_REQUIRED_FIELD'},
          {name: intl.formatMessage({id: 'LABEL_BRAND'})}
        ),
        {
          icon: '⚠️',
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowBrand])

  return (
    <>
      <div className='card-header border-0 mb-5'>
        <div className='card-body p-0 pt-5'>
          <div className='row'>
            {/* 1 Form */}
            <div className='col-lg-4'>
              <div className='fv-row mb-5'>
                <SearchComponent
                  useListQueryRequest={useListQueryRequest}
                  useListQueryResponse={useListQueryResponse}
                  labelUpper={intl.formatMessage({id: 'POS_REPORT_SHIFT_LIST_KEYWORD_LABEL'})}
                />
              </div>

              {/* Input group */}
              <div className='fv-row mb-5'>
                <label className='form-label fs-6 fw-bold'>
                  {intl.formatMessage({id: 'LABEL_FILTER_DATERANGE'})}
                </label>
                <DatePicker
                  oneTap
                  value={date === null ? null : new Date(moment(date).toDate())}
                  isoWeek
                  style={{maxWidth: 400}}
                  block
                  cleanable={false}
                  format='yyyy-MM-dd'
                  placement={`bottomStart`}
                  placeholder={intl.formatMessage({id: 'TOOLTIP_SELECT_ALL'})}
                  onChange={handleCallback}
                  // shouldDisableDate={(date: any) => isAfter(date, new Date())}
                  shouldDisableDate={afterToday()}
                />
              </div>
              {/* Input group */}

              {/* Input group */}
              <div className='fv-row mb-5'>
                <label className='form-label fs-6 fw-bold'>{'Holding :'}</label>
                <CheckPicker
                  style={{maxWidth: 400}}
                  block
                  placeholder={`${intl.formatMessage({id: 'TOOLTIP_SELECT_ALL'})}`}
                  data={optionsHolding}
                  onChange={(val, tr) => {
                    let tmp = []
                    let result: any[] = []
                    let label: any = holdingName

                    if (tr.type === 'keydown') {
                      // prevent user select/choice by using click enter
                      return
                    }

                    if (val.length > 0) {
                      if ((tr.target as unknown as any).checked === true) {
                        tmp = (optionsHolding as unknown as any[]).find(
                          (dt) => dt.label === (tr.target as unknown as any).labels[0].innerText
                        )
                        result = [...label, tmp.label]
                      } else {
                        if (tr.type === 'click') {
                          if ((tr.target as unknown as any).lastChild.nodeName === 'path') {
                            let tmp_result = label.filter(
                              (e: any) =>
                                e !==
                                (tr.target as unknown as any).parentElement.parentElement.innerText
                            )
                            result = tmp_result
                          }
                        } else {
                          let tmp_result = label.filter(
                            (e: any) => e !== (tr.target as unknown as any).labels[0].innerText
                          )
                          result = tmp_result
                        }
                      }
                      setHoldingID(val)
                      setHoldingName(result)
                    } else {
                      setHoldingID([])
                      setHoldingName([])
                    }

                    setCompanyID([])
                    setCompanyName([])
                    setBrandID([])
                    setBrandName([])
                    setOutletID([])
                    setOutletName([])
                  }}
                  onSelect={(val, ite) => {
                    let value: any = holdingID
                    let label: any = holdingName
                    let tmpValue: any = []
                    let tmpLabel: any = []
                    let resultValue: any[] = []
                    let resultLabel: any[] = []

                    if (val.length > 0) {
                      if (value.indexOf(ite.value) === -1) {
                        tmpValue = (optionsHolding as unknown as any[]).find(
                          (dt) => dt.value === ite.value
                        )

                        if (tmpValue === undefined) {
                          return
                        }

                        resultValue = [...value, tmpValue.value]
                        setHoldingID(resultValue)
                      } else {
                        tmpValue = value.filter((dt: any) => dt !== ite.value)
                        setHoldingID(tmpValue)
                      }

                      if (label.indexOf(ite.label) === -1) {
                        tmpLabel = (optionsHolding as unknown as any[]).find(
                          (dt) => dt.label === ite.label
                        )

                        if (tmpLabel === undefined) {
                          return
                        }

                        resultLabel = [...label, tmpLabel.label]
                        setHoldingName(resultLabel)
                      } else {
                        tmpLabel = label.filter((dt: any) => dt !== ite.label)
                        setHoldingName(tmpLabel)
                      }
                    } else {
                      setHoldingID([])
                      setHoldingName([])
                    }

                    setCompanyID([])
                    setCompanyName([])
                    setBrandID([])
                    setBrandName([])
                    setOutletID([])
                    setOutletName([])
                  }}
                  defaultValue={(optionsHolding as unknown as any[]).map((dt: any) => ({
                    value: dt.value,
                    label: dt.label,
                  }))}
                  value={holdingID}
                  renderExtraFooter={() => (
                    <div style={footerStyles}>
                      <Checkbox
                        indeterminate={
                          holdingID.length > 0 &&
                          holdingID.length < (optionsHolding as unknown as any[]).length
                        }
                        checked={holdingID.length === (optionsHolding as unknown as any[]).length}
                        onChange={(data, checked) => {
                          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                          if (checked) {
                            setHoldingID((optionsHolding as unknown as any[]).map((e) => e.value))
                            setHoldingName((optionsHolding as unknown as any[]).map((e) => e.label))
                          } else {
                            setHoldingID([])
                            setHoldingName([])
                          }
                        }}
                      >
                        {holdingID.length === (optionsHolding as unknown as any[]).length
                          ? intl.formatMessage({id: 'LABEL_UNSELECT_ALL'})
                          : intl.formatMessage({id: 'LABEL_SELECT_ALL'})}
                      </Checkbox>
                    </div>
                  )}
                />
              </div>
              {/* Input group */}
            </div>
            {/* 1 Form */}

            {/* 2 Form */}
            <div className='col-lg-4'>
              {/* Input group */}
              <div className='fv-row mb-5'>
                <label className='form-label fs-6 fw-bold'>
                  {intl.formatMessage({id: 'LABEL_COMPANY'})} :
                </label>
                <CheckPicker
                  style={{maxWidth: 400}}
                  block
                  placeholder={`${intl.formatMessage({id: 'TOOLTIP_SELECT_ALL'})}`}
                  disabled={holdingID.length > 0 ? false : true}
                  data={optionsCompany}
                  onChange={(val, tr) => {
                    let tmp = []
                    let result: any[] = []
                    let label: any = companyName

                    if (tr.type === 'keydown') {
                      // prevent user select/choice by using click enter
                      return
                    }

                    if (val.length > 0) {
                      if ((tr.target as unknown as any).checked === true) {
                        tmp = (optionsCompany as unknown as any[]).find(
                          (dt) => dt.label === (tr.target as unknown as any).labels[0].innerText
                        )
                        result = [...label, tmp.label]
                      } else {
                        if (tr.type === 'click') {
                          if ((tr.target as unknown as any).lastChild.nodeName === 'path') {
                            let tmp_result = label.filter(
                              (e: any) =>
                                e !==
                                (tr.target as unknown as any).parentElement.parentElement.innerText
                            )
                            result = tmp_result
                          }
                        } else {
                          let tmp_result = label.filter(
                            (e: any) => e !== (tr.target as unknown as any).labels[0].innerText
                          )
                          result = tmp_result
                        }
                      }
                      setCompanyID(val)
                      setCompanyName(result)
                      setAllowCompanyError(false)
                    } else {
                      setCompanyID([])
                      setCompanyName([])
                    }

                    setBrandID([])
                    setBrandName([])
                    setOutletID([])
                    setOutletName([])
                  }}
                  onSelect={(val, ite) => {
                    let value: any = companyID
                    let label: any = companyName
                    let tmpValue: any = []
                    let tmpLabel: any = []
                    let resultValue: any[] = []
                    let resultLabel: any[] = []

                    if (val.length > 0) {
                      if (value.indexOf(ite.value) === -1) {
                        tmpValue = (optionsCompany as unknown as any[]).find(
                          (dt) => dt.value === ite.value
                        )

                        if (tmpValue === undefined) {
                          return
                        }

                        resultValue = [...value, tmpValue.value]
                        setCompanyID(resultValue)
                      } else {
                        tmpValue = value.filter((dt: any) => dt !== ite.value)
                        setCompanyID(tmpValue)
                      }

                      if (label.indexOf(ite.label) === -1) {
                        tmpLabel = (optionsCompany as unknown as any[]).find(
                          (dt) => dt.label === ite.label
                        )

                        if (tmpLabel === undefined) {
                          return
                        }

                        resultLabel = [...label, tmpLabel.label]
                        setCompanyName(resultLabel)
                      } else {
                        tmpLabel = label.filter((dt: any) => dt !== ite.label)
                        setCompanyName(tmpLabel)
                      }
                      setAllowCompanyError(false)
                    } else {
                      setCompanyID([])
                      setCompanyName([])
                    }

                    setBrandID([])
                    setBrandName([])
                    setOutletID([])
                    setOutletName([])
                  }}
                  defaultValue={(optionsCompany as unknown as any[]).map((dt: any) => ({
                    value: dt.value,
                    label: dt.label,
                  }))}
                  value={companyID}
                  renderExtraFooter={() => (
                    <div style={footerStyles}>
                      <Checkbox
                        indeterminate={
                          companyID.length > 0 &&
                          companyID.length < (optionsCompany as unknown as any[]).length
                        }
                        checked={companyID.length === (optionsCompany as unknown as any[]).length}
                        onChange={(data, checked) => {
                          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                          if (checked) {
                            setCompanyID((optionsCompany as unknown as any[]).map((e) => e.value))
                            setCompanyName((optionsCompany as unknown as any[]).map((e) => e.label))
                          } else {
                            setCompanyID([])
                            setCompanyName([])
                          }
                        }}
                      >
                        {companyID.length === (optionsCompany as unknown as any[]).length
                          ? intl.formatMessage({id: 'LABEL_UNSELECT_ALL'})
                          : intl.formatMessage({id: 'LABEL_SELECT_ALL'})}
                      </Checkbox>
                    </div>
                  )}
                />
                {allowCompanyError === true && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block text-danger fw-bolder'>
                      {intl.formatMessage(
                        {id: 'VALIDATION_REQUIRED_FIELD'},
                        {
                          name: intl.formatMessage({id: 'LABEL_COMPANY'}),
                        }
                      )}
                    </div>
                  </div>
                )}
              </div>
              {/* Input group */}

              {/* Input group */}
              <div className='fv-row mb-5'>
                <label className='form-label fs-6 fw-bold'>
                  {intl.formatMessage({id: 'LABEL_BRAND'})} :
                </label>
                <CheckPicker
                  style={{maxWidth: 400}}
                  block
                  placeholder={`${intl.formatMessage({id: 'TOOLTIP_SELECT_ALL'})}`}
                  disabled={companyID.length > 0 ? false : true}
                  data={optionsBrand}
                  onChange={(val, tr) => {
                    let tmp = []
                    let result: any[] = []
                    let label: any = brandName

                    if (tr.type === 'keydown') {
                      // prevent user select/choice by using click enter
                      return
                    }

                    if (val.length > 0) {
                      if ((tr.target as unknown as any).checked === true) {
                        tmp = (optionsBrand as unknown as any[]).find(
                          (dt) => dt.label === (tr.target as unknown as any).labels[0].innerText
                        )
                        result = [...label, tmp.label]
                      } else {
                        if (tr.type === 'click') {
                          if ((tr.target as unknown as any).lastChild.nodeName === 'path') {
                            let tmp_result = label.filter(
                              (e: any) =>
                                e !==
                                (tr.target as unknown as any).parentElement.parentElement.innerText
                            )
                            result = tmp_result
                          }
                        } else {
                          let tmp_result = label.filter(
                            (e: any) => e !== (tr.target as unknown as any).labels[0].innerText
                          )
                          result = tmp_result
                        }
                      }
                      setBrandID(val)
                      setBrandName(result)
                      setAllowBrandError(false)
                    } else {
                      setBrandID([])
                      setBrandName([])
                    }

                    setOutletID([])
                    setOutletName([])
                  }}
                  onSelect={(val, ite) => {
                    let value: any = brandID
                    let label: any = brandName
                    let tmpValue: any = []
                    let tmpLabel: any = []
                    let resultValue: any[] = []
                    let resultLabel: any[] = []

                    if (val.length > 0) {
                      if (value.indexOf(ite.value) === -1) {
                        tmpValue = (optionsBrand as unknown as any[]).find(
                          (dt) => dt.value === ite.value
                        )

                        if (tmpValue === undefined) {
                          return
                        }

                        resultValue = [...value, tmpValue.value]
                        setBrandID(resultValue)
                      } else {
                        tmpValue = value.filter((dt: any) => dt !== ite.value)
                        setBrandID(tmpValue)
                      }

                      if (label.indexOf(ite.label) === -1) {
                        tmpLabel = (optionsBrand as unknown as any[]).find(
                          (dt) => dt.label === ite.label
                        )

                        if (tmpLabel === undefined) {
                          return
                        }

                        resultLabel = [...label, tmpLabel.label]
                        setBrandName(resultLabel)
                      } else {
                        tmpLabel = label.filter((dt: any) => dt !== ite.label)
                        setBrandName(tmpLabel)
                      }
                      setAllowBrandError(false)
                    } else {
                      setBrandID([])
                      setBrandName([])
                    }
                    setOutletID([])
                    setOutletName([])
                  }}
                  defaultValue={(optionsBrand as unknown as any[]).map((dt: any) => ({
                    value: dt.value,
                    label: dt.label,
                  }))}
                  value={brandID}
                  renderExtraFooter={() => (
                    <div style={footerStyles}>
                      <Checkbox
                        indeterminate={
                          brandID.length > 0 &&
                          brandID.length < (optionsBrand as unknown as any[]).length
                        }
                        checked={brandID.length === (optionsBrand as unknown as any[]).length}
                        onChange={(data, checked) => {
                          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                          if (checked) {
                            setBrandID((optionsBrand as unknown as any[]).map((e) => e.value))
                            setBrandName((optionsBrand as unknown as any[]).map((e) => e.label))
                          } else {
                            setBrandID([])
                            setBrandName([])
                          }
                        }}
                      >
                        {brandID.length === (optionsBrand as unknown as any[]).length
                          ? intl.formatMessage({id: 'LABEL_UNSELECT_ALL'})
                          : intl.formatMessage({id: 'LABEL_SELECT_ALL'})}
                      </Checkbox>
                    </div>
                  )}
                />
                {allowBrandError === true && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block text-danger fw-bolder'>
                      {intl.formatMessage(
                        {id: 'VALIDATION_REQUIRED_FIELD'},
                        {
                          name: intl.formatMessage({id: 'LABEL_BRAND'}),
                        }
                      )}
                    </div>
                  </div>
                )}
              </div>
              {/* Input group */}

              {/* Input group */}
              <div className='fv-row mb-5'>
                <label className='form-label fs-6 fw-bold'>
                  {intl.formatMessage({id: 'LABEL_OUTLET'})}&nbsp; :
                </label>
                <CheckPicker
                  style={{maxWidth: 400}}
                  block
                  placeholder={`${intl.formatMessage({id: 'TOOLTIP_SELECT_ALL'})}`}
                  disabled={brandID.length > 0 ? false : true}
                  data={optionsOutlet}
                  onChange={(val, tr) => {
                    let tmp = []
                    let result: any[] = []
                    let label: any = outletName

                    if (tr.type === 'keydown') {
                      // prevent user select/choice by using click enter
                      return
                    }

                    if (val.length > 0) {
                      if ((tr.target as unknown as any).checked === true) {
                        tmp = (optionsOutlet as unknown as any[]).find(
                          (dt) => dt.label === (tr.target as unknown as any).labels[0].innerText
                        )
                        result = [...label, tmp.label]
                      } else {
                        if (tr.type === 'click') {
                          if ((tr.target as unknown as any).lastChild.nodeName === 'path') {
                            let tmp_result = label.filter(
                              (e: any) =>
                                e !==
                                (tr.target as unknown as any).parentElement.parentElement.innerText
                            )
                            result = tmp_result
                          }
                        } else {
                          let tmp_result = label.filter(
                            (e: any) => e !== (tr.target as unknown as any).labels[0].innerText
                          )
                          result = tmp_result
                        }
                      }
                      setOutletID(val)
                      setOutletName(result)
                    } else {
                      setOutletID([])
                      setOutletName([])
                    }
                  }}
                  onSelect={(val, ite) => {
                    let value: any = outletID
                    let label: any = outletName
                    let tmpValue: any = []
                    let tmpLabel: any = []
                    let resultValue: any[] = []
                    let resultLabel: any[] = []

                    if (val.length > 0) {
                      if (value.indexOf(ite.value) === -1) {
                        tmpValue = (optionsOutlet as unknown as any[]).find(
                          (dt) => dt.value === ite.value
                        )

                        if (tmpValue === undefined) {
                          return
                        }

                        resultValue = [...value, tmpValue.value]
                        setOutletID(resultValue)
                      } else {
                        tmpValue = value.filter((dt: any) => dt !== ite.value)
                        setOutletID(tmpValue)
                      }

                      if (label.indexOf(ite.label) === -1) {
                        tmpLabel = (optionsOutlet as unknown as any[]).find(
                          (dt) => dt.label === ite.label
                        )

                        if (tmpLabel === undefined) {
                          return
                        }

                        resultLabel = [...label, tmpLabel.label]
                        setOutletName(resultLabel)
                      } else {
                        tmpLabel = label.filter((dt: any) => dt !== ite.label)
                        setOutletName(tmpLabel)
                      }
                    } else {
                      setOutletID([])
                      setOutletName([])
                    }
                  }}
                  defaultValue={(optionsOutlet as unknown as any[]).map((dt: any) => ({
                    value: dt.value,
                    label: dt.label,
                  }))}
                  value={outletID}
                  renderExtraFooter={() => (
                    <div style={footerStyles}>
                      <Checkbox
                        indeterminate={
                          outletID.length > 0 &&
                          outletID.length < (optionsOutlet as unknown as any[]).length
                        }
                        checked={outletID.length === (optionsOutlet as unknown as any[]).length}
                        onChange={(data, checked) => {
                          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                          if (checked) {
                            setOutletID((optionsOutlet as unknown as any[]).map((e) => e.value))
                            setOutletName((optionsOutlet as unknown as any[]).map((e) => e.label))
                          } else {
                            setOutletID([])
                            setOutletName([])
                          }
                        }}
                      >
                        {outletID.length === (optionsOutlet as unknown as any[]).length
                          ? intl.formatMessage({id: 'LABEL_UNSELECT_ALL'})
                          : intl.formatMessage({id: 'LABEL_SELECT_ALL'})}
                      </Checkbox>
                    </div>
                  )}
                />
              </div>
              {/* Input group */}
            </div>
            {/* 2 Form */}

            {/* 3 Form */}
            <div className='col-lg-4'>
              {/* Input group */}
              <div className='fv-row mb-10'>
                <label className='form-label fs-6 fw-bold'>Status :</label>
                <InputPicker
                  style={{ maxWidth: 400 }}
                  block
                  data={optionStatus}
                  placeholder={`${intl.formatMessage({id: 'TOOLTIP_SELECT_ALL'})}`}
                  defaultValue={
                    optionStatus
                      ? (optionStatus as unknown as any[]).find((e: any) => e.value === status)
                      : ''
                  }
                  onChange={(e) => {
                    setStatus(e)
                  }}
                  value={status}
                />
              </div>
              {/* Input group */}

              <div className='fv-row mb-5'></div>

              <div className='fv-row mb-5 d-flex justify-content-between'>
                <ShortcutMenuButton />
                <FilterAction
                  filterData={filterData}
                  isLoading={isLoading}
                  resetData={resetData}
                  setStatus={setStatus}
                />
              </div>
            </div>
            {/* 3 Form */}
          </div>
        </div>
      </div>
    </>
  )
}

export {ListFilter}
