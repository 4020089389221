import {useState} from 'react'
import {useIntl} from 'react-intl'
import {Checkbox, Drawer, InputPicker, CheckPicker} from 'rsuite'

import {isMobileDevice} from '_metronic/assets/ts/_utils'
import {FilterAction, FilterButton} from 'app/components'

import {useAction, useListQueryRequest, useListQueryResponse} from '../../providers'

const footerStyles = {
  padding: '0px 2px',
  borderTop: '1px solid #e5e5e5',
}

const ListFilter = () => {
  const intl = useIntl()
  const mobile = isMobileDevice()
  const {state, updateState} = useListQueryRequest()
  const {isLoading} = useListQueryResponse()
  const {showFilter, setShowFilter, cityOptions} = useAction()
  const [cityID, setCityID] = useState(state?.filter !== undefined ? state?.filter.city_id : [])
  const [cityName, setCityName] = useState(
    state?.filter !== undefined ? state?.filter.city_name : []
  )
  const optionsCity =
    cityOptions === undefined
      ? []
      : (cityOptions as unknown as any[]).map((city: any) => ({
          value: city.city_id,
          label: city.name,
        }))
  const optionStatus = [
    {
      value: 't',
      label: intl.formatMessage({id: 'LABEL_ACTIVE'}),
    },
    {
      value: 'f',
      label: intl.formatMessage({id: 'LABEL_INACTIVE'}),
    },
  ]
  const [status, setStatus] = useState(state?.filter !== undefined ? state?.filter.status : '')

  const resetData = () => {
    setCityID([])
    setCityName([])
    setStatus('')
    updateState({filter: undefined})
  }

  const filterData = () => {
    updateState({
      filter: {city_id: cityID, cityName, status},
    })
    setShowFilter(false)
  }

  return (
    <>
      <FilterButton
        isLoading={isLoading}
        state={state}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
      />
      <Drawer
        open={showFilter}
        onClose={() => {
          setShowFilter(false)
        }}
        placement='right'
        style={{width: mobile ? '100%' : '25%'}}
      >
        <Drawer.Header closeButton>
          <Drawer.Actions>
            {/* Actions */}
            <FilterAction
              filterData={filterData}
              isLoading={isLoading}
              resetData={resetData}
              setStatus={setStatus}
            />
            {/* Actions */}
          </Drawer.Actions>
        </Drawer.Header>

        {/* Content */}
        <div className='card-body table-responsive m-5'>
          {/* Input group */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>
              {intl.formatMessage({id: 'LABEL_CITY'})} :
            </label>
            <CheckPicker
              block
              placeholder={`-- ${intl.formatMessage({id: 'LABEL_SELECT'})} --`}
              data={optionsCity}
              onChange={(val, tr) => {
                let tmp = []
                let result: any[] = []
                let label: any = cityName

                if (tr.type === 'keydown') {
                  // prevent user select/choice by using click enter
                  return
                }

                if (val.length > 0) {
                  if ((tr.target as unknown as any).checked === true) {
                    tmp = (optionsCity as unknown as any[]).find(
                      (dt) => dt.label === (tr.target as unknown as any).labels[0].innerText
                    )
                    result = [...label, tmp.label]
                  } else {
                    if (tr.type === 'click') {
                      if ((tr.target as unknown as any).lastChild.nodeName === 'path') {
                        let tmp_result = label.filter(
                          (e: any) =>
                            e !==
                            (tr.target as unknown as any).parentElement.parentElement.innerText
                        )
                        result = tmp_result
                      }
                    } else {
                      let tmp_result = label.filter(
                        (e: any) => e !== (tr.target as unknown as any).labels[0].innerText
                      )
                      result = tmp_result
                    }
                  }
                  setCityID(val)
                  setCityName(result)
                } else {
                  setCityID([])
                  setCityName([])
                }
              }}
              onSelect={(val, ite) => {
                let value: any = cityID
                let label: any = cityName
                let tmpValue: any = []
                let tmpLabel: any = []
                let resultValue: any[] = []
                let resultLabel: any[] = []

                if (val.length > 0) {
                  if (value.indexOf(ite.value) === -1) {
                    tmpValue = (optionsCity as unknown as any[]).find(
                      (dt) => dt.value === ite.value
                    )

                    if (tmpValue === undefined) {
                      return
                    }

                    resultValue = [...value, tmpValue.value]
                    setCityID(resultValue)
                  } else {
                    tmpValue = value.filter((dt: any) => dt !== ite.value)
                    setCityID(tmpValue)
                  }

                  if (label.indexOf(ite.label) === -1) {
                    tmpLabel = (optionsCity as unknown as any[]).find(
                      (dt) => dt.label === ite.label
                    )

                    if (tmpLabel === undefined) {
                      return
                    }

                    resultLabel = [...label, tmpLabel.label]
                    setCityName(resultLabel)
                  } else {
                    tmpLabel = label.filter((dt: any) => dt !== ite.label)
                    setCityName(tmpLabel)
                  }
                } else {
                  setCityID([])
                  setCityName([])
                }
              }}
              defaultValue={(optionsCity as unknown as any[]).map((dt: any) => ({
                value: dt.value,
                label: dt.label,
              }))}
              value={cityID}
              renderExtraFooter={() => (
                <div style={footerStyles}>
                  <Checkbox
                    indeterminate={
                      cityID.length > 0 && cityID.length < (optionsCity as unknown as any[]).length
                    }
                    checked={cityID.length === (optionsCity as unknown as any[]).length}
                    onChange={(data, checked) => {
                      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                      if (checked) {
                        setCityID((optionsCity as unknown as any[]).map((e) => e.value))
                        setCityName((optionsCity as unknown as any[]).map((e) => e.label))
                      } else {
                        setCityID([])
                        setCityName([])
                      }
                    }}
                  >
                    {cityID.length === (optionsCity as unknown as any[]).length
                      ? intl.formatMessage({id: 'LABEL_UNSELECT_ALL'})
                      : intl.formatMessage({id: 'LABEL_SELECT_ALL'})}
                  </Checkbox>
                </div>
              )}
            />
          </div>
          {/* Input group */}

          {/* Input group */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Status :</label>
            <InputPicker
              block
              data={optionStatus}
              placeholder={`-- ${intl.formatMessage({id: 'LABEL_SELECT'})} --`}
              defaultValue={
                optionStatus
                  ? (optionStatus as unknown as any[]).find((e: any) => e.value === status)
                  : ''
              }
              onChange={(e) => {
                setStatus(e)
              }}
              value={status}
            />
          </div>
          {/* Input group */}
        </div>
        {/* Content */}
      </Drawer>
    </>
  )
}

export {ListFilter}
