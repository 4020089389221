import {UserGroupModulesResponse} from '../models'

const USER_GROUP_MODULES_STATE_KEY = 'user-group-modules-state'

const getUserGroupModules = (): UserGroupModulesResponse | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(USER_GROUP_MODULES_STATE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const userGroupModules: UserGroupModulesResponse = JSON.parse(
      lsValue
    ) as UserGroupModulesResponse
    if (userGroupModules) {
      return userGroupModules
    }
  } catch (error) {
    console.error('USER GROUP MODULES STATE LOCAL STORAGE PARSE ERROR', error)
  }
}

const setUserGroupModules = (userGroupModules: UserGroupModulesResponse) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(userGroupModules)
    localStorage.setItem(USER_GROUP_MODULES_STATE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

export {getUserGroupModules, setUserGroupModules, USER_GROUP_MODULES_STATE_KEY}
