import {createContext, FC, useContext, useState} from 'react'

import {WithChildren} from '_metronic/helpers'

import {ActionContextProps, initialAction} from '../helpers'

const Action = createContext<ActionContextProps>(initialAction)

const ActionProvider: FC<WithChildren> = ({children}) => {
  const [showFilter, setShowFilter] = useState(false)

  return (
    <Action.Provider
      value={{
        showFilter,
        setShowFilter,
      }}
    >
      {children}
    </Action.Provider>
  )
}

const useAction = () => useContext(Action)

export {ActionProvider, useAction}
