import axios, {AxiosResponse} from 'axios'

import {APIResponse} from 'app/components'

import {ListQueryResponse, DistrictID} from '.'

const CITY_API_URL = `${process.env.REACT_APP_MOBILE_APP_API_URL}/city`
const DISTRICT_API_URL = `${process.env.REACT_APP_MOBILE_APP_API_URL}/district`
const MOBILE_X_API_KEY = process.env.REACT_APP_MOBILE_APP_API_KEY

const fetch = (query: any): Promise<ListQueryResponse> => {
  return axios
    .get(`${DISTRICT_API_URL}/list?${query}`, {
      headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
    })
    .then((response: AxiosResponse<ListQueryResponse>) => response.data)
}

const fetchCityOptions = (mode: any) => {
  return axios.get(`${CITY_API_URL}/list-options/${mode}/null`, {
    headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
  })
}

const get = (district_id: DistrictID): Promise<APIResponse | undefined> => {
  return axios
    .get(`${DISTRICT_API_URL}/${district_id}/detail`, {
      headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
    })
    .then((response: AxiosResponse<APIResponse>) => response.data)
}

const addData = (payload: any): Promise<APIResponse | undefined> => {
  return axios
    .post(
      `${DISTRICT_API_URL}`,
      {payload},
      {
        headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
      }
    )
    .then((response: AxiosResponse<APIResponse>) => response.data)
}

const updateData = (payload: any): Promise<APIResponse | undefined> => {
  return axios
    .put(
      `${DISTRICT_API_URL}/${payload.district_id}/update`,
      {payload},
      {
        headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
      }
    )
    .then((response: AxiosResponse<APIResponse>) => response.data)
}

const deleteData = (
  district_id: any,
  menu_id: any,
  creator_id: any,
  creator_by: any
): Promise<APIResponse | undefined> => {
  return axios
    .delete(`${DISTRICT_API_URL}/${district_id}/${menu_id}/${creator_id}/${creator_by}/delete`, {
      headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
    })
    .then((response: AxiosResponse<APIResponse>) => response.data)
}

export {fetch, fetchCityOptions, get, updateData, addData, deleteData}
