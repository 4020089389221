import {FC, lazy, useEffect, useRef, useState} from 'react'
import toast from 'react-hot-toast'
import {useIntl} from 'react-intl'
import {Navigate, Outlet, Route, Routes, useLocation} from 'react-router-dom'

import {PageLink, PageTitle} from '_metronic/layout/core'
import {SuspendViewComponents} from 'app/components'
import {CurrentMenuAccessModel} from 'app/context/models'
import {useUserGroupModules} from 'app/context/providers'
import {getCurrentMenuAccessByRoleIDAndMenuID} from 'app/context/requests'
import {removeAuth, useAuth} from 'app/modules/auth'

import * as userListState from 'app/modules/backoffice-orins-pos/pos-settings/user-management/user/providers/ListQueryStateContext'
import {initialListQueryState as userListQueryState} from 'app/modules/backoffice-orins-pos/pos-settings/user-management/user/helpers/ListQueryModel'
import * as groupListState from 'app/modules/backoffice-orins-pos/pos-settings/user-management/group/providers/ListQueryStateContext'
import {initialListQueryState as groupListQueryState} from 'app/modules/backoffice-orins-pos/pos-settings/user-management/group/helpers/ListQueryModel'
import * as accessListState from 'app/modules/backoffice-orins-pos/pos-settings/user-management/access/providers/ListQueryStateContext'
import {initialListQueryState as accessListQueryState} from 'app/modules/backoffice-orins-pos/pos-settings/user-management/access/helpers/ListQueryModel'
import * as logListState from 'app/modules/backoffice-orins-pos/pos-settings/user-management/log/providers/ListQueryStateContext'
import {initialListQueryState as logListQueryState} from 'app/modules/backoffice-orins-pos/pos-settings/user-management/log/helpers/ListQueryModel'
import * as holdingListState from 'app/modules/backoffice-orins-pos/pos-settings/masters/holding/providers/ListQueryStateContext'
import {initialListQueryState as holdingListQueryState} from 'app/modules/backoffice-orins-pos/pos-settings/masters/holding/helpers/ListQueryModel'
import * as companyListState from 'app/modules/backoffice-orins-pos/pos-settings/masters/company/providers/ListQueryStateContext'
import {initialListQueryState as companyListQueryState} from 'app/modules/backoffice-orins-pos/pos-settings/masters/company/helpers/ListQueryModel'
import * as brandListState from 'app/modules/backoffice-orins-pos/pos-settings/masters/brand/providers/ListQueryStateContext'
import {initialListQueryState as brandListQueryState} from 'app/modules/backoffice-orins-pos/pos-settings/masters/brand/helpers/ListQueryModel'
import * as provinceListState from 'app/modules/backoffice-orins-pos/pos-settings/masters/province/providers/ListQueryStateContext'
import {initialListQueryState as provinceListQueryState} from 'app/modules/backoffice-orins-pos/pos-settings/masters/province/helpers/ListQueryModel'
import * as cityListState from 'app/modules/backoffice-orins-pos/pos-settings/masters/city/providers/ListQueryStateContext'
import {initialListQueryState as cityListQueryState} from 'app/modules/backoffice-orins-pos/pos-settings/masters/city/helpers/ListQueryModel'
import * as districtListState from 'app/modules/backoffice-orins-pos/pos-settings/masters/districts/providers/ListQueryStateContext'
import {initialListQueryState as districtListQueryState} from 'app/modules/backoffice-orins-pos/pos-settings/masters/districts/helpers/ListQueryModel'
import * as villageListState from 'app/modules/backoffice-orins-pos/pos-settings/masters/villages/providers/ListQueryStateContext'
import {initialListQueryState as villageListQueryState} from 'app/modules/backoffice-orins-pos/pos-settings/masters/villages/helpers/ListQueryModel'
import * as areaListState from 'app/modules/backoffice-orins-pos/pos-settings/masters/area/providers/ListQueryStateContext'
import {initialListQueryState as areaListQueryState} from 'app/modules/backoffice-orins-pos/pos-settings/masters/area/helpers/ListQueryModel'
import * as clusterListState from 'app/modules/backoffice-orins-pos/pos-settings/masters/cluster/providers/ListQueryStateContext'
import {initialListQueryState as clusterListQueryState} from 'app/modules/backoffice-orins-pos/pos-settings/masters/cluster/helpers/ListQueryModel'
import * as outletListState from 'app/modules/backoffice-orins-pos/pos-settings/masters/outlet/providers/ListQueryStateContext'
import {initialListQueryState as outletListQueryState} from 'app/modules/backoffice-orins-pos/pos-settings/masters/outlet/helpers/ListQueryModel'
import * as mobileLayoutMenuListState from 'app/modules/backoffice-orins-pos/pos-settings/mobile-layout/menu/providers/ListQueryStateContext'
import {initialListQueryState as mobileLayoutMenuListQueryState} from 'app/modules/backoffice-orins-pos/pos-settings/mobile-layout/menu/helpers/ListQueryModel'
import * as libraryPaymentMethodListState from 'app/modules/backoffice-orins-pos/pos-settings/library/payment-method/providers/ListQueryStateContext'
import {initialListQueryState as libraryPaymentMethodListQueryState} from 'app/modules/backoffice-orins-pos/pos-settings/library/payment-method/helpers/ListQueryModel'
import * as librarySalesTypeListState from 'app/modules/backoffice-orins-pos/pos-settings/library/sales-type/providers/ListQueryStateContext'
import {initialListQueryState as librarySalesTypeListQueryState} from 'app/modules/backoffice-orins-pos/pos-settings/library/sales-type/helpers/ListQueryModel'
import * as libraryCategoriesListState from 'app/modules/backoffice-orins-pos/pos-settings/library/categories/providers/ListQueryStateContext'
import {initialListQueryState as libraryCategoriesListQueryState} from 'app/modules/backoffice-orins-pos/pos-settings/library/categories/helpers/ListQueryModel'
import * as libraryItemListState from 'app/modules/backoffice-orins-pos/pos-settings/library/item/providers/ListQueryStateContext'
import {initialListQueryState as libraryItemListQueryState} from 'app/modules/backoffice-orins-pos/pos-settings/library/item/helpers/ListQueryModel'
import * as libraryDiscountsListState from 'app/modules/backoffice-orins-pos/pos-settings/library/discounts/providers/ListQueryStateContext'
import {initialListQueryState as libraryDiscountsListQueryState} from 'app/modules/backoffice-orins-pos/pos-settings/library/discounts/helpers/ListQueryModel'
import * as receiptMediaListState from 'app/modules/backoffice-orins-pos/pos-settings/receipt/media/providers/ListQueryStateContext'
import {initialListQueryState as receiptMediaListQueryState} from 'app/modules/backoffice-orins-pos/pos-settings/receipt/media/helpers/ListQueryModel'
import * as receiptDataListState from 'app/modules/backoffice-orins-pos/pos-settings/receipt/data/providers/ListQueryStateContext'
import {initialListQueryState as receiptDataListQueryState} from 'app/modules/backoffice-orins-pos/pos-settings/receipt/data/helpers/ListQueryModel'

const UserPageProvider = lazy(() => import('./user/UserPageProvider'))
const GroupPageProvider = lazy(() => import('./group/GroupPageProvider'))
const AccessPageProvider = lazy(() => import('./access/AccessPageProvider'))
const LogPageProvider = lazy(() => import('./log/LogPageProvider'))

const BackOfficeOrinsPOSUserManagementWrapper: FC = () => {
  const intl = useIntl()
  const {pathname} = useLocation()
  const {
    currentModuleLabel,
    currentModulePath,
    currentSiteLabel,
    currentSitePath,
    currentParentMenuLabel,
    currentParentMenuPath,
    currentMenuLabel,
    currentMenuID,
  } = useUserGroupModules()
  const didRequest = useRef(false)
  const {currentUser} = useAuth()
  const [access, setAccess] = useState<CurrentMenuAccessModel | undefined>(undefined)

  const breadCrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({id: 'LABEL_HOMEPAGE'}),
      path: 'homepage',
      isSeparator: false,
      isActive: false,
    },
    {
      title: currentModuleLabel,
      path: currentModulePath,
      isSeparator: false,
      isActive: false,
    },
    {
      title: currentSiteLabel,
      path: currentSitePath,
      isSeparator: false,
      isActive: false,
    },
    {
      title: currentParentMenuLabel,
      path: currentParentMenuPath,
      isSeparator: false,
      isActive: false,
    },
  ]

  useEffect(() => {
    if (userListState.getListState() === undefined) {
      userListState.setListState(userListQueryState)
    }
    if (groupListState.getListState() === undefined) {
      groupListState.setListState(groupListQueryState)
    }
    if (accessListState.getListState() === undefined) {
      accessListState.setListState(accessListQueryState)
    }
    if (logListState.getListState() === undefined) {
      logListState.setListState(logListQueryState)
    }
    if (holdingListState.getListState() === undefined) {
      holdingListState.setListState(holdingListQueryState)
    }
    if (companyListState.getListState() === undefined) {
      companyListState.setListState(companyListQueryState)
    }
    if (brandListState.getListState() === undefined) {
      brandListState.setListState(brandListQueryState)
    }
    if (provinceListState.getListState() === undefined) {
      provinceListState.setListState(provinceListQueryState)
    }
    if (cityListState.getListState() === undefined) {
      cityListState.setListState(cityListQueryState)
    }
    if (districtListState.getListState() === undefined) {
      districtListState.setListState(districtListQueryState)
    }
    if (villageListState.getListState() === undefined) {
      villageListState.setListState(villageListQueryState)
    }
    if (areaListState.getListState() === undefined) {
      areaListState.setListState(areaListQueryState)
    }
    if (clusterListState.getListState() === undefined) {
      clusterListState.setListState(clusterListQueryState)
    }
    if (outletListState.getListState() === undefined) {
      outletListState.setListState(outletListQueryState)
    }
    if (mobileLayoutMenuListState.getListState() === undefined) {
      mobileLayoutMenuListState.setListState(mobileLayoutMenuListQueryState)
    }
    if (libraryPaymentMethodListState.getListState() === undefined) {
      libraryPaymentMethodListState.setListState(libraryPaymentMethodListQueryState)
    }
    if (librarySalesTypeListState.getListState() === undefined) {
      librarySalesTypeListState.setListState(librarySalesTypeListQueryState)
    }
    if (libraryCategoriesListState.getListState() === undefined) {
      libraryCategoriesListState.setListState(libraryCategoriesListQueryState)
    }
    if (libraryItemListState.getListState() === undefined) {
      libraryItemListState.setListState(libraryItemListQueryState)
    }
    if (libraryDiscountsListState.getListState() === undefined) {
      libraryDiscountsListState.setListState(libraryDiscountsListQueryState)
    }
    if (receiptMediaListState.getListState() === undefined) {
      receiptMediaListState.setListState(receiptMediaListQueryState)
    }
    if (receiptDataListState.getListState() === undefined) {
      receiptDataListState.setListState(receiptDataListQueryState)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const requestCurrentMenuAccess = async () => {
      try {
        if (!didRequest.current) {
          const access = await getCurrentMenuAccessByRoleIDAndMenuID(
            currentUser?.role.role_id,
            currentMenuID
          )
          // throw message error
          if (access.data.meta.code === 200) {
            setAccess(access.data.data)
          }
          if (access.data.meta.code === 404 || access.data.meta.code === 500) {
            toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
          }
          if (access.data.meta.code === 401) {
            toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
            removeAuth()
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          if ((error as unknown as any).response.data.meta.code === 401) {
            toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
            removeAuth()
          } else {
            toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
          }
        }
      } finally {
      }

      return () => (didRequest.current = true)
    }

    if (
      currentMenuID !== undefined &&
      currentMenuID !== '' &&
      pathname.split('/')[3] === currentParentMenuPath.split('/')[3]
    ) {
      requestCurrentMenuAccess()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMenuID])

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='app_users'
          element={
            <SuspendViewComponents>
              <PageTitle breadcrumbs={breadCrumbs}>{currentMenuLabel}</PageTitle>
              <UserPageProvider access={access} />
            </SuspendViewComponents>
          }
        />
        <Route
          path='app_groups'
          element={
            <SuspendViewComponents>
              <PageTitle breadcrumbs={breadCrumbs}>{currentMenuLabel}</PageTitle>
              <GroupPageProvider access={access} />
            </SuspendViewComponents>
          }
        />
        <Route
          path='app_access'
          element={
            <SuspendViewComponents>
              <PageTitle breadcrumbs={breadCrumbs}>{currentMenuLabel}</PageTitle>
              <AccessPageProvider access={access} />
            </SuspendViewComponents>
          }
        />
        <Route
          path='app_logs'
          element={
            <SuspendViewComponents>
              <PageTitle breadcrumbs={breadCrumbs}>{currentMenuLabel}</PageTitle>
              <LogPageProvider access={access} />
            </SuspendViewComponents>
          }
        />
        <Route
          index
          element={<Navigate to='/backoffice-orins-pos/pos-settings/users/app_users' />}
        />
      </Route>
    </Routes>
  )
}

export {BackOfficeOrinsPOSUserManagementWrapper}
