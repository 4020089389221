import {BrandID, ListResponse} from '.'

export type ListModel = {
  brand_id?: string
  name?: string
  slug?: string
  company_slug?: string
  email?: string
  description?: string
  status?: string
}

export type ListQueryResponse = ListResponse<Array<ListModel>>

export const initialList: ListModel = {
  brand_id: '',
  name: '',
  slug: '',
  email: '',
  description: '',
  status: '',
  company_slug: '',
}

export type HoldingModel = {
  holding_id: string
  name: string
  slug: string
}

export type CompanyModel = {
  company_id: string
  name: string
  slug: string
}

export type DetailModel = {
  brand_id?: BrandID
  holding_id?: string // modified payload for crud
  holding_name?: string // modified payload for crud
  company_id?: string // modified payload for crud
  company_name?: string // modified payload for crud
  name?: string
  slug?: string
  email?: string
  description?: string
  logo?: string
  file?: any
  status?: string
  holding?: HoldingModel
  company?: CompanyModel
  created_at?: string
  created_by?: string
  updated_at?: string
  updated_by?: string
  menu_id?: string // modified payload for crud
  creator_id?: string // modified payload for crud
  creator_by?: string // modified payload for crud
}

export const initialData: DetailModel = {
  brand_id: '',
  holding_id: '', // modified payload for crud
  holding_name: '', // modified payload for crud
  company_id: '', // modified payload for crud
  company_name: '', // modified payload for crud
  name: '',
  slug: '',
  email: '',
  description: '',
  status: 't',
  company: {
    company_id: '',
    name: '',
    slug: '',
  },
  logo: '',
  file: null,
  created_at: '',
  created_by: '',
  updated_at: '',
  updated_by: '',
  menu_id: '', // modified payload for crud
  creator_id: '', // modified payload for crud
  creator_by: '', // modified payload for crud
}
