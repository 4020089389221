import React, {useEffect} from 'react'
import clsx from 'clsx'
import {useLocation} from 'react-router'

import {useUserGroupModules} from 'app/context/providers'
import {WithChildren, checkIsActive, KTIcon} from '_metronic/helpers'
import {useLayout} from '_metronic/layout/core'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  idx: string
}

const SidebarMenuItemWithSub: React.FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet,
  idx,
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const {config} = useLayout()
  const {app} = config
  const {setCurrentParentMenuLabel, setCurrentParentMenuPath} = useUserGroupModules()

  useEffect(() => {
    isActive && setCurrentParentMenuLabel(title)
    isActive && setCurrentParentMenuPath(to)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive])

  return (
    <div
      className={clsx('menu-item', {'here show': isActive}, 'menu-accordion')}
      data-kt-menu-trigger='click'
      key={idx}
    >
      <span className='menu-link'>
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
          <span className='menu-icon'>
            <KTIcon iconName={icon} className={clsx('fs-2', {'text-primary': isActive})} />
          </span>
        )}
        {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
          <i className={clsx('bi fs-3', fontIcon)}></i>
        )}
        <span
          className={clsx('menu-title', {
            'text-primary text-decoration-underline fw-bolder': isActive,
          })}
        >
          {title}
        </span>
        <span className='menu-arrow'></span>
      </span>
      <div className={clsx('menu-sub menu-sub-accordion', {'menu-active-bg': isActive})}>
        {children}
      </div>
    </div>
  )
}

export {SidebarMenuItemWithSub}
