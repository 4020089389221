import {SocialMediaID, ListResponse} from '.'

export type ListModel = {
  social_media_id?: string
  name?: string
  logo?: string
  url?: string
  for_order?: number
  status?: string
}

export type ListQueryResponse = ListResponse<Array<ListModel>>

export const initialList: ListModel = {
  social_media_id: '',
  name: '',
  logo: '',
  url: '',
  for_order: 0,
  status: '',
}

export type DetailModel = {
  social_media_id?: SocialMediaID
  name?: string
  status?: string
  url?: string
  for_order?: number
  logo?: string
  file?: any
  created_at?: string
  created_by?: string
  updated_at?: string
  updated_by?: string
  menu_id?: string // modified payload for crud
  creator_id?: string // modified payload for crud
  creator_by?: string // modified payload for crud
}

export const initialData: DetailModel = {
  social_media_id: '',
  name: '',
  status: '',
  url: '',
  for_order: 1,
  logo: '',
  file: null,
  created_at: '',
  created_by: '',
  updated_at: '',
  updated_by: '',
  menu_id: '', // modified payload for crud
  creator_id: '', // modified payload for crud
  creator_by: '', // modified payload for crud
}
