import {CompanyID, ListResponse} from '.'

export type ListModel = {
  company_id?: string
  name?: string
  slug?: string
  holding_slug?: string
  email?: string
  description?: string
  status?: string
}

export type ListQueryResponse = ListResponse<Array<ListModel>>

export const initialList: ListModel = {
  company_id: '',
  name: '',
  slug: '',
  email: '',
  description: '',
  status: '',
  holding_slug: '',
}

export type HoldingModel = {
  holding_id: string
  name: string
  slug: string
}

export type DetailModel = {
  company_id?: CompanyID
  holding_id?: string // modified payload for crud
  holding_name?: string // modified payload for crud
  name?: string
  slug?: string
  email?: string
  description?: string
  status?: string
  holding?: HoldingModel
  created_at?: string
  created_by?: string
  updated_at?: string
  updated_by?: string
  menu_id?: string // modified payload for crud
  creator_id?: string // modified payload for crud
  creator_by?: string // modified payload for crud
}

export const initialData: DetailModel = {
  company_id: '',
  holding_id: '', // modified payload for crud
  holding_name: '', // modified payload for crud
  name: '',
  slug: '',
  email: '',
  description: '',
  status: '',
  holding: {
    holding_id: '',
    name: '',
    slug: '',
  },
  created_at: '',
  created_by: '',
  updated_at: '',
  updated_by: '',
  menu_id: '', // modified payload for crud
  creator_id: '', // modified payload for crud
  creator_by: '', // modified payload for crud
}
