import {PaymentMethodID, ListResponse} from '.'

export type ListModel = {
  payment_method_id?: string
  name?: string
  logo?: string
  color?: string
  status?: string
}

export type ListQueryResponse = ListResponse<Array<ListModel>>

export const initialList: ListModel = {
  payment_method_id: '',
  name: '',
  logo: '',
  color: '',
  status: '',
}

export type HoldingModel = {
  holding_id: string
  name: string
}
export type CompanyModel = {
  company_id: string
  name: string
}
export type BrandModel = {
  brand_id: string
  name: string
}

export type DetailModel = {
  payment_method_id?: PaymentMethodID
  holding_id?: any // modified payload for crud
  holding_name?: any // modified payload for crud
  company_id?: any // modified payload for crud
  company_name?: any // modified payload for crud
  brand_id?: any // modified payload for crud
  brand_name?: any // modified payload for crud
  holding?: HoldingModel[]
  company?: CompanyModel[]
  brand?: BrandModel[]
  name?: string
  status?: string
  description?: string
  created_at?: string
  created_by?: string
  updated_at?: string
  updated_by?: string
  menu_id?: string // modified payload for crud
  creator_id?: string // modified payload for crud
  creator_by?: string // modified payload for crud
}

export const initialData: DetailModel = {
  payment_method_id: '',
  holding_id: [], // modified payload for crud
  holding_name: [], // modified payload for crud
  company_id: [], // modified payload for crud
  company_name: [], // modified payload for crud
  brand_id: [], // modified payload for crud
  brand_name: [], // modified payload for crud
  holding: [
    {
      holding_id: '',
      name: '',
    },
  ],

  company: [
    {
      company_id: '',
      name: '',
    },
  ],

  brand: [
    {
      brand_id: '',
      name: '',
    },
  ],
  name: '',
  status: '',
  description: '',
  created_at: '',
  created_by: '',
  updated_at: '',
  updated_by: '',
  menu_id: '', // modified payload for crud
  creator_id: '', // modified payload for crud
  creator_by: '', // modified payload for crud
}
