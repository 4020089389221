import { FC } from 'react'

import { ListFilter as AccountSettingsActivityListFilter } from 'app/modules/account-settings/content/activity/components'
import { ListFilter as UserListFilter } from 'app/modules/general/settings/user-management/user/components'
import { ListFilter as UserGroupListFilter } from 'app/modules/general/settings/user-management/group/components'
import { ListFilter as UserGroupAccessListFilter } from 'app/modules/general/settings/user-management/access/components'
import { ListFilter as LogListFilter } from 'app/modules/general/settings/user-management/log/components'
import { ListFilter as ModuleListFilter } from 'app/modules/general/settings/conditional/module/components'
import { ListFilter as MenuListFilter } from 'app/modules/general/settings/conditional/menu/components'
import { ListFilter as ParameterListFilter } from 'app/modules/general/settings/conditional/parameter/components'
import { ListFilter as MasterDataFilter } from 'app/modules/general/settings/data/components'
import { ListFilter as ModuleUserListFilter } from 'app/modules/settings-all-module/user/components'
import { ListFilter as ModuleAccessListFilter } from 'app/modules/settings-all-module/access/components'
import { ListFilter as ModuleSiteListFilter } from 'app/modules/settings-all-module/site/components'
import { ListFilter as ModuleMenuListFilter } from 'app/modules/settings-all-module/menu/components'
import { ListFilter as ModuleParameterListFilter } from 'app/modules/settings-all-module/parameter/components'
import { ListFilter as ModuleDataListFilter } from 'app/modules/settings-all-module/data/components'
import { ListFilter as MasterHoldingListFilter } from 'app/modules/backoffice-orins-pos/pos-settings/masters/holding/components'
import { ListFilter as MasterCompanyListFilter } from 'app/modules/backoffice-orins-pos/pos-settings/masters/company/components'
import { ListFilter as MasterBrandListFilter } from 'app/modules/backoffice-orins-pos/pos-settings/masters/brand/components'
import { ListFilter as MasterProvinceListFilter } from 'app/modules/backoffice-orins-pos/pos-settings/masters/province/components'
import { ListFilter as MasterCityListFilter } from 'app/modules/backoffice-orins-pos/pos-settings/masters/city/components'
import { ListFilter as MasterDistrictListFilter } from 'app/modules/backoffice-orins-pos/pos-settings/masters/districts/components'
import { ListFilter as MasterVillageListFilter } from 'app/modules/backoffice-orins-pos/pos-settings/masters/villages/components'
import { ListFilter as MasterAreaListFilter } from 'app/modules/backoffice-orins-pos/pos-settings/masters/area/components'
import { ListFilter as MasterClusterListFilter } from 'app/modules/backoffice-orins-pos/pos-settings/masters/cluster/components'
import { ListFilter as MasterOutletListFilter } from 'app/modules/backoffice-orins-pos/pos-settings/masters/outlet/components'
import { ListFilter as UserManagementUserListFilter } from 'app/modules/backoffice-orins-pos/pos-settings/user-management/user/components'
import { ListFilter as UserManagementGroupListFilter } from 'app/modules/backoffice-orins-pos/pos-settings/user-management/group/components'
import { ListFilter as UserManagementAccessListFilter } from 'app/modules/backoffice-orins-pos/pos-settings/user-management/access/components'
import { ListFilter as UserManagementLogListFilter } from 'app/modules/backoffice-orins-pos/pos-settings/user-management/log/components'
import { ListFilter as MobileLayoutMenuListFilter } from 'app/modules/backoffice-orins-pos/pos-settings/mobile-layout/menu/components'
import { ListFilter as LibraryPaymentMethodListFilter } from 'app/modules/backoffice-orins-pos/pos-settings/library/payment-method/components'
import { ListFilter as LibrarySalesTypeListFilter } from 'app/modules/backoffice-orins-pos/pos-settings/library/sales-type/components'
import { ListFilter as LibraryCategoriesListFilter } from 'app/modules/backoffice-orins-pos/pos-settings/library/categories/components'
import { ListFilter as LibraryItemListFilter } from 'app/modules/backoffice-orins-pos/pos-settings/library/item/components'
import { ListFilter as LibraryDiscountListFilter } from 'app/modules/backoffice-orins-pos/pos-settings/library/discounts/components'
import { ListFilter as ReceiptMediaListFilter } from 'app/modules/backoffice-orins-pos/pos-settings/receipt/media/components'
import { ListFilter as ReceiptDataListFilter } from 'app/modules/backoffice-orins-pos/pos-settings/receipt/data/components'
import { ListFilter as POSReportShiftListFilter } from 'app/modules/backoffice-orins-pos/orins-pos/report/shift/components'
import { ListFilter as POSReportVoidListFilter } from 'app/modules/backoffice-orins-pos/orins-pos/report/void/components'

type Props = {
  menuID?: any
  parentMenuID?: any
}

const ListFilterHelper: FC<Props> = ({ menuID, parentMenuID }) => {
  switch (menuID) {
    case 'activity':
      return <AccountSettingsActivityListFilter />
    case '2':
      return <UserListFilter />
    case '3':
      return <UserGroupListFilter />
    case '4':
      return <UserGroupAccessListFilter />
    case '5':
      return <LogListFilter />
    case '7':
      return <ModuleListFilter />
    case '8':
      return <MenuListFilter />
    case '9':
      return <ParameterListFilter />
    case '50':
      return <ModuleUserListFilter />
    case '51':
      return <ModuleAccessListFilter />
    case '53':
      return <ModuleSiteListFilter />
    case '54':
      return <ModuleMenuListFilter />
    case '55':
      return <ModuleParameterListFilter />
    case '125':
      return <MasterHoldingListFilter />
    case '129':
      return <MasterCompanyListFilter />
    case '126':
      return <MasterBrandListFilter />
    case '130':
      return <MasterProvinceListFilter />
    case '131':
      return <MasterCityListFilter />
    case '132':
      return <MasterDistrictListFilter />
    case '133':
      return <MasterVillageListFilter />
    case '135':
      return <MasterAreaListFilter />
    case '136':
      return <MasterClusterListFilter />
    case '128':
      return <MasterOutletListFilter />
    case '99':
      return <UserManagementUserListFilter />
    case '101':
      return <UserManagementGroupListFilter />
    case '100':
      return <UserManagementAccessListFilter />
    case '102':
      return <UserManagementLogListFilter />
    case '105':
      return <MobileLayoutMenuListFilter />
    case '142':
      return <LibraryPaymentMethodListFilter />
    case '127':
      return <LibrarySalesTypeListFilter />
    case '116':
      return <LibraryCategoriesListFilter />
    case '114':
      return <LibraryItemListFilter />
    case '119':
      return <LibraryDiscountListFilter />
    case '137':
      return <ReceiptDataListFilter />
    case '139':
      return <ReceiptMediaListFilter />
    case '123':
      return <POSReportShiftListFilter />
    case '124':
      return <POSReportVoidListFilter />
  }

  switch (parentMenuID) {
    case '11':
      return <MasterDataFilter />
    case '57':
      return <ModuleDataListFilter />
  }

  return <></>
}

export { ListFilterHelper }
