import {ListResponse} from '.'

export type ListModel = {
  sales_summary: SalesSummary
  sales_channel: SalesChannel[]
  sales_channel_summary: SalesChannelSummary
  payment_method: SalesChannel[]
  payment_method_summary: SalesChannelSummary
}
export type ListQueryResponse = ListResponse<ListModel>

export type SalesSummary = {
  gross_sales: number
  discount_amount: number
  net_sales: number
  tax_amount: number
  rounding: number
  total_price_amount: number
}
export type SalesSummaryV2Model = {
  gross_sales_expected: number
  gross_sales_refund: number
  gross_sales: number
  discount_amount_expected: number
  discount_amount_refund: number
  discount_amount: number
  net_sales_expected: number
  net_sales_refund: number
  net_sales: number
  tax_amount: number
  rounding: number
  total_price_amount_expected: number
  total_price_amount_refund: number
  total_price_amount: number
  total_count_expected: number
  total_count_refund: number
  total_refund_percentage: number
}
export type SalesChannel = {
  amount: number
  count: number
  name: string
}
export type SalesChannelSummary = {
  total_amount: number
  total_count: number
}
export type IsOfflineModel = {
  is_offline_expected: SalesChannel
  is_offline_refund: SalesChannel
  is_offline_actual: SalesChannel
}
export type IsOnlineModel = {
  is_online_expected: SalesChannel
  is_online_refund: SalesChannel
  is_online_actual: SalesChannel
}
export type SalesChannelV2Model = {
  is_offline: IsOfflineModel
  is_online: IsOnlineModel
  total_count_expected: number
  total_count_refund: number
  total_count: number
  total_refund_percentage: number
  total_amount_expected: number
  total_amount_refund: number
  total_amount: number
}

// export models
export interface ExportModels {
  exportMode: 'sales_summary' | 'sales_type' | 'payment_method'
}

export interface ExportData {
  currentStep: number
  isLoading: boolean
  mode: ExportModels
  url: string | undefined
}

export const defaultExportData: ExportData = {
  currentStep: 1,
  isLoading: false,
  mode: {exportMode: 'sales_summary'},
  url: undefined,
}

export type StepProps = {
  menuName?: string
  data: ExportData
  updateData: (fieldsToUpdate: Partial<ExportData>) => void
  hasError: boolean
}

export const defaultStepProps: StepProps = {
  menuName: '',
  data: defaultExportData,
  updateData: () => {},
  hasError: false,
}
