/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'

import {KTIcon, toAbsoluteUrl} from '_metronic/helpers'

type Props = {
  fileUrl?: any
  fileID?: any
  size?: any
}

const RowFileFormatter: FC<Props> = ({fileUrl, fileID, size}) => {
  return (
    <div className={`symbol symbol-${size ? size + 'px' : '50px'} symbol-2by3 overflow-hidden`}>
      <a href={fileUrl} target='_blank' rel='noreferrer'>
        {fileUrl ? (
          <div className='symbol-label'>
            <img src={toAbsoluteUrl(fileUrl)} alt={fileID} className='w-100' />
          </div>
        ) : (
          <div className='symbol-label'>
            <KTIcon iconName='information-3' className='fs-5x' />
          </div>
        )}
      </a>
    </div>
  )
}

export {RowFileFormatter}
