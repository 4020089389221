import {ShortcutMenus} from '../models'

const SHORTCUT_MENU_STATE_KEY = 'shortcut-menu-state'

const getShortcutMenus = (): ShortcutMenus | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: any = localStorage.getItem(SHORTCUT_MENU_STATE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const shortcutMenus: ShortcutMenus = JSON.parse(lsValue) as ShortcutMenus
    if (shortcutMenus) {
      return shortcutMenus
    }
  } catch (error) {
    console.error('SHORTCUT MENU STATE LOCAL STORAGE PARSE ERROR', error)
  }
}

const setShortcutMenus = (shortcutMenus: ShortcutMenus) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(shortcutMenus)
    localStorage.setItem(SHORTCUT_MENU_STATE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

export {getShortcutMenus, setShortcutMenus, SHORTCUT_MENU_STATE_KEY}
