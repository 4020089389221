import {FormattedMessage} from 'react-intl'
import subDays from 'date-fns/subDays'
import startOfWeek from 'date-fns/startOfWeek'
import endOfWeek from 'date-fns/endOfWeek'
import addDays from 'date-fns/addDays'
import startOfMonth from 'date-fns/startOfMonth'
import endOfMonth from 'date-fns/endOfMonth'
import addMonths from 'date-fns/addMonths'

const fullRangesPeriod = [
  {
    label: <FormattedMessage id='LABEL_TODAY' />,
    value: [new Date(), new Date()],
  },
  {
    label: <FormattedMessage id='LABEL_YESTERDAY' />,
    value: [addDays(new Date(), -1), addDays(new Date(), -1)],
  },
  {
    label: <FormattedMessage id='LABEL_THIS_WEEK' />,
    value: [startOfWeek(new Date()), endOfWeek(new Date())],
  },
  {
    label: <FormattedMessage id='LABEL_LAST_7_DAYS' />,
    value: [subDays(new Date(), 6), new Date()],
  },
  {
    label: <FormattedMessage id='LABEL_LAST_30_DAYS' />,
    value: [subDays(new Date(), 29), new Date()],
  },
  {
    label: <FormattedMessage id='LABEL_THIS_MONTH' />,
    value: [startOfMonth(new Date()), new Date()],
  },
  {
    label: <FormattedMessage id='LABEL_LAST_MONTH' />,
    value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
  },
  {
    label: <FormattedMessage id='LABEL_THIS_YEAR' />,
    value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
  },
  {
    label: <FormattedMessage id='LABEL_LAST_YEAR' />,
    value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
  },
]

export {fullRangesPeriod}
