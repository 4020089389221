import {FC, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {Cell, Column, HeaderCell, Table} from 'rsuite-table'
import {Loader, Placeholder} from 'rsuite'
import * as CurrencyFormat from 'react-currency-format'

import {useThemeMode} from '_metronic/partials'
import {isMobileDevice} from '_metronic/assets/ts/_utils'
import {
  RowTooltipCellFormatter,
  RowTooltipCountReportShiftOpenFormatter,
  RowTooltipCountReportShiftCloseFormatter,
  RowTooltipCountReportShiftCreatedByFormatter,
  RowDatetimeCellFormatter,
  RowTooltipCountReportShiftDifferentFormatter,
  RowTooltipCountReportShiftActualFormatter,
  RowTooltipCountReportShiftExpectedFormatter,
} from 'app/components'

import {
  useListQueryRequest,
  useListQueryResponseLoading,
  useListQueryResponseData,
  useAction,
} from '../../providers'

const ListTable: FC = () => {
  const intl = useIntl()
  const isMobile = isMobileDevice()
  const {setItemIdForUpdate, setOutletName} = useAction()
  const {state, updateState} = useListQueryRequest()
  const isLoading = useListQueryResponseLoading()
  const users = useListQueryResponseData()
  const data = useMemo(() => users, [users])
  const [usePlaceholder] = useState(true)
  const [sortColumn, setSortColumn] = useState(state?.sort)
  const [sortType, setSortType] = useState(state?.order)
  const {mode} = useThemeMode()

  const sortData = () => {
    if (sortColumn && sortType) {
      return data.sort((a: any, b: any) => {
        let x = a[sortColumn]
        let y = b[sortColumn]

        if (sortType === 'asc') {
          return x - y
        } else {
          return y - x
        }
      })
    }
    return data
  }

  const handleSortColumn = (sortColumn: any, sortType: any) => {
    setSortColumn(sortColumn)
    setSortType(sortType)
    updateState({sort: sortColumn, order: sortType})
  }

  const renderLoading = () => {
    if (usePlaceholder) {
      return (
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            background: mode === 'light' ? '#fff' : '#252530',
            padding: 20,
            zIndex: 1,
          }}
        >
          <Placeholder.Grid rows={5} columns={6} active />
        </div>
      )
    }

    return <Loader center backdrop content={intl.formatMessage({id: 'BUTTON_LABEL_PLEASE_WAIT'})} />
  }

  return (
    <div>
      <Table
        wordWrap={isMobile ? true : false}
        bordered
        cellBordered
        loading={isLoading}
        height={450}
        headerHeight={70}
        autoHeight={isMobile ? true : false}
        affixHeader={50}
        affixHorizontalScrollbar
        data={sortData()}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
        renderLoading={isLoading ? renderLoading : undefined}
        locale={{emptyMessage: intl.formatMessage({id: 'LABEL_EMPTY_DATA'})}}
        onRowClick={(dt) => {
          setItemIdForUpdate(dt.outlet_id)
          setOutletName(dt.outlet_name)
        }}
      >
        {/* Outlet */}
        <Column fixed={isMobile ? false : true} verticalAlign='middle' width={250}>
          <HeaderCell align='center'>
            <span
              className={`fw-bold fs-6 text-decoration-underline ${
                sortColumn === 'outlet_name' ? 'text-primary' : 'text-dark'
              }`}
            >
              {intl.formatMessage({id: 'LABEL_OUTLET'})}
            </span>
          </HeaderCell>
          <Cell dataKey='outlet_name' style={{cursor: 'pointer'}}>
            {(rowData: any) =>
              rowData.outlet_name.length < 37 ? (
                rowData.outlet_name
              ) : (
                <RowTooltipCellFormatter text={rowData.outlet_name} length={36} />
              )
            }
          </Cell>
        </Column>

        {/* Brand */}
        <Column verticalAlign='middle' width={85}>
          <HeaderCell align='center'>
            <span
              className={`fw-bold fs-6 text-decoration-underline ${
                sortColumn === 'brand_name' ? 'text-primary' : 'text-dark'
              }`}
            >
              {intl.formatMessage({id: 'LABEL_BRAND'})}
            </span>
          </HeaderCell>
          <Cell dataKey='brand_name' align='center' style={{cursor: 'pointer'}}>
            {(rowData: any) =>
              rowData.brand_name.length < 8 ? (
                rowData.brand_name
              ) : (
                <RowTooltipCellFormatter text={rowData.brand_name} length={7} />
              )
            }
          </Cell>
        </Column>

        {/* Created By */}
        <Column verticalAlign='middle' width={120}>
          <HeaderCell align='center'>
            <span
              className={`fw-bold fs-6 text-decoration-underline ${
                sortColumn === 'created_by' ? 'text-primary' : 'text-dark'
              }`}
            >
              {intl.formatMessage({id: 'LABEL_CREATED_BY'}).length < 18 ? (
                intl.formatMessage({id: 'LABEL_CREATED_BY'})
              ) : (
                <RowTooltipCellFormatter
                  text={intl.formatMessage({id: 'LABEL_CREATED_BY'})}
                  length={17}
                />
              )}
            </span>
          </HeaderCell>
          <Cell dataKey='created_by' align='center' style={{cursor: 'pointer'}}>
            {(rowData: any) =>
              rowData.shift.length === 0 ? (
                '-'
              ) : rowData.shift.length === 1 ? (
                <RowTooltipCellFormatter text={rowData.shift[0].user.username} length={9} />
              ) : (
                <RowTooltipCountReportShiftCreatedByFormatter
                  count={rowData.shift.length}
                  data={rowData.shift}
                  id={`${rowData.outlet_id}-created-by`}
                />
              )
            }
          </Cell>
        </Column>

        {/* Open Shift */}
        <Column verticalAlign='middle' width={250}>
          <HeaderCell align='center'>
            <span
              className={`fw-bold fs-6 text-decoration-underline ${
                sortColumn === 'start_time' ? 'text-primary' : 'text-dark'
              }`}
            >
              {intl.formatMessage({id: 'LABEL_OPEN_SHIFT'}).length < 20 ? (
                intl.formatMessage({id: 'LABEL_OPEN_SHIFT'})
              ) : (
                <RowTooltipCellFormatter
                  text={intl.formatMessage({id: 'LABEL_OPEN_SHIFT'})}
                  length={19}
                />
              )}
            </span>
          </HeaderCell>
          <Cell dataKey='start_time' align='center' style={{cursor: 'pointer'}}>
            {(rowData: any) =>
              rowData.shift.length === 0 ? (
                '-'
              ) : rowData.shift.length === 1 ? (
                <RowDatetimeCellFormatter datetime={rowData.shift[0].start_time} />
              ) : (
                <RowTooltipCountReportShiftOpenFormatter
                  count={rowData.shift.length}
                  data={rowData.shift}
                  id={`${rowData.outlet_id}-open`}
                />
              )
            }
          </Cell>
        </Column>

        {/* Close Shift */}
        <Column verticalAlign='middle' width={250}>
          <HeaderCell align='center'>
            <span
              className={`fw-bold fs-6 text-decoration-underline ${
                sortColumn === 'end_time' ? 'text-primary' : 'text-dark'
              }`}
            >
              {intl.formatMessage({id: 'LABEL_CLOSE_SHIFT'}).length < 20 ? (
                intl.formatMessage({id: 'LABEL_CLOSE_SHIFT'})
              ) : (
                <RowTooltipCellFormatter
                  text={intl.formatMessage({id: 'LABEL_CLOSE_SHIFT'})}
                  length={19}
                />
              )}
            </span>
          </HeaderCell>
          <Cell dataKey='end_time' align='center' style={{cursor: 'pointer'}}>
            {(rowData: any) =>
              rowData.shift.length === 0 ? (
                '-'
              ) : rowData.shift.length === 1 ? (
                <>
                  {rowData.shift[0].end_time === '0001-01-01T00:00:00Z' ? (
                    '-'
                  ) : (
                    <RowDatetimeCellFormatter datetime={rowData.shift[0].end_time} />
                  )}
                </>
              ) : (
                <RowTooltipCountReportShiftCloseFormatter
                  count={rowData.shift.length}
                  data={rowData.shift}
                  id={`${rowData.outlet_id}-close`}
                />
              )
            }
          </Cell>
        </Column>

        {/* Total Expected */}
        <Column verticalAlign='middle' width={175}>
          <HeaderCell align='center'>
            <span
              className={`fw-bold fs-6 text-decoration-underline ${
                sortColumn === 'end_time' ? 'text-primary' : 'text-dark'
              }`}
            >
              {intl.formatMessage({id: 'LABEL_TOTAL_EXPECTED'}).length < 15 ? (
                intl.formatMessage({id: 'LABEL_TOTAL_EXPECTED'})
              ) : (
                <RowTooltipCellFormatter
                  text={intl.formatMessage({id: 'LABEL_TOTAL_EXPECTED'})}
                  length={14}
                />
              )}
            </span>
          </HeaderCell>
          <Cell dataKey='end_time' align='center' style={{cursor: 'pointer'}}>
            {(rowData: any) =>
              rowData.shift.length === 0 ? (
                '-'
              ) : rowData.shift.length === 1 ? (
                <CurrencyFormat
                  value={rowData.shift[0].total_expected}
                  displayType={'text'}
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  prefix={'Rp. '}
                />
              ) : (
                <RowTooltipCountReportShiftExpectedFormatter
                  count={rowData.shift.length}
                  data={rowData.shift}
                  id={`${rowData.outlet_id}-total-expected`}
                />
              )
            }
          </Cell>
        </Column>

        {/* Total Actual */}
        <Column verticalAlign='middle' width={175}>
          <HeaderCell align='center'>
            <span
              className={`fw-bold fs-6 text-decoration-underline ${
                sortColumn === 'end_time' ? 'text-primary' : 'text-dark'
              }`}
            >
              {intl.formatMessage({id: 'LABEL_TOTAL_ACTUAL'}).length < 15 ? (
                intl.formatMessage({id: 'LABEL_TOTAL_ACTUAL'})
              ) : (
                <RowTooltipCellFormatter
                  text={intl.formatMessage({id: 'LABEL_TOTAL_ACTUAL'})}
                  length={14}
                />
              )}
            </span>
          </HeaderCell>
          <Cell dataKey='end_time' align='center' style={{cursor: 'pointer'}}>
            {(rowData: any) =>
              rowData.shift.length === 0 ? (
                '-'
              ) : rowData.shift.length === 1 ? (
                <CurrencyFormat
                  value={rowData.shift[0].total_actual}
                  displayType={'text'}
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  prefix={'Rp. '}
                />
              ) : (
                <RowTooltipCountReportShiftActualFormatter
                  count={rowData.shift.length}
                  data={rowData.shift}
                  id={`${rowData.outlet_id}-total-actual`}
                />
              )
            }
          </Cell>
        </Column>

        {/* Different */}
        <Column verticalAlign='middle' width={175}>
          <HeaderCell align='center'>
            <span
              className={`fw-bold fs-6 text-decoration-underline ${
                sortColumn === 'end_time' ? 'text-primary' : 'text-dark'
              }`}
            >
              {intl.formatMessage({id: 'LABEL_TOTAL_DIFFERENT'}).length < 15 ? (
                intl.formatMessage({id: 'LABEL_TOTAL_DIFFERENT'})
              ) : (
                <RowTooltipCellFormatter
                  text={intl.formatMessage({id: 'LABEL_TOTAL_DIFFERENT'})}
                  length={14}
                />
              )}
            </span>
          </HeaderCell>
          <Cell dataKey='end_time' align='center' style={{cursor: 'pointer'}}>
            {(rowData: any) =>
              rowData.shift.length === 0 ? (
                '-'
              ) : rowData.shift.length === 1 ? (
                <CurrencyFormat
                  value={rowData.shift[0].different}
                  displayType={'text'}
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  prefix={'Rp. '}
                />
              ) : (
                <RowTooltipCountReportShiftDifferentFormatter
                  count={rowData.shift.length}
                  data={rowData.shift}
                  id={`${rowData.outlet_id}-different`}
                />
              )
            }
          </Cell>
        </Column>
      </Table>
    </div>
  )
}

export {ListTable}
