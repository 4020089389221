const getInputClasses = (touched: any, errors: any) => {
  if (touched && errors) {
    return 'is-invalid'
  }

  if (touched && !errors) {
    return 'is-valid'
  }

  return ''
}

const days = ['Ahad', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu']
const daysEN = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
const months = [
  'Januari',
  'Februari',
  'Maret',
  'April',
  'Mei',
  'Juni',
  'Juli',
  'Agustus',
  'September',
  'Oktober',
  'November',
  'Desember',
]
const monthsEN = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const datetimeFormatter = (datetime: any, format: any, locale: any) => {
  const today = new Date(datetime)
  const day = locale === 'id' ? days[today.getDay()] : daysEN[today.getDay()]
  const month = locale === 'id' ? months[today.getMonth()] : monthsEN[today.getMonth()]

  switch (format) {
    case '1':
      return (
        day +
        ', ' +
        today.getDate() +
        ' ' +
        month +
        ' ' +
        today.getFullYear() +
        ' @' +
        (today.getHours() < 10 ? '0' + today.getHours() : today.getHours()) +
        ':' +
        (today.getMinutes() < 10 ? '0' + today.getMinutes() : today.getMinutes()) +
        ' WIB'
      )
    case '2':
      return day + ', ' + today.getDate() + ' ' + month + ' ' + today.getFullYear()
    case '3':
      return today.getDate() + ' ' + month + ' ' + today.getFullYear()
    case '4':
      return (
        ' @' +
        (today.getHours() < 10 ? '0' + today.getHours() : today.getHours()) +
        ':' +
        (today.getMinutes() < 10 ? '0' + today.getMinutes() : today.getMinutes()) +
        ' WIB'
      )
    default:
      return today.toString()
  }
}

const getFormAction = (
  action: any,
  view: any,
  edit: any,
  add: any,
  deleteAction: any,
  fillData?: any,
  importAction?: any,
  exportAction?: any,
  apv1?: string,
  apv2?: string,
  apv3?: string
) => {
  switch (action) {
    case 'edit':
      return edit
    case 'add':
      return add
    case 'delete':
      return deleteAction
    case 'fill-data':
      return fillData
    case 'import':
      return importAction
    case 'export':
      return exportAction
    case 'apv1':
      return apv1
    case 'apv2':
      return apv2
    case 'apv3':
      return apv3

    default:
      return view
  }
}

const getCurrentMenuID = (path: any, menus: any) => {
  for (var i = 0; i < menus.length; i++) {
    if ('/' + menus[i].path === path) {
      return menus[i].menu_id
    }
  }
}

const filterModuleStates = (module_id: any, modules: any) => {
  for (var i = 0; i < modules.length; i++) {
    if (modules[i].module_id === module_id) {
      return true
    }
  }
}

const replaceAt = (index: any, replacement: any, string: any) => {
  if (index >= string.length) {
    return string.valueOf()
  }

  return string.substring(0, index) + replacement + string.substring(index + 1)
}

const payloadToRead = (string: any) => {
  return JSON.stringify(string)
    .replace('map', '')
    .replaceAll('ip_address:', 'ip_address: ')
    .replaceAll('ip_address:', 'ip_address: ')
    .replaceAll('id:', 'id: ')
    .replaceAll('email:', 'email: ')
    .replaceAll('Email:', 'Email: ')
    .replaceAll('payload:', 'payload: ')
    .replaceAll('name:', 'name: ')
    .replaceAll('code:', 'code: ')
    .replaceAll('detail:', 'detail: ')
    .replaceAll('nik:', '\nnik: ')
    .replaceAll('outlet_id:', '\noutlet_id:')
    .replaceAll('currentPassword:', 'currentPassword: ')
    .replaceAll('training_date:', 'training_date: ')
    .replaceAll(' info_date:', '\ninfo_date:')
    .replaceAll(' info_detail:', '\ninfo_detail:')
    .replaceAll(' name_data_id:', '\nname_data_id:')
    .replaceAll(' type_data_id:', '\ntype_data_id:')
    .replaceAll(' area_id:', '\narea_id:')
    .replaceAll(' city_id:', '\ncity_id:')
    .replaceAll(' cluster_id:', '\ncluster_id:')
    .replaceAll(' company_id:', '\ncompany_id:')
    .replaceAll(' district_id:', '\ndistrict_id:')
    .replaceAll(' grade_id:', '\ngrade_id:')
    .replaceAll(' outlet_type_id:', '\noutlet_type_id:')
    .replaceAll(' user_group_id:', '\nuser_group_id:')
    .replaceAll(' phone:', '\nphone: ')
    .replaceAll(' mail:', '\nmail: ')
    .replaceAll(' province_id:', '\nprovince_id:')
    .replaceAll(' village_id:', '\nvillage_id:')
    .replaceAll(' code:', '\ncode:')
    .replaceAll(' icon:', '\nicon: ')
    .replaceAll(' path:', '\npath: ')
    .replaceAll(' Fullname:', '\nFullname: ')
    .replaceAll(' RoleID:', '\nRoleID: ')
    .replaceAll(' Status:', '\nStatus: ')
    .replaceAll(' UpdatedAt:', '\nUpdatedAt: ')
    .replaceAll(' UpdatedBy:', '\nUpdatedBy: ')
    .replaceAll(' file:', '\nfile: ')
    .replaceAll(' for_order:', '\nfor_order: ')
    .replaceAll(' parameter_id:', '\nparameter_id: ')
    .replaceAll(' parent_id:', '\nparent_id: ')
    .replaceAll(' state:', '\nstate: ')
    .replaceAll(' name:', '\nname: ')
    .replaceAll(' ip_address:', '\nip_address: ')
    .replaceAll(' menu_id:', '\nmenu_id: ')
    .replaceAll(' site_id:', '\nsite_id: ')
    .replaceAll(' status:', '\nstatus: ')
    .replaceAll(' task_date:', '\ntask_date: ')
    .replaceAll(' task_detail:', '\ntask_detail: ')
    .replaceAll(' task_id:', '\ntask_id: ')
    .replaceAll(' task_id_for_check:', '\ntask_id_for_check: ')
    .replaceAll(' user_id:', '\nuser_id: ')
    .replaceAll(' troubleshoot_id:', '\ntroubleshoot_id: ')
    .replaceAll('password:', '\npassword: ')
    .replaceAll(' token:', '\ntoken: ')
    .replaceAll(' level:', '\nlevel: ')
    .replaceAll(' has_child:', '\nhas_child: ')
    .replaceAll(' menu_menu_id:', '\nmenu_menu_id: ')
    .replaceAll(' status_id:', '\nstatus_id: ')
    .replaceAll(' task_item_id:', '\ntask_item_id: ')
    .replaceAll(' troubleshoot_detail:', '\ntroubleshoot_detail: ')
    .replaceAll(' troubleshoot_end_date:', '\ntroubleshoot_end_date: ')
    .replaceAll(' troubleshoot_outlet_branch_data_id:', '\ntroubleshoot_outlet_branch_data_id: ')
    .replaceAll(' troubleshoot_outlet_user_data_id:', '\ntroubleshoot_outlet_user_data_id: ')
    .replaceAll(' troubleshoot_solution_detail:', '\ntroubleshoot_solution_detail: ')
    .replaceAll(' troubleshoot_start_date:', '\ntroubleshoot_start_date: ')
    .replaceAll(' user_data_for_check:', '\nuser_data_for_check: ')
    .replaceAll(' user_division_data_for_check:', '\nuser_division_data_for_check: ')
    .replaceAll(' troubleshoot_user_data_id:', '\ntroubleshoot_user_data_id: ')
    .replaceAll(' troubleshoot_user_division_data_id:', '\ntroubleshoot_user_division_data_id: ')
    .replaceAll(' detail:', '\ndetail: ')
    .replaceAll(' module:', '\nmodule: ')
    .replaceAll(' module_id:', '\nmodule_id: ')
    .replaceAll(' participant_id:', '\nparticipant_id: ')
    .replaceAll(' training_date:', '\ntraining_date: ')
    .replaceAll(' trainer_id:', '\ntrainer_id: ')
    .replaceAll(' fullname:', '\nfullname: ')
    .replaceAll(' role:', '\nrole: ')
    .replaceAll(' username:', '\nusername: ')
    .replaceAll(' password:', '\npassword: ')
    .replaceAll(' re\npassword:', '\nrepassword: ')
    .replaceAll('"', '')
}

const truncate = (str: any, n: any) => {
  return str.length > n ? str.slice(0, n - 1) + '...' : str
}

export {
  getInputClasses,
  datetimeFormatter,
  getFormAction,
  getCurrentMenuID,
  filterModuleStates,
  replaceAt,
  payloadToRead,
  truncate,
}
