import {FC, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {Cell, Column, HeaderCell, Table} from 'rsuite-table'
import {Loader, Placeholder} from 'rsuite'
import * as CurrencyFormat from 'react-currency-format'

import {useThemeMode} from '_metronic/partials'
import {isMobileDevice} from '_metronic/assets/ts/_utils'
import {
  RowDatetimeCellFormatter,
  RowESBSalesStatusCellFormatter,
  RowTooltipCellFormatter,
  RowTooltipSalesOrderCountFormatter,
} from 'app/components'

import {useListQueryResponseLoading, useListQueryResponseData, useAction} from '../../providers'

const ListTable: FC = () => {
  const intl = useIntl()
  const isMobile = isMobileDevice()
  const {setItemIdForUpdate, setAction} = useAction()
  const isLoading = useListQueryResponseLoading()
  const users = useListQueryResponseData()
  const data = useMemo(() => users, [users])
  const [usePlaceholder] = useState(true)
  const {mode} = useThemeMode()

  const renderLoading = () => {
    if (usePlaceholder) {
      return (
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            background: mode === 'light' ? '#fff' : '#252530',
            padding: 20,
            zIndex: 1,
          }}
        >
          <Placeholder.Grid rows={5} columns={9} active />
        </div>
      )
    }

    return <Loader center backdrop content={intl.formatMessage({id: 'BUTTON_LABEL_PLEASE_WAIT'})} />
  }

  return (
    <div>
      <Table
        wordWrap={isMobile ? true : false}
        bordered
        cellBordered
        loading={isLoading}
        height={500}
        headerHeight={70}
        autoHeight={isMobile ? true : false}
        affixHeader={isMobile ? 70 : undefined}
        affixHorizontalScrollbar={isMobile ? true : false}
        data={data}
        renderLoading={isLoading ? renderLoading : undefined}
        locale={{emptyMessage: intl.formatMessage({id: 'LABEL_EMPTY_DATA'})}}
        onRowClick={(dt) => {
          setItemIdForUpdate(dt.order_id)
          setAction('view')
        }}
      >
        {/* Created At */}
        <Column fixed={isMobile ? false : true} verticalAlign='middle' width={280}>
          <HeaderCell align='center'>
            <span className={`fw-bold fs-6 text-decoration-underline text-dark`}>
              {intl.formatMessage({id: 'LABEL_CREATED_AT'})}
            </span>
          </HeaderCell>
          <Cell dataKey='created_at' style={{cursor: 'pointer'}}>
            {(rowData: any) => <RowDatetimeCellFormatter datetime={rowData.created_at} />}
          </Cell>
        </Column>

        {/* Status */}
        <Column verticalAlign='middle' width={200}>
          <HeaderCell align='center'>
            <span className={`fw-bold fs-6 text-decoration-underline text-dark`}>Status</span>
          </HeaderCell>
          <Cell dataKey='esb_status' align='center' style={{cursor: 'pointer'}}>
            {(rowData: any) => <RowESBSalesStatusCellFormatter row={rowData.esb_status} />}
          </Cell>
        </Column>

        {/* Error Message */}
        <Column verticalAlign='middle' width={300}>
          <HeaderCell align='center'>
            <span className={`fw-bold fs-6 text-decoration-underline text-dark`}>
              {intl.formatMessage({id: 'LABEL_ESB_SYNC_ERROR_MESSAGE'}).length < 25 ? (
                intl.formatMessage({id: 'LABEL_ESB_SYNC_ERROR_MESSAGE'})
              ) : (
                <RowTooltipCellFormatter
                  text={intl.formatMessage({id: 'LABEL_ESB_SYNC_ERROR_MESSAGE'})}
                  length={24}
                />
              )}
            </span>
          </HeaderCell>
          <Cell dataKey='error_message' align='center' style={{cursor: 'pointer'}}>
            {(rowData: any) => (rowData.esb_status === '00' ? '-' : rowData.esb_message)}
          </Cell>
        </Column>

        {/* Brand */}
        <Column verticalAlign='middle' width={85}>
          <HeaderCell align='center'>
            <span className={`fw-bold fs-6 text-decoration-underline text-dark`}>
              {intl.formatMessage({id: 'LABEL_BRAND'})}
            </span>
          </HeaderCell>
          <Cell dataKey='brand_name' align='center' style={{cursor: 'pointer'}}>
            {(rowData: any) =>
              rowData.brand_name.length < 8 ? (
                rowData.brand_name
              ) : (
                <RowTooltipCellFormatter text={rowData.brand_name} length={7} />
              )
            }
          </Cell>
        </Column>

        {/* Outlet */}
        <Column verticalAlign='middle' width={250}>
          <HeaderCell align='center'>
            <span className={`fw-bold fs-6 text-decoration-underline text-dark`}>
              {intl.formatMessage({id: 'LABEL_OUTLET'})}
            </span>
          </HeaderCell>
          <Cell dataKey='outlet_name' style={{cursor: 'pointer'}}>
            {(rowData: any) =>
              rowData.outlet_name.length < 37 ? (
                rowData.outlet_name
              ) : (
                <RowTooltipCellFormatter text={rowData.outlet_name} length={36} />
              )
            }
          </Cell>
        </Column>

        {/* Created By */}
        <Column verticalAlign='middle' width={130}>
          <HeaderCell align='center'>
            <span className={`fw-bold fs-6 text-decoration-underline text-dark`}>
              {intl.formatMessage({id: 'LABEL_CREATED_BY'}).length < 18 ? (
                intl.formatMessage({id: 'LABEL_CREATED_BY'})
              ) : (
                <RowTooltipCellFormatter
                  text={intl.formatMessage({id: 'LABEL_CREATED_BY'})}
                  length={17}
                />
              )}
            </span>
          </HeaderCell>
          <Cell dataKey='created_by' align='center' style={{cursor: 'pointer'}}>
            {(rowData: any) => <RowTooltipCellFormatter text={rowData.created_by} length={17} />}
          </Cell>
        </Column>

        {/* Receipt Number */}
        <Column verticalAlign='middle' width={140}>
          <HeaderCell align='center'>
            <span className={`fw-bold fs-6 text-decoration-underline text-dark`}>
              {intl.formatMessage({id: 'LABEL_RECEIPT_NUMBER'}).length < 15 ? (
                intl.formatMessage({id: 'LABEL_RECEIPT_NUMBER'})
              ) : (
                <RowTooltipCellFormatter
                  text={intl.formatMessage({id: 'LABEL_RECEIPT_NUMBER'})}
                  length={14}
                />
              )}
            </span>
          </HeaderCell>
          <Cell dataKey='receipt_number' align='center' style={{cursor: 'pointer'}}>
            {(rowData: any) =>
              rowData.receipt_number.length < 15 ? (
                rowData.receipt_number
              ) : (
                <RowTooltipCellFormatter text={rowData.receipt_number} length={14} />
              )
            }
          </Cell>
        </Column>

        {/* SalesType */}
        <Column verticalAlign='middle' width={150}>
          <HeaderCell align='center'>
            <span className={`fw-bold fs-6 text-decoration-underline text-dark`}>
              {intl.formatMessage({id: 'LABEL_SALES_TYPE'}).length < 16 ? (
                intl.formatMessage({id: 'LABEL_SALES_TYPE'})
              ) : (
                <RowTooltipCellFormatter
                  text={intl.formatMessage({id: 'LABEL_SALES_TYPE'})}
                  length={15}
                />
              )}
            </span>
          </HeaderCell>
          <Cell dataKey='sales_type_name' align='center' style={{cursor: 'pointer'}}>
            {(rowData: any) =>
              rowData.sales_type_name.length < 15 ? (
                rowData.sales_type_name
              ) : (
                <RowTooltipCellFormatter text={rowData.sales_type_name} length={14} />
              )
            }
          </Cell>
        </Column>

        {/* Order */}
        <Column verticalAlign='middle' width={75}>
          <HeaderCell align='center'>
            <span className={`fw-bold fs-6 text-decoration-underline text-dark`}>
              {intl.formatMessage({id: 'LABEL_SALES_ORDER'}).length < 9 ? (
                intl.formatMessage({id: 'LABEL_SALES_ORDER'})
              ) : (
                <RowTooltipCellFormatter
                  text={intl.formatMessage({id: 'LABEL_IS_VARIANT'})}
                  length={8}
                />
              )}
            </span>
          </HeaderCell>
          <Cell dataKey='c_order' align='center' style={{cursor: 'pointer'}}>
            {(rowData: any) => (
              <RowTooltipSalesOrderCountFormatter
                count={rowData.c_order}
                data={rowData.orders}
                id={rowData.order_id}
              />
            )}
          </Cell>
        </Column>

        {/* Amount */}
        <Column verticalAlign='middle' width={135}>
          <HeaderCell align='center'>
            <span className={`fw-bold fs-6 text-decoration-underline text-dark`}>Total</span>
          </HeaderCell>
          <Cell dataKey='total_price_amount' align='right' style={{cursor: 'pointer'}}>
            {(rowData: any) => (
              <CurrencyFormat
                value={rowData.total_price_amount}
                displayType={'text'}
                thousandSeparator={'.'}
                decimalSeparator={','}
                prefix={'Rp. '}
              />
            )}
          </Cell>
        </Column>
      </Table>
    </div>
  )
}

export {ListTable}
