const filterSitesFromPathAndModuleID = (path: any, moduleID: any, userModules: any) => {
  const module = userModules.filter((module: any) => module.module_id === moduleID)

  return module[0].sites.filter(
    (site: any) =>
      site.path === path.split('/')[1] + '/' + path.split('/')[2] + '/' + path.split('/')[3]
  )
}

const filterCurrentMenus = (
  moduleID: any,
  userModules: any,
  siteID?: any,
  locale?: string,
  setCurrentModuleLabel?: any,
  setCurrentModulePath?: any,
  setCurrentModuleID?: any,
  setCurrentSiteLabel?: any,
  setCurrentSitePath?: any,
  setCurrentSiteID?: any
) => {
  for (var i = 0; i < userModules.length; i++) {
    if (siteID !== '') {
      if (userModules[i].module_id === moduleID) {
        setCurrentModuleLabel(locale === 'id' ? userModules[i].name_idn : userModules[i].name_eng)
        setCurrentModulePath(userModules[i].path)
        setCurrentModuleID(userModules[i].module_id)
        setCurrentSiteLabel(
          userModules[i].sites
            .filter((s: any) => s.site_id === siteID)
            .map((site: any) => {
              return locale === 'id' ? site.name_idn : site.name_eng
            })
            .toString()
        )
        setCurrentSitePath(
          userModules[i].sites
            .filter((s: any) => s.site_id === siteID)
            .map((site: any) => {
              return site.path
            })
            .toString()
        )
        return userModules[i].sites
          .filter((s: any) => s.site_id === siteID)
          .map((sites: any) => {
            return sites
          })
      }
    } else {
      if (userModules[i].module_id === moduleID) {
        setCurrentModuleLabel(locale === 'id' ? userModules[i].name_idn : userModules[i].name_eng)
        setCurrentModulePath(userModules[i].path)
        setCurrentModuleID(userModules[i].module_id)

        setCurrentSiteLabel(
          locale === 'id' ? userModules[i].sites[0].name_idn : userModules[i].sites[0].name_eng
        )
        setCurrentSitePath(userModules[i].sites[0].path)
        setCurrentSiteID(userModules[i].sites[0].site_id)
        return userModules[i].sites[0].menus
      }
    }
  }
}

const filterParentMenu = (moduleID: any, userModules: any) => {
  for (var i = 0; i < userModules.length; i++) {
    if (userModules[i].module_id === moduleID) {
      return userModules[i].sites[0].menus.filter((s: any) => s.parent_id === '')
    }
  }
}

const filterLabelMenu = (path: any, menus: any) => {
  for (var i = 0; i < menus.length; i++) {
    if ('/' + menus[i].path === path) {
      return menus[i].name
    }
  }
}

export {filterSitesFromPathAndModuleID, filterParentMenu, filterCurrentMenus, filterLabelMenu}
