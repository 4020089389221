import {initialListQueryState, ListQueryState} from 'app/modules/settings-all-module/access/helpers'

const ACCESS_LIST_STATE_KEY = 'backoffice-orins-pos/settings/user-management/access'

const getAccessListState = () => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null | undefined = localStorage.getItem(ACCESS_LIST_STATE_KEY)
  if (!lsValue) {
    return setAccessListState(initialListQueryState)
  }

  try {
    const state = JSON.parse(lsValue)
    if (state) {
      return state
    }
  } catch (error) {
    console.log(ACCESS_LIST_STATE_KEY + ' STATE LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAccessListState = (state: ListQueryState) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(state)
    localStorage.setItem(ACCESS_LIST_STATE_KEY, lsValue)
  } catch (error) {
    console.error(ACCESS_LIST_STATE_KEY + ' STATE LOCAL STORAGE SAVE ERROR', error)
  }
}

export {getAccessListState, setAccessListState, ACCESS_LIST_STATE_KEY}
