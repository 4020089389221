/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'

import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItemWithSubMenu} from './SidebarMenuItemWithSubMenu'
import {SidebarSubModuleMenuItem} from './SidebarSubModuleMenuItem'
import {SidebarMenuItem} from './SidebarMenuItem'
import {useLang} from '_metronic/i18n/Metronici18n'
import {useUserGroupModules} from 'app/context/providers'
import {filterCurrentMenus} from 'app/components'

import * as contextHelper from 'app/context/state/UserGroupModulesStateContext'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const {pathname} = useLocation()
  const locale = useLang()
  const userGroupModules = contextHelper.getUserGroupModules()
  const [currentModule, setCurrentModule] = useState<null | string | undefined>(null)
  const [menus, setMenus] = useState<any>([])
  const {
    setCurrentModuleLabel,
    setCurrentModulePath,
    setCurrentModuleID,
    setCurrentSiteLabel,
    setCurrentSitePath,
    setCurrentSiteID,
    setCurrentParentMenuLabel,
    setCurrentParentMenuPath,
    setCurrentParentMenuID,
    setCurrentMenuLabel,
    setCurrentMenuPath,
    setCurrentMenuID,
  } = useUserGroupModules()

  useEffect(() => {
    const path = pathname.split('/')[1]

    switch (path) {
      case 'general':
        setCurrentModule('1')
        const _menu_general = filterCurrentMenus(
          '1',
          userGroupModules,
          '',
          locale,
          setCurrentModuleLabel,
          setCurrentModulePath,
          setCurrentModuleID,
          setCurrentSiteLabel,
          setCurrentSitePath,
          setCurrentSiteID
        )
        _menu_general && setMenus(_menu_general)
        break
      case 'backoffice-orins-pos':
        setCurrentModule('6')
        const _menu_orins_pos = filterCurrentMenus(
          '6',
          userGroupModules,
          pathname.split('/')[2] === 'settings'
            ? '6'
            : pathname.split('/')[2] === 'pos-settings'
              ? '9'
              : '10',
          locale,
          setCurrentModuleLabel,
          setCurrentModulePath,
          setCurrentModuleID,
          setCurrentSiteLabel,
          setCurrentSitePath,
          setCurrentSiteID
        )
        _menu_orins_pos && setMenus(_menu_orins_pos[0].menus)
        break
      default:
        setCurrentModule('')
        setCurrentModuleLabel('')
        setCurrentModulePath('')
        setCurrentModuleID('')
        setCurrentSiteLabel('')
        setCurrentSitePath('')
        setCurrentSiteID('')
        setCurrentParentMenuLabel('')
        setCurrentParentMenuPath('')
        setCurrentParentMenuID('')
        setCurrentMenuLabel('')
        setCurrentMenuPath('')
        setCurrentMenuID('')
        setMenus([])
        break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  return (
    <>
      {/* Homepage */}
      <SidebarMenuItem
        idx='homepage'
        to='/homepage'
        icon='home'
        title={intl.formatMessage({id: 'LABEL_HOMEPAGE'})}
        fontIcon='bi-app-indicator'
      />
      {/* Homepage */}

      {currentModule ? (
        <div className='menu-item pt-5 pb-2'>
          <div className='menu-content'>
            <span className='menu-section text-muted text-uppercase ls-1'>
              <strong>{intl.formatMessage({id: 'LABEL_SUB_MODULE'})}</strong>
            </span>
          </div>
        </div>
      ) : (
        <>
          {/* Account Settings */}
          <SidebarMenuItemWithSub
            idx='sdb-sub-account'
            to='/account'
            title={intl.formatMessage({id: 'LABEL_ACCOUNT_SETTINGS'})}
            icon='wrench'
          >
            <SidebarMenuItem
              idx='account-activity'
              to='/account/activity'
              title={intl.formatMessage({id: 'LABEL_ACTIVITY'})}
              hasBullet={true}
            />
            <SidebarMenuItem
              idx='account-change-password'
              to='/account/change-password'
              title={intl.formatMessage({id: 'LABEL_CHANGE_PASSWORD'})}
              hasBullet={true}
            />
          </SidebarMenuItemWithSub>
          {/* Account Settings */}
        </>
      )}
      {/* Sub Module Content */}
      {currentModule &&
        (userGroupModules as unknown as any[]).map((module: any, i: number) => (
          <div key={`module-${i}`}>
            {(module.sites as unknown as any[]).map(
              (site: any, i: number) =>
                // currentModule &&
                currentModule === module.module_id &&
                site.menus.length > 0 && (
                  <div key={`st-${i}`}>
                    <SidebarSubModuleMenuItem
                      idx={`site-${i}`}
                      to={`/${site.path}`}
                      icon={`${site.icon}`}
                      title={`${locale === 'id' ? site.name_idn : site.name_eng}`}
                      hasBullet={false}
                    />
                  </div>
                )
            )}
          </div>
        ))}
      {/* Sub Module Content */}

      {/* Menu Content */}
      {menus && menus.length > 0 && currentModule !== '' && (
        <div className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span className='menu-section text-muted text-uppercase ls-1 fw-bolder'>
              <strong>Menu</strong>
            </span>
          </div>
        </div>
      )}

      {menus &&
        menus.length > 0 &&
        (menus as unknown as any[]).map(
          (menuParent, i: number) =>
            menuParent.parent_id === '' && (
              <div key={`m-pr-${i}`}>
                <SidebarMenuItemWithSubMenu
                  idx={`m-par-${i}`}
                  to={`/${menuParent.path}`}
                  title={`${locale === 'id' ? menuParent.name_idn : menuParent.name_eng}`}
                  icon={`${menuParent.icon}`}
                  parentMenuID={`${menuParent.menu_id}`}
                >
                  {menus &&
                    (menus as unknown as any[]).map(
                      (menuChild, i: number) =>
                        menuChild.has_child !== 't' &&
                        menuChild.parent_id === menuParent.menu_id && (
                          <div key={`m-ch-${i}`}>
                            <SidebarMenuItem
                              idx={`m-chi-${i}`}
                              to={`/${menuChild.path}`}
                              title={`${locale === 'id' ? menuChild.name_idn : menuChild.name_eng}`}
                              hasBullet={true}
                              moduleID={menuChild.module_id}
                              siteID={menuChild.site_id}
                              menuID={menuChild.menu_id}
                              parentMenuID={menuChild.parent_id}
                            />
                          </div>
                        )
                    )}
                </SidebarMenuItemWithSubMenu>
              </div>
            )
        )}
      {/* Menu Content */}
    </>
  )
}

export {SidebarMenuMain}
