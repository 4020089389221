import {FC, lazy, useEffect, useRef, useState} from 'react'
import toast from 'react-hot-toast'
import {useIntl} from 'react-intl'
import {Navigate, Outlet, Route, Routes, useLocation} from 'react-router-dom'

import {PageLink, PageTitle} from '_metronic/layout/core'
import {SuspendViewComponents} from 'app/components'
import {CurrentMenuAccessModel} from 'app/context/models'
import {useUserGroupModules} from 'app/context/providers'
import {getCurrentMenuAccessByRoleIDAndMenuID} from 'app/context/requests'
import {removeAuth, useAuth} from 'app/modules/auth'

import * as reportSalesListState from 'app/modules/backoffice-orins-pos/orins-pos/report/sales/providers/ListQueryStateContext'
import {initialListQueryState as reportSalesListQueryState} from 'app/modules/backoffice-orins-pos/orins-pos/report/sales/helpers/ListQueryModel'
import * as reportTransactionListState from 'app/modules/backoffice-orins-pos/orins-pos/report/transactions/providers/ListQueryStateContext'
import {initialListQueryState as reportTransactionListQueryState} from 'app/modules/backoffice-orins-pos/orins-pos/report/transactions/helpers/ListQueryModel'
import * as reportShiftListState from 'app/modules/backoffice-orins-pos/orins-pos/report/shift/providers/ListQueryStateContext'
import {initialListQueryState as reportShiftListQueryState} from 'app/modules/backoffice-orins-pos/orins-pos/report/shift/helpers/ListQueryModel'
import * as reportVoidListState from 'app/modules/backoffice-orins-pos/orins-pos/report/void/providers/ListQueryStateContext'
import {initialListQueryState as reportVoidListQueryState} from 'app/modules/backoffice-orins-pos/orins-pos/report/void/helpers/ListQueryModel'
import * as esbSalesListState from 'app/modules/backoffice-orins-pos/orins-pos/esb/sales/providers/ListQueryStateContext'
import {initialListQueryState as esbSalesListQueryState} from 'app/modules/backoffice-orins-pos/orins-pos/esb/sales/helpers/ListQueryModel'

const SalesPageProvider = lazy(() => import('./sales/SalesPageProvider'))
const TransactionsPageProvider = lazy(() => import('./transactions/TransactionsPageProvider'))
const ShiftPageProvider = lazy(() => import('./shift/ShiftPageProvider'))
const VoidPageProvider = lazy(() => import('./void/VoidPageProvider'))

const BackOfficeOrinsPOSReportWrapper: FC = () => {
  const intl = useIntl()
  const {pathname} = useLocation()
  const {
    currentModuleLabel,
    currentModulePath,
    currentSiteLabel,
    currentSitePath,
    currentParentMenuLabel,
    currentParentMenuPath,
    currentMenuLabel,
    currentMenuID,
  } = useUserGroupModules()
  const didRequest = useRef(false)
  const {currentUser} = useAuth()
  const [access, setAccess] = useState<CurrentMenuAccessModel | undefined>(undefined)

  const breadCrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({id: 'LABEL_HOMEPAGE'}),
      path: 'homepage',
      isSeparator: false,
      isActive: false,
    },
    {
      title: currentModuleLabel,
      path: currentModulePath,
      isSeparator: false,
      isActive: false,
    },
    {
      title: currentSiteLabel,
      path: currentSitePath,
      isSeparator: false,
      isActive: false,
    },
    {
      title: currentParentMenuLabel,
      path: currentParentMenuPath,
      isSeparator: false,
      isActive: false,
    },
  ]

  useEffect(() => {
    if (reportSalesListState.getListState() === undefined) {
      reportSalesListState.setListState(reportSalesListQueryState)
    }
    if (reportTransactionListState.getListState() === undefined) {
      reportTransactionListState.setListState(reportTransactionListQueryState)
    }
    if (reportShiftListState.getListState() === undefined) {
      reportShiftListState.setListState(reportShiftListQueryState)
    }
    if (reportVoidListState.getListState() === undefined) {
      reportVoidListState.setListState(reportVoidListQueryState)
    }

    if (esbSalesListState.getListState() === undefined) {
      esbSalesListState.setListState(esbSalesListQueryState)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const requestCurrentMenuAccess = async () => {
      try {
        if (!didRequest.current) {
          const access = await getCurrentMenuAccessByRoleIDAndMenuID(
            currentUser?.role.role_id,
            currentMenuID
          )
          // throw message error
          if (access.data.meta.code === 200) {
            setAccess(access.data.data)
          }
          if (access.data.meta.code === 404 || access.data.meta.code === 500) {
            toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
          }
          if (access.data.meta.code === 401) {
            toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
            removeAuth()
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          if ((error as unknown as any).response.data.meta.code === 401) {
            toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
            removeAuth()
          } else {
            toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
          }
        }
      } finally {
      }

      return () => (didRequest.current = true)
    }

    if (
      currentMenuID !== undefined &&
      currentMenuID !== '' &&
      pathname.split('/')[3] === currentParentMenuPath.split('/')[3]
    ) {
      requestCurrentMenuAccess()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMenuID])

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='sales'
          element={
            <SuspendViewComponents>
              <PageTitle breadcrumbs={breadCrumbs}>{currentMenuLabel}</PageTitle>
              <SalesPageProvider access={access} />
            </SuspendViewComponents>
          }
        />
        <Route
          path='transactions'
          element={
            <SuspendViewComponents>
              <PageTitle breadcrumbs={breadCrumbs}>{currentMenuLabel}</PageTitle>
              <TransactionsPageProvider access={access} />
            </SuspendViewComponents>
          }
        />
        <Route
          path='shift'
          element={
            <SuspendViewComponents>
              <PageTitle breadcrumbs={breadCrumbs}>{currentMenuLabel}</PageTitle>
              <ShiftPageProvider access={access} />
            </SuspendViewComponents>
          }
        />
        <Route
          path='void'
          element={
            <SuspendViewComponents>
              <PageTitle breadcrumbs={breadCrumbs}>{currentMenuLabel}</PageTitle>
              <VoidPageProvider access={access} />
            </SuspendViewComponents>
          }
        />
        <Route index element={<Navigate to='/backoffice-orins-pos/orins-pos/report/sales' />} />
      </Route>
    </Routes>
  )
}

export {BackOfficeOrinsPOSReportWrapper}
