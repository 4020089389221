import {FC, useEffect, useState} from 'react'

import {KTCard} from '_metronic/helpers'
import {useUserGroupModules} from 'app/context/providers'
import {NotAuthRedirectForm} from 'app/components'
import {getAuth} from 'app/modules/auth'

import {ShiftDate} from '../../../helpers'

import {useAction, useListQueryResponseData} from './providers'
import {ListFilter, ListTableWrapper, ViewDrawerFormWrapper} from './components'

type Props = {
  shift_date: ShiftDate
}

const DataList: FC<Props> = ({shift_date}) => {
  const auth = getAuth()
  const [isAuth, setIsAuth] = useState(true)
  const data = useListQueryResponseData()
  const {action, itemIdForUpdate, setShiftDate} = useAction()
  const {currentMenuLabel, currentMenuID} = useUserGroupModules()

  useEffect(() => {
    auth === undefined && setIsAuth(false)
  }, [data, auth])

  useEffect(() => {
    shift_date && setShiftDate(shift_date)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shift_date])

  return (
    <KTCard>
      {!isAuth && <NotAuthRedirectForm />}

      <ListFilter />

      <ListTableWrapper />

      {itemIdForUpdate !== undefined && (
        <>
          {action === 'view' && (
            <ViewDrawerFormWrapper
              menuName={currentMenuLabel}
              modalID={'kt-modal-backoffice-orins-pos-orins-pos-report-transaction-esb'}
              appMenuID={currentMenuID}
            />
          )}
        </>
      )}
    </KTCard>
  )
}

export default DataList
