/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {Dialog} from '@headlessui/react'
import clsx from 'clsx'
import toast from 'react-hot-toast'
import {Checkbox, Drawer, InputPicker, CheckPicker, Toggle, Tooltip, Whisper} from 'rsuite'

import {KTIcon} from '_metronic/helpers'
import {
  NotAuthRedirectForm,
  FormLog,
  inputTrimSpace,
  getFormAction,
  SpinnerIndicator,
  datetimeFormatter,
} from 'app/components'
import {getAuth, removeAuth, useAuth} from 'app/modules/auth'

import {
  DetailModel,
  addData,
  deleteData,
  fetchBrandOptions,
  fetchCompanyOptions,
  fetchHoldingOptions,
  fetchOutletOptions,
  fetchRoleOptions,
  initialData,
  updateData,
} from '../../helpers'
import {useAction, useListQueryResponse} from '../../providers'

const footerStyles = {
  padding: '0px 2px',
  borderTop: '1px solid #e5e5e5',
}

type Props = {
  isLoading: boolean
  user: DetailModel
  appMenuID: any
  modalID: any
  menuName: any
}

const pass_min_length: any = process.env.REACT_APP_PASSWORD_MIN_LENGTH
const ViewDrawerForm: FC<Props> = ({menuName, user, isLoading, appMenuID, modalID}) => {
  const auth = getAuth()
  const intl = useIntl()
  const [tab, setTab] = useState('data')
  const {currentUser} = useAuth()
  const {setItemIdForUpdate, action} = useAction()
  const didRequestHolding = useRef(false)
  const didRequestCompany = useRef(false)
  const didRequestBrand = useRef(false)
  const didRequestOutlet = useRef(false)
  const didRequestRole = useRef(false)
  const {refetch} = useListQueryResponse()
  const [isAuth, setIsAuth] = useState(true)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [completed, setCompleted] = useState(false)
  const [holdingOptions, setHoldingOptions] = useState(undefined)
  const [companyOptions, setCompanyOptions] = useState(undefined)
  const [brandOptions, setBrandOptions] = useState(undefined)
  const [outletOptions, setOutletOptions] = useState(undefined)
  const [roleOptions, setRoleOptions] = useState(undefined)
  const optionsHolding =
    holdingOptions === undefined
      ? []
      : (holdingOptions as unknown as any[]).map((holdings: any) => ({
          value: holdings.holding_id,
          label: holdings.name,
        }))
  const optionsCompany =
    companyOptions === undefined
      ? []
      : (companyOptions as unknown as any[]).map((company: any) => ({
          value: company.company_id,
          label: company.name,
        }))
  const optionsBrand =
    brandOptions === undefined
      ? []
      : (brandOptions as unknown as any[]).map((brand: any) => ({
          value: brand.brand_id,
          label: brand.name,
        }))
  const optionsOutlet =
    outletOptions === undefined
      ? []
      : (outletOptions as unknown as any[]).map((outlet: any) => ({
          value: outlet.outlet_id,
          label: outlet.name,
        }))
  const optionsRole =
    roleOptions === undefined
      ? []
      : (roleOptions as unknown as any[]).map((role: any) => ({
          value: role.role_id,
          label: role.name,
        }))

  const withPasswordValidation = Yup.object().shape({
    username: Yup.string()
      .min(5, intl.formatMessage({id: 'VALIDATION_MIN_LENGTH_FIELD'}, {min: 5}))
      .max(50, intl.formatMessage({id: 'VALIDATION_MAX_LENGTH_FIELD'}, {max: 50}))
      .required(
        intl.formatMessage(
          {id: 'VALIDATION_REQUIRED_FIELD'},
          {name: intl.formatMessage({id: 'LABEL_USERNAME'})}
        )
      ),
    fullname: Yup.string()
      .min(5, intl.formatMessage({id: 'VALIDATION_MIN_LENGTH_FIELD'}, {min: 5}))
      .max(125, intl.formatMessage({id: 'VALIDATION_MAX_LENGTH_FIELD'}, {max: 125}))
      .required(
        intl.formatMessage(
          {id: 'VALIDATION_REQUIRED_FIELD'},
          {name: intl.formatMessage({id: 'LABEL_FULLNAME'})}
        )
      ),
    email: Yup.string()
      .email(
        intl.formatMessage(
          {id: 'VALIDATION_FIELD_NOT_VALID'},
          {name: intl.formatMessage({id: 'LABEL_EMAIL'})}
        )
      )
      .min(10, intl.formatMessage({id: 'VALIDATION_MIN_LENGTH_FIELD'}, {min: 10}))
      .max(125, intl.formatMessage({id: 'VALIDATION_MAX_LENGTH_FIELD'}, {max: 125}))
      .required(
        intl.formatMessage(
          {id: 'VALIDATION_REQUIRED_FIELD'},
          {name: intl.formatMessage({id: 'LABEL_EMAIL'})}
        )
      ),
    password: Yup.string()
      .min(
        pass_min_length,
        intl.formatMessage({id: 'VALIDATION_MIN_LENGTH_FIELD'}, {min: pass_min_length})
      )
      .max(50, intl.formatMessage({id: 'VALIDATION_MAX_LENGTH_FIELD'}, {max: 50}))
      .matches(/[a-z]/, intl.formatMessage({id: 'VALIDATION_PASSWORD_RULES_LOWERCASE'}))
      .matches(/[A-Z]/, intl.formatMessage({id: 'VALIDATION_PASSWORD_RULES_UPPERCASE'}))
      .matches(/[0-9]/, intl.formatMessage({id: 'VALIDATION_PASSWORD_RULES_NUMBER'}))
      .matches(
        /[^0-9a-zA-Z\s]/,
        intl.formatMessage({id: 'VALIDATION_PASSWORD_RULES_SPECIAL_CHARACTER'})
      )
      .required(
        intl.formatMessage(
          {id: 'VALIDATION_REQUIRED_FIELD'},
          {name: intl.formatMessage({id: 'LABEL_PASSWORD'})}
        )
      ),
  })

  const baseValidation = Yup.object().shape({
    username: Yup.string()
      .min(5, intl.formatMessage({id: 'VALIDATION_MIN_LENGTH_FIELD'}, {min: 5}))
      .max(50, intl.formatMessage({id: 'VALIDATION_MAX_LENGTH_FIELD'}, {max: 50}))
      .required(
        intl.formatMessage(
          {id: 'VALIDATION_REQUIRED_FIELD'},
          {name: intl.formatMessage({id: 'LABEL_USERNAME'})}
        )
      ),
    fullname: Yup.string()
      .min(5, intl.formatMessage({id: 'VALIDATION_MIN_LENGTH_FIELD'}, {min: 5}))
      .max(125, intl.formatMessage({id: 'VALIDATION_MAX_LENGTH_FIELD'}, {max: 125}))
      .required(
        intl.formatMessage(
          {id: 'VALIDATION_REQUIRED_FIELD'},
          {name: intl.formatMessage({id: 'LABEL_FULLNAME'})}
        )
      ),
    email: Yup.string()
      .email(
        intl.formatMessage(
          {id: 'VALIDATION_FIELD_NOT_VALID'},
          {name: intl.formatMessage({id: 'LABEL_EMAIL'})}
        )
      )
      .min(10, intl.formatMessage({id: 'VALIDATION_MIN_LENGTH_FIELD'}, {min: 10}))
      .max(125, intl.formatMessage({id: 'VALIDATION_MAX_LENGTH_FIELD'}, {max: 125}))
      .required(
        intl.formatMessage(
          {id: 'VALIDATION_REQUIRED_FIELD'},
          {name: intl.formatMessage({id: 'LABEL_EMAIL'})}
        )
      ),
  })

  const [models] = useState<DetailModel>({
    ...user,
    user_id: user.user_id || initialData.user_id,
    holding_id: action === 'add' ? [] : user.holding?.map((dt) => dt.holding_id),
    holding_name: action === 'add' ? [] : user.holding?.map((dt) => dt.name),
    company_id: action === 'add' ? [] : user.company?.map((dt) => dt.company_id),
    company_name: action === 'add' ? [] : user.company?.map((dt) => dt.name),
    brand_id: action === 'add' ? [] : user.brand?.map((dt) => dt.brand_id),
    brand_name: action === 'add' ? [] : user.brand?.map((dt) => dt.name),
    outlet_id: action === 'add' ? [] : user.outlet?.map((dt) => dt.outlet_id),
    outlet_name: action === 'add' ? [] : user.outlet?.map((dt) => dt.name),
    role_id: user.role_id || initialData.role_id,
    role_name: user.role?.name || initialData.role?.name,
    holding: user.holding,
    username: user.username || initialData.username,
    fullname: user.fullname || initialData.fullname,
    password: user.password || initialData.password,
    is_event_user: user.is_event_user || initialData.is_event_user,
    status: user.status || initialData.status,
    note: user.note || initialData.note,
    last_login: user.last_login || initialData.last_login,
    created_at: user.created_at || initialData.created_at,
    created_by: user.created_by || initialData.created_by,
    updated_at: user.updated_at || initialData.updated_at,
    updated_by: user.updated_by || initialData.updated_by,
    menu_id: appMenuID,
    creator_id: currentUser?.user_id,
    creator_by: currentUser?.username,
  })

  const [schema, updateSchema] = useState(baseValidation)
  const formik = useFormik({
    initialValues: models,
    validationSchema: schema,
    onSubmit: async (values, {setSubmitting, setStatus, setFieldError}) => {
      setIsDialogOpen(false)
      action === 'edit'
        ? requestUpdateData(values, setStatus, setSubmitting, setFieldError)
        : action === 'add'
          ? requestAddData(values, setStatus, setSubmitting, setFieldError)
          : requestDeleteData(values, setStatus, setSubmitting)
    },
    onReset: (values) => {
      values = models
    },
  })

  const requestUpdateData = async (
    values: any,
    setStatus: any,
    setSubmitting: any,
    setFieldError: any
  ) => {
    setLoading(true)
    setStatus(null)
    setSubmitting(true)

    // request update to API
    try {
      const res = await updateData(values)

      // throw message error
      if (res?.meta.code !== 200) {
        if (res?.meta.message === 'username already used') {
          setStatus(intl.formatMessage({id: 'RESPONSE_USER_USERNAME_ALREADY_USED'}))
          setFieldError('username', intl.formatMessage({id: 'RESPONSE_USER_USERNAME_ALREADY_USED'}))
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
        if (res?.meta.message === 'fullname already used') {
          setStatus(intl.formatMessage({id: 'RESPONSE_USER_FULLNAME_ALREADY_USED'}))
          setFieldError('fullname', intl.formatMessage({id: 'RESPONSE_USER_FULLNAME_ALREADY_USED'}))
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
        if (res?.meta.message === 'email already used') {
          setStatus(intl.formatMessage({id: 'RESPONSE_USER_EMAIL_ALREADY_USED'}))
          setFieldError('email', intl.formatMessage({id: 'RESPONSE_USER_EMAIL_ALREADY_USED'}))
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_USER_EDIT_SUCCESS'}))
        setCompleted(true)
        setTimeout(function () {
          setItemIdForUpdate(undefined)
        }, 1e3)
        refetch()
      }
    } catch (error: any) {
      console.error(error)
      if (error.response.status === 401) {
        setStatus(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
        removeAuth()
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
      }
      setSubmitting(false)
      setLoading(false)
      setTimeout(function () {
        setStatus(null)
      }, 9e3)

      return false
    } finally {
      setTimeout(() => {
        setLoading(false)
        setSubmitting(false)
      }, 5e2)
    }
  }
  const requestAddData = async (
    values: any,
    setStatus: any,
    setSubmitting: any,
    setFieldError: any
  ) => {
    setLoading(true)
    setStatus(null)
    setSubmitting(true)

    // request add to API
    try {
      const res = await addData(values)

      // throw message error
      if (res?.meta.code !== 200) {
        if (res?.meta.message === 'username already used') {
          setStatus(intl.formatMessage({id: 'RESPONSE_USER_USERNAME_ALREADY_USED'}))
          setFieldError('username', intl.formatMessage({id: 'RESPONSE_USER_USERNAME_ALREADY_USED'}))
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
        if (res?.meta.message === 'fullname already used') {
          setStatus(intl.formatMessage({id: 'RESPONSE_USER_FULLNAME_ALREADY_USED'}))
          setFieldError('fullname', intl.formatMessage({id: 'RESPONSE_USER_FULLNAME_ALREADY_USED'}))
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
        if (res?.meta.message === 'email already used') {
          setStatus(intl.formatMessage({id: 'RESPONSE_USER_EMAIL_ALREADY_USED'}))
          setFieldError('email', intl.formatMessage({id: 'RESPONSE_USER_EMAIL_ALREADY_USED'}))
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_USER_ADD_SUCCESS'}))
        setCompleted(true)
        setTimeout(function () {
          setItemIdForUpdate(undefined)
        }, 1e3)
        refetch()
      }
    } catch (error: any) {
      console.error(error)
      if (error.response.status === 401) {
        setStatus(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
        removeAuth()
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
      }
      setSubmitting(false)
      setLoading(false)
      setTimeout(function () {
        setStatus(null)
      }, 9e3)

      return false
    } finally {
      setTimeout(() => {
        setLoading(false)
        setSubmitting(false)
      }, 5e2)
    }
  }
  const requestDeleteData = async (values: any, setStatus: any, setSubmitting: any) => {
    setLoading(true)
    setStatus(null)
    setSubmitting(true)

    // request delete to API
    try {
      const res = await deleteData(
        values.user_id,
        values.menu_id,
        values.creator_id,
        values.creator_by
      )

      // throw message error
      if (res?.meta.code !== 200) {
        if (res?.meta.message === 'not inactive') {
          setStatus(intl.formatMessage({id: 'RESPONSE_USER_IS_ACTIVE'}))
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
        if (res?.meta.message === 'inused by other data') {
          setStatus(intl.formatMessage({id: 'RESPONSE_USER_IS_INUSED'}))
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_USER_DELETE_SUCCESS'}))
        setCompleted(true)
        setTimeout(function () {
          setItemIdForUpdate(undefined)
        }, 1e3)
        refetch()
      }
    } catch (error: any) {
      console.error(error)
      if (error.response.status === 401) {
        setStatus(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
        removeAuth()
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
      }
      setSubmitting(false)
      setLoading(false)
      setTimeout(function () {
        setStatus(null)
      }, 9e3)

      return false
    } finally {
      setTimeout(() => {
        setLoading(false)
        setSubmitting(false)
      }, 5e2)
    }
  }

  useEffect(() => {
    auth === undefined && setIsAuth(false)
  }, [auth])

  useEffect(() => {
    const requestHoldingOptions = async () => {
      try {
        if (!didRequestHolding.current) {
          const options = await fetchHoldingOptions('input')
          // throw message error
          if (options.data.meta.code === 200) {
            // set option
            setHoldingOptions(options.data.data)
          }
        }
      } catch (error: any) {
        console.error(error)
        if (error.response.status === 401) {
          toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
          removeAuth()
        } else {
          toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
        }
      } finally {
      }

      return () => (didRequestHolding.current = true)
    }
    const requestRoleOptions = async () => {
      try {
        if (!didRequestRole.current) {
          const options = await fetchRoleOptions('input')
          // throw message error
          if (options.data.meta.code === 200) {
            // set option
            setRoleOptions(options.data.data)
          }
        }
      } catch (error: any) {
        console.error(error)
        if (error.response.status === 401) {
          toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
          removeAuth()
        } else {
          toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
        }
      } finally {
      }

      return () => (didRequestRole.current = true)
    }

    requestHoldingOptions()
    requestRoleOptions()

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const requestCompanyOptions = async () => {
      try {
        if (!didRequestCompany.current) {
          const options = await fetchCompanyOptions('input', formik.values.holding_id)
          // throw message error
          if (options.data.meta.code === 200) {
            // set options
            setCompanyOptions(options.data.data)
          }
        }
      } catch (error: any) {
        console.error(error)
        if (error.response.status === 401) {
          toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
          removeAuth()
        } else {
          toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
        }
      } finally {
      }

      return () => (didRequestCompany.current = true)
    }

    if (action === 'add' || action === 'edit') {
      ;(formik.values.holding_id as unknown as any[])?.length > 0
        ? requestCompanyOptions()
        : setCompanyOptions(undefined)
    }

    // eslint-disable-next-line
  }, [formik.values.holding_id])

  useEffect(() => {
    const requestBrandOptions = async () => {
      try {
        if (!didRequestBrand.current) {
          const options = await fetchBrandOptions('input', formik.values.company_id)
          // throw message error
          if (options.data.meta.code === 200) {
            // set options
            setBrandOptions(options.data.data)
          }
        }
      } catch (error: any) {
        console.error(error)
        if (error.response.status === 401) {
          toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
          removeAuth()
        } else {
          toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
        }
      } finally {
      }

      return () => (didRequestBrand.current = true)
    }

    if (action === 'add' || action === 'edit') {
      formik.values.company_id !== undefined &&
      (formik.values.company_id as unknown as any[]).length > 0
        ? requestBrandOptions()
        : setBrandOptions(undefined)
    }

    // eslint-disable-next-line
  }, [formik.values.company_id])

  useEffect(() => {
    const requestOutletOptions = async () => {
      try {
        if (!didRequestOutlet.current) {
          const options = await fetchOutletOptions('input', formik.values.brand_id)
          // throw message error
          if (options.data.meta.code === 200) {
            // set options
            setOutletOptions(options.data.data)
          }
        }
      } catch (error: any) {
        console.error(error)
        if (error.response.status === 401) {
          toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
          removeAuth()
        } else {
          toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
        }
      } finally {
      }

      return () => (didRequestOutlet.current = true)
    }

    if (action === 'add' || action === 'edit') {
      formik.values.brand_id !== undefined &&
      (formik.values.brand_id as unknown as any[]).length > 0
        ? requestOutletOptions()
        : setOutletOptions(undefined)
    }

    // eslint-disable-next-line
  }, [formik.values.brand_id])

  const handleUsernameChange = (e: any) => {
    formik.setFieldValue('username', inputTrimSpace(e.target.value).toLowerCase())
  }

  const [passwordShown, setPasswordShown] = useState(false)
  const [labelPassword, setLabelPassword] = useState(false)
  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown)
    setLabelPassword(!labelPassword)
  }

  const tooltipPass = (
    <Tooltip>
      {!labelPassword
        ? intl.formatMessage({id: 'BUTTON_LABEL_SHOW_PASSWORD'})
        : intl.formatMessage({id: 'BUTTON_LABEL_HIDE_PASSWORD'})}
    </Tooltip>
  )

  useEffect(() => {
    if (action === 'add') {
      updateSchema(withPasswordValidation)
    }
    if (action === 'edit' && formik.values.password !== '') {
      updateSchema(withPasswordValidation)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, formik.values.password])

  return (
    <>
      {!isAuth && <NotAuthRedirectForm />}

      <Drawer.Header>
        <Drawer.Title className='fw-bolder'>
          {isLoading ? (
            <SpinnerIndicator label={intl.formatMessage({id: 'BUTTON_LABEL_PLEASE_WAIT'})} />
          ) : (
            <>
              {getFormAction(
                action,
                intl.formatMessage({id: 'BUTTON_LABEL_VIEW'}),
                intl.formatMessage({id: 'BUTTON_LABEL_EDIT'}),
                intl.formatMessage({id: 'BUTTON_LABEL_ADD'}),
                intl.formatMessage({id: 'BUTTON_LABEL_DELETE'})
              )}{' '}
              {menuName}
            </>
          )}
        </Drawer.Title>
        <Drawer.Actions>
          {action !== 'view' && models !== formik.values && !completed && (
            <button
              type='reset'
              className='btn btn-light me-3'
              data-kt-users-modal-action='reset'
              onClick={formik.handleReset}
              disabled={loading}
            >
              {!loading && (
                <span className='indicator-label'>
                  {intl.formatMessage({id: 'BUTTON_LABEL_RESET'})}
                </span>
              )}
            </button>
          )}

          {action !== 'view' && models !== formik.values && !completed && (
            <button
              type='submit'
              className='btn btn-primary'
              data-kt-users-modal-action='submit'
              onClick={() => {
                if (formik.values.holding_id?.toString().replaceAll(',', ', ') === '') {
                  formik.setFieldError('holding_id', 'validation errors')
                  return false
                }
                if (formik.values.company_id?.toString().replaceAll(',', ', ') === '') {
                  formik.setFieldError('company_id', 'validation errors')
                  return false
                }
                if (formik.values.brand_id?.toString().replaceAll(',', ', ') === '') {
                  formik.setFieldError('brand_id', 'validation errors')
                  return false
                }
                if (formik.values.outlet_id?.toString().replaceAll(',', ', ') === '') {
                  formik.setFieldError('outlet_id', 'validation errors')
                  return false
                }
                if (formik.values.role_id === '') {
                  formik.setFieldError('role_id', 'validation errors')
                  return false
                }
                setIsDialogOpen(true)
                setLoading(true)
                setTimeout(function () {
                  setLoading(false)
                }, 2e3)
              }}
              disabled={isLoading || loading || formik.isSubmitting || !formik.isValid}
            >
              {!loading && (
                <span className='indicator-label'>
                  {intl.formatMessage({id: 'BUTTON_LABEL_SUBMIT'})}
                </span>
              )}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {intl.formatMessage({id: 'BUTTON_LABEL_SUBMITTING'})}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          )}

          {action === 'delete' && !completed && (
            <button
              type='submit'
              className='btn btn-danger'
              data-kt-users-modal-action='submit'
              onClick={() => formik.handleSubmit()}
              disabled={isLoading || loading || formik.isSubmitting || !formik.isValid}
            >
              {!loading && (
                <span className='indicator-label'>
                  {intl.formatMessage({id: 'BUTTON_LABEL_DELETE'})}
                </span>
              )}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {intl.formatMessage({id: 'BUTTON_LABEL_SUBMITTING'})}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          )}
        </Drawer.Actions>
      </Drawer.Header>

      <Drawer.Body style={{padding: 0}}>
        {/* Response Message */}
        {formik.status &&
          (formik.status === intl.formatMessage({id: 'RESPONSE_USER_ADD_SUCCESS'}) ||
          formik.status === intl.formatMessage({id: 'RESPONSE_USER_EDIT_SUCCESS'}) ||
          formik.status === intl.formatMessage({id: 'RESPONSE_USER_DELETE_SUCCESS'}) ? (
            <div className='m-lg-5 p-2 bg-light-success rounded'>
              <div className='text-success text-center font-weight-bold fw-bolder'>
                {formik.status}
              </div>
            </div>
          ) : (
            <div className={`m-lg-5 p-2 alert alert-danger`}>
              <div className='alert-text text-center font-weight-bold fw-bolder'>
                {formik.status}
              </div>
            </div>
          ))}
        {/* Response Message */}

        {/* Form */}
        <div className={`modal-body m-5 ${action === 'add' ? '' : ' mt-0'}`}>
          {action === 'add' ? (
            <form id={`${modalID}-form`} className='form' onSubmit={formik.handleSubmit} noValidate>
              <div className='row'>
                {/* First Col */}
                <div className='col-lg-6'>
                  {/* Username */}
                  <div className='fv-row mb-5'>
                    {formik.values.username !== '' && (
                      <label className='form-label fs-6 fw-bolder text-dark required'>
                        {intl.formatMessage({id: 'LABEL_USERNAME'})}
                      </label>
                    )}
                    <input
                      type='text'
                      autoComplete='off'
                      className={clsx(
                        'form-control form-control-solid',
                        {'is-invalid': formik.touched.username && formik.errors.username},
                        {
                          'is-valid': formik.touched.username && !formik.errors.username,
                        }
                      )}
                      placeholder={intl.formatMessage({id: 'LABEL_USERNAME'})}
                      {...formik.getFieldProps('username')}
                      onKeyUp={handleUsernameChange}
                    />
                    {formik.touched.username && formik.errors.username ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger fw-bolder'>
                          {formik.errors.username}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {/* Username */}

                  {/* Fullname */}
                  <div className='fv-row mb-5'>
                    {formik.values.fullname !== '' && (
                      <label className='form-label fs-6 fw-bolder text-dark required'>
                        {intl.formatMessage({id: 'LABEL_FULLNAME'})}
                      </label>
                    )}
                    <input
                      type='text'
                      className={clsx(
                        'form-control form-control-solid',
                        {'is-invalid': formik.touched.fullname && formik.errors.fullname},
                        {
                          'is-valid': formik.touched.fullname && !formik.errors.fullname,
                        }
                      )}
                      placeholder={intl.formatMessage({id: 'LABEL_FULLNAME'})}
                      {...formik.getFieldProps('fullname')}
                    />
                    {formik.touched.fullname && formik.errors.fullname ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger fw-bolder'>
                          {formik.errors.fullname}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {/* Fullname */}

                  {/* Email */}
                  <div className='fv-row mb-5'>
                    {formik.values.email !== '' && (
                      <label className='form-label fs-6 fw-bolder text-dark required'>
                        {intl.formatMessage({id: 'LABEL_EMAIL'})}
                      </label>
                    )}
                    <input
                      type='text'
                      className={clsx(
                        'form-control form-control-solid',
                        {'is-invalid': formik.touched.email && formik.errors.email},
                        {
                          'is-valid': formik.touched.email && !formik.errors.email,
                        }
                      )}
                      placeholder={intl.formatMessage({id: 'LABEL_EMAIL'})}
                      {...formik.getFieldProps('email')}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger fw-bolder'>
                          {formik.errors.email}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {/* Email */}

                  {/* Password */}
                  <div className='fv-row mb-5'>
                    {formik.values.password !== '' && (
                      <label className='form-label fs-6 fw-bolder text-dark required'>
                        {intl.formatMessage({id: 'LABEL_PASSWORD'})}
                      </label>
                    )}
                    <div className='input-group'>
                      <input
                        type={!labelPassword ? 'password' : 'text'}
                        placeholder={intl.formatMessage({id: 'PLACEHOLDER_PASSWORD'})}
                        autoComplete='off'
                        {...formik.getFieldProps('password')}
                        className={clsx(
                          'form-control form-control-solid',
                          {'is-invalid': formik.touched.password && formik.errors.password},
                          {
                            'is-valid': formik.touched.password && !formik.errors.password,
                          }
                        )}
                      />
                      <Whisper
                        placement='top'
                        controlId='control-id-hover'
                        trigger='hover'
                        speaker={
                          <Tooltip>
                            {!labelPassword
                              ? intl.formatMessage({id: 'BUTTON_LABEL_SHOW_PASSWORD'})
                              : intl.formatMessage({id: 'BUTTON_LABEL_HIDE_PASSWORD'})}
                          </Tooltip>
                        }
                      >
                        <span className='input-group-append'>
                          <button
                            className='form-control form-control-solid'
                            type='button'
                            onClick={togglePasswordVisiblity}
                          >
                            {!labelPassword ? (
                              <a href='#'>
                                <span className='menu-icon' data-kt-element='icon'>
                                  <KTIcon iconName='eye' className='fs-2' />
                                </span>
                              </a>
                            ) : (
                              <a href='#'>
                                <span className='menu-icon' data-kt-element='icon'>
                                  <KTIcon iconName='eye-slash' className='fs-2' />
                                </span>
                              </a>
                            )}
                          </button>
                        </span>
                      </Whisper>
                    </div>
                    {formik.touched.password && formik.errors.password ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger fw-bolder'>
                          {formik.errors.password}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {/* Password */}
                </div>
                {/* First Col */}

                {/* Second Col */}
                <div className='col-lg-6'>
                  {/* Holding */}
                  <div className='fv-row mb-5'>
                    {formik.values.holding_id &&
                      (formik.values.holding_id as unknown as any[]).length > 0 && (
                        <label className='form-label fs-6 fw-bolder text-dark required'>
                          Holding
                        </label>
                      )}
                    <CheckPicker
                      block
                      placeholder={'-- Holding --'}
                      data={optionsHolding}
                      defaultValue={
                        user && user.holding
                          ? (user.holding as unknown as any[]).map((e) => e.holding_id)
                          : []
                      }
                      onChange={(val, tr) => {
                        let tmp = []
                        let result: any[] = []
                        let label: any = formik.values.holding_name

                        if (tr.type === 'keydown') {
                          // prevent user select/choice by using click enter
                          return
                        }

                        if (val.length > 0) {
                          if ((tr.target as unknown as any).checked === true) {
                            tmp = (optionsHolding as unknown as any[]).find(
                              (dt) => dt.label === (tr.target as unknown as any).labels[0].innerText
                            )
                            result = [...label, tmp.label]
                          } else {
                            if (tr.type === 'click') {
                              if ((tr.target as unknown as any).lastChild.nodeName === 'path') {
                                let tmp_result = label.filter(
                                  (e: any) =>
                                    e !==
                                    (tr.target as unknown as any).parentElement.parentElement
                                      .innerText
                                )
                                result = tmp_result
                              }
                            } else {
                              let tmp_result = label.filter(
                                (e: any) => e !== (tr.target as unknown as any).labels[0].innerText
                              )
                              result = tmp_result
                            }
                          }
                          formik.setFieldValue('holding_id', val)
                          formik.setFieldValue('holding_name', result)
                        } else {
                          formik.setFieldValue('holding_id', [])
                          formik.setFieldValue('holding_name', [])
                        }

                        formik.values.company_id = user.company_id
                        formik.values.brand_id = user.brand_id
                        formik.values.outlet_id = user.outlet_id

                        formik.setFieldValue('company_id', [])
                        formik.setFieldValue('company_name', [])
                        formik.setFieldValue('brand_id', [])
                        formik.setFieldValue('brand_name', [])
                        formik.setFieldValue('outlet_id', [])
                        formik.setFieldValue('outlet_name', [])
                      }}
                      onSelect={(val, ite) => {
                        let value: any = formik.values.holding_id
                        let label: any = formik.values.holding_name
                        let tmpValue: any = []
                        let tmpLabel: any = []
                        let resultValue: any[] = []
                        let resultLabel: any[] = []

                        if (val.length > 0) {
                          if (value.indexOf(ite.value) === -1) {
                            tmpValue = (optionsHolding as unknown as any[]).find(
                              (dt) => dt.value === ite.value
                            )

                            if (tmpValue === undefined) {
                              return
                            }

                            resultValue = [...value, tmpValue.value]
                            formik.setFieldValue('holding_id', resultValue)
                          } else {
                            tmpValue = value.filter((dt: any) => dt !== ite.value)
                            formik.setFieldValue('holding_id', tmpValue)
                          }

                          if (label.indexOf(ite.label) === -1) {
                            tmpLabel = (optionsHolding as unknown as any[]).find(
                              (dt) => dt.label === ite.label
                            )

                            if (tmpLabel === undefined) {
                              return
                            }

                            resultLabel = [...label, tmpLabel.label]
                            formik.setFieldValue('holding_name', resultLabel)
                          } else {
                            tmpLabel = label.filter((dt: any) => dt !== ite.label)
                            formik.setFieldValue('holding_name', tmpLabel)
                          }
                        } else {
                          formik.setFieldValue('holding_id', [])
                          formik.setFieldValue('holding_name', [])
                        }

                        formik.values.company_id = user.company_id
                        formik.values.brand_id = user.brand_id
                        formik.values.outlet_id = user.outlet_id

                        formik.setFieldValue('company_id', [])
                        formik.setFieldValue('company_name', [])
                        formik.setFieldValue('brand_id', [])
                        formik.setFieldValue('brand_name', [])
                        formik.setFieldValue('outlet_id', [])
                        formik.setFieldValue('outlet_name', [])
                      }}
                      value={formik.values.holding_id}
                      renderExtraFooter={() => (
                        <div style={footerStyles}>
                          <Checkbox
                            indeterminate={
                              (formik.values.holding_id as unknown as any[]).length > 0 &&
                              (formik.values.holding_id as unknown as any[]).length <
                                (optionsHolding as unknown as any[]).length
                            }
                            checked={
                              (formik.values.holding_id as unknown as any[]).length ===
                              (optionsHolding as unknown as any[]).length
                            }
                            onChange={(data, checked) => {
                              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                              if (checked) {
                                formik.setFieldValue(
                                  'holding_id',
                                  (optionsHolding as unknown as any[]).map((e) => e.value)
                                )
                                formik.setFieldValue(
                                  'holding_name',
                                  (optionsHolding as unknown as any[]).map((e) => e.label)
                                )
                              } else {
                                formik.setFieldValue('holding_id', [])
                                formik.setFieldValue('holding_name', [])
                              }
                            }}
                          >
                            {(formik.values.holding_id as unknown as any[]).length ===
                            (optionsHolding as unknown as any[]).length
                              ? intl.formatMessage({id: 'LABEL_UNSELECT_ALL'})
                              : intl.formatMessage({id: 'LABEL_SELECT_ALL'})}
                          </Checkbox>
                        </div>
                      )}
                    />
                    {formik.errors.holding_id ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger fw-bolder'>
                          {intl.formatMessage(
                            {id: 'VALIDATION_REQUIRED_FIELD'},
                            {
                              name: 'Holding',
                            }
                          )}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {/* Holding */}

                  {/* Company */}
                  <div className='fv-row mb-5'>
                    {formik.values.company_id &&
                      (formik.values.company_id as unknown as any[]).length > 0 && (
                        <label className='form-label fs-6 fw-bolder text-dark required'>
                          {intl.formatMessage({id: 'LABEL_COMPANY'})}
                        </label>
                      )}
                    <CheckPicker
                      block
                      placeholder={`-- ${intl.formatMessage({id: 'LABEL_COMPANY'})} --`}
                      data={optionsCompany}
                      disabled={
                        (formik.values.holding_id as unknown as any[]).length > 0 ? false : true
                      }
                      defaultValue={
                        user && user.company
                          ? (user.company as unknown as any[]).map((e) => e.company_id)
                          : []
                      }
                      onChange={(val, tr) => {
                        let tmp = []
                        let result: any[] = []
                        let label: any = formik.values.company_name

                        if (tr.type === 'keydown') {
                          // prevent user select/choice by using click enter
                          return
                        }

                        if (val.length > 0) {
                          if ((tr.target as unknown as any).checked === true) {
                            tmp = (optionsCompany as unknown as any[]).find(
                              (dt) => dt.label === (tr.target as unknown as any).labels[0].innerText
                            )
                            result = [...label, tmp.label]
                          } else {
                            if (tr.type === 'click') {
                              if ((tr.target as unknown as any).lastChild.nodeName === 'path') {
                                let tmp_result = label.filter(
                                  (e: any) =>
                                    e !==
                                    (tr.target as unknown as any).parentElement.parentElement
                                      .innerText
                                )
                                result = tmp_result
                              }
                            } else {
                              let tmp_result = label.filter(
                                (e: any) => e !== (tr.target as unknown as any).labels[0].innerText
                              )
                              result = tmp_result
                            }
                          }
                          formik.setFieldValue('company_id', val)
                          formik.setFieldValue('company_name', result)
                        } else {
                          formik.setFieldValue('company_id', [])
                          formik.setFieldValue('company_name', [])
                        }

                        formik.values.brand_id = user.brand_id
                        formik.values.outlet_id = user.outlet_id

                        formik.setFieldValue('brand_id', [])
                        formik.setFieldValue('brand_name', [])
                        formik.setFieldValue('outlet_id', [])
                        formik.setFieldValue('outlet_name', [])
                      }}
                      onSelect={(val, ite) => {
                        let value: any = formik.values.company_id
                        let label: any = formik.values.company_name
                        let tmpValue: any = []
                        let tmpLabel: any = []
                        let resultValue: any[] = []
                        let resultLabel: any[] = []

                        if (val.length > 0) {
                          if (value.indexOf(ite.value) === -1) {
                            tmpValue = (optionsCompany as unknown as any[]).find(
                              (dt) => dt.value === ite.value
                            )

                            if (tmpValue === undefined) {
                              return
                            }

                            resultValue = [...value, tmpValue.value]
                            formik.setFieldValue('company_id', resultValue)
                          } else {
                            tmpValue = value.filter((dt: any) => dt !== ite.value)
                            formik.setFieldValue('company_id', tmpValue)
                          }

                          if (label.indexOf(ite.label) === -1) {
                            tmpLabel = (optionsCompany as unknown as any[]).find(
                              (dt) => dt.label === ite.label
                            )

                            if (tmpLabel === undefined) {
                              return
                            }

                            resultLabel = [...label, tmpLabel.label]
                            formik.setFieldValue('company_name', resultLabel)
                          } else {
                            tmpLabel = label.filter((dt: any) => dt !== ite.label)
                            formik.setFieldValue('company_name', tmpLabel)
                          }
                        } else {
                          formik.setFieldValue('company_id', [])
                          formik.setFieldValue('company_name', [])
                        }

                        formik.values.brand_id = user.brand_id
                        formik.values.outlet_id = user.outlet_id

                        formik.setFieldValue('brand_id', [])
                        formik.setFieldValue('brand_name', [])
                        formik.setFieldValue('outlet_id', [])
                        formik.setFieldValue('outlet_name', [])
                      }}
                      value={formik.values.company_id}
                      renderExtraFooter={() => (
                        <div style={footerStyles}>
                          <Checkbox
                            indeterminate={
                              (formik.values.company_id as unknown as any[]).length > 0 &&
                              (formik.values.company_id as unknown as any[]).length <
                                (optionsCompany as unknown as any[]).length
                            }
                            checked={
                              (formik.values.company_id as unknown as any[]).length ===
                              (optionsCompany as unknown as any[]).length
                            }
                            onChange={(data, checked) => {
                              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                              if (checked) {
                                formik.setFieldValue(
                                  'company_id',
                                  (optionsCompany as unknown as any[]).map((e) => e.value)
                                )
                                formik.setFieldValue(
                                  'company_name',
                                  (optionsCompany as unknown as any[]).map((e) => e.label)
                                )
                              } else {
                                formik.setFieldValue('company_id', [])
                                formik.setFieldValue('company_name', [])
                              }
                            }}
                          >
                            {(formik.values.company_id as unknown as any[]).length ===
                            (optionsCompany as unknown as any[]).length
                              ? intl.formatMessage({id: 'LABEL_UNSELECT_ALL'})
                              : intl.formatMessage({id: 'LABEL_SELECT_ALL'})}
                          </Checkbox>
                        </div>
                      )}
                    />
                    {formik.errors.company_id ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger fw-bolder'>
                          {intl.formatMessage(
                            {id: 'VALIDATION_REQUIRED_FIELD'},
                            {
                              name: intl.formatMessage({id: 'LABEL_COMPANY'}),
                            }
                          )}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {/* Company */}

                  {/* Brand */}
                  <div className='fv-row mb-5'>
                    {formik.values.brand_id &&
                      (formik.values.brand_id as unknown as any[]).length > 0 && (
                        <label className='form-label fs-6 fw-bolder text-dark required'>
                          {intl.formatMessage({id: 'LABEL_BRAND'})}
                        </label>
                      )}
                    <CheckPicker
                      block
                      placeholder={`-- ${intl.formatMessage({id: 'LABEL_BRAND'})} --`}
                      data={optionsBrand}
                      disabled={
                        (formik.values.company_id as unknown as any[]).length > 0 ? false : true
                      }
                      defaultValue={
                        user && user.brand
                          ? (user.brand as unknown as any[]).map((e) => e.brand_id)
                          : []
                      }
                      onChange={(val, tr) => {
                        let tmp = []
                        let result: any[] = []
                        let label: any = formik.values.brand_name

                        if (tr.type === 'keydown') {
                          // prevent user select/choice by using click enter
                          return
                        }

                        if (val.length > 0) {
                          if ((tr.target as unknown as any).checked === true) {
                            tmp = (optionsBrand as unknown as any[]).find(
                              (dt) => dt.label === (tr.target as unknown as any).labels[0].innerText
                            )
                            result = [...label, tmp.label]
                          } else {
                            if (tr.type === 'click') {
                              if ((tr.target as unknown as any).lastChild.nodeName === 'path') {
                                let tmp_result = label.filter(
                                  (e: any) =>
                                    e !==
                                    (tr.target as unknown as any).parentElement.parentElement
                                      .innerText
                                )
                                result = tmp_result
                              }
                            } else {
                              let tmp_result = label.filter(
                                (e: any) => e !== (tr.target as unknown as any).labels[0].innerText
                              )
                              result = tmp_result
                            }
                          }
                          formik.setFieldValue('brand_id', val)
                          formik.setFieldValue('brand_name', result)
                        } else {
                          formik.setFieldValue('brand_id', [])
                          formik.setFieldValue('brand_name', [])
                        }

                        formik.values.brand_id = user.brand_id
                        formik.values.outlet_id = user.outlet_id

                        formik.setFieldValue('outlet_id', [])
                        formik.setFieldValue('outlet_name', [])
                      }}
                      onSelect={(val, ite) => {
                        let value: any = formik.values.brand_id
                        let label: any = formik.values.brand_name
                        let tmpValue: any = []
                        let tmpLabel: any = []
                        let resultValue: any[] = []
                        let resultLabel: any[] = []

                        if (val.length > 0) {
                          if (value.indexOf(ite.value) === -1) {
                            tmpValue = (optionsBrand as unknown as any[]).find(
                              (dt) => dt.value === ite.value
                            )

                            if (tmpValue === undefined) {
                              return
                            }

                            resultValue = [...value, tmpValue.value]
                            formik.setFieldValue('brand_id', resultValue)
                          } else {
                            tmpValue = value.filter((dt: any) => dt !== ite.value)
                            formik.setFieldValue('brand_id', tmpValue)
                          }

                          if (label.indexOf(ite.label) === -1) {
                            tmpLabel = (optionsBrand as unknown as any[]).find(
                              (dt) => dt.label === ite.label
                            )

                            if (tmpLabel === undefined) {
                              return
                            }

                            resultLabel = [...label, tmpLabel.label]
                            formik.setFieldValue('brand_name', resultLabel)
                          } else {
                            tmpLabel = label.filter((dt: any) => dt !== ite.label)
                            formik.setFieldValue('brand_name', tmpLabel)
                          }
                        } else {
                          formik.setFieldValue('brand_id', [])
                          formik.setFieldValue('brand_name', [])
                        }

                        formik.values.brand_id = user.brand_id
                        formik.values.outlet_id = user.outlet_id

                        formik.setFieldValue('outlet_id', [])
                        formik.setFieldValue('outlet_name', [])
                      }}
                      value={formik.values.brand_id}
                      renderExtraFooter={() => (
                        <div style={footerStyles}>
                          <Checkbox
                            indeterminate={
                              (formik.values.brand_id as unknown as any[]).length > 0 &&
                              (formik.values.brand_id as unknown as any[]).length <
                                (optionsBrand as unknown as any[]).length
                            }
                            checked={
                              (formik.values.brand_id as unknown as any[]).length ===
                              (optionsBrand as unknown as any[]).length
                            }
                            onChange={(data, checked) => {
                              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                              if (checked) {
                                formik.setFieldValue(
                                  'brand_id',
                                  (optionsBrand as unknown as any[]).map((e) => e.value)
                                )
                                formik.setFieldValue(
                                  'brand_name',
                                  (optionsBrand as unknown as any[]).map((e) => e.label)
                                )
                              } else {
                                formik.setFieldValue('brand_id', [])
                                formik.setFieldValue('brand_name', [])
                              }
                            }}
                          >
                            {(formik.values.brand_id as unknown as any[]).length ===
                            (optionsBrand as unknown as any[]).length
                              ? intl.formatMessage({id: 'LABEL_UNSELECT_ALL'})
                              : intl.formatMessage({id: 'LABEL_SELECT_ALL'})}
                          </Checkbox>
                        </div>
                      )}
                    />
                    {formik.errors.brand_id ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger fw-bolder'>
                          {intl.formatMessage(
                            {id: 'VALIDATION_REQUIRED_FIELD'},
                            {
                              name: intl.formatMessage({id: 'LABEL_BRAND'}),
                            }
                          )}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {/* Brand */}

                  {/* Outlet */}
                  <div className='fv-row mb-5'>
                    {formik.values.outlet_id &&
                      (formik.values.outlet_id as unknown as any[]).length > 0 && (
                        <label className='form-label fs-6 fw-bolder text-dark required'>
                          Outlet
                        </label>
                      )}
                    <CheckPicker
                      block
                      placeholder={`-- Outlet --`}
                      data={optionsOutlet}
                      disabled={
                        (formik.values.brand_id as unknown as any[]).length > 0 ? false : true
                      }
                      defaultValue={
                        user && user.outlet
                          ? (user.outlet as unknown as any[]).map((e) => e.outlet_id)
                          : []
                      }
                      onChange={(val, tr) => {
                        let tmp = []
                        let result: any[] = []
                        let label: any = formik.values.outlet_name

                        if (tr.type === 'keydown') {
                          // prevent user select/choice by using click enter
                          return
                        }

                        if (val.length > 0) {
                          if ((tr.target as unknown as any).checked === true) {
                            tmp = (optionsOutlet as unknown as any[]).find(
                              (dt) => dt.label === (tr.target as unknown as any).labels[0].innerText
                            )
                            result = [...label, tmp.label]
                          } else {
                            if (tr.type === 'click') {
                              if ((tr.target as unknown as any).lastChild.nodeName === 'path') {
                                let tmp_result = label.filter(
                                  (e: any) =>
                                    e !==
                                    (tr.target as unknown as any).parentElement.parentElement
                                      .innerText
                                )
                                result = tmp_result
                              }
                            } else {
                              let tmp_result = label.filter(
                                (e: any) => e !== (tr.target as unknown as any).labels[0].innerText
                              )
                              result = tmp_result
                            }
                          }
                          formik.setFieldValue('outlet_id', val)
                          formik.setFieldValue('outlet_name', result)
                        } else {
                          formik.setFieldValue('outlet_id', [])
                          formik.setFieldValue('outlet_name', [])
                        }
                      }}
                      onSelect={(val, ite) => {
                        let value: any = formik.values.outlet_id
                        let label: any = formik.values.outlet_name
                        let tmpValue: any = []
                        let tmpLabel: any = []
                        let resultValue: any[] = []
                        let resultLabel: any[] = []

                        if (val.length > 0) {
                          if (value.indexOf(ite.value) === -1) {
                            tmpValue = (optionsOutlet as unknown as any[]).find(
                              (dt) => dt.value === ite.value
                            )

                            if (tmpValue === undefined) {
                              return
                            }

                            resultValue = [...value, tmpValue.value]
                            formik.setFieldValue('outlet_id', resultValue)
                          } else {
                            tmpValue = value.filter((dt: any) => dt !== ite.value)
                            formik.setFieldValue('outlet_id', tmpValue)
                          }

                          if (label.indexOf(ite.label) === -1) {
                            tmpLabel = (optionsOutlet as unknown as any[]).find(
                              (dt) => dt.label === ite.label
                            )

                            if (tmpLabel === undefined) {
                              return
                            }

                            resultLabel = [...label, tmpLabel.label]
                            formik.setFieldValue('outlet_name', resultLabel)
                          } else {
                            tmpLabel = label.filter((dt: any) => dt !== ite.label)
                            formik.setFieldValue('outlet_name', tmpLabel)
                          }
                        } else {
                          formik.setFieldValue('outlet_id', [])
                          formik.setFieldValue('outlet_name', [])
                        }
                      }}
                      value={formik.values.outlet_id}
                      renderExtraFooter={() => (
                        <div style={footerStyles}>
                          <Checkbox
                            indeterminate={
                              (formik.values.outlet_id as unknown as any[]).length > 0 &&
                              (formik.values.outlet_id as unknown as any[]).length <
                                (optionsOutlet as unknown as any[]).length
                            }
                            checked={
                              (formik.values.outlet_id as unknown as any[]).length ===
                              (optionsOutlet as unknown as any[]).length
                            }
                            onChange={(data, checked) => {
                              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                              if (checked) {
                                formik.setFieldValue(
                                  'outlet_id',
                                  (optionsOutlet as unknown as any[]).map((e) => e.value)
                                )
                                formik.setFieldValue(
                                  'outlet_name',
                                  (optionsOutlet as unknown as any[]).map((e) => e.label)
                                )
                              } else {
                                formik.setFieldValue('outlet_id', [])
                                formik.setFieldValue('outlet_name', [])
                              }
                            }}
                          >
                            {(formik.values.outlet_id as unknown as any[]).length ===
                            (optionsOutlet as unknown as any[]).length
                              ? intl.formatMessage({id: 'LABEL_UNSELECT_ALL'})
                              : intl.formatMessage({id: 'LABEL_SELECT_ALL'})}
                          </Checkbox>
                        </div>
                      )}
                    />
                    {formik.errors.outlet_id ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger fw-bolder'>
                          {intl.formatMessage(
                            {id: 'VALIDATION_REQUIRED_FIELD'},
                            {
                              name: 'Outlet',
                            }
                          )}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {/* Outlet */}
                </div>
                {/* Second Col */}
              </div>

              <div className='row mt-10'>
                {/* Third Col */}
                <div className='col-lg-6'>
                  {/* User Group */}
                  <div className='fv-row mb-5'>
                    {formik.values.role_id !== '' && (
                      <label className='form-label fs-6 fw-bolder text-dark required'>
                        {intl.formatMessage({id: 'LABEL_USER_GROUP'})}
                      </label>
                    )}
                    <InputPicker
                      block
                      data={optionsRole}
                      placeholder={`-- ${intl.formatMessage({id: 'LABEL_USER_GROUP'})} --`}
                      defaultValue={
                        optionsRole
                          ? (optionsRole as unknown as any[]).find(
                              (e: any) => e.value === formik.values.role_id
                            )
                          : ''
                      }
                      onChange={(e) => {
                        formik.setFieldValue('role_id', e === null ? '' : e)
                      }}
                      onSelect={(val, itm) => {
                        formik.setFieldValue('role_name', val === null ? '' : itm.label)
                      }}
                      value={formik.values.role_id}
                    />
                    {formik.errors.role_id ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger fw-bolder'>
                          {intl.formatMessage(
                            {id: 'VALIDATION_REQUIRED_FIELD'},
                            {
                              name: intl.formatMessage({
                                id: 'LABEL_USER_GROUP',
                              }),
                            }
                          )}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {/* User Group */}

                  {/* Note */}
                  <div className='fv-row mb-5'>
                    {formik.values.note !== '' && (
                      <label className='form-label fs-6 fw-bolder text-dark'>
                        {intl.formatMessage({id: 'LABEL_NOTE'})}
                      </label>
                    )}
                    <textarea
                      className={clsx(
                        'form-control form-control-solid',
                        {'is-invalid': formik.touched.note && formik.errors.note},
                        {
                          'is-valid': formik.touched.note && !formik.errors.note,
                        }
                      )}
                      placeholder={intl.formatMessage({id: 'LABEL_NOTE'})}
                      {...formik.getFieldProps('note')}
                      rows={3}
                    />
                  </div>
                  {/* Note */}
                </div>
                {/* Third Col */}

                {/* Fourth Col */}
                <div className='col-lg-6'>
                  {/* Status */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark required'>Status</label>
                    <div className='form-check form-switch form-check-custom form-check-solid mt-1'>
                      <Toggle
                        size='lg'
                        checkedChildren={intl.formatMessage({id: 'LABEL_ACTIVE'})}
                        unCheckedChildren={intl.formatMessage({id: 'LABEL_INACTIVE'})}
                        checked={formik.values.status === 't' ? true : false}
                        onChange={() =>
                          formik.setFieldValue('status', formik.values.status === 't' ? 'f' : 't')
                        }
                      />
                    </div>
                  </div>
                  {/* Status */}

                  {/* Is Event User */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark required'>
                      {intl.formatMessage({id: 'LABEL_EVENT_ACCOUNT'})}
                    </label>
                    <div className='form-check form-switch form-check-custom form-check-solid mt-1'>
                      <Toggle
                        size='lg'
                        checkedChildren={intl.formatMessage({id: 'LABEL_YES'})}
                        unCheckedChildren={intl.formatMessage({id: 'LABEL_NO'})}
                        checked={formik.values.is_event_user === 't' ? true : false}
                        onChange={() =>
                          formik.setFieldValue(
                            'is_event_user',
                            formik.values.is_event_user === 't' ? 'f' : 't'
                          )
                        }
                      />
                    </div>
                  </div>
                  {/* Is Event User */}
                </div>
                {/* Fourth Col */}
              </div>
            </form>
          ) : (
            <form id={`${modalID}-form`} className='form' onSubmit={formik.handleSubmit} noValidate>
              {/* Tab Header */}
              <div className='card-toolbar'>
                <ul
                  className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-2 mt-2'
                  role='tablist'
                >
                  <li className={`nav-item ${tab === 'data' ? 'fw-bolder' : ''}`}>
                    <a
                      className={clsx(`nav-link cursor-pointer`, {
                        active: tab === 'data',
                      })}
                      onClick={() => setTab('data')}
                      role='tab'
                    >
                      Data
                    </a>
                  </li>
                  <li className={`nav-item ${tab === 'log' ? 'fw-bolder' : ''}`}>
                    <a
                      className={clsx(`nav-link cursor-pointer`, {
                        active: tab === 'log',
                      })}
                      onClick={() => setTab('log')}
                      role='tab'
                    >
                      Log
                    </a>
                  </li>
                </ul>
              </div>
              {/* Tab Header */}

              {/* Tab Body */}
              <div className='card-body p-0 pt-2'>
                <div className='tab-content'>
                  {/* Data */}
                  <div
                    className={clsx('tab-pane', {
                      active: tab === 'data',
                    })}
                  >
                    <div className='row'>
                      {/* First Col */}
                      <div className='col-lg-6'>
                        {/* Username */}
                        <div className='fv-row mb-5'>
                          {formik.values.username !== '' && (
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action === 'edit' ? 'required' : ''
                              }`}
                            >
                              {intl.formatMessage({id: 'LABEL_USERNAME'})}
                            </label>
                          )}
                          <input
                            type='text'
                            className={clsx(
                              'form-control form-control-solid',
                              {'is-invalid': formik.touched.username && formik.errors.username},
                              {
                                'is-valid': formik.touched.username && !formik.errors.username,
                              }
                            )}
                            placeholder={intl.formatMessage({id: 'LABEL_USERNAME'})}
                            {...formik.getFieldProps('username')}
                            onKeyUp={handleUsernameChange}
                            readOnly={action === 'edit' ? false : true}
                          />
                          {formik.touched.username && formik.errors.username ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {formik.errors.username}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Username */}

                        {/* Fullname */}
                        <div className='fv-row mb-5'>
                          {formik.values.fullname !== '' && (
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action === 'edit' ? 'required' : ''
                              }`}
                            >
                              {intl.formatMessage({id: 'LABEL_FULLNAME'})}
                            </label>
                          )}
                          <input
                            type='text'
                            className={clsx(
                              'form-control form-control-solid',
                              {'is-invalid': formik.touched.fullname && formik.errors.fullname},
                              {
                                'is-valid': formik.touched.fullname && !formik.errors.fullname,
                              }
                            )}
                            placeholder={intl.formatMessage({id: 'LABEL_FULLNAME'})}
                            {...formik.getFieldProps('fullname')}
                            readOnly={action === 'edit' ? false : true}
                          />
                          {formik.touched.fullname && formik.errors.fullname ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {formik.errors.fullname}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Fullname */}

                        {/* Email */}
                        <div className='fv-row mb-5'>
                          {formik.values.email !== '' && (
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action === 'edit' ? 'required' : ''
                              }`}
                            >
                              {intl.formatMessage({id: 'LABEL_EMAIL'})}
                            </label>
                          )}
                          <input
                            type='text'
                            className={clsx(
                              'form-control form-control-solid',
                              {'is-invalid': formik.touched.email && formik.errors.email},
                              {
                                'is-valid': formik.touched.email && !formik.errors.email,
                              }
                            )}
                            placeholder={intl.formatMessage({id: 'LABEL_EMAIL'})}
                            {...formik.getFieldProps('email')}
                            readOnly={action === 'edit' ? false : true}
                          />
                          {formik.touched.email && formik.errors.email ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {formik.errors.email}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Email */}

                        {/* Password */}
                        <div className='fv-row mb-5'>
                          {formik.values.password !== '' && (
                            <label className={`form-label fs-6 fw-bolder text-dark`}>
                              {intl.formatMessage({id: 'LABEL_PASSWORD'})}
                            </label>
                          )}
                          <div className='input-group'>
                            <input
                              type={!labelPassword ? 'password' : 'text'}
                              className={clsx(
                                'form-control form-control-solid',
                                {
                                  'is-invalid':
                                    formik.touched.password &&
                                    formik.values.password !== '' &&
                                    formik.errors.password,
                                },
                                {
                                  'is-valid':
                                    formik.touched.password &&
                                    formik.values.password !== '' &&
                                    !formik.errors.password,
                                }
                              )}
                              placeholder={intl.formatMessage({id: 'LABEL_PASSWORD'})}
                              {...formik.getFieldProps('password')}
                              readOnly={action === 'edit' ? false : true}
                            />
                            <Whisper
                              placement='top'
                              controlId='control-id-hover'
                              trigger='hover'
                              speaker={tooltipPass}
                            >
                              <span className='input-group-append'>
                                <button
                                  className='form-control form-control-solid'
                                  type='button'
                                  onClick={togglePasswordVisiblity}
                                >
                                  {!labelPassword ? (
                                    <a href='#'>
                                      <span className='menu-icon' data-kt-element='icon'>
                                        <KTIcon iconName='eye' className='fs-2' />
                                      </span>
                                    </a>
                                  ) : (
                                    <a href='#'>
                                      <span className='menu-icon' data-kt-element='icon'>
                                        <KTIcon iconName='eye-slash' className='fs-2' />
                                      </span>
                                    </a>
                                  )}
                                </button>
                              </span>
                            </Whisper>
                          </div>
                          {action === 'edit' && (
                            <small className='text-muted'>
                              {intl.formatMessage({id: 'LABEL_UPDATE_USER_PASSWORD'})}
                            </small>
                          )}
                          {formik.touched.password &&
                          formik.values.password !== '' &&
                          formik.errors.password ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {formik.errors.password}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Password */}

                        {/* Last Login */}
                        {action === 'view' && (
                          <div className='fv-row mb-5'>
                            <label className={`form-label fs-6 fw-bolder text-dark`}>
                              {intl.formatMessage({id: 'LABEL_LAST_LOGIN'})}
                            </label>
                            <input
                              type='text'
                              className='form-control form-control-solid'
                              placeholder={intl.formatMessage({
                                id: 'LABEL_LAST_LOGIN',
                              })}
                              value={
                                user.last_login === '0001-01-01T00:00:00Z'
                                  ? '-'
                                  : datetimeFormatter(user.last_login, '1', intl.locale)
                              }
                              readOnly
                            />
                          </div>
                        )}

                        {/* Last Login */}
                      </div>
                      {/* First Col */}

                      {/* Second Col */}
                      <div className='col-lg-6'>
                        {/* Holding */}
                        <div className='fv-row mb-5'>
                          {formik.values.holding_id && formik.values?.holding_id?.length > 0 && (
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action === 'edit' ? 'required' : ''
                              }`}
                            >
                              Holding
                            </label>
                          )}
                          <CheckPicker
                            readOnly={action !== 'edit' ? true : false}
                            block
                            placeholder={'-- Holding --'}
                            data={optionsHolding}
                            defaultValue={models.holding_id}
                            onChange={(val, tr) => {
                              let tmp = []
                              let result: any[] = []
                              let label: any = formik.values.holding_name

                              if (tr.type === 'keydown') {
                                // prevent user select/choice by using click enter
                                return
                              }

                              if (val.length > 0) {
                                if ((tr.target as unknown as any).checked === true) {
                                  tmp = (optionsHolding as unknown as any[]).find(
                                    (dt) =>
                                      dt.label === (tr.target as unknown as any).labels[0].innerText
                                  )
                                  result = [...label, tmp.label]
                                } else {
                                  if (tr.type === 'click') {
                                    if (
                                      (tr.target as unknown as any).lastChild.nodeName === 'path'
                                    ) {
                                      let tmp_result = label.filter(
                                        (e: any) =>
                                          e !==
                                          (tr.target as unknown as any).parentElement.parentElement
                                            .innerText
                                      )
                                      result = tmp_result
                                    }
                                  } else {
                                    let tmp_result = label.filter(
                                      (e: any) =>
                                        e !== (tr.target as unknown as any).labels[0].innerText
                                    )
                                    result = tmp_result
                                  }
                                }
                                formik.setFieldValue('holding_id', val)
                                formik.setFieldValue('holding_name', result)
                              } else {
                                formik.setFieldValue('holding_id', [])
                                formik.setFieldValue('holding_name', [])
                              }

                              formik.setFieldValue('company_id', [])
                              formik.setFieldValue('company_name', [])
                              formik.setFieldValue('brand_id', [])
                              formik.setFieldValue('brand_name', [])
                              formik.setFieldValue('outlet_id', [])
                              formik.setFieldValue('outlet_name', [])
                            }}
                            onSelect={(val, ite) => {
                              let value: any = formik.values.holding_id
                              let label: any = formik.values.holding_name
                              let tmpValue: any = []
                              let tmpLabel: any = []
                              let resultValue: any[] = []
                              let resultLabel: any[] = []

                              if (val.length > 0) {
                                if (value.indexOf(ite.value) === -1) {
                                  tmpValue = (optionsHolding as unknown as any[]).find(
                                    (dt) => dt.value === ite.value
                                  )

                                  if (tmpValue === undefined) {
                                    return
                                  }

                                  resultValue = [...value, tmpValue.value]
                                  formik.setFieldValue('holding_id', resultValue)
                                } else {
                                  tmpValue = value.filter((dt: any) => dt !== ite.value)
                                  formik.setFieldValue('holding_id', tmpValue)
                                }

                                if (label.indexOf(ite.label) === -1) {
                                  tmpLabel = (optionsHolding as unknown as any[]).find(
                                    (dt) => dt.label === ite.label
                                  )

                                  if (tmpLabel === undefined) {
                                    return
                                  }

                                  resultLabel = [...label, tmpLabel.label]
                                  formik.setFieldValue('holding_name', resultLabel)
                                } else {
                                  tmpLabel = label.filter((dt: any) => dt !== ite.label)
                                  formik.setFieldValue('holding_name', tmpLabel)
                                }
                              } else {
                                formik.setFieldValue('holding_id', [])
                                formik.setFieldValue('holding_name', [])
                              }

                              formik.setFieldValue('company_id', [])
                              formik.setFieldValue('company_name', [])
                              formik.setFieldValue('brand_id', [])
                              formik.setFieldValue('brand_name', [])
                              formik.setFieldValue('outlet_id', [])
                              formik.setFieldValue('outlet_name', [])
                            }}
                            value={formik.values.holding_id}
                            renderExtraFooter={() => (
                              <div style={footerStyles}>
                                <Checkbox
                                  indeterminate={
                                    (formik.values.holding_id as unknown as any[]).length > 0 &&
                                    (formik.values.holding_id as unknown as any[]).length <
                                      (optionsHolding as unknown as any[]).length
                                  }
                                  checked={
                                    (formik.values.holding_id as unknown as any[]).length ===
                                    (optionsHolding as unknown as any[]).length
                                  }
                                  onChange={(data, checked) => {
                                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                                    if (checked) {
                                      formik.setFieldValue(
                                        'holding_id',
                                        (optionsHolding as unknown as any[]).map((e) => e.value)
                                      )
                                      formik.setFieldValue(
                                        'holding_name',
                                        (optionsHolding as unknown as any[]).map((e) => e.label)
                                      )
                                    } else {
                                      formik.setFieldValue('holding_id', [])
                                      formik.setFieldValue('holding_name', [])
                                    }
                                  }}
                                >
                                  {(formik.values.holding_id as unknown as any[]).length ===
                                  (optionsHolding as unknown as any[]).length
                                    ? intl.formatMessage({id: 'LABEL_UNSELECT_ALL'})
                                    : intl.formatMessage({id: 'LABEL_SELECT_ALL'})}
                                </Checkbox>
                              </div>
                            )}
                          />
                          {formik.errors.holding_id ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {intl.formatMessage(
                                  {id: 'VALIDATION_REQUIRED_FIELD'},
                                  {
                                    name: 'Holding',
                                  }
                                )}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Holding */}

                        {/* Company */}
                        <div className='fv-row mb-5'>
                          {formik.values.company_id && formik.values?.company_id?.length > 0 && (
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action === 'edit' ? 'required' : ''
                              }`}
                            >
                              {intl.formatMessage({id: 'LABEL_COMPANY'})}
                            </label>
                          )}
                          {action !== 'edit' ? (
                            models.company !== undefined && (
                              <CheckPicker
                                readOnly={true}
                                block
                                data={(models.company as unknown as any[]).map((e) => ({
                                  value: e.company_id,
                                  label: e.name,
                                }))}
                                defaultValue={models.company_id}
                              />
                            )
                          ) : (
                            <CheckPicker
                              block
                              disabled={
                                formik.values.holding_id &&
                                (formik.values.holding_id as unknown as any[]).length > 0
                                  ? false
                                  : true
                              }
                              placeholder={`-- ${intl.formatMessage({id: 'LABEL_COMPANY'})} --`}
                              data={optionsCompany}
                              defaultValue={models.company_id}
                              onChange={(val, tr) => {
                                let tmp = []
                                let result: any[] = []
                                let label: any = formik.values.company_name

                                if (tr.type === 'keydown') {
                                  // prevent user select/choice by using click enter
                                  return
                                }

                                if (val.length > 0) {
                                  if ((tr.target as unknown as any).checked === true) {
                                    tmp = (optionsCompany as unknown as any[]).find(
                                      (dt) =>
                                        dt.label ===
                                        (tr.target as unknown as any).labels[0].innerText
                                    )
                                    result = [...label, tmp.label]
                                  } else {
                                    if (tr.type === 'click') {
                                      if (
                                        (tr.target as unknown as any).lastChild.nodeName === 'path'
                                      ) {
                                        let tmp_result = label.filter(
                                          (e: any) =>
                                            e !==
                                            (tr.target as unknown as any).parentElement
                                              .parentElement.innerText
                                        )
                                        result = tmp_result
                                      }
                                    } else {
                                      let tmp_result = label.filter(
                                        (e: any) =>
                                          e !== (tr.target as unknown as any).labels[0].innerText
                                      )
                                      result = tmp_result
                                    }
                                  }
                                  formik.setFieldValue('company_id', val)
                                  formik.setFieldValue('company_name', result)
                                } else {
                                  formik.setFieldValue('company_id', [])
                                  formik.setFieldValue('company_name', [])
                                }

                                formik.setFieldValue('brand_id', [])
                                formik.setFieldValue('brand_name', [])
                                formik.setFieldValue('outlet_id', [])
                                formik.setFieldValue('outlet_name', [])
                              }}
                              onSelect={(val, ite) => {
                                let value: any = formik.values.company_id
                                let label: any = formik.values.company_name
                                let tmpValue: any = []
                                let tmpLabel: any = []
                                let resultValue: any[] = []
                                let resultLabel: any[] = []

                                if (val.length > 0) {
                                  if (value.indexOf(ite.value) === -1) {
                                    tmpValue = (optionsCompany as unknown as any[]).find(
                                      (dt) => dt.value === ite.value
                                    )

                                    if (tmpValue === undefined) {
                                      return
                                    }

                                    resultValue = [...value, tmpValue.value]
                                    formik.setFieldValue('company_id', resultValue)
                                  } else {
                                    tmpValue = value.filter((dt: any) => dt !== ite.value)
                                    formik.setFieldValue('company_id', tmpValue)
                                  }

                                  if (label.indexOf(ite.label) === -1) {
                                    tmpLabel = (optionsCompany as unknown as any[]).find(
                                      (dt) => dt.label === ite.label
                                    )

                                    if (tmpLabel === undefined) {
                                      return
                                    }

                                    resultLabel = [...label, tmpLabel.label]
                                    formik.setFieldValue('company_name', resultLabel)
                                  } else {
                                    tmpLabel = label.filter((dt: any) => dt !== ite.label)
                                    formik.setFieldValue('company_name', tmpLabel)
                                  }
                                } else {
                                  formik.setFieldValue('company_id', [])
                                  formik.setFieldValue('company_name', [])
                                }

                                formik.setFieldValue('brand_id', [])
                                formik.setFieldValue('brand_name', [])
                                formik.setFieldValue('outlet_id', [])
                                formik.setFieldValue('outlet_name', [])
                              }}
                              value={formik.values.company_id}
                              renderExtraFooter={() => (
                                <div style={footerStyles}>
                                  <Checkbox
                                    indeterminate={
                                      (formik.values.company_id as unknown as any[]).length > 0 &&
                                      (formik.values.company_id as unknown as any[]).length <
                                        (optionsCompany as unknown as any[]).length
                                    }
                                    checked={
                                      (formik.values.company_id as unknown as any[]).length ===
                                      (optionsCompany as unknown as any[]).length
                                    }
                                    onChange={(data, checked) => {
                                      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                                      if (checked) {
                                        formik.setFieldValue(
                                          'company_id',
                                          (optionsCompany as unknown as any[]).map((e) => e.value)
                                        )
                                        formik.setFieldValue(
                                          'company_name',
                                          (optionsCompany as unknown as any[]).map((e) => e.label)
                                        )
                                      } else {
                                        formik.setFieldValue('company_id', [])
                                        formik.setFieldValue('company_name', [])
                                      }
                                    }}
                                  >
                                    {(formik.values.company_id as unknown as any[]).length ===
                                    (optionsCompany as unknown as any[]).length
                                      ? intl.formatMessage({id: 'LABEL_UNSELECT_ALL'})
                                      : intl.formatMessage({id: 'LABEL_SELECT_ALL'})}
                                  </Checkbox>
                                </div>
                              )}
                            />
                          )}
                          {formik.errors.company_id ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {intl.formatMessage(
                                  {id: 'VALIDATION_REQUIRED_FIELD'},
                                  {
                                    name: intl.formatMessage({id: 'LABEL_COMPANY'}),
                                  }
                                )}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Company */}

                        {/* Brand */}
                        <div className='fv-row mb-5'>
                          {formik.values.brand_id && formik.values?.brand_id?.length > 0 && (
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action === 'edit' ? 'required' : ''
                              }`}
                            >
                              {intl.formatMessage({id: 'LABEL_BRAND'})}
                            </label>
                          )}
                          {action !== 'edit' ? (
                            models.brand !== undefined && (
                              <CheckPicker
                                readOnly={true}
                                block
                                data={(models.brand as unknown as any[]).map((e) => ({
                                  value: e.brand_id,
                                  label: e.name,
                                }))}
                                defaultValue={models.brand_id}
                              />
                            )
                          ) : (
                            <CheckPicker
                              block
                              disabled={
                                formik.values.company_id &&
                                (formik.values.company_id as unknown as any[]).length > 0
                                  ? false
                                  : true
                              }
                              placeholder={`-- ${intl.formatMessage({id: 'LABEL_BRAND'})} --`}
                              data={optionsBrand}
                              defaultValue={models.brand_id}
                              onChange={(val, tr) => {
                                let tmp = []
                                let result: any[] = []
                                let label: any = formik.values.brand_name

                                if (tr.type === 'keydown') {
                                  // prevent user select/choice by using click enter
                                  return
                                }

                                if (val.length > 0) {
                                  if ((tr.target as unknown as any).checked === true) {
                                    tmp = (optionsBrand as unknown as any[]).find(
                                      (dt) =>
                                        dt.label ===
                                        (tr.target as unknown as any).labels[0].innerText
                                    )
                                    result = [...label, tmp.label]
                                  } else {
                                    if (tr.type === 'click') {
                                      if (
                                        (tr.target as unknown as any).lastChild.nodeName === 'path'
                                      ) {
                                        let tmp_result = label.filter(
                                          (e: any) =>
                                            e !==
                                            (tr.target as unknown as any).parentElement
                                              .parentElement.innerText
                                        )
                                        result = tmp_result
                                      }
                                    } else {
                                      let tmp_result = label.filter(
                                        (e: any) =>
                                          e !== (tr.target as unknown as any).labels[0].innerText
                                      )
                                      result = tmp_result
                                    }
                                  }
                                  formik.setFieldValue('brand_id', val)
                                  formik.setFieldValue('brand_name', result)
                                } else {
                                  formik.setFieldValue('brand_id', [])
                                  formik.setFieldValue('brand_name', [])
                                }

                                formik.setFieldValue('outlet_id', [])
                                formik.setFieldValue('outlet_name', [])
                              }}
                              onSelect={(val, ite) => {
                                let value: any = formik.values.brand_id
                                let label: any = formik.values.brand_name
                                let tmpValue: any = []
                                let tmpLabel: any = []
                                let resultValue: any[] = []
                                let resultLabel: any[] = []

                                if (val.length > 0) {
                                  if (value.indexOf(ite.value) === -1) {
                                    tmpValue = (optionsBrand as unknown as any[]).find(
                                      (dt) => dt.value === ite.value
                                    )

                                    if (tmpValue === undefined) {
                                      return
                                    }

                                    resultValue = [...value, tmpValue.value]
                                    formik.setFieldValue('brand_id', resultValue)
                                  } else {
                                    tmpValue = value.filter((dt: any) => dt !== ite.value)
                                    formik.setFieldValue('brand_id', tmpValue)
                                  }

                                  if (label.indexOf(ite.label) === -1) {
                                    tmpLabel = (optionsBrand as unknown as any[]).find(
                                      (dt) => dt.label === ite.label
                                    )

                                    if (tmpLabel === undefined) {
                                      return
                                    }

                                    resultLabel = [...label, tmpLabel.label]
                                    formik.setFieldValue('brand_name', resultLabel)
                                  } else {
                                    tmpLabel = label.filter((dt: any) => dt !== ite.label)
                                    formik.setFieldValue('brand_name', tmpLabel)
                                  }
                                } else {
                                  formik.setFieldValue('brand_id', [])
                                  formik.setFieldValue('brand_name', [])
                                }

                                formik.setFieldValue('outlet_id', [])
                                formik.setFieldValue('outlet_name', [])
                              }}
                              value={formik.values.brand_id}
                              renderExtraFooter={() => (
                                <div style={footerStyles}>
                                  <Checkbox
                                    indeterminate={
                                      (formik.values.brand_id as unknown as any[]).length > 0 &&
                                      (formik.values.brand_id as unknown as any[]).length <
                                        (optionsBrand as unknown as any[]).length
                                    }
                                    checked={
                                      (formik.values.brand_id as unknown as any[]).length ===
                                      (optionsBrand as unknown as any[]).length
                                    }
                                    onChange={(data, checked) => {
                                      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                                      if (checked) {
                                        formik.setFieldValue(
                                          'brand_id',
                                          (optionsBrand as unknown as any[]).map((e) => e.value)
                                        )
                                        formik.setFieldValue(
                                          'brand_name',
                                          (optionsBrand as unknown as any[]).map((e) => e.label)
                                        )
                                      } else {
                                        formik.setFieldValue('brand_id', [])
                                        formik.setFieldValue('brand_name', [])
                                      }
                                    }}
                                  >
                                    {(formik.values.brand_id as unknown as any[]).length ===
                                    (optionsBrand as unknown as any[]).length
                                      ? intl.formatMessage({id: 'LABEL_UNSELECT_ALL'})
                                      : intl.formatMessage({id: 'LABEL_SELECT_ALL'})}
                                  </Checkbox>
                                </div>
                              )}
                            />
                          )}
                          {formik.errors.brand_id ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {intl.formatMessage(
                                  {id: 'VALIDATION_REQUIRED_FIELD'},
                                  {
                                    name: intl.formatMessage({id: 'LABEL_BRAND'}),
                                  }
                                )}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Brand */}

                        {/* Outlet */}
                        <div className='fv-row mb-5'>
                          {formik.values.outlet_id && formik.values?.outlet_id?.length > 0 && (
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action === 'edit' ? 'required' : ''
                              }`}
                            >
                              Outlet
                            </label>
                          )}
                          {action !== 'edit' ? (
                            models.outlet !== undefined && (
                              <CheckPicker
                                readOnly={true}
                                block
                                data={(models.outlet as unknown as any[]).map((e) => ({
                                  value: e.outlet_id,
                                  label: e.name,
                                }))}
                                defaultValue={models.outlet_id}
                              />
                            )
                          ) : (
                            <CheckPicker
                              block
                              disabled={
                                formik.values.brand_id &&
                                (formik.values.brand_id as unknown as any[]).length > 0
                                  ? false
                                  : true
                              }
                              placeholder={`-- Outlet --`}
                              data={optionsOutlet}
                              defaultValue={models.outlet_id}
                              onChange={(val, tr) => {
                                let tmp = []
                                let result: any[] = []
                                let label: any = formik.values.outlet_name

                                if (tr.type === 'keydown') {
                                  // prevent user select/choice by using click enter
                                  return
                                }

                                if (val.length > 0) {
                                  if ((tr.target as unknown as any).checked === true) {
                                    tmp = (optionsOutlet as unknown as any[]).find(
                                      (dt) =>
                                        dt.label ===
                                        (tr.target as unknown as any).labels[0].innerText
                                    )
                                    result = [...label, tmp.label]
                                  } else {
                                    if (tr.type === 'click') {
                                      if (
                                        (tr.target as unknown as any).lastChild.nodeName === 'path'
                                      ) {
                                        let tmp_result = label.filter(
                                          (e: any) =>
                                            e !==
                                            (tr.target as unknown as any).parentElement
                                              .parentElement.innerText
                                        )
                                        result = tmp_result
                                      }
                                    } else {
                                      let tmp_result = label.filter(
                                        (e: any) =>
                                          e !== (tr.target as unknown as any).labels[0].innerText
                                      )
                                      result = tmp_result
                                    }
                                  }
                                  formik.setFieldValue('outlet_id', val)
                                  formik.setFieldValue('outlet_name', result)
                                } else {
                                  formik.setFieldValue('outlet_id', [])
                                  formik.setFieldValue('outlet_name', [])
                                }
                              }}
                              onSelect={(val, ite) => {
                                let value: any = formik.values.outlet_id
                                let label: any = formik.values.outlet_name
                                let tmpValue: any = []
                                let tmpLabel: any = []
                                let resultValue: any[] = []
                                let resultLabel: any[] = []

                                if (val.length > 0) {
                                  if (value.indexOf(ite.value) === -1) {
                                    tmpValue = (optionsOutlet as unknown as any[]).find(
                                      (dt) => dt.value === ite.value
                                    )

                                    if (tmpValue === undefined) {
                                      return
                                    }

                                    resultValue = [...value, tmpValue.value]
                                    formik.setFieldValue('outlet_id', resultValue)
                                  } else {
                                    tmpValue = value.filter((dt: any) => dt !== ite.value)
                                    formik.setFieldValue('outlet_id', tmpValue)
                                  }

                                  if (label.indexOf(ite.label) === -1) {
                                    tmpLabel = (optionsOutlet as unknown as any[]).find(
                                      (dt) => dt.label === ite.label
                                    )

                                    if (tmpLabel === undefined) {
                                      return
                                    }

                                    resultLabel = [...label, tmpLabel.label]
                                    formik.setFieldValue('outlet_name', resultLabel)
                                  } else {
                                    tmpLabel = label.filter((dt: any) => dt !== ite.label)
                                    formik.setFieldValue('outlet_name', tmpLabel)
                                  }
                                } else {
                                  formik.setFieldValue('outlet_id', [])
                                  formik.setFieldValue('outlet_name', [])
                                }
                              }}
                              value={formik.values.outlet_id}
                              renderExtraFooter={() => (
                                <div style={footerStyles}>
                                  <Checkbox
                                    indeterminate={
                                      (formik.values.outlet_id as unknown as any[]).length > 0 &&
                                      (formik.values.outlet_id as unknown as any[]).length <
                                        (optionsOutlet as unknown as any[]).length
                                    }
                                    checked={
                                      (formik.values.outlet_id as unknown as any[]).length ===
                                      (optionsOutlet as unknown as any[]).length
                                    }
                                    onChange={(data, checked) => {
                                      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                                      if (checked) {
                                        formik.setFieldValue(
                                          'outlet_id',
                                          (optionsOutlet as unknown as any[]).map((e) => e.value)
                                        )
                                        formik.setFieldValue(
                                          'outlet_name',
                                          (optionsOutlet as unknown as any[]).map((e) => e.label)
                                        )
                                      } else {
                                        formik.setFieldValue('outlet_id', [])
                                        formik.setFieldValue('outlet_name', [])
                                      }
                                    }}
                                  >
                                    {(formik.values.outlet_id as unknown as any[]).length ===
                                    (optionsOutlet as unknown as any[]).length
                                      ? intl.formatMessage({id: 'LABEL_UNSELECT_ALL'})
                                      : intl.formatMessage({id: 'LABEL_SELECT_ALL'})}
                                  </Checkbox>
                                </div>
                              )}
                            />
                          )}
                          {formik.errors.outlet_id ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {intl.formatMessage(
                                  {id: 'VALIDATION_REQUIRED_FIELD'},
                                  {
                                    name: 'Outlet',
                                  }
                                )}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Outlet */}
                      </div>
                      {/* Second Col */}
                    </div>

                    <div className='row mt-10'>
                      {/* Third Col */}
                      <div className='col-lg-6'>
                        {/* User Group */}
                        <div className='fv-row mb-5'>
                          {formik.values.role_id !== '' && (
                            <label className={`form-label fs-6 fw-bolder text-dark`}>
                              {intl.formatMessage({id: 'LABEL_USER_GROUP'})}
                            </label>
                          )}
                          <InputPicker
                            block
                            data={optionsRole}
                            readOnly={action !== 'edit' ? true : false}
                            placeholder={`-- ${intl.formatMessage({id: 'LABEL_USER_GROUP'})} --`}
                            defaultValue={
                              optionsRole
                                ? (optionsRole as unknown as any[]).find(
                                    (e: any) => e.value === formik.values.role_id
                                  )
                                : ''
                            }
                            onChange={(e) => {
                              formik.setFieldValue('role_id', e === null ? '' : e)
                            }}
                            onSelect={(val, itm) => {
                              formik.setFieldValue('role_name', val === null ? '' : itm.label)
                            }}
                            value={formik.values.role_id}
                          />
                          {formik.errors.role_id ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {intl.formatMessage(
                                  {id: 'VALIDATION_REQUIRED_FIELD'},
                                  {
                                    name: intl.formatMessage({id: 'LABEL_USER_GROUP'}),
                                  }
                                )}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* User Group */}

                        {/* Note */}
                        <div className='fv-row mb-5'>
                          {formik.values.note !== '' && (
                            <label className={`form-label fs-6 fw-bolder text-dark`}>
                              {intl.formatMessage({id: 'LABEL_NOTE'})}
                            </label>
                          )}
                          <textarea
                            className={clsx(
                              'form-control form-control-solid',
                              {'is-invalid': formik.touched.note && formik.errors.note},
                              {
                                'is-valid': formik.touched.note && !formik.errors.note,
                              }
                            )}
                            placeholder={intl.formatMessage({id: 'LABEL_NOTE'})}
                            {...formik.getFieldProps('note')}
                            rows={3}
                            readOnly={action !== 'edit' ? true : false}
                          />
                        </div>
                        {/* Address */}
                      </div>
                      {/* Third Col */}

                      {/* Fourth Col */}
                      <div className='col-lg-6'>
                        {/* Status */}
                        <div className='fv-row mb-5'>
                          {formik.values.status !== '' && (
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action === 'edit' ? 'required' : ''
                              }`}
                            >
                              Status
                            </label>
                          )}
                          <div className='form-check form-switch form-check-custom form-check-solid mt-1'>
                            <Toggle
                              size='lg'
                              readOnly={action !== 'edit' ? true : false}
                              checkedChildren={intl.formatMessage({id: 'LABEL_ACTIVE'})}
                              unCheckedChildren={intl.formatMessage({id: 'LABEL_INACTIVE'})}
                              checked={formik.values.status === 't' ? true : false}
                              onChange={() =>
                                formik.setFieldValue(
                                  'status',
                                  formik.values.status === 't' ? 'f' : 't'
                                )
                              }
                            />
                          </div>
                          {formik.touched.status && formik.errors.status ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {formik.errors.status}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Status */}

                        {/* Is Event User */}
                        <div className='fv-row mb-5'>
                          {formik.values.is_event_user !== '' && (
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action === 'edit' ? 'required' : ''
                              }`}
                            >
                              {intl.formatMessage({id: 'LABEL_EVENT_ACCOUNT'})}
                            </label>
                          )}
                          <div className='form-check form-switch form-check-custom form-check-solid mt-1'>
                            <Toggle
                              size='lg'
                              readOnly={action !== 'edit' ? true : false}
                              checkedChildren={intl.formatMessage({id: 'LABEL_YES'})}
                              unCheckedChildren={intl.formatMessage({id: 'LABEL_NO'})}
                              checked={formik.values.is_event_user === 't' ? true : false}
                              onChange={() =>
                                formik.setFieldValue(
                                  'is_event_user',
                                  formik.values.is_event_user === 't' ? 'f' : 't'
                                )
                              }
                            />
                          </div>
                          {formik.touched.is_event_user && formik.errors.is_event_user ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {formik.errors.is_event_user}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Is Event User */}
                      </div>
                      {/* Fourth Col */}
                    </div>
                  </div>
                  {/* Data */}

                  {/* Detail */}
                  <div
                    className={clsx('tab-pane', {
                      active: tab === 'log',
                    })}
                  >
                    <FormLog payload={user} formik={formik} />
                  </div>
                  {/* Detail */}
                </div>
              </div>
              {/* Tab Body */}
            </form>
          )}
        </div>
        {/* Form */}
      </Drawer.Body>

      {/* Dialog */}
      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(true)}
        className='modal fade show d-block'
      >
        <Dialog.Panel className='modal-dialog modal-fullscreen'>
          <div className='modal-content'>
            <Dialog.Title className='modal-header'>
              {intl.formatMessage({id: 'DIALOG_HEADER_TITLE'})}
            </Dialog.Title>
            <div className='modal-body scroll-y mx-2 mx-xl-2 my-2'>
              <div className='row'>
                {/* First Col */}
                <div className='col-lg-6'>
                  {/* Username */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark'>
                      {intl.formatMessage({id: 'LABEL_USERNAME'})}
                    </label>
                    {models.username !== formik.values.username ? (
                      <>
                        {action !== 'add' && (
                          <input
                            type='text'
                            className='form-control form-control-solid'
                            value={models.username}
                            readOnly
                          />
                        )}
                        <input
                          type='text'
                          className='form-control form-control-solid text-primary is-valid'
                          value={formik.values.username}
                          readOnly
                        />
                      </>
                    ) : (
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        value={models.username}
                        readOnly
                      />
                    )}
                  </div>
                  {/* Username */}

                  {/* Fullname */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark'>
                      {intl.formatMessage({id: 'LABEL_FULLNAME'})}
                    </label>
                    {models.fullname !== formik.values.fullname ? (
                      <>
                        {action !== 'add' && (
                          <input
                            type='text'
                            className='form-control form-control-solid'
                            value={models.fullname}
                            readOnly
                          />
                        )}
                        <input
                          type='text'
                          className='form-control form-control-solid text-primary is-valid'
                          value={formik.values.fullname}
                          readOnly
                        />
                      </>
                    ) : (
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        value={models.fullname}
                        readOnly
                      />
                    )}
                  </div>
                  {/* Fullname */}

                  {/* Email */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark'>
                      {intl.formatMessage({id: 'LABEL_EMAIL'})}
                    </label>
                    {models.email !== formik.values.email ? (
                      <>
                        {action !== 'add' && (
                          <input
                            type='text'
                            className='form-control form-control-solid'
                            value={models.email}
                            readOnly
                          />
                        )}
                        <input
                          type='text'
                          className='form-control form-control-solid text-primary is-valid'
                          value={formik.values.email}
                          readOnly
                        />
                      </>
                    ) : (
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        value={models.email}
                        readOnly
                      />
                    )}
                  </div>
                  {/* Email */}

                  {/* Password */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark'>
                      {intl.formatMessage({id: 'LABEL_PASSWORD'})}
                    </label>
                    {models.password !== formik.values.password ? (
                      <>
                        {action !== 'add' && (
                          <input
                            type='text'
                            className='form-control form-control-solid'
                            value={models.password}
                            readOnly
                          />
                        )}
                        <input
                          type='text'
                          className='form-control form-control-solid text-primary is-valid'
                          value={formik.values.password}
                          readOnly
                        />
                      </>
                    ) : (
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        value={models.password}
                        readOnly
                      />
                    )}
                  </div>
                  {/* Password */}
                </div>
                {/* First Col */}

                {/* Second Col */}
                <div className='col-lg-6'>
                  {/* Holding */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark'>Holding</label>
                    {models.holding_id !== formik.values.holding_id ? (
                      <>
                        {action !== 'add' && (
                          <textarea
                            className='form-control form-control-solid'
                            value={models.holding_name?.toString().replaceAll(',', ', ')}
                            readOnly
                          />
                        )}
                        <textarea
                          className='form-control form-control-solid text-primary is-valid'
                          value={formik.values?.holding_name?.toString().replaceAll(',', ', ')}
                          readOnly
                        />
                      </>
                    ) : (
                      <textarea
                        className='form-control form-control-solid'
                        value={formik.values?.holding_name?.toString().replaceAll(',', ', ')}
                        readOnly
                      />
                    )}
                  </div>
                  {/* Holding */}

                  {/* Company */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark'>
                      {intl.formatMessage({id: 'LABEL_COMPANY'})}
                    </label>
                    {models.company_id !== formik.values.company_id ? (
                      <>
                        {action !== 'add' && (
                          <textarea
                            className='form-control form-control-solid'
                            value={models.company_name?.toString().replaceAll(',', ', ')}
                            readOnly
                          />
                        )}
                        <textarea
                          className='form-control form-control-solid text-primary is-valid'
                          value={formik.values?.company_name?.toString().replaceAll(',', ', ')}
                          readOnly
                        />
                      </>
                    ) : (
                      <textarea
                        className='form-control form-control-solid'
                        value={formik.values?.company_name?.toString().replaceAll(',', ', ')}
                        readOnly
                      />
                    )}
                  </div>
                  {/* Company */}

                  {/* Brand */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark'>
                      {intl.formatMessage({id: 'LABEL_BRAND'})}
                    </label>
                    {models.brand_id !== formik.values.brand_id ? (
                      <>
                        {action !== 'add' && (
                          <textarea
                            className='form-control form-control-solid'
                            value={models.brand_name?.toString().replaceAll(',', ', ')}
                            readOnly
                          />
                        )}
                        <textarea
                          className='form-control form-control-solid text-primary is-valid'
                          value={formik.values?.brand_name?.toString().replaceAll(',', ', ')}
                          readOnly
                        />
                      </>
                    ) : (
                      <textarea
                        className='form-control form-control-solid'
                        value={formik.values?.brand_name?.toString().replaceAll(',', ', ')}
                        readOnly
                      />
                    )}
                  </div>
                  {/* Brand */}

                  {/* Outlet */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark'>Outlet</label>
                    {models.outlet_id !== formik.values.outlet_id ? (
                      <>
                        {action !== 'add' && (
                          <textarea
                            className='form-control form-control-solid'
                            value={models.outlet_name?.toString().replaceAll(',', ', ')}
                            readOnly
                          />
                        )}
                        <textarea
                          className='form-control form-control-solid text-primary is-valid'
                          value={formik.values?.outlet_name?.toString().replaceAll(',', ', ')}
                          readOnly
                        />
                      </>
                    ) : (
                      <textarea
                        className='form-control form-control-solid'
                        value={formik.values?.outlet_name?.toString().replaceAll(',', ', ')}
                        readOnly
                      />
                    )}
                  </div>
                  {/* Outlet */}
                </div>
                {/* Second Col */}
              </div>

              <div className='row mt-10'>
                {/* Third Col */}
                <div className='col-lg-6'>
                  {/* User Group */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark'>
                      {intl.formatMessage({id: 'LABEL_USER_GROUP'})}
                    </label>
                    {models.role_id !== formik.values.role_id ? (
                      <>
                        {action !== 'add' && (
                          <input
                            type='text'
                            className='form-control form-control-solid'
                            value={models.role_name}
                            readOnly
                          />
                        )}
                        <input
                          type='text'
                          className='form-control form-control-solid text-primary is-valid'
                          value={formik.values.role_name}
                          readOnly
                        />
                      </>
                    ) : (
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        value={models.role_name}
                        readOnly
                      />
                    )}
                  </div>
                  {/* User Group */}

                  {/* Note */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark'>
                      {intl.formatMessage({id: 'LABEL_NOTE'})}
                    </label>
                    {models.note !== formik.values.note ? (
                      <>
                        {action !== 'add' && (
                          <textarea
                            className='form-control form-control-solid'
                            value={models.note}
                            readOnly
                          />
                        )}
                        <textarea
                          className='form-control form-control-solid text-primary is-valid'
                          value={formik.values.note}
                          readOnly
                        />
                      </>
                    ) : (
                      <textarea
                        className='form-control form-control-solid'
                        value={formik.values.note}
                        readOnly
                      />
                    )}
                  </div>
                  {/* Note */}
                </div>
                {/* Third Col */}

                {/* Fourth Col */}
                <div className='col-lg-6'>
                  {/* Status */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark'>
                      <label className='form-label fs-6 fw-bolder text-dark'>Status</label>
                    </label>
                    {models.status !== formik.values.status ? (
                      <>
                        {action !== 'add' && (
                          <input
                            type='text'
                            className='form-control form-control-solid'
                            value={
                              models.status === 't'
                                ? intl.formatMessage({id: 'LABEL_ACTIVE'})
                                : intl.formatMessage({id: 'LABEL_INACTIVE'})
                            }
                            readOnly
                          />
                        )}
                        <input
                          type='text'
                          className='form-control form-control-solid text-primary is-valid'
                          value={
                            formik.values.status === 't'
                              ? intl.formatMessage({id: 'LABEL_ACTIVE'})
                              : intl.formatMessage({id: 'LABEL_INACTIVE'})
                          }
                          readOnly
                        />
                      </>
                    ) : (
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        value={
                          models.status === 't'
                            ? intl.formatMessage({id: 'LABEL_ACTIVE'})
                            : intl.formatMessage({id: 'LABEL_INACTIVE'})
                        }
                        readOnly
                      />
                    )}
                  </div>
                  {/* Status */}

                  {/* Is Event User */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark'>
                      <label className='form-label fs-6 fw-bolder text-dark'>
                        {intl.formatMessage({id: 'LABEL_EVENT_ACCOUNT'})}
                      </label>
                    </label>
                    {models.is_event_user !== formik.values.is_event_user ? (
                      <>
                        {action !== 'add' && (
                          <input
                            type='text'
                            className='form-control form-control-solid'
                            value={
                              models.is_event_user === 't'
                                ? intl.formatMessage({id: 'LABEL_YES'})
                                : intl.formatMessage({id: 'LABEL_NO'})
                            }
                            readOnly
                          />
                        )}
                        <input
                          type='text'
                          className='form-control form-control-solid text-primary is-valid'
                          value={
                            formik.values.is_event_user === 't'
                              ? intl.formatMessage({id: 'LABEL_YES'})
                              : intl.formatMessage({id: 'LABEL_NO'})
                          }
                          readOnly
                        />
                      </>
                    ) : (
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        value={
                          models.is_event_user === 't'
                            ? intl.formatMessage({id: 'LABEL_YES'})
                            : intl.formatMessage({id: 'LABEL_NO'})
                        }
                        readOnly
                      />
                    )}
                  </div>
                  {/* Is Event User */}
                </div>
                {/* Fourth Col */}
              </div>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-outline btn-btn-outline-info me-3'
                data-kt-users-modal-action='close'
                onClick={() => setIsDialogOpen(false)}
              >
                {intl.formatMessage({id: 'BUTTON_LABEL_BACK'})}
              </button>

              <button
                type='button'
                className={`btn ${loading ? 'btn-secondary' : 'btn-success'}`}
                data-kt-users-modal-action='submit'
                onClick={() => formik.handleSubmit()}
                disabled={loading ? true : false}
              >
                {!loading && (
                  <span className='indicator-label'>
                    {intl.formatMessage({id: 'BUTTON_LABEL_SUBMIT'})}
                  </span>
                )}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    {intl.formatMessage({id: 'BUTTON_LABEL_PLEASE_WAIT'})}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
      {/* Dialog */}
    </>
  )
}

export {ViewDrawerForm}
