import {RowDatetimeCellFormatter} from 'app/components'
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {Whisper, Tooltip} from 'rsuite'

type Props = {
  count: any
  data: any
  id: any
}

const RowTooltipCountReportShiftOpenFormatter: FC<Props> = ({count, data, id}) => {
  const intl = useIntl()
  return (
    <Whisper
      key={`whisper-${id}`}
      placement='top'
      controlId='control-id-hover'
      trigger='hover'
      speaker={
        <Tooltip id={`tooltip-count-${id}`} key={`tooltip-count-${id}`}>
          {
            <div className='menu-item' key={`open-item-${id}`}>
              {data.map((item: any, index: number) => (
                <>
                  {index < 6 &&
                    (index !== 5 ? (
                      <div className='menu-link without-sub' key={`open-link-${index}`}>
                        <span className='menu-bullet' key={`open-bullet-${index}`}>
                          <span className='bullet bullet-dot' key={`bullet-dot-${index}`}></span>
                        </span>
                        <span className='menu-title' key={`open-title-${index}`}>
                          {item.start_time === '0001-01-01T00:00:00Z' ? (
                            '-'
                          ) : (
                            <RowDatetimeCellFormatter datetime={item.start_time} />
                          )}
                        </span>
                      </div>
                    ) : (
                      <div className='menu-link without-sub' key={`open-link-${index}`}>
                        <span className='menu-bullet' key={`open-bullet-${index}`}>
                          <span className='bullet bullet-dot' key={`bullet-dot-${index}`}></span>
                        </span>
                        <span className='menu-title' key={`open-title-${index}`}>
                          {count - 5} {intl.formatMessage({id: 'LABEL_OTHERS'})}
                        </span>
                      </div>
                    ))}
                </>
              ))}
            </div>
          }
        </Tooltip>
      }
    >
      <div className={`badge badge-secondary fw-bolder`} key={`count-${id}`}>
        {count}
      </div>
    </Whisper>
  )
}

export {RowTooltipCountReportShiftOpenFormatter}
