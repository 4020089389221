/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {Tooltip, Whisper} from 'rsuite'

import {KTIcon} from '_metronic/helpers'
import {useShortcutMenus, useUserGroupModules} from 'app/context/providers'

const ToolbarReports = () => {
  const intl = useIntl()
  const {state: shortcuts} = useShortcutMenus()
  const {currentMenuID} = useUserGroupModules()

  const tooltipIcon = <Tooltip>{intl.formatMessage({id: 'LABEL_SHORTCUT_MENU'})}</Tooltip>

  useEffect(() => {
    document.body.setAttribute('data-kt-app-toolbar-fixed', 'true')
  }, [])

  return (
    <div className='d-flex align-items-center overflow-auto'>
      {/* Wrapper */}
      <div className='d-flex align-items-center flex-shrink-0'>
        {/* Icon */}
        <Whisper placement='top' controlId='control-id-hover' trigger='hover' speaker={tooltipIcon}>
          <span>
            <KTIcon iconName='clipboard' className='fs-2' />
          </span>
        </Whisper>
        {/* Icon */}

        <div className='bullet bg-secondary h-35px w-1px mx-5'></div>

        {/* Shortcut Menu */}
        {shortcuts !== undefined && shortcuts.length === 0 ? (
          <span className='text-muted'>-</span>
        ) : (
          <div className='flex-shrink-0' style={{paddingLeft: '5px'}}>
            <ul className='nav'>
              {shortcuts !== undefined &&
                (shortcuts as unknown as any[]).map((dt: any, i: number) => (
                  <div key={`list-${i}`}>
                    <Whisper
                      placement='left'
                      controlId='control-id-hover'
                      trigger='hover'
                      speaker={
                        <Tooltip id={`tooltip-shortcut-menu-${dt.menu_id}`}>
                          {intl.formatMessage({id: 'LABEL_MODULE'})}: {dt.module_name}
                          <br />
                          {intl.formatMessage({id: 'LABEL_SUB_MODULE'})}: {dt.site_name}
                          <br />
                          {intl.formatMessage({id: 'LABEL_MENU'})}: {dt.menu_name}
                        </Tooltip>
                      }
                    >
                      <li className='nav-item' key={`li-shortcut-menus-${dt.menu_id}`}>
                        <Link
                          className={`nav-link btn btn-sm fw-semibold fs-7 px-4 me-1 ${
                            currentMenuID === dt.menu_id
                              ? 'btn-active-color-primary btn-active-light active fw-bolder'
                              : 'btn-color-muted'
                          }`}
                          to={dt.path}
                          state={{
                            menu_id: dt.menu_id,
                            parent_menu_id: dt.parent_menu_id,
                            menu_name: dt.menu_name,
                            module_id: dt.module_id,
                            site_id: dt.site_id,
                            path: dt.path,
                          }}
                        >
                          {dt.menu_name}
                        </Link>
                      </li>
                    </Whisper>
                  </div>
                ))}
            </ul>
          </div>
        )}

        {/* Shortcut Menu */}
      </div>
      {/* Wrapper */}
    </div>
  )
}

export {ToolbarReports}
