/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {Whisper, Tooltip} from 'rsuite'

import {MenuComponent} from '_metronic/assets/ts/components'
import {KTIcon} from '_metronic/helpers'

import {ItemID} from '../../helpers'
import {useAction} from '../../providers'

type Props = {
  item_id: ItemID
  status: string
}

const ListActionFormatter: FC<Props> = ({item_id, status}) => {
  const intl = useIntl()
  const {setAction, setItemIdForUpdate, currentAccessMenu, setIsItemCategory} = useAction()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openModal = (action: any) => {
    setAction(action)
    setItemIdForUpdate(item_id)
    setIsItemCategory(false)
  }

  return (
    <>
      <a
        href='#'
        className='btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px'
        data-kt-menu-trigger='hover'
        data-kt-menu-placement='left'
      >
        &nbsp;
        <KTIcon iconName='dots-square-vertical' className='fs-2x' />
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-row menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-175px'
        data-kt-menu='true'
      >
        {/* View */}
        <div className='menu-item'>
          <Whisper
            placement='top'
            controlId='control-id-hover'
            trigger='hover'
            speaker={
              <Tooltip id='tooltip-user-name'>
                <span className='menu-title fw-bolder'>
                  {intl.formatMessage({id: 'BUTTON_LABEL_VIEW'})}
                </span>
              </Tooltip>
            }
          >
            <a href='#' className='menu-link' onClick={() => openModal('view')}>
              <span className='menu-icon' data-kt-element='icon'>
                <KTIcon iconName='magnifier' className='fs-2' />
              </span>
            </a>
          </Whisper>
        </div>
        {/* View */}

        {/* Edit */}
        {currentAccessMenu?.edit?.access_role === 'edit' && (
          <div className='menu-item'>
            <Whisper
              placement='top'
              controlId='control-id-hover'
              trigger='hover'
              speaker={
                <Tooltip id='tooltip-user-name'>
                  <span className='menu-title text-warning fw-bolder'>
                    {intl.formatMessage({id: 'BUTTON_LABEL_EDIT'})}
                  </span>
                </Tooltip>
              }
            >
              <a href='#' className='menu-link' onClick={() => openModal('edit')}>
                <span className='menu-icon' data-kt-element='icon'>
                  <KTIcon iconName='notepad-edit' className='fs-2 text-warning' />
                </span>
              </a>
            </Whisper>
          </div>
        )}
        {/* Edit */}

        {/* Delete */}
        {currentAccessMenu?.delete?.access_role === 'delete' && status === 'f' && (
          <div className='menu-item'>
            <Whisper
              placement='top'
              controlId='control-id-hover'
              trigger='hover'
              speaker={
                <Tooltip id='tooltip-user-name'>
                  <span className='menu-title text-danger fw-bolder'>
                    {intl.formatMessage({id: 'BUTTON_LABEL_DELETE'})}
                  </span>
                </Tooltip>
              }
            >
              <a href='#' className='menu-link' onClick={() => openModal('delete')}>
                <span className='menu-icon' data-kt-element='icon'>
                  <KTIcon iconName='tablet-delete' className='fs-2 text-danger' />
                </span>
              </a>
            </Whisper>
          </div>
        )}
        {/* Delete */}
      </div>
      {/* end::Menu */}
    </>
  )
}

export {ListActionFormatter}
