const inputPhoneNumbers = (input: any) => {
  var value = input
  var regex_cell = /[a-zA-Z]/
  var new_value = value.replace(regex_cell, '')

  return new_value
}

const inputPostalCode = (input: any) => {
  var value = input
  var regex_cell = /\D/g
  var new_value = value.replace(regex_cell, '')

  return new_value
}

const inputTrimSpace = (string: any) => {
  var value = string
  const new_value = value.replace(/ /g, '')

  return new_value
}

export {inputPhoneNumbers, inputPostalCode, inputTrimSpace}
