import {Dispatch, SetStateAction} from 'react'

import {CurrentMenuAccessModel} from 'app/context/models'

import {LogID} from '.'

export type ActionContextProps = {
  currentAccessMenu: CurrentMenuAccessModel | undefined
  setCurrentAccessMenu: Dispatch<SetStateAction<CurrentMenuAccessModel | undefined>>
  isLoading: boolean
  setIsLoading: Dispatch<SetStateAction<boolean>>
  showFilter: boolean
  setShowFilter: Dispatch<SetStateAction<boolean>>
  action: string
  setAction: Dispatch<SetStateAction<string>>
  itemIdForUpdate?: LogID
  setItemIdForUpdate: Dispatch<SetStateAction<LogID>>
  logActivity: string
  setLogActivity: Dispatch<SetStateAction<string>>
}

export const initialAction: ActionContextProps = {
  currentAccessMenu: undefined,
  setCurrentAccessMenu: () => {},
  isLoading: false,
  setIsLoading: () => {},
  showFilter: false,
  setShowFilter: () => {},
  action: '',
  setAction: () => {},
  setItemIdForUpdate: () => {},
  logActivity: '',
  setLogActivity: () => {},
}
