/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {Dialog} from '@headlessui/react'
import clsx from 'clsx'
import toast from 'react-hot-toast'
import {CheckPicker, Checkbox, Divider, Drawer, InputPicker, Toggle} from 'rsuite'
import * as CurrencyFormat from 'react-currency-format'
import {v4 as uuidv4} from 'uuid'

import {useThemeMode} from '_metronic/partials'
import {KTIcon} from '_metronic/helpers'
import {
  NotAuthRedirectForm,
  FormLog,
  getFormAction,
  SpinnerIndicator,
  UnderDevelopmentPage,
} from 'app/components'
import {getAuth, removeAuth, useAuth} from 'app/modules/auth'

import {
  DetailModel,
  addData,
  deleteData,
  fetchBrandOptions,
  fetchCompanyOptions,
  fetchHoldingOptions,
  fetchCategoriesOptions,
  initialData,
  updateData,
  fetchOutletOptions,
  fetchSalesTypeOptions,
  getCountCategoryItems,
  get,
} from '../../helpers'
import {useAction, useListQueryResponse} from '../../providers'

const footerStyles = {
  padding: '0px 2px',
  borderTop: '1px solid #e5e5e5',
}

type Props = {
  isLoading: boolean
  item: DetailModel
  appMenuID: any
  modalID: any
  menuName: any
}

function updateSalesType(
  formik: any,
  ppn: number | undefined,
  ppn_price: number | undefined,
  price: number | undefined,
  final_price: number | undefined,
  sku: string | undefined,
  basePPN: Number | undefined
) {
  let tmp_sales_type = formik.values.sales_type_price
  for (var i in tmp_sales_type) {
    tmp_sales_type[i].ppn = ppn === undefined ? basePPN : ppn
    tmp_sales_type[i].ppn_price = ppn_price === undefined ? 0 : ppn_price
    tmp_sales_type[i].price = price === undefined ? 0 : price
    tmp_sales_type[i].final_price = final_price === undefined ? 0 : final_price
    tmp_sales_type[i].sku = sku === undefined ? '' : sku
  }

  formik.setFieldValue('sales_type_price', tmp_sales_type)
}

function setSalesTypeVariantInclude(
  formik: any,
  sales_type_id: string | undefined,
  is_include: string,
  basePPN: Number | undefined
) {
  let tmp_sales_type = formik.values.sales_type_items
  for (var i in tmp_sales_type) {
    for (var j in tmp_sales_type[i].sales_type_price) {
      if (
        // tmp_sales_type[i].is_include === 'f' &&
        tmp_sales_type[i].sales_type_price[j].sales_type_id === sales_type_id
      ) {
        tmp_sales_type[i].sales_type_price[j].is_include = is_include
        tmp_sales_type[i].sales_type_price[j].sku = ''
        tmp_sales_type[i].sales_type_price[j].ppn = is_include === 't' ? basePPN : 0
        tmp_sales_type[i].sales_type_price[j].ppn_price = 0
        tmp_sales_type[i].sales_type_price[j].price = 0
        tmp_sales_type[i].sales_type_price[j].final_price = 0
      }
    }
  }

  formik.setFieldValue('sales_type_price', tmp_sales_type)
}

function setSalesTypeVariantSKU(
  formik: any,
  variant_id: string | undefined,
  sku: string | undefined
) {
  let tmp_sales_type = formik.values.sales_type_items
  for (var i in tmp_sales_type) {
    for (var j in tmp_sales_type[i].sales_type_price) {
      if (
        tmp_sales_type[i].is_include === 't' &&
        tmp_sales_type[i].sales_type_price[j].variant_id === variant_id
      ) {
        tmp_sales_type[i].sales_type_price[j].sku = sku
      }
    }
  }

  formik.setFieldValue('sales_type_price', tmp_sales_type)
}

function setSalesTypeNoVariantSKU(formik: any, sku: string | undefined) {
  let tmp_sales_type = formik.values.sales_type_items
  for (var i in tmp_sales_type) {
    for (var j in tmp_sales_type[i].sales_type_price) {
      tmp_sales_type[i].sales_type_price[j].sku = sku
    }
  }

  formik.setFieldValue('sales_type_price', tmp_sales_type)
}

const ViewDrawerForm: FC<Props> = ({menuName, item, isLoading, appMenuID, modalID}) => {
  const auth = getAuth()
  const intl = useIntl()
  const {mode} = useThemeMode()
  const [tab, setTab] = useState('general')
  const [tabAdd, setTabAdd] = useState('general')
  const {currentUser} = useAuth()
  const {setItemIdForUpdate, action, categoryName, setCategoryName, itemName, setItemName} =
    useAction()
  const didRequestHolding = useRef(false)
  const didRequestCompany = useRef(false)
  const didRequestBrand = useRef(false)
  const didRequestCategories = useRef(false)
  const didRequestOutlet = useRef(false)
  const didRequestSalesType = useRef(false)
  const didRequestCategoryItems = useRef(false)
  const {refetch} = useListQueryResponse()
  const [isAuth, setIsAuth] = useState(true)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [isDialogVariantOpen, setIsDialogVariantOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [completed, setCompleted] = useState(false)
  const [holdingOptions, setHoldingOptions] = useState(undefined)
  const [companyOptions, setCompanyOptions] = useState(undefined)
  const [brandOptions, setBrandOptions] = useState(undefined)
  const [categoriesOptions, setCategoriesOptions] = useState(undefined)
  const [outletOptions, setOutletOptions] = useState(undefined)
  const [salesTypeOptions, setSalesTypeOptions] = useState(undefined)
  const [basePPN, setBasePPN] = useState<Number>(
    process.env.REACT_APP_BASE_PPN_PERCENT ? Number(process.env.REACT_APP_BASE_PPN_PERCENT) : 11
  )
  const [addVariantHasError, setAddVariantHasError] = useState('')
  const optionsHolding =
    holdingOptions === undefined
      ? []
      : (holdingOptions as unknown as any[]).map((holdings: any) => ({
          value: holdings.holding_id,
          label: holdings.name,
        }))
  const optionsCompany =
    companyOptions === undefined
      ? []
      : (companyOptions as unknown as any[]).map((company: any) => ({
          value: company.company_id,
          label: company.name,
        }))
  const optionsBrand =
    brandOptions === undefined
      ? []
      : (brandOptions as unknown as any[]).map((brand: any) => ({
          value: brand.brand_id,
          label: brand.name,
        }))
  const optionsCategories =
    categoriesOptions === undefined
      ? []
      : (categoriesOptions as unknown as any[]).map((category: any) => ({
          value: category.category_id,
          label: category.name,
        }))
  const optionsOutlet =
    outletOptions === undefined
      ? []
      : (outletOptions as unknown as any[]).map((outlet: any) => ({
          value: outlet.outlet_id,
          label: outlet.name,
        }))
  const optionsSalesType =
    salesTypeOptions === undefined
      ? []
      : (salesTypeOptions as unknown as any[]).map((sales_type: any) => ({
          sales_type_id: sales_type.sales_type_id,
          variant_id: sales_type.variant_id,
          is_include: 't',
          name: sales_type.name,
          price: 0.0,
          ppn: basePPN,
          ppn_price: 0.0,
          final_price: 0.0,
          sku: '',
        }))

  const baseValidation = Yup.object().shape({
    name: Yup.string()
      .min(3, intl.formatMessage({id: 'VALIDATION_MIN_LENGTH_FIELD'}, {min: 3}))
      .max(125, intl.formatMessage({id: 'VALIDATION_MAX_LENGTH_FIELD'}, {max: 125}))
      .required(
        intl.formatMessage(
          {id: 'VALIDATION_REQUIRED_FIELD'},
          {name: intl.formatMessage({id: 'LABEL_NAME'})}
        )
      ),
    for_order: Yup.string().required(
      intl.formatMessage(
        {id: 'VALIDATION_REQUIRED_FIELD'},
        {name: intl.formatMessage({id: 'LABEL_ORDER'})}
      )
    ),
  })

  const [models] = useState<DetailModel>({
    ...item,
    item_id: item.item_id || initialData.item_id,
    category_id: item.category_id || initialData.category_id,
    category_name: item.category_name || initialData.category_name,
    holding_id: item.holding_id || initialData.holding_id,
    holding_name: item.holding?.name || initialData.holding?.name,
    company_id: item.company_id || initialData.company_id,
    company_name: item.company?.name || initialData.company?.name,
    brand_id: item.brand_id || initialData.brand_id,
    brand_name: item.brand?.name || initialData.brand?.name,
    outlet_id: action === 'add' ? [] : item.outlet?.map((dt) => dt.outlet_id),
    outlet_name: action === 'add' ? [] : item.outlet?.map((dt) => dt.name),
    holding: item.holding,
    company: item.company,
    brand: item.brand,
    outlet: item.outlet,
    sales_type_price: item.sales_type_price || initialData.sales_type_price,
    sales_type_items: item.sales_type_items || initialData.sales_type_items,
    name: item.name || initialData.name,
    is_multiple_price_sales_type:
      item.is_multiple_price_sales_type || initialData.is_multiple_price_sales_type,
    is_default_parent_category:
      item.is_default_parent_category || initialData.is_default_parent_category,
    price: item.price || initialData.price,
    ppn: item.ppn || initialData.ppn,
    ppn_price: item.ppn_price || initialData.ppn_price,
    final_price: item.final_price || initialData.final_price,
    sku: item.sku || initialData.sku,
    is_lifetime: item.is_lifetime || initialData.is_lifetime,
    start_period: item.start_period || initialData.start_period,
    end_period: item.end_period || initialData.end_period,
    start_hour: item.start_hour || initialData.start_hour,
    end_hour: item.end_hour || initialData.end_hour,
    days: item.days || initialData.days,
    for_order: item.for_order || initialData.for_order,
    is_default: item.is_default || initialData.is_default,
    has_variant: item.has_variant || initialData.has_variant,
    status: item.status || initialData.status,
    variant_items: item.variant_items || initialData.variant_items,
    tmp_variant_items: item.tmp_variant_items || initialData.tmp_variant_items,
    description: item.description || initialData.description,
    created_at: item.created_at || initialData.created_at,
    created_by: item.created_by || initialData.created_by,
    updated_at: item.updated_at || initialData.updated_at,
    updated_by: item.updated_by || initialData.updated_by,
    menu_id: appMenuID,
    creator_id: currentUser?.user_id,
    creator_by: currentUser?.username,
  })

  const formik = useFormik({
    initialValues: models,
    validationSchema: action !== 'delete' ? baseValidation : undefined,
    onSubmit: async (values, {setSubmitting, setStatus, setFieldError}) => {
      setIsDialogOpen(false)
      action === 'edit'
        ? requestUpdateData(values, setStatus, setSubmitting, setFieldError)
        : action === 'add'
          ? requestAddData(values, setStatus, setSubmitting, setFieldError)
          : requestDeleteData(values, setStatus, setSubmitting)
    },
    onReset: async (values) => {
      values = models
      if (action === 'add') {
        values.tmp_variant_items = []
        values.variant_items = []
        values.sales_type_price = []
        values.sales_type_items = []

        setTabAdd('general')
      } else {
        const freshItem = await get(formik.values.item_id)
        const tmpBaseVariantItems = freshItem && freshItem.data.variant_items
        const tmpBaseSalesTypeItems = freshItem && freshItem.data.sales_type_items

        values.variant_items = tmpBaseVariantItems
        values.sales_type_items = tmpBaseSalesTypeItems

        setTab('general')
      }

      setBasePPN(
        process.env.REACT_APP_BASE_PPN_PERCENT ? Number(process.env.REACT_APP_BASE_PPN_PERCENT) : 11
      )
    },
  })

  const requestUpdateData = async (
    values: any,
    setStatus: any,
    setSubmitting: any,
    setFieldError: any
  ) => {
    setLoading(true)
    setStatus(null)
    setSubmitting(true)

    // delete unused field
    delete values.tmp_variant_items
    delete values.sales_type_price
    delete values.items_outlet
    delete values.items_price

    // fix has variant
    values.has_variant = values.variant_items.length > 0 ? 't' : 'f'

    // fix ppn and price type
    values.ppn = parseFloat(values.ppn)
    values.ppn_price = parseInt(values.ppn_price)
    values.price = parseFloat(values.price)
    values.final_price = parseInt(values.final_price)

    const newSalesTypeItems = (values.sales_type_items as unknown as []).map((dt: any) => ({
      sales_type_id: dt.sales_type_id,
      is_include: dt.is_include,
      name: dt.name,
      sales_type_price: (dt.sales_type_price as unknown as []).map((dtDetails: any) => ({
        sales_type_id: dtDetails.sales_type_id,
        variant_id: dtDetails.variant_id,
        is_include: dtDetails.is_include,
        ppn: parseFloat(dtDetails.ppn),
        price: parseFloat(dtDetails.price),
        ppn_price: parseInt(dtDetails.ppn_price),
        final_price: parseInt(dtDetails.final_price),
        name: dtDetails.name,
        sku: dtDetails.sku,
      })),
    }))
    values.sales_type_items = newSalesTypeItems

    // request update to API
    try {
      // fix number
      values.for_order = parseInt(values.for_order)
      const res = await updateData(values)

      // throw message error
      if (res?.meta.code !== 200) {
        if (res?.meta.message === 'name already used') {
          setStatus(intl.formatMessage({id: 'RESPONSE_LIBRARY_ITEM_NAME_ALREADY_USED'}))
          setFieldError('name', intl.formatMessage({id: 'RESPONSE_LIBRARY_ITEM_NAME_ALREADY_USED'}))
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_LIBRARY_ITEM_EDIT_SUCCESS'}))
        setCompleted(true)
        setTimeout(function () {
          setItemIdForUpdate(undefined)
          refetch()
        }, 2e3)
      }
    } catch (error: any) {
      console.error(error)
      if (error.response.status === 401) {
        setStatus(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
        removeAuth()
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
      }
      setSubmitting(false)
      setLoading(false)
      setTimeout(function () {
        setStatus(null)
      }, 9e3)

      return false
    } finally {
      setTimeout(() => {
        setLoading(false)
        setSubmitting(false)
      }, 5e2)
    }
  }
  const requestAddData = async (
    values: any,
    setStatus: any,
    setSubmitting: any,
    setFieldError: any
  ) => {
    setLoading(true)
    setStatus(null)
    setSubmitting(true)

    // delete unused field
    delete values.tmp_variant_items
    delete values.sales_type_price

    // fix has variant
    values.has_variant = values.variant_items.length > 0 ? 't' : 'f'

    // fix ppn and price type
    values.ppn = parseFloat(values.ppn)
    values.ppn_price = parseInt(values.ppn_price)
    values.price = parseFloat(values.price)
    values.final_price = parseInt(values.final_price)

    const newSalesTypeItems = (values.sales_type_items as unknown as []).map((dt: any) => ({
      sales_type_id: dt.sales_type_id,
      is_include: dt.is_include,
      name: dt.name,
      sales_type_price: (dt.sales_type_price as unknown as []).map((dtDetails: any) => ({
        sales_type_id: dtDetails.sales_type_id,
        variant_id: dtDetails.variant_id,
        is_include: dtDetails.is_include,
        ppn: parseFloat(dtDetails.ppn),
        price: parseFloat(dtDetails.price),
        ppn_price: parseInt(dtDetails.ppn_price),
        final_price: parseInt(dtDetails.final_price),
        name: dtDetails.name,
        sku: dtDetails.sku,
      })),
    }))
    values.sales_type_items = newSalesTypeItems

    // request add to API
    try {
      const res = await addData(values)

      // throw message error
      if (res?.meta.code !== 200) {
        if (res?.meta.message === 'name already used') {
          setStatus(intl.formatMessage({id: 'RESPONSE_LIBRARY_CATEGORIES_NAME_ALREADY_USED'}))
          setFieldError(
            'name',
            intl.formatMessage({id: 'RESPONSE_LIBRARY_CATEGORIES_NAME_ALREADY_USED'})
          )
          setTabAdd('general')
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_LIBRARY_ITEM_ADD_SUCCESS'}))
        setCompleted(true)
        setTimeout(function () {
          setItemIdForUpdate(undefined)
          refetch()
        }, 2e3)
      }
    } catch (error: any) {
      console.error(error)
      if (error.response.status === 401) {
        setStatus(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
        removeAuth()
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
      }
      setSubmitting(false)
      setLoading(false)
      setTimeout(function () {
        setStatus(null)
      }, 9e3)

      return false
    } finally {
      setTimeout(() => {
        setLoading(false)
        setSubmitting(false)
      }, 5e2)
    }
  }
  const requestDeleteData = async (values: any, setStatus: any, setSubmitting: any) => {
    setLoading(true)
    setStatus(null)
    setSubmitting(true)

    // request delete to API
    try {
      const res = await deleteData(
        values.item_id,
        values.menu_id,
        values.creator_id,
        values.creator_by
      )

      // throw message error
      if (res?.meta.code !== 200) {
        if (res?.meta.message === 'not inactive') {
          setStatus(intl.formatMessage({id: 'RESPONSE_LIBRARY_ITEM_IS_ACTIVE'}))
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
        if (res?.meta.message === 'inused by other data') {
          setStatus(intl.formatMessage({id: 'RESPONSE_LIBRARY_ITEM_IS_INUSED'}))
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_LIBRARY_ITEM_DELETE_SUCCESS'}))
        setCompleted(true)
        setTimeout(function () {
          setItemIdForUpdate(undefined)
          refetch()
        }, 2e3)
      }
    } catch (error: any) {
      console.error(error)
      if (error.response.status === 401) {
        setStatus(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
        removeAuth()
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
      }
      setSubmitting(false)
      setLoading(false)
      setTimeout(function () {
        setStatus(null)
      }, 9e3)

      return false
    } finally {
      setTimeout(() => {
        setLoading(false)
        setSubmitting(false)
      }, 5e2)
    }
  }

  useEffect(() => {
    auth === undefined && setIsAuth(false)
  }, [auth])

  useEffect(() => {
    const requestHoldingOptions = async () => {
      try {
        if (!didRequestHolding.current) {
          const options = await fetchHoldingOptions('input')
          // throw message error
          if (options.data.meta.code === 200) {
            // set option
            setHoldingOptions(options.data.data)
          }
        }
      } catch (error: any) {
        console.error(error)
        if (error.response.status === 401) {
          toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
          removeAuth()
        } else {
          toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
        }
      } finally {
      }

      return () => (didRequestHolding.current = true)
    }

    requestHoldingOptions()

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const requestCompanyOptions = async () => {
      try {
        if (!didRequestCompany.current) {
          const options = await fetchCompanyOptions('input', formik.values.holding_id)
          // throw message error
          if (options.data.meta.code === 200) {
            // set options
            setCompanyOptions(options.data.data)
          }
        }
      } catch (error: any) {
        console.error(error)
        if (error.response.status === 401) {
          toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
          removeAuth()
        } else {
          toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
        }
      } finally {
      }

      return () => (didRequestCompany.current = true)
    }

    formik.values.holding_id !== '' ? requestCompanyOptions() : setCompanyOptions(undefined)

    // eslint-disable-next-line
  }, [formik.values.holding_id])

  useEffect(() => {
    const requestBrandOptions = async () => {
      try {
        if (!didRequestBrand.current) {
          const options = await fetchBrandOptions('input', formik.values.company_id)
          // throw message error
          if (options.data.meta.code === 200) {
            // set options
            setBrandOptions(options.data.data)
          }
        }
      } catch (error: any) {
        console.error(error)
        if (error.response.status === 401) {
          toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
          removeAuth()
        } else {
          toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
        }
      } finally {
      }

      return () => (didRequestBrand.current = true)
    }

    formik.values.company_id !== '' ? requestBrandOptions() : setBrandOptions(undefined)

    // eslint-disable-next-line
  }, [formik.values.company_id])

  useEffect(() => {
    const categoriesOptions = async () => {
      try {
        if (!didRequestCategories.current) {
          const options = await fetchCategoriesOptions('input', formik.values.brand_id)
          // throw message error
          if (options.data.meta.code === 200) {
            // set options
            setCategoriesOptions(options.data.data)
            formik.setFieldValue('sales_type_price', optionsSalesType)
          }
        }
      } catch (error: any) {
        console.error(error)
        if (error.response.status === 401) {
          toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
          removeAuth()
        } else {
          toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
        }
      } finally {
      }

      return () => (didRequestCategories.current = true)
    }
    const outletOptions = async () => {
      try {
        if (!didRequestOutlet.current) {
          const options = await fetchOutletOptions('input', formik.values.brand_id)
          // throw message error
          if (options.data.meta.code === 200) {
            // set options
            setOutletOptions(options.data.data)
          }
        }
      } catch (error: any) {
        console.error(error)
        if (error.response.status === 401) {
          toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
          removeAuth()
        } else {
          toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
        }
      } finally {
      }

      return () => (didRequestOutlet.current = true)
    }
    const salesTypeOptions = async () => {
      try {
        if (!didRequestSalesType.current) {
          const options = await fetchSalesTypeOptions('input', formik.values.brand_id)
          // throw message error
          if (options.data.meta.code === 200) {
            // set options
            setSalesTypeOptions(options.data.data)
          }
        }
      } catch (error: any) {
        console.error(error)
        if (error.response.status === 401) {
          toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
          removeAuth()
        } else {
          toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
        }
      } finally {
      }

      return () => (didRequestSalesType.current = true)
    }

    if (formik.values.brand_id !== '') {
      categoriesOptions()
      outletOptions()
      salesTypeOptions()
    } else {
      setCategoriesOptions(undefined)
      setOutletOptions(undefined)
      setSalesTypeOptions(undefined)
    }

    // eslint-disable-next-line
  }, [formik.values.brand_id])

  useEffect(() => {
    const requestCountCategoryItems = async () => {
      try {
        if (!didRequestCategoryItems.current) {
          const options = await getCountCategoryItems(formik.values.category_id)
          // throw message error
          if (options.data.meta.code === 200) {
            // set for_order values
            formik.setFieldValue('for_order', options.data.data.count)
          }
        }
      } catch (error: any) {
        console.error(error)
        if (error.response.status === 401) {
          toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
          removeAuth()
        } else {
          toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
        }
      } finally {
      }

      return () => (didRequestCategoryItems.current = true)
    }

    if (action === 'add') {
      formik.values.category_id !== ''
        ? requestCountCategoryItems()
        : formik.setFieldValue('for_order', 1)
    }

    // eslint-disable-next-line
  }, [formik.values.category_id])

  useEffect(() => {
    if (action === 'add') {
      if (salesTypeOptions && formik.values.is_multiple_price_sales_type === 't') {
        if (formik.values.variant_items && formik.values.variant_items?.length > 0) {
          let dt = (formik.values.variant_items as unknown as any).map((e: any) => ({
            variant_id: e.variant_id,
            name: e.name,
            sku: e.sku,
          }))

          let dtDetails = (salesTypeOptions as unknown as any).map((e: any) => ({
            sales_type_id: e.sales_type_id,
            is_include: 't',
            name: e.name,
            sales_type_price: dt.map((f: any) => ({
              sales_type_id: e.sales_type_id,
              is_include: 't',
              variant_id: f.variant_id,
              price: 0,
              ppn: basePPN,
              ppn_price: 0,
              final_price: 0,
              name: f.name,
              sku: f.sku,
            })),
          }))

          formik.setFieldValue('sales_type_items', dtDetails)
        } else {
          let dtDetails = (salesTypeOptions as unknown as any).map((e: any) => ({
            sales_type_id: e.sales_type_id,
            is_include: 't',
            name: e.name,
            sales_type_price: [
              {
                sales_type_id: e.sales_type_id,
                is_include: 't',
                variant_id: '',
                price: 0,
                ppn: basePPN,
                ppn_price: 0,
                final_price: 0,
                name: '',
                sku: '',
              },
            ],
          }))

          formik.setFieldValue('sales_type_items', dtDetails)
        }
      } else {
        formik.setFieldValue('sku', '')
        formik.setFieldValue('price', 0)
        formik.setFieldValue('final_price', 0)
        formik.setFieldValue('ppn_price', 0)
        formik.setFieldValue('ppn', basePPN)
        formik.setFieldValue('sales_type_items', [])
      }
    }

    // eslint-disable-next-line
  }, [formik.values.is_multiple_price_sales_type])

  useEffect(() => {
    formik.values.category_name && setCategoryName(formik.values.category_name)

    // eslint-disable-next-line
  }, [formik.values.category_name])

  useEffect(() => {
    formik.values.name && setItemName(formik.values.name)

    // eslint-disable-next-line
  }, [formik.values.name])

  const initiateAddTMPVariant = async () => {
    formik.setFieldValue('tmp_variant_items', formik.values.variant_items)

    // fix multiple sales type no variant
    if (action === 'add') {
      if (formik.values.sales_type_items && formik.values.sales_type_items.length > 0) {
        let tmpSalesTypeItems = formik.values.sales_type_items
        for (var i in tmpSalesTypeItems) {
          tmpSalesTypeItems[i].sales_type_price = []
        }
      }
    }
  }

  const addTMPVariant = async () => {
    let tmpVariants = formik.values.tmp_variant_items ? formik.values.tmp_variant_items : []
    tmpVariants.push({name: '', sku: '', variant_id: uuidv4()})

    formik.setFieldValue('tmp_variant_items', tmpVariants)
  }

  const saveTMPVariant = async () => {
    let tmpVariants = formik.values.tmp_variant_items ? formik.values.tmp_variant_items : []
    tmpVariants.map((dt: any) => ({
      variant_id: dt.variant_id,
      name: dt.name,
      sku: dt.sku,
    }))

    formik.setFieldValue('tmp_variant_items', [])
    formik.setFieldValue('variant_items', tmpVariants)

    // update sales type items
    if (formik.values.sales_type_items && formik.values.sales_type_items.length > 0) {
      let tmpSalesTypeItems = formik.values.sales_type_items
      for (var i in tmpSalesTypeItems) {
        for (var j in tmpSalesTypeItems[i].sales_type_price) {
          for (var k in tmpVariants) {
            // update each sku & name
            if (tmpSalesTypeItems[i].sales_type_price[j].variant_id === tmpVariants[k].variant_id) {
              tmpSalesTypeItems[i].sales_type_price[j].sku = tmpVariants[k].sku
              tmpSalesTypeItems[i].sales_type_price[j].name = tmpVariants[k].name
            }
          }
        }

        // add new variant
        if (tmpVariants.length > tmpSalesTypeItems[i].sales_type_price.length) {
          for (let l = tmpSalesTypeItems[i].sales_type_price.length; l < tmpVariants.length; l++) {
            tmpSalesTypeItems[i].sales_type_price.push({
              sales_type_id: tmpSalesTypeItems[i].sales_type_id,
              variant_id: tmpVariants[l].variant_id,
              is_include: tmpSalesTypeItems[i].is_include,
              name: tmpVariants[l].name,
              sku: tmpVariants[l].sku,
              price: 0.0,
              ppn: process.env.REACT_APP_BASE_PPN_PERCENT
                ? Number(process.env.REACT_APP_BASE_PPN_PERCENT)
                : 11,
              ppn_price: 0.0,
              final_price: 0.0,
            })
          }
        }
      }
    }
  }

  const deleteTMPVariant = async (index: number, variant_id: string | undefined) => {
    let tmpVariants = formik.values.tmp_variant_items ? formik.values.tmp_variant_items : []
    tmpVariants.splice(index, 1)

    formik.setFieldValue('tmp_variant_items', tmpVariants)

    // delete sales type items
    // let tmpSalesTypeItems = formik.values.sales_type_items
    if (formik.values.sales_type_items) {
      if (formik.values.sales_type_items.length > 0) {
        for (var i in formik.values.sales_type_items) {
          for (var j in formik.values.sales_type_items[i].sales_type_price) {
            // delete variant by variant id
            if (formik.values.sales_type_items[i].sales_type_price[j].variant_id === variant_id) {
              formik.values.sales_type_items[i].sales_type_price.splice(parseInt(j), 1)
            }
          }
        }
      }
    }
  }

  return (
    <>
      {!isAuth && <NotAuthRedirectForm />}

      <Drawer.Header>
        <Drawer.Title className='fw-bolder'>
          {isLoading ? (
            <SpinnerIndicator label={intl.formatMessage({id: 'BUTTON_LABEL_PLEASE_WAIT'})} />
          ) : (
            <>
              {getFormAction(
                action,
                intl.formatMessage({id: 'BUTTON_LABEL_VIEW'}),
                intl.formatMessage({id: 'BUTTON_LABEL_EDIT'}),
                intl.formatMessage({id: 'BUTTON_LABEL_ADD'}),
                intl.formatMessage({id: 'BUTTON_LABEL_DELETE'})
              )}{' '}
              {menuName}
              {' - '}
              {categoryName}
              {' - '}
              {itemName}
            </>
          )}
        </Drawer.Title>
        <Drawer.Actions>
          {action !== 'view' && action !== 'delete' && models !== formik.values && !completed && (
            <button
              type='reset'
              className='btn btn-light me-3'
              data-kt-users-modal-action='reset'
              onClick={formik.handleReset}
              disabled={loading}
            >
              {!loading && (
                <span className='indicator-label'>
                  {intl.formatMessage({id: 'BUTTON_LABEL_RESET'})}
                </span>
              )}
            </button>
          )}

          {action !== 'view' && action !== 'delete' && models !== formik.values && !completed && (
            <button
              type='submit'
              className='btn btn-primary'
              data-kt-users-modal-action='submit'
              onClick={() => {
                if (formik.values.holding_id === '') {
                  formik.setFieldError('holding_id', 'validation errors')
                  setTabAdd('general')
                  return false
                }
                if (formik.values.company_id === '') {
                  formik.setFieldError('company_id', 'validation errors')
                  setTabAdd('general')
                  return false
                }
                if (formik.values.brand_id === '') {
                  formik.setFieldError('brand_id', 'validation errors')
                  setTabAdd('general')
                  return false
                }
                if (formik.values.outlet_id?.toString().replaceAll(',', ', ') === '') {
                  formik.setFieldError('outlet_id', 'validation errors')
                  setTabAdd('general')
                  return false
                }
                if (formik.values.category_id === '') {
                  formik.setFieldError(
                    'is_default_parent_category.category_id',
                    'validation errors'
                  )
                  setTabAdd('general')
                  return false
                }
                // is multiple price sales type
                if (formik.values.is_multiple_price_sales_type === 't') {
                  let tmp_sales_type = formik.values.sales_type_items
                  let countInclude = 0

                  for (var j in tmp_sales_type) {
                    if (tmp_sales_type && tmp_sales_type[Number(j)].is_include === 't') {
                      countInclude = countInclude + 1
                    }
                  }

                  // is include
                  if (countInclude === 0) {
                    formik.setStatus(
                      intl.formatMessage({id: 'RESPONSE_LIBRARY_ITEM_IS_INCLUDE_VALIDATION'})
                    )
                    setTimeout(function () {
                      formik.setStatus(null)
                    }, 5e3)
                    setTabAdd('pricing')
                    return false
                  }
                }

                // is default
                if (formik.values.is_default === 't') {
                  if (
                    formik.values.is_default_parent_category &&
                    formik.values.is_default_parent_category.category_id === ''
                  ) {
                    formik.setFieldError('is_default_parent_category', 'validation errors')
                    setTabAdd('general')
                    return false
                  }
                }
                setIsDialogOpen(true)
                setTabAdd('general')
                setLoading(true)
                setTimeout(function () {
                  setLoading(false)
                }, 2e3)
              }}
              disabled={isLoading || loading || formik.isSubmitting || !formik.isValid}
            >
              {!loading && (
                <span className='indicator-label'>
                  {intl.formatMessage({id: 'BUTTON_LABEL_SUBMIT'})}
                </span>
              )}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {intl.formatMessage({id: 'BUTTON_LABEL_SUBMITTING'})}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          )}

          {action === 'delete' && !completed && (
            <button
              type='submit'
              className='btn btn-danger'
              data-kt-users-modal-action='submit'
              onClick={() => formik.handleSubmit()}
              disabled={isLoading || loading || formik.isSubmitting || !formik.isValid}
            >
              {!loading && (
                <span className='indicator-label'>
                  {intl.formatMessage({id: 'BUTTON_LABEL_DELETE'})}
                </span>
              )}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {intl.formatMessage({id: 'BUTTON_LABEL_SUBMITTING'})}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          )}
        </Drawer.Actions>
      </Drawer.Header>

      <Drawer.Body style={{padding: 0}}>
        {/* Response Message */}
        {formik.status &&
          (formik.status === intl.formatMessage({id: 'RESPONSE_LIBRARY_ITEM_ADD_SUCCESS'}) ||
          formik.status === intl.formatMessage({id: 'RESPONSE_LIBRARY_ITEM_EDIT_SUCCESS'}) ||
          formik.status === intl.formatMessage({id: 'RESPONSE_LIBRARY_ITEM_DELETE_SUCCESS'}) ? (
            <div className='m-lg-5 p-2 bg-light-success rounded'>
              <div className='text-success text-center font-weight-bold fw-bolder'>
                {formik.status}
              </div>
            </div>
          ) : (
            <div className={`m-lg-5 p-2 alert alert-danger`}>
              <div className='alert-text text-center font-weight-bold fw-bolder'>
                {formik.status}
              </div>
            </div>
          ))}
        {/* Response Message */}

        {/* Form */}
        <div className={`modal-body m-5 mt-0`}>
          {action === 'add' ? (
            <form id={`${modalID}-form`} className='form' onSubmit={formik.handleSubmit} noValidate>
              {/* Tab Header */}
              <div className='card-toolbar'>
                <ul
                  className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-2 mt-2'
                  role='tablist'
                >
                  <li className={`nav-item ${tabAdd === 'general' ? 'fw-bolder' : ''}`}>
                    <a
                      className={clsx(`nav-link cursor-pointer`, {
                        active: tabAdd === 'general',
                      })}
                      onClick={() => setTabAdd('general')}
                      role='tab'
                    >
                      {intl.formatMessage({id: 'LABEL_GENERAL_INFORMATION'})}
                    </a>
                  </li>
                  <li className={`nav-item ${tabAdd === 'pricing' ? 'fw-bolder' : ''}`}>
                    <a
                      className={clsx(`nav-link cursor-pointer`, {
                        active: tabAdd === 'pricing',
                      })}
                      onClick={() =>
                        formik.values.category_id === ''
                          ? setTabAdd('general')
                          : setTabAdd('pricing')
                      }
                      role='tab'
                    >
                      {intl.formatMessage({id: 'LABEL_PRICING'})}
                    </a>
                  </li>
                  <li className={`nav-item ${tabAdd === 'period' ? 'fw-bolder' : ''}`}>
                    <a
                      className={clsx(`nav-link cursor-pointer`, {
                        active: tabAdd === 'period',
                      })}
                      onClick={() =>
                        formik.values.category_id === ''
                          ? setTabAdd('general')
                          : setTabAdd('period')
                      }
                      role='tab'
                    >
                      {intl.formatMessage({id: 'LABEL_PERIOD'})}
                    </a>
                  </li>
                </ul>
              </div>
              {/* Tab Header */}

              {/* Tab Body */}
              <div className='card-body p-0 pt-2'>
                <div className='tab-content'>
                  {/* General Information */}
                  <div
                    className={clsx('tab-pane', {
                      active: tabAdd === 'general',
                    })}
                  >
                    <div className='row'>
                      {/* First Col */}
                      <div className='col-lg-6'>
                        {/* Holding */}
                        <div className='fv-row mb-5'>
                          {formik.values.holding_id !== '' && (
                            <label className='form-label fs-6 fw-bolder text-dark required'>
                              Holding
                            </label>
                          )}
                          <InputPicker
                            block
                            data={optionsHolding}
                            placeholder={`-- Holding --`}
                            defaultValue={
                              optionsHolding
                                ? (optionsHolding as unknown as any[]).find(
                                    (e: any) => e.value === formik.values.holding_id
                                  )
                                : ''
                            }
                            onChange={(e) => {
                              formik.setFieldValue('holding_id', e === null ? '' : e)
                              formik.values.company_id = item.company_id
                              formik.values.company_name = item.company_name
                              formik.values.brand_id = item.brand_id
                              formik.values.brand_name = item.brand_name
                              formik.values.outlet_id = item.outlet_id
                              formik.values.outlet_name = item.outlet_name
                              formik.values.category_id = item.category_id
                              formik.values.category_name = item.category_name
                              formik.setFieldValue('company_id', '')
                              formik.setFieldValue('company_name', '')
                              formik.setFieldValue('brand_id', '')
                              formik.setFieldValue('brand_name', '')
                              formik.setFieldValue('outlet_id', [])
                              formik.setFieldValue('outlet_name', [])
                              formik.setFieldValue('category_id', '')
                              formik.setFieldValue('category_name', '')
                            }}
                            onSelect={(val, itm) => {
                              formik.setFieldValue('holding_name', val === null ? '' : itm.label)
                              formik.values.company_id = item.company_id
                              formik.values.company_name = item.company_name
                              formik.values.brand_id = item.brand_id
                              formik.values.brand_name = item.brand_name
                              formik.values.outlet_id = item.outlet_id
                              formik.values.outlet_name = item.outlet_name
                              formik.values.category_id = item.category_id
                              formik.values.category_name = item.category_name
                              formik.setFieldValue('company_id', '')
                              formik.setFieldValue('company_name', '')
                              formik.setFieldValue('brand_id', '')
                              formik.setFieldValue('brand_name', '')
                              formik.setFieldValue('outlet_id', [])
                              formik.setFieldValue('outlet_name', [])
                              formik.setFieldValue('category_id', '')
                              formik.setFieldValue('category_name', '')
                            }}
                            value={formik.values.holding_id}
                          />
                          {formik.errors.holding_id ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {intl.formatMessage(
                                  {id: 'VALIDATION_REQUIRED_FIELD'},
                                  {
                                    name: 'Holding',
                                  }
                                )}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Holding */}

                        {/* Company */}
                        <div className='fv-row mb-5'>
                          {formik.values.company_id !== '' && (
                            <label className='form-label fs-6 fw-bolder text-dark required'>
                              {intl.formatMessage({id: 'LABEL_COMPANY'})}
                            </label>
                          )}
                          <InputPicker
                            block
                            data={optionsCompany}
                            disabled={formik.values.holding_id === '' ? true : false}
                            placeholder={`-- ${intl.formatMessage({id: 'LABEL_COMPANY'})} --`}
                            defaultValue={
                              optionsCompany
                                ? (optionsCompany as unknown as any[]).find(
                                    (e: any) => e.value === formik.values.company_id
                                  )
                                : ''
                            }
                            onChange={(e) => {
                              formik.setFieldValue('company_id', e === null ? '' : e)
                              formik.values.brand_id = item.brand_id
                              formik.values.brand_name = item.brand_name
                              formik.values.outlet_id = item.outlet_id
                              formik.values.outlet_name = item.outlet_name
                              formik.values.category_id = item.category_id
                              formik.values.category_name = item.category_name
                              formik.setFieldValue('brand_id', '')
                              formik.setFieldValue('brand_name', '')
                              formik.setFieldValue('outlet_id', [])
                              formik.setFieldValue('outlet_name', [])
                              formik.setFieldValue('category_id', '')
                              formik.setFieldValue('category_name', '')
                            }}
                            onSelect={(val, itm) => {
                              formik.setFieldValue('company_name', val === null ? '' : itm.label)
                              formik.values.brand_id = item.brand_id
                              formik.values.brand_name = item.brand_name
                              formik.values.outlet_id = item.outlet_id
                              formik.values.outlet_name = item.outlet_name
                              formik.values.category_id = item.category_id
                              formik.values.category_name = item.category_name
                              formik.setFieldValue('brand_id', '')
                              formik.setFieldValue('brand_name', '')
                              formik.setFieldValue('outlet_id', [])
                              formik.setFieldValue('outlet_name', [])
                              formik.setFieldValue('category_id', '')
                              formik.setFieldValue('category_name', '')
                            }}
                            value={formik.values.company_id}
                          />
                          {formik.errors.company_id ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {intl.formatMessage(
                                  {id: 'VALIDATION_REQUIRED_FIELD'},
                                  {
                                    name: intl.formatMessage({id: 'LABEL_COMPANY'}),
                                  }
                                )}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Company */}

                        {/* Brand */}
                        <div className='fv-row mb-5'>
                          {formik.values.brand_id !== '' && (
                            <label className='form-label fs-6 fw-bolder text-dark required'>
                              {intl.formatMessage({id: 'LABEL_BRAND'})}
                            </label>
                          )}
                          <InputPicker
                            block
                            data={optionsBrand}
                            disabled={formik.values.company_id === '' ? true : false}
                            placeholder={`-- ${intl.formatMessage({id: 'LABEL_BRAND'})} --`}
                            defaultValue={
                              optionsBrand
                                ? (optionsBrand as unknown as any[]).find(
                                    (e: any) => e.value === formik.values.brand_id
                                  )
                                : ''
                            }
                            onChange={(e) => {
                              formik.setFieldValue('brand_id', e === null ? '' : e)
                              formik.values.outlet_id = item.outlet_id
                              formik.values.outlet_name = item.outlet_name
                              formik.values.category_id = item.category_id
                              formik.values.category_name = item.category_name
                              formik.setFieldValue('outlet_id', [])
                              formik.setFieldValue('outlet_name', [])
                              formik.setFieldValue('category_id', '')
                              formik.setFieldValue('category_name', '')
                            }}
                            onSelect={(val, itm) => {
                              formik.setFieldValue('brand_name', val === null ? '' : itm.label)
                              formik.values.outlet_id = item.outlet_id
                              formik.values.outlet_name = item.outlet_name
                              formik.values.category_id = item.category_id
                              formik.values.category_name = item.category_name
                              formik.setFieldValue('outlet_id', [])
                              formik.setFieldValue('outlet_name', [])
                              formik.setFieldValue('category_id', '')
                              formik.setFieldValue('category_name', '')
                            }}
                            value={formik.values.brand_id}
                          />
                          {formik.errors.brand_id ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {intl.formatMessage(
                                  {id: 'VALIDATION_REQUIRED_FIELD'},
                                  {
                                    name: intl.formatMessage({id: 'LABEL_BRAND'}),
                                  }
                                )}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Brand */}

                        {/* Outlet */}
                        <div className='fv-row mb-5'>
                          {formik.values.outlet_id &&
                            (formik.values.outlet_id as unknown as any[]).length > 0 && (
                              <label className='form-label fs-6 fw-bolder text-dark required'>
                                Outlet
                              </label>
                            )}
                          <CheckPicker
                            block
                            placeholder={`-- Outlet --`}
                            data={optionsOutlet}
                            disabled={formik.values.brand_id === '' ? true : false}
                            defaultValue={
                              item && item.outlet
                                ? (item.outlet as unknown as any[]).map((e) => e.outlet_id)
                                : []
                            }
                            onChange={(val, tr) => {
                              let tmp = []
                              let result: any[] = []
                              let label: any = formik.values.outlet_name

                              if (tr.type === 'keydown') {
                                // prevent user select/choice by using click enter
                                return
                              }

                              if (val.length > 0) {
                                if ((tr.target as unknown as any).checked === true) {
                                  tmp = (optionsOutlet as unknown as any[]).find(
                                    (dt) =>
                                      dt.label === (tr.target as unknown as any).labels[0].innerText
                                  )
                                  result = [...label, tmp.label]
                                } else {
                                  if (tr.type === 'click') {
                                    if (
                                      (tr.target as unknown as any).lastChild.nodeName === 'path'
                                    ) {
                                      let tmp_result = label.filter(
                                        (e: any) =>
                                          e !==
                                          (tr.target as unknown as any).parentElement.parentElement
                                            .innerText
                                      )
                                      result = tmp_result
                                    }
                                  } else {
                                    let tmp_result = label.filter(
                                      (e: any) =>
                                        e !== (tr.target as unknown as any).labels[0].innerText
                                    )
                                    result = tmp_result
                                  }
                                }
                                formik.setFieldValue('outlet_id', val)
                                formik.setFieldValue('outlet_name', result)
                              } else {
                                formik.setFieldValue('outlet_id', [])
                                formik.setFieldValue('outlet_name', [])
                              }
                            }}
                            onSelect={(val, ite) => {
                              let value: any = formik.values.outlet_id
                              let label: any = formik.values.outlet_name
                              let tmpValue: any = []
                              let tmpLabel: any = []
                              let resultValue: any[] = []
                              let resultLabel: any[] = []

                              if (val.length > 0) {
                                if (value.indexOf(ite.value) === -1) {
                                  tmpValue = (optionsOutlet as unknown as any[]).find(
                                    (dt) => dt.value === ite.value
                                  )

                                  if (tmpValue === undefined) {
                                    return
                                  }

                                  resultValue = [...value, tmpValue.value]
                                  formik.setFieldValue('outlet_id', resultValue)
                                } else {
                                  tmpValue = value.filter((dt: any) => dt !== ite.value)
                                  formik.setFieldValue('outlet_id', tmpValue)
                                }

                                if (label.indexOf(ite.label) === -1) {
                                  tmpLabel = (optionsOutlet as unknown as any[]).find(
                                    (dt) => dt.label === ite.label
                                  )

                                  if (tmpLabel === undefined) {
                                    return
                                  }

                                  resultLabel = [...label, tmpLabel.label]
                                  formik.setFieldValue('outlet_name', resultLabel)
                                } else {
                                  tmpLabel = label.filter((dt: any) => dt !== ite.label)
                                  formik.setFieldValue('outlet_name', tmpLabel)
                                }
                              } else {
                                formik.setFieldValue('outlet_id', [])
                                formik.setFieldValue('outlet_name', [])
                              }
                            }}
                            value={formik.values.outlet_id}
                            renderExtraFooter={() => (
                              <div style={footerStyles}>
                                <Checkbox
                                  indeterminate={
                                    (formik.values.outlet_id as unknown as any[]).length > 0 &&
                                    (formik.values.outlet_id as unknown as any[]).length <
                                      (optionsOutlet as unknown as any[]).length
                                  }
                                  checked={
                                    (formik.values.outlet_id as unknown as any[]).length ===
                                    (optionsOutlet as unknown as any[]).length
                                  }
                                  onChange={(data, checked) => {
                                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                                    if (checked) {
                                      formik.setFieldValue(
                                        'outlet_id',
                                        (optionsOutlet as unknown as any[]).map((e) => e.value)
                                      )
                                      formik.setFieldValue(
                                        'outlet_name',
                                        (optionsOutlet as unknown as any[]).map((e) => e.label)
                                      )
                                    } else {
                                      formik.setFieldValue('outlet_id', [])
                                      formik.setFieldValue('outlet_name', [])
                                    }
                                  }}
                                >
                                  {(formik.values.outlet_id as unknown as any[]).length ===
                                  (optionsOutlet as unknown as any[]).length
                                    ? intl.formatMessage({id: 'LABEL_UNSELECT_ALL'})
                                    : intl.formatMessage({id: 'LABEL_SELECT_ALL'})}
                                </Checkbox>
                              </div>
                            )}
                          />
                          {formik.errors.outlet_id ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {intl.formatMessage(
                                  {id: 'VALIDATION_REQUIRED_FIELD'},
                                  {
                                    name: 'Outlet',
                                  }
                                )}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Outlet */}
                      </div>
                      {/* First Col */}

                      {/* Second Col */}
                      <div className='col-lg-6'>
                        {/* Category */}
                        <div className='fv-row mb-5'>
                          {formik.values.category_id !== '' && (
                            <label className='form-label fs-6 fw-bolder text-dark required'>
                              {intl.formatMessage({id: 'LABEL_CATEGORY'})}
                            </label>
                          )}
                          <InputPicker
                            block
                            data={optionsCategories}
                            disabled={formik.values.brand_id === '' ? true : false}
                            placeholder={`-- ${intl.formatMessage({id: 'LABEL_CATEGORY'})} --`}
                            defaultValue={
                              optionsCategories
                                ? (optionsCategories as unknown as any[]).find(
                                    (e: any) => e.value === formik.values.category_id
                                  )
                                : ''
                            }
                            onChange={(e) => {
                              formik.setFieldValue('category_id', e === null ? '' : e)
                              formik.setFieldValue(
                                'sales_type_price',
                                e === null ? [] : optionsSalesType
                              )
                              formik.setFieldValue('is_multiple_price_sales_type', 'f')
                            }}
                            onSelect={(val, itm) => {
                              formik.setFieldValue('category_name', val === null ? '' : itm.label)
                              formik.setFieldValue(
                                'sales_type_price',
                                val === null ? [] : optionsSalesType
                              )
                              formik.setFieldValue('is_multiple_price_sales_type', 'f')
                            }}
                            value={formik.values.category_id}
                          />
                          {formik.errors.category_id ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {intl.formatMessage(
                                  {id: 'VALIDATION_REQUIRED_FIELD'},
                                  {
                                    name: intl.formatMessage({id: 'LABEL_CATEGORY'}),
                                  }
                                )}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Category */}

                        {/* Name */}
                        <div className='fv-row mb-5'>
                          {formik.values.name !== '' && (
                            <label className='form-label fs-6 fw-bolder text-dark required'>
                              {intl.formatMessage({id: 'LABEL_NAME'})}
                            </label>
                          )}
                          <input
                            type='text'
                            className={clsx(
                              'form-control form-control-solid',
                              {'is-invalid': formik.touched.name && formik.errors.name},
                              {
                                'is-valid': formik.touched.name && !formik.errors.name,
                              }
                            )}
                            placeholder={intl.formatMessage({id: 'LABEL_NAME'})}
                            {...formik.getFieldProps('name')}
                          />
                          {formik.touched.name && formik.errors.name ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {formik.errors.name}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Name */}

                        {/* Order */}
                        <div className='fv-row mb-5'>
                          {formik.values.for_order && formik.values.for_order > 0 && (
                            <label className='form-label fs-6 fw-bolder text-dark required mb-0'>
                              {intl.formatMessage({id: 'LABEL_ORDER'})}
                            </label>
                          )}
                          <br />
                          <small className='text-muted'>
                            &nbsp;{intl.formatMessage({id: 'LABEL_MASTER_ITEMS_ORDER_INFO'})}
                          </small>
                          <input
                            type='number'
                            className={clsx(
                              'form-control form-control-solid',
                              {'is-invalid': formik.touched.for_order && formik.errors.for_order},
                              {
                                'is-valid': formik.touched.for_order && !formik.errors.for_order,
                              }
                            )}
                            placeholder={intl.formatMessage({id: 'LABEL_ORDER'})}
                            {...formik.getFieldProps('for_order')}
                            onChange={(e) => {
                              formik.setFieldValue('for_order', parseInt(e.target.value))
                            }}
                            min={1}
                          />
                          {formik.touched.for_order && formik.errors.for_order ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger'>
                                {formik.errors.for_order}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Order */}
                      </div>
                      {/* Second Col */}
                    </div>

                    <div className='row mt-10'>
                      {/* Third Col */}
                      <div className='col-lg-6'>
                        {/* Description */}
                        <div className='fv-row mb-5'>
                          {formik.values.description !== '' && (
                            <label className='form-label fs-6 fw-bolder text-dark'>
                              {intl.formatMessage({id: 'LABEL_DESCRIPTION'})}
                            </label>
                          )}
                          <textarea
                            className={clsx(
                              'form-control form-control-solid',
                              {
                                'is-invalid':
                                  formik.touched.description && formik.errors.description,
                              },
                              {
                                'is-valid':
                                  formik.touched.description && !formik.errors.description,
                              }
                            )}
                            placeholder={intl.formatMessage({id: 'LABEL_DESCRIPTION'})}
                            {...formik.getFieldProps('description')}
                            rows={3}
                          />
                        </div>
                        {/* Description */}
                      </div>
                      {/* Third Col */}

                      {/* Fourth Col */}
                      <div className='col-lg-6'>
                        {/* Is Default */}
                        <div className='fv-row mb-5'>
                          <label className='form-label fs-6 fw-bolder text-dark required'>
                            {intl.formatMessage({id: 'LABEL_IS_DEFAULT'})}
                          </label>
                          <div className='form-check form-switch form-check-custom form-check-solid mt-1'>
                            <Toggle
                              size='lg'
                              checkedChildren={intl.formatMessage({id: 'LABEL_YES'})}
                              unCheckedChildren={intl.formatMessage({id: 'LABEL_NO'})}
                              checked={formik.values.is_default === 't' ? true : false}
                              onChange={() =>
                                formik.setFieldValue(
                                  'is_default',
                                  formik.values.is_default === 't' ? 'f' : 't'
                                )
                              }
                            />
                          </div>
                        </div>
                        {/* Is Default */}

                        {formik.values.is_default === 't' && (
                          <>
                            {/* Is Default Parent Category */}
                            <div className='fv-row mb-5'>
                              {formik.values.is_default_parent_category?.category_id !== '' && (
                                <label className='form-label fs-6 fw-bolder text-dark required'>
                                  {intl.formatMessage({id: 'LABEL_IS_DEFAULT_PARENT_CATEGORY'})}
                                </label>
                              )}
                              <InputPicker
                                block
                                data={optionsCategories}
                                placeholder={`-- ${intl.formatMessage({id: 'LABEL_IS_DEFAULT_PARENT_CATEGORY'})} --`}
                                defaultValue={
                                  optionsCategories
                                    ? (optionsCategories as unknown as any[]).find(
                                        (e: any) =>
                                          e.value ===
                                          formik.values.is_default_parent_category?.category_id
                                      )
                                    : ''
                                }
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    'is_default_parent_category.category_id',
                                    e === null ? '' : e
                                  )
                                }}
                                onSelect={(val, itm) => {
                                  formik.setFieldValue(
                                    'is_default_parent_category.name',
                                    val === null ? '' : itm.label
                                  )
                                }}
                                value={formik.values.is_default_parent_category?.category_id}
                              />
                              {formik.errors.is_default_parent_category ? (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block text-danger fw-bolder'>
                                    {intl.formatMessage(
                                      {id: 'VALIDATION_REQUIRED_FIELD'},
                                      {
                                        name: intl.formatMessage({
                                          id: 'LABEL_IS_DEFAULT_PARENT_CATEGORY',
                                        }),
                                      }
                                    )}
                                  </div>
                                </div>
                              ) : null}
                            </div>
                            {/* Is Default Parent Category */}
                          </>
                        )}

                        {/* Status */}
                        <div className='fv-row mb-5'>
                          <label className='form-label fs-6 fw-bolder text-dark required'>
                            Status
                          </label>
                          <div className='form-check form-switch form-check-custom form-check-solid mt-1'>
                            <Toggle
                              size='lg'
                              checkedChildren={intl.formatMessage({id: 'LABEL_ACTIVE'})}
                              unCheckedChildren={intl.formatMessage({id: 'LABEL_INACTIVE'})}
                              checked={formik.values.status === 't' ? true : false}
                              onChange={() =>
                                formik.setFieldValue(
                                  'status',
                                  formik.values.status === 't' ? 'f' : 't'
                                )
                              }
                            />
                          </div>
                        </div>
                        {/* Status */}
                      </div>
                      {/* Fourth Col */}
                    </div>
                  </div>
                  {/* General Information */}

                  {/* Pricing */}
                  <div
                    className={clsx('tab-pane', {
                      active: tabAdd === 'pricing',
                    })}
                  >
                    {/* All Ppn & Multiple Sales Type */}
                    <div className='row'>
                      {/* First Col */}
                      <div className='col-lg-6'>
                        {/* All PPN */}
                        <div className='fv-row mb-5'>
                          <label className='form-label fs-6 fw-bolder text-dark'>
                            {intl.formatMessage({id: 'LABEL_ALL_PPN'})}
                          </label>
                          <div className='input-group'>
                            <CurrencyFormat
                              className='form-control form-control-sm'
                              value={basePPN}
                              displayType={'input'}
                              isNumericString={false}
                              decimalScale={2}
                              thousandSeparator={true}
                              key={`base-ppn`}
                              onValueChange={(values: any) => {
                                const {value} = values
                                let val = value < 1 ? 0 : value > 100 ? 100 : !value ? 0 : value
                                setBasePPN(Number(val))
                                formik.setFieldValue('ppn', val)

                                formik.values.price &&
                                  formik.setFieldValue(
                                    'ppn_price',
                                    parseInt(Number(formik.values.price * (val / 100)).toFixed(0))
                                  )
                                formik.values.ppn_price &&
                                  formik.values.price &&
                                  formik.setFieldValue(
                                    'final_price',
                                    parseInt(
                                      Number(
                                        parseInt(
                                          Number(formik.values.price * (val / 100)).toFixed(0)
                                        ) + Number(formik.values.price)
                                      ).toFixed(0)
                                    )
                                  )

                                updateSalesType(
                                  formik,
                                  val,
                                  formik.values.ppn_price,
                                  formik.values.price,
                                  formik.values.final_price,
                                  formik.values.sku,
                                  basePPN
                                )
                              }}
                            />
                            <span className='input-group-text' id='base-ppn'>
                              %
                            </span>
                          </div>
                        </div>
                        {/* All PPN */}
                      </div>
                      {/* First Col */}

                      {/* Second Col */}
                      <div className='col-lg-6'>
                        {/* Apply Multiple Price */}
                        <div className='fv-row mb-5'>
                          <label className='form-label fs-6 fw-bolder text-dark required'>
                            {intl.formatMessage({id: 'LABEL_APPLY_MULTIPLE_PRICE'})}
                          </label>
                          <div className='form-check form-switch form-check-custom form-check-solid mt-1'>
                            <Toggle
                              size='lg'
                              readOnly={
                                formik.values.variant_items &&
                                formik.values.variant_items.length > 0
                                  ? false
                                  : true
                              }
                              checkedChildren={intl.formatMessage({id: 'LABEL_YES'})}
                              unCheckedChildren={intl.formatMessage({id: 'LABEL_NO'})}
                              checked={
                                formik.values.is_multiple_price_sales_type === 't' ? true : false
                              }
                              onChange={() => {
                                formik.setFieldValue(
                                  'is_multiple_price_sales_type',
                                  formik.values.is_multiple_price_sales_type === 't' ? 'f' : 't'
                                )
                              }}
                            />
                          </div>
                        </div>
                        {/* Apply Multiple Price */}
                      </div>
                      {/* Second Col */}
                    </div>
                    {/* All Ppn & Multiple Sales Type

                    {/* Button Manage Variant */}
                    <div className='row'>
                      <div className='col-sm-12'>
                        <div className='d-grid'>
                          <button
                            type='button'
                            className='btn btn-bg-secondary btn-sm'
                            onClick={() => {
                              // console.log('button manage variant')
                              setIsDialogVariantOpen(true)
                              initiateAddTMPVariant()
                            }}
                          >
                            {intl.formatMessage({id: 'LABEL_MANAGE_VARIANT'})}
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* Button Manage Variant */}

                    {/* SKU Variant */}
                    {formik.values.variant_items &&
                      formik.values.variant_items.length > 0 &&
                      formik.values.variant_items[0].name !== '' && (
                        <div className='fv-row '>
                          <table className='table table-bordered'>
                            <thead>
                              <tr
                                className='fw-bold fs-6 text-gray-800'
                                style={{backgroundColor: mode === 'light' ? '#e8e8e8' : '#4f545d'}}
                              >
                                <th className='required' style={{width: '50%'}}>
                                  {intl.formatMessage({id: 'LABEL_NAME'})}
                                </th>
                                <th style={{width: '50%'}}>
                                  {intl.formatMessage({id: 'LABEL_SKU'})}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {formik.values.variant_items !== undefined &&
                                formik.values.variant_items.map((variant: any, i: number) => (
                                  <tr key={`tbd-${i}`}>
                                    <td style={{verticalAlign: 'middle'}} className='fw-bold'>
                                      {variant.name}
                                    </td>
                                    <td>
                                      <input
                                        id={`${variant.variant_id}`}
                                        type='text'
                                        className={clsx(
                                          'form-control form-control-solid form-control-sm'
                                        )}
                                        placeholder={intl.formatMessage({id: 'LABEL_SKU'})}
                                        {...formik.getFieldProps(`variant_items[${i}].sku`)}
                                        onChange={(e) => {
                                          formik.setFieldValue(
                                            `variant_items[${i}].sku`,
                                            e.currentTarget.value.toUpperCase()
                                          )

                                          if (e !== null || e !== '') {
                                            setSalesTypeVariantSKU(
                                              formik,
                                              variant.variant_id,
                                              e.currentTarget.value.toUpperCase()
                                            )
                                          }
                                        }}
                                      />
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      )}
                    {/* SKU Variant */}

                    {/* Divider */}
                    {formik.values.is_multiple_price_sales_type === 'f' ? (
                      <Divider />
                    ) : (
                      <Divider className='fw-bolder fs-4'>
                        {intl.formatMessage({id: 'LABEL_SALES_TYPE'})}
                      </Divider>
                    )}
                    {/* Divider */}

                    {/* Pricing Form */}
                    {formik.values.is_multiple_price_sales_type === 'f' ? (
                      formik.values.variant_items && formik.values.variant_items.length > 0 ? (
                        <>
                          {/* No Multiple SalesType With Variants */}
                          {/* No Multiple SalesType With Variants */}
                        </>
                      ) : (
                        <>
                          {/* No Multiple SalesType With No Variants */}
                          <div className='row'>
                            {/* First Col */}
                            <div className='col-lg-6'>
                              {/* PPN */}
                              <div className='fv-row mb-5'>
                                <label className='form-label fs-6 fw-bolder text-dark'>
                                  {intl.formatMessage({id: 'LABEL_PPN'})}
                                </label>
                                <div className='input-group'>
                                  <CurrencyFormat
                                    className='form-control form-control-sm'
                                    value={formik.values.ppn}
                                    displayType={'input'}
                                    isNumericString={false}
                                    decimalScale={2}
                                    thousandSeparator={true}
                                    key={`ppn-${formik.values.item_id}`}
                                    onValueChange={(values: any) => {
                                      const {value} = values
                                      // ppn
                                      formik.setFieldValue('ppn', value)

                                      // ppn price
                                      formik.values.price &&
                                        formik.setFieldValue(
                                          'ppn_price',
                                          parseInt(
                                            Number(formik.values.price * (value / 100)).toFixed(0)
                                          )
                                        )

                                      // final price
                                      formik.values.ppn_price &&
                                        formik.values.price &&
                                        formik.setFieldValue(
                                          'final_price',
                                          parseInt(
                                            Number(
                                              parseInt(
                                                Number(formik.values.price * (value / 100)).toFixed(
                                                  0
                                                )
                                              ) + Number(formik.values.price)
                                            ).toFixed(0)
                                          )
                                        )

                                      updateSalesType(
                                        formik,
                                        value,
                                        formik.values.ppn_price,
                                        formik.values.price,
                                        formik.values.final_price,
                                        formik.values.sku,
                                        basePPN
                                      )
                                    }}
                                  />
                                  <span className='input-group-text' id='ppn'>
                                    %
                                  </span>
                                </div>
                              </div>
                              {/* PPN */}

                              {/* Price */}
                              <div className='fv-row mb-5'>
                                <label className='form-label fs-6 fw-bolder text-dark'>
                                  {intl.formatMessage({id: 'LABEL_PRICE'})}
                                </label>
                                <div className='input-group'>
                                  <span className='input-group-text' id='price'>
                                    Rp
                                  </span>
                                  <CurrencyFormat
                                    className='form-control form-control-sm'
                                    value={formik.values.price}
                                    displayType={'input'}
                                    isNumericString={false}
                                    decimalScale={2}
                                    thousandSeparator={true}
                                    key={`price-${formik.values.item_id}`}
                                    onValueChange={(values: any) => {
                                      const {value} = values
                                      // price
                                      formik.setFieldValue('price', value)

                                      // ppn price
                                      formik.values.ppn &&
                                        formik.setFieldValue(
                                          'ppn_price',
                                          parseInt(
                                            Number(
                                              (Number(value) * (formik.values.ppn / 100)).toFixed(0)
                                            ).toFixed(0)
                                          )
                                        )

                                      // final price
                                      formik.values.ppn &&
                                        formik.setFieldValue(
                                          'final_price',
                                          parseInt(
                                            Number(
                                              Number(
                                                (value * (formik.values.ppn / 100)).toFixed(0)
                                              ) + Number(value)
                                            ).toFixed(0)
                                          )
                                        )

                                      updateSalesType(
                                        formik,
                                        formik.values.ppn,
                                        formik.values.ppn_price,
                                        value,
                                        formik.values.final_price,
                                        formik.values.sku,
                                        basePPN
                                      )
                                    }}
                                  />
                                </div>
                                {formik.errors.price ? (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block text-danger fw-bolder'>
                                      {intl.formatMessage(
                                        {id: 'VALIDATION_REQUIRED_FIELD'},
                                        {
                                          name: intl.formatMessage({id: 'LABEL_PRICE'}),
                                        }
                                      )}
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                              {/* Price */}
                            </div>
                            {/* First Col */}

                            {/* Second Col */}
                            <div className='col-lg-6'>
                              {/* PPN Price*/}
                              <div className='fv-row mb-5'>
                                <label className='form-label fs-6 fw-bolder text-dark'>
                                  {intl.formatMessage({id: 'LABEL_PPN_PRICE'})}
                                </label>
                                <div className='input-group'>
                                  <span className='input-group-text' id='ppn-price'>
                                    Rp
                                  </span>
                                  <CurrencyFormat
                                    className='form-control form-control-sm'
                                    disabled
                                    value={Number(formik.values.ppn_price)}
                                    displayType={'input'}
                                    isNumericString={false}
                                    decimalScale={2}
                                    thousandSeparator={true}
                                    key={`ppn-price-${formik.values.item_id}`}
                                  />
                                </div>
                              </div>
                              {/* PPN Price*/}

                              {/* Final Price*/}
                              <div className='fv-row mb-5'>
                                <label className='form-label fs-6 fw-bolder text-dark text-decoration-underline'>
                                  {intl.formatMessage({id: 'LABEL_FINAL_PRICE'})}
                                </label>
                                <div className='input-group'>
                                  <span className='input-group-text' id='final-price'>
                                    Rp
                                  </span>
                                  <CurrencyFormat
                                    className='form-control  form-control-sm fw-bolder'
                                    value={Number(formik.values.final_price)}
                                    displayType={'input'}
                                    isNumericString={false}
                                    decimalScale={2}
                                    thousandSeparator={true}
                                    key={`final-price-${formik.values.item_id}`}
                                    onValueChange={(values: any) => {
                                      const {value} = values
                                      // final price
                                      formik.setFieldValue('final_price', value)

                                      // ppn price
                                      formik.values.ppn &&
                                        formik.setFieldValue(
                                          'ppn_price',
                                          Number(
                                            value -
                                              (Number(value) / (100 + formik.values.ppn)) * 100
                                          ).toFixed(0)
                                        )

                                      // price
                                      formik.values.ppn &&
                                        formik.setFieldValue(
                                          'price',
                                          Number(
                                            value -
                                              (value -
                                                (Number(value) / (100 + formik.values.ppn)) * 100)
                                          ).toFixed(2)
                                        )

                                      updateSalesType(
                                        formik,
                                        formik.values.ppn,
                                        formik.values.ppn_price,
                                        formik.values.price,
                                        value,
                                        formik.values.sku,
                                        basePPN
                                      )
                                    }}
                                  />
                                </div>
                              </div>
                              {/* Final Price*/}

                              {/* SKU */}
                              <div className='fv-row mb-5'>
                                {formik.values.sku !== '' && (
                                  <label className='form-label fs-6 fw-bolder text-dark'>
                                    {intl.formatMessage({id: 'LABEL_SKU'})}
                                  </label>
                                )}
                                <input
                                  type='text'
                                  className={clsx(
                                    'form-control  form-control-sm form-control-solid',
                                    {'is-invalid': formik.touched.sku && formik.errors.sku},
                                    {
                                      'is-valid': formik.touched.sku && !formik.errors.sku,
                                    }
                                  )}
                                  placeholder={intl.formatMessage({id: 'LABEL_SKU'})}
                                  {...formik.getFieldProps('sku')}
                                  onChange={(e) => {
                                    formik.setFieldValue('sku', e.target.value.toUpperCase())
                                    updateSalesType(
                                      formik,
                                      formik.values.ppn,
                                      formik.values.ppn_price,
                                      formik.values.price,
                                      formik.values.final_price,
                                      e.target.value.toUpperCase(),
                                      basePPN
                                    )
                                  }}
                                />
                                {formik.touched.sku && formik.errors.sku ? (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block text-danger fw-bolder'>
                                      {formik.errors.sku}
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                              {/* SKU */}
                            </div>
                            {/* Second Col */}
                          </div>
                          {/* No Multiple SalesType With No Variants */}
                        </>
                      )
                    ) : (
                      <>
                        {/* ALL SKU No Variant */}
                        {formik.values.variant_items &&
                          formik.values.variant_items.length === 0 && (
                            <div className='row'>
                              {/* SKU */}
                              <div className='fv-row mb-5'>
                                {formik.values.sku !== '' && (
                                  <label className='form-label fs-6 fw-bolder text-dark'>
                                    {intl.formatMessage({id: 'LABEL_SKU'})}
                                  </label>
                                )}
                                <input
                                  type='text'
                                  className={clsx(
                                    'form-control form-control-solid',
                                    {'is-invalid': formik.touched.sku && formik.errors.sku},
                                    {
                                      'is-valid': formik.touched.sku && !formik.errors.sku,
                                    }
                                  )}
                                  placeholder={intl.formatMessage({id: 'LABEL_SKU'})}
                                  {...formik.getFieldProps('sku')}
                                  onChange={(e) => {
                                    formik.setFieldValue('sku', e.target.value.toUpperCase())

                                    if (e !== null || e !== '') {
                                      setSalesTypeNoVariantSKU(formik, e.target.value.toUpperCase())
                                    }
                                  }}
                                />
                              </div>
                              {/* SKU */}
                            </div>
                          )}
                        {/* ALL SKU No Variant */}

                        {/* Sales Type Price */}
                        {formik.values.sales_type_items &&
                          formik.values.sales_type_items.map((salesTypeItems: any, i: number) => (
                            <>
                              {/* Sales Type Label */}
                              <div className='fv-row mb-2'>
                                <label className='form-label fs-6 fw-bolder text-dark'>
                                  {salesTypeItems.name}
                                </label>
                                <div className='form-check form-switch form-check-custom form-check-solid mt-1'>
                                  <Toggle
                                    key={salesTypeItems.sales_type_id}
                                    size='lg'
                                    checkedChildren={intl.formatMessage({id: 'LABEL_YES'})}
                                    unCheckedChildren={intl.formatMessage({id: 'LABEL_NO'})}
                                    checked={salesTypeItems.is_include === 't' ? true : false}
                                    onChange={() => {
                                      formik.setFieldValue(
                                        `sales_type_items[${i}].is_include`,
                                        formik.values.sales_type_items &&
                                          formik.values.sales_type_items[i].is_include === 't'
                                          ? 'f'
                                          : 't'
                                      )
                                      setSalesTypeVariantInclude(
                                        formik,
                                        salesTypeItems.sales_type_id,
                                        formik.values.sales_type_items &&
                                          formik.values.sales_type_items[i].is_include === 't'
                                          ? 'f'
                                          : 't',
                                        basePPN
                                      )
                                    }}
                                  />
                                  <label
                                    className='form-label fs-6 fw-bolder text-muted'
                                    style={{paddingLeft: '10px', paddingTop: '5px'}}
                                  >
                                    {intl.formatMessage({id: 'LABEL_SALES_TYPE_IS_INCLUDE'})}
                                  </label>
                                </div>
                              </div>
                              {/* Sales Type Label */}

                              {/* Sales Type Price Variant */}
                              <div className='fv-row'>
                                <table className='table table-bordered'>
                                  <thead>
                                    <tr
                                      className='fw-bold fs-6 text-gray-800'
                                      style={{
                                        backgroundColor: mode === 'light' ? '#e8e8e8' : '#4f545d',
                                      }}
                                    >
                                      {formik.values.variant_items &&
                                        formik.values.variant_items.length > 0 && (
                                          <>
                                            <th style={{width: '15%'}}>
                                              {intl.formatMessage({id: 'LABEL_IS_VARIANT'})}
                                            </th>
                                            <th className='required' style={{width: '5%'}}>
                                              {intl.formatMessage({id: 'LABEL_INCLUDE'})}
                                            </th>
                                          </>
                                        )}
                                      <th className='required' style={{width: '15%'}}>
                                        {intl.formatMessage({id: 'LABEL_SKU'})}
                                      </th>
                                      <th className='required' style={{width: '14%'}}>
                                        {intl.formatMessage({id: 'LABEL_PPN'})}
                                      </th>
                                      <th className='required' style={{width: '20%'}}>
                                        {intl.formatMessage({id: 'LABEL_PRICE'})}
                                      </th>
                                      <th style={{width: '15%'}}>
                                        {intl.formatMessage({id: 'LABEL_PPN_PRICE'})}
                                      </th>
                                      <th
                                        style={{width: '20%'}}
                                        className='text-decoration-underline'
                                      >
                                        {intl.formatMessage({id: 'LABEL_FINAL_PRICE'})}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {salesTypeItems.sales_type_price.map(
                                      (salesTypePrice: any, j: number) => (
                                        <tr key={`tbdSales-${j}`}>
                                          {formik.values.variant_items &&
                                            formik.values.variant_items.length > 0 && (
                                              <>
                                                <td
                                                  style={{verticalAlign: 'middle'}}
                                                  className='fw-bold'
                                                >
                                                  {salesTypePrice.name}
                                                </td>
                                                <td>
                                                  <div className='form-check form-switch form-check-custom form-check-solid mt-1'>
                                                    <Toggle
                                                      readOnly={
                                                        formik.values.has_variant === 't'
                                                          ? true
                                                          : false
                                                      }
                                                      size='lg'
                                                      checkedChildren={intl.formatMessage({
                                                        id: 'LABEL_YES',
                                                      })}
                                                      unCheckedChildren={intl.formatMessage({
                                                        id: 'LABEL_NO',
                                                      })}
                                                      checked={
                                                        formik.values.sales_type_items &&
                                                        formik.values.sales_type_items[i]
                                                          .sales_type_price[j].is_include === 't'
                                                          ? true
                                                          : false
                                                      }
                                                      onChange={() => {
                                                        formik.setFieldValue(
                                                          `sales_type_items[${i}].sales_type_price[${j}].is_include`,
                                                          formik.values.sales_type_items &&
                                                            formik.values.sales_type_items[i]
                                                              .sales_type_price[j].is_include ===
                                                              't'
                                                            ? 'f'
                                                            : 't'
                                                        )
                                                      }}
                                                    />
                                                  </div>
                                                </td>
                                              </>
                                            )}
                                          <td>
                                            <input
                                              id={`sku-${salesTypePrice.variant_id}-${salesTypeItems.sales_type_id}`}
                                              type='text'
                                              className={clsx(
                                                'form-control form-control-solid form-control-sm'
                                              )}
                                              placeholder={
                                                salesTypeItems.is_include === 't' &&
                                                salesTypeItems.sales_type_price[j].is_include ===
                                                  't'
                                                  ? intl.formatMessage({id: 'LABEL_SKU'})
                                                  : intl.formatMessage({
                                                      id: 'LABEL_NOT_INCLUDE',
                                                    })
                                              }
                                              {...formik.getFieldProps(
                                                `sales_type_items[${i}].sales_type_price[${j}].sku`
                                              )}
                                              disabled
                                            />
                                          </td>
                                          {/* PPN  */}
                                          <td>
                                            <div className='input-group input-group-sm'>
                                              <CurrencyFormat
                                                className='form-control'
                                                value={
                                                  formik.values.sales_type_items &&
                                                  formik.values.sales_type_items[i]
                                                    .sales_type_price[j].is_include === 't'
                                                    ? formik.values.sales_type_items &&
                                                      formik.values.sales_type_items[i]
                                                        .sales_type_price[j].ppn
                                                    : 0
                                                }
                                                displayType={'input'}
                                                isNumericString={false}
                                                decimalScale={2}
                                                thousandSeparator={true}
                                                key={`ppn-${
                                                  formik.values.sales_type_items &&
                                                  formik.values.sales_type_items[i]
                                                    .sales_type_price[j]
                                                }`}
                                                onValueChange={(values: any) => {
                                                  const {value} = values

                                                  // ppn
                                                  formik.setFieldValue(
                                                    `sales_type_items[${i}].sales_type_price[${j}].ppn`,
                                                    value
                                                  )

                                                  // ppn price
                                                  formik.values.sales_type_items &&
                                                    formik.setFieldValue(
                                                      `sales_type_items[${i}].sales_type_price[${j}].ppn_price`,
                                                      parseInt(
                                                        Number(
                                                          formik.values.sales_type_items[i]
                                                            .sales_type_price[j].price *
                                                            (value / 100)
                                                        ).toFixed(0)
                                                      )
                                                    )

                                                  // final price
                                                  formik.values.sales_type_items &&
                                                    formik.setFieldValue(
                                                      `sales_type_items[${i}].sales_type_price[${j}].final_price`,
                                                      parseInt(
                                                        Number(
                                                          parseInt(
                                                            Number(
                                                              formik.values.sales_type_items[i]
                                                                .sales_type_price[j].price *
                                                                (value / 100)
                                                            ).toFixed(0)
                                                          ) +
                                                            Number(
                                                              formik.values.sales_type_items[i]
                                                                .sales_type_price[j].price
                                                            )
                                                        ).toFixed(0)
                                                      )
                                                    )
                                                }}
                                                disabled={
                                                  formik.values.sales_type_items &&
                                                  formik.values.sales_type_items[i].is_include ===
                                                    't' &&
                                                  formik.values.sales_type_items[i]
                                                    .sales_type_price[j].is_include === 't'
                                                    ? false
                                                    : true
                                                }
                                              />
                                              <span className='input-group-text' id='ppn'>
                                                %
                                              </span>
                                            </div>
                                          </td>
                                          {/* Price */}
                                          <td>
                                            <div className='input-group input-group-sm'>
                                              <span className='input-group-text' id='price'>
                                                Rp
                                              </span>
                                              <CurrencyFormat
                                                className='form-control'
                                                value={
                                                  formik.values.sales_type_items &&
                                                  formik.values.sales_type_items[i]
                                                    .sales_type_price[j].is_include === 't'
                                                    ? formik.values.sales_type_items &&
                                                      formik.values.sales_type_items[i]
                                                        .sales_type_price[j].price
                                                    : 0
                                                }
                                                displayType={'input'}
                                                isNumericString={false}
                                                decimalScale={2}
                                                thousandSeparator={true}
                                                key={`price-${
                                                  formik.values.sales_type_items &&
                                                  formik.values.sales_type_items[i]
                                                    .sales_type_price[j]
                                                }`}
                                                onValueChange={(values: any) => {
                                                  const {value} = values
                                                  // price
                                                  formik.setFieldValue(
                                                    `sales_type_items[${i}].sales_type_price[${j}].price`,
                                                    value
                                                  )

                                                  // ppn price
                                                  formik.values.sales_type_items &&
                                                    formik.setFieldValue(
                                                      `sales_type_items[${i}].sales_type_price[${j}].ppn_price`,
                                                      parseInt(
                                                        Number(
                                                          (
                                                            Number(value) *
                                                            (formik.values.sales_type_items[i]
                                                              .sales_type_price[j].ppn /
                                                              100)
                                                          ).toFixed(0)
                                                        ).toFixed(0)
                                                      )
                                                    )

                                                  // final price
                                                  formik.values.sales_type_items &&
                                                    formik.setFieldValue(
                                                      `sales_type_items[${i}].sales_type_price[${j}].final_price`,
                                                      parseInt(
                                                        Number(
                                                          Number(
                                                            (
                                                              value *
                                                              (formik.values.sales_type_items[i]
                                                                .sales_type_price[j].ppn /
                                                                100)
                                                            ).toFixed(0)
                                                          ) + Number(value)
                                                        ).toFixed(0)
                                                      )
                                                    )
                                                }}
                                                disabled={
                                                  formik.values.sales_type_items &&
                                                  formik.values.sales_type_items[i].is_include ===
                                                    't' &&
                                                  formik.values.sales_type_items[i]
                                                    .sales_type_price[j].is_include === 't'
                                                    ? false
                                                    : true
                                                }
                                              />
                                            </div>
                                          </td>
                                          {/* PPN Price */}
                                          <td>
                                            <div className='input-group input-group-sm'>
                                              <span
                                                className='input-group-text'
                                                id={`ppn-price-${
                                                  formik.values.sales_type_items &&
                                                  formik.values.sales_type_items[i]
                                                    .sales_type_price[j]
                                                }`}
                                              >
                                                Rp
                                              </span>
                                              <CurrencyFormat
                                                className='form-control'
                                                disabled
                                                value={
                                                  formik.values.sales_type_items &&
                                                  formik.values.sales_type_items[i]
                                                    .sales_type_price[j].is_include === 't'
                                                    ? Number(
                                                        formik.values.sales_type_items &&
                                                          formik.values.sales_type_items[i]
                                                            .sales_type_price[j].ppn_price
                                                      )
                                                    : 0
                                                }
                                                displayType={'input'}
                                                isNumericString={false}
                                                decimalScale={2}
                                                thousandSeparator={true}
                                                key={`ppn-price-${
                                                  formik.values.sales_type_items &&
                                                  formik.values.sales_type_items[i]
                                                    .sales_type_price[j]
                                                }`}
                                              />
                                            </div>
                                          </td>
                                          {/* Final Price */}
                                          <td>
                                            <div className='input-group input-group-sm'>
                                              <span
                                                className='input-group-text'
                                                id={`final-price-${
                                                  formik.values.sales_type_items &&
                                                  formik.values.sales_type_items[i]
                                                    .sales_type_price[j]
                                                }`}
                                              >
                                                Rp
                                              </span>
                                              <CurrencyFormat
                                                className='form-control fw-bolder'
                                                value={
                                                  formik.values.sales_type_items &&
                                                  formik.values.sales_type_items[i]
                                                    .sales_type_price[j].is_include === 't'
                                                    ? Number(
                                                        formik.values.sales_type_items &&
                                                          formik.values.sales_type_items[i]
                                                            .sales_type_price[j].final_price
                                                      )
                                                    : 0
                                                }
                                                displayType={'input'}
                                                isNumericString={false}
                                                decimalScale={2}
                                                thousandSeparator={true}
                                                key={`final-price-${
                                                  formik.values.sales_type_items &&
                                                  formik.values.sales_type_items[i]
                                                    .sales_type_price[j]
                                                }`}
                                                onValueChange={(values: any) => {
                                                  const {value} = values
                                                  // final price
                                                  formik.setFieldValue(
                                                    `sales_type_items[${i}].sales_type_price[${j}].final_price`,
                                                    value
                                                  )

                                                  // ppn price
                                                  formik.values.sales_type_items &&
                                                    formik.setFieldValue(
                                                      `sales_type_items[${i}].sales_type_price[${j}].ppn_price`,
                                                      Number(
                                                        value -
                                                          (Number(value) /
                                                            (100 +
                                                              formik.values.sales_type_items[i]
                                                                .sales_type_price[j].ppn)) *
                                                            100
                                                      ).toFixed(0)
                                                    )

                                                  // price
                                                  formik.values.sales_type_items &&
                                                    formik.setFieldValue(
                                                      `sales_type_items[${i}].sales_type_price[${j}].price`,
                                                      Number(
                                                        value -
                                                          (value -
                                                            (Number(value) /
                                                              (100 +
                                                                formik.values.sales_type_items[i]
                                                                  .sales_type_price[j].ppn)) *
                                                              100)
                                                      ).toFixed(2)
                                                    )
                                                }}
                                                disabled={
                                                  formik.values.sales_type_items &&
                                                  formik.values.sales_type_items[i].is_include ===
                                                    't' &&
                                                  formik.values.sales_type_items[i]
                                                    .sales_type_price[j].is_include === 't'
                                                    ? false
                                                    : true
                                                }
                                              />
                                            </div>
                                          </td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              </div>
                              <Divider />
                              {/* Sales Type Price Variant */}
                            </>
                          ))}
                        {/* Sales Type Price */}
                      </>
                    )}
                    {/* Pricing Form */}
                  </div>
                  {/* Pricing */}

                  {/* Period */}
                  <div
                    className={clsx('tab-pane', {
                      active: tabAdd === 'period',
                    })}
                  >
                    <div className='row'>
                      <div className='col-lg-12 mb-5'>
                        <UnderDevelopmentPage />

                        {/* Apply Is Lifetime */}
                        {/* <div className='fv-row mb-5'>
                          <label className='form-label fs-6 fw-bolder text-dark required'>
                            {intl.formatMessage({id: 'LABEL_APPLY_LIFETIME'})}
                          </label>
                          <div className='form-check form-switch form-check-custom form-check-solid mt-1'>
                            <Toggle
                              readOnly={true}
                              size='lg'
                              checkedChildren={intl.formatMessage({id: 'LABEL_YES'})}
                              unCheckedChildren={intl.formatMessage({id: 'LABEL_NO'})}
                              checked={formik.values.is_lifetime === 'f' ? true : false}
                              onChange={() => {
                                formik.setFieldValue(
                                  'is_lifetime',
                                  formik.values.is_lifetime === 't' ? 'f' : 't'
                                )
                              }}
                            />
                          </div>
                        </div> */}
                        {/* Apply Is Lifetime */}
                      </div>
                    </div>
                  </div>
                  {/* Period */}
                </div>
              </div>
              {/* Tab Body */}
            </form>
          ) : (
            <form id={`${modalID}-form`} className='form' onSubmit={formik.handleSubmit} noValidate>
              {/* Tab Header */}
              <div className='card-toolbar'>
                <ul
                  className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-2 mt-2'
                  role='tablist'
                >
                  <li className={`nav-item ${tab === 'general' ? 'fw-bolder' : ''}`}>
                    <a
                      className={clsx(`nav-link cursor-pointer`, {
                        active: tab === 'general',
                      })}
                      onClick={() => setTab('general')}
                      role='tab'
                    >
                      {intl.formatMessage({id: 'LABEL_GENERAL_INFORMATION'})}
                    </a>
                  </li>
                  <li className={`nav-item ${tab === 'pricing' ? 'fw-bolder' : ''}`}>
                    <a
                      className={clsx(`nav-link cursor-pointer`, {
                        active: tab === 'pricing',
                      })}
                      onClick={() =>
                        formik.values.category_id === '' ? setTab('general') : setTab('pricing')
                      }
                      role='tab'
                    >
                      {intl.formatMessage({id: 'LABEL_PRICING'})}
                    </a>
                  </li>
                  <li className={`nav-item ${tab === 'period' ? 'fw-bolder' : ''}`}>
                    <a
                      className={clsx(`nav-link cursor-pointer`, {
                        active: tab === 'period',
                      })}
                      onClick={() =>
                        formik.values.category_id === '' ? setTab('general') : setTab('period')
                      }
                      role='tab'
                    >
                      {intl.formatMessage({id: 'LABEL_PERIOD'})}
                    </a>
                  </li>
                  <li className={`nav-item ${tab === 'log' ? 'fw-bolder' : ''}`}>
                    <a
                      className={clsx(`nav-link cursor-pointer`, {
                        active: tab === 'log',
                      })}
                      onClick={() => setTab('log')}
                      role='tab'
                    >
                      Log
                    </a>
                  </li>
                </ul>
              </div>
              {/* Tab Header */}

              {/* Tab Body */}
              <div className='card-body p-0 pt-2'>
                <div className='tab-content'>
                  {/* General Information */}
                  <div
                    className={clsx('tab-pane', {
                      active: tab === 'general',
                    })}
                  >
                    <div className='row'>
                      {/* First Col */}
                      <div className='col-lg-6'>
                        {/* Holding */}
                        <div className='fv-row mb-5'>
                          {formik.values.holding_id !== '' && (
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action === 'edit' ? 'required' : ''
                              }`}
                            >
                              Holding
                            </label>
                          )}
                          <InputPicker
                            block
                            readOnly={action !== 'edit' ? true : false}
                            data={optionsHolding}
                            placeholder={`-- Holding --`}
                            defaultValue={
                              optionsHolding
                                ? (optionsHolding as unknown as any[]).find(
                                    (e: any) => e.value === formik.values.holding_id
                                  )
                                : ''
                            }
                            onChange={(e) => {
                              formik.setFieldValue('holding_id', e === null ? '' : e)
                            }}
                            onSelect={(val, itm) => {
                              formik.setFieldValue('holding_name', val === null ? '' : itm.label)
                            }}
                            value={formik.values.holding_id}
                          />
                          {formik.errors.holding_id ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {intl.formatMessage(
                                  {id: 'VALIDATION_REQUIRED_FIELD'},
                                  {
                                    name: 'Holding',
                                  }
                                )}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Holding */}

                        {/* Company */}
                        <div className='fv-row mb-5'>
                          {formik.values.company_id !== '' && (
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action === 'edit' ? 'required' : ''
                              }`}
                            >
                              {intl.formatMessage({id: 'LABEL_COMPANY'})}
                            </label>
                          )}
                          <InputPicker
                            block
                            readOnly={action !== 'edit' ? true : false}
                            data={optionsCompany}
                            placeholder={`-- ${intl.formatMessage({id: 'LABEL_COMPANY'})} --`}
                            defaultValue={
                              optionsCompany
                                ? (optionsCompany as unknown as any[]).find(
                                    (e: any) => e.value === formik.values.company_id
                                  )
                                : ''
                            }
                            onChange={(e) => {
                              formik.setFieldValue('company_id', e === null ? '' : e)
                            }}
                            onSelect={(val, itm) => {
                              formik.setFieldValue('company_name', val === null ? '' : itm.label)
                            }}
                            value={formik.values.company_id}
                          />
                          {formik.errors.company_id ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {intl.formatMessage(
                                  {id: 'VALIDATION_REQUIRED_FIELD'},
                                  {
                                    name: intl.formatMessage({id: 'LABEL_COMPANY'}),
                                  }
                                )}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Company */}

                        {/* Brand */}
                        <div className='fv-row mb-5'>
                          {formik.values.brand_id !== '' && (
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action === 'edit' ? 'required' : ''
                              }`}
                            >
                              {intl.formatMessage({id: 'LABEL_BRAND'})}
                            </label>
                          )}
                          <InputPicker
                            block
                            readOnly={action !== 'edit' ? true : false}
                            data={optionsBrand}
                            placeholder={`-- ${intl.formatMessage({id: 'LABEL_BRAND'})} --`}
                            defaultValue={
                              optionsBrand
                                ? (optionsBrand as unknown as any[]).find(
                                    (e: any) => e.value === formik.values.brand_id
                                  )
                                : ''
                            }
                            onChange={(e) => {
                              formik.setFieldValue('brand_id', e === null ? '' : e)
                            }}
                            onSelect={(val, itm) => {
                              formik.setFieldValue('brand_name', val === null ? '' : itm.label)
                            }}
                            value={formik.values.brand_id}
                          />
                          {formik.errors.brand_id ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {intl.formatMessage(
                                  {id: 'VALIDATION_REQUIRED_FIELD'},
                                  {
                                    name: intl.formatMessage({id: 'LABEL_BRAND'}),
                                  }
                                )}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Brand */}

                        {/* Outlet */}
                        <div className='fv-row mb-5'>
                          {formik.values.category_id !== '' && (
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action === 'edit' ? 'required' : ''
                              }`}
                            >
                              {intl.formatMessage({id: 'LABEL_OUTLET'})}
                            </label>
                          )}
                          <CheckPicker
                            block
                            readOnly={action !== 'edit' ? true : false}
                            data={optionsOutlet}
                            placeholder={`-- ${intl.formatMessage({id: 'LABEL_OUTLET'})} --`}
                            defaultValue={
                              optionsOutlet
                                ? (optionsOutlet as unknown as any[]).find(
                                    (e: any) => e.value === formik.values.outlet_id
                                  )
                                : ''
                            }
                            onChange={(val, tr) => {
                              let tmp = []
                              let result: any[] = []
                              let label: any = formik.values.outlet_name

                              if (tr.type === 'keydown') {
                                // prevent user select/choice by using click enter
                                return
                              }

                              if (val.length > 0) {
                                if ((tr.target as unknown as any).checked === true) {
                                  tmp = (optionsOutlet as unknown as any[]).find(
                                    (dt) =>
                                      dt.label === (tr.target as unknown as any).labels[0].innerText
                                  )
                                  result = [...label, tmp.label]
                                } else {
                                  if (tr.type === 'click') {
                                    if (
                                      (tr.target as unknown as any).lastChild.nodeName === 'path'
                                    ) {
                                      let tmp_result = label.filter(
                                        (e: any) =>
                                          e !==
                                          (tr.target as unknown as any).parentElement.parentElement
                                            .innerText
                                      )
                                      result = tmp_result
                                    }
                                  } else {
                                    let tmp_result = label.filter(
                                      (e: any) =>
                                        e !== (tr.target as unknown as any).labels[0].innerText
                                    )
                                    result = tmp_result
                                  }
                                }
                                formik.setFieldValue('outlet_id', val)
                                formik.setFieldValue('outlet_name', result)
                              } else {
                                formik.setFieldValue('outlet_id', [])
                                formik.setFieldValue('outlet_name', [])
                              }
                            }}
                            onSelect={(val, ite) => {
                              let value: any = formik.values.outlet_id
                              let label: any = formik.values.outlet_name
                              let tmpValue: any = []
                              let tmpLabel: any = []
                              let resultValue: any[] = []
                              let resultLabel: any[] = []

                              if (val.length > 0) {
                                if (value.indexOf(ite.value) === -1) {
                                  tmpValue = (optionsOutlet as unknown as any[]).find(
                                    (dt) => dt.value === ite.value
                                  )

                                  if (tmpValue === undefined) {
                                    return
                                  }

                                  resultValue = [...value, tmpValue.value]
                                  formik.setFieldValue('outlet_id', resultValue)
                                } else {
                                  tmpValue = value.filter((dt: any) => dt !== ite.value)
                                  formik.setFieldValue('outlet_id', tmpValue)
                                }

                                if (label.indexOf(ite.label) === -1) {
                                  tmpLabel = (optionsOutlet as unknown as any[]).find(
                                    (dt) => dt.label === ite.label
                                  )

                                  if (tmpLabel === undefined) {
                                    return
                                  }

                                  resultLabel = [...label, tmpLabel.label]
                                  formik.setFieldValue('outlet_name', resultLabel)
                                } else {
                                  tmpLabel = label.filter((dt: any) => dt !== ite.label)
                                  formik.setFieldValue('outlet_name', tmpLabel)
                                }
                              } else {
                                formik.setFieldValue('outlet_id', [])
                                formik.setFieldValue('outlet_name', [])
                              }
                            }}
                            value={formik.values.outlet_id}
                            renderExtraFooter={() => (
                              <div style={footerStyles}>
                                <Checkbox
                                  indeterminate={
                                    (formik.values.outlet_id as unknown as any[]).length > 0 &&
                                    (formik.values.outlet_id as unknown as any[]).length <
                                      (optionsOutlet as unknown as any[]).length
                                  }
                                  checked={
                                    (formik.values.outlet_id as unknown as any[]).length ===
                                    (optionsOutlet as unknown as any[]).length
                                  }
                                  onChange={(data, checked) => {
                                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                                    if (checked) {
                                      formik.setFieldValue(
                                        'outlet_id',
                                        (optionsOutlet as unknown as any[]).map((e) => e.value)
                                      )
                                      formik.setFieldValue(
                                        'outlet_name',
                                        (optionsOutlet as unknown as any[]).map((e) => e.label)
                                      )
                                    } else {
                                      formik.setFieldValue('outlet_id', [])
                                      formik.setFieldValue('outlet_name', [])
                                    }
                                  }}
                                >
                                  {(formik.values.outlet_id as unknown as any[]).length ===
                                  (optionsOutlet as unknown as any[]).length
                                    ? intl.formatMessage({id: 'LABEL_UNSELECT_ALL'})
                                    : intl.formatMessage({id: 'LABEL_SELECT_ALL'})}
                                </Checkbox>
                              </div>
                            )}
                          />
                          {formik.errors.outlet_id ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {intl.formatMessage(
                                  {id: 'VALIDATION_REQUIRED_FIELD'},
                                  {
                                    name: intl.formatMessage({id: 'LABEL_OUTLET'}),
                                  }
                                )}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Outlet */}
                      </div>
                      {/* First Col */}

                      {/* Second Col */}
                      <div className='col-lg-6'>
                        {/* Category */}
                        <div className='fv-row mb-5'>
                          {formik.values.category_id !== '' && (
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action === 'edit' ? 'required' : ''
                              }`}
                            >
                              {intl.formatMessage({id: 'LABEL_CATEGORY'})}
                            </label>
                          )}
                          <InputPicker
                            block
                            readOnly={action !== 'edit' ? true : false}
                            data={optionsCategories}
                            placeholder={`-- ${intl.formatMessage({id: 'LABEL_CATEGORY'})} --`}
                            defaultValue={
                              optionsCategories
                                ? (optionsCategories as unknown as any[]).find(
                                    (e: any) => e.value === formik.values.category_id
                                  )
                                : ''
                            }
                            onChange={(e) => {
                              formik.setFieldValue('category_id', e === null ? '' : e)
                            }}
                            onSelect={(val, itm) => {
                              formik.setFieldValue('category_name', val === null ? '' : itm.label)
                            }}
                            value={formik.values.category_id}
                          />
                          {formik.errors.category_id ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {intl.formatMessage(
                                  {id: 'VALIDATION_REQUIRED_FIELD'},
                                  {
                                    name: intl.formatMessage({id: 'LABEL_CATEGORY'}),
                                  }
                                )}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Category */}

                        {/* Name */}
                        <div className='fv-row mb-5'>
                          {formik.values.name !== '' && (
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action === 'edit' ? 'required' : ''
                              }`}
                            >
                              {intl.formatMessage({id: 'LABEL_NAME'})}
                            </label>
                          )}
                          <input
                            type='text'
                            className={clsx(
                              'form-control form-control-solid',
                              {'is-invalid': formik.touched.name && formik.errors.name},
                              {
                                'is-valid': formik.touched.name && !formik.errors.name,
                              }
                            )}
                            placeholder={intl.formatMessage({id: 'LABEL_NAME'})}
                            {...formik.getFieldProps('name')}
                            readOnly={action === 'edit' ? false : true}
                          />
                          {formik.touched.name && formik.errors.name ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {formik.errors.name}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Name */}

                        {/* Order */}
                        <div className='fv-row mb-5'>
                          {formik.values.for_order && formik.values.for_order > 0 && (
                            <label
                              className={`form-label fs-6 fw-bolder text-dark mb-0 ${
                                action === 'edit' ? 'required' : ''
                              }`}
                            >
                              {intl.formatMessage({id: 'LABEL_ORDER'})}
                            </label>
                          )}
                          <br />
                          <small className='text-muted'>
                            &nbsp;{intl.formatMessage({id: 'LABEL_MASTER_ITEMS_ORDER_INFO'})}
                          </small>
                          <input
                            type='number'
                            className={clsx(
                              'form-control form-control-solid',
                              {'is-invalid': formik.touched.for_order && formik.errors.for_order},
                              {
                                'is-valid': formik.touched.for_order && !formik.errors.for_order,
                              }
                            )}
                            placeholder={intl.formatMessage({id: 'LABEL_ORDER'})}
                            {...formik.getFieldProps('for_order')}
                            readOnly={action === 'edit' ? false : true}
                            onChange={(e) => {
                              formik.setFieldValue('for_order', parseInt(e.target.value))
                            }}
                            min={1}
                          />
                          {formik.touched.for_order && formik.errors.for_order ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger'>
                                {formik.errors.for_order}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Order */}
                      </div>
                      {/* Second Col */}
                    </div>

                    <div className='row mt-10'>
                      {/* Third Col */}
                      <div className='col-lg-6'>
                        {/* Description */}
                        <div className='fv-row mb-5'>
                          {formik.values.description !== '' && (
                            <label className={`form-label fs-6 fw-bolder text-dark`}>
                              {intl.formatMessage({id: 'LABEL_DESCRIPTION'})}
                            </label>
                          )}
                          <textarea
                            className={clsx(
                              'form-control form-control-solid',
                              {
                                'is-invalid':
                                  formik.touched.description && formik.errors.description,
                              },
                              {
                                'is-valid':
                                  formik.touched.description && !formik.errors.description,
                              }
                            )}
                            placeholder={intl.formatMessage({id: 'LABEL_DESCRIPTION'})}
                            {...formik.getFieldProps('description')}
                            rows={3}
                            readOnly={action !== 'edit' ? true : false}
                          />
                        </div>
                        {/* Address */}
                      </div>
                      {/* Third Col */}

                      {/* Fourth Col */}
                      <div className='col-lg-6'>
                        {/* Is Default */}
                        <div className='fv-row mb-5'>
                          {formik.values.is_default !== '' && (
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action === 'edit' ? 'required' : ''
                              }`}
                            >
                              {intl.formatMessage({id: 'LABEL_IS_DEFAULT'})}
                            </label>
                          )}
                          <div className='form-check form-switch form-check-custom form-check-solid mt-1'>
                            <Toggle
                              size='lg'
                              readOnly={action !== 'edit' ? true : false}
                              checkedChildren={intl.formatMessage({id: 'LABEL_YES'})}
                              unCheckedChildren={intl.formatMessage({id: 'LABEL_NO'})}
                              checked={formik.values.is_default === 't' ? true : false}
                              onChange={() =>
                                formik.setFieldValue(
                                  'is_default',
                                  formik.values.is_default === 't' ? 'f' : 't'
                                )
                              }
                            />
                          </div>
                          {formik.touched.is_default && formik.errors.is_default ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {formik.errors.is_default}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Is Default */}

                        {formik.values.is_default === 't' && (
                          <>
                            {/* Is Default Parent Category */}
                            <div className='fv-row mb-5'>
                              {formik.values.is_default_parent_category?.category_id !== '' && (
                                <label className='form-label fs-6 fw-bolder text-dark required'>
                                  {intl.formatMessage({id: 'LABEL_IS_DEFAULT_PARENT_CATEGORY'})}
                                </label>
                              )}
                              <InputPicker
                                block
                                readOnly={action !== 'edit' ? true : false}
                                data={optionsCategories}
                                placeholder={`-- ${intl.formatMessage({id: 'LABEL_IS_DEFAULT_PARENT_CATEGORY'})} --`}
                                defaultValue={
                                  optionsCategories
                                    ? (optionsCategories as unknown as any[]).find(
                                        (e: any) =>
                                          e.value ===
                                          formik.values.is_default_parent_category?.category_id
                                      )
                                    : ''
                                }
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    'is_default_parent_category.category_id',
                                    e === null ? '' : e
                                  )
                                }}
                                onSelect={(val, itm) => {
                                  formik.setFieldValue(
                                    'is_default_parent_category.name',
                                    val === null ? '' : itm.label
                                  )
                                }}
                                value={formik.values.is_default_parent_category?.category_id}
                              />
                              {formik.errors.is_default_parent_category ? (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block text-danger fw-bolder'>
                                    {intl.formatMessage(
                                      {id: 'VALIDATION_REQUIRED_FIELD'},
                                      {
                                        name: intl.formatMessage({
                                          id: 'LABEL_IS_DEFAULT_PARENT_CATEGORY',
                                        }),
                                      }
                                    )}
                                  </div>
                                </div>
                              ) : null}
                            </div>
                            {/* Is Default Parent Category */}
                          </>
                        )}

                        {/* Status */}
                        <div className='fv-row mb-5'>
                          {formik.values.status !== '' && (
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action === 'edit' ? 'required' : ''
                              }`}
                            >
                              Status
                            </label>
                          )}
                          <div className='form-check form-switch form-check-custom form-check-solid mt-1'>
                            <Toggle
                              size='lg'
                              readOnly={action !== 'edit' ? true : false}
                              checkedChildren={intl.formatMessage({id: 'LABEL_ACTIVE'})}
                              unCheckedChildren={intl.formatMessage({id: 'LABEL_INACTIVE'})}
                              checked={formik.values.status === 't' ? true : false}
                              onChange={() =>
                                formik.setFieldValue(
                                  'status',
                                  formik.values.status === 't' ? 'f' : 't'
                                )
                              }
                            />
                          </div>
                          {formik.touched.status && formik.errors.status ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {formik.errors.status}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Status */}
                      </div>
                      {/* Fourth Col */}
                    </div>
                  </div>
                  {/* General Information */}

                  {/* Pricing */}
                  <div
                    className={clsx('tab-pane', {
                      active: tab === 'pricing',
                    })}
                  >
                    {/* All Ppn & Multiple Sales Type */}
                    <div className='row'>
                      {action === 'edit' && (
                        <>
                          {/* First Col */}
                          <div className='col-lg-6'>
                            {/* All PPN */}
                            <div className='fv-row mb-5'>
                              <label className='form-label fs-6 fw-bolder text-dark'>
                                {intl.formatMessage({id: 'LABEL_ALL_PPN'})}
                              </label>
                              <div className='input-group'>
                                <CurrencyFormat
                                  className='form-control form-control-sm'
                                  value={basePPN}
                                  displayType={'input'}
                                  isNumericString={false}
                                  decimalScale={2}
                                  thousandSeparator={true}
                                  key={`base-ppn`}
                                  onValueChange={(values: any) => {
                                    const {value} = values
                                    let val = value < 1 ? 0 : value > 100 ? 100 : !value ? 0 : value
                                    setBasePPN(Number(val))
                                    formik.setFieldValue('ppn', val)

                                    formik.values.price &&
                                      formik.setFieldValue(
                                        'ppn_price',
                                        parseInt(
                                          Number(formik.values.price * (val / 100)).toFixed(0)
                                        )
                                      )
                                    formik.values.ppn_price &&
                                      formik.values.price &&
                                      formik.setFieldValue(
                                        'final_price',
                                        parseInt(
                                          Number(
                                            parseInt(
                                              Number(formik.values.price * (val / 100)).toFixed(0)
                                            ) + Number(formik.values.price)
                                          ).toFixed(0)
                                        )
                                      )

                                    updateSalesType(
                                      formik,
                                      val,
                                      formik.values.ppn_price,
                                      formik.values.price,
                                      formik.values.final_price,
                                      formik.values.sku,
                                      basePPN
                                    )
                                  }}
                                />
                                <span className='input-group-text' id='base-ppn'>
                                  %
                                </span>
                              </div>
                            </div>
                            {/* All PPN */}
                          </div>
                          {/* First Col */}
                        </>
                      )}

                      {/* Second Col */}
                      <div className='col-lg-6'>
                        {/* Apply Multiple Price */}
                        <div className='fv-row mb-5'>
                          <label className='form-label fs-6 fw-bolder text-dark required'>
                            {intl.formatMessage({id: 'LABEL_APPLY_MULTIPLE_PRICE'})}
                          </label>
                          <div className='form-check form-switch form-check-custom form-check-solid mt-1'>
                            <Toggle
                              size='lg'
                              readOnly={
                                action !== 'edit'
                                  ? true
                                  : formik.values.has_variant === 'f'
                                    ? true
                                    : false
                              }
                              checkedChildren={intl.formatMessage({id: 'LABEL_YES'})}
                              unCheckedChildren={intl.formatMessage({id: 'LABEL_NO'})}
                              checked={
                                formik.values.is_multiple_price_sales_type === 't' ? true : false
                              }
                              onChange={() => {
                                formik.setFieldValue(
                                  'is_multiple_price_sales_type',
                                  formik.values.is_multiple_price_sales_type === 't' ? 'f' : 't'
                                )
                              }}
                            />
                          </div>
                        </div>
                        {/* Apply Multiple Price */}
                      </div>
                      {/* Second Col */}
                    </div>
                    {/* All Ppn & Multiple Sales Type */}

                    {/* Button Manage Variant */}
                    {action === 'edit' && (
                      <>
                        <div className='row'>
                          <div className='col-sm-12'>
                            <div className='d-grid'>
                              <button
                                type='button'
                                className='btn btn-bg-secondary btn-sm'
                                onClick={() => {
                                  // console.log('button manage variant')
                                  setIsDialogVariantOpen(true)
                                  initiateAddTMPVariant()
                                }}
                              >
                                {intl.formatMessage({id: 'LABEL_MANAGE_VARIANT'})}
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {/* Button Manage Variant */}

                    {/* SKU Variant */}
                    {formik.values.variant_items &&
                      formik.values.variant_items.length > 0 &&
                      formik.values.variant_items[0].name !== '' && (
                        <div className='fv-row '>
                          <table className='table table-bordered'>
                            <thead>
                              <tr
                                className='fw-bold fs-6 text-gray-800'
                                style={{backgroundColor: mode === 'light' ? '#e8e8e8' : '#4f545d'}}
                              >
                                <th className='required' style={{width: '50%'}}>
                                  {intl.formatMessage({id: 'LABEL_NAME'})}
                                </th>
                                <th style={{width: '50%'}}>
                                  {intl.formatMessage({id: 'LABEL_SKU'})}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {formik.values.variant_items !== undefined &&
                                formik.values.variant_items.map((variant: any, i: number) => (
                                  <tr key={`tbd-${i}`}>
                                    <td style={{verticalAlign: 'middle'}} className='fw-bold'>
                                      {variant.name}
                                    </td>
                                    <td>
                                      <input
                                        id={`${variant.variant_id}`}
                                        type='text'
                                        className={clsx(
                                          'form-control form-control-solid form-control-sm'
                                        )}
                                        placeholder={intl.formatMessage({id: 'LABEL_SKU'})}
                                        {...formik.getFieldProps(`variant_items[${i}].sku`)}
                                        onChange={(e) => {
                                          formik.setFieldValue(
                                            `variant_items[${i}].sku`,
                                            e.currentTarget.value.toUpperCase()
                                          )

                                          if (e !== null || e !== '') {
                                            setSalesTypeVariantSKU(
                                              formik,
                                              variant.variant_id,
                                              e.currentTarget.value.toUpperCase()
                                            )
                                          }
                                        }}
                                        readOnly={action !== 'edit' ? true : false}
                                      />
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      )}
                    {/* SKU Variant */}

                    {/* Divider */}
                    {formik.values.is_multiple_price_sales_type === 'f' ? (
                      <Divider />
                    ) : (
                      <Divider className='fw-bolder fs-4'>
                        {intl.formatMessage({id: 'LABEL_SALES_TYPE'})}
                      </Divider>
                    )}
                    {/* Divider */}

                    {/* Pricing Form */}
                    {formik.values.is_multiple_price_sales_type === 'f' ? (
                      formik.values.variant_items && formik.values.variant_items.length > 0 ? (
                        <>
                          {/* No Multiple SalesType With Variants */}
                          {/* No Multiple SalesType With Variants */}
                        </>
                      ) : (
                        <>
                          {/* No Multiple SalesType With No Variants */}
                          <div className='row'>
                            {/* First Col */}
                            <div className='col-lg-6'>
                              {/* PPN */}
                              <div className='fv-row mb-5'>
                                <label className='form-label fs-6 fw-bolder text-dark'>
                                  {intl.formatMessage({id: 'LABEL_PPN'})}
                                </label>
                                <div className='input-group'>
                                  <CurrencyFormat
                                    readOnly={action !== 'edit' ? true : false}
                                    className='form-control form-control-sm'
                                    value={formik.values.ppn}
                                    displayType={'input'}
                                    isNumericString={false}
                                    decimalScale={2}
                                    thousandSeparator={true}
                                    key={`ppn-${formik.values.item_id}`}
                                    onValueChange={(values: any) => {
                                      const {value} = values
                                      // ppn
                                      formik.setFieldValue('ppn', value)

                                      // ppn price
                                      formik.values.price &&
                                        formik.setFieldValue(
                                          'ppn_price',
                                          parseInt(
                                            Number(formik.values.price * (value / 100)).toFixed(0)
                                          )
                                        )

                                      // final price
                                      formik.values.ppn_price &&
                                        formik.values.price &&
                                        formik.setFieldValue(
                                          'final_price',
                                          parseInt(
                                            Number(
                                              parseInt(
                                                Number(formik.values.price * (value / 100)).toFixed(
                                                  0
                                                )
                                              ) + Number(formik.values.price)
                                            ).toFixed(0)
                                          )
                                        )

                                      updateSalesType(
                                        formik,
                                        value,
                                        formik.values.ppn_price,
                                        formik.values.price,
                                        formik.values.final_price,
                                        formik.values.sku,
                                        basePPN
                                      )
                                    }}
                                  />
                                  <span className='input-group-text' id='ppn'>
                                    %
                                  </span>
                                </div>
                              </div>
                              {/* PPN */}

                              {/* Price */}
                              <div className='fv-row mb-5'>
                                <label className='form-label fs-6 fw-bolder text-dark'>
                                  {intl.formatMessage({id: 'LABEL_PRICE'})}
                                </label>
                                <div className='input-group'>
                                  <span className='input-group-text' id='price'>
                                    Rp
                                  </span>
                                  <CurrencyFormat
                                    readOnly={action !== 'edit' ? true : false}
                                    className='form-control form-control-sm'
                                    value={formik.values.price}
                                    displayType={'input'}
                                    isNumericString={false}
                                    decimalScale={2}
                                    thousandSeparator={true}
                                    key={`price-${formik.values.item_id}`}
                                    onValueChange={(values: any) => {
                                      const {value} = values
                                      // price
                                      formik.setFieldValue('price', value)

                                      // ppn price
                                      formik.values.ppn &&
                                        formik.setFieldValue(
                                          'ppn_price',
                                          parseInt(
                                            Number(
                                              (Number(value) * (formik.values.ppn / 100)).toFixed(0)
                                            ).toFixed(0)
                                          )
                                        )

                                      // final price
                                      formik.values.ppn &&
                                        formik.setFieldValue(
                                          'final_price',
                                          parseInt(
                                            Number(
                                              Number(
                                                (value * (formik.values.ppn / 100)).toFixed(0)
                                              ) + Number(value)
                                            ).toFixed(0)
                                          )
                                        )

                                      updateSalesType(
                                        formik,
                                        formik.values.ppn,
                                        formik.values.ppn_price,
                                        value,
                                        formik.values.final_price,
                                        formik.values.sku,
                                        basePPN
                                      )
                                    }}
                                  />
                                </div>
                                {formik.errors.price ? (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block text-danger fw-bolder'>
                                      {intl.formatMessage(
                                        {id: 'VALIDATION_REQUIRED_FIELD'},
                                        {
                                          name: intl.formatMessage({id: 'LABEL_PRICE'}),
                                        }
                                      )}
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                              {/* Price */}
                            </div>
                            {/* First Col */}

                            {/* Second Col */}
                            <div className='col-lg-6'>
                              {/* PPN Price*/}
                              <div className='fv-row mb-5'>
                                <label className='form-label fs-6 fw-bolder text-dark'>
                                  {intl.formatMessage({id: 'LABEL_PPN_PRICE'})}
                                </label>
                                <div className='input-group'>
                                  <span className='input-group-text' id='ppn-price'>
                                    Rp
                                  </span>
                                  <CurrencyFormat
                                    className='form-control form-control-sm'
                                    disabled
                                    value={Number(formik.values.ppn_price)}
                                    displayType={'input'}
                                    isNumericString={false}
                                    decimalScale={2}
                                    thousandSeparator={true}
                                    key={`ppn-price-${formik.values.item_id}`}
                                  />
                                </div>
                              </div>
                              {/* PPN Price*/}

                              {/* Final Price*/}
                              <div className='fv-row mb-5'>
                                <label className='form-label fs-6 fw-bolder text-dark text-decoration-underline'>
                                  {intl.formatMessage({id: 'LABEL_FINAL_PRICE'})}
                                </label>
                                <div className='input-group'>
                                  <span className='input-group-text' id='final-price'>
                                    Rp
                                  </span>
                                  <CurrencyFormat
                                    readOnly={action !== 'edit' ? true : false}
                                    className='form-control  form-control-sm fw-bolder'
                                    value={Number(formik.values.final_price)}
                                    displayType={'input'}
                                    isNumericString={false}
                                    decimalScale={2}
                                    thousandSeparator={true}
                                    key={`final-price-${formik.values.item_id}`}
                                    onValueChange={(values: any) => {
                                      const {value} = values
                                      // final price
                                      formik.setFieldValue('final_price', value)

                                      // ppn price
                                      formik.values.ppn &&
                                        formik.setFieldValue(
                                          'ppn_price',
                                          Number(
                                            value -
                                              (Number(value) / (100 + formik.values.ppn)) * 100
                                          ).toFixed(0)
                                        )

                                      // price
                                      formik.values.ppn &&
                                        formik.setFieldValue(
                                          'price',
                                          Number(
                                            value -
                                              (value -
                                                (Number(value) / (100 + formik.values.ppn)) * 100)
                                          ).toFixed(2)
                                        )

                                      updateSalesType(
                                        formik,
                                        formik.values.ppn,
                                        formik.values.ppn_price,
                                        formik.values.price,
                                        value,
                                        formik.values.sku,
                                        basePPN
                                      )
                                    }}
                                  />
                                </div>
                              </div>
                              {/* Final Price*/}

                              {/* SKU */}
                              <div className='fv-row mb-5'>
                                {formik.values.sku !== '' && (
                                  <label className='form-label fs-6 fw-bolder text-dark'>
                                    {intl.formatMessage({id: 'LABEL_SKU'})}
                                  </label>
                                )}
                                <input
                                  type='text'
                                  readOnly={action !== 'edit' ? true : false}
                                  className={clsx(
                                    'form-control  form-control-sm form-control-solid',
                                    {'is-invalid': formik.touched.sku && formik.errors.sku},
                                    {
                                      'is-valid': formik.touched.sku && !formik.errors.sku,
                                    }
                                  )}
                                  placeholder={intl.formatMessage({id: 'LABEL_SKU'})}
                                  {...formik.getFieldProps('sku')}
                                  onChange={(e) => {
                                    formik.setFieldValue('sku', e.target.value.toUpperCase())
                                    updateSalesType(
                                      formik,
                                      formik.values.ppn,
                                      formik.values.ppn_price,
                                      formik.values.price,
                                      formik.values.final_price,
                                      e.target.value.toUpperCase(),
                                      basePPN
                                    )
                                  }}
                                />
                                {formik.touched.sku && formik.errors.sku ? (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block text-danger fw-bolder'>
                                      {formik.errors.sku}
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                              {/* SKU */}
                            </div>
                            {/* Second Col */}
                          </div>
                          {/* No Multiple SalesType With No Variants */}
                        </>
                      )
                    ) : (
                      <>
                        {/* ALL SKU No Variant */}
                        {formik.values.variant_items &&
                          formik.values.variant_items.length === 0 && (
                            <div className='row'>
                              {/* SKU */}
                              <div className='fv-row mb-5'>
                                {formik.values.sku !== '' && (
                                  <label className='form-label fs-6 fw-bolder text-dark'>
                                    {intl.formatMessage({id: 'LABEL_SKU'})}
                                  </label>
                                )}
                                <input
                                  type='text'
                                  readOnly={action !== 'edit' ? true : false}
                                  className={clsx(
                                    'form-control form-control-solid',
                                    {'is-invalid': formik.touched.sku && formik.errors.sku},
                                    {
                                      'is-valid': formik.touched.sku && !formik.errors.sku,
                                    }
                                  )}
                                  placeholder={intl.formatMessage({id: 'LABEL_SKU'})}
                                  {...formik.getFieldProps('sku')}
                                  onChange={(e) => {
                                    formik.setFieldValue('sku', e.target.value.toUpperCase())

                                    if (e !== null || e !== '') {
                                      setSalesTypeNoVariantSKU(formik, e.target.value.toUpperCase())
                                    }
                                  }}
                                />
                              </div>
                              {/* SKU */}
                            </div>
                          )}
                        {/* ALL SKU No Variant */}

                        {/* Sales Type Price */}
                        {formik.values.sales_type_items &&
                          formik.values.sales_type_items.map((salesTypeItems: any, i: number) => (
                            <>
                              {/* Sales Type Label */}
                              <div className='fv-row mb-2'>
                                <label className='form-label fs-6 fw-bolder text-dark'>
                                  {salesTypeItems.name}
                                </label>
                                <div className='form-check form-switch form-check-custom form-check-solid mt-1'>
                                  <Toggle
                                    readOnly={action !== 'edit' ? true : false}
                                    key={salesTypeItems.sales_type_id}
                                    size='lg'
                                    checkedChildren={intl.formatMessage({id: 'LABEL_YES'})}
                                    unCheckedChildren={intl.formatMessage({id: 'LABEL_NO'})}
                                    checked={salesTypeItems.is_include === 't' ? true : false}
                                    onChange={() => {
                                      formik.setFieldValue(
                                        `sales_type_items[${i}].is_include`,
                                        formik.values.sales_type_items &&
                                          formik.values.sales_type_items[i].is_include === 't'
                                          ? 'f'
                                          : 't'
                                      )
                                      setSalesTypeVariantInclude(
                                        formik,
                                        salesTypeItems.sales_type_id,
                                        formik.values.sales_type_items &&
                                          formik.values.sales_type_items[i].is_include === 't'
                                          ? 'f'
                                          : 't',
                                        basePPN
                                      )
                                    }}
                                  />
                                  <label
                                    className='form-label fs-6 fw-bolder text-muted'
                                    style={{paddingLeft: '10px', paddingTop: '5px'}}
                                  >
                                    {intl.formatMessage({id: 'LABEL_SALES_TYPE_IS_INCLUDE'})}
                                  </label>
                                </div>
                              </div>
                              {/* Sales Type Label */}

                              {/* Sales Type Price Variant */}
                              <div className='fv-row'>
                                <table className='table table-bordered'>
                                  <thead>
                                    <tr
                                      className='fw-bold fs-6 text-gray-800'
                                      style={{
                                        backgroundColor: mode === 'light' ? '#e8e8e8' : '#4f545d',
                                      }}
                                    >
                                      {formik.values.variant_items &&
                                        formik.values.variant_items.length > 0 && (
                                          <>
                                            <th style={{width: '15%'}}>
                                              {intl.formatMessage({id: 'LABEL_IS_VARIANT'})}
                                            </th>
                                            <th className='required' style={{width: '5%'}}>
                                              {intl.formatMessage({id: 'LABEL_INCLUDE'})}
                                            </th>
                                          </>
                                        )}
                                      <th className='required' style={{width: '15%'}}>
                                        {intl.formatMessage({id: 'LABEL_SKU'})}
                                      </th>
                                      <th className='required' style={{width: '14%'}}>
                                        {intl.formatMessage({id: 'LABEL_PPN'})}
                                      </th>
                                      <th className='required' style={{width: '20%'}}>
                                        {intl.formatMessage({id: 'LABEL_PRICE'})}
                                      </th>
                                      <th style={{width: '15%'}}>
                                        {intl.formatMessage({id: 'LABEL_PPN_PRICE'})}
                                      </th>
                                      <th
                                        style={{width: '20%'}}
                                        className='text-decoration-underline'
                                      >
                                        {intl.formatMessage({id: 'LABEL_FINAL_PRICE'})}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {salesTypeItems.sales_type_price &&
                                      salesTypeItems.sales_type_price.length > 0 &&
                                      salesTypeItems.sales_type_price.map(
                                        (salesTypePrice: any, j: number) => (
                                          <tr key={`tbdSales-${j}`}>
                                            {formik.values.variant_items &&
                                              formik.values.variant_items.length > 0 && (
                                                <>
                                                  <td
                                                    style={{verticalAlign: 'middle'}}
                                                    className='fw-bold'
                                                  >
                                                    {salesTypePrice.name}
                                                  </td>
                                                  <td>
                                                    <div className='form-check form-switch form-check-custom form-check-solid mt-1'>
                                                      <Toggle
                                                        readOnly={
                                                          formik.values.has_variant === 't'
                                                            ? action !== 'edit'
                                                              ? true
                                                              : false
                                                            : false
                                                        }
                                                        size='lg'
                                                        checkedChildren={intl.formatMessage({
                                                          id: 'LABEL_YES',
                                                        })}
                                                        unCheckedChildren={intl.formatMessage({
                                                          id: 'LABEL_NO',
                                                        })}
                                                        checked={
                                                          formik.values.sales_type_items &&
                                                          formik.values.sales_type_items[i]
                                                            .sales_type_price[j].is_include === 't'
                                                            ? true
                                                            : false
                                                        }
                                                        onChange={() => {
                                                          formik.setFieldValue(
                                                            `sales_type_items[${i}].sales_type_price[${j}].is_include`,
                                                            formik.values.sales_type_items &&
                                                              formik.values.sales_type_items[i]
                                                                .sales_type_price[j].is_include ===
                                                                't'
                                                              ? 'f'
                                                              : 't'
                                                          )
                                                        }}
                                                      />
                                                    </div>
                                                  </td>
                                                </>
                                              )}
                                            <td>
                                              <input
                                                id={`sku-${salesTypePrice.variant_id}-${salesTypeItems.sales_type_id}`}
                                                type='text'
                                                className={clsx(
                                                  'form-control form-control-solid form-control-sm'
                                                )}
                                                placeholder={
                                                  salesTypeItems.is_include === 't' &&
                                                  salesTypeItems.sales_type_price[j].is_include ===
                                                    't'
                                                    ? intl.formatMessage({id: 'LABEL_SKU'})
                                                    : intl.formatMessage({
                                                        id: 'LABEL_NOT_INCLUDE',
                                                      })
                                                }
                                                {...formik.getFieldProps(`variant_items[${j}].sku`)}
                                                disabled
                                              />
                                            </td>
                                            {/* PPN  */}
                                            <td>
                                              <div className='input-group input-group-sm'>
                                                <CurrencyFormat
                                                  readOnly={action !== 'edit' ? true : false}
                                                  className='form-control'
                                                  value={
                                                    formik.values.sales_type_items &&
                                                    formik.values.sales_type_items[i]
                                                      .sales_type_price[j].is_include === 't'
                                                      ? formik.values.sales_type_items &&
                                                        formik.values.sales_type_items[i]
                                                          .sales_type_price[j].ppn
                                                      : 0
                                                  }
                                                  displayType={'input'}
                                                  isNumericString={false}
                                                  decimalScale={2}
                                                  thousandSeparator={true}
                                                  key={`ppn-${
                                                    formik.values.sales_type_items &&
                                                    formik.values.sales_type_items[i]
                                                      .sales_type_price[j]
                                                  }`}
                                                  onValueChange={(values: any) => {
                                                    const {value} = values

                                                    // ppn
                                                    formik.setFieldValue(
                                                      `sales_type_items[${i}].sales_type_price[${j}].ppn`,
                                                      value
                                                    )

                                                    // ppn price
                                                    formik.values.sales_type_items &&
                                                      formik.setFieldValue(
                                                        `sales_type_items[${i}].sales_type_price[${j}].ppn_price`,
                                                        parseInt(
                                                          Number(
                                                            formik.values.sales_type_items[i]
                                                              .sales_type_price[j].price *
                                                              (value / 100)
                                                          ).toFixed(0)
                                                        )
                                                      )

                                                    // final price
                                                    formik.values.sales_type_items &&
                                                      formik.setFieldValue(
                                                        `sales_type_items[${i}].sales_type_price[${j}].final_price`,
                                                        parseInt(
                                                          Number(
                                                            parseInt(
                                                              Number(
                                                                formik.values.sales_type_items[i]
                                                                  .sales_type_price[j].price *
                                                                  (value / 100)
                                                              ).toFixed(0)
                                                            ) +
                                                              Number(
                                                                formik.values.sales_type_items[i]
                                                                  .sales_type_price[j].price
                                                              )
                                                          ).toFixed(0)
                                                        )
                                                      )
                                                  }}
                                                  disabled={
                                                    formik.values.sales_type_items &&
                                                    formik.values.sales_type_items[i].is_include ===
                                                      't' &&
                                                    formik.values.sales_type_items[i]
                                                      .sales_type_price[j].is_include === 't'
                                                      ? false
                                                      : true
                                                  }
                                                />
                                                <span className='input-group-text' id='ppn'>
                                                  %
                                                </span>
                                              </div>
                                            </td>
                                            {/* Price */}
                                            <td>
                                              <div className='input-group input-group-sm'>
                                                <span className='input-group-text' id='price'>
                                                  Rp
                                                </span>
                                                <CurrencyFormat
                                                  className='form-control'
                                                  readOnly={action !== 'edit' ? true : false}
                                                  value={
                                                    formik.values.sales_type_items &&
                                                    formik.values.sales_type_items[i]
                                                      .sales_type_price[j].is_include === 't'
                                                      ? formik.values.sales_type_items &&
                                                        formik.values.sales_type_items[i]
                                                          .sales_type_price[j].price
                                                      : 0
                                                  }
                                                  displayType={'input'}
                                                  isNumericString={false}
                                                  decimalScale={2}
                                                  thousandSeparator={true}
                                                  key={`price-${
                                                    formik.values.sales_type_items &&
                                                    formik.values.sales_type_items[i]
                                                      .sales_type_price[j]
                                                  }`}
                                                  onValueChange={(values: any) => {
                                                    const {value} = values
                                                    // price
                                                    formik.setFieldValue(
                                                      `sales_type_items[${i}].sales_type_price[${j}].price`,
                                                      value
                                                    )

                                                    // ppn price
                                                    formik.values.sales_type_items &&
                                                      formik.setFieldValue(
                                                        `sales_type_items[${i}].sales_type_price[${j}].ppn_price`,
                                                        parseInt(
                                                          Number(
                                                            (
                                                              Number(value) *
                                                              (formik.values.sales_type_items[i]
                                                                .sales_type_price[j].ppn /
                                                                100)
                                                            ).toFixed(0)
                                                          ).toFixed(0)
                                                        )
                                                      )

                                                    // final price
                                                    formik.values.sales_type_items &&
                                                      formik.setFieldValue(
                                                        `sales_type_items[${i}].sales_type_price[${j}].final_price`,
                                                        parseInt(
                                                          Number(
                                                            Number(
                                                              (
                                                                value *
                                                                (formik.values.sales_type_items[i]
                                                                  .sales_type_price[j].ppn /
                                                                  100)
                                                              ).toFixed(0)
                                                            ) + Number(value)
                                                          ).toFixed(0)
                                                        )
                                                      )
                                                  }}
                                                  disabled={
                                                    formik.values.sales_type_items &&
                                                    formik.values.sales_type_items[i].is_include ===
                                                      't' &&
                                                    formik.values.sales_type_items[i]
                                                      .sales_type_price[j].is_include === 't'
                                                      ? false
                                                      : true
                                                  }
                                                />
                                              </div>
                                            </td>
                                            {/* PPN Price */}
                                            <td>
                                              <div className='input-group input-group-sm'>
                                                <span
                                                  className='input-group-text'
                                                  id={`ppn-price-${
                                                    formik.values.sales_type_items &&
                                                    formik.values.sales_type_items[i]
                                                      .sales_type_price[j]
                                                  }`}
                                                >
                                                  Rp
                                                </span>
                                                <CurrencyFormat
                                                  className='form-control'
                                                  disabled
                                                  value={
                                                    formik.values.sales_type_items &&
                                                    formik.values.sales_type_items[i]
                                                      .sales_type_price[j].is_include === 't'
                                                      ? Number(
                                                          formik.values.sales_type_items &&
                                                            formik.values.sales_type_items[i]
                                                              .sales_type_price[j].ppn_price
                                                        )
                                                      : 0
                                                  }
                                                  displayType={'input'}
                                                  isNumericString={false}
                                                  decimalScale={2}
                                                  thousandSeparator={true}
                                                  key={`ppn-price-${
                                                    formik.values.sales_type_items &&
                                                    formik.values.sales_type_items[i]
                                                      .sales_type_price[j]
                                                  }`}
                                                />
                                              </div>
                                            </td>
                                            {/* Final Price */}
                                            <td>
                                              <div className='input-group input-group-sm'>
                                                <span
                                                  className='input-group-text'
                                                  id={`final-price-${
                                                    formik.values.sales_type_items &&
                                                    formik.values.sales_type_items[i]
                                                      .sales_type_price[j]
                                                  }`}
                                                >
                                                  Rp
                                                </span>
                                                <CurrencyFormat
                                                  readOnly={action !== 'edit' ? true : false}
                                                  className='form-control fw-bolder'
                                                  value={
                                                    formik.values.sales_type_items &&
                                                    formik.values.sales_type_items[i]
                                                      .sales_type_price[j].is_include === 't'
                                                      ? Number(
                                                          formik.values.sales_type_items &&
                                                            formik.values.sales_type_items[i]
                                                              .sales_type_price[j].final_price
                                                        )
                                                      : 0
                                                  }
                                                  displayType={'input'}
                                                  isNumericString={false}
                                                  decimalScale={2}
                                                  thousandSeparator={true}
                                                  key={`final-price-${
                                                    formik.values.sales_type_items &&
                                                    formik.values.sales_type_items[i]
                                                      .sales_type_price[j]
                                                  }`}
                                                  onValueChange={(values: any) => {
                                                    const {value} = values
                                                    // final price
                                                    formik.setFieldValue(
                                                      `sales_type_items[${i}].sales_type_price[${j}].final_price`,
                                                      value
                                                    )

                                                    // ppn price
                                                    formik.values.sales_type_items &&
                                                      formik.setFieldValue(
                                                        `sales_type_items[${i}].sales_type_price[${j}].ppn_price`,
                                                        Number(
                                                          value -
                                                            (Number(value) /
                                                              (100 +
                                                                formik.values.sales_type_items[i]
                                                                  .sales_type_price[j].ppn)) *
                                                              100
                                                        ).toFixed(0)
                                                      )

                                                    // price
                                                    formik.values.sales_type_items &&
                                                      formik.setFieldValue(
                                                        `sales_type_items[${i}].sales_type_price[${j}].price`,
                                                        Number(
                                                          value -
                                                            (value -
                                                              (Number(value) /
                                                                (100 +
                                                                  formik.values.sales_type_items[i]
                                                                    .sales_type_price[j].ppn)) *
                                                                100)
                                                        ).toFixed(2)
                                                      )
                                                  }}
                                                  disabled={
                                                    formik.values.sales_type_items &&
                                                    formik.values.sales_type_items[i].is_include ===
                                                      't' &&
                                                    formik.values.sales_type_items[i]
                                                      .sales_type_price[j].is_include === 't'
                                                      ? false
                                                      : true
                                                  }
                                                />
                                              </div>
                                            </td>
                                          </tr>
                                        )
                                      )}
                                  </tbody>
                                </table>
                              </div>
                              <Divider />
                              {/* Sales Type Price Variant */}
                            </>
                          ))}
                        {/* Sales Type Price */}
                      </>
                    )}
                    {/* Pricing Form */}
                  </div>
                  {/* Pricing */}

                  {/* Period */}
                  <div
                    className={clsx('tab-pane', {
                      active: tab === 'period',
                    })}
                  >
                    <div className='row'>
                      <div className='col-lg-12 mb-5'>
                        <UnderDevelopmentPage />

                        {/* Apply Is Lifetime */}
                        {/* <div className='fv-row mb-5'>
                          <label className='form-label fs-6 fw-bolder text-dark required'>
                            {intl.formatMessage({id: 'LABEL_APPLY_LIFETIME'})}
                          </label>
                          <div className='form-check form-switch form-check-custom form-check-solid mt-1'>
                            <Toggle
                              size='lg'
                              // readOnly={action !== 'edit' ? true : false}
                              readOnly={true}
                              checkedChildren={intl.formatMessage({id: 'LABEL_YES'})}
                              unCheckedChildren={intl.formatMessage({id: 'LABEL_NO'})}
                              checked={formik.values.is_lifetime === 'f' ? true : false}
                              onChange={() => {
                                formik.setFieldValue(
                                  'is_lifetime',
                                  formik.values.is_lifetime === 't' ? 'f' : 't'
                                )
                              }}
                            />
                          </div>
                        </div> */}
                        {/* Apply Is Lifetime */}
                      </div>
                    </div>
                  </div>
                  {/* Period */}

                  {/* Detail */}
                  <div
                    className={clsx('tab-pane', {
                      active: tab === 'log',
                    })}
                  >
                    <FormLog payload={item} formik={formik} />
                  </div>
                  {/* Detail */}
                </div>
              </div>
              {/* Tab Body */}
            </form>
          )}
        </div>
        {/* Form */}
      </Drawer.Body>

      {/* Dialog Variant */}
      <Dialog
        open={isDialogVariantOpen}
        onClose={() => setIsDialogVariantOpen(true)}
        className='modal fade show d-block'
      >
        <Dialog.Panel className='modal-dialog modal-fullscreen'>
          <div className='modal-content'>
            <Dialog.Title className='modal-header'>
              {intl.formatMessage({id: 'LABEL_MANAGE_VARIANT'})}
            </Dialog.Title>
            <div className='modal-body scroll-y mx-2 mx-xl-2 pt-0'>
              {/* Validation Message */}
              {addVariantHasError !== '' && (
                <div className={`m-lg-5 p-2 alert alert-danger`}>
                  <div className='alert-text text-center font-weight-bold fw-bolder'>
                    {addVariantHasError}
                  </div>
                </div>
              )}
              {/* Validation Message */}

              <div className='row'>
                {/* Add Variant Button */}
                <div className='col-sm-12'>
                  <div className='d-grid'>
                    <button
                      type='button'
                      className='btn btn-primary btn-sm'
                      onClick={() => {
                        addTMPVariant()
                      }}
                    >
                      {intl.formatMessage({id: 'LABEL_ADD_VARIANT'})}
                    </button>
                  </div>
                </div>

                {/* Table Form Variant */}
                <div className='col-sm-12'>
                  <table className='table table-bordered'>
                    <thead>
                      <tr
                        className='fw-bold fs-6 text-gray-800'
                        style={{backgroundColor: mode === 'light' ? '#e8e8e8' : '#4f545d'}}
                      >
                        <th className='required' style={{width: '50%'}}>
                          {intl.formatMessage({id: 'LABEL_NAME'})}
                        </th>
                        <th style={{width: '45%'}}>{intl.formatMessage({id: 'LABEL_SKU'})}</th>
                        <th style={{width: '5%', textAlign: 'right'}}>-</th>
                      </tr>
                    </thead>
                    <tbody>
                      {formik.values.tmp_variant_items &&
                        formik.values.tmp_variant_items.length > 0 &&
                        formik.values.tmp_variant_items.map((variant: any, i: number) => (
                          <tr key={`tmp-tbd-${i}`}>
                            <td style={{verticalAlign: 'middle'}} className='fw-bold'>
                              <input
                                id={`tmp-name-${i}`}
                                type='text'
                                className={clsx(
                                  'form-control form-control-solid',
                                  {
                                    'is-invalid':
                                      formik.values.tmp_variant_items &&
                                      formik.values.tmp_variant_items[i].name === '',
                                  },
                                  {
                                    'is-valid':
                                      formik.values.tmp_variant_items &&
                                      formik.values.tmp_variant_items[i].name !== '',
                                  }
                                )}
                                placeholder={intl.formatMessage(
                                  {id: 'VALIDATION_REQUIRED_FIELD'},
                                  {
                                    name: intl.formatMessage({id: 'LABEL_NAME'}),
                                  }
                                )}
                                {...formik.getFieldProps(`tmp_variant_items[${i}].name`)}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    `tmp_variant_items[${i}].name`,
                                    e.currentTarget.value
                                  )
                                }}
                              />
                            </td>
                            <td>
                              <input
                                id={`tmp-sku-${i}`}
                                type='text'
                                className={clsx('form-control form-control-solid', {
                                  'is-valid':
                                    formik.values.tmp_variant_items &&
                                    formik.values.tmp_variant_items[i].sku !== '',
                                })}
                                placeholder={intl.formatMessage({id: 'LABEL_SKU'})}
                                {...formik.getFieldProps(`tmp_variant_items[${i}].sku`)}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    `tmp_variant_items[${i}].sku`,
                                    e.currentTarget.value.toUpperCase()
                                  )
                                }}
                              />
                            </td>
                            <td>
                              <div className='menu-item'>
                                <a
                                  href='#'
                                  className='menu-link'
                                  onClick={() => {
                                    // console.log('button delete item variant')
                                    deleteTMPVariant(
                                      i,
                                      formik.values.tmp_variant_items
                                        ? formik.values.tmp_variant_items[i].variant_id
                                        : ''
                                    )
                                  }}
                                >
                                  <span className='menu-icon' data-kt-element='icon'>
                                    <KTIcon iconName='tablet-delete' className='fs-2 text-danger' />
                                  </span>
                                </a>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className='modal-footer'>
              {formik.values.tmp_variant_items && formik.values.tmp_variant_items?.length === 0 ? (
                <button
                  type='button'
                  className='btn btn-outline btn-btn-outline-info me-3'
                  data-kt-users-modal-action='close'
                  onClick={() => {
                    // console.log('button close form variant')
                    setIsDialogVariantOpen(false)
                    formik.setFieldValue('tmp_variant_items', [])
                  }}
                >
                  {intl.formatMessage({id: 'BUTTON_LABEL_BACK'})}
                </button>
              ) : (
                <button
                  type='button'
                  className={`btn ${loading ? 'btn-secondary' : 'btn-success'}`}
                  data-kt-users-modal-action='submit'
                  onClick={() => {
                    // console.log('button submit form variant')
                    if (
                      formik.values.tmp_variant_items &&
                      formik.values.tmp_variant_items?.length === 0
                    ) {
                      setAddVariantHasError(
                        intl.formatMessage({id: 'RESPONSE_LIBRARY_ITEM_IS_VARIANT_SKU_NOT_ADDED'})
                      )
                      setTimeout(function () {
                        setAddVariantHasError('')
                      }, 5e3)
                      return false
                    } else {
                      let tmp_variant = formik.values.tmp_variant_items
                      let countValidate = 0

                      for (var i in tmp_variant) {
                        if (tmp_variant && tmp_variant[Number(i)].name === '') {
                          countValidate = countValidate + 1
                        }
                      }

                      // sku
                      if (countValidate > 0) {
                        setAddVariantHasError(
                          intl.formatMessage({
                            id: 'RESPONSE_LIBRARY_ITEM_IS_VARIANT_SKU_VALIDATION',
                          })
                        )
                        setTimeout(function () {
                          setAddVariantHasError('')
                        }, 5e3)
                        return false
                      }
                      saveTMPVariant()
                      setIsDialogVariantOpen(false)
                    }
                  }}
                  disabled={loading ? true : false}
                >
                  {!loading && (
                    <span className='indicator-label'>
                      {intl.formatMessage({id: 'BUTTON_LABEL_APPLY'})}
                    </span>
                  )}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      {intl.formatMessage({id: 'BUTTON_LABEL_PLEASE_WAIT'})}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              )}
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
      {/* Dialog Variant */}

      {/* Dialog */}
      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(true)}
        className='modal fade show d-block'
      >
        <Dialog.Panel className='modal-dialog modal-fullscreen'>
          <div className='modal-content'>
            <Dialog.Title className='modal-header'>
              {intl.formatMessage({id: 'DIALOG_HEADER_TITLE'})}
            </Dialog.Title>
            <div className='modal-body scroll-y mx-2 mx-xl-2 pt-0'>
              {/* Tab Header */}
              <div className='card-toolbar'>
                <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-2x' role='tablist'>
                  <li className={`nav-item ${tabAdd === 'general' ? 'fw-bolder' : ''}`}>
                    <a
                      className={clsx(`nav-link cursor-pointer`, {
                        active: tabAdd === 'general',
                      })}
                      onClick={() => setTabAdd('general')}
                      role='tab'
                    >
                      {intl.formatMessage({id: 'LABEL_GENERAL_INFORMATION'})}
                    </a>
                  </li>
                  <li className={`nav-item ${tabAdd === 'pricing' ? 'fw-bolder' : ''}`}>
                    <a
                      className={clsx(`nav-link cursor-pointer`, {
                        active: tabAdd === 'pricing',
                      })}
                      onClick={() => setTabAdd('pricing')}
                      role='tab'
                    >
                      {intl.formatMessage({id: 'LABEL_PRICING'})}
                    </a>
                  </li>
                  <li className={`nav-item ${tabAdd === 'period' ? 'fw-bolder' : ''}`}>
                    <a
                      className={clsx(`nav-link cursor-pointer`, {
                        active: tabAdd === 'period',
                      })}
                      onClick={() => setTabAdd('period')}
                      role='tab'
                    >
                      {intl.formatMessage({id: 'LABEL_PERIOD'})}
                    </a>
                  </li>
                </ul>
              </div>
              {/* Tab Header */}

              {/* Tab Body */}
              <div className='card-body p-0 pt-2'>
                <div className='tab-content'>
                  {/* General Information */}
                  <div
                    className={clsx('tab-pane', {
                      active: tabAdd === 'general',
                    })}
                  >
                    <div className='row'>
                      {/* First Col */}
                      <div className='col-lg-6'>
                        {/* Holding */}
                        <div className='fv-row mb-5'>
                          <label className='form-label fs-6 fw-bolder text-dark'>Holding</label>
                          {models.holding_id !== formik.values.holding_id ? (
                            <>
                              {action !== 'add' && (
                                <input
                                  className='form-control form-control-solid'
                                  value={models.holding_name?.toString().replaceAll(',', ', ')}
                                  readOnly
                                />
                              )}
                              <input
                                className='form-control form-control-solid text-primary is-valid'
                                value={formik.values?.holding_name
                                  ?.toString()
                                  .replaceAll(',', ', ')}
                                readOnly
                              />
                            </>
                          ) : (
                            <input
                              className='form-control form-control-solid'
                              value={formik.values?.holding_name?.toString().replaceAll(',', ', ')}
                              readOnly
                            />
                          )}
                        </div>
                        {/* Holding */}

                        {/* Company */}
                        <div className='fv-row mb-5'>
                          <label className='form-label fs-6 fw-bolder text-dark'>
                            {intl.formatMessage({id: 'LABEL_COMPANY'})}
                          </label>
                          {models.company_id !== formik.values.company_id ? (
                            <>
                              {action !== 'add' && (
                                <input
                                  className='form-control form-control-solid'
                                  value={models.company_name?.toString().replaceAll(',', ', ')}
                                  readOnly
                                />
                              )}
                              <input
                                className='form-control form-control-solid text-primary is-valid'
                                value={formik.values?.company_name
                                  ?.toString()
                                  .replaceAll(',', ', ')}
                                readOnly
                              />
                            </>
                          ) : (
                            <input
                              className='form-control form-control-solid'
                              value={formik.values?.company_name?.toString().replaceAll(',', ', ')}
                              readOnly
                            />
                          )}
                        </div>
                        {/* Company */}

                        {/* Brand */}
                        <div className='fv-row mb-5'>
                          <label className='form-label fs-6 fw-bolder text-dark'>
                            {intl.formatMessage({id: 'LABEL_BRAND'})}
                          </label>
                          {models.brand_id !== formik.values.brand_id ? (
                            <>
                              {action !== 'add' && (
                                <input
                                  className='form-control form-control-solid'
                                  value={models.brand_name?.toString().replaceAll(',', ', ')}
                                  readOnly
                                />
                              )}
                              <input
                                className='form-control form-control-solid text-primary is-valid'
                                value={formik.values?.brand_name?.toString().replaceAll(',', ', ')}
                                readOnly
                              />
                            </>
                          ) : (
                            <input
                              className='form-control form-control-solid'
                              value={formik.values?.brand_name?.toString().replaceAll(',', ', ')}
                              readOnly
                            />
                          )}
                        </div>
                        {/* Brand */}

                        {/* Outlet */}
                        <div className='fv-row mb-5'>
                          <label className='form-label fs-6 fw-bolder text-dark'>Outlet</label>
                          {models.outlet_id !== formik.values.outlet_id ? (
                            <>
                              {action !== 'add' && (
                                <textarea
                                  className='form-control form-control-solid'
                                  value={models.outlet_name?.toString().replaceAll(',', ', ')}
                                  readOnly
                                />
                              )}
                              <textarea
                                className='form-control form-control-solid text-primary is-valid'
                                value={formik.values?.outlet_name?.toString().replaceAll(',', ', ')}
                                readOnly
                              />
                            </>
                          ) : (
                            <textarea
                              className='form-control form-control-solid'
                              value={formik.values?.outlet_name?.toString().replaceAll(',', ', ')}
                              readOnly
                            />
                          )}
                        </div>
                        {/* Outlet */}
                      </div>
                      {/* First Col */}

                      {/* Second Col */}
                      <div className='col-lg-6'>
                        {/* Category */}
                        <div className='fv-row mb-5'>
                          <label className='form-label fs-6 fw-bolder text-dark'>
                            {intl.formatMessage({id: 'LABEL_CATEGORY'})}
                          </label>
                          {models.category_id !== formik.values.category_id ? (
                            <>
                              {action !== 'add' && (
                                <input
                                  className='form-control form-control-solid'
                                  value={models.category_name?.toString().replaceAll(',', ', ')}
                                  readOnly
                                />
                              )}
                              <input
                                className='form-control form-control-solid text-primary is-valid'
                                value={formik.values?.category_name
                                  ?.toString()
                                  .replaceAll(',', ', ')}
                                readOnly
                              />
                            </>
                          ) : (
                            <input
                              className='form-control form-control-solid'
                              value={formik.values?.category_name?.toString().replaceAll(',', ', ')}
                              readOnly
                            />
                          )}
                        </div>
                        {/* Category */}

                        {/* Name */}
                        <div className='fv-row mb-5'>
                          <label className='form-label fs-6 fw-bolder text-dark'>
                            {intl.formatMessage({id: 'LABEL_NAME'})}
                          </label>
                          {models.name !== formik.values.name ? (
                            <>
                              {action !== 'add' && (
                                <input
                                  type='text'
                                  className='form-control form-control-solid'
                                  value={models.name}
                                  readOnly
                                />
                              )}
                              <input
                                type='text'
                                className='form-control form-control-solid text-primary is-valid'
                                value={formik.values.name}
                                readOnly
                              />
                            </>
                          ) : (
                            <input
                              type='text'
                              className='form-control form-control-solid'
                              value={models.name}
                              readOnly
                            />
                          )}
                        </div>
                        {/* Name */}

                        {/* Order */}
                        <div className='fv-row mb-5'>
                          <label className='form-label fs-6 fw-bolder text-dark'>
                            {intl.formatMessage({id: 'LABEL_ORDER'})}
                          </label>
                          {models.for_order?.toString() !== formik.values?.for_order?.toString() ? (
                            <>
                              {action !== 'add' && (
                                <input
                                  type='text'
                                  className='form-control form-control-solid'
                                  value={models.for_order}
                                  readOnly
                                />
                              )}
                              <input
                                type='text'
                                className='form-control form-control-solid text-primary is-valid'
                                value={formik.values.for_order}
                                readOnly
                              />
                            </>
                          ) : (
                            <input
                              type='text'
                              className='form-control form-control-solid'
                              value={models.for_order}
                              readOnly
                            />
                          )}
                        </div>
                        {/* Order */}
                      </div>
                      {/* Second Col */}
                    </div>

                    <div className='row mt-10'>
                      {/* Third Col */}
                      <div className='col-lg-6'>
                        {/* Description */}
                        <div className='fv-row mb-5'>
                          <label className='form-label fs-6 fw-bolder text-dark'>
                            {intl.formatMessage({id: 'LABEL_DESCRIPTION'})}
                          </label>
                          {models.description !== formik.values.description ? (
                            <>
                              {action !== 'add' && (
                                <textarea
                                  className='form-control form-control-solid'
                                  value={models.description}
                                  readOnly
                                />
                              )}
                              <textarea
                                className='form-control form-control-solid text-primary is-valid'
                                value={formik.values.description}
                                readOnly
                              />
                            </>
                          ) : (
                            <textarea
                              className='form-control form-control-solid'
                              value={formik.values.description}
                              readOnly
                            />
                          )}
                        </div>
                        {/* Description */}
                      </div>
                      {/* Third Col */}

                      {/* Fourth Col */}
                      <div className='col-lg-6'>
                        {/* Is Default */}
                        <div className='fv-row mb-5'>
                          <label className='form-label fs-6 fw-bolder text-dark'>
                            <label className='form-label fs-6 fw-bolder text-dark'>
                              {intl.formatMessage({id: 'LABEL_IS_DEFAULT'})}
                            </label>
                          </label>
                          {models.is_default !== formik.values.is_default ? (
                            <>
                              {action !== 'add' && (
                                <input
                                  type='text'
                                  className='form-control form-control-solid'
                                  value={
                                    models.is_default === 't'
                                      ? intl.formatMessage({id: 'LABEL_YES'})
                                      : intl.formatMessage({id: 'LABEL_NO'})
                                  }
                                  readOnly
                                />
                              )}
                              <input
                                type='text'
                                className='form-control form-control-solid text-primary is-valid'
                                value={
                                  formik.values.is_default === 't'
                                    ? intl.formatMessage({id: 'LABEL_YES'})
                                    : intl.formatMessage({id: 'LABEL_NO'})
                                }
                                readOnly
                              />
                            </>
                          ) : (
                            <input
                              type='text'
                              className='form-control form-control-solid'
                              value={
                                models.is_default === 't'
                                  ? intl.formatMessage({id: 'LABEL_YES'})
                                  : intl.formatMessage({id: 'LABEL_NO'})
                              }
                              readOnly
                            />
                          )}
                        </div>
                        {/* Is Default */}

                        {formik.values.is_default === 't' && (
                          <>
                            {/* Is Default Parent Category */}
                            <div className='fv-row mb-5'>
                              <label className='form-label fs-6 fw-bolder text-dark'>
                                {intl.formatMessage({id: 'LABEL_IS_DEFAULT_PARENT_CATEGORY'})}
                              </label>
                              {models.is_default_parent_category &&
                              formik.values.is_default_parent_category &&
                              models.is_default_parent_category.name !==
                                formik.values.is_default_parent_category.name ? (
                                <>
                                  {action !== 'add' && (
                                    <input
                                      className='form-control form-control-solid'
                                      value={
                                        models.is_default_parent_category &&
                                        models.is_default_parent_category.name
                                          ?.toString()
                                          .replaceAll(',', ', ')
                                      }
                                      readOnly
                                    />
                                  )}
                                  <input
                                    className='form-control form-control-solid text-primary is-valid'
                                    value={formik.values.is_default_parent_category.name
                                      ?.toString()
                                      .replaceAll(',', ', ')}
                                    readOnly
                                  />
                                </>
                              ) : (
                                <input
                                  className='form-control form-control-solid'
                                  value={
                                    formik.values.is_default_parent_category &&
                                    formik.values.is_default_parent_category.name
                                      ?.toString()
                                      .replaceAll(',', ', ')
                                  }
                                  readOnly
                                />
                              )}
                            </div>
                            {/* Is Default Parent Category */}
                          </>
                        )}

                        {/* Status */}
                        <div className='fv-row mb-5'>
                          <label className='form-label fs-6 fw-bolder text-dark'>
                            <label className='form-label fs-6 fw-bolder text-dark'>Status</label>
                          </label>
                          {models.status !== formik.values.status ? (
                            <>
                              {action !== 'add' && (
                                <input
                                  type='text'
                                  className='form-control form-control-solid'
                                  value={
                                    models.status === 't'
                                      ? intl.formatMessage({id: 'LABEL_ACTIVE'})
                                      : intl.formatMessage({id: 'LABEL_INACTIVE'})
                                  }
                                  readOnly
                                />
                              )}
                              <input
                                type='text'
                                className='form-control form-control-solid text-primary is-valid'
                                value={
                                  formik.values.status === 't'
                                    ? intl.formatMessage({id: 'LABEL_ACTIVE'})
                                    : intl.formatMessage({id: 'LABEL_INACTIVE'})
                                }
                                readOnly
                              />
                            </>
                          ) : (
                            <input
                              type='text'
                              className='form-control form-control-solid'
                              value={
                                models.status === 't'
                                  ? intl.formatMessage({id: 'LABEL_ACTIVE'})
                                  : intl.formatMessage({id: 'LABEL_INACTIVE'})
                              }
                              readOnly
                            />
                          )}
                        </div>
                        {/* Status */}
                      </div>
                      {/* Fourth Col */}
                    </div>
                  </div>
                  {/* General Information */}

                  {/* Pricing */}
                  <div
                    className={clsx('tab-pane', {
                      active: tabAdd === 'pricing',
                    })}
                  >
                    {/* Is Variant */}
                    <div className='row'>
                      <div className='col-lg-12 mb-5'>
                        <div className='fv-row mb-5'>
                          <label className='form-label fs-6 fw-bolder text-dark'>
                            <label className='form-label fs-6 fw-bolder text-dark'>
                              {intl.formatMessage({id: 'LABEL_APPLY_MULTIPLE_PRICE'})}
                            </label>
                          </label>
                          {models.is_multiple_price_sales_type !==
                          formik.values.is_multiple_price_sales_type ? (
                            <>
                              {action !== 'add' && (
                                <input
                                  type='text'
                                  className='form-control form-control-solid'
                                  value={
                                    models.is_multiple_price_sales_type === 't'
                                      ? intl.formatMessage({id: 'LABEL_YES'})
                                      : intl.formatMessage({id: 'LABEL_NO'})
                                  }
                                  readOnly
                                />
                              )}
                              <input
                                type='text'
                                className='form-control form-control-solid text-primary is-valid'
                                value={
                                  formik.values.is_multiple_price_sales_type === 't'
                                    ? intl.formatMessage({id: 'LABEL_YES'})
                                    : intl.formatMessage({id: 'LABEL_NO'})
                                }
                                readOnly
                              />
                            </>
                          ) : (
                            <input
                              type='text'
                              className='form-control form-control-solid'
                              value={
                                models.is_multiple_price_sales_type === 't'
                                  ? intl.formatMessage({id: 'LABEL_YES'})
                                  : intl.formatMessage({id: 'LABEL_NO'})
                              }
                              readOnly
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    {/* Is Variant */}

                    {/* SKU Variant */}
                    {formik.values.variant_items &&
                      formik.values.variant_items.length > 0 &&
                      formik.values.variant_items[0].name !== '' && (
                        <div className='fv-row '>
                          <table className='table table-bordered'>
                            <thead>
                              <tr
                                className='fw-bold fs-6 text-gray-800'
                                style={{backgroundColor: mode === 'light' ? '#e8e8e8' : '#4f545d'}}
                              >
                                <th style={{width: '50%'}}>
                                  {intl.formatMessage({id: 'LABEL_NAME'})}
                                </th>
                                <th style={{width: '50%'}}>
                                  {intl.formatMessage({id: 'LABEL_SKU'})}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {formik.values.variant_items !== undefined &&
                                formik.values.variant_items.map((variant: any, i: number) => (
                                  <tr key={`tbd-${i}`}>
                                    <td
                                      style={{verticalAlign: 'middle'}}
                                      className={clsx('fw-bold', {
                                        'text-primary':
                                          action === 'edit' &&
                                          models.variant_items &&
                                          formik.values.variant_items &&
                                          models.variant_items[i].name &&
                                          formik.values.variant_items[i].name &&
                                          models.variant_items[i].name !==
                                            formik.values.variant_items[i].name,
                                      })}
                                    >
                                      {variant.name}
                                    </td>
                                    <td>
                                      <input
                                        id={`${variant.variant_id}`}
                                        readOnly
                                        type='text'
                                        className={clsx(
                                          'form-control form-control-solid form-control-sm',
                                          {
                                            'text-primary':
                                              action === 'edit' &&
                                              models.variant_items &&
                                              formik.values.variant_items &&
                                              models.variant_items[i].sku !==
                                                formik.values.variant_items[i].sku,
                                          }
                                        )}
                                        placeholder={intl.formatMessage({id: 'LABEL_SKU'})}
                                        {...formik.getFieldProps(`variant_items[${i}].sku`)}
                                      />
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      )}
                    {/* SKU Variant */}

                    {/* Divider */}
                    {formik.values.is_multiple_price_sales_type === 'f' ? (
                      <Divider />
                    ) : (
                      <Divider className='fw-bolder fs-4'>
                        {intl.formatMessage({id: 'LABEL_SALES_TYPE'})}
                      </Divider>
                    )}
                    {/* Divider */}

                    {/* Pricing Form */}
                    {formik.values.is_multiple_price_sales_type === 'f' ? (
                      formik.values.variant_items && formik.values.variant_items.length > 0 ? (
                        <>
                          {/* No Multiple SalesType With Variants */}
                          {/* No Multiple SalesType With Variants */}
                        </>
                      ) : (
                        <>
                          {/* No Multiple SalesType With No Variants */}
                          <div className='row'>
                            {/* First Col */}
                            <div className='col-lg-6'>
                              {/* PPN */}
                              <div className='fv-row mb-5'>
                                <label className='form-label fs-6 fw-bolder text-dark'>
                                  {intl.formatMessage({id: 'LABEL_PPN'})}
                                </label>
                                <div className='input-group'>
                                  <CurrencyFormat
                                    className={clsx('form-control', {
                                      'text-primary':
                                        models.ppn &&
                                        formik.values.ppn &&
                                        models.ppn !== formik.values.ppn,
                                    })}
                                    readOnly
                                    value={Number(formik.values.ppn)}
                                    displayType={'input'}
                                    isNumericString={false}
                                    decimalScale={2}
                                    thousandSeparator={true}
                                    key={`dialog-ppn-${formik.values.item_id}`}
                                  />
                                  <span className='input-group-text' id='dialog-ppn'>
                                    %
                                  </span>
                                </div>
                              </div>
                              {/* PPN */}

                              {/* Price */}
                              <div className='fv-row mb-5'>
                                <label className='form-label fs-6 fw-bolder text-dark'>
                                  {intl.formatMessage({id: 'LABEL_PRICE'})}
                                </label>
                                <div className='input-group'>
                                  <CurrencyFormat
                                    className={clsx('form-control', {
                                      'text-primary':
                                        models.price &&
                                        formik.values.price &&
                                        models.price !== formik.values.price,
                                    })}
                                    readOnly
                                    value={Number(formik.values.price)}
                                    displayType={'input'}
                                    isNumericString={false}
                                    decimalScale={2}
                                    thousandSeparator={true}
                                    key={`dialog-price-${formik.values.item_id}`}
                                  />
                                  <span className='input-group-text' id='dialog-price'>
                                    %
                                  </span>
                                </div>
                              </div>
                              {/* Price */}
                            </div>
                            {/* First Col */}

                            {/* Second Col */}
                            <div className='col-lg-6'>
                              {/* PPN Price*/}
                              <div className='fv-row mb-5'>
                                <label className='form-label fs-6 fw-bolder text-dark'>
                                  {intl.formatMessage({id: 'LABEL_PPN_PRICE'})}
                                </label>
                                <div className='input-group'>
                                  <CurrencyFormat
                                    className={clsx('form-control', {
                                      'text-primary':
                                        models.ppn_price &&
                                        formik.values.ppn_price &&
                                        models.ppn_price !== formik.values.ppn_price,
                                    })}
                                    readOnly
                                    value={Number(formik.values.ppn_price)}
                                    displayType={'input'}
                                    isNumericString={false}
                                    decimalScale={2}
                                    thousandSeparator={true}
                                    key={`dialog-ppn-price-${formik.values.item_id}`}
                                  />
                                  <span className='input-group-text' id='dialog-ppn-price'>
                                    %
                                  </span>
                                </div>
                              </div>
                              {/* PPN Price*/}

                              {/* Final Price*/}
                              <div className='fv-row mb-5'>
                                <label className='form-label fs-6 fw-bolder text-dark text-decoration-underline'>
                                  {intl.formatMessage({id: 'LABEL_FINAL_PRICE'})}
                                </label>
                                <div className='input-group'>
                                  <CurrencyFormat
                                    className={clsx('form-control fw-bolder', {
                                      'text-primary':
                                        models.final_price &&
                                        formik.values.final_price &&
                                        models.final_price !== formik.values.final_price,
                                    })}
                                    readOnly
                                    value={Number(formik.values.final_price)}
                                    displayType={'input'}
                                    isNumericString={false}
                                    decimalScale={2}
                                    thousandSeparator={true}
                                    key={`dialog-final-price-${formik.values.item_id}`}
                                  />
                                  <span className='input-group-text' id='dialog-final-price'>
                                    %
                                  </span>
                                </div>
                              </div>
                              {/* Final Price*/}

                              {/* SKU */}
                              <div className='fv-row mb-5'>
                                {formik.values.sku !== '' && (
                                  <label className='form-label fs-6 fw-bolder text-dark'>
                                    {intl.formatMessage({id: 'LABEL_SKU'})}
                                  </label>
                                )}
                                <input
                                  type='text'
                                  className={clsx(
                                    'form-control form-control-solid',
                                    {'is-invalid': formik.touched.sku && formik.errors.sku},
                                    {
                                      'is-valid': formik.touched.sku && !formik.errors.sku,
                                    },
                                    {
                                      'text-primary':
                                        models.sku &&
                                        formik.values.sku &&
                                        models.sku !== formik.values.sku,
                                    }
                                  )}
                                  placeholder={intl.formatMessage({id: 'LABEL_SKU'})}
                                  {...formik.getFieldProps('sku')}
                                  readOnly
                                />
                              </div>
                              {/* SKU */}
                            </div>
                            {/* Second Col */}
                          </div>
                          {/* No Multiple SalesType With No Variants */}
                        </>
                      )
                    ) : (
                      <>
                        {/* ALL SKU No Variant */}
                        {formik.values.variant_items &&
                          formik.values.variant_items.length === 0 && (
                            <div className='row'>
                              {/* SKU */}
                              <div className='fv-row mb-5'>
                                {formik.values.sku !== '' && (
                                  <label className='form-label fs-6 fw-bolder text-dark'>
                                    {intl.formatMessage({id: 'LABEL_SKU'})}
                                  </label>
                                )}
                                <input
                                  type='text'
                                  readOnly
                                  className={clsx(
                                    'form-control form-control-solid',
                                    {'is-invalid': formik.touched.sku && formik.errors.sku},
                                    {
                                      'is-valid': formik.touched.sku && !formik.errors.sku,
                                    },
                                    {
                                      'text-primary':
                                        models.sku &&
                                        formik.values.sku &&
                                        models.sku !== formik.values.sku,
                                    }
                                  )}
                                  placeholder={intl.formatMessage({id: 'LABEL_SKU'})}
                                  {...formik.getFieldProps('sku')}
                                />
                              </div>
                              {/* SKU */}
                            </div>
                          )}
                        {/* ALL SKU No Variant */}

                        {/* Sales Type Price */}
                        {formik.values.sales_type_items &&
                          formik.values.sales_type_items.map((salesTypeItems: any, i: number) => (
                            <>
                              {/* Sales Type Label */}
                              <div className='fv-row mb-2'>
                                <label className='form-label fs-6 fw-bolder text-dark'>
                                  {salesTypeItems.name}
                                </label>
                                <div className='form-check form-switch form-check-custom form-check-solid mt-1'>
                                  <Toggle
                                    key={salesTypeItems.sales_type_id}
                                    size='lg'
                                    checkedChildren={intl.formatMessage({id: 'LABEL_YES'})}
                                    unCheckedChildren={intl.formatMessage({id: 'LABEL_NO'})}
                                    checked={salesTypeItems.is_include === 't' ? true : false}
                                    disabled={
                                      formik.values.sales_type_items &&
                                      formik.values.sales_type_items[i].is_include === 't'
                                        ? false
                                        : true
                                    }
                                    readOnly
                                  />
                                  <label
                                    className='form-label fs-6 fw-bolder text-muted'
                                    style={{paddingLeft: '10px', paddingTop: '5px'}}
                                  >
                                    {intl.formatMessage({id: 'LABEL_SALES_TYPE_IS_INCLUDE'})}
                                  </label>
                                </div>
                              </div>
                              {/* Sales Type Label */}

                              {/* Sales Type Price Variant */}
                              <div className='fv-row'>
                                <table className='table table-bordered'>
                                  <thead>
                                    <tr
                                      className='fw-bold fs-6 text-gray-800'
                                      style={{
                                        backgroundColor: mode === 'light' ? '#e8e8e8' : '#4f545d',
                                      }}
                                    >
                                      {formik.values.variant_items &&
                                        formik.values.variant_items.length > 0 && (
                                          <>
                                            <th style={{width: '15%'}}>
                                              {intl.formatMessage({id: 'LABEL_IS_VARIANT'})}
                                            </th>
                                            <th style={{width: '5%'}}>
                                              {intl.formatMessage({id: 'LABEL_INCLUDE'})}
                                            </th>
                                          </>
                                        )}
                                      <th style={{width: '15%'}}>
                                        {intl.formatMessage({id: 'LABEL_SKU'})}
                                      </th>
                                      <th style={{width: '14%'}}>
                                        {intl.formatMessage({id: 'LABEL_PPN'})}
                                      </th>
                                      <th style={{width: '20%'}}>
                                        {intl.formatMessage({id: 'LABEL_PRICE'})}
                                      </th>
                                      <th style={{width: '15%'}}>
                                        {intl.formatMessage({id: 'LABEL_PPN_PRICE'})}
                                      </th>
                                      <th
                                        style={{width: '20%'}}
                                        className='text-decoration-underline'
                                      >
                                        {intl.formatMessage({id: 'LABEL_FINAL_PRICE'})}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {salesTypeItems.sales_type_price &&
                                      salesTypeItems.sales_type_price.length > 0 &&
                                      salesTypeItems.sales_type_price.map(
                                        (salesTypePrice: any, j: number) => (
                                          <tr key={`dialog-tbdSales-${j}`}>
                                            {formik.values.variant_items &&
                                              formik.values.variant_items.length > 0 && (
                                                <>
                                                  <td
                                                    style={{verticalAlign: 'middle'}}
                                                    className={clsx('fw-bold', {
                                                      'text-primary':
                                                        action === 'edit' &&
                                                        models.variant_items &&
                                                        formik.values.variant_items &&
                                                        models.variant_items[j].name !==
                                                          formik.values.variant_items[j].name,
                                                    })}
                                                  >
                                                    {salesTypePrice.name}
                                                  </td>
                                                  <td>
                                                    <div className='form-check form-switch form-check-custom form-check-solid mt-1'>
                                                      <Toggle
                                                        readOnly={
                                                          formik.values.has_variant === 't'
                                                            ? true
                                                            : false
                                                        }
                                                        size='lg'
                                                        checkedChildren={intl.formatMessage({
                                                          id: 'LABEL_YES',
                                                        })}
                                                        unCheckedChildren={intl.formatMessage({
                                                          id: 'LABEL_NO',
                                                        })}
                                                        checked={
                                                          formik.values.sales_type_items &&
                                                          formik.values.sales_type_items[i]
                                                            .sales_type_price[j].is_include === 't'
                                                            ? true
                                                            : false
                                                        }
                                                        disabled={
                                                          formik.values.sales_type_items &&
                                                          formik.values.sales_type_items[i]
                                                            .is_include === 't' &&
                                                          formik.values.sales_type_items[i]
                                                            .sales_type_price[j].is_include === 't'
                                                            ? false
                                                            : true
                                                        }
                                                      />
                                                    </div>
                                                  </td>
                                                </>
                                              )}
                                            <td>
                                              <input
                                                id={`dialog-sku-${salesTypePrice.variant_id}-${salesTypeItems.sales_type_id}`}
                                                type='text'
                                                className={clsx(
                                                  'form-control form-control-solid form-control-sm',
                                                  {
                                                    'text-primary':
                                                      action === 'edit' &&
                                                      models.variant_items &&
                                                      formik.values.variant_items &&
                                                      models.variant_items[j].sku &&
                                                      formik.values.variant_items[j].sku &&
                                                      models.variant_items[j].sku !==
                                                        formik.values.variant_items[j].sku,
                                                  }
                                                )}
                                                placeholder={
                                                  salesTypeItems.is_include === 't' &&
                                                  salesTypeItems.sales_type_price[j].is_include ===
                                                    't'
                                                    ? intl.formatMessage({id: 'LABEL_SKU'})
                                                    : intl.formatMessage({
                                                        id: 'LABEL_NOT_INCLUDE',
                                                      })
                                                }
                                                {...formik.getFieldProps(`variant_items[${j}].sku`)}
                                                disabled={
                                                  formik.values.sales_type_items &&
                                                  formik.values.sales_type_items[i].is_include ===
                                                    't' &&
                                                  formik.values.sales_type_items[i]
                                                    .sales_type_price[j].is_include === 't'
                                                    ? false
                                                    : true
                                                }
                                                readOnly
                                              />
                                            </td>
                                            {/* PPN  */}
                                            <td>
                                              <div className='input-group input-group-sm'>
                                                <CurrencyFormat
                                                  className={clsx('form-control', {
                                                    'text-primary':
                                                      action === 'edit' &&
                                                      models.sales_type_items &&
                                                      formik.values.sales_type_items &&
                                                      models.sales_type_items[i].sales_type_price[j]
                                                        .ppn !==
                                                        formik.values.sales_type_items[i]
                                                          .sales_type_price[j].ppn,
                                                  })}
                                                  value={
                                                    formik.values.sales_type_items &&
                                                    formik.values.sales_type_items[i]
                                                      .sales_type_price[j].is_include === 't'
                                                      ? formik.values.sales_type_items &&
                                                        formik.values.sales_type_items[i]
                                                          .sales_type_price[j].ppn
                                                      : 0
                                                  }
                                                  displayType={'input'}
                                                  isNumericString={false}
                                                  decimalScale={2}
                                                  thousandSeparator={true}
                                                  key={`dialog-ppn-${
                                                    formik.values.sales_type_items &&
                                                    formik.values.sales_type_items[i]
                                                      .sales_type_price[j]
                                                  }`}
                                                  disabled={
                                                    formik.values.sales_type_items &&
                                                    formik.values.sales_type_items[i].is_include ===
                                                      't' &&
                                                    formik.values.sales_type_items[i]
                                                      .sales_type_price[j].is_include === 't'
                                                      ? false
                                                      : true
                                                  }
                                                  readOnly
                                                />
                                                <span className='input-group-text' id='ppn'>
                                                  %
                                                </span>
                                              </div>
                                            </td>
                                            {/* Price */}
                                            <td>
                                              <div className='input-group input-group-sm'>
                                                <span className='input-group-text' id='price'>
                                                  Rp
                                                </span>
                                                <CurrencyFormat
                                                  className={clsx('form-control', {
                                                    'text-primary':
                                                      action === 'edit' &&
                                                      models.sales_type_items &&
                                                      formik.values.sales_type_items &&
                                                      models.sales_type_items[i].sales_type_price[j]
                                                        .price !==
                                                        formik.values.sales_type_items[i]
                                                          .sales_type_price[j].price,
                                                  })}
                                                  value={
                                                    formik.values.sales_type_items &&
                                                    formik.values.sales_type_items[i]
                                                      .sales_type_price[j].is_include === 't'
                                                      ? formik.values.sales_type_items &&
                                                        formik.values.sales_type_items[i]
                                                          .sales_type_price[j].price
                                                      : 0
                                                  }
                                                  displayType={'input'}
                                                  isNumericString={false}
                                                  decimalScale={2}
                                                  thousandSeparator={true}
                                                  key={`dialog-price-${
                                                    formik.values.sales_type_items &&
                                                    formik.values.sales_type_items[i]
                                                      .sales_type_price[j]
                                                  }`}
                                                  disabled={
                                                    formik.values.sales_type_items &&
                                                    formik.values.sales_type_items[i].is_include ===
                                                      't' &&
                                                    formik.values.sales_type_items[i]
                                                      .sales_type_price[j].is_include === 't'
                                                      ? false
                                                      : true
                                                  }
                                                  readOnly
                                                />
                                              </div>
                                            </td>
                                            {/* PPN Price */}
                                            <td>
                                              <div className='input-group input-group-sm'>
                                                <span
                                                  className='input-group-text'
                                                  id={`dialog-ppn-price-${
                                                    formik.values.sales_type_items &&
                                                    formik.values.sales_type_items[i]
                                                      .sales_type_price[j]
                                                  }`}
                                                >
                                                  Rp
                                                </span>
                                                <CurrencyFormat
                                                  className={clsx('form-control', {
                                                    'text-primary':
                                                      action === 'edit' &&
                                                      models.sales_type_items &&
                                                      formik.values.sales_type_items &&
                                                      models.sales_type_items[i].sales_type_price[j]
                                                        .ppn_price !==
                                                        formik.values.sales_type_items[i]
                                                          .sales_type_price[j].ppn_price,
                                                  })}
                                                  value={
                                                    formik.values.sales_type_items &&
                                                    formik.values.sales_type_items[i]
                                                      .sales_type_price[j].is_include === 't'
                                                      ? Number(
                                                          formik.values.sales_type_items &&
                                                            formik.values.sales_type_items[i]
                                                              .sales_type_price[j].ppn_price
                                                        )
                                                      : 0
                                                  }
                                                  displayType={'input'}
                                                  isNumericString={false}
                                                  decimalScale={2}
                                                  thousandSeparator={true}
                                                  key={`ppn-price-${
                                                    formik.values.sales_type_items &&
                                                    formik.values.sales_type_items[i]
                                                      .sales_type_price[j]
                                                  }`}
                                                  disabled={
                                                    formik.values.sales_type_items &&
                                                    formik.values.sales_type_items[i].is_include ===
                                                      't' &&
                                                    formik.values.sales_type_items[i]
                                                      .sales_type_price[j].is_include === 't'
                                                      ? false
                                                      : true
                                                  }
                                                  readOnly
                                                />
                                              </div>
                                            </td>
                                            {/* Final Price */}
                                            <td>
                                              <div className='input-group input-group-sm'>
                                                <span
                                                  className='input-group-text'
                                                  id={`dialog-final-price-${
                                                    formik.values.sales_type_items &&
                                                    formik.values.sales_type_items[i]
                                                      .sales_type_price[j]
                                                  }`}
                                                >
                                                  Rp
                                                </span>
                                                <CurrencyFormat
                                                  className={clsx('form-control fw-bolder', {
                                                    'text-primary':
                                                      action === 'edit' &&
                                                      models.sales_type_items &&
                                                      formik.values.sales_type_items &&
                                                      models.sales_type_items[i].sales_type_price[j]
                                                        .final_price !==
                                                        formik.values.sales_type_items[i]
                                                          .sales_type_price[j].final_price,
                                                  })}
                                                  value={
                                                    formik.values.sales_type_items &&
                                                    formik.values.sales_type_items[i]
                                                      .sales_type_price[j].is_include === 't'
                                                      ? Number(
                                                          formik.values.sales_type_items &&
                                                            formik.values.sales_type_items[i]
                                                              .sales_type_price[j].final_price
                                                        )
                                                      : 0
                                                  }
                                                  displayType={'input'}
                                                  isNumericString={false}
                                                  decimalScale={2}
                                                  thousandSeparator={true}
                                                  key={`final-price-${
                                                    formik.values.sales_type_items &&
                                                    formik.values.sales_type_items[i]
                                                      .sales_type_price[j]
                                                  }`}
                                                  disabled={
                                                    formik.values.sales_type_items &&
                                                    formik.values.sales_type_items[i].is_include ===
                                                      't' &&
                                                    formik.values.sales_type_items[i]
                                                      .sales_type_price[j].is_include === 't'
                                                      ? false
                                                      : true
                                                  }
                                                  readOnly
                                                />
                                              </div>
                                            </td>
                                          </tr>
                                        )
                                      )}
                                  </tbody>
                                </table>
                              </div>
                              <Divider />
                              {/* Sales Type Price Variant */}
                            </>
                          ))}
                        {/* Sales Type Price */}
                      </>
                    )}
                    {/* Pricing Form */}
                  </div>
                  {/* Pricing */}

                  {/* Period */}
                  <div
                    className={clsx('tab-pane', {
                      active: tabAdd === 'period',
                    })}
                  >
                    <div className='row'>
                      <div className='col-lg-12 mb-5'>
                        {/* Is Lifetime */}
                        <div className='fv-row mb-5'>
                          <label className='form-label fs-6 fw-bolder text-dark'>
                            <label className='form-label fs-6 fw-bolder text-dark'>
                              {intl.formatMessage({id: 'LABEL_APPLY_LIFETIME'})}
                            </label>
                          </label>
                          {models.is_lifetime !== formik.values.is_lifetime ? (
                            <>
                              {action !== 'add' && (
                                <input
                                  type='text'
                                  className='form-control form-control-solid'
                                  value={
                                    models.is_lifetime === 'f'
                                      ? intl.formatMessage({id: 'LABEL_YES'})
                                      : intl.formatMessage({id: 'LABEL_NO'})
                                  }
                                  readOnly
                                />
                              )}
                              <input
                                type='text'
                                className='form-control form-control-solid text-primary is-valid'
                                value={
                                  formik.values.is_lifetime === 'f'
                                    ? intl.formatMessage({id: 'LABEL_YES'})
                                    : intl.formatMessage({id: 'LABEL_NO'})
                                }
                                readOnly
                              />
                            </>
                          ) : (
                            <input
                              type='text'
                              className='form-control form-control-solid'
                              value={
                                models.is_lifetime === 'f'
                                  ? intl.formatMessage({id: 'LABEL_YES'})
                                  : intl.formatMessage({id: 'LABEL_NO'})
                              }
                              readOnly
                            />
                          )}
                        </div>
                        {/* Is Lifetime */}
                      </div>
                    </div>
                  </div>
                  {/* Period */}
                </div>
              </div>
              {/* Tab Body */}
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-outline btn-btn-outline-info me-3'
                data-kt-users-modal-action='close'
                onClick={() => setIsDialogOpen(false)}
              >
                {intl.formatMessage({id: 'BUTTON_LABEL_BACK'})}
              </button>

              <button
                type='button'
                className={`btn ${loading ? 'btn-secondary' : 'btn-success'}`}
                data-kt-users-modal-action='submit'
                onClick={() => formik.handleSubmit()}
                disabled={loading ? true : false}
              >
                {!loading && (
                  <span className='indicator-label'>
                    {intl.formatMessage({id: 'BUTTON_LABEL_SUBMIT'})}
                  </span>
                )}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    {intl.formatMessage({id: 'BUTTON_LABEL_PLEASE_WAIT'})}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
      {/* Dialog */}
    </>
  )
}

export {ViewDrawerForm}
