/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {Dialog} from '@headlessui/react'
import clsx from 'clsx'
import {Divider, Drawer, InputPicker} from 'rsuite'
import toast from 'react-hot-toast'

import {useThemeMode} from '_metronic/partials'
import {NotAuthRedirectForm, FormLog, getFormAction, SpinnerIndicator} from 'app/components'
import {getAuth, removeAuth, useAuth} from 'app/modules/auth'

import {
  DetailModel,
  SocialMediaModel,
  addData,
  fetchBrandOptions,
  fetchCompanyOptions,
  fetchHoldingOptions,
  fetchSocialMediaOptions,
  initialData,
  updateData,
} from '../../helpers'
import {useAction, useListQueryResponse} from '../../providers'

type Props = {
  isLoading: boolean
  receipt: DetailModel
  appMenuID: any
  modalID: any
  menuName: any
}

const ViewDrawerForm: FC<Props> = ({menuName, receipt, isLoading, appMenuID, modalID}) => {
  const auth = getAuth()
  const intl = useIntl()
  const {mode} = useThemeMode()
  const [tab, setTab] = useState('data')
  const {currentUser} = useAuth()
  const {
    setItemIdForUpdate,
    action,
    holdingOptions,
    companyOptions,
    brandOptions,
    setHoldingOptions,
    setCompanyOptions,
    setBrandOptions,
  } = useAction()
  const {refetch} = useListQueryResponse()
  const [isAuth, setIsAuth] = useState(true)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [completed, setCompleted] = useState(false)
  const didRequestHolding = useRef(false)
  const didRequestCompany = useRef(false)
  const didRequestBrand = useRef(false)
  const didRequestSocialMedia = useRef(false)
  const [socialMediaOptions, setSocialMediaOptions] = useState<SocialMediaModel>()
  const optionsHolding =
    holdingOptions === undefined
      ? []
      : (holdingOptions as unknown as any[]).map((holdings: any) => ({
          value: holdings.holding_id,
          label: holdings.name,
        }))
  const optionsCompany =
    companyOptions === undefined
      ? []
      : (companyOptions as unknown as any[]).map((company: any) => ({
          value: company.company_id,
          label: company.name,
        }))
  const optionsBrand =
    brandOptions === undefined
      ? []
      : (brandOptions as unknown as any[]).map((brand: any) => ({
          value: brand.brand_id,
          label: brand.name,
        }))

  const baseValidation = Yup.object().shape({
    notes: Yup.string()
      .min(10, intl.formatMessage({id: 'VALIDATION_MIN_LENGTH_FIELD'}, {min: 10}))
      .required(
        intl.formatMessage(
          {id: 'VALIDATION_REQUIRED_FIELD'},
          {name: intl.formatMessage({id: 'LABEL_NOTE'})}
        )
      ),
  })

  const [models] = useState<DetailModel>({
    ...receipt,
    receipt_id: receipt.receipt_id || initialData.receipt_id,
    company_id: receipt.company_id || initialData.company_id,
    company_name: receipt.company?.name || initialData.company?.name,
    holding_id: receipt.holding_id || initialData.holding_id,
    holding_name: receipt.holding?.name || initialData.holding?.name,
    brand_id: receipt.brand_id || initialData.brand_id,
    brand_name: receipt.brand?.name || initialData.brand?.name,
    social_media: receipt.social_media || initialData.social_media,
    notes: receipt.notes || initialData.notes,
    created_at: receipt.created_at || initialData.created_at,
    created_by: receipt.created_by || initialData.created_by,
    updated_at: receipt.updated_at || initialData.updated_at,
    updated_by: receipt.updated_by || initialData.updated_by,
    menu_id: appMenuID,
    creator_id: currentUser?.user_id,
    creator_by: currentUser?.username,
  })

  const formik = useFormik({
    initialValues: models,
    validationSchema: action !== 'delete' ? baseValidation : undefined,
    onSubmit: async (values, {setSubmitting, setStatus, setFieldError}) => {
      setIsDialogOpen(false)
      action === 'edit'
        ? requestUpdateData(values, setStatus, setSubmitting, setFieldError)
        : requestAddData(values, setStatus, setSubmitting, setFieldError)
    },
    onReset: (values) => {
      values = models
    },
  })

  const requestUpdateData = async (
    values: any,
    setStatus: any,
    setSubmitting: any,
    setFieldError: any
  ) => {
    setLoading(true)
    setStatus(null)
    setSubmitting(true)

    // request update to API
    try {
      const res = await updateData(values)

      // throw message error
      if (res?.meta.code !== 200) {
        if (res?.meta.message === 'already exist') {
          setStatus(intl.formatMessage({id: 'RESPONSE_RECEIPT_DATA_ALREADY_EXIST'}))
          setFieldError('brand_id', intl.formatMessage({id: 'RESPONSE_RECEIPT_DATA_ALREADY_EXIST'}))
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_RECEIPT_DATA_EDIT_SUCCESS'}))
        setCompleted(true)
        setTimeout(function () {
          setItemIdForUpdate(undefined)
          refetch()
        }, 1e3)
      }
    } catch (error: any) {
      console.error(error)
      if (error.response.status === 401) {
        setStatus(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
        removeAuth()
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
      }
      setSubmitting(false)
      setLoading(false)
      setTimeout(function () {
        setStatus(null)
      }, 9e3)

      return false
    } finally {
      setTimeout(() => {
        setLoading(false)
        setSubmitting(false)
      }, 5e2)
    }
  }
  const requestAddData = async (
    values: any,
    setStatus: any,
    setSubmitting: any,
    setFieldError: any
  ) => {
    setLoading(true)
    setStatus(null)
    setSubmitting(true)

    // request add to API
    try {
      const res = await addData(values)

      // throw message error
      if (res?.meta.code !== 200) {
        if (res?.meta.message === 'already exist') {
          setStatus(intl.formatMessage({id: 'RESPONSE_RECEIPT_DATA_ALREADY_EXIST'}))
          setFieldError('brand_id', intl.formatMessage({id: 'RESPONSE_RECEIPT_DATA_ALREADY_EXIST'}))
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_RECEIPT_DATA_ADD_SUCCESS'}))
        setCompleted(true)
        setTimeout(function () {
          setItemIdForUpdate(undefined)
          refetch()
        }, 1e3)
      }
    } catch (error: any) {
      console.error(error)
      if (error.response.status === 401) {
        setStatus(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
        removeAuth()
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
      }
      setSubmitting(false)
      setLoading(false)
      setTimeout(function () {
        setStatus(null)
      }, 9e3)

      return false
    } finally {
      setTimeout(() => {
        setLoading(false)
        setSubmitting(false)
      }, 5e2)
    }
  }

  useEffect(() => {
    auth === undefined && setIsAuth(false)
  }, [auth])

  useEffect(() => {
    const requestHoldingOptions = async () => {
      try {
        if (!didRequestHolding.current) {
          const options = await fetchHoldingOptions('input')
          // throw message error
          if (options.data.meta.code === 200) {
            // set option
            setHoldingOptions(options.data.data)
          }
        }
      } catch (error: any) {
        console.error(error)
        if (error.response.status === 401) {
          toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
          removeAuth()
        } else {
          toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
        }
      } finally {
      }

      return () => (didRequestHolding.current = true)
    }
    const requestSocialMediaOptions = async () => {
      try {
        if (!didRequestSocialMedia.current) {
          const options = await fetchSocialMediaOptions('input')
          // throw message error
          if (options.data.meta.code === 200) {
            // set option
            setSocialMediaOptions(options.data.data)
          }
        }
      } catch (error: any) {
        console.error(error)
        if (error.response.status === 401) {
          toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
          removeAuth()
        } else {
          toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
        }
      } finally {
      }

      return () => (didRequestSocialMedia.current = true)
    }

    requestHoldingOptions()
    requestSocialMediaOptions()

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const requestCompanyOptions = async () => {
      try {
        if (!didRequestCompany.current) {
          const options = await fetchCompanyOptions('input', formik.values.holding_id)
          // throw message error
          if (options.data.meta.code === 200) {
            // set options
            setCompanyOptions(options.data.data)
          }
        }
      } catch (error: any) {
        console.error(error)
        if (error.response.status === 401) {
          toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
          removeAuth()
        } else {
          toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
        }
      } finally {
      }

      return () => (didRequestCompany.current = true)
    }

    formik.values.holding_id !== undefined && formik.values.holding_id !== ''
      ? requestCompanyOptions()
      : setCompanyOptions(undefined)

    // eslint-disable-next-line
  }, [formik.values.holding_id])

  useEffect(() => {
    const requestBrandOptions = async () => {
      try {
        if (!didRequestBrand.current) {
          const options = await fetchBrandOptions('input', formik.values.company_id)
          // throw message error
          if (options.data.meta.code === 200) {
            // set options
            setBrandOptions(options.data.data)
          }
        }
      } catch (error: any) {
        console.error(error)
        if (error.response.status === 401) {
          toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
          removeAuth()
        } else {
          toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
        }
      } finally {
      }

      return () => (didRequestBrand.current = true)
    }

    formik.values.company_id !== undefined && formik.values.company_id !== ''
      ? requestBrandOptions()
      : setBrandOptions(undefined)

    // eslint-disable-next-line
  }, [formik.values.company_id])

  return (
    <>
      {!isAuth && <NotAuthRedirectForm />}

      <Drawer.Header>
        <Drawer.Title className='fw-bolder'>
          {isLoading ? (
            <SpinnerIndicator label={intl.formatMessage({id: 'BUTTON_LABEL_PLEASE_WAIT'})} />
          ) : (
            <>
              {getFormAction(
                action,
                intl.formatMessage({id: 'BUTTON_LABEL_VIEW'}),
                intl.formatMessage({id: 'BUTTON_LABEL_EDIT'}),
                intl.formatMessage({id: 'BUTTON_LABEL_ADD'}),
                intl.formatMessage({id: 'BUTTON_LABEL_DELETE'})
              )}{' '}
              {menuName}
            </>
          )}
        </Drawer.Title>
        <Drawer.Actions>
          {action !== 'view' && models !== formik.values && !completed && (
            <button
              type='reset'
              className='btn btn-light me-3'
              data-kt-users-modal-action='reset'
              onClick={formik.handleReset}
              disabled={loading}
            >
              {!loading && (
                <span className='indicator-label'>
                  {intl.formatMessage({id: 'BUTTON_LABEL_RESET'})}
                </span>
              )}
            </button>
          )}

          {action !== 'view' && models !== formik.values && !completed && (
            <button
              type='submit'
              className='btn btn-primary'
              data-kt-users-modal-action='submit'
              onClick={() => {
                if (formik.values.holding_id?.toString().replaceAll(',', ', ') === '') {
                  formik.setFieldError('holding_id', 'validation errors')
                  return false
                }
                if (formik.values.company_id?.toString().replaceAll(',', ', ') === '') {
                  formik.setFieldError('company_id', 'validation errors')
                  return false
                }
                if (formik.values.brand_id?.toString().replaceAll(',', ', ') === '') {
                  formik.setFieldError('brand_id', 'validation errors')
                  return false
                }
                setIsDialogOpen(true)
                setLoading(true)
                setTimeout(function () {
                  setLoading(false)
                }, 2e3)
              }}
              disabled={isLoading || loading || formik.isSubmitting || !formik.isValid}
            >
              {!loading && (
                <span className='indicator-label'>
                  {intl.formatMessage({id: 'BUTTON_LABEL_SUBMIT'})}
                </span>
              )}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {intl.formatMessage({id: 'BUTTON_LABEL_SUBMITTING'})}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          )}

          {action === 'delete' && !completed && (
            <button
              type='submit'
              className='btn btn-danger'
              data-kt-users-modal-action='submit'
              onClick={() => formik.handleSubmit()}
              disabled={isLoading || loading || formik.isSubmitting || !formik.isValid}
            >
              {!loading && (
                <span className='indicator-label'>
                  {intl.formatMessage({id: 'BUTTON_LABEL_DELETE'})}
                </span>
              )}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {intl.formatMessage({id: 'BUTTON_LABEL_SUBMITTING'})}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          )}
        </Drawer.Actions>
      </Drawer.Header>

      <Drawer.Body style={{padding: 0}}>
        {/* Response Message */}
        {formik.status &&
          (formik.status === intl.formatMessage({id: 'RESPONSE_RECEIPT_DATA_ADD_SUCCESS'}) ||
          formik.status === intl.formatMessage({id: 'RESPONSE_RECEIPT_DATA_EDIT_SUCCESS'}) ? (
            <div className='m-lg-5 p-2 bg-light-success rounded'>
              <div className='text-success text-center font-weight-bold fw-bolder'>
                {formik.status}
              </div>
            </div>
          ) : (
            <div className={`m-lg-5 p-2 alert alert-danger`}>
              <div className='alert-text text-center font-weight-bold fw-bolder'>
                {formik.status}
              </div>
            </div>
          ))}
        {/* Response Message */}

        {/* Form */}
        <div className={`modal-body m-5 ${action === 'add' ? '' : ' mt-0'}`}>
          {action === 'add' ? (
            <form id={`${modalID}-form`} className='form' onSubmit={formik.handleSubmit} noValidate>
              <div className='row'>
                {/* Holding */}
                <div className='fv-row mb-5'>
                  {formik.values.holding_id !== '' && (
                    <>
                      <label className='form-label fs-6 fw-bolder text-dark required'>
                        Holding
                      </label>
                      <br />
                    </>
                  )}
                  <InputPicker
                    data={optionsHolding}
                    block
                    placeholder={`-- Holding --`}
                    defaultValue={
                      optionsHolding
                        ? (optionsHolding as unknown as any[]).find(
                            (e: any) => e.value === formik.values.holding_id
                          )
                        : ''
                    }
                    onChange={(e) => {
                      formik.setFieldValue('holding_id', e === null ? '' : e)
                      formik.setFieldValue('company_id', '')
                      formik.setFieldValue('brand_id', '')
                    }}
                    onSelect={(val, itm) => {
                      formik.setFieldValue('holding_name', val === null ? '' : itm.label)
                      formik.setFieldValue('company_name', '')
                      formik.setFieldValue('brand_name', '')
                    }}
                    value={formik.values.holding_id}
                  />
                  {formik.errors.holding_id ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block text-danger fw-bolder'>
                        {intl.formatMessage(
                          {id: 'VALIDATION_REQUIRED_FIELD'},
                          {
                            name: 'Holding',
                          }
                        )}
                      </div>
                    </div>
                  ) : null}
                </div>
                {/* Holding */}

                {/* Company */}
                <div className='fv-row mb-5'>
                  {formik.values.company_id !== '' && (
                    <>
                      <label className='form-label fs-6 fw-bolder text-dark required'>
                        {intl.formatMessage({id: 'LABEL_COMPANY'})}
                      </label>
                      <br />
                    </>
                  )}
                  <InputPicker
                    data={optionsCompany}
                    block
                    disabled={formik.values.holding_id === '' ? true : false}
                    placeholder={`-- ${intl.formatMessage({id: 'LABEL_COMPANY'})} --`}
                    defaultValue={
                      optionsCompany
                        ? (optionsCompany as unknown as any[]).find(
                            (e: any) => e.value === formik.values.company_id
                          )
                        : ''
                    }
                    onChange={(e) => {
                      formik.setFieldValue('company_id', e === null ? '' : e)
                      formik.setFieldValue('brand_id', '')
                    }}
                    onSelect={(val, itm) => {
                      formik.setFieldValue('company_name', val === null ? '' : itm.label)
                      formik.setFieldValue('brand_name', '')
                    }}
                    value={formik.values.company_id}
                  />
                  {formik.errors.company_id ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block text-danger fw-bolder'>
                        {intl.formatMessage(
                          {id: 'VALIDATION_REQUIRED_FIELD'},
                          {
                            name: intl.formatMessage({id: 'LABEL_COMPANY'}),
                          }
                        )}
                      </div>
                    </div>
                  ) : null}
                </div>
                {/* Company */}

                {/* Brand */}
                <div className='fv-row mb-5'>
                  {formik.values.brand_id !== '' && (
                    <>
                      <label className='form-label fs-6 fw-bolder text-dark required'>
                        {intl.formatMessage({id: 'LABEL_BRAND'})}
                      </label>
                      <br />
                    </>
                  )}
                  <InputPicker
                    data={optionsBrand}
                    block
                    disabled={formik.values.company_id === '' ? true : false}
                    placeholder={`-- ${intl.formatMessage({id: 'LABEL_BRAND'})} --`}
                    defaultValue={
                      optionsBrand
                        ? (optionsBrand as unknown as any[]).find(
                            (e: any) => e.value === formik.values.brand_id
                          )
                        : ''
                    }
                    onChange={(e) => {
                      formik.setFieldValue('brand_id', e === null ? '' : e)
                    }}
                    onSelect={(val, itm) => {
                      formik.setFieldValue('brand_name', val === null ? '' : itm.label)
                    }}
                    value={formik.values.brand_id}
                  />
                  {formik.errors.brand_id ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block text-danger fw-bolder'>
                        {intl.formatMessage(
                          {id: 'VALIDATION_REQUIRED_FIELD'},
                          {
                            name: intl.formatMessage({id: 'LABEL_BRAND'}),
                          }
                        )}
                      </div>
                    </div>
                  ) : null}
                </div>
                {/* Brand */}

                {/* Notes */}
                <div className='fv-row mb-5'>
                  {formik.values.notes !== '' && (
                    <label className='form-label fs-6 fw-bolder text-dark required'>
                      {intl.formatMessage({id: 'LABEL_NOTE'})}
                    </label>
                  )}
                  <textarea
                    className={clsx(
                      'form-control form-control-solid',
                      {'is-invalid': formik.touched.notes && formik.errors.notes},
                      {
                        'is-valid': formik.touched.notes && !formik.errors.notes,
                      }
                    )}
                    placeholder={intl.formatMessage({id: 'LABEL_NOTE'})}
                    {...formik.getFieldProps('notes')}
                    rows={5}
                  />
                  {formik.touched.notes && formik.errors.notes ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block text-danger fw-bolder'>
                        {formik.errors.notes}
                      </div>
                    </div>
                  ) : null}
                </div>
                {/* Notes */}
              </div>
              <Divider className='fw-bolder fs-4'>
                {intl.formatMessage({id: 'LABEL_SOCIAL_MEDIA'})}
              </Divider>
              <div className='row'>
                {/* Social Media */}
                {socialMediaOptions ? (
                  <div className='fv-row '>
                    <table className='table table-bordered'>
                      <thead>
                        <tr
                          className='fw-bold fs-6 text-gray-800'
                          style={{backgroundColor: mode === 'light' ? '#e8e8e8' : '#4f545d'}}
                        >
                          <th style={{width: '25%'}}></th>
                          <th style={{width: '75%'}}>{intl.formatMessage({id: 'LABEL_LINK'})}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {(socialMediaOptions as unknown as any[]).map((socmed: any, i: number) => (
                          <tr key={`tbd-${i}`}>
                            <td align='center'>
                              <div className='symbol symbol-50px symbol-circle'>
                                <img alt='Logo' src={socmed.logo} loading='lazy' />
                              </div>
                            </td>
                            <td>
                              <input
                                id={`url-${socmed.social_media_id}`}
                                type='text'
                                className={clsx('form-control form-control-solid form-control-sm')}
                                placeholder={socmed.url}
                                {...formik.getFieldProps(`social_media[${i}].url`)}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    `social_media[${i}].url`,
                                    e.currentTarget.value
                                  )
                                  formik.setFieldValue(
                                    `social_media[${i}].social_media_id`,
                                    socmed.social_media_id
                                  )
                                  formik.setFieldValue(`social_media[${i}].logo`, socmed.logo)
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <></>
                )}
                {/* Social Media */}
              </div>
            </form>
          ) : (
            <form id={`${modalID}-form`} className='form' onSubmit={formik.handleSubmit} noValidate>
              {/* Tab Header */}
              <div className='card-toolbar'>
                <ul
                  className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-2 mt-2'
                  role='tablist'
                >
                  <li className={`nav-item ${tab === 'data' ? 'fw-bolder' : ''}`}>
                    <a
                      className={clsx(`nav-link cursor-pointer`, {
                        active: tab === 'data',
                      })}
                      onClick={() => setTab('data')}
                      role='tab'
                    >
                      Data
                    </a>
                  </li>
                  <li className={`nav-item ${tab === 'log' ? 'fw-bolder' : ''}`}>
                    <a
                      className={clsx(`nav-link cursor-pointer`, {
                        active: tab === 'log',
                      })}
                      onClick={() => setTab('log')}
                      role='tab'
                    >
                      Log
                    </a>
                  </li>
                </ul>
              </div>
              {/* Tab Header */}

              {/* Tab Body */}
              <div className='card-body p-0 pt-2'>
                <div className='tab-content'>
                  {/* Data */}
                  <div
                    className={clsx('tab-pane', {
                      active: tab === 'data',
                    })}
                  >
                    <div className='row'>
                      {/* Holding */}
                      <div className='fv-row mb-5'>
                        {formik.values.holding_id !== '' && (
                          <>
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action === 'edit' ? ' required' : ''
                              }`}
                            >
                              Holding
                            </label>
                            <br />
                          </>
                        )}
                        <InputPicker
                          readOnly={action !== 'edit' ? true : false}
                          data={optionsHolding}
                          block
                          placeholder={`-- Holding --`}
                          defaultValue={
                            optionsHolding
                              ? (optionsHolding as unknown as any[]).find(
                                  (e: any) => e.value === formik.values.holding_id
                                )
                              : ''
                          }
                          onChange={(e) => {
                            formik.setFieldValue('holding_id', e === null ? '' : e)
                            formik.setFieldValue('company_id', '')
                            formik.setFieldValue('brand_id', '')
                          }}
                          onSelect={(val, itm) => {
                            formik.setFieldValue('holding_name', val === null ? '' : itm.label)
                            formik.setFieldValue('company_name', '')
                            formik.setFieldValue('brand_name', '')
                          }}
                          value={formik.values.holding_id}
                        />
                        {formik.errors.holding_id ? (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block text-danger fw-bolder'>
                              {intl.formatMessage(
                                {id: 'VALIDATION_REQUIRED_FIELD'},
                                {
                                  name: 'Holding',
                                }
                              )}
                            </div>
                          </div>
                        ) : null}
                      </div>
                      {/* Holding */}

                      {/* Company */}
                      <div className='fv-row mb-5'>
                        {formik.values.company_id !== '' && (
                          <>
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action === 'edit' ? 'required' : ''
                              }`}
                            >
                              {intl.formatMessage({id: 'LABEL_COMPANY'})}
                            </label>
                            <br />
                          </>
                        )}
                        <InputPicker
                          data={optionsCompany}
                          readOnly={action !== 'edit' ? true : false}
                          block
                          disabled={formik.values.holding_id === '' ? true : false}
                          placeholder={`-- ${intl.formatMessage({id: 'LABEL_COMPANY'})} --`}
                          defaultValue={
                            optionsCompany
                              ? (optionsCompany as unknown as any[]).find(
                                  (e: any) => e.value === formik.values.company_id
                                )
                              : ''
                          }
                          onChange={(e) => {
                            formik.setFieldValue('company_id', e === null ? '' : e)
                            formik.setFieldValue('brand_id', '')
                          }}
                          onSelect={(val, itm) => {
                            formik.setFieldValue('company_name', val === null ? '' : itm.label)
                            formik.setFieldValue('brand_name', '')
                          }}
                          value={formik.values.company_id}
                        />
                        {formik.errors.company_id ? (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block text-danger fw-bolder'>
                              {intl.formatMessage(
                                {id: 'VALIDATION_REQUIRED_FIELD'},
                                {
                                  name: intl.formatMessage({id: 'LABEL_COMPANY'}),
                                }
                              )}
                            </div>
                          </div>
                        ) : null}
                      </div>
                      {/* Company */}

                      {/* Brand */}
                      <div className='fv-row mb-5'>
                        {formik.values.brand_id !== '' && (
                          <>
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action === 'edit' ? 'required' : ''
                              }`}
                            >
                              {intl.formatMessage({id: 'LABEL_BRAND'})}
                            </label>
                            <br />
                          </>
                        )}
                        <InputPicker
                          data={optionsBrand}
                          readOnly={action !== 'edit' ? true : false}
                          block
                          disabled={formik.values.company_id === '' ? true : false}
                          placeholder={`-- ${intl.formatMessage({id: 'LABEL_BRAND'})} --`}
                          defaultValue={
                            optionsBrand
                              ? (optionsBrand as unknown as any[]).find(
                                  (e: any) => e.value === formik.values.brand_id
                                )
                              : ''
                          }
                          onChange={(e) => {
                            formik.setFieldValue('brand_id', e === null ? '' : e)
                          }}
                          onSelect={(val, itm) => {
                            formik.setFieldValue('brand_name', val === null ? '' : itm.label)
                          }}
                          value={formik.values.brand_id}
                        />
                        {formik.errors.brand_id ? (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block text-danger fw-bolder'>
                              {intl.formatMessage(
                                {id: 'VALIDATION_REQUIRED_FIELD'},
                                {
                                  name: intl.formatMessage({id: 'LABEL_BRAND'}),
                                }
                              )}
                            </div>
                          </div>
                        ) : null}
                      </div>
                      {/* Brand */}

                      {/* Notes */}
                      <div className='fv-row mb-5'>
                        {formik.values.notes !== '' && (
                          <label
                            className={`form-label fs-6 fw-bolder text-dark ${
                              action === 'edit' ? 'required' : ''
                            }`}
                          >
                            {intl.formatMessage({id: 'LABEL_NOTE'})}
                          </label>
                        )}
                        <textarea
                          className={clsx(
                            'form-control form-control-solid',
                            {'is-invalid': formik.touched.notes && formik.errors.notes},
                            {
                              'is-valid': formik.touched.notes && !formik.errors.notes,
                            }
                          )}
                          placeholder={intl.formatMessage({id: 'LABEL_NOTE'})}
                          {...formik.getFieldProps('notes')}
                          rows={5}
                          readOnly={action === 'view' ? true : false}
                        />
                        {formik.touched.notes && formik.errors.notes ? (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block text-danger fw-bolder'>
                              {formik.errors.notes}
                            </div>
                          </div>
                        ) : null}
                      </div>
                      {/* Notes */}
                    </div>
                    <Divider className='fw-bolder fs-4'>
                      {intl.formatMessage({id: 'LABEL_SOCIAL_MEDIA'})}
                    </Divider>
                    <div className='row'>
                      {/* Social Media */}
                      {socialMediaOptions ? (
                        <div className='fv-row '>
                          <table className='table table-bordered'>
                            <thead>
                              <tr
                                className='fw-bold fs-6 text-gray-800'
                                style={{
                                  backgroundColor: mode === 'light' ? '#e8e8e8' : '#4f545d',
                                }}
                              >
                                <th style={{width: '25%'}}>
                                  {intl.formatMessage({id: 'LABEL_NAME'})}
                                </th>
                                <th style={{width: '75%'}}>
                                  {intl.formatMessage({id: 'LABEL_LINK'})}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {socialMediaOptions &&
                                (socialMediaOptions as unknown as any[]).map(
                                  (socmed: any, i: number) => (
                                    <tr key={`tbd-${i}`}>
                                      <td align='center'>
                                        <div className='symbol symbol-50px symbol-circle'>
                                          <img alt='Logo' src={socmed.logo} loading='lazy' />
                                        </div>
                                      </td>
                                      <td>
                                        <input
                                          id={`url-${socmed.social_media_id}`}
                                          type='text'
                                          className={clsx(
                                            'form-control form-control-solid form-control-sm'
                                          )}
                                          placeholder={socmed.url}
                                          {...formik.getFieldProps(`social_media[${i}].url`)}
                                          onChange={(e) => {
                                            formik.setFieldValue(
                                              `social_media[${i}].url`,
                                              e.currentTarget.value
                                            )
                                            formik.setFieldValue(
                                              `social_media[${i}].social_media_id`,
                                              socmed.social_media_id
                                            )
                                            formik.setFieldValue(
                                              `social_media[${i}].logo`,
                                              socmed.logo
                                            )
                                          }}
                                          readOnly={action !== 'edit' ? true : false}
                                        />
                                      </td>
                                    </tr>
                                  )
                                )}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <></>
                      )}
                      {/* Social Media */}
                    </div>
                  </div>
                  {/* Data */}

                  {/* Detail */}
                  <div
                    className={clsx('tab-pane', {
                      active: tab === 'log',
                    })}
                  >
                    <FormLog payload={receipt} formik={formik} />
                  </div>
                  {/* Detail */}
                </div>
              </div>
              {/* Tab Body */}
            </form>
          )}
        </div>
        {/* Form */}
      </Drawer.Body>

      {/* Dialog */}
      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(true)}
        className='modal fade show d-block'
      >
        <Dialog.Panel className='modal-dialog modal-fullscreen'>
          <div className='modal-content'>
            <Dialog.Title className='modal-header'>
              {intl.formatMessage({id: 'DIALOG_HEADER_TITLE'})}
            </Dialog.Title>
            <div className='modal-body scroll-y mx-2 mx-xl-2 my-2'>
              <div className='row'>
                {/* Holding */}
                <div className='fv-row mb-5'>
                  <label className='form-label fs-6 fw-bolder text-dark'>Holding</label>
                  {models.holding_id !== formik.values.holding_id ? (
                    <>
                      {action !== 'add' && (
                        <input
                          type='text'
                          className='form-control form-control-solid'
                          value={models.holding_name}
                          readOnly
                        />
                      )}
                      <input
                        type='text'
                        className='form-control form-control-solid text-primary is-valid'
                        value={formik.values.holding_name}
                        readOnly
                      />
                    </>
                  ) : (
                    <input
                      type='text'
                      className='form-control form-control-solid'
                      value={models.holding_name}
                      readOnly
                    />
                  )}
                </div>
                {/* Holding */}

                {/* Company */}
                <div className='fv-row mb-5'>
                  <label className='form-label fs-6 fw-bolder text-dark'>
                    {intl.formatMessage({id: 'LABEL_COMPANY'})}
                  </label>
                  {models.company_id !== formik.values.company_id ? (
                    <>
                      {action !== 'add' && (
                        <input
                          type='text'
                          className='form-control form-control-solid'
                          value={models.company_name}
                          readOnly
                        />
                      )}
                      <input
                        type='text'
                        className='form-control form-control-solid text-primary is-valid'
                        value={formik.values.company_name}
                        readOnly
                      />
                    </>
                  ) : (
                    <input
                      type='text'
                      className='form-control form-control-solid'
                      value={models.company_name}
                      readOnly
                    />
                  )}
                </div>
                {/* Company */}

                {/* Brand */}
                <div className='fv-row mb-5'>
                  <label className='form-label fs-6 fw-bolder text-dark'>
                    {' '}
                    {intl.formatMessage({id: 'LABEL_BRAND'})}
                  </label>
                  {models.brand_id !== formik.values.brand_id ? (
                    <>
                      {action !== 'add' && (
                        <input
                          type='text'
                          className='form-control form-control-solid'
                          value={models.brand_name}
                          readOnly
                        />
                      )}
                      <input
                        type='text'
                        className='form-control form-control-solid text-primary is-valid'
                        value={formik.values.brand_name}
                        readOnly
                      />
                    </>
                  ) : (
                    <input
                      type='text'
                      className='form-control form-control-solid'
                      value={models.brand_name}
                      readOnly
                    />
                  )}
                </div>
                {/* Brand */}

                {/* Notes */}
                <div className='fv-row mb-5'>
                  <label className='form-label fs-6 fw-bolder text-dark'>
                    {intl.formatMessage({id: 'LABEL_NOTE'})}
                  </label>
                  {models.notes !== formik.values.notes ? (
                    <>
                      {action !== 'add' && (
                        <textarea
                          className='form-control form-control-solid'
                          value={models.notes}
                          readOnly
                          rows={5}
                        />
                      )}
                      <textarea
                        className='form-control form-control-solid text-primary is-valid'
                        value={formik.values.notes}
                        readOnly
                        rows={5}
                      />
                    </>
                  ) : (
                    <textarea
                      className='form-control form-control-solid'
                      value={formik.values.notes}
                      readOnly
                      rows={5}
                    />
                  )}
                </div>
                {/* Notes */}
              </div>
              <Divider className='fw-bolder fs-4'>
                {intl.formatMessage({id: 'LABEL_SOCIAL_MEDIA'})}
              </Divider>
              <div className='row'>
                {/* Social Media */}
                {socialMediaOptions ? (
                  <div className='fv-row '>
                    <table className='table table-bordered'>
                      <thead>
                        <tr
                          className='fw-bold fs-6 text-gray-800'
                          style={{backgroundColor: mode === 'light' ? '#e8e8e8' : '#4f545d'}}
                        >
                          <th style={{width: '25%'}}></th>
                          <th style={{width: '75%'}}>{intl.formatMessage({id: 'LABEL_LINK'})}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {socialMediaOptions &&
                          (socialMediaOptions as unknown as any[]).map((socmed: any, i: number) => (
                            <tr key={`tbd-${i}`}>
                              <td align='center'>
                                <div className='symbol symbol-50px symbol-circle'>
                                  <img alt='Logo' src={socmed.logo} loading='lazy' />
                                </div>
                              </td>
                              <td>
                                <input
                                  id={`url-${socmed.social_media_id}`}
                                  readOnly
                                  type='text'
                                  className={'form-control form-control-solid form-control-sm'}
                                  placeholder={socmed.url}
                                  {...formik.getFieldProps(`social_media[${i}].url`)}
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      `social_media[${i}].url`,
                                      e.currentTarget.value
                                    )
                                    formik.setFieldValue(
                                      `social_media[${i}].social_media_id`,
                                      socmed.social_media_id
                                    )
                                    formik.setFieldValue(`social_media[${i}].logo`, socmed.logo)
                                  }}
                                />
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <></>
                )}
                {/* Social Media */}
              </div>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-outline btn-btn-outline-info me-3'
                data-kt-users-modal-action='close'
                onClick={() => setIsDialogOpen(false)}
              >
                {intl.formatMessage({id: 'BUTTON_LABEL_BACK'})}
              </button>

              <button
                type='button'
                className={`btn ${loading ? 'btn-secondary' : 'btn-success'}`}
                data-kt-users-modal-action='submit'
                onClick={() => formik.handleSubmit()}
                disabled={loading ? true : false}
              >
                {!loading && (
                  <span className='indicator-label'>
                    {intl.formatMessage({id: 'BUTTON_LABEL_SUBMIT'})}
                  </span>
                )}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    {intl.formatMessage({id: 'BUTTON_LABEL_PLEASE_WAIT'})}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
      {/* Dialog */}
    </>
  )
}

export {ViewDrawerForm}
