/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {Dialog} from '@headlessui/react'
import clsx from 'clsx'
import {Toggle, CheckPicker, Checkbox, Drawer} from 'rsuite'

import {useLang} from '_metronic/i18n/Metronici18n'
import {FormLog, NotAuthRedirectForm, SpinnerIndicator, getFormAction} from 'app/components'
import {getAuth, removeAuth, useAuth} from 'app/modules/auth'

import * as contextHelper from 'app/context/state/UserGroupModulesStateContext'

import {GetDataByIDModel, addData, deleteData, initialData, updateData} from '../../helpers'
import {useAction, useListQueryResponse} from '../../providers'

const footerStyles = {
  padding: '0px 2px',
  borderTop: '1px solid #e5e5e5',
}

type Props = {
  isLoading: boolean
  userGroup: GetDataByIDModel
  appMenuID: any
  modalID: any
  menuName: any
}

const ViewDrawerForm: FC<Props> = ({menuName, userGroup, isLoading, appMenuID, modalID}) => {
  const [tab, setTab] = useState('data')
  const auth = getAuth()
  const intl = useIntl()
  const local = useLang()
  const {currentUser} = useAuth()
  const {setItemIdForUpdate, action} = useAction()
  const {refetch} = useListQueryResponse()
  const [loading, setLoading] = useState(false)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [isAuth, setIsAuth] = useState(true)
  const [completed, setCompleted] = useState(false)
  const userGroupModules = contextHelper.getUserGroupModules()
  const optionModules =
    userGroupModules === undefined
      ? []
      : (userGroupModules as unknown as any[]).map((module: any) => ({
          value: module.module_id,
          label: local === 'id' ? module.name_idn : module.name_eng,
        }))

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, intl.formatMessage({id: 'VALIDATION_MIN_LENGTH_FIELD'}, {min: 3}))
      .max(120, intl.formatMessage({id: 'VALIDATION_MAX_LENGTH_FIELD'}, {max: 120}))
      .required(
        intl.formatMessage(
          {id: 'VALIDATION_REQUIRED_FIELD'},
          {name: intl.formatMessage({id: 'LABEL_NAME'})}
        )
      ),
    code: Yup.string()
      .min(3, intl.formatMessage({id: 'VALIDATION_MIN_LENGTH_FIELD'}, {min: 3}))
      .max(10, intl.formatMessage({id: 'VALIDATION_MAX_LENGTH_FIELD'}, {max: 10}))
      .required(
        intl.formatMessage(
          {id: 'VALIDATION_REQUIRED_FIELD'},
          {name: intl.formatMessage({id: 'LABEL_CODE'})}
        )
      ),
  })
  const [userGroupModels] = useState<GetDataByIDModel>({
    ...userGroup,
    role_id: userGroup.role_id || initialData.role_id,
    name: userGroup.name || initialData.name,
    code: userGroup.code || initialData.code,
    detail: userGroup.detail || initialData.detail,
    status: userGroup.status || initialData.status,
    module: userGroup.module || initialData.module,
    menu_id: appMenuID,
  })
  const requestUpdateData = async (
    values: any,
    setStatus: any,
    setSubmitting: any,
    setFieldError: any
  ) => {
    setLoading(true)
    setStatus(null)
    setSubmitting(true)

    // request update to API
    try {
      // fix module id is not array
      if (!Array.isArray(values.module.module_id)) {
        values.module.module_id = values.module.module_id.split(',')
        values.module.name_eng = values.module.name_eng.split(',')
        values.module.name_idn = values.module.name_idn.split(',')
      }

      const res = await updateData(values, currentUser?.role.role_id)

      // throw message error
      if (res?.meta.code !== 200) {
        if (res?.meta.message === 'name already used') {
          setStatus(intl.formatMessage({id: 'RESPONSE_USER_GROUP_NAME_ALREADY_USED'}))
          setFieldError('name', intl.formatMessage({id: 'RESPONSE_USER_GROUP_NAME_ALREADY_USED'}))
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
        if (res?.meta.message === 'code already used') {
          setStatus(intl.formatMessage({id: 'RESPONSE_USER_GROUP_CODE_ALREADY_USED'}))
          setFieldError('code', intl.formatMessage({id: 'RESPONSE_USER_GROUP_CODE_ALREADY_USED'}))
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_USER_GROUP_EDIT_SUCCESS'}))
        setCompleted(true)
        setTimeout(function () {
          setItemIdForUpdate(undefined)
        }, 1e3)
        refetch()
      }
    } catch (error: any) {
      console.error(error)
      if (error.response.status === 401) {
        setStatus(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
        removeAuth()
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
      }
      setTimeout(function () {
        setStatus(null)
      }, 9e3)

      return false
    } finally {
      setTimeout(() => {
        setLoading(false)
        setSubmitting(false)
      }, 5e2)
    }
  }
  const requestAddData = async (
    values: any,
    setStatus: any,
    setSubmitting: any,
    setFieldError: any
  ) => {
    setLoading(true)
    setStatus(null)
    setSubmitting(true)

    // request add to API
    try {
      const res = await addData(values, currentUser?.role.role_id)

      // throw message error
      if (res?.meta.code !== 200) {
        if (res?.meta.message === 'name already used') {
          setStatus(intl.formatMessage({id: 'RESPONSE_USER_GROUP_NAME_ALREADY_USED'}))
          setFieldError('name', intl.formatMessage({id: 'RESPONSE_USER_GROUP_NAME_ALREADY_USED'}))
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
        if (res?.meta.message === 'code already used') {
          setStatus(intl.formatMessage({id: 'RESPONSE_USER_GROUP_CODE_ALREADY_USED'}))
          setFieldError('code', intl.formatMessage({id: 'RESPONSE_USER_GROUP_CODE_ALREADY_USED'}))
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_USER_GROUP_ADD_SUCCESS'}))
        setCompleted(true)
        setTimeout(function () {
          setItemIdForUpdate(undefined)
        }, 1e3)
        refetch()
      }
    } catch (error: any) {
      console.error(error)
      if (error.response.status === 401) {
        setStatus(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
        removeAuth()
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
      }
      setTimeout(function () {
        setStatus(null)
      }, 9e3)

      return false
    } finally {
      setTimeout(() => {
        setLoading(false)
        setSubmitting(false)
      }, 5e2)
    }
  }
  const requestDeleteData = async (values: any, setStatus: any, setSubmitting: any) => {
    setLoading(true)
    setStatus(null)
    setSubmitting(true)

    // request delete to API
    try {
      const res = await deleteData(appMenuID, values.role_id, currentUser?.role.role_id)

      // throw message error
      if (res?.meta.code !== 200) {
        if (res?.meta.message === 'user group is not inactive') {
          setStatus(intl.formatMessage({id: 'RESPONSE_USER_GROUP_IS_ACTIVE'}))
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_USER_GROUP_DELETE_SUCCESS'}))
        setCompleted(true)
        setTimeout(function () {
          setItemIdForUpdate(undefined)
        }, 1e3)
        refetch()
      }
    } catch (error: any) {
      console.error(error)
      if (error.response.status === 401) {
        setStatus(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
        removeAuth()
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
      }
      setSubmitting(false)
      setLoading(false)
      setTimeout(function () {
        setStatus(null)
      }, 9e3)

      return false
    } finally {
      setTimeout(() => {
        setLoading(false)
        setSubmitting(false)
      }, 5e2)
    }
  }
  const formik = useFormik({
    initialValues: userGroupModels,
    validationSchema: validationSchema,
    onSubmit: async (values, {setSubmitting, setStatus, setFieldError}) => {
      setIsDialogOpen(false)
      action === 'edit'
        ? requestUpdateData(values, setStatus, setSubmitting, setFieldError)
        : action === 'add'
          ? requestAddData(values, setStatus, setSubmitting, setFieldError)
          : requestDeleteData(values, setStatus, setSubmitting)
    },
    onReset: (values) => {
      values = userGroupModels
    },
  })

  useEffect(() => {
    auth === undefined && setIsAuth(false)
  }, [auth])

  return (
    <>
      {!isAuth && <NotAuthRedirectForm />}

      <Drawer.Header>
        <Drawer.Title className='fw-bolder'>
          {isLoading ? (
            <SpinnerIndicator label={intl.formatMessage({id: 'BUTTON_LABEL_PLEASE_WAIT'})} />
          ) : (
            <>
              {getFormAction(
                action,
                intl.formatMessage({id: 'BUTTON_LABEL_VIEW'}),
                intl.formatMessage({id: 'BUTTON_LABEL_EDIT'}),
                intl.formatMessage({id: 'BUTTON_LABEL_ADD'}),
                intl.formatMessage({id: 'BUTTON_LABEL_DELETE'})
              )}{' '}
              {menuName}
            </>
          )}
        </Drawer.Title>
        <Drawer.Actions>
          {action !== 'view' && userGroupModels !== formik.values && !completed && (
            <button
              type='reset'
              className='btn btn-light me-3'
              data-kt-users-modal-action='reset'
              onClick={formik.handleReset}
              disabled={loading}
            >
              {!loading && (
                <span className='indicator-label'>
                  {intl.formatMessage({id: 'BUTTON_LABEL_RESET'})}
                </span>
              )}
            </button>
          )}

          {action !== 'view' && userGroupModels !== formik.values && !completed && (
            <button
              type='submit'
              className='btn btn-primary'
              data-kt-users-modal-action='submit'
              onClick={() => {
                if (
                  local === 'id' &&
                  formik.values.module?.name_idn?.toString().replaceAll(',', ', ') === ''
                ) {
                  formik.setFieldError('module', 'validation errors')
                  return false
                }
                if (
                  local === 'en' &&
                  formik.values.module?.name_eng?.toString().replaceAll(',', ', ') === ''
                ) {
                  formik.setFieldError('module', 'validation errors')
                  return false
                }

                setIsDialogOpen(true)
                setLoading(true)
                setTimeout(function () {
                  setLoading(false)
                }, 2e3)
              }}
              disabled={isLoading || loading || formik.isSubmitting || !formik.isValid}
            >
              {!loading && (
                <span className='indicator-label'>
                  {intl.formatMessage({id: 'BUTTON_LABEL_SUBMIT'})}
                </span>
              )}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {intl.formatMessage({id: 'BUTTON_LABEL_SUBMITTING'})}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          )}

          {action === 'delete' && !completed && (
            <button
              type='submit'
              className='btn btn-danger'
              data-kt-users-modal-action='submit'
              onClick={() => formik.handleSubmit()}
              disabled={isLoading || loading || formik.isSubmitting || !formik.isValid}
            >
              {!loading && (
                <span className='indicator-label'>
                  {intl.formatMessage({id: 'BUTTON_LABEL_DELETE'})}
                </span>
              )}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {intl.formatMessage({id: 'BUTTON_LABEL_PLEASE_WAIT'})}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          )}
        </Drawer.Actions>
      </Drawer.Header>

      <Drawer.Body style={{padding: 0}}>
        {/* Response Message */}
        {formik.status &&
          (formik.status === intl.formatMessage({id: 'RESPONSE_USER_GROUP_ADD_SUCCESS'}) ||
          formik.status === intl.formatMessage({id: 'RESPONSE_USER_GROUP_EDIT_SUCCESS'}) ||
          formik.status === intl.formatMessage({id: 'RESPONSE_USER_GROUP_DELETE_SUCCESS'}) ? (
            <div className='m-lg-5 p-2 bg-light-success rounded'>
              <div className='text-success text-center font-weight-bold fw-bolder'>
                {formik.status}
              </div>
            </div>
          ) : (
            <div className={`m-lg-5 p-2 alert alert-danger`}>
              <div className='alert-text text-center font-weight-bold fw-bolder'>
                {formik.status}
              </div>
            </div>
          ))}
        {/* Response Message */}

        {/* Form */}
        <div className={`modal-body m-5 ${action === 'add' ? '' : ' mt-0'}`}>
          {action === 'add' ? (
            <form id={`${modalID}-form`} className='form' onSubmit={formik.handleSubmit} noValidate>
              <div className='row'>
                {/* Left Form */}
                <div className='col-lg-6'>
                  {/* Module */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark required'>
                      {intl.formatMessage({id: 'LABEL_MODULE'})}
                    </label>
                    <CheckPicker
                      block
                      placeholder={`-- ${intl.formatMessage({id: 'LABEL_MODULE'})} --`}
                      data={optionModules}
                      defaultValue={
                        optionModules &&
                        formik.values?.module &&
                        formik.values.module?.name_idn &&
                        formik.values.module?.name_eng
                          ? (optionModules as unknown as any[]).filter((e: any) =>
                              local === 'id'
                                ? formik.values.module?.name_idn?.indexOf(e.label) >= 0
                                : formik.values.module?.name_eng?.indexOf(e.label) >= 0
                            )
                          : []
                      }
                      onChange={(val, tr) => {
                        let tmp = []
                        let result: any[] = []
                        let label: any =
                          local === 'id'
                            ? formik.values.module.name_idn
                            : formik.values.module.name_idn

                        if (tr.type === 'keydown') {
                          // prevent user select/choice by using click enter
                          return
                        }

                        if (val.length > 0) {
                          if ((tr.target as unknown as any).checked === true) {
                            tmp = (optionModules as unknown as any[]).find(
                              (dt) => dt.label === (tr.target as unknown as any).labels[0].innerText
                            )
                            result = [...label, tmp.label]
                          } else {
                            if (tr.type === 'click') {
                              if ((tr.target as unknown as any).lastChild.nodeName === 'path') {
                                let tmp_result = label.filter(
                                  (e: any) =>
                                    e !==
                                    (tr.target as unknown as any).parentElement.parentElement
                                      .innerText
                                )
                                result = tmp_result
                              }
                            } else {
                              let tmp_result = label.filter(
                                (e: any) => e !== (tr.target as unknown as any).labels[0].innerText
                              )
                              result = tmp_result
                            }
                          }
                          formik.setFieldValue('module.module_id', val)
                          local === 'id'
                            ? formik.setFieldValue('module.name_idn', result)
                            : formik.setFieldValue('module.name_eng', result)
                        } else {
                          formik.setFieldValue('module.module_id', [])
                          local === 'id'
                            ? formik.setFieldValue('module.name_idn', [])
                            : formik.setFieldValue('module.name_eng', [])
                        }
                      }}
                      onSelect={(val, ite) => {
                        let value: any = formik.values.module.module_id
                        let label: any =
                          local === 'id'
                            ? formik.values.module.name_idn
                            : formik.values.module.name_idn
                        let tmpValue: any = []
                        let tmpLabel: any = []
                        let resultValue: any[] = []
                        let resultLabel: any[] = []

                        if (val.length > 0) {
                          if (value.indexOf(ite.value) === -1) {
                            tmpValue = (optionModules as unknown as any[]).find(
                              (dt) => dt.value === ite.value
                            )

                            if (tmpValue === undefined) {
                              return
                            }

                            resultValue = [...value, tmpValue.value]
                            formik.setFieldValue('module.module_id', resultValue)
                          } else {
                            tmpValue = value.filter((dt: any) => dt !== ite.value)
                            formik.setFieldValue('module.module_id', tmpValue)
                          }

                          if (label.indexOf(ite.label) === -1) {
                            tmpLabel = (optionModules as unknown as any[]).find(
                              (dt) => dt.label === ite.label
                            )

                            if (tmpLabel === undefined) {
                              return
                            }

                            resultLabel = [...label, tmpLabel.label]
                            local === 'id'
                              ? formik.setFieldValue('module.name_idn', resultLabel)
                              : formik.setFieldValue('module.name_eng', resultLabel)
                          } else {
                            tmpLabel = label.filter((dt: any) => dt !== ite.label)
                            local === 'id'
                              ? formik.setFieldValue('module.name_idn', tmpLabel)
                              : formik.setFieldValue('module.name_eng', tmpLabel)
                          }
                        } else {
                          formik.setFieldValue('module.module_id', [])
                          local === 'id'
                            ? formik.setFieldValue('module.name_idn', [])
                            : formik.setFieldValue('module.name_eng', [])
                        }
                      }}
                      value={formik.values.module.module_id}
                      renderExtraFooter={() => (
                        <div style={footerStyles}>
                          <Checkbox
                            indeterminate={
                              (formik.values.module.module_id as unknown as any[]).length > 0 &&
                              (formik.values.module.module_id as unknown as any[]).length <
                                (optionModules as unknown as any[]).length
                            }
                            checked={
                              (formik.values.module.module_id as unknown as any[]).length ===
                              (optionModules as unknown as any[]).length
                            }
                            onChange={(data, checked) => {
                              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                              if (checked) {
                                formik.setFieldValue(
                                  'module.module_id',
                                  (optionModules as unknown as any[]).map((e) => e.value)
                                )
                                local === 'id'
                                  ? formik.setFieldValue(
                                      'module.name_idn',
                                      (optionModules as unknown as any[]).map((e) => e.label)
                                    )
                                  : formik.setFieldValue(
                                      'module.name_eng',
                                      (optionModules as unknown as any[]).map((e) => e.label)
                                    )
                              } else {
                                formik.setFieldValue('module.module_id', [])
                                local === 'id'
                                  ? formik.setFieldValue('module.name_idn', [])
                                  : formik.setFieldValue('module.name_eng', [])
                              }
                            }}
                          >
                            {(formik.values.module.module_id as unknown as any[]).length ===
                            (optionModules as unknown as any[]).length
                              ? intl.formatMessage({id: 'LABEL_UNSELECT_ALL'})
                              : intl.formatMessage({id: 'LABEL_SELECT_ALL'})}
                          </Checkbox>
                        </div>
                      )}
                    />
                    {formik.errors.module ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger fw-bolder'>
                          {intl.formatMessage(
                            {id: 'VALIDATION_REQUIRED_FIELD'},
                            {name: intl.formatMessage({id: 'LABEL_MODULE'})}
                          )}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {/* Module */}

                  {/* Name */}
                  <div className='fv-row mb-5'>
                    {formik.values.name !== '' && (
                      <label className='form-label fs-6 fw-bolder text-dark required'>
                        {intl.formatMessage({id: 'LABEL_NAME'})}
                      </label>
                    )}
                    <input
                      type='text'
                      className={clsx(
                        'form-control form-control-solid',
                        {'is-invalid': formik.touched.name && formik.errors.name},
                        {
                          'is-valid': formik.touched.name && !formik.errors.name,
                        }
                      )}
                      placeholder={intl.formatMessage({id: 'LABEL_NAME'})}
                      {...formik.getFieldProps('name')}
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger fw-bolder'>
                          {formik.errors.name}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {/* Name */}

                  {/* Code */}
                  <div className='fv-row mb-5'>
                    {formik.values.code !== '' && (
                      <label className='form-label fs-6 fw-bolder text-dark required'>
                        {intl.formatMessage({id: 'LABEL_CODE'})}
                      </label>
                    )}
                    <input
                      type='text'
                      className={clsx(
                        'form-control form-control-solid',
                        {'is-invalid': formik.touched.code && formik.errors.code},
                        {
                          'is-valid': formik.touched.code && !formik.errors.code,
                        }
                      )}
                      placeholder={intl.formatMessage({id: 'LABEL_CODE'})}
                      {...formik.getFieldProps('code')}
                      onChange={(e) => {
                        formik.setFieldValue('code', e.target.value.toUpperCase())
                      }}
                    />
                    {formik.touched.code && formik.errors.code ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger fw-bolder'>
                          {formik.errors.code}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {/* Code */}
                </div>
                {/* Left Form */}

                {/* Right Form */}
                <div className='col-lg-6'>
                  {/* Description */}
                  <div className='fv-row mb-5'>
                    {formik.values.detail !== '' && (
                      <label className='form-label fs-6 fw-bolder text-dark'>
                        {intl.formatMessage({id: 'LABEL_DESCRIPTION'})}
                      </label>
                    )}
                    <textarea
                      className={clsx(
                        'form-control form-control-solid',
                        {'is-invalid': formik.touched.detail && formik.errors.detail},
                        {
                          'is-valid': formik.touched.detail && !formik.errors.detail,
                        }
                      )}
                      placeholder={intl.formatMessage({id: 'LABEL_DESCRIPTION'})}
                      {...formik.getFieldProps('detail')}
                      rows={3}
                    />
                  </div>
                  {/* Description */}

                  {/* Status */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark required'>Status</label>
                    <div className='form-check form-switch form-check-custom form-check-solid mt-1'>
                      <Toggle
                        size='lg'
                        checkedChildren={intl.formatMessage({id: 'LABEL_ACTIVE'})}
                        unCheckedChildren={intl.formatMessage({id: 'LABEL_INACTIVE'})}
                        checked={formik.values.status === 't' ? true : false}
                        onChange={() =>
                          formik.setFieldValue('status', formik.values.status === 't' ? 'f' : 't')
                        }
                      />
                    </div>
                  </div>
                  {/* Status */}
                </div>
                {/* Right Form */}
              </div>
            </form>
          ) : (
            <form id={`${modalID}-form`} className='form' onSubmit={formik.handleSubmit} noValidate>
              {/* Tab Header */}
              <div className='card-toolbar'>
                <ul
                  className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-2 mt-2'
                  role='tablist'
                >
                  <li className={`nav-item ${tab === 'data' ? 'fw-bolder' : ''}`}>
                    <a
                      className={clsx(`nav-link cursor-pointer`, {
                        active: tab === 'data',
                      })}
                      onClick={() => setTab('data')}
                      role='tab'
                    >
                      Data
                    </a>
                  </li>
                  <li className={`nav-item ${tab === 'log' ? 'fw-bolder' : ''}`}>
                    <a
                      className={clsx(`nav-link cursor-pointer`, {
                        active: tab === 'log',
                      })}
                      onClick={() => setTab('log')}
                      role='tab'
                    >
                      Log
                    </a>
                  </li>
                </ul>
              </div>
              {/* Tab Header */}

              {/* Tab Body */}
              <div className='card-body p-0 pt-2'>
                <div className='tab-content'>
                  {/* Data */}
                  <div
                    className={clsx('tab-pane', {
                      active: tab === 'data',
                    })}
                  >
                    <div className='row'>
                      {/* Left Form */}
                      <div className='col-lg-6'>
                        {/* Module */}
                        <div className='fv-row mb-5'>
                          <label
                            className={`form-label fs-6 fw-bolder text-dark ${
                              action === 'edit' ? 'required' : ''
                            }`}
                          >
                            {intl.formatMessage({id: 'LABEL_MODULE'})}
                          </label>
                          <CheckPicker
                            readOnly={action !== 'edit' ? true : false}
                            block
                            placeholder={`-- ${intl.formatMessage({id: 'LABEL_MODULE'})} --`}
                            data={optionModules}
                            defaultValue={
                              optionModules &&
                              formik.values?.module &&
                              formik.values.module?.name_idn &&
                              formik.values.module?.name_eng
                                ? (optionModules as unknown as any[]).filter((e: any) =>
                                    local === 'id'
                                      ? formik.values.module?.name_idn?.indexOf(e.label) >= 0
                                      : formik.values.module?.name_eng?.indexOf(e.label) >= 0
                                  )
                                : []
                            }
                            onChange={(val, tr) => {
                              let tmp = []
                              let result: any[] = []
                              let label: any =
                                local === 'id'
                                  ? formik.values.module.module_id.length > 0
                                    ? (formik.values.module.name_idn as unknown as any).split(',')
                                    : []
                                  : formik.values.module.module_id.length > 0
                                    ? (formik.values.module.name_eng as unknown as any).split(',')
                                    : []

                              if (tr.type === 'keydown') {
                                // prevent user select/choice by using click enter
                                return
                              }

                              if (val.length > 0) {
                                if ((tr.target as unknown as any).checked === true) {
                                  tmp = (optionModules as unknown as any[]).find(
                                    (dt) =>
                                      dt.label === (tr.target as unknown as any).labels[0].innerText
                                  )
                                  result = [...label, tmp.label]
                                } else {
                                  if (tr.type === 'click') {
                                    if (
                                      (tr.target as unknown as any).lastChild.nodeName === 'path'
                                    ) {
                                      let tmp_result = label.filter(
                                        (e: any) =>
                                          e !==
                                          (tr.target as unknown as any).parentElement.parentElement
                                            .innerText
                                      )
                                      result = tmp_result
                                    }
                                  } else {
                                    let tmp_result = label.filter(
                                      (e: any) =>
                                        e !== (tr.target as unknown as any).labels[0].innerText
                                    )
                                    result = tmp_result
                                  }
                                }
                                formik.setFieldValue('module.module_id', val)
                                local === 'id'
                                  ? formik.setFieldValue('module.name_idn', result)
                                  : formik.setFieldValue('module.name_eng', result)
                              } else {
                                formik.setFieldValue('module.module_id', [])
                                local === 'id'
                                  ? formik.setFieldValue('module.name_idn', [])
                                  : formik.setFieldValue('module.name_eng', [])
                              }
                            }}
                            onSelect={(val, ite) => {
                              let value: any =
                                formik.values.module.module_id.length > 0
                                  ? (formik.values.module.module_id as unknown as any).split(',')
                                  : []

                              let label: any =
                                local === 'id'
                                  ? formik.values.module.module_id.length > 0
                                    ? (formik.values.module.name_idn as unknown as any).split(',')
                                    : []
                                  : formik.values.module.module_id.length > 0
                                    ? (formik.values.module.name_eng as unknown as any).split(',')
                                    : []
                              let tmpValue: any = []
                              let tmpLabel: any = []
                              let resultValue: any[] = []
                              let resultLabel: any[] = []

                              if (val.length > 0) {
                                if (value.indexOf(ite.value) === -1) {
                                  tmpValue = (optionModules as unknown as any[]).find(
                                    (dt) => dt.value === ite.value
                                  )

                                  if (tmpValue === undefined) {
                                    return
                                  }

                                  resultValue = [...value, tmpValue.value]
                                  formik.setFieldValue('module.module_id', resultValue)
                                } else {
                                  tmpValue = value.filter((dt: any) => dt !== ite.value)
                                  formik.setFieldValue('module.module_id', tmpValue)
                                }

                                if (label.indexOf(ite.label) === -1) {
                                  tmpLabel = (optionModules as unknown as any[]).find(
                                    (dt) => dt.label === ite.label
                                  )

                                  if (tmpLabel === undefined) {
                                    return
                                  }

                                  resultLabel = [...label, tmpLabel.label]
                                  local === 'id'
                                    ? formik.setFieldValue('module.name_idn', resultLabel)
                                    : formik.setFieldValue('module.name_eng', resultLabel)
                                } else {
                                  tmpLabel = label.filter((dt: any) => dt !== ite.label)
                                  local === 'id'
                                    ? formik.setFieldValue('module.name_idn', tmpLabel)
                                    : formik.setFieldValue('module.name_eng', tmpLabel)
                                }
                              } else {
                                formik.setFieldValue('module.module_id', [])
                                local === 'id'
                                  ? formik.setFieldValue('module.name_idn', [])
                                  : formik.setFieldValue('module.name_eng', [])
                              }
                            }}
                            value={
                              userGroupModels.module.module_id.length > 0
                                ? userGroupModels.module.module_id !==
                                  formik.values.module.module_id
                                  ? formik.values.module.module_id
                                  : (userGroupModels.module.module_id as unknown as any).split(',')
                                : []
                            }
                            renderExtraFooter={() => (
                              <div style={footerStyles}>
                                <Checkbox
                                  indeterminate={
                                    (formik.values.module.module_id as unknown as any[]).length >
                                      0 &&
                                    (formik.values.module.module_id as unknown as any[]).length <
                                      (optionModules as unknown as any[]).length
                                  }
                                  checked={
                                    (formik.values.module.module_id as unknown as any[]).length ===
                                    (optionModules as unknown as any[]).length
                                  }
                                  onChange={(data, checked) => {
                                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                                    if (checked) {
                                      formik.setFieldValue(
                                        'module.module_id',
                                        (optionModules as unknown as any[]).map((e) => e.value)
                                      )
                                      local === 'id'
                                        ? formik.setFieldValue(
                                            'module.name_idn',
                                            (optionModules as unknown as any[]).map((e) => e.label)
                                          )
                                        : formik.setFieldValue(
                                            'module.name_eng',
                                            (optionModules as unknown as any[]).map((e) => e.label)
                                          )
                                    } else {
                                      formik.setFieldValue('module.module_id', [])
                                      local === 'id'
                                        ? formik.setFieldValue('module.name_idn', [])
                                        : formik.setFieldValue('module.name_eng', [])
                                    }
                                  }}
                                >
                                  {(formik.values.module.module_id as unknown as any[]).length ===
                                  (optionModules as unknown as any[]).length
                                    ? intl.formatMessage({id: 'LABEL_UNSELECT_ALL'})
                                    : intl.formatMessage({id: 'LABEL_SELECT_ALL'})}
                                </Checkbox>
                              </div>
                            )}
                          />
                          {formik.errors.module ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {intl.formatMessage(
                                  {id: 'VALIDATION_REQUIRED_FIELD'},
                                  {name: intl.formatMessage({id: 'LABEL_MODULE'})}
                                )}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Module */}

                        {/* Name */}
                        <div className='fv-row mb-5'>
                          {formik.values.name !== '' && (
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action === 'edit' ? 'required' : ''
                              }`}
                            >
                              {intl.formatMessage({id: 'LABEL_NAME'})}
                            </label>
                          )}
                          <input
                            type='text'
                            className={clsx(
                              'form-control form-control-solid',
                              {'is-invalid': formik.touched.name && formik.errors.name},
                              {
                                'is-valid': formik.touched.name && !formik.errors.name,
                              }
                            )}
                            placeholder={intl.formatMessage({id: 'LABEL_NAME'})}
                            {...formik.getFieldProps('name')}
                            readOnly={action === 'edit' ? false : true}
                          />
                          {formik.touched.name && formik.errors.name ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {formik.errors.name}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Name */}

                        {/* Code */}
                        <div className='fv-row mb-5'>
                          {formik.values.code !== '' && (
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action === 'edit' ? 'required' : ''
                              }`}
                            >
                              {intl.formatMessage({id: 'LABEL_CODE'})}
                            </label>
                          )}
                          <input
                            type='text'
                            className={clsx(
                              'form-control form-control-solid',
                              {'is-invalid': formik.touched.code && formik.errors.code},
                              {
                                'is-valid': formik.touched.code && !formik.errors.code,
                              }
                            )}
                            placeholder={intl.formatMessage({id: 'LABEL_CODE'})}
                            {...formik.getFieldProps('code')}
                            readOnly={action === 'edit' ? false : true}
                            onChange={(e) => {
                              formik.setFieldValue('code', e.target.value.toUpperCase())
                            }}
                          />
                          {formik.touched.code && formik.errors.code ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {formik.errors.code}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Code */}
                      </div>
                      {/* Left Form */}

                      {/* Right Form */}
                      <div className='col-lg-6'>
                        {/* Description */}
                        <div className='fv-row mb-5'>
                          {formik.values.detail !== '' && (
                            <label className='form-label fs-6 fw-bolder text-dark'>
                              {intl.formatMessage({id: 'LABEL_DESCRIPTION'})}
                            </label>
                          )}
                          <textarea
                            className={clsx(
                              'form-control form-control-solid',
                              {'is-invalid': formik.touched.detail && formik.errors.detail},
                              {
                                'is-valid': formik.touched.detail && !formik.errors.detail,
                              }
                            )}
                            placeholder={intl.formatMessage({id: 'LABEL_DESCRIPTION'})}
                            {...formik.getFieldProps('detail')}
                            readOnly={action === 'edit' ? false : true}
                            rows={3}
                          />
                        </div>
                        {/* Description */}

                        {/* Status */}
                        <div className='fv-row mb-5'>
                          {formik.values.status !== '' && (
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action === 'edit' ? 'required' : ''
                              }`}
                            >
                              Status
                            </label>
                          )}
                          <div className='form-check form-switch form-check-custom form-check-solid mt-1'>
                            <Toggle
                              size='lg'
                              readOnly={action !== 'edit' ? true : false}
                              checkedChildren={intl.formatMessage({id: 'LABEL_ACTIVE'})}
                              unCheckedChildren={intl.formatMessage({id: 'LABEL_INACTIVE'})}
                              checked={formik.values.status === 't' ? true : false}
                              onChange={() =>
                                formik.setFieldValue(
                                  'status',
                                  formik.values.status === 't' ? 'f' : 't'
                                )
                              }
                            />
                          </div>
                          {formik.touched.status && formik.errors.status ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {formik.errors.status}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Status */}
                      </div>
                      {/* Right Form */}
                    </div>
                  </div>
                  {/* Data */}

                  {/* Detail */}
                  <div
                    className={clsx('tab-pane', {
                      active: tab === 'log',
                    })}
                  >
                    <FormLog payload={userGroup} formik={formik} />
                  </div>
                  {/* Detail */}
                </div>
              </div>
              {/* Tab Body */}
            </form>
          )}
        </div>
        {/* Form */}
      </Drawer.Body>

      {/* Dialog */}
      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(true)}
        className='modal fade show d-block'
      >
        <Dialog.Panel className='modal-dialog modal-fullscreen'>
          <div className='modal-content'>
            <Dialog.Title className='modal-header'>
              {intl.formatMessage({id: 'DIALOG_HEADER_TITLE'})}
            </Dialog.Title>
            <div className='modal-body scroll-y mx-2 mx-xl-2 my-2'>
              {/* Name */}
              {userGroupModels.name !== formik.values.name && (
                <div className='fv-row mb-5'>
                  <label className='form-label fs-6 fw-bolder text-dark'>
                    {intl.formatMessage({id: 'LABEL_NAME'})}
                  </label>
                  {action !== 'add' && (
                    <input
                      type='text'
                      className='form-control form-control-solid'
                      value={userGroupModels.name}
                      readOnly
                    />
                  )}
                  <input
                    type='text'
                    className='form-control form-control-solid text-primary is-valid'
                    value={formik.values.name}
                    readOnly
                  />
                </div>
              )}
              {/* Name */}

              {/* Code */}
              {userGroupModels.code !== formik.values.code && (
                <div className='fv-row mb-5'>
                  <label className='form-label fs-6 fw-bolder text-dark'>
                    {intl.formatMessage({id: 'LABEL_CODE'})}
                  </label>
                  {action !== 'add' && (
                    <input
                      type='text'
                      className='form-control form-control-solid'
                      value={userGroupModels.code}
                      readOnly
                    />
                  )}
                  <input
                    type='text'
                    className='form-control form-control-solid text-primary is-valid'
                    value={formik.values.code}
                    readOnly
                  />
                </div>
              )}
              {/* Code */}

              {/* Module */}
              {userGroupModels.module.module_id !== formik.values.module.module_id && (
                <div className='fv-row mb-5'>
                  <label className='form-label fs-6 fw-bolder text-dark'>
                    {intl.formatMessage({id: 'LABEL_MODULE'})}
                  </label>
                  {action !== 'add' && (
                    <textarea
                      className='form-control form-control-solid'
                      value={
                        local === 'id'
                          ? userGroup.module?.name_idn?.toString().replaceAll(',', ', ')
                          : userGroup.module?.name_eng?.toString().replaceAll(',', ', ')
                      }
                      readOnly
                      rows={2}
                    />
                  )}
                  <textarea
                    className='form-control form-control-solid text-primary is-valid'
                    value={
                      local === 'id'
                        ? formik.values.module?.name_idn?.toString().replaceAll(',', ', ')
                        : formik.values.module?.name_eng?.toString().replaceAll(',', ', ')
                    }
                    readOnly
                    rows={2}
                  />
                </div>
              )}
              {/* Module */}

              {/* Status */}
              {action === 'add' ? (
                <div className='fv-row mb-5'>
                  <label className='form-label fs-6 fw-bolder text-dark'>Status</label>
                  <input
                    type='text'
                    className='form-control form-control-solid text-primary is-valid'
                    value={
                      formik.values.status === 't'
                        ? intl.formatMessage({id: 'LABEL_ACTIVE'})
                        : intl.formatMessage({id: 'LABEL_INACTIVE'})
                    }
                    readOnly
                  />
                </div>
              ) : (
                userGroupModels.status !== formik.values.status && (
                  <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark'>Status</label>
                    <input
                      type='text'
                      className='form-control form-control-solid'
                      value={
                        userGroupModels.status === 't'
                          ? intl.formatMessage({id: 'LABEL_ACTIVE'})
                          : intl.formatMessage({id: 'LABEL_INACTIVE'})
                      }
                      readOnly
                    />
                    <input
                      type='text'
                      className='form-control form-control-solid text-primary is-valid'
                      value={
                        formik.values.status === 't'
                          ? intl.formatMessage({id: 'LABEL_ACTIVE'})
                          : intl.formatMessage({id: 'LABEL_INACTIVE'})
                      }
                      readOnly
                    />
                  </div>
                )
              )}
              {/* Status */}

              {/* Description */}
              {userGroupModels.detail !== formik.values.detail && (
                <div className='fv-row mb-5'>
                  <label className='form-label fs-6 fw-bolder text-dark'>
                    {intl.formatMessage({id: 'LABEL_DESCRIPTION'})}
                  </label>
                  {action !== 'add' && (
                    <textarea
                      className='form-control form-control-solid'
                      value={userGroupModels.detail}
                      readOnly
                    />
                  )}
                  <textarea
                    className='form-control form-control-solid text-primary is-valid'
                    value={formik.values.detail}
                    readOnly
                  />
                </div>
              )}
              {/* Description */}
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-outline btn-btn-outline-info me-3'
                data-kt-users-modal-action='close'
                onClick={() => setIsDialogOpen(false)}
              >
                {intl.formatMessage({id: 'BUTTON_LABEL_BACK'})}
              </button>

              <button
                type='button'
                className={`btn ${loading ? 'btn-secondary' : 'btn-success'}`}
                data-kt-users-modal-action='submit'
                onClick={() => formik.handleSubmit()}
                disabled={loading ? true : false}
              >
                {!loading && (
                  <span className='indicator-label'>
                    {intl.formatMessage({id: 'BUTTON_LABEL_SUBMIT'})}
                  </span>
                )}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    {intl.formatMessage({id: 'BUTTON_LABEL_PLEASE_WAIT'})}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
      {/* Dialog */}
    </>
  )
}

export {ViewDrawerForm}
