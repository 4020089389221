/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {Dialog} from '@headlessui/react'
import clsx from 'clsx'
import toast from 'react-hot-toast'
import {Checkbox, Drawer, CheckPicker, Toggle} from 'rsuite'
import {HexColorPicker} from 'react-colorful'

import {
  NotAuthRedirectForm,
  FormLog,
  getFormAction,
  SpinnerIndicator,
  RowFileFormatter,
  inputTrimSpace,
} from 'app/components'
import {getAuth, removeAuth, useAuth} from 'app/modules/auth'

import {
  DetailModel,
  addData,
  deleteData,
  fetchBrandOptions,
  fetchCompanyOptions,
  fetchHoldingOptions,
  fetchPaymentMethodOptions,
  initialData,
  updateData,
} from '../../helpers'
import {useAction, useListQueryResponse} from '../../providers'

const footerStyles = {
  padding: '0px 2px',
  borderTop: '1px solid #e5e5e5',
}

type Props = {
  isLoading: boolean
  salesType: DetailModel
  appMenuID: any
  modalID: any
  menuName: any
}

const ViewDrawerForm: FC<Props> = ({menuName, salesType, isLoading, appMenuID, modalID}) => {
  const auth = getAuth()
  const intl = useIntl()
  const [tab, setTab] = useState('data')
  const {currentUser} = useAuth()
  const {setItemIdForUpdate, action} = useAction()
  const didRequestHolding = useRef(false)
  const didRequestCompany = useRef(false)
  const didRequestBrand = useRef(false)
  const didRequestPaymentMethod = useRef(false)
  const {refetch} = useListQueryResponse()
  const [isAuth, setIsAuth] = useState(true)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [completed, setCompleted] = useState(false)
  const [holdingOptions, setHoldingOptions] = useState(undefined)
  const [companyOptions, setCompanyOptions] = useState(undefined)
  const [brandOptions, setBrandOptions] = useState(undefined)
  const [paymentMethodOptions, setPaymentMethodOptions] = useState(undefined)
  const fileRef = useRef(null)
  const MAX_SIZED = 0.3
  const [baseSize, setBaseSize] = useState(0)
  const [size, setSize] = useState(0)
  const [preview, setPreview] = useState<string | ArrayBuffer | null>(null) // MB
  const optionsHolding =
    holdingOptions === undefined
      ? []
      : (holdingOptions as unknown as any[]).map((holdings: any) => ({
          value: holdings.holding_id,
          label: holdings.name,
        }))
  const optionsCompany =
    companyOptions === undefined
      ? []
      : (companyOptions as unknown as any[]).map((company: any) => ({
          value: company.company_id,
          label: company.name,
        }))
  const optionsBrand =
    brandOptions === undefined
      ? []
      : (brandOptions as unknown as any[]).map((brand: any) => ({
          value: brand.brand_id,
          label: brand.name,
        }))
  const optionsPaymentMethod =
    paymentMethodOptions === undefined
      ? []
      : (paymentMethodOptions as unknown as any[]).map((payment_method: any) => ({
          value: payment_method.payment_method_id,
          label: payment_method.name,
        }))

  const baseValidation = Yup.object().shape({
    name: Yup.string()
      .min(3, intl.formatMessage({id: 'VALIDATION_MIN_LENGTH_FIELD'}, {min: 3}))
      .max(125, intl.formatMessage({id: 'VALIDATION_MAX_LENGTH_FIELD'}, {max: 125}))
      .required(
        intl.formatMessage(
          {id: 'VALIDATION_REQUIRED_FIELD'},
          {name: intl.formatMessage({id: 'LABEL_NAME'})}
        )
      ),
    color: Yup.string().required(
      intl.formatMessage(
        {id: 'VALIDATION_REQUIRED_FIELD'},
        {name: intl.formatMessage({id: 'LABEL_COLOR'})}
      )
    ),
    file: Yup.string().required(
      intl.formatMessage({id: 'VALIDATION_REQUIRED_FIELD'}, {name: 'Logo'})
    ),
    for_order: Yup.string().required(
      intl.formatMessage(
        {id: 'VALIDATION_REQUIRED_FIELD'},
        {name: intl.formatMessage({id: 'LABEL_ORDER'})}
      )
    ),
  })

  const [models] = useState<DetailModel>({
    ...salesType,
    sales_type_id: salesType.sales_type_id || initialData.sales_type_id,
    holding_id: action === 'add' ? [] : salesType.holding?.map((dt) => dt.holding_id),
    holding_name: action === 'add' ? [] : salesType.holding?.map((dt) => dt.name),
    company_id: action === 'add' ? [] : salesType.company?.map((dt) => dt.company_id),
    company_name: action === 'add' ? [] : salesType.company?.map((dt) => dt.name),
    brand_id: action === 'add' ? [] : salesType.brand?.map((dt) => dt.brand_id),
    brand_name: action === 'add' ? [] : salesType.brand?.map((dt) => dt.name),
    payment_method_id:
      action === 'add' ? [] : salesType.payment_method?.map((dt) => dt.payment_method_id),
    payment_method_name: action === 'add' ? [] : salesType.payment_method?.map((dt) => dt.name),
    holding: salesType.holding,
    name: salesType.name || initialData.name,
    status: salesType.status || initialData.status,
    description: salesType.description || initialData.description,
    color: salesType.color || initialData.color,
    for_order: salesType.for_order || initialData.for_order,
    logo: salesType.logo || initialData.logo,
    file: salesType.file || initialData.file,
    created_at: salesType.created_at || initialData.created_at,
    created_by: salesType.created_by || initialData.created_by,
    updated_at: salesType.updated_at || initialData.updated_at,
    updated_by: salesType.updated_by || initialData.updated_by,
    menu_id: appMenuID,
    creator_id: currentUser?.user_id,
    creator_by: currentUser?.username,
  })

  const formik = useFormik({
    initialValues: models,
    validationSchema: action !== 'delete' ? baseValidation : undefined,
    onSubmit: async (values, {setSubmitting, setStatus, setFieldError}) => {
      setIsDialogOpen(false)
      action === 'edit'
        ? requestUpdateData(values, setStatus, setSubmitting, setFieldError)
        : action === 'add'
          ? requestAddData(values, setStatus, setSubmitting, setFieldError)
          : requestDeleteData(values, setStatus, setSubmitting)
    },
    onReset: (values) => {
      values = models

      if (fileRef && fileRef.current) {
        const curRef: any = fileRef.current
        curRef.value = null
        setSize(0)
      }
    },
  })

  const requestUpdateData = async (
    values: any,
    setStatus: any,
    setSubmitting: any,
    setFieldError: any
  ) => {
    setLoading(true)
    setStatus(null)
    setSubmitting(true)

    // request update to API
    try {
      // fix for order is number
      values.for_order = parseInt(values.for_order)

      const res = await updateData(values)

      // throw message error
      if (res?.data.meta.code !== 200) {
        if (res?.data.meta.message === 'name already used') {
          setStatus(intl.formatMessage({id: 'RESPONSE_LIBRARY_SALES_TYPE_NAME_ALREADY_USED'}))
          setFieldError(
            'name',
            intl.formatMessage({id: 'RESPONSE_LIBRARY_SALES_TYPE_NAME_ALREADY_USED'})
          )
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_LIBRARY_SALES_TYPE_EDIT_SUCCESS'}))
        setCompleted(true)
        setTimeout(function () {
          setItemIdForUpdate(undefined)
          refetch()
        }, 2e3)
      }
    } catch (error: any) {
      console.error(error)
      if (error.response.status === 401) {
        setStatus(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
        removeAuth()
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
      }
      setSubmitting(false)
      setLoading(false)
      setTimeout(function () {
        setStatus(null)
      }, 9e3)

      return false
    } finally {
      setTimeout(() => {
        setLoading(false)
        setSubmitting(false)
      }, 5e2)
    }
  }
  const requestAddData = async (
    values: any,
    setStatus: any,
    setSubmitting: any,
    setFieldError: any
  ) => {
    setLoading(true)
    setStatus(null)
    setSubmitting(true)

    // request add to API
    try {
      const res = await addData(values)

      // throw message error
      if (res?.data.meta.code !== 200) {
        if (res?.data.meta.message === 'name already used') {
          setStatus(intl.formatMessage({id: 'RESPONSE_LIBRARY_SALES_TYPE_NAME_ALREADY_USED'}))
          setFieldError(
            'name',
            intl.formatMessage({id: 'RESPONSE_LIBRARY_SALES_TYPE_NAME_ALREADY_USED'})
          )
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_LIBRARY_SALES_TYPE_ADD_SUCCESS'}))
        setCompleted(true)
        setTimeout(function () {
          setItemIdForUpdate(undefined)
          refetch()
        }, 2e3)
      }
    } catch (error: any) {
      console.error(error)
      if (error.response.status === 401) {
        setStatus(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
        removeAuth()
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
      }
      setSubmitting(false)
      setLoading(false)
      setTimeout(function () {
        setStatus(null)
      }, 9e3)

      return false
    } finally {
      setTimeout(() => {
        setLoading(false)
        setSubmitting(false)
      }, 5e2)
    }
  }
  const requestDeleteData = async (values: any, setStatus: any, setSubmitting: any) => {
    setLoading(true)
    setStatus(null)
    setSubmitting(true)

    // request delete to API
    try {
      const res = await deleteData(
        values.sales_type_id,
        values.menu_id,
        values.creator_id,
        values.creator_by
      )

      // throw message error
      if (res?.meta.code !== 200) {
        if (res?.meta.message === 'not inactive') {
          setStatus(intl.formatMessage({id: 'RESPONSE_LIBRARY_SALES_TYPE_IS_ACTIVE'}))
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
        if (res?.meta.message === 'inused by other data') {
          setStatus(intl.formatMessage({id: 'RESPONSE_LIBRARY_SALES_TYPE_IS_INUSED'}))
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_LIBRARY_SALES_TYPE_DELETE_SUCCESS'}))
        setCompleted(true)
        setTimeout(function () {
          setItemIdForUpdate(undefined)
          refetch()
        }, 2e3)
      }
    } catch (error: any) {
      console.error(error)
      if (error.response.status === 401) {
        setStatus(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
        removeAuth()
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
      }
      setSubmitting(false)
      setLoading(false)
      setTimeout(function () {
        setStatus(null)
      }, 9e3)

      return false
    } finally {
      setTimeout(() => {
        setLoading(false)
        setSubmitting(false)
      }, 5e2)
    }
  }

  useEffect(() => {
    auth === undefined && setIsAuth(false)
  }, [auth])

  useEffect(() => {
    const requestHoldingOptions = async () => {
      try {
        if (!didRequestHolding.current) {
          const options = await fetchHoldingOptions('input')
          // throw message error
          if (options.data.meta.code === 200) {
            // set option
            setHoldingOptions(options.data.data)
          }
        }
      } catch (error: any) {
        console.error(error)
        if (error.response.status === 401) {
          toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
          removeAuth()
        } else {
          toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
        }
      } finally {
      }

      return () => (didRequestHolding.current = true)
    }

    requestHoldingOptions()

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const requestCompanyOptions = async () => {
      try {
        if (!didRequestCompany.current) {
          const options = await fetchCompanyOptions('input', formik.values.holding_id)
          // throw message error
          if (options.data.meta.code === 200) {
            // set options
            setCompanyOptions(options.data.data)
          }
        }
      } catch (error: any) {
        console.error(error)
        if (error.response.status === 401) {
          toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
          removeAuth()
        } else {
          toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
        }
      } finally {
      }

      return () => (didRequestCompany.current = true)
    }

    if (action === 'add' || action === 'edit') {
      ;(formik.values.holding_id as unknown as any[])?.length > 0
        ? requestCompanyOptions()
        : setCompanyOptions(undefined)
    }

    // eslint-disable-next-line
  }, [formik.values.holding_id])

  useEffect(() => {
    const requestBrandOptions = async () => {
      try {
        if (!didRequestBrand.current) {
          const options = await fetchBrandOptions('input', formik.values.company_id)
          // throw message error
          if (options.data.meta.code === 200) {
            // set options
            setBrandOptions(options.data.data)
          }
        }
      } catch (error: any) {
        console.error(error)
        if (error.response.status === 401) {
          toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
          removeAuth()
        } else {
          toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
        }
      } finally {
      }

      return () => (didRequestBrand.current = true)
    }

    if (action === 'add' || action === 'edit') {
      formik.values.company_id !== undefined &&
      (formik.values.company_id as unknown as any[]).length > 0
        ? requestBrandOptions()
        : setBrandOptions(undefined)
    }

    // eslint-disable-next-line
  }, [formik.values.company_id])

  useEffect(() => {
    const requestPaymentMethodOptions = async () => {
      try {
        if (!didRequestPaymentMethod.current) {
          const options = await fetchPaymentMethodOptions('input', formik.values.brand_id)
          // throw message error
          if (options.data.meta.code === 200) {
            // set options
            setPaymentMethodOptions(options.data.data)
          }
        }
      } catch (error: any) {
        console.error(error)
        if (error.response.status === 401) {
          toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
          removeAuth()
        } else {
          toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
        }
      } finally {
      }

      return () => (didRequestPaymentMethod.current = true)
    }

    if (action === 'add' || action === 'edit') {
      formik.values.brand_id !== undefined &&
      (formik.values.brand_id as unknown as any[]).length > 0
        ? requestPaymentMethodOptions()
        : setPaymentMethodOptions(undefined)
    }

    // eslint-disable-next-line
  }, [formik.values.brand_id])

  const handleInputFile = (event: any) => {
    // Update the state
    formik.setFieldValue('file', null)

    const file = event.target.files

    if (file[0] && file[0].size > 0) {
      formik.setFieldValue('file', event.target.files[0] === null ? '' : event.target.files[0])
      setSize(Number((file[0].size / 1024 / 1000).toFixed(1)))
      setBaseSize(file[0].size)

      var reader = new FileReader()
      reader.readAsDataURL(file[0])
      reader.onloadend = function (e: any) {
        setPreview(reader.result)
      }

      if (Number((file[0].size / 1024 / 1000).toFixed(1)) > Number(MAX_SIZED)) {
        const curRef: any = fileRef.current
        curRef.value = null
        return false
      }
    } else {
      setSize(0)
      return false
    }
  }

  const handleColorChange = (e: any) => {
    formik.setFieldValue('color', inputTrimSpace(e).toLowerCase())
  }

  return (
    <>
      {!isAuth && <NotAuthRedirectForm />}

      <Drawer.Header>
        <Drawer.Title className='fw-bolder'>
          {isLoading ? (
            <SpinnerIndicator label={intl.formatMessage({id: 'BUTTON_LABEL_PLEASE_WAIT'})} />
          ) : (
            <>
              {getFormAction(
                action,
                intl.formatMessage({id: 'BUTTON_LABEL_VIEW'}),
                intl.formatMessage({id: 'BUTTON_LABEL_EDIT'}),
                intl.formatMessage({id: 'BUTTON_LABEL_ADD'}),
                intl.formatMessage({id: 'BUTTON_LABEL_DELETE'})
              )}{' '}
              {menuName}
            </>
          )}
        </Drawer.Title>
        <Drawer.Actions>
          {action !== 'view' && models !== formik.values && !completed && (
            <button
              type='reset'
              className='btn btn-light me-3'
              data-kt-users-modal-action='reset'
              onClick={formik.handleReset}
              disabled={loading}
            >
              {!loading && (
                <span className='indicator-label'>
                  {intl.formatMessage({id: 'BUTTON_LABEL_RESET'})}
                </span>
              )}
            </button>
          )}

          {action !== 'view' && models !== formik.values && !completed && (
            <button
              type='submit'
              className='btn btn-primary'
              data-kt-users-modal-action='submit'
              onClick={() => {
                if (formik.values.holding_id?.toString().replaceAll(',', ', ') === '') {
                  formik.setFieldError('holding_id', 'validation errors')
                  return false
                }
                if (formik.values.company_id?.toString().replaceAll(',', ', ') === '') {
                  formik.setFieldError('company_id', 'validation errors')
                  return false
                }
                if (formik.values.brand_id?.toString().replaceAll(',', ', ') === '') {
                  formik.setFieldError('brand_id', 'validation errors')
                  return false
                }
                if (formik.values.status === 't') {
                  if (formik.values.payment_method_id?.toString().replaceAll(',', ', ') === '') {
                    formik.setFieldError('payment_method_id', 'validation errors')
                    return false
                  }
                }
                if (action === 'add' && (baseSize === 0 || size > Number(MAX_SIZED))) {
                  formik.setFieldError('file', 'validation errors')
                  return false
                }
                if (action === 'edit' && size > -1 && size > Number(MAX_SIZED)) {
                  formik.setFieldError('file', 'validation errors')
                  return false
                }
                setIsDialogOpen(true)
                setLoading(true)
                setTimeout(function () {
                  setLoading(false)
                }, 2e3)
              }}
              disabled={isLoading || loading || formik.isSubmitting || !formik.isValid}
            >
              {!loading && (
                <span className='indicator-label'>
                  {intl.formatMessage({id: 'BUTTON_LABEL_SUBMIT'})}
                </span>
              )}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {intl.formatMessage({id: 'BUTTON_LABEL_SUBMITTING'})}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          )}

          {action === 'delete' && !completed && (
            <button
              type='submit'
              className='btn btn-danger'
              data-kt-users-modal-action='submit'
              onClick={() => formik.handleSubmit()}
              disabled={isLoading || loading || formik.isSubmitting || !formik.isValid}
            >
              {!loading && (
                <span className='indicator-label'>
                  {intl.formatMessage({id: 'BUTTON_LABEL_DELETE'})}
                </span>
              )}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {intl.formatMessage({id: 'BUTTON_LABEL_SUBMITTING'})}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          )}
        </Drawer.Actions>
      </Drawer.Header>

      <Drawer.Body style={{padding: 0}}>
        {/* Response Message */}
        {formik.status &&
          (formik.status === intl.formatMessage({id: 'RESPONSE_LIBRARY_SALES_TYPE_ADD_SUCCESS'}) ||
          formik.status === intl.formatMessage({id: 'RESPONSE_LIBRARY_SALES_TYPE_EDIT_SUCCESS'}) ||
          formik.status ===
            intl.formatMessage({id: 'RESPONSE_LIBRARY_SALES_TYPE_DELETE_SUCCESS'}) ? (
            <div className='m-lg-5 p-2 bg-light-success rounded'>
              <div className='text-success text-center font-weight-bold fw-bolder'>
                {formik.status}
              </div>
            </div>
          ) : (
            <div className={`m-lg-5 p-2 alert alert-danger`}>
              <div className='alert-text text-center font-weight-bold fw-bolder'>
                {formik.status}
              </div>
            </div>
          ))}
        {/* Response Message */}

        {/* Form */}
        <div className={`modal-body m-5 ${action === 'add' ? '' : ' mt-0'}`}>
          {action === 'add' ? (
            <form id={`${modalID}-form`} className='form' onSubmit={formik.handleSubmit} noValidate>
              <div className='row'>
                {/* First Col */}
                <div className='col-lg-6'>
                  {/* Name */}
                  <div className='fv-row mb-5'>
                    {formik.values.name !== '' && (
                      <label className='form-label fs-6 fw-bolder text-dark required'>
                        {intl.formatMessage({id: 'LABEL_NAME'})}
                      </label>
                    )}
                    <input
                      type='text'
                      className={clsx(
                        'form-control form-control-solid',
                        {'is-invalid': formik.touched.name && formik.errors.name},
                        {
                          'is-valid': formik.touched.name && !formik.errors.name,
                        }
                      )}
                      placeholder={intl.formatMessage({id: 'LABEL_NAME'})}
                      {...formik.getFieldProps('name')}
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger fw-bolder'>
                          {formik.errors.name}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {/* Name */}

                  {/* Color */}
                  <div className='fv-row mb-5'>
                    {formik.values.color !== '' && (
                      <label className='form-label fs-6 fw-bolder text-dark required'>
                        {intl.formatMessage({id: 'LABEL_COLOR'})}
                      </label>
                    )}
                    <button
                      type='button'
                      className='btn btn-lg btn-outline btn-outline-dashed m-5'
                      style={{backgroundColor: formik.values.color}}
                    >
                      &nbsp;&nbsp; &nbsp;&nbsp;
                    </button>
                    <input
                      type='text'
                      className={clsx(
                        'form-control form-control-solid mb-5',
                        {'is-invalid': formik.touched.color && formik.errors.color},
                        {
                          'is-valid': formik.touched.color && !formik.errors.color,
                        }
                      )}
                      placeholder={intl.formatMessage({id: 'LABEL_COLOR'})}
                      {...formik.getFieldProps('color')}
                      onChange={(e) =>
                        formik.setFieldValue('color', inputTrimSpace(e.target.value).toLowerCase())
                      }
                    />
                    <HexColorPicker color={formik.values.color} onChange={handleColorChange} />
                    {formik.touched.color && formik.errors.color ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger fw-bolder'>
                          {formik.errors.color}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {/* Color */}

                  {/* Logo */}
                  <div className='fv-row mb-5'>
                    <span className='d-flex flex-column'>
                      <label className='form-label fs-6 fw-bolder text-dark required'>Logo</label>
                      <span className='fs-7 text-muted mb-5'>
                        {intl.formatMessage({id: 'LABEL_INPUT_FILE_IMAGE_DESC'})}
                      </span>
                      <input
                        ref={fileRef}
                        type='file'
                        className={clsx(
                          'form-control form-control-solid',
                          {'is-invalid': formik.touched.file && formik.errors.file},
                          {
                            'is-valid': formik.touched.file && !formik.errors.file,
                          }
                        )}
                        name='file'
                        onChange={handleInputFile}
                        multiple={false}
                        accept='image/*'
                      />
                      {formik.errors.file ? (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block text-danger fw-bolder'>
                            {intl.formatMessage(
                              {id: 'VALIDATION_REQUIRED_FIELD'},
                              {
                                name: 'Logo',
                              }
                            )}
                          </div>
                        </div>
                      ) : null}

                      {size > Number(MAX_SIZED) ? (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block text-danger fw-bolder'>
                            {intl.formatMessage(
                              {id: 'VALIDATION_MAX_FILE_SIZE'},
                              {
                                max: Number(MAX_SIZED),
                              }
                            )}
                          </div>
                        </div>
                      ) : null}
                    </span>
                  </div>
                  {/* Logo */}
                </div>
                {/* First Col */}

                {/* Second Col */}
                <div className='col-lg-6'>
                  {/* Holding */}
                  <div className='fv-row mb-5'>
                    {(formik.values.holding_id as unknown as any[]).length > 0 && (
                      <label className='form-label fs-6 fw-bolder text-dark required'>
                        Holding
                      </label>
                    )}
                    <CheckPicker
                      block
                      placeholder={'-- Holding --'}
                      data={optionsHolding}
                      defaultValue={
                        salesType && salesType.holding
                          ? (salesType.holding as unknown as any[]).map((e) => e.holding_id)
                          : []
                      }
                      onChange={(val, tr) => {
                        let tmp = []
                        let result: any[] = []
                        let label: any = formik.values.holding_name

                        if (tr.type === 'keydown') {
                          // prevent user select/choice by using click enter
                          return
                        }

                        if (val.length > 0) {
                          if ((tr.target as unknown as any).checked === true) {
                            tmp = (optionsHolding as unknown as any[]).find(
                              (dt) => dt.label === (tr.target as unknown as any).labels[0].innerText
                            )
                            result = [...label, tmp.label]
                          } else {
                            if (tr.type === 'click') {
                              if ((tr.target as unknown as any).lastChild.nodeName === 'path') {
                                let tmp_result = label.filter(
                                  (e: any) =>
                                    e !==
                                    (tr.target as unknown as any).parentElement.parentElement
                                      .innerText
                                )
                                result = tmp_result
                              }
                            } else {
                              let tmp_result = label.filter(
                                (e: any) => e !== (tr.target as unknown as any).labels[0].innerText
                              )
                              result = tmp_result
                            }
                          }
                          formik.setFieldValue('holding_id', val)
                          formik.setFieldValue('holding_name', result)
                        } else {
                          formik.setFieldValue('holding_id', [])
                          formik.setFieldValue('holding_name', [])
                        }

                        formik.values.company_id = salesType.company_id
                        formik.values.brand_id = salesType.brand_id
                        formik.values.payment_method_id = salesType.payment_method_id

                        formik.setFieldValue('company_id', [])
                        formik.setFieldValue('company_name', [])
                        formik.setFieldValue('brand_id', [])
                        formik.setFieldValue('brand_name', [])
                        formik.setFieldValue('payment_method_id', [])
                        formik.setFieldValue('payment_method_name', [])
                      }}
                      onSelect={(val, ite) => {
                        let value: any = formik.values.holding_id
                        let label: any = formik.values.holding_name
                        let tmpValue: any = []
                        let tmpLabel: any = []
                        let resultValue: any[] = []
                        let resultLabel: any[] = []

                        if (val.length > 0) {
                          if (value.indexOf(ite.value) === -1) {
                            tmpValue = (optionsHolding as unknown as any[]).find(
                              (dt) => dt.value === ite.value
                            )

                            if (tmpValue === undefined) {
                              return
                            }

                            resultValue = [...value, tmpValue.value]
                            formik.setFieldValue('holding_id', resultValue)
                          } else {
                            tmpValue = value.filter((dt: any) => dt !== ite.value)
                            formik.setFieldValue('holding_id', tmpValue)
                          }

                          if (label.indexOf(ite.label) === -1) {
                            tmpLabel = (optionsHolding as unknown as any[]).find(
                              (dt) => dt.label === ite.label
                            )

                            if (tmpLabel === undefined) {
                              return
                            }

                            resultLabel = [...label, tmpLabel.label]
                            formik.setFieldValue('holding_name', resultLabel)
                          } else {
                            tmpLabel = label.filter((dt: any) => dt !== ite.label)
                            formik.setFieldValue('holding_name', tmpLabel)
                          }
                        } else {
                          formik.setFieldValue('holding_id', [])
                          formik.setFieldValue('holding_name', [])
                        }

                        formik.values.company_id = salesType.company_id
                        formik.values.brand_id = salesType.brand_id
                        formik.values.payment_method_id = salesType.payment_method_id

                        formik.setFieldValue('company_id', [])
                        formik.setFieldValue('company_name', [])
                        formik.setFieldValue('brand_id', [])
                        formik.setFieldValue('brand_name', [])
                        formik.setFieldValue('payment_method_id', [])
                        formik.setFieldValue('payment_method_name', [])
                      }}
                      value={formik.values.holding_id}
                      renderExtraFooter={() => (
                        <div style={footerStyles}>
                          <Checkbox
                            indeterminate={
                              (formik.values.holding_id as unknown as any[]).length > 0 &&
                              (formik.values.holding_id as unknown as any[]).length <
                                (optionsHolding as unknown as any[]).length
                            }
                            checked={
                              (formik.values.holding_id as unknown as any[]).length ===
                              (optionsHolding as unknown as any[]).length
                            }
                            onChange={(data, checked) => {
                              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                              if (checked) {
                                formik.setFieldValue(
                                  'holding_id',
                                  (optionsHolding as unknown as any[]).map((e) => e.value)
                                )
                                formik.setFieldValue(
                                  'holding_name',
                                  (optionsHolding as unknown as any[]).map((e) => e.label)
                                )
                              } else {
                                formik.setFieldValue('holding_id', [])
                                formik.setFieldValue('holding_name', [])
                              }
                            }}
                          >
                            {(formik.values.holding_id as unknown as any[]).length ===
                            (optionsHolding as unknown as any[]).length
                              ? intl.formatMessage({id: 'LABEL_UNSELECT_ALL'})
                              : intl.formatMessage({id: 'LABEL_SELECT_ALL'})}
                          </Checkbox>
                        </div>
                      )}
                    />
                    {formik.errors.holding_id ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger fw-bolder'>
                          {intl.formatMessage(
                            {id: 'VALIDATION_REQUIRED_FIELD'},
                            {
                              name: 'Holding',
                            }
                          )}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {/* Holding */}

                  {/* Company */}
                  <div className='fv-row mb-5'>
                    {(formik.values.company_id as unknown as any[]).length > 0 && (
                      <label className='form-label fs-6 fw-bolder text-dark required'>
                        {intl.formatMessage({id: 'LABEL_COMPANY'})}
                      </label>
                    )}
                    <CheckPicker
                      block
                      placeholder={`-- ${intl.formatMessage({id: 'LABEL_COMPANY'})} --`}
                      data={optionsCompany}
                      disabled={
                        (formik.values.holding_id as unknown as any[]).length > 0 ? false : true
                      }
                      defaultValue={
                        salesType && salesType.company
                          ? (salesType.company as unknown as any[]).map((e) => e.company_id)
                          : []
                      }
                      onChange={(val, tr) => {
                        let tmp = []
                        let result: any[] = []
                        let label: any = formik.values.company_name

                        if (tr.type === 'keydown') {
                          // prevent user select/choice by using click enter
                          return
                        }

                        if (val.length > 0) {
                          if ((tr.target as unknown as any).checked === true) {
                            tmp = (optionsCompany as unknown as any[]).find(
                              (dt) => dt.label === (tr.target as unknown as any).labels[0].innerText
                            )
                            result = [...label, tmp.label]
                          } else {
                            if (tr.type === 'click') {
                              if ((tr.target as unknown as any).lastChild.nodeName === 'path') {
                                let tmp_result = label.filter(
                                  (e: any) =>
                                    e !==
                                    (tr.target as unknown as any).parentElement.parentElement
                                      .innerText
                                )
                                result = tmp_result
                              }
                            } else {
                              let tmp_result = label.filter(
                                (e: any) => e !== (tr.target as unknown as any).labels[0].innerText
                              )
                              result = tmp_result
                            }
                          }
                          formik.setFieldValue('company_id', val)
                          formik.setFieldValue('company_name', result)
                        } else {
                          formik.setFieldValue('company_id', [])
                          formik.setFieldValue('company_name', [])
                        }

                        formik.values.brand_id = salesType.brand_id
                        formik.values.payment_method_id = salesType.payment_method_id

                        formik.setFieldValue('brand_id', [])
                        formik.setFieldValue('brand_name', [])
                        formik.setFieldValue('payment_method_id', [])
                        formik.setFieldValue('payment_method_name', [])
                      }}
                      onSelect={(val, ite) => {
                        let value: any = formik.values.company_id
                        let label: any = formik.values.company_name
                        let tmpValue: any = []
                        let tmpLabel: any = []
                        let resultValue: any[] = []
                        let resultLabel: any[] = []

                        if (val.length > 0) {
                          if (value.indexOf(ite.value) === -1) {
                            tmpValue = (optionsCompany as unknown as any[]).find(
                              (dt) => dt.value === ite.value
                            )

                            if (tmpValue === undefined) {
                              return
                            }

                            resultValue = [...value, tmpValue.value]
                            formik.setFieldValue('company_id', resultValue)
                          } else {
                            tmpValue = value.filter((dt: any) => dt !== ite.value)
                            formik.setFieldValue('company_id', tmpValue)
                          }

                          if (label.indexOf(ite.label) === -1) {
                            tmpLabel = (optionsCompany as unknown as any[]).find(
                              (dt) => dt.label === ite.label
                            )

                            if (tmpLabel === undefined) {
                              return
                            }

                            resultLabel = [...label, tmpLabel.label]
                            formik.setFieldValue('company_name', resultLabel)
                          } else {
                            tmpLabel = label.filter((dt: any) => dt !== ite.label)
                            formik.setFieldValue('company_name', tmpLabel)
                          }
                        } else {
                          formik.setFieldValue('company_id', [])
                          formik.setFieldValue('company_name', [])
                        }

                        formik.values.brand_id = salesType.brand_id
                        formik.values.payment_method_id = salesType.payment_method_id

                        formik.setFieldValue('brand_id', [])
                        formik.setFieldValue('brand_name', [])
                        formik.setFieldValue('payment_method_id', [])
                        formik.setFieldValue('payment_method_name', [])
                      }}
                      value={formik.values.company_id}
                      renderExtraFooter={() => (
                        <div style={footerStyles}>
                          <Checkbox
                            indeterminate={
                              (formik.values.company_id as unknown as any[]).length > 0 &&
                              (formik.values.company_id as unknown as any[]).length <
                                (optionsCompany as unknown as any[]).length
                            }
                            checked={
                              (formik.values.company_id as unknown as any[]).length ===
                              (optionsCompany as unknown as any[]).length
                            }
                            onChange={(data, checked) => {
                              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                              if (checked) {
                                formik.setFieldValue(
                                  'company_id',
                                  (optionsCompany as unknown as any[]).map((e) => e.value)
                                )
                                formik.setFieldValue(
                                  'company_name',
                                  (optionsCompany as unknown as any[]).map((e) => e.label)
                                )
                              } else {
                                formik.setFieldValue('company_id', [])
                                formik.setFieldValue('company_name', [])
                              }
                            }}
                          >
                            {(formik.values.company_id as unknown as any[]).length ===
                            (optionsCompany as unknown as any[]).length
                              ? intl.formatMessage({id: 'LABEL_UNSELECT_ALL'})
                              : intl.formatMessage({id: 'LABEL_SELECT_ALL'})}
                          </Checkbox>
                        </div>
                      )}
                    />
                    {formik.errors.company_id ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger fw-bolder'>
                          {intl.formatMessage(
                            {id: 'VALIDATION_REQUIRED_FIELD'},
                            {
                              name: intl.formatMessage({id: 'LABEL_COMPANY'}),
                            }
                          )}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {/* Company */}

                  {/* Brand */}
                  <div className='fv-row mb-5'>
                    {(formik.values.brand_id as unknown as any[]).length > 0 && (
                      <label className='form-label fs-6 fw-bolder text-dark required'>
                        {intl.formatMessage({id: 'LABEL_BRAND'})}
                      </label>
                    )}
                    <CheckPicker
                      block
                      placeholder={`-- ${intl.formatMessage({id: 'LABEL_BRAND'})} --`}
                      data={optionsBrand}
                      disabled={
                        (formik.values.company_id as unknown as any[]).length > 0 ? false : true
                      }
                      defaultValue={
                        salesType && salesType.brand
                          ? (salesType.brand as unknown as any[]).map((e) => e.brand_id)
                          : []
                      }
                      onChange={(val, tr) => {
                        let tmp = []
                        let result: any[] = []
                        let label: any = formik.values.brand_name

                        if (tr.type === 'keydown') {
                          // prevent user select/choice by using click enter
                          return
                        }

                        if (val.length > 0) {
                          if ((tr.target as unknown as any).checked === true) {
                            tmp = (optionsBrand as unknown as any[]).find(
                              (dt) => dt.label === (tr.target as unknown as any).labels[0].innerText
                            )
                            result = [...label, tmp.label]
                          } else {
                            if (tr.type === 'click') {
                              if ((tr.target as unknown as any).lastChild.nodeName === 'path') {
                                let tmp_result = label.filter(
                                  (e: any) =>
                                    e !==
                                    (tr.target as unknown as any).parentElement.parentElement
                                      .innerText
                                )
                                result = tmp_result
                              }
                            } else {
                              let tmp_result = label.filter(
                                (e: any) => e !== (tr.target as unknown as any).labels[0].innerText
                              )
                              result = tmp_result
                            }
                          }
                          formik.setFieldValue('brand_id', val)
                          formik.setFieldValue('brand_name', result)
                        } else {
                          formik.setFieldValue('brand_id', [])
                          formik.setFieldValue('brand_name', [])
                        }

                        formik.values.payment_method_id = salesType.payment_method_id

                        formik.setFieldValue('payment_method_id', [])
                        formik.setFieldValue('payment_method_name', [])
                      }}
                      onSelect={(val, ite) => {
                        let value: any = formik.values.brand_id
                        let label: any = formik.values.brand_name
                        let tmpValue: any = []
                        let tmpLabel: any = []
                        let resultValue: any[] = []
                        let resultLabel: any[] = []

                        if (val.length > 0) {
                          if (value.indexOf(ite.value) === -1) {
                            tmpValue = (optionsBrand as unknown as any[]).find(
                              (dt) => dt.value === ite.value
                            )

                            if (tmpValue === undefined) {
                              return
                            }

                            resultValue = [...value, tmpValue.value]
                            formik.setFieldValue('brand_id', resultValue)
                          } else {
                            tmpValue = value.filter((dt: any) => dt !== ite.value)
                            formik.setFieldValue('brand_id', tmpValue)
                          }

                          if (label.indexOf(ite.label) === -1) {
                            tmpLabel = (optionsBrand as unknown as any[]).find(
                              (dt) => dt.label === ite.label
                            )

                            if (tmpLabel === undefined) {
                              return
                            }

                            resultLabel = [...label, tmpLabel.label]
                            formik.setFieldValue('brand_name', resultLabel)
                          } else {
                            tmpLabel = label.filter((dt: any) => dt !== ite.label)
                            formik.setFieldValue('brand_name', tmpLabel)
                          }
                        } else {
                          formik.setFieldValue('brand_id', [])
                          formik.setFieldValue('brand_name', [])
                        }

                        formik.values.payment_method_id = salesType.payment_method_id

                        formik.setFieldValue('payment_method_id', [])
                        formik.setFieldValue('payment_method_name', [])
                      }}
                      value={formik.values.brand_id}
                      renderExtraFooter={() => (
                        <div style={footerStyles}>
                          <Checkbox
                            indeterminate={
                              (formik.values.brand_id as unknown as any[]).length > 0 &&
                              (formik.values.brand_id as unknown as any[]).length <
                                (optionsBrand as unknown as any[]).length
                            }
                            checked={
                              (formik.values.brand_id as unknown as any[]).length ===
                              (optionsBrand as unknown as any[]).length
                            }
                            onChange={(data, checked) => {
                              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                              if (checked) {
                                formik.setFieldValue(
                                  'brand_id',
                                  (optionsBrand as unknown as any[]).map((e) => e.value)
                                )
                                formik.setFieldValue(
                                  'brand_name',
                                  (optionsBrand as unknown as any[]).map((e) => e.label)
                                )
                              } else {
                                formik.setFieldValue('brand_id', [])
                                formik.setFieldValue('brand_name', [])
                              }
                            }}
                          >
                            {(formik.values.brand_id as unknown as any[]).length ===
                            (optionsBrand as unknown as any[]).length
                              ? intl.formatMessage({id: 'LABEL_UNSELECT_ALL'})
                              : intl.formatMessage({id: 'LABEL_SELECT_ALL'})}
                          </Checkbox>
                        </div>
                      )}
                    />
                    {formik.errors.brand_id ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger fw-bolder'>
                          {intl.formatMessage(
                            {id: 'VALIDATION_REQUIRED_FIELD'},
                            {
                              name: intl.formatMessage({id: 'LABEL_BRAND'}),
                            }
                          )}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {/* Brand */}

                  {/* Payment Method */}
                  <div className='fv-row mb-5'>
                    {(formik.values.payment_method_id as unknown as any[]).length > 0 && (
                      <label className='form-label fs-6 fw-bolder text-dark required'>
                        {intl.formatMessage({id: 'LABEL_PAYMENT_METHOD'})}
                      </label>
                    )}
                    <CheckPicker
                      block
                      placeholder={`-- ${intl.formatMessage({id: 'LABEL_PAYMENT_METHOD'})} --`}
                      data={optionsPaymentMethod}
                      disabled={
                        (formik.values.brand_id as unknown as any[]).length > 0 ? false : true
                      }
                      defaultValue={
                        salesType && salesType.payment_method
                          ? (salesType.payment_method as unknown as any[]).map(
                              (e) => e.payment_method_id
                            )
                          : []
                      }
                      onChange={(val, tr) => {
                        let tmp = []
                        let result: any[] = []
                        let label: any = formik.values.payment_method_name

                        if (tr.type === 'keydown') {
                          // prevent user select/choice by using click enter
                          return
                        }

                        if (val.length > 0) {
                          if ((tr.target as unknown as any).checked === true) {
                            tmp = (optionsPaymentMethod as unknown as any[]).find(
                              (dt) => dt.label === (tr.target as unknown as any).labels[0].innerText
                            )
                            result = [...label, tmp.label]
                          } else {
                            if (tr.type === 'click') {
                              if ((tr.target as unknown as any).lastChild.nodeName === 'path') {
                                let tmp_result = label.filter(
                                  (e: any) =>
                                    e !==
                                    (tr.target as unknown as any).parentElement.parentElement
                                      .innerText
                                )
                                result = tmp_result
                              }
                            } else {
                              let tmp_result = label.filter(
                                (e: any) => e !== (tr.target as unknown as any).labels[0].innerText
                              )
                              result = tmp_result
                            }
                          }
                          formik.setFieldValue('payment_method_id', val)
                          formik.setFieldValue('payment_method_name', result)
                        } else {
                          formik.setFieldValue('payment_method_id', [])
                          formik.setFieldValue('payment_method_name', [])
                        }
                      }}
                      onSelect={(val, ite) => {
                        let value: any = formik.values.payment_method_id
                        let label: any = formik.values.payment_method_name
                        let tmpValue: any = []
                        let tmpLabel: any = []
                        let resultValue: any[] = []
                        let resultLabel: any[] = []

                        if (val.length > 0) {
                          if (value.indexOf(ite.value) === -1) {
                            tmpValue = (optionsPaymentMethod as unknown as any[]).find(
                              (dt) => dt.value === ite.value
                            )

                            if (tmpValue === undefined) {
                              return
                            }

                            resultValue = [...value, tmpValue.value]
                            formik.setFieldValue('payment_method_id', resultValue)
                          } else {
                            tmpValue = value.filter((dt: any) => dt !== ite.value)
                            formik.setFieldValue('payment_method_id', tmpValue)
                          }

                          if (label.indexOf(ite.label) === -1) {
                            tmpLabel = (optionsPaymentMethod as unknown as any[]).find(
                              (dt) => dt.label === ite.label
                            )

                            if (tmpLabel === undefined) {
                              return
                            }

                            resultLabel = [...label, tmpLabel.label]
                            formik.setFieldValue('payment_method_name', resultLabel)
                          } else {
                            tmpLabel = label.filter((dt: any) => dt !== ite.label)
                            formik.setFieldValue('payment_method_name', tmpLabel)
                          }
                        } else {
                          formik.setFieldValue('payment_method_id', [])
                          formik.setFieldValue('payment_method_name', [])
                        }
                      }}
                      value={formik.values.payment_method_id}
                      renderExtraFooter={() => (
                        <div style={footerStyles}>
                          <Checkbox
                            indeterminate={
                              (formik.values.payment_method_id as unknown as any[]).length > 0 &&
                              (formik.values.payment_method_id as unknown as any[]).length <
                                (optionsPaymentMethod as unknown as any[]).length
                            }
                            checked={
                              (formik.values.payment_method_id as unknown as any[]).length ===
                              (optionsPaymentMethod as unknown as any[]).length
                            }
                            onChange={(data, checked) => {
                              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                              if (checked) {
                                formik.setFieldValue(
                                  'payment_method_id',
                                  (optionsPaymentMethod as unknown as any[]).map((e) => e.value)
                                )
                                formik.setFieldValue(
                                  'payment_method_name',
                                  (optionsPaymentMethod as unknown as any[]).map((e) => e.label)
                                )
                              } else {
                                formik.setFieldValue('payment_method_id', [])
                                formik.setFieldValue('payment_method_name', [])
                              }
                            }}
                          >
                            {(formik.values.payment_method_id as unknown as any[]).length ===
                            (optionsPaymentMethod as unknown as any[]).length
                              ? intl.formatMessage({id: 'LABEL_UNSELECT_ALL'})
                              : intl.formatMessage({id: 'LABEL_SELECT_ALL'})}
                          </Checkbox>
                        </div>
                      )}
                    />
                    {formik.errors.payment_method_id ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger fw-bolder'>
                          {intl.formatMessage(
                            {id: 'VALIDATION_REQUIRED_FIELD'},
                            {
                              name: intl.formatMessage({id: 'LABEL_PAYMENT_METHOD'}),
                            }
                          )}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {/* Payment Method */}

                  {/* Order */}
                  <div className='fv-row mb-5'>
                    {formik.values.for_order && formik.values.for_order > 0 && (
                      <label className='form-label fs-6 fw-bolder text-dark required'>
                        {intl.formatMessage({id: 'LABEL_ORDER'})}
                      </label>
                    )}
                    <input
                      type='number'
                      className={clsx(
                        'form-control form-control-solid',
                        {'is-invalid': formik.touched.for_order && formik.errors.for_order},
                        {
                          'is-valid': formik.touched.for_order && !formik.errors.for_order,
                        }
                      )}
                      placeholder={intl.formatMessage({id: 'LABEL_ORDER'})}
                      {...formik.getFieldProps('for_order')}
                      onChange={(e) => {
                        formik.setFieldValue('for_order', parseInt(e.target.value))
                      }}
                    />
                    {formik.touched.for_order && formik.errors.for_order ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger'>{formik.errors.for_order}</div>
                      </div>
                    ) : null}
                  </div>
                  {/* Order */}
                </div>
                {/* Second Col */}
              </div>

              <div className='row mt-10'>
                {/* Third Col */}
                <div className='col-lg-6'>
                  {/* Description */}
                  <div className='fv-row mb-5'>
                    {formik.values.description !== '' && (
                      <label className='form-label fs-6 fw-bolder text-dark'>
                        {intl.formatMessage({id: 'LABEL_DESCRIPTION'})}
                      </label>
                    )}
                    <textarea
                      className={clsx(
                        'form-control form-control-solid',
                        {'is-invalid': formik.touched.description && formik.errors.description},
                        {
                          'is-valid': formik.touched.description && !formik.errors.description,
                        }
                      )}
                      placeholder={intl.formatMessage({id: 'LABEL_DESCRIPTION'})}
                      {...formik.getFieldProps('description')}
                      rows={3}
                    />
                  </div>
                  {/* Description */}
                </div>
                {/* Third Col */}

                {/* Fourth Col */}
                <div className='col-lg-6'>
                  {/* Status */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark required'>Status</label>
                    <div className='form-check form-switch form-check-custom form-check-solid mt-1'>
                      <Toggle
                        size='lg'
                        checkedChildren={intl.formatMessage({id: 'LABEL_ACTIVE'})}
                        unCheckedChildren={intl.formatMessage({id: 'LABEL_INACTIVE'})}
                        checked={formik.values.status === 't' ? true : false}
                        onChange={() =>
                          formik.setFieldValue('status', formik.values.status === 't' ? 'f' : 't')
                        }
                      />
                    </div>
                  </div>
                  {/* Status */}
                </div>
                {/* Fourth Col */}
              </div>
            </form>
          ) : (
            <form id={`${modalID}-form`} className='form' onSubmit={formik.handleSubmit} noValidate>
              {/* Tab Header */}
              <div className='card-toolbar'>
                <ul
                  className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-2 mt-2'
                  role='tablist'
                >
                  <li className={`nav-item ${tab === 'data' ? 'fw-bolder' : ''}`}>
                    <a
                      className={clsx(`nav-link cursor-pointer`, {
                        active: tab === 'data',
                      })}
                      onClick={() => setTab('data')}
                      role='tab'
                    >
                      Data
                    </a>
                  </li>
                  <li className={`nav-item ${tab === 'log' ? 'fw-bolder' : ''}`}>
                    <a
                      className={clsx(`nav-link cursor-pointer`, {
                        active: tab === 'log',
                      })}
                      onClick={() => setTab('log')}
                      role='tab'
                    >
                      Log
                    </a>
                  </li>
                </ul>
              </div>
              {/* Tab Header */}

              {/* Tab Body */}
              <div className='card-body p-0 pt-2'>
                <div className='tab-content'>
                  {/* Data */}
                  <div
                    className={clsx('tab-pane', {
                      active: tab === 'data',
                    })}
                  >
                    <div className='row'>
                      {/* First Col */}
                      <div className='col-lg-6'>
                        {/* Name */}
                        <div className='fv-row mb-5'>
                          {formik.values.name !== '' && (
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action === 'edit' ? 'required' : ''
                              }`}
                            >
                              {intl.formatMessage({id: 'LABEL_NAME'})}
                            </label>
                          )}
                          <input
                            type='text'
                            className={clsx(
                              'form-control form-control-solid',
                              {'is-invalid': formik.touched.name && formik.errors.name},
                              {
                                'is-valid': formik.touched.name && !formik.errors.name,
                              }
                            )}
                            placeholder={intl.formatMessage({id: 'LABEL_NAME'})}
                            {...formik.getFieldProps('name')}
                            readOnly={action === 'edit' ? false : true}
                          />
                          {formik.touched.name && formik.errors.name ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {formik.errors.name}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Name */}

                        {/* Color */}
                        <div className='fv-row mb-5'>
                          {formik.values.color !== '' && (
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action === 'edit' ? 'required' : ''
                              }`}
                            >
                              {intl.formatMessage({id: 'LABEL_COLOR'})}
                            </label>
                          )}
                          <button
                            type='button'
                            className='btn btn-lg btn-outline btn-outline-dashed m-5'
                            style={{backgroundColor: formik.values.color}}
                          >
                            &nbsp;&nbsp; &nbsp;&nbsp;
                          </button>
                          <input
                            type='text'
                            className={clsx(
                              'form-control form-control-solid mb-5',
                              {'is-invalid': formik.touched.color && formik.errors.color},
                              {
                                'is-valid': formik.touched.color && !formik.errors.color,
                              }
                            )}
                            placeholder={intl.formatMessage({id: 'LABEL_COLOR'})}
                            {...formik.getFieldProps('color')}
                            onChange={(e) =>
                              formik.setFieldValue(
                                'color',
                                inputTrimSpace(e.target.value).toLowerCase()
                              )
                            }
                            readOnly={action === 'edit' ? false : true}
                          />
                          {action === 'edit' ? (
                            <HexColorPicker
                              color={formik.values.color}
                              onChange={handleColorChange}
                            />
                          ) : (
                            <></>
                          )}

                          {formik.touched.color && formik.errors.color ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {formik.errors.color}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Color */}

                        {/* Logo */}
                        <div className='fv-row mb-5'>
                          <span className='d-flex flex-column'>
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action === 'edit' ? ' required' : ''
                              }`}
                            >
                              Logo
                            </label>
                            {action === 'edit' && (
                              <span className='fs-7 text-muted mb-5'>
                                {intl.formatMessage({id: 'LABEL_INPUT_FILE_IMAGE_DESC'})}
                              </span>
                            )}
                            {action === 'edit' ? (
                              <>
                                <input
                                  ref={fileRef}
                                  type='file'
                                  className={clsx(
                                    'form-control form-control-solid',
                                    {'is-invalid': formik.touched.file && formik.errors.file},
                                    {
                                      'is-valid': formik.touched.file && !formik.errors.file,
                                    }
                                  )}
                                  name='file'
                                  onChange={handleInputFile}
                                  multiple={false}
                                  accept='image/*'
                                />
                                {formik.errors.file ? (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block text-danger fw-bolder'>
                                      {intl.formatMessage(
                                        {id: 'VALIDATION_REQUIRED_FIELD'},
                                        {
                                          name: 'Logo',
                                        }
                                      )}
                                    </div>
                                  </div>
                                ) : null}

                                {size > Number(MAX_SIZED) ? (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block text-danger fw-bolder'>
                                      {intl.formatMessage(
                                        {id: 'VALIDATION_MAX_FILE_SIZE'},
                                        {
                                          max: Number(MAX_SIZED),
                                        }
                                      )}
                                    </div>
                                  </div>
                                ) : null}
                              </>
                            ) : (
                              <div className='d-flex align-items-center'>
                                <RowFileFormatter
                                  size={200}
                                  fileUrl={models.logo}
                                  fileID={models.sales_type_id}
                                />
                              </div>
                            )}
                          </span>
                        </div>
                        {/* Logo */}
                      </div>
                      {/* First Col */}

                      {/* Second Col */}
                      <div className='col-lg-6'>
                        {/* Holding */}
                        <div className='fv-row mb-5'>
                          {formik.values?.holding_id?.length > 0 && (
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action === 'edit' ? 'required' : ''
                              }`}
                            >
                              Holding
                            </label>
                          )}
                          <CheckPicker
                            readOnly={action !== 'edit' ? true : false}
                            block
                            placeholder={'-- Holding --'}
                            data={optionsHolding}
                            defaultValue={models.holding_id}
                            onChange={(val, tr) => {
                              let tmp = []
                              let result: any[] = []
                              let label: any = formik.values.holding_name

                              if (tr.type === 'keydown') {
                                // prevent user select/choice by using click enter
                                return
                              }

                              if (val.length > 0) {
                                if ((tr.target as unknown as any).checked === true) {
                                  tmp = (optionsHolding as unknown as any[]).find(
                                    (dt) =>
                                      dt.label === (tr.target as unknown as any).labels[0].innerText
                                  )
                                  result = [...label, tmp.label]
                                } else {
                                  if (tr.type === 'click') {
                                    if (
                                      (tr.target as unknown as any).lastChild.nodeName === 'path'
                                    ) {
                                      let tmp_result = label.filter(
                                        (e: any) =>
                                          e !==
                                          (tr.target as unknown as any).parentElement.parentElement
                                            .innerText
                                      )
                                      result = tmp_result
                                    }
                                  } else {
                                    let tmp_result = label.filter(
                                      (e: any) =>
                                        e !== (tr.target as unknown as any).labels[0].innerText
                                    )
                                    result = tmp_result
                                  }
                                }
                                formik.setFieldValue('holding_id', val)
                                formik.setFieldValue('holding_name', result)
                              } else {
                                formik.setFieldValue('holding_id', [])
                                formik.setFieldValue('holding_name', [])
                              }

                              formik.setFieldValue('company_id', [])
                              formik.setFieldValue('company_name', [])
                              formik.setFieldValue('brand_id', [])
                              formik.setFieldValue('brand_name', [])
                              formik.setFieldValue('payment_method_id', [])
                              formik.setFieldValue('payment_method_name', [])
                            }}
                            onSelect={(val, ite) => {
                              let value: any = formik.values.holding_id
                              let label: any = formik.values.holding_name
                              let tmpValue: any = []
                              let tmpLabel: any = []
                              let resultValue: any[] = []
                              let resultLabel: any[] = []

                              if (val.length > 0) {
                                if (value.indexOf(ite.value) === -1) {
                                  tmpValue = (optionsHolding as unknown as any[]).find(
                                    (dt) => dt.value === ite.value
                                  )

                                  if (tmpValue === undefined) {
                                    return
                                  }

                                  resultValue = [...value, tmpValue.value]
                                  formik.setFieldValue('holding_id', resultValue)
                                } else {
                                  tmpValue = value.filter((dt: any) => dt !== ite.value)
                                  formik.setFieldValue('holding_id', tmpValue)
                                }

                                if (label.indexOf(ite.label) === -1) {
                                  tmpLabel = (optionsHolding as unknown as any[]).find(
                                    (dt) => dt.label === ite.label
                                  )

                                  if (tmpLabel === undefined) {
                                    return
                                  }

                                  resultLabel = [...label, tmpLabel.label]
                                  formik.setFieldValue('holding_name', resultLabel)
                                } else {
                                  tmpLabel = label.filter((dt: any) => dt !== ite.label)
                                  formik.setFieldValue('holding_name', tmpLabel)
                                }
                              } else {
                                formik.setFieldValue('holding_id', [])
                                formik.setFieldValue('holding_name', [])
                              }

                              formik.setFieldValue('company_id', [])
                              formik.setFieldValue('company_name', [])
                              formik.setFieldValue('brand_id', [])
                              formik.setFieldValue('brand_name', [])
                              formik.setFieldValue('payment_method_id', [])
                              formik.setFieldValue('payment_method_name', [])
                            }}
                            value={formik.values.holding_id}
                            renderExtraFooter={() => (
                              <div style={footerStyles}>
                                <Checkbox
                                  indeterminate={
                                    (formik.values.holding_id as unknown as any[]).length > 0 &&
                                    (formik.values.holding_id as unknown as any[]).length <
                                      (optionsHolding as unknown as any[]).length
                                  }
                                  checked={
                                    (formik.values.holding_id as unknown as any[]).length ===
                                    (optionsHolding as unknown as any[]).length
                                  }
                                  onChange={(data, checked) => {
                                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                                    if (checked) {
                                      formik.setFieldValue(
                                        'holding_id',
                                        (optionsHolding as unknown as any[]).map((e) => e.value)
                                      )
                                      formik.setFieldValue(
                                        'holding_name',
                                        (optionsHolding as unknown as any[]).map((e) => e.label)
                                      )
                                    } else {
                                      formik.setFieldValue('holding_id', [])
                                      formik.setFieldValue('holding_name', [])
                                    }
                                  }}
                                >
                                  {(formik.values.holding_id as unknown as any[]).length ===
                                  (optionsHolding as unknown as any[]).length
                                    ? intl.formatMessage({id: 'LABEL_UNSELECT_ALL'})
                                    : intl.formatMessage({id: 'LABEL_SELECT_ALL'})}
                                </Checkbox>
                              </div>
                            )}
                          />
                          {formik.errors.holding_id ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {intl.formatMessage(
                                  {id: 'VALIDATION_REQUIRED_FIELD'},
                                  {
                                    name: 'Holding',
                                  }
                                )}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Holding */}

                        {/* Company */}
                        <div className='fv-row mb-5'>
                          {formik.values?.company_id?.length > 0 && (
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action === 'edit' ? 'required' : ''
                              }`}
                            >
                              {intl.formatMessage({id: 'LABEL_COMPANY'})}
                            </label>
                          )}
                          {action !== 'edit' ? (
                            models.company !== undefined && (
                              <CheckPicker
                                readOnly={true}
                                block
                                data={(models.company as unknown as any[]).map((e) => ({
                                  value: e.company_id,
                                  label: e.name,
                                }))}
                                defaultValue={models.company_id}
                              />
                            )
                          ) : (
                            <CheckPicker
                              block
                              disabled={
                                formik.values.holding_id &&
                                (formik.values.holding_id as unknown as any[]).length > 0
                                  ? false
                                  : true
                              }
                              placeholder={`-- ${intl.formatMessage({id: 'LABEL_COMPANY'})} --`}
                              data={optionsCompany}
                              defaultValue={models.company_id}
                              onChange={(val, tr) => {
                                let tmp = []
                                let result: any[] = []
                                let label: any = formik.values.company_name

                                if (tr.type === 'keydown') {
                                  // prevent user select/choice by using click enter
                                  return
                                }

                                if (val.length > 0) {
                                  if ((tr.target as unknown as any).checked === true) {
                                    tmp = (optionsCompany as unknown as any[]).find(
                                      (dt) =>
                                        dt.label ===
                                        (tr.target as unknown as any).labels[0].innerText
                                    )
                                    result = [...label, tmp.label]
                                  } else {
                                    if (tr.type === 'click') {
                                      if (
                                        (tr.target as unknown as any).lastChild.nodeName === 'path'
                                      ) {
                                        let tmp_result = label.filter(
                                          (e: any) =>
                                            e !==
                                            (tr.target as unknown as any).parentElement
                                              .parentElement.innerText
                                        )
                                        result = tmp_result
                                      }
                                    } else {
                                      let tmp_result = label.filter(
                                        (e: any) =>
                                          e !== (tr.target as unknown as any).labels[0].innerText
                                      )
                                      result = tmp_result
                                    }
                                  }
                                  formik.setFieldValue('company_id', val)
                                  formik.setFieldValue('company_name', result)
                                } else {
                                  formik.setFieldValue('company_id', [])
                                  formik.setFieldValue('company_name', [])
                                }

                                formik.setFieldValue('brand_id', [])
                                formik.setFieldValue('brand_name', [])
                                formik.setFieldValue('payment_method_id', [])
                                formik.setFieldValue('payment_method_name', [])
                              }}
                              onSelect={(val, ite) => {
                                let value: any = formik.values.company_id
                                let label: any = formik.values.company_name
                                let tmpValue: any = []
                                let tmpLabel: any = []
                                let resultValue: any[] = []
                                let resultLabel: any[] = []

                                if (val.length > 0) {
                                  if (value.indexOf(ite.value) === -1) {
                                    tmpValue = (optionsCompany as unknown as any[]).find(
                                      (dt) => dt.value === ite.value
                                    )

                                    if (tmpValue === undefined) {
                                      return
                                    }

                                    resultValue = [...value, tmpValue.value]
                                    formik.setFieldValue('company_id', resultValue)
                                  } else {
                                    tmpValue = value.filter((dt: any) => dt !== ite.value)
                                    formik.setFieldValue('company_id', tmpValue)
                                  }

                                  if (label.indexOf(ite.label) === -1) {
                                    tmpLabel = (optionsCompany as unknown as any[]).find(
                                      (dt) => dt.label === ite.label
                                    )

                                    if (tmpLabel === undefined) {
                                      return
                                    }

                                    resultLabel = [...label, tmpLabel.label]
                                    formik.setFieldValue('company_name', resultLabel)
                                  } else {
                                    tmpLabel = label.filter((dt: any) => dt !== ite.label)
                                    formik.setFieldValue('company_name', tmpLabel)
                                  }
                                } else {
                                  formik.setFieldValue('company_id', [])
                                  formik.setFieldValue('company_name', [])
                                }

                                formik.setFieldValue('brand_id', [])
                                formik.setFieldValue('brand_name', [])
                                formik.setFieldValue('payment_method_id', [])
                                formik.setFieldValue('payment_method_name', [])
                              }}
                              value={formik.values.company_id}
                              renderExtraFooter={() => (
                                <div style={footerStyles}>
                                  <Checkbox
                                    indeterminate={
                                      (formik.values.company_id as unknown as any[]).length > 0 &&
                                      (formik.values.company_id as unknown as any[]).length <
                                        (optionsCompany as unknown as any[]).length
                                    }
                                    checked={
                                      (formik.values.company_id as unknown as any[]).length ===
                                      (optionsCompany as unknown as any[]).length
                                    }
                                    onChange={(data, checked) => {
                                      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                                      if (checked) {
                                        formik.setFieldValue(
                                          'company_id',
                                          (optionsCompany as unknown as any[]).map((e) => e.value)
                                        )
                                        formik.setFieldValue(
                                          'company_name',
                                          (optionsCompany as unknown as any[]).map((e) => e.label)
                                        )
                                      } else {
                                        formik.setFieldValue('company_id', [])
                                        formik.setFieldValue('company_name', [])
                                      }
                                    }}
                                  >
                                    {(formik.values.company_id as unknown as any[]).length ===
                                    (optionsCompany as unknown as any[]).length
                                      ? intl.formatMessage({id: 'LABEL_UNSELECT_ALL'})
                                      : intl.formatMessage({id: 'LABEL_SELECT_ALL'})}
                                  </Checkbox>
                                </div>
                              )}
                            />
                          )}
                          {formik.errors.company_id ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {intl.formatMessage(
                                  {id: 'VALIDATION_REQUIRED_FIELD'},
                                  {
                                    name: intl.formatMessage({id: 'LABEL_COMPANY'}),
                                  }
                                )}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Company */}

                        {/* Brand */}
                        <div className='fv-row mb-5'>
                          {formik.values?.brand_id?.length > 0 && (
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action === 'edit' ? 'required' : ''
                              }`}
                            >
                              {intl.formatMessage({id: 'LABEL_BRAND'})}
                            </label>
                          )}
                          {action !== 'edit' ? (
                            models.brand !== undefined && (
                              <CheckPicker
                                readOnly={true}
                                block
                                data={(models.brand as unknown as any[]).map((e) => ({
                                  value: e.brand_id,
                                  label: e.name,
                                }))}
                                defaultValue={models.brand_id}
                              />
                            )
                          ) : (
                            <CheckPicker
                              block
                              disabled={
                                formik.values.company_id &&
                                (formik.values.company_id as unknown as any[]).length > 0
                                  ? false
                                  : true
                              }
                              placeholder={`-- ${intl.formatMessage({id: 'LABEL_BRAND'})} --`}
                              data={optionsBrand}
                              defaultValue={models.brand_id}
                              onChange={(val, tr) => {
                                let tmp = []
                                let result: any[] = []
                                let label: any = formik.values.brand_name

                                if (tr.type === 'keydown') {
                                  // prevent user select/choice by using click enter
                                  return
                                }

                                if (val.length > 0) {
                                  if ((tr.target as unknown as any).checked === true) {
                                    tmp = (optionsBrand as unknown as any[]).find(
                                      (dt) =>
                                        dt.label ===
                                        (tr.target as unknown as any).labels[0].innerText
                                    )
                                    result = [...label, tmp.label]
                                  } else {
                                    if (tr.type === 'click') {
                                      if (
                                        (tr.target as unknown as any).lastChild.nodeName === 'path'
                                      ) {
                                        let tmp_result = label.filter(
                                          (e: any) =>
                                            e !==
                                            (tr.target as unknown as any).parentElement
                                              .parentElement.innerText
                                        )
                                        result = tmp_result
                                      }
                                    } else {
                                      let tmp_result = label.filter(
                                        (e: any) =>
                                          e !== (tr.target as unknown as any).labels[0].innerText
                                      )
                                      result = tmp_result
                                    }
                                  }
                                  formik.setFieldValue('brand_id', val)
                                  formik.setFieldValue('brand_name', result)
                                } else {
                                  formik.setFieldValue('brand_id', [])
                                  formik.setFieldValue('brand_name', [])
                                }

                                formik.setFieldValue('payment_method_id', [])
                                formik.setFieldValue('payment_method_name', [])
                              }}
                              onSelect={(val, ite) => {
                                let value: any = formik.values.brand_id
                                let label: any = formik.values.brand_name
                                let tmpValue: any = []
                                let tmpLabel: any = []
                                let resultValue: any[] = []
                                let resultLabel: any[] = []

                                if (val.length > 0) {
                                  if (value.indexOf(ite.value) === -1) {
                                    tmpValue = (optionsBrand as unknown as any[]).find(
                                      (dt) => dt.value === ite.value
                                    )

                                    if (tmpValue === undefined) {
                                      return
                                    }

                                    resultValue = [...value, tmpValue.value]
                                    formik.setFieldValue('brand_id', resultValue)
                                  } else {
                                    tmpValue = value.filter((dt: any) => dt !== ite.value)
                                    formik.setFieldValue('brand_id', tmpValue)
                                  }

                                  if (label.indexOf(ite.label) === -1) {
                                    tmpLabel = (optionsBrand as unknown as any[]).find(
                                      (dt) => dt.label === ite.label
                                    )

                                    if (tmpLabel === undefined) {
                                      return
                                    }

                                    resultLabel = [...label, tmpLabel.label]
                                    formik.setFieldValue('brand_name', resultLabel)
                                  } else {
                                    tmpLabel = label.filter((dt: any) => dt !== ite.label)
                                    formik.setFieldValue('brand_name', tmpLabel)
                                  }
                                } else {
                                  formik.setFieldValue('brand_id', [])
                                  formik.setFieldValue('brand_name', [])
                                }

                                formik.setFieldValue('payment_method_id', [])
                                formik.setFieldValue('payment_method_name', [])
                              }}
                              value={formik.values.brand_id}
                              renderExtraFooter={() => (
                                <div style={footerStyles}>
                                  <Checkbox
                                    indeterminate={
                                      (formik.values.brand_id as unknown as any[]).length > 0 &&
                                      (formik.values.brand_id as unknown as any[]).length <
                                        (optionsBrand as unknown as any[]).length
                                    }
                                    checked={
                                      (formik.values.brand_id as unknown as any[]).length ===
                                      (optionsBrand as unknown as any[]).length
                                    }
                                    onChange={(data, checked) => {
                                      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                                      if (checked) {
                                        formik.setFieldValue(
                                          'brand_id',
                                          (optionsBrand as unknown as any[]).map((e) => e.value)
                                        )
                                        formik.setFieldValue(
                                          'brand_name',
                                          (optionsBrand as unknown as any[]).map((e) => e.label)
                                        )
                                      } else {
                                        formik.setFieldValue('brand_id', [])
                                        formik.setFieldValue('brand_name', [])
                                      }
                                    }}
                                  >
                                    {(formik.values.brand_id as unknown as any[]).length ===
                                    (optionsBrand as unknown as any[]).length
                                      ? intl.formatMessage({id: 'LABEL_UNSELECT_ALL'})
                                      : intl.formatMessage({id: 'LABEL_SELECT_ALL'})}
                                  </Checkbox>
                                </div>
                              )}
                            />
                          )}
                          {formik.errors.brand_id ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {intl.formatMessage(
                                  {id: 'VALIDATION_REQUIRED_FIELD'},
                                  {
                                    name: intl.formatMessage({id: 'LABEL_BRAND'}),
                                  }
                                )}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Brand */}

                        {/* Payment Method */}
                        <div className='fv-row mb-5'>
                          {formik.values?.payment_method_id?.length > 0 && (
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action === 'edit' ? 'required' : ''
                              }`}
                            >
                              {intl.formatMessage({id: 'LABEL_PAYMENT_METHOD'})}
                            </label>
                          )}
                          {action !== 'edit' ? (
                            models.payment_method !== undefined && (
                              <CheckPicker
                                readOnly={true}
                                block
                                data={(models.payment_method as unknown as any[]).map((e) => ({
                                  value: e.payment_method_id,
                                  label: e.name,
                                }))}
                                defaultValue={models.payment_method_id}
                              />
                            )
                          ) : (
                            <CheckPicker
                              block
                              disabled={
                                formik.values.brand_id &&
                                (formik.values.brand_id as unknown as any[]).length > 0
                                  ? false
                                  : true
                              }
                              placeholder={`-- ${intl.formatMessage({
                                id: 'LABEL_PAYMENT_METHOD',
                              })} --`}
                              data={optionsPaymentMethod}
                              defaultValue={models.payment_method_id}
                              onChange={(val, tr) => {
                                let tmp = []
                                let result: any[] = []
                                let label: any = formik.values.payment_method_name

                                if (tr.type === 'keydown') {
                                  // prevent user select/choice by using click enter
                                  return
                                }

                                if (val.length > 0) {
                                  if ((tr.target as unknown as any).checked === true) {
                                    tmp = (optionsPaymentMethod as unknown as any[]).find(
                                      (dt) =>
                                        dt.label ===
                                        (tr.target as unknown as any).labels[0].innerText
                                    )
                                    result = [...label, tmp.label]
                                  } else {
                                    if (tr.type === 'click') {
                                      if (
                                        (tr.target as unknown as any).lastChild.nodeName === 'path'
                                      ) {
                                        let tmp_result = label.filter(
                                          (e: any) =>
                                            e !==
                                            (tr.target as unknown as any).parentElement
                                              .parentElement.innerText
                                        )
                                        result = tmp_result
                                      }
                                    } else {
                                      let tmp_result = label.filter(
                                        (e: any) =>
                                          e !== (tr.target as unknown as any).labels[0].innerText
                                      )
                                      result = tmp_result
                                    }
                                  }
                                  formik.setFieldValue('payment_method_id', val)
                                  formik.setFieldValue('payment_method_name', result)
                                } else {
                                  formik.setFieldValue('payment_method_id', [])
                                  formik.setFieldValue('payment_method_name', [])
                                }
                              }}
                              onSelect={(val, ite) => {
                                let value: any = formik.values.payment_method_id
                                let label: any = formik.values.payment_method_name
                                let tmpValue: any = []
                                let tmpLabel: any = []
                                let resultValue: any[] = []
                                let resultLabel: any[] = []

                                if (val.length > 0) {
                                  if (value.indexOf(ite.value) === -1) {
                                    tmpValue = (optionsPaymentMethod as unknown as any[]).find(
                                      (dt) => dt.value === ite.value
                                    )

                                    if (tmpValue === undefined) {
                                      return
                                    }

                                    resultValue = [...value, tmpValue.value]
                                    formik.setFieldValue('payment_method_id', resultValue)
                                  } else {
                                    tmpValue = value.filter((dt: any) => dt !== ite.value)
                                    formik.setFieldValue('payment_method_id', tmpValue)
                                  }

                                  if (label.indexOf(ite.label) === -1) {
                                    tmpLabel = (optionsPaymentMethod as unknown as any[]).find(
                                      (dt) => dt.label === ite.label
                                    )

                                    if (tmpLabel === undefined) {
                                      return
                                    }

                                    resultLabel = [...label, tmpLabel.label]
                                    formik.setFieldValue('payment_method_name', resultLabel)
                                  } else {
                                    tmpLabel = label.filter((dt: any) => dt !== ite.label)
                                    formik.setFieldValue('payment_method_name', tmpLabel)
                                  }
                                } else {
                                  formik.setFieldValue('payment_method_id', [])
                                  formik.setFieldValue('payment_method_name', [])
                                }
                              }}
                              value={formik.values.payment_method_id}
                              renderExtraFooter={() => (
                                <div style={footerStyles}>
                                  <Checkbox
                                    indeterminate={
                                      (formik.values.payment_method_id as unknown as any[]).length >
                                        0 &&
                                      (formik.values.payment_method_id as unknown as any[]).length <
                                        (optionsPaymentMethod as unknown as any[]).length
                                    }
                                    checked={
                                      (formik.values.payment_method_id as unknown as any[])
                                        .length ===
                                      (optionsPaymentMethod as unknown as any[]).length
                                    }
                                    onChange={(data, checked) => {
                                      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                                      if (checked) {
                                        formik.setFieldValue(
                                          'payment_method_id',
                                          (optionsPaymentMethod as unknown as any[]).map(
                                            (e) => e.value
                                          )
                                        )
                                        formik.setFieldValue(
                                          'payment_method_name',
                                          (optionsPaymentMethod as unknown as any[]).map(
                                            (e) => e.label
                                          )
                                        )
                                      } else {
                                        formik.setFieldValue('payment_method_id', [])
                                        formik.setFieldValue('payment_method_name', [])
                                      }
                                    }}
                                  >
                                    {(formik.values.payment_method_id as unknown as any[])
                                      .length === (optionsPaymentMethod as unknown as any[]).length
                                      ? intl.formatMessage({id: 'LABEL_UNSELECT_ALL'})
                                      : intl.formatMessage({id: 'LABEL_SELECT_ALL'})}
                                  </Checkbox>
                                </div>
                              )}
                            />
                          )}
                          {formik.errors.payment_method_id ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {intl.formatMessage(
                                  {id: 'VALIDATION_REQUIRED_FIELD'},
                                  {
                                    name: intl.formatMessage({id: 'LABEL_PAYMENT_METHOD'}),
                                  }
                                )}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Payment Method */}

                        {/* Order */}
                        <div className='fv-row mb-5'>
                          {formik.values.for_order && formik.values.for_order > 0 && (
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action === 'edit' ? 'required' : ''
                              }`}
                            >
                              {intl.formatMessage({id: 'LABEL_ORDER'})}
                            </label>
                          )}
                          <input
                            type={`${action === 'edit' ? 'number' : 'text'}`}
                            className={clsx(
                              'form-control form-control-solid',
                              {'is-invalid': formik.touched.for_order && formik.errors.for_order},
                              {
                                'is-valid': formik.touched.for_order && !formik.errors.for_order,
                              }
                            )}
                            placeholder={intl.formatMessage({id: 'LABEL_ORDER'})}
                            {...formik.getFieldProps('for_order')}
                            readOnly={action === 'edit' ? false : true}
                            onChange={(e) => {
                              formik.setFieldValue('for_order', e.target.value)
                            }}
                          />
                          {formik.touched.for_order && formik.errors.for_order ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger'>
                                {formik.errors.for_order}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Order */}
                      </div>
                      {/* Second Col */}
                    </div>

                    <div className='row mt-10'>
                      {/* Third Col */}
                      <div className='col-lg-6'>
                        {/* Description */}
                        <div className='fv-row mb-5'>
                          {formik.values.description !== '' && (
                            <label className={`form-label fs-6 fw-bolder text-dark`}>
                              {intl.formatMessage({id: 'LABEL_DESCRIPTION'})}
                            </label>
                          )}
                          <textarea
                            className={clsx(
                              'form-control form-control-solid',
                              {
                                'is-invalid':
                                  formik.touched.description && formik.errors.description,
                              },
                              {
                                'is-valid':
                                  formik.touched.description && !formik.errors.description,
                              }
                            )}
                            placeholder={intl.formatMessage({id: 'LABEL_DESCRIPTION'})}
                            {...formik.getFieldProps('description')}
                            rows={3}
                            readOnly={action !== 'edit' ? true : false}
                          />
                        </div>
                        {/* Address */}
                      </div>
                      {/* Third Col */}

                      {/* Fourth Col */}
                      <div className='col-lg-6'>
                        {/* Status */}
                        <div className='fv-row mb-5'>
                          {formik.values.status !== '' && (
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action === 'edit' ? 'required' : ''
                              }`}
                            >
                              Status
                            </label>
                          )}
                          <div className='form-check form-switch form-check-custom form-check-solid mt-1'>
                            <Toggle
                              size='lg'
                              readOnly={action !== 'edit' ? true : false}
                              checkedChildren={intl.formatMessage({id: 'LABEL_ACTIVE'})}
                              unCheckedChildren={intl.formatMessage({id: 'LABEL_INACTIVE'})}
                              checked={formik.values.status === 't' ? true : false}
                              onChange={() =>
                                formik.setFieldValue(
                                  'status',
                                  formik.values.status === 't' ? 'f' : 't'
                                )
                              }
                            />
                          </div>
                          {formik.touched.status && formik.errors.status ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {formik.errors.status}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Status */}
                      </div>
                      {/* Fourth Col */}
                    </div>
                  </div>
                  {/* Data */}

                  {/* Detail */}
                  <div
                    className={clsx('tab-pane', {
                      active: tab === 'log',
                    })}
                  >
                    <FormLog payload={salesType} formik={formik} />
                  </div>
                  {/* Detail */}
                </div>
              </div>
              {/* Tab Body */}
            </form>
          )}
        </div>
        {/* Form */}
      </Drawer.Body>

      {/* Dialog */}
      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(true)}
        className='modal fade show d-block'
      >
        <Dialog.Panel className='modal-dialog modal-fullscreen'>
          <div className='modal-content'>
            <Dialog.Title className='modal-header'>
              {intl.formatMessage({id: 'DIALOG_HEADER_TITLE'})}
            </Dialog.Title>
            <div className='modal-body scroll-y mx-2 mx-xl-2 my-2'>
              <div className='row'>
                {/* First Col */}
                <div className='col-lg-6'>
                  {/* Name */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark'>
                      {intl.formatMessage({id: 'LABEL_NAME'})}
                    </label>
                    {models.name !== formik.values.name ? (
                      <>
                        {action !== 'add' && (
                          <input
                            type='text'
                            className='form-control form-control-solid'
                            value={models.name}
                            readOnly
                          />
                        )}
                        <input
                          type='text'
                          className='form-control form-control-solid text-primary is-valid'
                          value={formik.values.name}
                          readOnly
                        />
                      </>
                    ) : (
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        value={models.name}
                        readOnly
                      />
                    )}
                  </div>
                  {/* Name */}

                  {/* Color */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark'>
                      {intl.formatMessage({id: 'LABEL_COLOR'})}
                    </label>
                    {models.color !== formik.values.color ? (
                      <>
                        {action !== 'add' && (
                          <button
                            className='btn btn-lg btn-outline btn-outline-dashed m-5'
                            style={{backgroundColor: models.color}}
                          >
                            &nbsp;&nbsp; &nbsp;&nbsp;
                          </button>
                        )}
                        <button
                          className='btn btn-lg btn-outline btn-outline-dashed m-5'
                          style={{backgroundColor: formik.values.color}}
                        >
                          &nbsp;&nbsp; &nbsp;&nbsp;
                        </button>
                      </>
                    ) : (
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        value={models.color}
                        readOnly
                      />
                    )}
                  </div>
                  {/* Color */}

                  {/* LOGO */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark'>Logo</label>
                    {models.file !== formik.values.file ? (
                      <>
                        {action !== 'add' && (
                          <div className='d-flex align-items-center mb-10'>
                            <RowFileFormatter
                              size={200}
                              fileUrl={models.logo}
                              fileID={models.sales_type_id}
                            />
                          </div>
                        )}
                        <div className='d-flex align-items-center'>
                          <RowFileFormatter
                            size={200}
                            fileUrl={preview}
                            fileID={formik.values.sales_type_id}
                          />
                        </div>
                      </>
                    ) : (
                      <div className='d-flex align-items-center'>
                        <RowFileFormatter
                          size={200}
                          fileUrl={models.logo}
                          fileID={models.sales_type_id}
                        />
                      </div>
                    )}
                  </div>
                  {/* LOGO */}
                </div>
                {/* First Col */}

                {/* Second Col */}
                <div className='col-lg-6'>
                  {/* Holding */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark'>Holding</label>
                    {models.holding_id !== formik.values.holding_id ? (
                      <>
                        {action !== 'add' && (
                          <textarea
                            className='form-control form-control-solid'
                            value={models.holding_name?.toString().replaceAll(',', ', ')}
                            readOnly
                          />
                        )}
                        <textarea
                          className='form-control form-control-solid text-primary is-valid'
                          value={formik.values?.holding_name?.toString().replaceAll(',', ', ')}
                          readOnly
                        />
                      </>
                    ) : (
                      <textarea
                        className='form-control form-control-solid'
                        value={formik.values?.holding_name?.toString().replaceAll(',', ', ')}
                        readOnly
                      />
                    )}
                  </div>
                  {/* Holding */}

                  {/* Company */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark'>
                      {intl.formatMessage({id: 'LABEL_COMPANY'})}
                    </label>
                    {models.company_id !== formik.values.company_id ? (
                      <>
                        {action !== 'add' && (
                          <textarea
                            className='form-control form-control-solid'
                            value={models.company_name?.toString().replaceAll(',', ', ')}
                            readOnly
                          />
                        )}
                        <textarea
                          className='form-control form-control-solid text-primary is-valid'
                          value={formik.values?.company_name?.toString().replaceAll(',', ', ')}
                          readOnly
                        />
                      </>
                    ) : (
                      <textarea
                        className='form-control form-control-solid'
                        value={formik.values?.company_name?.toString().replaceAll(',', ', ')}
                        readOnly
                      />
                    )}
                  </div>
                  {/* Company */}

                  {/* Brand */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark'>
                      {intl.formatMessage({id: 'LABEL_BRAND'})}
                    </label>
                    {models.brand_id !== formik.values.brand_id ? (
                      <>
                        {action !== 'add' && (
                          <textarea
                            className='form-control form-control-solid'
                            value={models.brand_name?.toString().replaceAll(',', ', ')}
                            readOnly
                          />
                        )}
                        <textarea
                          className='form-control form-control-solid text-primary is-valid'
                          value={formik.values?.brand_name?.toString().replaceAll(',', ', ')}
                          readOnly
                        />
                      </>
                    ) : (
                      <textarea
                        className='form-control form-control-solid'
                        value={formik.values?.brand_name?.toString().replaceAll(',', ', ')}
                        readOnly
                      />
                    )}
                  </div>
                  {/* Brand */}

                  {/* Payment Method */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark'>
                      {intl.formatMessage({id: 'LABEL_PAYMENT_METHOD'})}
                    </label>
                    {models.payment_method_id !== formik.values.payment_method_id ? (
                      <>
                        {action !== 'add' && (
                          <textarea
                            className='form-control form-control-solid'
                            value={models.payment_method_name?.toString().replaceAll(',', ', ')}
                            readOnly
                          />
                        )}
                        <textarea
                          className='form-control form-control-solid text-primary is-valid'
                          value={formik.values?.payment_method_name
                            ?.toString()
                            .replaceAll(',', ', ')}
                          readOnly
                        />
                      </>
                    ) : (
                      <textarea
                        className='form-control form-control-solid'
                        value={formik.values?.payment_method_name?.toString().replaceAll(',', ', ')}
                        readOnly
                      />
                    )}
                  </div>
                  {/* Payment Method */}

                  {/* Order */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark'>
                      {intl.formatMessage({id: 'LABEL_ORDER'})}
                    </label>
                    {models.for_order?.toString() !== formik.values?.for_order?.toString() ? (
                      <>
                        {action !== 'add' && (
                          <input
                            type='text'
                            className='form-control form-control-solid'
                            value={models.for_order}
                            readOnly
                          />
                        )}
                        <input
                          type='text'
                          className='form-control form-control-solid text-primary is-valid'
                          value={formik.values.for_order}
                          readOnly
                        />
                      </>
                    ) : (
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        value={models.for_order}
                        readOnly
                      />
                    )}
                  </div>
                  {/* Order */}
                </div>
                {/* Second Col */}
              </div>

              <div className='row mt-10'>
                {/* Third Col */}
                <div className='col-lg-6'>
                  {/* Description */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark'>
                      {intl.formatMessage({id: 'LABEL_DESCRIPTION'})}
                    </label>
                    {models.description !== formik.values.description ? (
                      <>
                        {action !== 'add' && (
                          <textarea
                            className='form-control form-control-solid'
                            value={models.description}
                            readOnly
                          />
                        )}
                        <textarea
                          className='form-control form-control-solid text-primary is-valid'
                          value={formik.values.description}
                          readOnly
                        />
                      </>
                    ) : (
                      <textarea
                        className='form-control form-control-solid'
                        value={formik.values.description}
                        readOnly
                      />
                    )}
                  </div>
                  {/* Description */}
                </div>
                {/* Third Col */}

                {/* Fourth Col */}
                <div className='col-lg-6'>
                  {/* Status */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark'>
                      <label className='form-label fs-6 fw-bolder text-dark'>Status</label>
                    </label>
                    {models.status !== formik.values.status ? (
                      <>
                        {action !== 'add' && (
                          <input
                            type='text'
                            className='form-control form-control-solid'
                            value={
                              models.status === 't'
                                ? intl.formatMessage({id: 'LABEL_ACTIVE'})
                                : intl.formatMessage({id: 'LABEL_INACTIVE'})
                            }
                            readOnly
                          />
                        )}
                        <input
                          type='text'
                          className='form-control form-control-solid text-primary is-valid'
                          value={
                            formik.values.status === 't'
                              ? intl.formatMessage({id: 'LABEL_ACTIVE'})
                              : intl.formatMessage({id: 'LABEL_INACTIVE'})
                          }
                          readOnly
                        />
                      </>
                    ) : (
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        value={
                          models.status === 't'
                            ? intl.formatMessage({id: 'LABEL_ACTIVE'})
                            : intl.formatMessage({id: 'LABEL_INACTIVE'})
                        }
                        readOnly
                      />
                    )}
                  </div>
                  {/* Status */}
                </div>
                {/* Fourth Col */}
              </div>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-outline btn-btn-outline-info me-3'
                data-kt-users-modal-action='close'
                onClick={() => setIsDialogOpen(false)}
              >
                {intl.formatMessage({id: 'BUTTON_LABEL_BACK'})}
              </button>

              <button
                type='button'
                className={`btn ${loading ? 'btn-secondary' : 'btn-success'}`}
                data-kt-users-modal-action='submit'
                onClick={() => formik.handleSubmit()}
                disabled={loading ? true : false}
              >
                {!loading && (
                  <span className='indicator-label'>
                    {intl.formatMessage({id: 'BUTTON_LABEL_SUBMIT'})}
                  </span>
                )}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    {intl.formatMessage({id: 'BUTTON_LABEL_PLEASE_WAIT'})}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
      {/* Dialog */}
    </>
  )
}

export {ViewDrawerForm}
