import {FC} from 'react'
import TimeAgo from 'react-timeago'
import enFormat from 'react-timeago/lib/language-strings/en'
import idFormat from 'react-timeago/lib/language-strings/id'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'

type Props = {
  datetime?: string
  local?: string
}

const RowTimeAgoCellFormatter: FC<Props> = ({datetime, local}) => {
  return (
    <div>
      {datetime === '' ? (
        <span>{'-'}</span>
      ) : (
        <TimeAgo date={datetime} formatter={buildFormatter(local === 'id' ? idFormat : enFormat)} />
      )}
    </div>
  )
}

export {RowTimeAgoCellFormatter}
