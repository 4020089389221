import {FC, createContext, useContext, useEffect, useRef, useState} from 'react'

import {WithChildren} from '_metronic/helpers'
import {ShortcutMenuStateModels, ShortcutMenus} from 'app/context/models'

import * as contextHelper from 'app/context/state/ShortcutMenuStateContext'

export type ShortcutMenusContextProps = {
  state: ShortcutMenus | undefined
  updateState: (shortcutMenu: ShortcutMenuStateModels, action: string) => void
}

export const initialShortcutMenus: ShortcutMenusContextProps = {
  state: [],
  updateState: () => {},
}

const ShortcutMenusContext = createContext<ShortcutMenusContextProps>(initialShortcutMenus)

const useShortcutMenus = () => {
  return useContext(ShortcutMenusContext)
}

const ShortcutMenusProvider: FC<WithChildren> = ({children}) => {
  const [state, setState] = useState<ShortcutMenus>(contextHelper.getShortcutMenus())

  const updateState = (shortcutMenu: ShortcutMenuStateModels, action: string) => {
    const result: ShortcutMenus = state

    if (action === 'pin') {
      result?.push(shortcutMenu)
      result?.filter((a) => a.menu_id !== '' || a.menu_id !== undefined)

      if (result === undefined) {
        window.location.reload()
        return false
      } else {
        setState(result)
        contextHelper.setShortcutMenus(result)
      }
    } else {
      setState(
        result?.filter((a) => {
          return a.menu_id !== shortcutMenu.menu_id
        })
      )
      contextHelper.setShortcutMenus(
        result?.filter((a) => {
          return a.menu_id !== shortcutMenu.menu_id
        })
      )
    }
  }

  return (
    <ShortcutMenusContext.Provider value={{state, updateState}}>
      {children}
    </ShortcutMenusContext.Provider>
  )
}

const ShortcutMenusInit: FC<WithChildren> = ({children}) => {
  const didRequest = useRef(false)
  useEffect(() => {
    if (!didRequest.current && contextHelper.getShortcutMenus() === undefined) {
      contextHelper.setShortcutMenus([])
    }
    didRequest.current = true
    // eslint-disable-next-line
  }, [])

  return <>{children}</>
}

export {ShortcutMenusProvider, ShortcutMenusInit, useShortcutMenus}
