import {FC, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {Cell, Column, HeaderCell, Table} from 'rsuite-table'
import {Loader, Placeholder} from 'rsuite'

import {useThemeMode} from '_metronic/partials'
import {isMobileDevice} from '_metronic/assets/ts/_utils'
import {
  RowNoYesCellFormatter,
  RowStatusCellFormatter,
  RowTooltipCellFormatter,
  RowTooltipCountFormatter,
  RowYesNoCellFormatter,
} from 'app/components'

import {ListActionFormatter} from './ListActionFormatter'
import {
  useListQueryRequest,
  useListQueryResponseLoading,
  useListQueryResponseData,
} from '../../providers'

const ListTable: FC = () => {
  const intl = useIntl()
  const isMobile = isMobileDevice()
  const {state, updateState} = useListQueryRequest()
  const isLoading = useListQueryResponseLoading()
  const users = useListQueryResponseData()
  const data = useMemo(() => users, [users])
  const [usePlaceholder] = useState(true)
  const [sortColumn, setSortColumn] = useState(state?.sort)
  const [sortType, setSortType] = useState(state?.order)
  const {mode} = useThemeMode()

  const sortData = () => {
    if (sortColumn && sortType) {
      return data.sort((a: any, b: any) => {
        let x = a[sortColumn]
        let y = b[sortColumn]

        if (sortType === 'asc') {
          return x - y
        } else {
          return y - x
        }
      })
    }
    return data
  }

  const handleSortColumn = (sortColumn: any, sortType: any) => {
    setSortColumn(sortColumn)
    setSortType(sortType)
    updateState({sort: sortColumn, order: sortType})
  }

  const renderLoading = () => {
    if (usePlaceholder) {
      return (
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            background: mode === 'light' ? '#fff' : '#252530',
            padding: 20,
            zIndex: 1,
          }}
        >
          <Placeholder.Grid rows={5} columns={8} active />
        </div>
      )
    }

    return <Loader center backdrop content={intl.formatMessage({id: 'BUTTON_LABEL_PLEASE_WAIT'})} />
  }

  return (
    <div>
      <Table
        // isTree
        // defaultExpandAllRows
        // rowKey='category_id'
        // shouldUpdateScroll={true}
        wordWrap={isMobile ? true : false}
        bordered
        cellBordered
        loading={isLoading}
        height={450}
        headerHeight={70}
        autoHeight={isMobile ? true : false}
        affixHeader={50}
        affixHorizontalScrollbar
        data={sortData()}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
        renderLoading={isLoading ? renderLoading : undefined}
        locale={{emptyMessage: intl.formatMessage({id: 'LABEL_EMPTY_DATA'})}}
      >
        {/* Category */}
        <Column fixed={isMobile ? false : true} verticalAlign='middle' width={300}>
          <HeaderCell align='center'>
            <span
              className={`fw-bold fs-6 text-decoration-underline ${
                sortColumn === 'category_name' ? 'text-primary' : 'text-dark'
              }`}
            >
              {intl.formatMessage({id: 'LABEL_CATEGORY'})}
            </span>
          </HeaderCell>
          <Cell dataKey='category_name'>
            {(rowData: any) =>
              rowData.category_name.length < 55 ? (
                rowData.category_name
              ) : (
                <RowTooltipCellFormatter text={rowData.category_name} length={54} />
              )
            }
          </Cell>
        </Column>

        {/* Name */}
        <Column fixed={isMobile ? false : true} verticalAlign='middle' width={300}>
          <HeaderCell align='center'>
            <span
              className={`fw-bold fs-6 text-decoration-underline ${
                sortColumn === 'name' ? 'text-primary' : 'text-dark'
              }`}
            >
              {intl.formatMessage({id: 'LABEL_NAME'})}
            </span>
          </HeaderCell>
          <Cell dataKey='name'>
            {(rowData: any) =>
              rowData.name.length < 45 ? (
                rowData.name
              ) : (
                <RowTooltipCellFormatter text={rowData.name} length={44} />
              )
            }
          </Cell>
        </Column>

        {/* Brand */}
        <Column verticalAlign='middle' width={100}>
          <HeaderCell align='center'>
            <span
              className={`fw-bold fs-6 text-decoration-underline ${
                sortColumn === 'brand_slug' ? 'text-primary' : 'text-dark'
              }`}
            >
              {intl.formatMessage({id: 'LABEL_BRAND'})}
            </span>
          </HeaderCell>
          <Cell dataKey='brand_slug' align='center'>
            {(rowData: any) =>
              rowData.brand_slug.length < 15 ? (
                rowData.brand_slug
              ) : (
                <RowTooltipCellFormatter text={rowData.brand_slug} length={14} />
              )
            }
          </Cell>
        </Column>

        {/* Outlet */}
        <Column verticalAlign='middle' width={125}>
          <HeaderCell align='center'>
            <span
              className={`fw-bold fs-6 text-decoration-underline ${
                sortColumn === 'c_outlet' ? 'text-primary' : 'text-dark'
              }`}
            >
              {intl.formatMessage({id: 'LABEL_OUTLET'})}
            </span>
          </HeaderCell>
          <Cell dataKey='c_outlet' align='center'>
            {(rowData: any) => (
              <RowTooltipCountFormatter
                count={rowData.c_outlet}
                data={rowData.outlets}
                id={rowData.item_id}
              />
            )}
          </Cell>
        </Column>

        {/* Sales Type */}
        <Column verticalAlign='middle' width={125}>
          <HeaderCell align='center'>
            <span
              className={`fw-bold fs-6 text-decoration-underline ${
                sortColumn === 'c_sales_type' ? 'text-primary' : 'text-dark'
              }`}
            >
              {intl.formatMessage({id: 'LABEL_SALES_TYPE'}).length < 9 ? (
                intl.formatMessage({id: 'LABEL_SALES_TYPE'})
              ) : (
                <RowTooltipCellFormatter
                  text={intl.formatMessage({id: 'LABEL_SALES_TYPE'})}
                  length={8}
                />
              )}
            </span>
          </HeaderCell>
          <Cell dataKey='c_sales_type' align='center'>
            {(rowData: any) => (
              <RowTooltipCountFormatter
                count={rowData.c_sales_type}
                data={rowData.sales_types}
                id={rowData.item_id}
              />
            )}
          </Cell>
        </Column>

        {/* Variant */}
        <Column verticalAlign='middle' width={125}>
          <HeaderCell align='center'>
            <span
              className={`fw-bold fs-6 text-decoration-underline ${
                sortColumn === 'c_variant' ? 'text-primary' : 'text-dark'
              }`}
            >
              {intl.formatMessage({id: 'LABEL_IS_VARIANT'}).length < 9 ? (
                intl.formatMessage({id: 'LABEL_IS_VARIANT'})
              ) : (
                <RowTooltipCellFormatter
                  text={intl.formatMessage({id: 'LABEL_IS_VARIANT'})}
                  length={8}
                />
              )}
            </span>
          </HeaderCell>
          <Cell dataKey='c_variant' align='center'>
            {(rowData: any) => (
              <RowTooltipCountFormatter
                count={rowData.c_variant}
                data={rowData.variants}
                id={rowData.item_id}
              />
            )}
          </Cell>
        </Column>

        {/* Order */}
        <Column verticalAlign='middle' width={100}>
          <HeaderCell align='center'>
            <span
              className={`fw-bold fs-6 text-decoration-underline ${
                sortColumn === 'for_order' ? 'text-primary' : 'text-dark'
              }`}
            >
              {intl.formatMessage({id: 'LABEL_MASTER_ITEMS_ORDER_INFO'}).length < 9 ? (
                intl.formatMessage({id: 'LABEL_MASTER_ITEMS_ORDER_INFO'})
              ) : (
                <RowTooltipCellFormatter
                  text={intl.formatMessage({id: 'LABEL_MASTER_ITEMS_ORDER_INFO'})}
                  length={8}
                />
              )}
            </span>
          </HeaderCell>
          <Cell dataKey='for_order' align='center'>
            {(rowData: any) => rowData.for_order}
          </Cell>
        </Column>

        {/* Is Default */}
        <Column verticalAlign='middle' width={100}>
          <HeaderCell align='center'>
            <span
              className={`fw-bold fs-6 text-decoration-underline ${
                sortColumn === 'is_default' ? 'text-primary' : 'text-dark'
              }`}
            >
              {intl.formatMessage({id: 'LABEL_IS_DEFAULT'}).length < 9 ? (
                intl.formatMessage({id: 'LABEL_IS_DEFAULT'})
              ) : (
                <RowTooltipCellFormatter
                  text={intl.formatMessage({id: 'LABEL_IS_DEFAULT'})}
                  length={8}
                />
              )}
            </span>
          </HeaderCell>
          <Cell dataKey='is_default' align='center'>
            {(rowData: any) => <RowYesNoCellFormatter row={rowData.is_default} />}
          </Cell>
        </Column>

        {/* Is Multiple Price */}
        <Column verticalAlign='middle' width={100}>
          <HeaderCell align='center'>
            <span
              className={`fw-bold fs-6 text-decoration-underline ${
                sortColumn === 'is_multiple_price_sales_type' ? 'text-primary' : 'text-dark'
              }`}
            >
              {intl.formatMessage({id: 'LABEL_IS_MULTIPLE_SALES_TYPE'}).length < 9 ? (
                intl.formatMessage({id: 'LABEL_IS_MULTIPLE_SALES_TYPE'})
              ) : (
                <RowTooltipCellFormatter
                  text={intl.formatMessage({id: 'LABEL_IS_MULTIPLE_SALES_TYPE'})}
                  length={8}
                />
              )}
            </span>
          </HeaderCell>
          <Cell dataKey='is_multiple_price_sales_type' align='center'>
            {(rowData: any) => <RowYesNoCellFormatter row={rowData.is_multiple_price_sales_type} />}
          </Cell>
        </Column>

        {/* Is Lifetime */}
        <Column verticalAlign='middle' width={100}>
          <HeaderCell align='center'>
            <span
              className={`fw-bold fs-6 text-decoration-underline ${
                sortColumn === 'is_lifetime' ? 'text-primary' : 'text-dark'
              }`}
            >
              {intl.formatMessage({id: 'LABEL_IS_LIFETIME'})}
            </span>
          </HeaderCell>
          <Cell dataKey='is_lifetime' align='center'>
            {(rowData: any) => <RowNoYesCellFormatter row={rowData.is_lifetime} />}
          </Cell>
        </Column>

        {/* Status */}
        <Column verticalAlign='middle' width={100}>
          <HeaderCell align='center'>
            <span
              className={`fw-bold fs-6 text-decoration-underline ${
                sortColumn === 'status' ? 'text-primary' : 'text-dark'
              }`}
            >
              Status
            </span>
          </HeaderCell>
          <Cell dataKey='status' align='center'>
            {(rowData: any) => <RowStatusCellFormatter row={rowData.status} />}
          </Cell>
        </Column>

        {/* Action */}
        <Column fixed='right' align='right' verticalAlign='middle' width={50}>
          <HeaderCell>
            <span className={`fw-bold fs-6 text-dark text-decoration-underline`}>
              {intl.formatMessage({id: 'LABEL_ACTION'})}
            </span>
          </HeaderCell>
          <Cell dataKey='action' style={{flexWrap: 'nowrap'}}>
            {(rowData: any) => (
              <ListActionFormatter item_id={rowData.item_id} status={rowData.status} />
            )}
          </Cell>
        </Column>
      </Table>
    </div>
  )
}

export {ListTable}
