import {FC} from 'react'

import {ViewModalHeader as UserGroupAccessModals} from 'app/modules/general/settings/user-management/access/components'
import {ViewModalHeader as LogModals} from 'app/modules/general/settings/user-management/log/components'
import {ViewModalHeader as ModuleAccessModals} from 'app/modules/settings-all-module/access/components'
import {ViewModalHeader as LogPOSModals} from 'app/modules/backoffice-orins-pos/pos-settings/user-management/log/components'
import {ViewModalHeader as ESBSalesDataModals} from 'app/modules/backoffice-orins-pos/orins-pos/esb/sales/components'

type Props = {menuName: any; appMenuID: any; appParentMenuID: any}

const ListModalHelper: FC<Props> = ({menuName, appMenuID, appParentMenuID}) => {
  switch (appMenuID) {
    case '4':
      return <UserGroupAccessModals menuName={menuName} />
    case '5':
      return <LogModals menuName={menuName} />
    case '51':
      return <ModuleAccessModals menuName={menuName} />
    case '102':
      return <LogPOSModals menuName={menuName} />
    case '144':
      return <ESBSalesDataModals menuName={menuName} />
  }

  return <></>
}

export {ListModalHelper}
