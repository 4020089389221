import axios, {AxiosResponse} from 'axios'

import {APIResponse} from 'app/components'

import {ListQueryResponse} from './_list_models'
import {RoleID} from '.'

const API_APP_URL = process.env.REACT_APP_APP_API_URL
const X_API_KEY = process.env.REACT_APP_APP_API_KEY
const GET_USER_GROUPS_LIST_URL = `${API_APP_URL}/user-group?`
const GET_USER_GROUP_URL = `${API_APP_URL}/user-group/fetch-user-group?`
const ADD_UPDATE_USER_GROUP_URL = `${API_APP_URL}/user-group?`
const DELETE_USER_GROUP_URL = `${API_APP_URL}/user-group`

const getList = (query: any): Promise<ListQueryResponse> => {
  return axios
    .get(`${GET_USER_GROUPS_LIST_URL}${query}`, {
      headers: {'X-API-KEY': `${X_API_KEY}`},
    })
    .then((d: AxiosResponse<ListQueryResponse>) => d.data)
}

const getDataByID = (role_id: RoleID): Promise<APIResponse | undefined> => {
  return axios
    .get(`${GET_USER_GROUP_URL}role_id=${role_id}`, {
      headers: {'X-API-KEY': `${X_API_KEY}`},
    })
    .then((response: AxiosResponse<APIResponse>) => response.data)
}

const addData = (payload: any, current_user_role_id: any): Promise<APIResponse | undefined> => {
  return axios
    .post(
      `${ADD_UPDATE_USER_GROUP_URL}&role_id=${current_user_role_id}`,
      {payload},
      {
        headers: {'X-API-KEY': `${X_API_KEY}`},
      }
    )
    .then((response: AxiosResponse<APIResponse>) => response.data)
}

const updateData = (payload: any, current_user_role_id: any): Promise<APIResponse | undefined> => {
  return axios
    .put(
      `${ADD_UPDATE_USER_GROUP_URL}&role_id=${current_user_role_id}`,
      {payload},
      {
        headers: {'X-API-KEY': `${X_API_KEY}`},
      }
    )
    .then((response: AxiosResponse<APIResponse>) => response.data)
}

const deleteData = (
  menu_id: any,
  role_id: any,
  current_user_role_id: any
): Promise<APIResponse | undefined> => {
  return axios
    .delete(`${DELETE_USER_GROUP_URL}/${role_id}/${menu_id}?&role_id=${current_user_role_id}`, {
      headers: {'X-API-KEY': `${X_API_KEY}`},
    })
    .then((response: AxiosResponse<APIResponse>) => response.data)
}

export {getList, getDataByID, addData, updateData, deleteData}
