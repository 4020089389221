/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {Drawer} from 'rsuite'
import toast from 'react-hot-toast'

import {NotAuthRedirectForm, SpinnerIndicator, getFormAction} from 'app/components'
import {getAuth, removeAuth, useAuth} from 'app/modules/auth'
import {fetch} from 'app/modules/backoffice-orins-pos/pos-settings/mobile-layout/menu/helpers'

import {DetailModel, ListModel, initialData, updateData} from '../../helpers'
import {useAction, useListQueryResponse} from '../../providers'

type CheckBoxProps = {
  menuLabel: string
  name: string
  value: string
  currentValue: any
  currentPayload: any
  formik: any
  action: string
}

const setActionLabel = (menuLabel: string, name: string) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const intl = useIntl()

  switch (menuLabel.toLowerCase()) {
    case 'transactions':
      switch (name.toLowerCase()) {
        case 'view':
          return intl.formatMessage({id: 'BUTTON_LABEL_VIEW'})
        case 'add':
          return intl.formatMessage({id: 'LABEL_ADD_TRANSACTION'})
        case 'delete':
          return intl.formatMessage({id: 'LABEL_VOID_REFUND'})
      }
      break
    case 'shift':
      switch (name.toLowerCase()) {
        case 'view':
          return intl.formatMessage({id: 'BUTTON_LABEL_VIEW'})
        case 'add':
          return intl.formatMessage({id: 'LABEL_OPEN_SHIFT'})
        case 'edit':
          return intl.formatMessage({id: 'LABEL_CLOSE_SHIFT'})
      }
      break
    default:
      switch (name.toLowerCase()) {
        case 'view':
          return intl.formatMessage({id: 'BUTTON_LABEL_VIEW'})
        case 'add':
          return intl.formatMessage({id: 'BUTTON_LABEL_ADD'})
        case 'edit':
          return intl.formatMessage({id: 'BUTTON_LABEL_EDIT'})
        case 'delete':
          return intl.formatMessage({id: 'BUTTON_LABEL_DELETE'})
      }
      break
  }
}

const Checkbox: FC<CheckBoxProps> = ({
  menuLabel,
  name,
  value,
  currentValue,
  currentPayload,
  formik,
  action,
}) => {
  return (
    <div className='form-check form-check-custom form-check-solid'>
      <input
        type='checkbox'
        id={`${name}-${value}`}
        checked={currentValue.includes(value)}
        className={`form-check-input ${action !== 'view' && 'cursor-pointer'}`}
        onChange={() => {
          if (action !== 'view') {
            if (currentValue.includes(value)) {
              const nextValue = currentValue.filter((dt: any) => dt !== value)
              formik.setFieldValue(name, nextValue)
            } else {
              const nextValue = currentValue.concat(value)
              formik.setFieldValue(name, nextValue)
            }
          }
        }}
        disabled={action === 'view' ? true : false}
      />
      <label
        className={`form-check-label ${
          currentPayload.includes(value)
            ? currentPayload.includes(value) && !currentValue.includes(value) && 'text-info fw-bold'
            : !currentPayload.includes(value) && currentValue.includes(value) && 'text-info fw-bold'
        }`}
        htmlFor={`${name}-${value}`}
      >
        {setActionLabel(menuLabel, name)}
      </label>
    </div>
  )
}

type Props = {
  isLoading: boolean
  role: DetailModel
  appMenuID: any
  modalID: any
  menuName: any
}

const ViewDrawerForm: FC<Props> = ({menuName, role, isLoading, appMenuID, modalID}) => {
  const auth = getAuth()
  const {currentUser} = useAuth()
  const intl = useIntl()
  const {setItemIdForUpdate, action, itemNameForUpdate, itemCodeForUpdate} = useAction()
  const {refetch} = useListQueryResponse()
  const [loading, setLoading] = useState(false)
  const [isAuth, setIsAuth] = useState(true)
  const [completed, setCompleted] = useState(false)
  const didRequest = useRef(false)
  const [menus, setMenus] = useState<ListModel[] | undefined>([])

  const [models] = useState<DetailModel>({
    ...role,
    role_id: role.role_id || initialData.role_id,
    add: role.add || initialData.add,
    apv1: role.apv1 || initialData.apv1,
    apv2: role.apv2 || initialData.apv2,
    apv3: role.apv3 || initialData.apv3,
    delete: role.delete || initialData.delete,
    edit: role.edit || initialData.edit,
    view: role.view || initialData.view,
    menu_id: appMenuID,
    creator_id: currentUser?.user_id,
    creator_by: currentUser?.username,
  })

  const requestUpdateData = async (values: any, setStatus: any, setSubmitting: any) => {
    setLoading(true)
    setStatus(null)
    setSubmitting(true)

    // request update to API
    try {
      const res = await updateData(values)

      // throw message error
      if (res?.meta.code === 200) {
        setStatus(intl.formatMessage({id: 'RESPONSE_USER_GROUP_EDIT_SUCCESS'}))
        setCompleted(true)
        setTimeout(function () {
          setItemIdForUpdate(undefined)
        }, 1e3)
        refetch()
      }
    } catch (error: any) {
      console.error(error)
      if (error.response.status === 401) {
        setStatus(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
        removeAuth()
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
      }
      setSubmitting(false)
      setLoading(false)
      setTimeout(function () {
        setStatus(null)
      }, 9e3)

      return false
    } finally {
      setTimeout(() => {
        setLoading(false)
        setSubmitting(false)
      }, 5e2)
    }
  }
  const formik = useFormik({
    initialValues: models,
    onSubmit: async (values, {setSubmitting, setStatus}) => {
      requestUpdateData(values, setStatus, setSubmitting)
    },
    onReset: (values) => {
      values = models
    },
  })

  useEffect(() => {
    auth === undefined && setIsAuth(false)
  }, [auth])

  useEffect(() => {
    const requestLayoutMenuList = async () => {
      try {
        if (!didRequest.current) {
          const res = await fetch('?page=1&items_per_page=50&sort=for_order&order=asc')
          // throw message error
          if (res && res.meta?.code === 200) {
            // set option
            res.data && setMenus(res.data)
          }
        }
      } catch (error: any) {
        console.error(error)
        if (error.response.status === 401) {
          toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
          removeAuth()
        } else {
          toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
        }
      } finally {
      }

      return () => (didRequest.current = true)
    }

    requestLayoutMenuList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {!isAuth && <NotAuthRedirectForm />}

      <Drawer.Header>
        <Drawer.Title className='fw-bolder'>
          {isLoading ? (
            <SpinnerIndicator label={intl.formatMessage({id: 'BUTTON_LABEL_PLEASE_WAIT'})} />
          ) : (
            <>
              {getFormAction(
                action,
                intl.formatMessage({id: 'BUTTON_LABEL_VIEW'}),
                intl.formatMessage({id: 'BUTTON_LABEL_EDIT'}),
                intl.formatMessage({id: 'BUTTON_LABEL_ADD'}),
                intl.formatMessage({id: 'BUTTON_LABEL_DELETE'})
              )}{' '}
              {menuName}: {itemNameForUpdate}
              {' ('}
              {itemCodeForUpdate}
              {')'}
            </>
          )}
        </Drawer.Title>
        <Drawer.Actions>
          {action !== 'view' && models !== formik.values && !completed && (
            <button
              type='reset'
              className='btn btn-light me-3'
              data-kt-users-modal-action='reset'
              onClick={formik.handleReset}
              disabled={loading}
            >
              {!loading && (
                <span className='indicator-label'>
                  {intl.formatMessage({id: 'BUTTON_LABEL_RESET'})}
                </span>
              )}
            </button>
          )}

          {action !== 'view' && models !== formik.values && !completed && (
            <button
              type='submit'
              className='btn btn-primary'
              data-kt-users-modal-action='submit'
              onClick={() => formik.handleSubmit()}
              disabled={isLoading || loading || formik.isSubmitting || !formik.isValid}
            >
              {!loading && (
                <span className='indicator-label'>
                  {intl.formatMessage({id: 'BUTTON_LABEL_SUBMIT'})}
                </span>
              )}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {intl.formatMessage({id: 'BUTTON_LABEL_SUBMITTING'})}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          )}

          {action === 'delete' && !completed && (
            <button
              type='submit'
              className='btn btn-danger'
              data-kt-users-modal-action='submit'
              onClick={() => formik.handleSubmit()}
              disabled={isLoading || loading || formik.isSubmitting || !formik.isValid}
            >
              {!loading && (
                <span className='indicator-label'>
                  {intl.formatMessage({id: 'BUTTON_LABEL_DELETE'})}
                </span>
              )}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {intl.formatMessage({id: 'BUTTON_LABEL_SUBMITTING'})}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          )}
        </Drawer.Actions>
      </Drawer.Header>

      <Drawer.Body style={{padding: 0}}>
        {/* Response Message */}
        {formik.status &&
          (formik.status === intl.formatMessage({id: 'RESPONSE_USER_GROUP_EDIT_SUCCESS'}) ? (
            <div className='m-lg-5 p-2 bg-light-success rounded'>
              <div className='text-success text-center font-weight-bold fw-bolder'>
                {formik.status}
              </div>
            </div>
          ) : (
            <div className={`m-lg-5 p-2 alert alert-danger`}>
              <div className='alert-text text-center font-weight-bold fw-bolder'>
                {formik.status}
              </div>
            </div>
          ))}
        {/* Response Message */}

        {/* Form */}
        <form id={`${modalID}-form`} className='form' onSubmit={formik.handleSubmit} noValidate>
          <div
            className='d-flex flex-column scroll-y me-n2'
            id={`body-${modalID}`}
            data-kt-scroll='true'
            data-kt-scroll-activate='{default: false, lg: true}'
            data-kt-scroll-max-height='auto'
            data-kt-scroll-dependencies={`#${modalID}`}
            data-kt-scroll-wrappers={`#body-${modalID}`}
            data-kt-scroll-offset='300px'
          >
            <div className='card card-custom'>
              <div className='card-header card-header-stretch overflow-auto'>
                <ul
                  className='nav nav-stretch nav-line-tabs nav-line-tabs-2x fw-bold border-transparent flex-nowrap'
                  role='tablist'
                >
                  <li className='nav-item' key={`li-1`}>
                    <a className={clsx(`nav-link cursor-pointer text-primary active`)} role='tab'>
                      Orins POS Mobile Apps
                    </a>
                  </li>
                </ul>
              </div>

              <div className='card-body'>
                <table className='table table-rounded table-responsive table-hover border gy-4 gs-4'>
                  <tbody>
                    {menus &&
                      menus.map((menu: any) => (
                        <tr key={`tr-${menu.menu_id}`}>
                          <td
                            key={`td-${menu.menu_id}`}
                            style={menu.parent_id === '' ? undefined : {paddingLeft: 30}}
                          >
                            {menu.parent_id === '' ? (
                              <strong> {menu.name} </strong>
                            ) : (
                              <i>. {menu.name}</i>
                            )}
                          </td>
                          {menu.access !== '0000000' && (
                            <>
                              {String(menu.access).charAt(0) === '1' ? (
                                <td key={`view-${menu.menu_id}`} style={{textAlign: 'center'}}>
                                  <Checkbox
                                    menuLabel={menu.name}
                                    name='view'
                                    value={menu.menu_id}
                                    currentValue={formik.values.view}
                                    currentPayload={models.view}
                                    formik={formik}
                                    action={action}
                                  />
                                </td>
                              ) : (
                                <td />
                              )}
                              {String(menu.access).charAt(1) === '1' ? (
                                <td key={`add-${menu.menu_id}`} style={{textAlign: 'center'}}>
                                  <Checkbox
                                    menuLabel={menu.name}
                                    name='add'
                                    value={menu.menu_id}
                                    currentValue={formik.values.add}
                                    currentPayload={models.add}
                                    formik={formik}
                                    action={action}
                                  />
                                </td>
                              ) : (
                                <td />
                              )}
                              {String(menu.access).charAt(2) === '1' ? (
                                <td key={`edit-${menu.menu_id}`} style={{textAlign: 'center'}}>
                                  <Checkbox
                                    menuLabel={menu.name}
                                    name='edit'
                                    value={menu.menu_id}
                                    currentValue={formik.values.edit}
                                    currentPayload={models.edit}
                                    formik={formik}
                                    action={action}
                                  />
                                </td>
                              ) : (
                                <td />
                              )}
                              {String(menu.access).charAt(3) === '1' ? (
                                <td key={`delete-${menu.menu_id}`} style={{textAlign: 'center'}}>
                                  <Checkbox
                                    menuLabel={menu.name}
                                    name='delete'
                                    value={menu.menu_id}
                                    currentValue={formik.values.delete}
                                    currentPayload={models.delete}
                                    formik={formik}
                                    action={action}
                                  />
                                </td>
                              ) : (
                                <td />
                              )}
                              {String(menu.access).charAt(4) === '1' ? (
                                <td key={`apv1-${menu.menu_id}`} style={{textAlign: 'center'}}>
                                  <Checkbox
                                    menuLabel={menu.name}
                                    name='apv1'
                                    value={menu.menu_id}
                                    currentValue={formik.values.apv1}
                                    currentPayload={models.apv1}
                                    formik={formik}
                                    action={action}
                                  />
                                </td>
                              ) : (
                                <td />
                              )}
                              {String(menu.access).charAt(5) === '1' ? (
                                <td key={`apv2-${menu.menu_id}`} style={{textAlign: 'center'}}>
                                  <Checkbox
                                    menuLabel={menu.name}
                                    name='apv2'
                                    value={menu.menu_id}
                                    currentValue={formik.values.apv2}
                                    currentPayload={models.apv2}
                                    formik={formik}
                                    action={action}
                                  />
                                </td>
                              ) : (
                                <td />
                              )}
                              {String(menu.access).charAt(6) === '1' ? (
                                <td key={`apv3-${menu.menu_id}`} style={{textAlign: 'center'}}>
                                  <Checkbox
                                    menuLabel={menu.name}
                                    name='apv3'
                                    value={menu.menu_id}
                                    currentValue={formik.values.apv3}
                                    currentPayload={models.apv3}
                                    formik={formik}
                                    action={action}
                                  />
                                </td>
                              ) : (
                                <td />
                              )}
                            </>
                          )}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </form>
        {/* Form */}
      </Drawer.Body>
    </>
  )
}

export {ViewDrawerForm}
