import {Dispatch, SetStateAction} from 'react'

export type ActionContextProps = {
  showFilter: boolean
  setShowFilter: Dispatch<SetStateAction<boolean>>
}

export const initialAction: ActionContextProps = {
  showFilter: false,
  setShowFilter: () => {},
}
