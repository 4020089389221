import {initialListQueryState, ListQueryState} from 'app/modules/settings-all-module/menu/helpers'

const MENU_LIST_STATE_KEY = 'backoffice-orins-pos/settings/conditional/menu'

const getMenuListState = () => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null | undefined = localStorage.getItem(MENU_LIST_STATE_KEY)
  if (!lsValue) {
    return setMenuListState(initialListQueryState)
  }

  try {
    const state = JSON.parse(lsValue)
    if (state) {
      return state
    }
  } catch (error) {
    console.log(MENU_LIST_STATE_KEY + ' STATE LOCAL STORAGE PARSE ERROR', error)
  }
}

const setMenuListState = (state: ListQueryState) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(state)
    localStorage.setItem(MENU_LIST_STATE_KEY, lsValue)
  } catch (error) {
    console.error(MENU_LIST_STATE_KEY + ' STATE LOCAL STORAGE SAVE ERROR', error)
  }
}

export {getMenuListState, setMenuListState, MENU_LIST_STATE_KEY}
