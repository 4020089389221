import {FC} from 'react'
import {Tooltip, Whisper} from 'rsuite'

import {truncate} from 'app/components'

type Props = {
  text?: string | undefined
  length?: number
}

const RowTooltipCellFormatter: FC<Props> = ({text, length}) => {
  return (
    <Whisper
      placement='top'
      controlId='control-id-hover'
      trigger='hover'
      speaker={<Tooltip id='tooltip-cell'>{text}</Tooltip>}
    >
      <span>{truncate(text && text.replaceAll(';', ','), length)}</span>
    </Whisper>
  )
}

export {RowTooltipCellFormatter}
