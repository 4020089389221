import axios, {AxiosResponse} from 'axios'

import {ListQueryResponse} from '.'

const ESB_API_URL = `${process.env.REACT_APP_ESB_API_URL}/esb-sales`
const MOBILE_X_API_KEY = process.env.REACT_APP_MOBILE_APP_API_KEY

const fetch = (query: any): Promise<ListQueryResponse> => {
  return axios
    .get(`${ESB_API_URL}/list?${query}`, {
      headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
    })
    .then((response: AxiosResponse<ListQueryResponse>) => response.data)
}

export {fetch}
