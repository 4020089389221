import {FC, useState} from 'react'
import toast from 'react-hot-toast'
import {useQuery} from 'react-query'
import {Drawer} from 'rsuite'

import {QUERIES, isNotEmpty} from '_metronic/helpers'
import {removeAuth} from 'app/modules/auth'

import {useAction} from '../../providers'
import {get} from '../../helpers'
import {ViewDrawerForm} from '..'

type Props = {
  menuName?: any
  modalID?: any
  appMenuID: any
}

const ViewDrawerFormWrapper: FC<Props> = ({menuName, modalID, appMenuID}) => {
  const [open, setOpen] = useState(true)
  const {action, isLoading, itemIdForUpdate, setItemIdForUpdate, setIsLoading} = useAction()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {data: outlet, error} = useQuery(
    [`${QUERIES.POS_SETTINGS_GET_OUTLET}-${itemIdForUpdate}`],
    () => {
      setIsLoading(true)
      return get(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      refetchOnWindowFocus: false,
      onError: (err: any) => {
        if (err.response.status !== 200) {
          setIsLoading(false)
          if (err.response.status === 401) {
            toast.error(err.response.statusText)
            removeAuth()
          } else {
            toast.error(err.response.statusText)
          }
        }
      },
      onSuccess: () => {
        setIsLoading(false)
      },
    }
  )

  return (
    <Drawer
      open={open}
      onClose={() => {
        setOpen(false)
        setItemIdForUpdate(undefined)
      }}
      placement='right'
      backdrop='static'
      keyboard={action === 'view' ? true : false}
      style={{width: '100%'}}
    >
      {outlet === undefined && (
        <ViewDrawerForm
          menuName={menuName}
          isLoading={isLoading}
          outlet={{
            outlet_id: undefined,
            status: 't',
            name: '',
            phone_number: '',
            postal_code: '',
            address: '',
          }}
          appMenuID={appMenuID}
          modalID={modalID}
        />
      )}
      {!error && outlet && (
        <ViewDrawerForm
          menuName={menuName}
          isLoading={isLoading}
          outlet={outlet.data}
          appMenuID={appMenuID}
          modalID={modalID}
        />
      )}
    </Drawer>
  )
}

export {ViewDrawerFormWrapper}
