import {FC} from 'react'
import {useIntl} from 'react-intl'
import {Tooltip, Whisper} from 'rsuite'

import {KTIcon} from '_metronic/helpers'

type Props = {
  isLoading: any
  state: any
  showFilter?: any
  setShowFilter?: any
}

const FilterButton: FC<Props> = ({isLoading, state, showFilter, setShowFilter}) => {
  const intl = useIntl()

  return (
    <Whisper
      placement='top'
      controlId='control-id-hover'
      trigger='hover'
      speaker={
        <Tooltip id='tooltip-shortcut-menu'>{intl.formatMessage({id: 'LABEL_FILTER'})}</Tooltip>
      }
    >
      <button
        disabled={isLoading}
        type='button'
        className={`btn btn-icon btn-${
          state?.filter !== undefined ? 'light-primary' : 'bg-light'
        } hover-scale me-4`}
        onClick={() => setShowFilter(!showFilter)}
      >
        <KTIcon iconName='filter' className='fs-2x' />
      </button>
    </Whisper>
  )
}

export {FilterButton}
