import axios, {AxiosResponse} from 'axios'

import {APIResponse} from 'app/components'

import {ListQueryResponse, OutletID} from '.'

const HOLDING_API_URL = `${process.env.REACT_APP_MOBILE_APP_API_URL}/holding`
const COMPANY_API_URL = `${process.env.REACT_APP_MOBILE_APP_API_URL}/company`
const BRAND_API_URL = `${process.env.REACT_APP_MOBILE_APP_API_URL}/brand`
const PROVINCE_API_URL = `${process.env.REACT_APP_MOBILE_APP_API_URL}/province`
const CITY_API_URL = `${process.env.REACT_APP_MOBILE_APP_API_URL}/city`
const DISTRICT_API_URL = `${process.env.REACT_APP_MOBILE_APP_API_URL}/district`
const VILLAGE_API_URL = `${process.env.REACT_APP_MOBILE_APP_API_URL}/village`
const AREA_API_URL = `${process.env.REACT_APP_MOBILE_APP_API_URL}/area`
const CLUSTER_API_URL = `${process.env.REACT_APP_MOBILE_APP_API_URL}/cluster`
const OUTLET_API_URL = `${process.env.REACT_APP_MOBILE_APP_API_URL}/outlet`
const MOBILE_X_API_KEY = process.env.REACT_APP_MOBILE_APP_API_KEY

const fetch = (query: any): Promise<ListQueryResponse> => {
  return axios
    .get(`${OUTLET_API_URL}/list?${query}`, {
      headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
    })
    .then((response: AxiosResponse<ListQueryResponse>) => response.data)
}

const fetchHoldingOptions = (mode: any) => {
  return axios.get(`${HOLDING_API_URL}/list-options/${mode}`, {
    headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
  })
}

const fetchCompanyOptions = (mode: any, holding_id: string | undefined) => {
  return axios.get(`${COMPANY_API_URL}/list-options/${mode}/${holding_id}`, {
    headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
  })
}

const fetchBrandOptions = (mode: any, company_id: string | undefined) => {
  return axios.get(`${BRAND_API_URL}/list-options/${mode}/${company_id}`, {
    headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
  })
}

const fetchProvinceOptions = (mode: any) => {
  return axios.get(`${PROVINCE_API_URL}/list-options/${mode}`, {
    headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
  })
}

const fetchCityOptions = (mode: any, province_id: string | undefined) => {
  return axios.get(`${CITY_API_URL}/list-options/${mode}/${province_id}`, {
    headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
  })
}

const fetchDistrictOptions = (mode: any, city_id: string | undefined) => {
  return axios.get(`${DISTRICT_API_URL}/list-options/${mode}/${city_id}`, {
    headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
  })
}

const fetchVillageOptions = (mode: any, district_id: string | undefined) => {
  return axios.get(`${VILLAGE_API_URL}/list-options/${mode}/${district_id}`, {
    headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
  })
}

const fetchAreaOptions = (mode: any) => {
  return axios.get(`${AREA_API_URL}/list-options/${mode}`, {
    headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
  })
}

const fetchClusterOptions = (mode: any, area_id: string | undefined) => {
  return axios.get(`${CLUSTER_API_URL}/list-options/${mode}/${area_id}`, {
    headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
  })
}

const get = (outlet_id: OutletID): Promise<APIResponse | undefined> => {
  return axios
    .get(`${OUTLET_API_URL}/${outlet_id}/detail`, {
      headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
    })
    .then((response: AxiosResponse<APIResponse>) => response.data)
}

const addData = (payload: any): Promise<APIResponse | undefined> => {
  return axios
    .post(
      `${OUTLET_API_URL}`,
      {payload},
      {
        headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
      }
    )
    .then((response: AxiosResponse<APIResponse>) => response.data)
}

const updateData = (payload: any): Promise<APIResponse | undefined> => {
  return axios
    .put(
      `${OUTLET_API_URL}/${payload.outlet_id}/update`,
      {payload},
      {
        headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
      }
    )
    .then((response: AxiosResponse<APIResponse>) => response.data)
}

const deleteData = (
  outlet_id: any,
  menu_id: any,
  creator_id: any,
  creator_by: any
): Promise<APIResponse | undefined> => {
  return axios
    .delete(`${OUTLET_API_URL}/${outlet_id}/${menu_id}/${creator_id}/${creator_by}/delete`, {
      headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
    })
    .then((response: AxiosResponse<APIResponse>) => response.data)
}

export {
  fetch,
  fetchHoldingOptions,
  fetchCompanyOptions,
  fetchBrandOptions,
  fetchProvinceOptions,
  fetchCityOptions,
  fetchDistrictOptions,
  fetchVillageOptions,
  fetchAreaOptions,
  fetchClusterOptions,
  get,
  updateData,
  addData,
  deleteData,
}
