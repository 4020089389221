import axios, {AxiosResponse} from 'axios'

import {APIResponse} from 'app/components'

import {ListQueryResponse, UserID} from '.'

const API_USER_URL = process.env.REACT_APP_USER_API_URL
const API_APP_URL = process.env.REACT_APP_APP_API_URL
const X_API_KEY = process.env.REACT_APP_APP_API_KEY
const GET_USERS_LIST_URL = `${API_USER_URL}/user?`
const GET_USER_URL = `${API_USER_URL}/user/fetch-user?`
const GET_USER_ROLES_FOR_INPUT_URL = `${API_APP_URL}/app/master-data/user-groups-by-role-id/`
const ADD_UPDATE_USER_URL = `${API_USER_URL}/user?`
const DELETE_USER_URL = `${API_USER_URL}/user`

const getList = (query: any): Promise<ListQueryResponse> => {
  return axios
    .get(`${GET_USERS_LIST_URL}${query}`)
    .then((d: AxiosResponse<ListQueryResponse>) => d.data)
}

const getDataByID = (user_id: UserID): Promise<APIResponse | undefined> => {
  return axios
    .get(`${GET_USER_URL}user_id=${user_id}`)
    .then((response: AxiosResponse<APIResponse>) => response.data)
}

const getUserRolesByRoleID = (used_for: string, role_id: UserID) => {
  return axios.get(`${GET_USER_ROLES_FOR_INPUT_URL}${used_for}/${role_id}`, {
    headers: {'X-API-KEY': `${X_API_KEY}`},
  })
}

const updateData = (payload: any, role_id: any): Promise<APIResponse | undefined> => {
  return axios
    .put(`${ADD_UPDATE_USER_URL}&role_id=${role_id}`, {payload})
    .then((response: AxiosResponse<APIResponse>) => response.data)
}

const addData = (payload: any, role_id: any): Promise<APIResponse | undefined> => {
  return axios
    .post(`${ADD_UPDATE_USER_URL}&role_id=${role_id}`, {payload})
    .then((response: AxiosResponse<APIResponse>) => response.data)
}

const deleteData = (user_id: any, menu_id: any, role_id: any): Promise<APIResponse | undefined> => {
  return axios
    .delete(`${DELETE_USER_URL}/${user_id}/${menu_id}?role_id=${role_id}`)
    .then((response: AxiosResponse<APIResponse>) => response.data)
}

export {getList, getDataByID, getUserRolesByRoleID, updateData, addData, deleteData}
