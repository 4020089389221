import {FC} from 'react'
import {useIntl} from 'react-intl'
import {Tooltip, Whisper} from 'rsuite'

import {KTIcon} from '_metronic/helpers'

type Props = {
  menuName: any
  useAction: any
}

const ToolbarAction: FC<Props> = ({menuName, useAction}) => {
  const intl = useIntl()
  const {setAction, setItemIdForUpdate, currentAccessMenu} = useAction()

  const openModal = (action: any) => {
    setAction(action)
    setItemIdForUpdate(null)
  }

  return (
    <>
      {currentAccessMenu?.add?.access_role === 'add' && (
        <Whisper
          placement='top'
          controlId='control-id-hover'
          trigger='hover'
          speaker={
            <Tooltip id='tooltip-shortcut-menu'>
              {intl.formatMessage({id: 'BUTTON_LABEL_ADD'})}
            </Tooltip>
          }
        >
          <button
            type='button'
            className='btn btn-icon btn-light-primary hover-scale'
            onClick={() => openModal('add')}
          >
            <KTIcon iconName='add-notepad' className='fs-2x' />
          </button>
        </Whisper>
      )}
    </>
  )
}

export {ToolbarAction}
