import axios, {AxiosResponse} from 'axios'

import {SiteID} from '.'
import {ListQueryResponse} from './_list_models'
import {APIResponse} from 'app/components'

const API_APP_URL = process.env.REACT_APP_APP_API_URL
const X_API_KEY = process.env.REACT_APP_APP_API_KEY
const GET_SITE_LIST_URL = `${API_APP_URL}/site?`
const GET_SITE_URL = `${API_APP_URL}/site/fetch-site?`
const ADD_UPDATE_SITE_URL = `${API_APP_URL}/site?`
const DELETE_SITE_URL = `${API_APP_URL}/site`

const getList = (query: any): Promise<ListQueryResponse> => {
  return axios
    .get(`${GET_SITE_LIST_URL}${query}`, {
      headers: {'X-API-KEY': `${X_API_KEY}`},
    })
    .then((d: AxiosResponse<ListQueryResponse>) => d.data)
}

const getDataByID = (site_id: SiteID): Promise<APIResponse | undefined> => {
  return axios
    .get(`${GET_SITE_URL}site_id=${site_id}`, {
      headers: {'X-API-KEY': `${X_API_KEY}`},
    })
    .then((response: AxiosResponse<APIResponse>) => response.data)
}

const addData = (payload: any): Promise<APIResponse | undefined> => {
  return axios
    .post(
      `${ADD_UPDATE_SITE_URL}`,
      {payload},
      {
        headers: {'X-API-KEY': `${X_API_KEY}`},
      }
    )
    .then((response: AxiosResponse<APIResponse>) => response.data)
}

const updateData = (payload: any): Promise<APIResponse | undefined> => {
  return axios
    .put(
      `${ADD_UPDATE_SITE_URL}`,
      {payload},
      {
        headers: {'X-API-KEY': `${X_API_KEY}`},
      }
    )
    .then((response: AxiosResponse<APIResponse>) => response.data)
}

const deleteData = (site_id: any, menu_id: any): Promise<APIResponse | undefined> => {
  return axios
    .delete(`${DELETE_SITE_URL}/${site_id}/${menu_id}`, {
      headers: {'X-API-KEY': `${X_API_KEY}`},
    })
    .then((response: AxiosResponse<APIResponse>) => response.data)
}

export {getList, getDataByID, addData, updateData, deleteData}
