import axios, {AxiosResponse} from 'axios'

import {APIResponse} from 'app/components'

import {ListQueryResponse, ReceiptID} from '.'

const HOLDING_API_URL = `${process.env.REACT_APP_MOBILE_APP_API_URL}/holding`
const COMPANY_API_URL = `${process.env.REACT_APP_MOBILE_APP_API_URL}/company`
const BRAND_API_URL = `${process.env.REACT_APP_MOBILE_APP_API_URL}/brand`
const SOCIAL_MEDIA_API_URL = `${process.env.REACT_APP_MOBILE_LIBRARY_API_URL}/social-media`
const RECEIPT_API_URL = `${process.env.REACT_APP_MOBILE_LIBRARY_API_URL}/receipt`
const MOBILE_X_API_KEY = process.env.REACT_APP_MOBILE_APP_API_KEY

const fetch = (query: any): Promise<ListQueryResponse> => {
  return axios
    .get(`${RECEIPT_API_URL}/list?${query}`, {
      headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
    })
    .then((response: AxiosResponse<ListQueryResponse>) => response.data)
}

const fetchHoldingOptions = (mode: any) => {
  return axios.get(`${HOLDING_API_URL}/list-options/${mode}`, {
    headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
  })
}

const fetchCompanyOptions = (mode: any, holding_id: string | undefined) => {
  return axios.get(`${COMPANY_API_URL}/list-options/${mode}/${holding_id}`, {
    headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
  })
}

const fetchBrandOptions = (mode: any, company_id: string | undefined) => {
  return axios.get(`${BRAND_API_URL}/list-options/${mode}/${company_id}`, {
    headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
  })
}

const fetchSocialMediaOptions = (mode: any) => {
  return axios.get(`${SOCIAL_MEDIA_API_URL}/list-options/${mode}`, {
    headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
  })
}

const get = (receipt_id: ReceiptID): Promise<APIResponse | undefined> => {
  return axios
    .get(`${RECEIPT_API_URL}/${receipt_id}/detail`, {
      headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
    })
    .then((response: AxiosResponse<APIResponse>) => response.data)
}

const addData = (payload: any): Promise<APIResponse | undefined> => {
  return axios
    .post(
      `${RECEIPT_API_URL}`,
      {payload},
      {
        headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
      }
    )
    .then((response: AxiosResponse<APIResponse>) => response.data)
}

const updateData = (payload: any): Promise<APIResponse | undefined> => {
  return axios
    .put(
      `${RECEIPT_API_URL}/${payload.receipt_id}/update`,
      {payload},
      {
        headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
      }
    )
    .then((response: AxiosResponse<APIResponse>) => response.data)
}

export {
  fetch,
  fetchHoldingOptions,
  fetchCompanyOptions,
  fetchBrandOptions,
  fetchSocialMediaOptions,
  get,
  updateData,
  addData,
}
