import {ItemID, ListResponse} from '.'

export type ListModel = {
  item_id?: string
  name?: string
  holding_slug?: string
  brand_slug?: string
  status?: string
}

export type ListQueryResponse = ListResponse<Array<ListModel>>

export const initialList: ListModel = {
  item_id: '',
  name: '',
  holding_slug: '',
  brand_slug: '',
  status: '',
}

export type HoldingModel = {
  holding_id: string
  name: string
}
export type CompanyModel = {
  company_id: string
  name: string
}
export type BrandModel = {
  brand_id: string
  name: string
}
export type OutletModel = {
  outlet_id: string
  name: string
}
export type SalesTypePriceModel = {
  sales_type_id: string
  variant_id: string
  is_include?: string
  price: number
  ppn: number
  ppn_price: number
  final_price: number
  name: string
  sku: string
}
export type VariantItems = {
  variant_id: string
  name: string
  sku: string
}
export type SalesTypeItemsModel = {
  sales_type_id: string
  is_include?: string
  name: string
  sales_type_price: SalesTypePriceModel[]
}
export type IsDefaultParentCategory = {
  category_id: string
  name: string
}

export type DetailModel = {
  item_id?: ItemID
  category_id?: any
  category_name?: string
  holding_id?: any // modified payload for crud
  holding_name?: any // modified payload for crud
  company_id?: any // modified payload for crud
  company_name?: any // modified payload for crud
  brand_id?: any // modified payload for crud
  brand_name?: any // modified payload for crud
  outlet_id?: any // modified payload for crud
  outlet_name?: any // modified payload for crud
  holding?: HoldingModel
  company?: CompanyModel
  brand?: BrandModel
  outlet?: OutletModel[]
  sales_type_price?: SalesTypePriceModel[]
  sales_type_items?: SalesTypeItemsModel[]
  variant_items?: VariantItems[]
  tmp_variant_items?: VariantItems[]
  is_default_parent_category?: IsDefaultParentCategory
  name?: string
  status?: string
  is_default?: string
  has_variant?: string
  for_order?: number
  description?: string
  is_multiple_price_sales_type?: string
  price?: number
  ppn?: number
  ppn_price?: number
  final_price?: number
  sku?: string
  is_lifetime?: string
  start_period?: string
  end_period?: string
  start_hour?: string
  end_hour?: string
  days?: number
  created_at?: string
  created_by?: string
  updated_at?: string
  updated_by?: string
  menu_id?: string // modified payload for crud
  creator_id?: string // modified payload for crud
  creator_by?: string // modified payload for crud
}

export const initialData: DetailModel = {
  item_id: '',
  category_id: '',
  category_name: '',
  holding_id: '', // modified payload for crud
  holding_name: '', // modified payload for crud
  company_id: '', // modified payload for crud
  company_name: '', // modified payload for crud
  brand_id: '', // modified payload for crud
  brand_name: '', // modified payload for crud
  holding: {
    holding_id: '',
    name: '',
  },
  company: {
    company_id: '',
    name: '',
  },
  brand: {
    brand_id: '',
    name: '',
  },
  outlet: [
    {
      outlet_id: '',
      name: '',
    },
  ],
  sales_type_price: [
    {
      sales_type_id: '',
      variant_id: '',
      is_include: 't',
      price: 0.0,
      ppn: 11,
      ppn_price: 0.0,
      final_price: 0.0,
      sku: '',
      name: '',
    },
  ],
  sales_type_items: [
    {
      sales_type_id: '',
      is_include: 't',
      name: '',
      sales_type_price: [
        {
          sales_type_id: '',
          variant_id: '',
          price: 0.0,
          ppn: 11,
          ppn_price: 0.0,
          final_price: 0.0,
          sku: '',
          name: '',
        },
      ],
    },
  ],
  variant_items: [
    {
      variant_id: '',
      name: '',
      sku: '',
    },
  ],
  tmp_variant_items: [
    {
      variant_id: '',
      name: '',
      sku: '',
    },
  ],
  is_default_parent_category: {
    category_id: '',
    name: '',
  },
  name: '',
  is_multiple_price_sales_type: '',
  price: 0,
  ppn: 0,
  ppn_price: 0,
  final_price: 0,
  sku: '',
  is_lifetime: '',
  start_period: '',
  end_period: '',
  start_hour: '',
  end_hour: '',
  days: 1111111,
  is_default: '',
  has_variant: '',
  status: '',
  for_order: 0,
  description: '',
  created_at: '',
  created_by: '',
  updated_at: '',
  updated_by: '',
  menu_id: '', // modified payload for crud
  creator_id: '', // modified payload for crud
  creator_by: '', // modified payload for crud
}
