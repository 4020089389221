import {initialListQueryState, ListQueryState} from 'app/modules/settings-all-module/access/helpers'

const DATA_LIST_STATE_KEY = 'backoffice-hrd/settings/datas'

const getDataListState = () => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null | undefined = localStorage.getItem(DATA_LIST_STATE_KEY)
  if (!lsValue) {
    return setDataListState(initialListQueryState)
  }

  try {
    const state = JSON.parse(lsValue)
    if (state) {
      return state
    }
  } catch (error) {
    console.log(DATA_LIST_STATE_KEY + ' STATE LOCAL STORAGE PARSE ERROR', error)
  }
}

const setDataListState = (state: ListQueryState) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(state)
    localStorage.setItem(DATA_LIST_STATE_KEY, lsValue)
  } catch (error) {
    console.error(DATA_LIST_STATE_KEY + ' STATE LOCAL STORAGE SAVE ERROR', error)
  }
}

export {getDataListState, setDataListState, DATA_LIST_STATE_KEY}
