import {createContext, FC, useContext, useState} from 'react'

import {WithChildren} from '_metronic/helpers'
import {CurrentMenuAccessModel} from 'app/context/models'

import {ActionContextProps, initialAction, UserID} from '../helpers'

const Action = createContext<ActionContextProps>(initialAction)

const ActionProvider: FC<WithChildren> = ({children}) => {
  const [itemIdForUpdate, setItemIdForUpdate] = useState<UserID>(initialAction.itemIdForUpdate)
  const [currentAccessMenu, setCurrentAccessMenu] = useState<CurrentMenuAccessModel | undefined>(
    undefined
  )
  const [isLoading, setIsLoading] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const [action, setAction] = useState('')
  const [userRoles, setUserRoles] = useState(undefined)

  return (
    <Action.Provider
      value={{
        currentAccessMenu,
        setCurrentAccessMenu,
        isLoading,
        setIsLoading,
        showFilter,
        setShowFilter,
        action,
        setAction,
        userRoles,
        setUserRoles,
        itemIdForUpdate,
        setItemIdForUpdate,
      }}
    >
      {children}
    </Action.Provider>
  )
}

const useAction = () => useContext(Action)

export {ActionProvider, useAction}
