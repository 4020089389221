import {createContext, FC, useContext, useState} from 'react'

import {WithChildren} from '_metronic/helpers'

import * as backOfficeOrinsPOSListStateContext from 'app/pages/settings-all-module/page-provider/backoffice-orins-pos/state-context'

import {initialListQueryRequest, ListQueryRequestContextProps, ListQueryState} from '../helpers'

const ListQueryRequestContext = createContext<ListQueryRequestContextProps>(initialListQueryRequest)

const ListQueryRequestProvider: FC<WithChildren> = ({children}) => {
  const [state, setState] = useState<ListQueryState>(
    backOfficeOrinsPOSListStateContext.getAccessListState()
  )

  const updateState = (updates: Partial<ListQueryState>) => {
    const updatedState = {...state, ...updates} as ListQueryState
    setState(updatedState)
    backOfficeOrinsPOSListStateContext.setAccessListState(updatedState)
  }

  return (
    <ListQueryRequestContext.Provider value={{state, updateState}}>
      {children}
    </ListQueryRequestContext.Provider>
  )
}

const useListQueryRequest = () => useContext(ListQueryRequestContext)

export {ListQueryRequestProvider, useListQueryRequest}
