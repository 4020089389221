import {ClusterID, ListResponse} from '.'

export type ListModel = {
  cluster_id?: string
  name?: string
  status?: string
  area?: string
}

export type ListQueryResponse = ListResponse<Array<ListModel>>

export const initialList: ListModel = {
  cluster_id: '',
  name: '',
  status: '',
  area: '',
}

export type areaModel = {
  area_id: string
  name: string
}

export type DetailModel = {
  cluster_id?: ClusterID
  area_id?: string
  area_name?: string
  name?: string
  status?: string
  area?: areaModel
  created_at?: string
  created_by?: string
  updated_at?: string
  updated_by?: string
  menu_id?: string // modified payload for crud
  creator_id?: string // modified payload for crud
  creator_by?: string // modified payload for crud
}

export const initialData: DetailModel = {
  cluster_id: '',
  area_id: '',
  area_name: '',
  name: '',
  status: '',
  area: {
    area_id: '',
    name: '',
  },
  created_at: '',
  created_by: '',
  updated_at: '',
  updated_by: '',
  menu_id: '', // modified payload for crud
  creator_id: '', // modified payload for crud
  creator_by: '', // modified payload for crud
}
