import {FC, useState} from 'react'
import toast from 'react-hot-toast'
import {useQuery} from 'react-query'
import {Drawer} from 'rsuite'
import moment from 'moment'

import {QUERIES, isNotEmpty} from '_metronic/helpers'
import {removeAuth} from 'app/modules/auth'

import {useAction, useListQueryRequest} from '../../providers'
import {get} from '../../helpers'
import {ViewDrawerForm} from '..'
import {isMobileDevice} from '_metronic/assets/ts/_utils'

type Props = {
  menuName?: any
  modalID?: any
  appMenuID: any
}

const ViewDrawerFormWrapper: FC<Props> = ({menuName, modalID, appMenuID}) => {
  const isMobile = isMobileDevice()
  const [open, setOpen] = useState(true)
  const {itemIdForUpdate, setItemIdForUpdate} = useAction()
  const {state} = useListQueryRequest()
  const [date] = useState(
    state?.date === null ? moment.utc(moment()).format('YYYY-MM-DD') : state?.date
  )
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    data: reportShift,
    error,
    isLoading,
  } = useQuery(
    [`${QUERIES.POS_REPORT_GET_SHIFT}-${itemIdForUpdate}`],
    () => {
      return get(itemIdForUpdate, date)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      refetchOnWindowFocus: false,
      onError: (err: any) => {
        if (err.response.status !== 200) {
          if (err.response.status === 401) {
            toast.error(err.response.statusText)
            removeAuth()
          } else {
            toast.error(err.response.statusText)
          }
        }
      },
      onSuccess: () => {},
    }
  )

  return (
    <Drawer
      open={open}
      onClose={() => {
        setOpen(false)
        setItemIdForUpdate(undefined)
      }}
      placement='right'
      backdrop={true}
      keyboard={true}
      style={{width: isMobile ? '100%' : '50%'}}
    >
      {reportShift === undefined && (
        <ViewDrawerForm
          menuName={menuName}
          isLoading={isLoading}
          reportShift={undefined}
          appMenuID={appMenuID}
          modalID={modalID}
        />
      )}
      {!error && reportShift && (
        <ViewDrawerForm
          menuName={menuName}
          isLoading={isLoading}
          reportShift={reportShift.data}
          appMenuID={appMenuID}
          modalID={modalID}
        />
      )}
    </Drawer>
  )
}

export {ViewDrawerFormWrapper}
