/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {Dialog} from '@headlessui/react'
import clsx from 'clsx'
import toast from 'react-hot-toast'
import {CheckPicker, Checkbox, Drawer, InputPicker, Toggle} from 'rsuite'
import * as CurrencyFormat from 'react-currency-format'

import {NotAuthRedirectForm, FormLog, getFormAction, SpinnerIndicator} from 'app/components'
import {getAuth, removeAuth, useAuth} from 'app/modules/auth'

import {
  DetailModel,
  addData,
  deleteData,
  fetchBrandOptions,
  fetchCompanyOptions,
  fetchHoldingOptions,
  fetchOutletOptions,
  initialData,
  updateData,
} from '../../helpers'
import {useAction, useListQueryResponse} from '../../providers'

const footerStyles = {
  padding: '0px 2px',
  borderTop: '1px solid #e5e5e5',
}

type Props = {
  isLoading: boolean
  discounts: DetailModel
  appMenuID: any
  modalID: any
  menuName: any
}

const ViewDrawerForm: FC<Props> = ({menuName, discounts, isLoading, appMenuID, modalID}) => {
  const auth = getAuth()
  const intl = useIntl()
  const [tab, setTab] = useState('data')
  const {currentUser} = useAuth()
  const {setItemIdForUpdate, action} = useAction()
  const didRequestHolding = useRef(false)
  const didRequestCompany = useRef(false)
  const didRequestBrand = useRef(false)
  const didRequestOutlet = useRef(false)
  const {refetch} = useListQueryResponse()
  const [isAuth, setIsAuth] = useState(true)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [completed, setCompleted] = useState(false)
  const [holdingOptions, setHoldingOptions] = useState(undefined)
  const [companyOptions, setCompanyOptions] = useState(undefined)
  const [brandOptions, setBrandOptions] = useState(undefined)
  const [outletOptions, setOutletOptions] = useState(undefined)
  const optionsHolding =
    holdingOptions === undefined
      ? []
      : (holdingOptions as unknown as any[]).map((holdings: any) => ({
          value: holdings.holding_id,
          label: holdings.name,
        }))
  const optionsCompany =
    companyOptions === undefined
      ? []
      : (companyOptions as unknown as any[]).map((company: any) => ({
          value: company.company_id,
          label: company.name,
        }))
  const optionsBrand =
    brandOptions === undefined
      ? []
      : (brandOptions as unknown as any[]).map((brand: any) => ({
          value: brand.brand_id,
          label: brand.name,
        }))
  const optionsOutlet =
    outletOptions === undefined
      ? []
      : (outletOptions as unknown as any[]).map((outlet: any) => ({
          value: outlet.outlet_id,
          label: outlet.name,
        }))

  const baseValidation = Yup.object().shape({
    name: Yup.string()
      .min(3, intl.formatMessage({id: 'VALIDATION_MIN_LENGTH_FIELD'}, {min: 3}))
      .max(125, intl.formatMessage({id: 'VALIDATION_MAX_LENGTH_FIELD'}, {max: 125}))
      .required(
        intl.formatMessage(
          {id: 'VALIDATION_REQUIRED_FIELD'},
          {name: intl.formatMessage({id: 'LABEL_NAME'})}
        )
      ),
  })

  const [models] = useState<DetailModel>({
    ...discounts,
    discount_id: discounts.discount_id || initialData.discount_id,
    holding_id: discounts.holding_id || initialData.holding_id,
    holding_name: discounts.holding?.name || initialData.holding?.name,
    company_id: discounts.company_id || initialData.company_id,
    company_name: discounts.company?.name || initialData.company?.name,
    brand_id: discounts.brand_id || initialData.brand_id,
    brand_name: discounts.brand?.name || initialData.brand?.name,
    outlet_id: action === 'add' ? [] : discounts.outlet?.map((dt) => dt.outlet_id),
    outlet_name: action === 'add' ? [] : discounts.outlet?.map((dt) => dt.name),
    holding: discounts.holding,
    company: discounts.company,
    brand: discounts.brand,
    outlet: discounts.outlet,
    name: discounts.name || initialData.name,
    format: discounts.format || initialData.format,
    amount: discounts.amount || initialData.amount,
    description: discounts.description || initialData.description,
    status: discounts.status || initialData.status,
    is_lifetime: discounts.is_lifetime || initialData.is_lifetime,
    start_period: discounts.start_period || initialData.start_period,
    end_period: discounts.end_period || initialData.end_period,
    start_hour: discounts.start_hour || initialData.start_hour,
    end_hour: discounts.end_hour || initialData.end_hour,
    days: discounts.days || initialData.days,
    created_at: discounts.created_at || initialData.created_at,
    created_by: discounts.created_by || initialData.created_by,
    updated_at: discounts.updated_at || initialData.updated_at,
    updated_by: discounts.updated_by || initialData.updated_by,
    menu_id: appMenuID,
    creator_id: currentUser?.user_id,
    creator_by: currentUser?.username,
  })

  const formik = useFormik({
    initialValues: models,
    validationSchema: action !== 'delete' ? baseValidation : undefined,
    onSubmit: async (values, {setSubmitting, setStatus, setFieldError}) => {
      setIsDialogOpen(false)
      action === 'edit'
        ? requestUpdateData(values, setStatus, setSubmitting, setFieldError)
        : action === 'add'
          ? requestAddData(values, setStatus, setSubmitting, setFieldError)
          : requestDeleteData(values, setStatus, setSubmitting)
    },
    onReset: (values) => {
      values = models
    },
  })

  const requestUpdateData = async (
    values: any,
    setStatus: any,
    setSubmitting: any,
    setFieldError: any
  ) => {
    setLoading(true)
    setStatus(null)
    setSubmitting(true)

    // request update to API
    try {
      // fix amount type
      values.amount = parseFloat(values.amount)
      const res = await updateData(values)

      // throw message error
      if (res?.meta.code !== 200) {
        if (res?.meta.message === 'name already used') {
          setStatus(intl.formatMessage({id: 'RESPONSE_LIBRARY_DISCOUNT_NAME_ALREADY_USED'}))
          setFieldError(
            'name',
            intl.formatMessage({id: 'RESPONSE_LIBRARY_DISCOUNT_NAME_ALREADY_USED'})
          )
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_LIBRARY_DISCOUNT_EDIT_SUCCESS'}))
        setCompleted(true)
        setTimeout(function () {
          setItemIdForUpdate(undefined)
          refetch()
        }, 2e3)
      }
    } catch (error: any) {
      console.error(error)
      if (error.response.status === 401) {
        setStatus(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
        removeAuth()
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
      }
      setSubmitting(false)
      setLoading(false)
      setTimeout(function () {
        setStatus(null)
      }, 9e3)

      return false
    } finally {
      setTimeout(() => {
        setLoading(false)
        setSubmitting(false)
      }, 5e2)
    }
  }
  const requestAddData = async (
    values: any,
    setStatus: any,
    setSubmitting: any,
    setFieldError: any
  ) => {
    setLoading(true)
    setStatus(null)
    setSubmitting(true)

    // fix amount type
    values.amount = parseFloat(values.amount)

    // request add to API
    try {
      const res = await addData(values)

      // throw message error
      if (res?.meta.code !== 200) {
        if (res?.meta.message === 'name already used') {
          setStatus(intl.formatMessage({id: 'RESPONSE_LIBRARY_DISCOUNT_NAME_ALREADY_USED'}))
          setFieldError(
            'name',
            intl.formatMessage({id: 'RESPONSE_LIBRARY_DISCOUNT_NAME_ALREADY_USED'})
          )
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_LIBRARY_DISCOUNT_ADD_SUCCESS'}))
        setCompleted(true)
        setTimeout(function () {
          setItemIdForUpdate(undefined)
          refetch()
        }, 2e3)
      }
    } catch (error: any) {
      console.error(error)
      if (error.response.status === 401) {
        setStatus(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
        removeAuth()
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
      }
      setSubmitting(false)
      setLoading(false)
      setTimeout(function () {
        setStatus(null)
      }, 9e3)

      return false
    } finally {
      setTimeout(() => {
        setLoading(false)
        setSubmitting(false)
      }, 5e2)
    }
  }
  const requestDeleteData = async (values: any, setStatus: any, setSubmitting: any) => {
    setLoading(true)
    setStatus(null)
    setSubmitting(true)

    // request delete to API
    try {
      const res = await deleteData(
        values.discount_id,
        values.menu_id,
        values.creator_id,
        values.creator_by
      )

      // throw message error
      if (res?.meta.code !== 200) {
        if (res?.meta.message === 'not inactive') {
          setStatus(intl.formatMessage({id: 'RESPONSE_LIBRARY_DISCOUNT_IS_ACTIVE'}))
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
        if (res?.meta.message === 'inused by other data') {
          setStatus(intl.formatMessage({id: 'RESPONSE_LIBRARY_DISCOUNT_IS_INUSED'}))
          setTimeout(function () {
            setStatus(null)
          }, 5e3)
        }
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_LIBRARY_DISCOUNT_DELETE_SUCCESS'}))
        setCompleted(true)
        setTimeout(function () {
          setItemIdForUpdate(undefined)
          refetch()
        }, 2e3)
      }
    } catch (error: any) {
      console.error(error)
      if (error.response.status === 401) {
        setStatus(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
        removeAuth()
      } else {
        setStatus(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
      }
      setSubmitting(false)
      setLoading(false)
      setTimeout(function () {
        setStatus(null)
      }, 9e3)

      return false
    } finally {
      setTimeout(() => {
        setLoading(false)
        setSubmitting(false)
      }, 5e2)
    }
  }

  useEffect(() => {
    auth === undefined && setIsAuth(false)
  }, [auth])

  useEffect(() => {
    const requestHoldingOptions = async () => {
      try {
        if (!didRequestHolding.current) {
          const options = await fetchHoldingOptions('input')
          // throw message error
          if (options.data.meta.code === 200) {
            // set option
            setHoldingOptions(options.data.data)
          }
        }
      } catch (error: any) {
        console.error(error)
        if (error.response.status === 401) {
          toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
          removeAuth()
        } else {
          toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
        }
      } finally {
      }

      return () => (didRequestHolding.current = true)
    }

    requestHoldingOptions()

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const requestCompanyOptions = async () => {
      try {
        if (!didRequestCompany.current) {
          const options = await fetchCompanyOptions('input', formik.values.holding_id)
          // throw message error
          if (options.data.meta.code === 200) {
            // set options
            setCompanyOptions(options.data.data)
          }
        }
      } catch (error: any) {
        console.error(error)
        if (error.response.status === 401) {
          toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
          removeAuth()
        } else {
          toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
        }
      } finally {
      }

      return () => (didRequestCompany.current = true)
    }

    formik.values.holding_id !== '' ? requestCompanyOptions() : setCompanyOptions(undefined)

    // eslint-disable-next-line
  }, [formik.values.holding_id])

  useEffect(() => {
    const requestBrandOptions = async () => {
      try {
        if (!didRequestBrand.current) {
          const options = await fetchBrandOptions('input', formik.values.company_id)
          // throw message error
          if (options.data.meta.code === 200) {
            // set options
            setBrandOptions(options.data.data)
          }
        }
      } catch (error: any) {
        console.error(error)
        if (error.response.status === 401) {
          toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
          removeAuth()
        } else {
          toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
        }
      } finally {
      }

      return () => (didRequestBrand.current = true)
    }

    formik.values.company_id !== '' ? requestBrandOptions() : setBrandOptions(undefined)

    // eslint-disable-next-line
  }, [formik.values.company_id])

  useEffect(() => {
    const outletOptions = async () => {
      try {
        if (!didRequestOutlet.current) {
          const options = await fetchOutletOptions('input', formik.values.brand_id)
          // throw message error
          if (options.data.meta.code === 200) {
            // set options
            setOutletOptions(options.data.data)
          }
        }
      } catch (error: any) {
        console.error(error)
        if (error.response.status === 401) {
          toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
          removeAuth()
        } else {
          toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
        }
      } finally {
      }

      return () => (didRequestOutlet.current = true)
    }

    if (formik.values.brand_id !== '') {
      outletOptions()
    } else {
      setOutletOptions(undefined)
    }

    // eslint-disable-next-line
  }, [formik.values.brand_id])

  return (
    <>
      {!isAuth && <NotAuthRedirectForm />}

      <Drawer.Header>
        <Drawer.Title className='fw-bolder'>
          {isLoading ? (
            <SpinnerIndicator label={intl.formatMessage({id: 'BUTTON_LABEL_PLEASE_WAIT'})} />
          ) : (
            <>
              {getFormAction(
                action,
                intl.formatMessage({id: 'BUTTON_LABEL_VIEW'}),
                intl.formatMessage({id: 'BUTTON_LABEL_EDIT'}),
                intl.formatMessage({id: 'BUTTON_LABEL_ADD'}),
                intl.formatMessage({id: 'BUTTON_LABEL_DELETE'})
              )}{' '}
              {menuName}
            </>
          )}
        </Drawer.Title>
        <Drawer.Actions>
          {action !== 'view' && models !== formik.values && !completed && (
            <button
              type='reset'
              className='btn btn-light me-3'
              data-kt-users-modal-action='reset'
              onClick={formik.handleReset}
              disabled={loading}
            >
              {!loading && (
                <span className='indicator-label'>
                  {intl.formatMessage({id: 'BUTTON_LABEL_RESET'})}
                </span>
              )}
            </button>
          )}

          {action !== 'view' && models !== formik.values && !completed && (
            <button
              type='submit'
              className='btn btn-primary'
              data-kt-users-modal-action='submit'
              onClick={() => {
                if (formik.values.holding_id === '') {
                  formik.setFieldError('holding_id', 'validation errors')
                  return false
                }
                if (formik.values.company_id === '') {
                  formik.setFieldError('company_id', 'validation errors')
                  return false
                }
                if (formik.values.brand_id === '') {
                  formik.setFieldError('brand_id', 'validation errors')
                  return false
                }
                if (formik.values.outlet_id?.toString().replaceAll(',', ', ') === '') {
                  formik.setFieldError('outlet_id', 'validation errors')
                  return false
                }
                if (Number(formik.values.amount) === 0 || Number(formik.values.amount) === 0.0) {
                  formik.setFieldError('amount', 'validation errors')
                  return false
                }
                setIsDialogOpen(true)
                setLoading(true)
                setTimeout(function () {
                  setLoading(false)
                }, 2e3)
              }}
              disabled={isLoading || loading || formik.isSubmitting || !formik.isValid}
            >
              {!loading && (
                <span className='indicator-label'>
                  {intl.formatMessage({id: 'BUTTON_LABEL_SUBMIT'})}
                </span>
              )}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {intl.formatMessage({id: 'BUTTON_LABEL_SUBMITTING'})}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          )}

          {action === 'delete' && !completed && (
            <button
              type='submit'
              className='btn btn-danger'
              data-kt-users-modal-action='submit'
              onClick={() => formik.handleSubmit()}
              disabled={isLoading || loading || formik.isSubmitting || !formik.isValid}
            >
              {!loading && (
                <span className='indicator-label'>
                  {intl.formatMessage({id: 'BUTTON_LABEL_DELETE'})}
                </span>
              )}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {intl.formatMessage({id: 'BUTTON_LABEL_SUBMITTING'})}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          )}
        </Drawer.Actions>
      </Drawer.Header>

      <Drawer.Body style={{padding: 0}}>
        {/* Response Message */}
        {formik.status &&
          (formik.status === intl.formatMessage({id: 'RESPONSE_LIBRARY_DISCOUNT_ADD_SUCCESS'}) ||
          formik.status === intl.formatMessage({id: 'RESPONSE_LIBRARY_DISCOUNT_EDIT_SUCCESS'}) ||
          formik.status === intl.formatMessage({id: 'RESPONSE_LIBRARY_DISCOUNT_DELETE_SUCCESS'}) ? (
            <div className='m-lg-5 p-2 bg-light-success rounded'>
              <div className='text-success text-center font-weight-bold fw-bolder'>
                {formik.status}
              </div>
            </div>
          ) : (
            <div className={`m-lg-5 p-2 alert alert-danger`}>
              <div className='alert-text text-center font-weight-bold fw-bolder'>
                {formik.status}
              </div>
            </div>
          ))}
        {/* Response Message */}

        {/* Form */}
        <div className={`modal-body m-5 ${action === 'add' ? '' : ' mt-0'}`}>
          {action === 'add' ? (
            <form id={`${modalID}-form`} className='form' onSubmit={formik.handleSubmit} noValidate>
              <div className='row'>
                {/* First Col */}
                <div className='col-lg-6'>
                  {/* Holding */}
                  <div className='fv-row mb-5'>
                    {formik.values.holding_id !== '' && (
                      <label className='form-label fs-6 fw-bolder text-dark required'>
                        Holding
                      </label>
                    )}
                    <InputPicker
                      block
                      data={optionsHolding}
                      placeholder={`-- Holding --`}
                      defaultValue={
                        optionsHolding
                          ? (optionsHolding as unknown as any[]).find(
                              (e: any) => e.value === formik.values.holding_id
                            )
                          : ''
                      }
                      onChange={(e) => {
                        formik.setFieldValue('holding_id', e === null ? '' : e)
                        formik.values.company_id = discounts.company_id
                        formik.values.company_name = discounts.company_name
                        formik.values.brand_id = discounts.brand_id
                        formik.values.brand_name = discounts.brand_name
                        formik.values.outlet_id = discounts.outlet_id
                        formik.values.outlet_name = discounts.outlet_name
                        formik.setFieldValue('company_id', '')
                        formik.setFieldValue('company_name', '')
                        formik.setFieldValue('brand_id', '')
                        formik.setFieldValue('brand_name', '')
                        formik.setFieldValue('outlet_id', [])
                        formik.setFieldValue('outlet_name', [])
                      }}
                      onSelect={(val, itm) => {
                        formik.setFieldValue('holding_name', val === null ? '' : itm.label)
                        formik.values.company_id = discounts.company_id
                        formik.values.company_name = discounts.company_name
                        formik.values.brand_id = discounts.brand_id
                        formik.values.brand_name = discounts.brand_name
                        formik.values.outlet_id = discounts.outlet_id
                        formik.values.outlet_name = discounts.outlet_name
                        formik.setFieldValue('company_id', '')
                        formik.setFieldValue('company_name', '')
                        formik.setFieldValue('brand_id', '')
                        formik.setFieldValue('brand_name', '')
                        formik.setFieldValue('outlet_id', [])
                        formik.setFieldValue('outlet_name', [])
                      }}
                      value={formik.values.holding_id}
                    />
                    {formik.errors.holding_id ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger fw-bolder'>
                          {intl.formatMessage(
                            {id: 'VALIDATION_REQUIRED_FIELD'},
                            {
                              name: 'Holding',
                            }
                          )}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {/* Holding */}

                  {/* Company */}
                  <div className='fv-row mb-5'>
                    {formik.values.company_id !== '' && (
                      <label className='form-label fs-6 fw-bolder text-dark required'>
                        {intl.formatMessage({id: 'LABEL_COMPANY'})}
                      </label>
                    )}
                    <InputPicker
                      block
                      data={optionsCompany}
                      disabled={formik.values.holding_id === '' ? true : false}
                      placeholder={`-- ${intl.formatMessage({id: 'LABEL_COMPANY'})} --`}
                      defaultValue={
                        optionsCompany
                          ? (optionsCompany as unknown as any[]).find(
                              (e: any) => e.value === formik.values.company_id
                            )
                          : ''
                      }
                      onChange={(e) => {
                        formik.setFieldValue('company_id', e === null ? '' : e)
                        formik.values.brand_id = discounts.brand_id
                        formik.values.brand_name = discounts.brand_name
                        formik.values.outlet_id = discounts.outlet_id
                        formik.values.outlet_name = discounts.outlet_name
                        formik.setFieldValue('brand_id', '')
                        formik.setFieldValue('brand_name', '')
                        formik.setFieldValue('outlet_id', [])
                        formik.setFieldValue('outlet_name', [])
                      }}
                      onSelect={(val, itm) => {
                        formik.setFieldValue('company_name', val === null ? '' : itm.label)
                        formik.values.brand_id = discounts.brand_id
                        formik.values.brand_name = discounts.brand_name
                        formik.values.outlet_id = discounts.outlet_id
                        formik.values.outlet_name = discounts.outlet_name
                        formik.setFieldValue('brand_id', '')
                        formik.setFieldValue('brand_name', '')
                        formik.setFieldValue('outlet_id', [])
                        formik.setFieldValue('outlet_name', [])
                      }}
                      value={formik.values.company_id}
                    />
                    {formik.errors.company_id ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger fw-bolder'>
                          {intl.formatMessage(
                            {id: 'VALIDATION_REQUIRED_FIELD'},
                            {
                              name: intl.formatMessage({id: 'LABEL_COMPANY'}),
                            }
                          )}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {/* Company */}

                  {/* Brand */}
                  <div className='fv-row mb-5'>
                    {formik.values.brand_id !== '' && (
                      <label className='form-label fs-6 fw-bolder text-dark required'>
                        {intl.formatMessage({id: 'LABEL_BRAND'})}
                      </label>
                    )}
                    <InputPicker
                      block
                      data={optionsBrand}
                      disabled={formik.values.company_id === '' ? true : false}
                      placeholder={`-- ${intl.formatMessage({id: 'LABEL_BRAND'})} --`}
                      defaultValue={
                        optionsBrand
                          ? (optionsBrand as unknown as any[]).find(
                              (e: any) => e.value === formik.values.brand_id
                            )
                          : ''
                      }
                      onChange={(e) => {
                        formik.setFieldValue('brand_id', e === null ? '' : e)
                        formik.values.outlet_id = discounts.outlet_id
                        formik.values.outlet_name = discounts.outlet_name
                        formik.setFieldValue('outlet_id', [])
                        formik.setFieldValue('outlet_name', [])
                      }}
                      onSelect={(val, itm) => {
                        formik.setFieldValue('brand_name', val === null ? '' : itm.label)
                        formik.values.outlet_id = discounts.outlet_id
                        formik.values.outlet_name = discounts.outlet_name
                        formik.setFieldValue('outlet_id', [])
                        formik.setFieldValue('outlet_name', [])
                      }}
                      value={formik.values.brand_id}
                    />
                    {formik.errors.brand_id ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger fw-bolder'>
                          {intl.formatMessage(
                            {id: 'VALIDATION_REQUIRED_FIELD'},
                            {
                              name: intl.formatMessage({id: 'LABEL_BRAND'}),
                            }
                          )}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {/* Brand */}

                  {/* Outlet */}
                  <div className='fv-row mb-5'>
                    {formik.values.outlet_id &&
                      (formik.values.outlet_id as unknown as any[]).length > 0 && (
                        <label className='form-label fs-6 fw-bolder text-dark required'>
                          Outlet
                        </label>
                      )}
                    <CheckPicker
                      block
                      placeholder={`-- Outlet --`}
                      data={optionsOutlet}
                      disabled={formik.values.brand_id === '' ? true : false}
                      defaultValue={
                        discounts && discounts.outlet
                          ? (discounts.outlet as unknown as any[]).map((e) => e.outlet_id)
                          : []
                      }
                      onChange={(val, tr) => {
                        let tmp = []
                        let result: any[] = []
                        let label: any = formik.values.outlet_name

                        if (tr.type === 'keydown') {
                          // prevent user select/choice by using click enter
                          return
                        }

                        if (val.length > 0) {
                          if ((tr.target as unknown as any).checked === true) {
                            tmp = (optionsOutlet as unknown as any[]).find(
                              (dt) => dt.label === (tr.target as unknown as any).labels[0].innerText
                            )
                            result = [...label, tmp.label]
                          } else {
                            if (tr.type === 'click') {
                              if ((tr.target as unknown as any).lastChild.nodeName === 'path') {
                                let tmp_result = label.filter(
                                  (e: any) =>
                                    e !==
                                    (tr.target as unknown as any).parentElement.parentElement
                                      .innerText
                                )
                                result = tmp_result
                              }
                            } else {
                              let tmp_result = label.filter(
                                (e: any) => e !== (tr.target as unknown as any).labels[0].innerText
                              )
                              result = tmp_result
                            }
                          }
                          formik.setFieldValue('outlet_id', val)
                          formik.setFieldValue('outlet_name', result)
                        } else {
                          formik.setFieldValue('outlet_id', [])
                          formik.setFieldValue('outlet_name', [])
                        }
                      }}
                      onSelect={(val, ite) => {
                        let value: any = formik.values.outlet_id
                        let label: any = formik.values.outlet_name
                        let tmpValue: any = []
                        let tmpLabel: any = []
                        let resultValue: any[] = []
                        let resultLabel: any[] = []

                        if (val.length > 0) {
                          if (value.indexOf(ite.value) === -1) {
                            tmpValue = (optionsOutlet as unknown as any[]).find(
                              (dt) => dt.value === ite.value
                            )

                            if (tmpValue === undefined) {
                              return
                            }

                            resultValue = [...value, tmpValue.value]
                            formik.setFieldValue('outlet_id', resultValue)
                          } else {
                            tmpValue = value.filter((dt: any) => dt !== ite.value)
                            formik.setFieldValue('outlet_id', tmpValue)
                          }

                          if (label.indexOf(ite.label) === -1) {
                            tmpLabel = (optionsOutlet as unknown as any[]).find(
                              (dt) => dt.label === ite.label
                            )

                            if (tmpLabel === undefined) {
                              return
                            }

                            resultLabel = [...label, tmpLabel.label]
                            formik.setFieldValue('outlet_name', resultLabel)
                          } else {
                            tmpLabel = label.filter((dt: any) => dt !== ite.label)
                            formik.setFieldValue('outlet_name', tmpLabel)
                          }
                        } else {
                          formik.setFieldValue('outlet_id', [])
                          formik.setFieldValue('outlet_name', [])
                        }
                      }}
                      value={formik.values.outlet_id}
                      renderExtraFooter={() => (
                        <div style={footerStyles}>
                          <Checkbox
                            indeterminate={
                              (formik.values.outlet_id as unknown as any[]).length > 0 &&
                              (formik.values.outlet_id as unknown as any[]).length <
                                (optionsOutlet as unknown as any[]).length
                            }
                            checked={
                              (formik.values.outlet_id as unknown as any[]).length ===
                              (optionsOutlet as unknown as any[]).length
                            }
                            onChange={(data, checked) => {
                              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                              if (checked) {
                                formik.setFieldValue(
                                  'outlet_id',
                                  (optionsOutlet as unknown as any[]).map((e) => e.value)
                                )
                                formik.setFieldValue(
                                  'outlet_name',
                                  (optionsOutlet as unknown as any[]).map((e) => e.label)
                                )
                              } else {
                                formik.setFieldValue('outlet_id', [])
                                formik.setFieldValue('outlet_name', [])
                              }
                            }}
                          >
                            {(formik.values.outlet_id as unknown as any[]).length ===
                            (optionsOutlet as unknown as any[]).length
                              ? intl.formatMessage({id: 'LABEL_UNSELECT_ALL'})
                              : intl.formatMessage({id: 'LABEL_SELECT_ALL'})}
                          </Checkbox>
                        </div>
                      )}
                    />
                    {formik.errors.outlet_id ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger fw-bolder'>
                          {intl.formatMessage(
                            {id: 'VALIDATION_REQUIRED_FIELD'},
                            {
                              name: 'Outlet',
                            }
                          )}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {/* Outlet */}
                </div>
                {/* First Col */}

                {/* Second Col */}
                <div className='col-lg-6'>
                  {/* Name */}
                  <div className='fv-row mb-5'>
                    {formik.values.name !== '' && (
                      <label className='form-label fs-6 fw-bolder text-dark required'>
                        {intl.formatMessage({id: 'LABEL_NAME'})}
                      </label>
                    )}
                    <input
                      type='text'
                      className={clsx(
                        'form-control form-control-solid',
                        {'is-invalid': formik.touched.name && formik.errors.name},
                        {
                          'is-valid': formik.touched.name && !formik.errors.name,
                        }
                      )}
                      placeholder={intl.formatMessage({id: 'LABEL_NAME'})}
                      {...formik.getFieldProps('name')}
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger fw-bolder'>
                          {formik.errors.name}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {/* Name */}

                  {/* Amount */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark required'>
                      {intl.formatMessage({id: 'LABEL_DISCOUNT_AMOUNT'})}
                    </label>

                    <br />
                    <small className='text-muted mt-5'>
                      &nbsp;{intl.formatMessage({id: 'LABEL_MASTER_DISCOUNT_AMOUNT_INFO'})}
                    </small>
                    <br />
                    <Toggle
                      size='lg'
                      className='mb-5'
                      checkedChildren={'%'}
                      unCheckedChildren={'Rp.'}
                      checked={formik.values.format === 'percentage' ? true : false}
                      onChange={() =>
                        formik.setFieldValue(
                          'format',
                          formik.values.format === 'percentage' ? 'cash' : 'percentage'
                        )
                      }
                    />
                    {formik.values.format === 'percentage' ? (
                      <>
                        <div className='input-group'>
                          <CurrencyFormat
                            className='form-control form-control-sm'
                            value={formik.values.amount}
                            displayType={'input'}
                            isNumericString={false}
                            decimalScale={2}
                            thousandSeparator={true}
                            key={`amount-per-${formik.values.discount_id}`}
                            onValueChange={(values: any) => {
                              const {value} = values

                              if (value > 100) {
                                formik.setFieldValue('amount', 100)
                              } else if (Number(value) > 0) {
                                formik.setFieldValue('amount', value)
                              } else {
                                formik.setFieldValue('amount', 0)
                              }
                            }}
                          />
                          <span className='input-group-text' id='amount-per'>
                            %
                          </span>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className='input-group'>
                          <span className='input-group-text' id='amount-rp'>
                            Rp
                          </span>
                          <CurrencyFormat
                            className='form-control form-control-sm'
                            value={formik.values.amount}
                            displayType={'input'}
                            isNumericString={false}
                            decimalScale={2}
                            thousandSeparator={true}
                            key={`amount-rp-${formik.values.discount_id}`}
                            onValueChange={(values: any) => {
                              const {value} = values

                              if (Number(value) > 0) {
                                formik.setFieldValue('amount', value)
                              } else {
                                formik.setFieldValue('amount', 0)
                              }
                            }}
                          />
                        </div>
                      </>
                    )}
                    {formik.errors.amount ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block text-danger fw-bolder'>
                          {intl.formatMessage(
                            {id: 'VALIDATION_REQUIRED_FIELD'},
                            {
                              name: intl.formatMessage({id: 'LABEL_DISCOUNT_AMOUNT'}),
                            }
                          )}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {/* Amount */}
                </div>
                {/* Second Col */}
              </div>

              <div className='row mt-10'>
                {/* Third Col */}
                <div className='col-lg-6'>
                  {/* Description */}
                  <div className='fv-row mb-5'>
                    {formik.values.description !== '' && (
                      <label className='form-label fs-6 fw-bolder text-dark'>
                        {intl.formatMessage({id: 'LABEL_DESCRIPTION'})}
                      </label>
                    )}
                    <textarea
                      className={clsx(
                        'form-control form-control-solid',
                        {'is-invalid': formik.touched.description && formik.errors.description},
                        {
                          'is-valid': formik.touched.description && !formik.errors.description,
                        }
                      )}
                      placeholder={intl.formatMessage({id: 'LABEL_DESCRIPTION'})}
                      {...formik.getFieldProps('description')}
                      rows={3}
                    />
                  </div>
                  {/* Description */}
                </div>
                {/* Third Col */}

                {/* Fourth Col */}
                <div className='col-lg-6'>
                  {/* Status */}
                  <div className='fv-row mb-10'>
                    <label className='form-label fs-6 fw-bolder text-dark required'>Status</label>
                    <div className='form-check form-switch form-check-custom form-check-solid mt-1'>
                      <Toggle
                        size='lg'
                        checkedChildren={intl.formatMessage({id: 'LABEL_ACTIVE'})}
                        unCheckedChildren={intl.formatMessage({id: 'LABEL_INACTIVE'})}
                        checked={formik.values.status === 't' ? true : false}
                        onChange={() =>
                          formik.setFieldValue('status', formik.values.status === 't' ? 'f' : 't')
                        }
                      />
                    </div>
                  </div>
                  {/* Status */}
                </div>
                {/* Fourth Col */}
              </div>
            </form>
          ) : (
            <form id={`${modalID}-form`} className='form' onSubmit={formik.handleSubmit} noValidate>
              {/* Tab Header */}
              <div className='card-toolbar'>
                <ul
                  className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-2 mt-2'
                  role='tablist'
                >
                  <li className={`nav-item ${tab === 'data' ? 'fw-bolder' : ''}`}>
                    <a
                      className={clsx(`nav-link cursor-pointer`, {
                        active: tab === 'data',
                      })}
                      onClick={() => setTab('data')}
                      role='tab'
                    >
                      Data
                    </a>
                  </li>
                  <li className={`nav-item ${tab === 'log' ? 'fw-bolder' : ''}`}>
                    <a
                      className={clsx(`nav-link cursor-pointer`, {
                        active: tab === 'log',
                      })}
                      onClick={() => setTab('log')}
                      role='tab'
                    >
                      Log
                    </a>
                  </li>
                </ul>
              </div>
              {/* Tab Header */}

              {/* Tab Body */}
              <div className='card-body p-0 pt-2'>
                <div className='tab-content'>
                  {/* Data */}
                  <div
                    className={clsx('tab-pane', {
                      active: tab === 'data',
                    })}
                  >
                    <div className='row'>
                      {/* First Col */}
                      <div className='col-lg-6'>
                        {/* Holding */}
                        <div className='fv-row mb-5'>
                          {formik.values.holding_id !== '' && (
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action === 'edit' ? 'required' : ''
                              }`}
                            >
                              Holding
                            </label>
                          )}
                          <InputPicker
                            block
                            readOnly={action !== 'edit' ? true : false}
                            data={optionsHolding}
                            placeholder={`-- Holding --`}
                            defaultValue={
                              optionsHolding
                                ? (optionsHolding as unknown as any[]).find(
                                    (e: any) => e.value === formik.values.holding_id
                                  )
                                : ''
                            }
                            onChange={(e) => {
                              formik.setFieldValue('holding_id', e === null ? '' : e)
                              formik.values.company_id = discounts.company_id
                              formik.values.company_name = discounts.company_name
                              formik.values.brand_id = discounts.brand_id
                              formik.values.brand_name = discounts.brand_name
                              formik.values.outlet_id = discounts.outlet_id
                              formik.values.outlet_name = discounts.outlet_name
                              formik.setFieldValue('company_id', '')
                              formik.setFieldValue('company_name', '')
                              formik.setFieldValue('brand_id', '')
                              formik.setFieldValue('brand_name', '')
                              formik.setFieldValue('outlet_id', [])
                              formik.setFieldValue('outlet_name', [])
                            }}
                            onSelect={(val, itm) => {
                              formik.setFieldValue('holding_name', val === null ? '' : itm.label)
                              formik.values.company_id = discounts.company_id
                              formik.values.company_name = discounts.company_name
                              formik.values.brand_id = discounts.brand_id
                              formik.values.brand_name = discounts.brand_name
                              formik.values.outlet_id = discounts.outlet_id
                              formik.values.outlet_name = discounts.outlet_name
                              formik.setFieldValue('company_id', '')
                              formik.setFieldValue('company_name', '')
                              formik.setFieldValue('brand_id', '')
                              formik.setFieldValue('brand_name', '')
                              formik.setFieldValue('outlet_id', [])
                              formik.setFieldValue('outlet_name', [])
                            }}
                            value={formik.values.holding_id}
                          />
                          {formik.errors.holding_id ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {intl.formatMessage(
                                  {id: 'VALIDATION_REQUIRED_FIELD'},
                                  {
                                    name: 'Holding',
                                  }
                                )}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Holding */}

                        {/* Company */}
                        <div className='fv-row mb-5'>
                          {formik.values.company_id !== '' && (
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action === 'edit' ? 'required' : ''
                              }`}
                            >
                              {intl.formatMessage({id: 'LABEL_COMPANY'})}
                            </label>
                          )}
                          <InputPicker
                            block
                            readOnly={action !== 'edit' ? true : false}
                            data={optionsCompany}
                            placeholder={`-- ${intl.formatMessage({id: 'LABEL_COMPANY'})} --`}
                            defaultValue={
                              optionsCompany
                                ? (optionsCompany as unknown as any[]).find(
                                    (e: any) => e.value === formik.values.company_id
                                  )
                                : ''
                            }
                            onChange={(e) => {
                              formik.setFieldValue('company_id', e === null ? '' : e)
                              formik.values.brand_id = discounts.brand_id
                              formik.values.brand_name = discounts.brand_name
                              formik.values.outlet_id = discounts.outlet_id
                              formik.values.outlet_name = discounts.outlet_name
                              formik.setFieldValue('brand_id', '')
                              formik.setFieldValue('brand_name', '')
                              formik.setFieldValue('outlet_id', [])
                              formik.setFieldValue('outlet_name', [])
                            }}
                            onSelect={(val, itm) => {
                              formik.setFieldValue('company_name', val === null ? '' : itm.label)
                              formik.values.brand_id = discounts.brand_id
                              formik.values.brand_name = discounts.brand_name
                              formik.values.outlet_id = discounts.outlet_id
                              formik.values.outlet_name = discounts.outlet_name
                              formik.setFieldValue('brand_id', '')
                              formik.setFieldValue('brand_name', '')
                              formik.setFieldValue('outlet_id', [])
                              formik.setFieldValue('outlet_name', [])
                            }}
                            value={formik.values.company_id}
                          />
                          {formik.errors.company_id ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {intl.formatMessage(
                                  {id: 'VALIDATION_REQUIRED_FIELD'},
                                  {
                                    name: intl.formatMessage({id: 'LABEL_COMPANY'}),
                                  }
                                )}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Company */}

                        {/* Brand */}
                        <div className='fv-row mb-5'>
                          {formik.values.brand_id !== '' && (
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action === 'edit' ? 'required' : ''
                              }`}
                            >
                              {intl.formatMessage({id: 'LABEL_BRAND'})}
                            </label>
                          )}
                          <InputPicker
                            block
                            readOnly={action !== 'edit' ? true : false}
                            data={optionsBrand}
                            placeholder={`-- ${intl.formatMessage({id: 'LABEL_BRAND'})} --`}
                            defaultValue={
                              optionsBrand
                                ? (optionsBrand as unknown as any[]).find(
                                    (e: any) => e.value === formik.values.brand_id
                                  )
                                : ''
                            }
                            onChange={(e) => {
                              formik.setFieldValue('brand_id', e === null ? '' : e)
                              formik.values.outlet_id = discounts.outlet_id
                              formik.values.outlet_name = discounts.outlet_name
                              formik.setFieldValue('outlet_id', [])
                              formik.setFieldValue('outlet_name', [])
                            }}
                            onSelect={(val, itm) => {
                              formik.setFieldValue('brand_name', val === null ? '' : itm.label)
                              formik.values.outlet_id = discounts.outlet_id
                              formik.values.outlet_name = discounts.outlet_name
                              formik.setFieldValue('outlet_id', [])
                              formik.setFieldValue('outlet_name', [])
                            }}
                            value={formik.values.brand_id}
                          />
                          {formik.errors.brand_id ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {intl.formatMessage(
                                  {id: 'VALIDATION_REQUIRED_FIELD'},
                                  {
                                    name: intl.formatMessage({id: 'LABEL_BRAND'}),
                                  }
                                )}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Brand */}

                        {/* Outlet */}
                        <div className='fv-row mb-5'>
                          {formik.values.brand_id !== '' && (
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action === 'edit' ? 'required' : ''
                              }`}
                            >
                              {intl.formatMessage({id: 'LABEL_OUTLET'})}
                            </label>
                          )}
                          <CheckPicker
                            block
                            readOnly={action !== 'edit' ? true : false}
                            data={optionsOutlet}
                            placeholder={`-- ${intl.formatMessage({id: 'LABEL_OUTLET'})} --`}
                            defaultValue={
                              optionsOutlet
                                ? (optionsOutlet as unknown as any[]).find(
                                    (e: any) => e.value === formik.values.outlet_id
                                  )
                                : ''
                            }
                            onChange={(val, tr) => {
                              let tmp = []
                              let result: any[] = []
                              let label: any = formik.values.outlet_name

                              if (tr.type === 'keydown') {
                                // prevent user select/choice by using click enter
                                return
                              }

                              if (val.length > 0) {
                                if ((tr.target as unknown as any).checked === true) {
                                  tmp = (optionsOutlet as unknown as any[]).find(
                                    (dt) =>
                                      dt.label === (tr.target as unknown as any).labels[0].innerText
                                  )
                                  result = [...label, tmp.label]
                                } else {
                                  if (tr.type === 'click') {
                                    if (
                                      (tr.target as unknown as any).lastChild.nodeName === 'path'
                                    ) {
                                      let tmp_result = label.filter(
                                        (e: any) =>
                                          e !==
                                          (tr.target as unknown as any).parentElement.parentElement
                                            .innerText
                                      )
                                      result = tmp_result
                                    }
                                  } else {
                                    let tmp_result = label.filter(
                                      (e: any) =>
                                        e !== (tr.target as unknown as any).labels[0].innerText
                                    )
                                    result = tmp_result
                                  }
                                }
                                formik.setFieldValue('outlet_id', val)
                                formik.setFieldValue('outlet_name', result)
                              } else {
                                formik.setFieldValue('outlet_id', [])
                                formik.setFieldValue('outlet_name', [])
                              }
                            }}
                            onSelect={(val, ite) => {
                              let value: any = formik.values.outlet_id
                              let label: any = formik.values.outlet_name
                              let tmpValue: any = []
                              let tmpLabel: any = []
                              let resultValue: any[] = []
                              let resultLabel: any[] = []

                              if (val.length > 0) {
                                if (value.indexOf(ite.value) === -1) {
                                  tmpValue = (optionsOutlet as unknown as any[]).find(
                                    (dt) => dt.value === ite.value
                                  )

                                  if (tmpValue === undefined) {
                                    return
                                  }

                                  resultValue = [...value, tmpValue.value]
                                  formik.setFieldValue('outlet_id', resultValue)
                                } else {
                                  tmpValue = value.filter((dt: any) => dt !== ite.value)
                                  formik.setFieldValue('outlet_id', tmpValue)
                                }

                                if (label.indexOf(ite.label) === -1) {
                                  tmpLabel = (optionsOutlet as unknown as any[]).find(
                                    (dt) => dt.label === ite.label
                                  )

                                  if (tmpLabel === undefined) {
                                    return
                                  }

                                  resultLabel = [...label, tmpLabel.label]
                                  formik.setFieldValue('outlet_name', resultLabel)
                                } else {
                                  tmpLabel = label.filter((dt: any) => dt !== ite.label)
                                  formik.setFieldValue('outlet_name', tmpLabel)
                                }
                              } else {
                                formik.setFieldValue('outlet_id', [])
                                formik.setFieldValue('outlet_name', [])
                              }
                            }}
                            value={formik.values.outlet_id}
                            renderExtraFooter={() => (
                              <div style={footerStyles}>
                                <Checkbox
                                  indeterminate={
                                    (formik.values.outlet_id as unknown as any[]).length > 0 &&
                                    (formik.values.outlet_id as unknown as any[]).length <
                                      (optionsOutlet as unknown as any[]).length
                                  }
                                  checked={
                                    (formik.values.outlet_id as unknown as any[]).length ===
                                    (optionsOutlet as unknown as any[]).length
                                  }
                                  onChange={(data, checked) => {
                                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                                    if (checked) {
                                      formik.setFieldValue(
                                        'outlet_id',
                                        (optionsOutlet as unknown as any[]).map((e) => e.value)
                                      )
                                      formik.setFieldValue(
                                        'outlet_name',
                                        (optionsOutlet as unknown as any[]).map((e) => e.label)
                                      )
                                    } else {
                                      formik.setFieldValue('outlet_id', [])
                                      formik.setFieldValue('outlet_name', [])
                                    }
                                  }}
                                >
                                  {(formik.values.outlet_id as unknown as any[]).length ===
                                  (optionsOutlet as unknown as any[]).length
                                    ? intl.formatMessage({id: 'LABEL_UNSELECT_ALL'})
                                    : intl.formatMessage({id: 'LABEL_SELECT_ALL'})}
                                </Checkbox>
                              </div>
                            )}
                          />
                          {formik.errors.outlet_id ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {intl.formatMessage(
                                  {id: 'VALIDATION_REQUIRED_FIELD'},
                                  {
                                    name: intl.formatMessage({id: 'LABEL_OUTLET'}),
                                  }
                                )}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Outlet */}
                      </div>
                      {/* First Col */}

                      {/* Second Col */}
                      <div className='col-lg-6'>
                        {/* Name */}
                        <div className='fv-row mb-5'>
                          {formik.values.name !== '' && (
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action === 'edit' ? 'required' : ''
                              }`}
                            >
                              {intl.formatMessage({id: 'LABEL_NAME'})}
                            </label>
                          )}
                          <input
                            type='text'
                            className={clsx(
                              'form-control form-control-solid',
                              {'is-invalid': formik.touched.name && formik.errors.name},
                              {
                                'is-valid': formik.touched.name && !formik.errors.name,
                              }
                            )}
                            placeholder={intl.formatMessage({id: 'LABEL_NAME'})}
                            {...formik.getFieldProps('name')}
                            readOnly={action === 'edit' ? false : true}
                          />
                          {formik.touched.name && formik.errors.name ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {formik.errors.name}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Name */}

                        {/* Amount */}
                        <div className='fv-row mb-5'>
                          <label className='form-label fs-6 fw-bolder text-dark required'>
                            {intl.formatMessage({id: 'LABEL_DISCOUNT_AMOUNT'})}
                          </label>

                          <br />
                          <small className='text-muted mt-5'>
                            &nbsp;{intl.formatMessage({id: 'LABEL_MASTER_DISCOUNT_AMOUNT_INFO'})}
                          </small>
                          <br />
                          <Toggle
                            size='lg'
                            readOnly={action === 'edit' ? false : true}
                            className='mb-5'
                            checkedChildren={'%'}
                            unCheckedChildren={'Rp.'}
                            checked={formik.values.format === 'percentage' ? true : false}
                            onChange={() =>
                              formik.setFieldValue(
                                'format',
                                formik.values.format === 'percentage' ? 'cash' : 'percentage'
                              )
                            }
                          />
                          {formik.values.format === 'percentage' ? (
                            <>
                              <div className='input-group'>
                                <CurrencyFormat
                                  readOnly={action === 'edit' ? false : true}
                                  className='form-control form-control-sm'
                                  value={formik.values.amount}
                                  displayType={'input'}
                                  isNumericString={false}
                                  decimalScale={2}
                                  thousandSeparator={true}
                                  key={`amount-per-${formik.values.discount_id}`}
                                  onValueChange={(values: any) => {
                                    const {value} = values

                                    if (value > 100) {
                                      formik.setFieldValue('amount', 100)
                                    } else if (Number(value) > 0) {
                                      formik.setFieldValue('amount', value)
                                    } else {
                                      formik.setFieldValue('amount', 0)
                                    }
                                  }}
                                />
                                <span className='input-group-text' id='amount-per'>
                                  %
                                </span>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className='input-group'>
                                <span className='input-group-text' id='amount-rp'>
                                  Rp
                                </span>
                                <CurrencyFormat
                                  readOnly={action === 'edit' ? false : true}
                                  className='form-control form-control-sm'
                                  value={formik.values.amount}
                                  displayType={'input'}
                                  isNumericString={false}
                                  decimalScale={2}
                                  thousandSeparator={true}
                                  key={`amount-rp-${formik.values.discount_id}`}
                                  onValueChange={(values: any) => {
                                    const {value} = values

                                    if (Number(value) > 0) {
                                      formik.setFieldValue('amount', value)
                                    } else {
                                      formik.setFieldValue('amount', 0)
                                    }
                                  }}
                                />
                              </div>
                            </>
                          )}
                          {formik.errors.amount ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger fw-bolder'>
                                {intl.formatMessage(
                                  {id: 'VALIDATION_REQUIRED_FIELD'},
                                  {
                                    name: intl.formatMessage({id: 'LABEL_DISCOUNT_AMOUNT'}),
                                  }
                                )}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* Amount */}
                      </div>
                      {/* Second Col */}
                    </div>

                    <div className='row mt-10'>
                      {/* Third Col */}
                      <div className='col-lg-6'>
                        {/* Description */}
                        <div className='fv-row mb-5'>
                          {formik.values.description !== '' && (
                            <label className={`form-label fs-6 fw-bolder text-dark`}>
                              {intl.formatMessage({id: 'LABEL_DESCRIPTION'})}
                            </label>
                          )}
                          <textarea
                            className={clsx(
                              'form-control form-control-solid',
                              {
                                'is-invalid':
                                  formik.touched.description && formik.errors.description,
                              },
                              {
                                'is-valid':
                                  formik.touched.description && !formik.errors.description,
                              }
                            )}
                            placeholder={intl.formatMessage({id: 'LABEL_DESCRIPTION'})}
                            {...formik.getFieldProps('description')}
                            rows={3}
                            readOnly={action !== 'edit' ? true : false}
                          />
                        </div>
                        {/* Address */}
                      </div>
                      {/* Third Col */}

                      {/* Fourth Col */}
                      <div className='col-lg-6'>
                        {/* Status */}
                        <div className='fv-row mb-5'>
                          {formik.values.status !== '' && (
                            <label
                              className={`form-label fs-6 fw-bolder text-dark ${
                                action === 'edit' ? 'required' : ''
                              }`}
                            >
                              Status
                            </label>
                          )}
                          <div className='form-check form-switch form-check-custom form-check-solid mt-1'>
                            <Toggle
                              size='lg'
                              readOnly={action !== 'edit' ? true : false}
                              checkedChildren={intl.formatMessage({id: 'LABEL_ACTIVE'})}
                              unCheckedChildren={intl.formatMessage({id: 'LABEL_INACTIVE'})}
                              checked={formik.values.status === 't' ? true : false}
                              onChange={() =>
                                formik.setFieldValue(
                                  'status',
                                  formik.values.status === 't' ? 'f' : 't'
                                )
                              }
                            />
                          </div>
                        </div>
                        {/* Status */}
                      </div>
                      {/* Fourth Col */}
                    </div>
                  </div>
                  {/* Data */}

                  {/* Detail */}
                  <div
                    className={clsx('tab-pane', {
                      active: tab === 'log',
                    })}
                  >
                    <FormLog payload={discounts} formik={formik} />
                  </div>
                  {/* Detail */}
                </div>
              </div>
              {/* Tab Body */}
            </form>
          )}
        </div>
        {/* Form */}
      </Drawer.Body>

      {/* Dialog */}
      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(true)}
        className='modal fade show d-block'
      >
        <Dialog.Panel className='modal-dialog modal-fullscreen'>
          <div className='modal-content'>
            <Dialog.Title className='modal-header'>
              {intl.formatMessage({id: 'DIALOG_HEADER_TITLE'})}
            </Dialog.Title>
            <div className='modal-body scroll-y mx-2 mx-xl-2 my-2'>
              <div className='row'>
                {/* First Col */}
                <div className='col-lg-6'>
                  {/* Holding */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark'>Holding</label>
                    {models.holding_id !== formik.values.holding_id ? (
                      <>
                        {action !== 'add' && (
                          <textarea
                            className='form-control form-control-solid'
                            value={models.holding_name?.toString().replaceAll(',', ', ')}
                            readOnly
                          />
                        )}
                        <textarea
                          className='form-control form-control-solid text-primary is-valid'
                          value={formik.values?.holding_name?.toString().replaceAll(',', ', ')}
                          readOnly
                        />
                      </>
                    ) : (
                      <textarea
                        className='form-control form-control-solid'
                        value={formik.values?.holding_name?.toString().replaceAll(',', ', ')}
                        readOnly
                      />
                    )}
                  </div>
                  {/* Holding */}

                  {/* Company */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark'>
                      {intl.formatMessage({id: 'LABEL_COMPANY'})}
                    </label>
                    {models.company_id !== formik.values.company_id ? (
                      <>
                        {action !== 'add' && (
                          <textarea
                            className='form-control form-control-solid'
                            value={models.company_name?.toString().replaceAll(',', ', ')}
                            readOnly
                          />
                        )}
                        <textarea
                          className='form-control form-control-solid text-primary is-valid'
                          value={formik.values?.company_name?.toString().replaceAll(',', ', ')}
                          readOnly
                        />
                      </>
                    ) : (
                      <textarea
                        className='form-control form-control-solid'
                        value={formik.values?.company_name?.toString().replaceAll(',', ', ')}
                        readOnly
                      />
                    )}
                  </div>
                  {/* Company */}

                  {/* Brand */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark'>
                      {intl.formatMessage({id: 'LABEL_BRAND'})}
                    </label>
                    {models.brand_id !== formik.values.brand_id ? (
                      <>
                        {action !== 'add' && (
                          <textarea
                            className='form-control form-control-solid'
                            value={models.brand_name?.toString().replaceAll(',', ', ')}
                            readOnly
                          />
                        )}
                        <textarea
                          className='form-control form-control-solid text-primary is-valid'
                          value={formik.values?.brand_name?.toString().replaceAll(',', ', ')}
                          readOnly
                        />
                      </>
                    ) : (
                      <textarea
                        className='form-control form-control-solid'
                        value={formik.values?.brand_name?.toString().replaceAll(',', ', ')}
                        readOnly
                      />
                    )}
                  </div>
                  {/* Brand */}

                  {/* Outlet */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark'>Outlet</label>
                    {models.outlet_id !== formik.values.outlet_id ? (
                      <>
                        {action !== 'add' && (
                          <textarea
                            className='form-control form-control-solid'
                            value={models.outlet_name?.toString().replaceAll(',', ', ')}
                            readOnly
                          />
                        )}
                        <textarea
                          className='form-control form-control-solid text-primary is-valid'
                          value={formik.values?.outlet_name?.toString().replaceAll(',', ', ')}
                          readOnly
                        />
                      </>
                    ) : (
                      <textarea
                        className='form-control form-control-solid'
                        value={formik.values?.outlet_name?.toString().replaceAll(',', ', ')}
                        readOnly
                      />
                    )}
                  </div>
                  {/* Outlet */}
                </div>
                {/* First Col */}

                {/* Second Col */}
                <div className='col-lg-6'>
                  {/* Name */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark'>
                      {intl.formatMessage({id: 'LABEL_NAME'})}
                    </label>
                    {models.name !== formik.values.name ? (
                      <>
                        {action !== 'add' && (
                          <input
                            type='text'
                            className='form-control form-control-solid'
                            value={models.name}
                            readOnly
                          />
                        )}
                        <input
                          type='text'
                          className='form-control form-control-solid text-primary is-valid'
                          value={formik.values.name}
                          readOnly
                        />
                      </>
                    ) : (
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        value={models.name}
                        readOnly
                      />
                    )}
                  </div>
                  {/* Name */}

                  {/* Amount */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark'>
                      {intl.formatMessage({id: 'LABEL_DISCOUNT_AMOUNT'})}
                    </label>
                    {models.amount !== formik.values.amount ||
                    models.format !== formik.values.format ? (
                      <>
                        {action !== 'add' && (
                          <>
                            {models.format !== formik.values.format ? (
                              models.format === 'percentage' ? (
                                <>
                                  <div className='input-group'>
                                    <CurrencyFormat
                                      className='form-control form-control-sm'
                                      readOnly
                                      value={models.amount}
                                      displayType={'input'}
                                      isNumericString={false}
                                      decimalScale={2}
                                      thousandSeparator={true}
                                      key={`dialog-amount-per-${models.discount_id}`}
                                    />
                                    <span className='input-group-text' id='dialog-amount-per'>
                                      %
                                    </span>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className='input-group'>
                                    <span className='input-group-text' id='dialog-amount-rp'>
                                      Rp
                                    </span>
                                    <CurrencyFormat
                                      className='form-control form-control-sm'
                                      readOnly
                                      value={models.amount}
                                      displayType={'input'}
                                      isNumericString={false}
                                      decimalScale={2}
                                      thousandSeparator={true}
                                      key={`dialog-amount-rp-${models.discount_id}`}
                                    />
                                  </div>
                                </>
                              )
                            ) : formik.values.format === 'percentage' ? (
                              <>
                                <div className='input-group'>
                                  <CurrencyFormat
                                    className='form-control form-control-sm'
                                    readOnly
                                    value={models.amount}
                                    displayType={'input'}
                                    isNumericString={false}
                                    decimalScale={2}
                                    thousandSeparator={true}
                                    key={`dialog-amount-per-${models.discount_id}`}
                                  />
                                  <span className='input-group-text' id='dialog-amount-per'>
                                    %
                                  </span>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className='input-group'>
                                  <span className='input-group-text' id='dialog-amount-rp'>
                                    Rp
                                  </span>
                                  <CurrencyFormat
                                    className='form-control form-control-sm'
                                    readOnly
                                    value={models.amount}
                                    displayType={'input'}
                                    isNumericString={false}
                                    decimalScale={2}
                                    thousandSeparator={true}
                                    key={`dialog-amount-rp-${models.discount_id}`}
                                  />
                                </div>
                              </>
                            )}
                          </>
                        )}
                        <>
                          {formik.values.format === 'percentage' ? (
                            <>
                              <div className='input-group'>
                                <CurrencyFormat
                                  className='form-control form-control-sm text-primary is-valid'
                                  readOnly
                                  value={formik.values.amount}
                                  displayType={'input'}
                                  isNumericString={false}
                                  decimalScale={2}
                                  thousandSeparator={true}
                                  key={`dialog-amount-per-${formik.values.discount_id}`}
                                />
                                <span className='input-group-text' id='dialog-amount-per'>
                                  %
                                </span>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className='input-group'>
                                <span className='input-group-text' id='dialog-amount-rp'>
                                  Rp
                                </span>
                                <CurrencyFormat
                                  className='form-control form-control-sm text-primary is-valid'
                                  readOnly
                                  value={formik.values.amount}
                                  displayType={'input'}
                                  isNumericString={false}
                                  decimalScale={2}
                                  thousandSeparator={true}
                                  key={`dialog-amount-rp-${formik.values.discount_id}`}
                                />
                              </div>
                            </>
                          )}
                        </>
                      </>
                    ) : (
                      <>
                        {formik.values.format === 'percentage' ? (
                          <>
                            <div className='input-group'>
                              <CurrencyFormat
                                className='form-control form-control-sm'
                                readOnly
                                value={models.amount}
                                displayType={'input'}
                                isNumericString={false}
                                decimalScale={2}
                                thousandSeparator={true}
                                key={`dialog-amount-per-${models.discount_id}`}
                              />
                              <span className='input-group-text' id='dialog-amount-per'>
                                %
                              </span>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className='input-group'>
                              <span className='input-group-text' id='dialog-amount-rp'>
                                Rp
                              </span>
                              <CurrencyFormat
                                className='form-control form-control-sm'
                                readOnly
                                value={models.amount}
                                displayType={'input'}
                                isNumericString={false}
                                decimalScale={2}
                                thousandSeparator={true}
                                key={`dialog-amount-rp-${models.discount_id}`}
                              />
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                  {/* Amount */}
                </div>
                {/* Second Col */}
              </div>

              <div className='row mt-10'>
                {/* Third Col */}
                <div className='col-lg-6'>
                  {/* Description */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark'>
                      {intl.formatMessage({id: 'LABEL_DESCRIPTION'})}
                    </label>
                    {models.description !== formik.values.description ? (
                      <>
                        {action !== 'add' && (
                          <textarea
                            className='form-control form-control-solid'
                            value={models.description}
                            readOnly
                          />
                        )}
                        <textarea
                          className='form-control form-control-solid text-primary is-valid'
                          value={formik.values.description}
                          readOnly
                        />
                      </>
                    ) : (
                      <textarea
                        className='form-control form-control-solid'
                        value={formik.values.description}
                        readOnly
                      />
                    )}
                  </div>
                  {/* Description */}
                </div>
                {/* Third Col */}

                {/* Fourth Col */}
                <div className='col-lg-6'>
                  {/* Status */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark'>
                      <label className='form-label fs-6 fw-bolder text-dark'>Status</label>
                    </label>
                    {models.status !== formik.values.status ? (
                      <>
                        {action !== 'add' && (
                          <input
                            type='text'
                            className='form-control form-control-solid'
                            value={
                              models.status === 't'
                                ? intl.formatMessage({id: 'LABEL_ACTIVE'})
                                : intl.formatMessage({id: 'LABEL_INACTIVE'})
                            }
                            readOnly
                          />
                        )}
                        <input
                          type='text'
                          className='form-control form-control-solid text-primary is-valid'
                          value={
                            formik.values.status === 't'
                              ? intl.formatMessage({id: 'LABEL_ACTIVE'})
                              : intl.formatMessage({id: 'LABEL_INACTIVE'})
                          }
                          readOnly
                        />
                      </>
                    ) : (
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        value={
                          models.status === 't'
                            ? intl.formatMessage({id: 'LABEL_ACTIVE'})
                            : intl.formatMessage({id: 'LABEL_INACTIVE'})
                        }
                        readOnly
                      />
                    )}
                  </div>
                  {/* Status */}
                </div>
                {/* Fourth Col */}
              </div>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-outline btn-btn-outline-info me-3'
                data-kt-users-modal-action='close'
                onClick={() => setIsDialogOpen(false)}
              >
                {intl.formatMessage({id: 'BUTTON_LABEL_BACK'})}
              </button>

              <button
                type='button'
                className={`btn ${loading ? 'btn-secondary' : 'btn-success'}`}
                data-kt-users-modal-action='submit'
                onClick={() => formik.handleSubmit()}
                disabled={loading ? true : false}
              >
                {!loading && (
                  <span className='indicator-label'>
                    {intl.formatMessage({id: 'BUTTON_LABEL_SUBMIT'})}
                  </span>
                )}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    {intl.formatMessage({id: 'BUTTON_LABEL_PLEASE_WAIT'})}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
      {/* Dialog */}
    </>
  )
}

export {ViewDrawerForm}
