import axios, {AxiosResponse} from 'axios'

import {APIResponse} from 'app/components'

import {ListQueryResponse} from './_list_models'
import {ParameterID} from '.'

const API_APP_URL = process.env.REACT_APP_APP_API_URL
const X_API_KEY = process.env.REACT_APP_APP_API_KEY
const GET_PARAMETER_LIST_URL = `${API_APP_URL}/parameter?`
const GET_PARAMETER_URL = `${API_APP_URL}/parameter/fetch-parameter?`
const GET_PARAMETER_LIST_FOR_INPUT_URL = `${API_APP_URL}/parameter/get-simple-parameters?`
const ADD_UPDATE_PARAMETER_URL = `${API_APP_URL}/parameter?`
const DELETE_PARAMETER_URL = `${API_APP_URL}/parameter`

const getList = (query: any): Promise<ListQueryResponse> => {
  return axios
    .get(`${GET_PARAMETER_LIST_URL}${query}`, {
      headers: {'X-API-KEY': `${X_API_KEY}`},
    })
    .then((d: AxiosResponse<ListQueryResponse>) => d.data)
}

const getParameterListForInput = (module_id: string | undefined) => {
  return axios.get(`${GET_PARAMETER_LIST_FOR_INPUT_URL}module_id=${module_id}`, {
    headers: {'X-API-KEY': `${X_API_KEY}`},
  })
}

const getDataByID = (parameter_id: ParameterID): Promise<APIResponse | undefined> => {
  return axios
    .get(`${GET_PARAMETER_URL}parameter_id=${parameter_id}`, {
      headers: {'X-API-KEY': `${X_API_KEY}`},
    })
    .then((response: AxiosResponse<APIResponse>) => response.data)
}

const addData = (payload: any): Promise<APIResponse | undefined> => {
  return axios
    .post(
      `${ADD_UPDATE_PARAMETER_URL}`,
      {payload},
      {
        headers: {'X-API-KEY': `${X_API_KEY}`},
      }
    )
    .then((response: AxiosResponse<APIResponse>) => response.data)
}

const updateData = (payload: any): Promise<APIResponse | undefined> => {
  return axios
    .put(
      `${ADD_UPDATE_PARAMETER_URL}`,
      {payload},
      {
        headers: {'X-API-KEY': `${X_API_KEY}`},
      }
    )
    .then((response: AxiosResponse<APIResponse>) => response.data)
}

const deleteData = (parameter_id: any, app_menu_id: any): Promise<APIResponse | undefined> => {
  return axios
    .delete(`${DELETE_PARAMETER_URL}/${parameter_id}/${app_menu_id}`, {
      headers: {'X-API-KEY': `${X_API_KEY}`},
    })
    .then((response: AxiosResponse<APIResponse>) => response.data)
}

export {getList, getParameterListForInput, getDataByID, addData, updateData, deleteData}
