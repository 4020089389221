import axios, {AxiosResponse} from 'axios'

import {APIResponse} from 'app/components'

import {ListQueryResponse, PaymentMethodID} from '.'

const PAYMENT_METHOD_API_URL = `${process.env.REACT_APP_MOBILE_LIBRARY_API_URL}/payment-method`
const HOLDING_API_URL = `${process.env.REACT_APP_MOBILE_APP_API_URL}/holding`
const COMPANY_API_URL = `${process.env.REACT_APP_MOBILE_APP_API_URL}/company`
const BRAND_API_URL = `${process.env.REACT_APP_MOBILE_APP_API_URL}/brand`
const MOBILE_X_API_KEY = process.env.REACT_APP_MOBILE_APP_API_KEY

const fetch = (query: any): Promise<ListQueryResponse> => {
  return axios
    .get(`${PAYMENT_METHOD_API_URL}/list?${query}`, {
      headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
    })
    .then((response: AxiosResponse<ListQueryResponse>) => response.data)
}

const fetchHoldingOptions = (mode: any) => {
  return axios.get(`${HOLDING_API_URL}/list-options/${mode}`, {
    headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
  })
}

const fetchCompanyOptions = (mode: any, holding_id: string | undefined) => {
  return axios.get(`${COMPANY_API_URL}/list-options/${mode}/${holding_id}`, {
    headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
  })
}

const fetchBrandOptions = (mode: any, company_id: string | undefined) => {
  return axios.get(`${BRAND_API_URL}/list-options/${mode}/${company_id}`, {
    headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
  })
}

const get = (payment_method_id: PaymentMethodID): Promise<APIResponse | undefined> => {
  return axios
    .get(`${PAYMENT_METHOD_API_URL}/${payment_method_id}/detail`, {
      headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
    })
    .then((response: AxiosResponse<APIResponse>) => response.data)
}

const addData = (payload: any): Promise<APIResponse | undefined> => {
  return axios
    .post(
      `${PAYMENT_METHOD_API_URL}`,
      {payload},
      {
        headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
      }
    )
    .then((response: AxiosResponse<APIResponse>) => response.data)
}

const updateData = (payload: any): Promise<APIResponse | undefined> => {
  return axios
    .put(
      `${PAYMENT_METHOD_API_URL}/${payload.payment_method_id}/update`,
      {payload},
      {
        headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
      }
    )
    .then((response: AxiosResponse<APIResponse>) => response.data)
}

const deleteData = (
  payment_method_id: any,
  menu_id: any,
  creator_id: any,
  creator_by: any
): Promise<APIResponse | undefined> => {
  return axios
    .delete(
      `${PAYMENT_METHOD_API_URL}/${payment_method_id}/${menu_id}/${creator_id}/${creator_by}/delete`,
      {
        headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
      }
    )
    .then((response: AxiosResponse<APIResponse>) => response.data)
}

export {
  fetch,
  fetchHoldingOptions,
  fetchCompanyOptions,
  fetchBrandOptions,
  get,
  updateData,
  addData,
  deleteData,
}
