import {ListResponse} from '.'

export type ListModel = {
  order_id?: string
  status?: string
}

export type ListQueryResponse = ListResponse<Array<ListModel>>

export const initialList: ListModel = {
  order_id: '',
  status: '',
}

export interface Receipt {
  notes: string
  social_media: SocialMedia[]
  outlet: Outlet
}

export interface SocialMedia {
  url: string
  logo: string
}

export interface Outlet {
  name: string
  address: string
  phone_number: string
  logo: string
}

export interface Item {
  for_order: number
  item_name: string
  variant_name: string
  quantity: number
  base_price_amount: number
  final_price_amount: number
  discount_items: DiscountItem[]
}

export interface DiscountItem {
  for_order: number
  format: string
  name: string
  discount_amount: number
  discount_price_amount: number
}

export interface DiscountBill {
  for_order: number
  format: string
  name: string
  discount_amount: number
  discount_price_amount: number
}

export interface PaymentMethod {
  payment_method: string
  total_price_amount: number
  payment_amount: number
  change_amount: number
}

export type DetailModel = {
  order_id?: string
  created_at: string
  created_by: string
  receipt_number: string
  total_discount_amount: 0
  sub_total_amount: 0
  ppn_percentage: 0
  ppn_amount: 0
  rounding_amount: 0
  total_price_amount: 0
  payment_amount: 0
  change_amount: 0
  sales_type: '0'
  status: '0'
  notes: ''
  outlet_name: ''
  items: Item[]
  discount_bill: DiscountBill[]
  payment_method: PaymentMethod[]
  receipt: Receipt
}

export const initialData: DetailModel = {
  order_id: '',
  created_at: '',
  created_by: '',
  receipt_number: '',
  total_discount_amount: 0,
  sub_total_amount: 0,
  ppn_percentage: 0,
  ppn_amount: 0,
  rounding_amount: 0,
  total_price_amount: 0,
  payment_amount: 0,
  change_amount: 0,
  sales_type: '0',
  status: '0',
  notes: '',
  outlet_name: '',
  items: [
    {
      for_order: 0,
      item_name: '',
      variant_name: '',
      quantity: 0,
      base_price_amount: 0,
      final_price_amount: 0,
      discount_items: [
        {
          for_order: 0,
          format: '',
          name: '',
          discount_amount: 0,
          discount_price_amount: 0,
        },
      ],
    },
  ],
  discount_bill: [
    {
      for_order: 0,
      format: '',
      name: '',
      discount_amount: 0,
      discount_price_amount: 0,
    },
  ],
  payment_method: [
    {
      payment_method: '',
      total_price_amount: 0,
      payment_amount: 0,
      change_amount: 0,
    },
  ],
  receipt: {
    notes: '',
    outlet: {
      address: '',
      logo: '',
      name: '',
      phone_number: '',
    },
    social_media: [
      {
        logo: '',
        url: '',
      },
    ],
  },
}

// export models
export interface ExportModels {
  exportMode: 'trx' | 'dt' | 'rs'
}

export interface ExportData {
  currentStep: number
  isLoading: boolean
  mode: ExportModels
  url: string | undefined
}

export const defaultExportData: ExportData = {
  currentStep: 1,
  isLoading: false,
  mode: {exportMode: 'trx'},
  url: undefined,
}

export type StepProps = {
  menuName?: string
  data: ExportData
  updateData: (fieldsToUpdate: Partial<ExportData>) => void
  hasError: boolean
}

export const defaultStepProps: StepProps = {
  menuName: '',
  data: defaultExportData,
  updateData: () => {},
  hasError: false,
}
