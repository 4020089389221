import {KTIcon, useDebounce} from '_metronic/helpers'
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {DateRangePicker} from 'rsuite'
import isAfter from 'date-fns/isAfter'
import moment from 'moment'

type Props = {
  useListQueryRequest: any
  useListQueryResponse: any
  labelUpper: any
}

const SearchComponentWithDaterange: FC<Props> = ({
  useListQueryRequest,
  useListQueryResponse,
  labelUpper,
}) => {
  const intl = useIntl()
  const {state, updateState} = useListQueryRequest()
  const [searchTerm, setSearchTerm] = useState(state?.search)
  const debouncedSearchTerm = useDebounce(searchTerm, 2e3)
  const {isLoading} = useListQueryResponse()
  const [daterange, setDaterange] = useState(state?.daterange === null ? null : state?.daterange)
  const handleCallback = (period: any) => {
    if (period !== null) {
      setDaterange(
        moment(period[0]).format('YYYY-MM-DD HH:mm:ss') +
          '[-]' +
          moment(period[1]).format('YYYY-MM-DD HH:mm:ss')
      )
    } else {
      setDaterange(null)
    }
  }

  useEffect(() => {
    if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
      updateState({search: debouncedSearchTerm})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm])

  useEffect(() => {
    if (daterange) {
      updateState({daterange})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [daterange])

  return (
    <>
      <div className='card-title'>
        <div className='d-flex flex-column'>
          <div className='d-flex flex-wrap fw-bold'>
            <span
              className={`${
                state.search === '' || state.search === undefined ? 'text-muted' : 'text-primary'
              }`}
              style={{display: 'block', fontSize: '12px'}}
            >
              &nbsp; {labelUpper}
            </span>
          </div>
          <div className='d-flex align-items-center position-relative my-1'>
            <KTIcon
              iconName='magnifier'
              className={`fs-1 position-absolute ms-6 ${
                state.search === '' || state.search === undefined ? 'text-muted' : 'text-primary'
              }`}
            />
            <input
              type='search'
              data-kt-activity-table-filter='search'
              className={`form-control form-control-solid w-250px ps-14 ${
                (state.search !== '' || state.search !== undefined) && 'text-primary'
              }`}
              placeholder={intl.formatMessage({id: 'PLACEHOLDER_SEARCH_KEYWORD'})}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              disabled={isLoading}
            />
          </div>
          <div className='d-flex flex-wrap fw-bold fs-6 pe-2'>
            {searchTerm !== undefined &&
              debouncedSearchTerm !== undefined &&
              searchTerm !== debouncedSearchTerm && (
                <span className='text-muted' style={{display: 'block'}}>
                  &nbsp; {intl.formatMessage({id: 'TOOLTIP_SEARCH_KEYWORD_BOTTOM'})}
                </span>
              )}
          </div>

          {/* Filter Daterange */}
          <div>
            <DateRangePicker
              defaultValue={
                daterange === null
                  ? undefined
                  : [
                      new Date(moment(daterange?.split('[-]')[0]).toDate()),
                      new Date(moment(daterange?.split('[-]')[1]).toDate()),
                    ]
              }
              isoWeek
              block
              format='yyyy-MM-dd'
              ranges={[]}
              placement={`bottomStart`}
              placeholder={intl.formatMessage({id: 'TOOLTIP_SELECT_ALL'})}
              shouldDisableDate={(date: any) => isAfter(date, new Date())}
              onChange={handleCallback}
            />
          </div>
          {/* Filter Daterange */}
        </div>
      </div>
    </>
  )
}

export {SearchComponentWithDaterange}
