const QUERIES = {
  USERS_LIST: 'users',
  GET_USER: 'user',
  USER_GROUPS_LIST: 'user-groups',
  GET_USER_GROUP: 'user-group',
  USER_GROUP_ACCESS_LIST: 'user-group-accesses',
  GET_USER_GROUP_ACCESS: 'user-group-access',
  LOG_LIST: 'logs',
  GET_LOG: 'log',
  MODULE_LIST: 'modules',
  GET_MODULE: 'module',
  MENU_LIST: 'menus',
  GET_MENU: 'menu',
  PARAMETER_LIST: 'parameters',
  GET_PARAMETER: 'parameter',
  MASTER_DATA_LIST: 'master-datas',
  GET_MASTER_DATA: 'master-data',
  EMPLOYEE_LIST: 'employees',
  GET_EMPLOYEE: 'employee',
  REPORT_EMPLOYEE_LIST: 'report-employees',
  EMPLOYEE_FAMILY_LIST: 'employee-family-list',
  GET_EMPLOYEE_FAMILY: 'employee-family',
  EMPLOYEE_EMERGENCY_LIST: 'employee-emergency-list',
  GET_EMPLOYEE_EMERGENCY: 'employee-emergency',
  EMPLOYEE_JOB_POSITION_LIST: 'employee-job-position-list',
  GET_EMPLOYEE_JOB_POSITION: 'employee-job-position',
  EMPLOYEE_OUTLET_LOCATION_LIST: 'employee-outlet-location-list',
  GET_EMPLOYEE_OUTLET_LOCATION: 'employee-outlet-location',
  EMPLOYEE_FILE_LIST: 'employee-file-list',
  GET_EMPLOYEE_FILE: 'employee-file',
  EMPLOYEE_FORMAL_EDUCATION_LIST: 'employee-formal-education-list',
  GET_EMPLOYEE_FORMAL_EDUCATION: 'employee-formal-education',
  MST_OUTLET_LIST: 'mst-outlets',
  GET_MST_OUTLET: 'mst-outlet',
  POS_DASHBOARD_SUMMARY_LIST: 'pos-dashboard-summary-list',
  POS_REPORT_SALES_SALES_SUMMARY_LIST: 'pos-report-sales-sales-summary-list',
  POS_REPORT_TRANSACTION_LIST: 'pos-report-transaction-list',
  POS_REPORT_GET_TRANSACTION: 'pos-report-transaction-detail',
  POS_REPORT_SHIFT_LIST: 'pos-report-shift-list',
  POS_REPORT_GET_SHIFT: 'pos-report-shift-detail',
  POS_REPORT_VOID_LIST: 'pos-report-void-list',
  POS_REPORT_GET_VOID: 'pos-report-void-detail',
  POS_ESB_SALES_LIST: 'pos-esb-sales-list',
  POS_ESB_GET_SALES: 'pos-esb-sales-detail',
  POS_REPORT_TRANSACTION_ESB_LIST: 'pos-report-transaction-esb-list',
  POS_REPORT_GET_TRANSACTION_ESB: 'pos-report-transaction-esb-detail',
  POS_SETTINGS_HOLDING_LIST: 'pos-setting-holding-list',
  POS_SETTINGS_GET_HOLDING: 'pos-setting-holding-detail',
  POS_SETTINGS_COMPANY_LIST: 'pos-setting-company-list',
  POS_SETTINGS_GET_COMPANY: 'pos-setting-company-detail',
  POS_SETTINGS_BRAND_LIST: 'pos-setting-brand-list',
  POS_SETTINGS_GET_BRAND: 'pos-setting-brand-detail',
  POS_SETTINGS_OUTLET_LIST: 'pos-setting-outlet-list',
  POS_SETTINGS_GET_OUTLET: 'pos-setting-outlet-detail',
  POS_SETTINGS_PROVINCE_LIST: 'pos-setting-province-list',
  POS_SETTINGS_GET_PROVINCE: 'pos-setting-province-detail',
  POS_SETTINGS_CITY_LIST: 'pos-setting-city-list',
  POS_SETTINGS_GET_CITY: 'pos-setting-city-detail',
  POS_SETTINGS_DISTRICT_LIST: 'pos-setting-district-list',
  POS_SETTINGS_GET_DISTRICT: 'pos-setting-district-detail',
  POS_SETTINGS_VILLAGE_LIST: 'pos-setting-village-list',
  POS_SETTINGS_GET_VILLAGE: 'pos-setting-village-detail',
  POS_SETTINGS_AREA_LIST: 'pos-setting-area-list',
  POS_SETTINGS_GET_AREA: 'pos-setting-area-detail',
  POS_SETTINGS_CLUSTER_LIST: 'pos-setting-cluster-list',
  POS_SETTINGS_GET_CLUSTER: 'pos-setting-cluster-detail',
  POS_SETTINGS_USER_MANAGEMENT_USER_LIST: 'pos-setting-user-list',
  POS_SETTINGS_USER_MANAGEMENT_GET_USER: 'pos-setting-user-detail',
  POS_SETTINGS_USER_MANAGEMENT_GROUP_LIST: 'pos-setting-group-list',
  POS_SETTINGS_USER_MANAGEMENT_GET_GROUP: 'pos-setting-group-detail',
  POS_SETTINGS_USER_MANAGEMENT_ACCESS_LIST: 'pos-setting-access-list',
  POS_SETTINGS_USER_MANAGEMENT_GET_ACCESS: 'pos-setting-access-detail',
  POS_SETTINGS_USER_MANAGEMENT_LOG_LIST: 'pos-setting-log-list',
  POS_SETTINGS_USER_MANAGEMENT_GET_LOG: 'pos-setting-log-detail',
  POS_SETTINGS_MOBILE_LAYOUT_MENU_LIST: 'pos-setting-menu-list',
  POS_SETTINGS_MOBILE_LAYOUT_GET_MENU: 'pos-setting-menu-detail',
  POS_SETTINGS_MOBILE_LIBRARY_PAYMENT_METHOD_LIST: 'pos-setting-payment-method-list',
  POS_SETTINGS_MOBILE_LIBRARY_GET_PAYMENT_METHOD: 'pos-setting-payment-method-detail',
  POS_SETTINGS_MOBILE_LIBRARY_SALES_TYPE_LIST: 'pos-setting-sales-type-list',
  POS_SETTINGS_MOBILE_LIBRARY_GET_SALES_TYPE: 'pos-setting-sales-type-detail',
  POS_SETTINGS_MOBILE_LIBRARY_CATEGORIES_LIST: 'pos-setting-categories-list',
  POS_SETTINGS_MOBILE_LIBRARY_GET_CATEGORIES: 'pos-setting-categories-detail',
  POS_SETTINGS_MOBILE_LIBRARY_VARIANT_LIST: 'pos-setting-variant-list',
  POS_SETTINGS_MOBILE_LIBRARY_GET_VARIANT: 'pos-setting-variant-detail',
  POS_SETTINGS_MOBILE_LIBRARY_ITEM_LIST: 'pos-setting-item-list',
  POS_SETTINGS_MOBILE_LIBRARY_GET_ITEM: 'pos-setting-item-detail',
  POS_SETTINGS_MOBILE_LIBRARY_DISCOUNT_LIST: 'pos-setting-discount-list',
  POS_SETTINGS_MOBILE_LIBRARY_GET_DISCOUNT: 'pos-setting-discount-detail',
  POS_SETTINGS_MOBILE_RECEIPT_MEDIA_LIST: 'pos-setting-receipt-media-list',
  POS_SETTINGS_MOBILE_RECEIPT_GET_MEDIA: 'pos-setting-receipt-media-detail',
  POS_SETTINGS_MOBILE_RECEIPT_DATA_LIST: 'pos-setting-receipt-data-list',
  POS_SETTINGS_MOBILE_RECEIPT_GET_DATA: 'pos-setting-receipt-data-detail',
}

const SELECT_COLOR_STYLES = {
  option: (styles: any, {isFocused, isSelected}: {isFocused: any; isSelected: any}) => {
    return {
      ...styles,
      backgroundColor: isFocused ? '#009ef7' : isSelected ? '#b0def7' : null,
      color: '#000000',
    }
  },
}

const BASE_PAGINATION_PER_SIZE = [5, 50, 500]

export {QUERIES, BASE_PAGINATION_PER_SIZE, SELECT_COLOR_STYLES}
