import {RoleID, ListResponse} from '.'

export type ListModel = {
  role_id?: RoleID
  name?: string
  code?: string
  detail?: string
  status?: string
}

export type ListQueryResponse = ListResponse<Array<ListModel>>

export const initialList: ListModel = {
  role_id: '',
  name: '',
  code: '',
  detail: '',
  status: '',
}

export type GetDataByIDModel = {
  add: Array<string>
  apv1: Array<string>
  apv2: Array<string>
  apv3: Array<string>
  delete: Array<string>
  edit: Array<string>
  view: Array<string>
  menu_id: string
  role_id: RoleID
}

export const initialData: GetDataByIDModel = {
  add: [''],
  apv1: [''],
  apv2: [''],
  apv3: [''],
  delete: [''],
  edit: [''],
  view: [''],
  menu_id: '',
  role_id: '',
}
