import axios, {AxiosResponse} from 'axios'

import {APIResponse} from 'app/components'

import {ListQueryResponse, VoidID} from '.'

const SALES_API_URL = `${process.env.REACT_APP_MOBILE_SALES_API_URL}/web/report/void`
const HOLDING_API_URL = `${process.env.REACT_APP_MOBILE_APP_API_URL}/holding`
const COMPANY_API_URL = `${process.env.REACT_APP_MOBILE_APP_API_URL}/company`
const BRAND_API_URL = `${process.env.REACT_APP_MOBILE_APP_API_URL}/brand`
const OUTLET_API_URL = `${process.env.REACT_APP_MOBILE_APP_API_URL}/outlet`
const MOBILE_X_API_KEY = process.env.REACT_APP_MOBILE_APP_API_KEY

const fetch = (query: any): Promise<ListQueryResponse> => {
  return axios
    .get(`${SALES_API_URL}/list?${query}`, {
      headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
    })
    .then((response: AxiosResponse<ListQueryResponse>) => response.data)
}
const fetchHoldingOptions = (mode: any) => {
  return axios.get(`${HOLDING_API_URL}/list-options/${mode}`, {
    headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
  })
}

const fetchCompanyOptions = (mode: any, holding_id: string | undefined) => {
  return axios.get(`${COMPANY_API_URL}/list-options/${mode}/${holding_id}`, {
    headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
  })
}

const fetchBrandOptions = (mode: any, company_id: string | undefined) => {
  return axios.get(`${BRAND_API_URL}/list-options/${mode}/${company_id}`, {
    headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
  })
}

const fetchOutletOptions = (mode: any, brand: string | undefined) => {
  return axios.get(`${OUTLET_API_URL}/list-options/${mode}/${brand}`, {
    headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
  })
}

const get = (void_id: VoidID): Promise<APIResponse | undefined> => {
  return axios
    .get(`${SALES_API_URL}/${void_id}/detail`, {
      headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
    })
    .then((response: AxiosResponse<APIResponse>) => response.data)
}

const requestCancelVoidData = (
  void_id: any,
  menu_id: any,
  creator_id: any,
  creator_by: any
): Promise<APIResponse | undefined> => {
  return axios
    .put(
      `${SALES_API_URL}/${void_id}/${menu_id}/${creator_id}/${creator_by}/cancel`,
      {},
      {
        headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
      }
    )
    .then((response: AxiosResponse<APIResponse>) => response.data)
}

const requestApproveVoidData = (
  void_id: any,
  menu_id: any,
  creator_id: any,
  creator_by: any
): Promise<APIResponse | undefined> => {
  return axios
    .put(
      `${SALES_API_URL}/${void_id}/${menu_id}/${creator_id}/${creator_by}/approve`,
      {},
      {
        headers: {'x-api-key': `${MOBILE_X_API_KEY}`},
      }
    )
    .then((response: AxiosResponse<APIResponse>) => response.data)
}

export {
  fetch,
  fetchHoldingOptions,
  fetchCompanyOptions,
  fetchBrandOptions,
  fetchOutletOptions,
  get,
  requestCancelVoidData,
  requestApproveVoidData,
}
