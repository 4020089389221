import {FC, lazy, useEffect, useRef, useState} from 'react'
import toast from 'react-hot-toast'
import {useIntl} from 'react-intl'
import {Navigate, Outlet, Route, Routes, useLocation} from 'react-router-dom'

import * as libraryPaymentMethodListState from 'app/modules/backoffice-orins-pos/pos-settings/library/payment-method/providers/ListQueryStateContext'
import {initialListQueryState as libraryPaymentMethodListQueryState} from 'app/modules/backoffice-orins-pos/pos-settings/library/payment-method/helpers/ListQueryModel'
import * as librarySalesTypeListState from 'app/modules/backoffice-orins-pos/pos-settings/library/sales-type/providers/ListQueryStateContext'
import {initialListQueryState as librarySalesTypeListQueryState} from 'app/modules/backoffice-orins-pos/pos-settings/library/sales-type/helpers/ListQueryModel'
import * as libraryCategoriesListState from 'app/modules/backoffice-orins-pos/pos-settings/library/categories/providers/ListQueryStateContext'
import {initialListQueryState as libraryCategoriesListQueryState} from 'app/modules/backoffice-orins-pos/pos-settings/library/categories/helpers/ListQueryModel'
import * as libraryItemListState from 'app/modules/backoffice-orins-pos/pos-settings/library/item/providers/ListQueryStateContext'
import {initialListQueryState as libraryItemListQueryState} from 'app/modules/backoffice-orins-pos/pos-settings/library/item/helpers/ListQueryModel'
import * as libraryDiscountsListState from 'app/modules/backoffice-orins-pos/pos-settings/library/discounts/providers/ListQueryStateContext'
import {initialListQueryState as libraryDiscountsListQueryState} from 'app/modules/backoffice-orins-pos/pos-settings/library/discounts/helpers/ListQueryModel'

import {PageLink, PageTitle} from '_metronic/layout/core'
import {SuspendViewComponents} from 'app/components'
import {CurrentMenuAccessModel} from 'app/context/models'
import {useUserGroupModules} from 'app/context/providers'
import {getCurrentMenuAccessByRoleIDAndMenuID} from 'app/context/requests'
import {removeAuth, useAuth} from 'app/modules/auth'

const PaymentMethodPageProvider = lazy(() => import('./payment-method/PaymentMethodPageProvider'))
const SalesTypePageProvider = lazy(() => import('./sales-type/SalesTypePageProvider'))
const CategoriesPageProvider = lazy(() => import('./categories/CategoriesPageProvider'))
const ItemsPageProvider = lazy(() => import('./items/ItemsPageProvider'))
const BundlePackagePageProvider = lazy(() => import('./bundle-package/BundlePackagePageProvider'))
const PromoPageProvider = lazy(() => import('./promo/PromoPageProvider'))
const DiscountPageProvider = lazy(() => import('./discount/DiscountPageProvider'))

const BackOfficeOrinsPOSLibraryWrapper: FC = () => {
  const intl = useIntl()
  const {pathname} = useLocation()
  const {
    currentModuleLabel,
    currentModulePath,
    currentSiteLabel,
    currentSitePath,
    currentParentMenuLabel,
    currentParentMenuPath,
    currentMenuLabel,
    currentMenuID,
  } = useUserGroupModules()
  const didRequest = useRef(false)
  const {currentUser} = useAuth()
  const [access, setAccess] = useState<CurrentMenuAccessModel | undefined>(undefined)

  const breadCrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({id: 'LABEL_HOMEPAGE'}),
      path: 'homepage',
      isSeparator: false,
      isActive: false,
    },
    {
      title: currentModuleLabel,
      path: currentModulePath,
      isSeparator: false,
      isActive: false,
    },
    {
      title: currentSiteLabel,
      path: currentSitePath,
      isSeparator: false,
      isActive: false,
    },
    {
      title: currentParentMenuLabel,
      path: currentParentMenuPath,
      isSeparator: false,
      isActive: false,
    },
  ]

  useEffect(() => {
    if (libraryPaymentMethodListState.getListState() === undefined) {
      libraryPaymentMethodListState.setListState(libraryPaymentMethodListQueryState)
    }
    if (librarySalesTypeListState.getListState() === undefined) {
      librarySalesTypeListState.setListState(librarySalesTypeListQueryState)
    }
    if (libraryCategoriesListState.getListState() === undefined) {
      libraryCategoriesListState.setListState(libraryCategoriesListQueryState)
    }
    if (libraryItemListState.getListState() === undefined) {
      libraryItemListState.setListState(libraryItemListQueryState)
    }
    if (libraryDiscountsListState.getListState() === undefined) {
      libraryDiscountsListState.setListState(libraryDiscountsListQueryState)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const requestCurrentMenuAccess = async () => {
      try {
        if (!didRequest.current) {
          const access = await getCurrentMenuAccessByRoleIDAndMenuID(
            currentUser?.role.role_id,
            currentMenuID
          )
          // throw message error
          if (access.data.meta.code === 200) {
            setAccess(access.data.data)
          }
          if (access.data.meta.code === 404 || access.data.meta.code === 500) {
            toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
          }
          if (access.data.meta.code === 401) {
            toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
            removeAuth()
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          if ((error as unknown as any).response.data.meta.code === 401) {
            toast.error(intl.formatMessage({id: 'RESPONSE_NOT_AUTHORIZED'}))
            removeAuth()
          } else {
            toast.error(intl.formatMessage({id: 'RESPONSE_ERROR_API'}))
          }
        }
      } finally {
      }

      return () => (didRequest.current = true)
    }

    if (
      currentMenuID !== undefined &&
      currentMenuID !== '' &&
      pathname.split('/')[3] === currentParentMenuPath.split('/')[3]
    ) {
      requestCurrentMenuAccess()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMenuID])

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='payment-method'
          element={
            <SuspendViewComponents>
              <PageTitle breadcrumbs={breadCrumbs}>{currentMenuLabel}</PageTitle>
              <PaymentMethodPageProvider access={access} />
            </SuspendViewComponents>
          }
        />
        <Route
          path='sales-type'
          element={
            <SuspendViewComponents>
              <PageTitle breadcrumbs={breadCrumbs}>{currentMenuLabel}</PageTitle>
              <SalesTypePageProvider access={access} />
            </SuspendViewComponents>
          }
        />
        <Route
          path='categories'
          element={
            <SuspendViewComponents>
              <PageTitle breadcrumbs={breadCrumbs}>{currentMenuLabel}</PageTitle>
              <CategoriesPageProvider access={access} />
            </SuspendViewComponents>
          }
        />
        <Route
          path='items'
          element={
            <SuspendViewComponents>
              <PageTitle breadcrumbs={breadCrumbs}>{currentMenuLabel}</PageTitle>
              <ItemsPageProvider access={access} />
            </SuspendViewComponents>
          }
        />
        <Route
          path='bundle-package'
          element={
            <SuspendViewComponents>
              <PageTitle breadcrumbs={breadCrumbs}>{currentMenuLabel}</PageTitle>
              <BundlePackagePageProvider access={access} />
            </SuspendViewComponents>
          }
        />
        <Route
          path='promo'
          element={
            <SuspendViewComponents>
              <PageTitle breadcrumbs={breadCrumbs}>{currentMenuLabel}</PageTitle>
              <PromoPageProvider access={access} />
            </SuspendViewComponents>
          }
        />
        <Route
          path='discount'
          element={
            <SuspendViewComponents>
              <PageTitle breadcrumbs={breadCrumbs}>{currentMenuLabel}</PageTitle>
              <DiscountPageProvider access={access} />
            </SuspendViewComponents>
          }
        />
        <Route
          index
          element={<Navigate to='/backoffice-orins-pos/pos-settings/library/payment-method' />}
        />
      </Route>
    </Routes>
  )
}

export {BackOfficeOrinsPOSLibraryWrapper}
