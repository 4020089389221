import {FC} from 'react'
import {useIntl} from 'react-intl'

type Props = {
  isLoading: any
  resetData: any
  setStatus?: any
  setUserID?: any
  setModuleID?: any
  setMenuID?: any
  setParentID?: any
  filterData: any
}

function reset(
  setStatus: any,
  setUserID: any,
  setModuleID: any,
  setMenuID: any,
  setParentID: any,
  resetData: any
) {
  if (setStatus) {
    setStatus('')
  }
  if (setUserID) {
    setUserID('')
  }
  if (setModuleID) {
    setModuleID('')
  }
  if (setMenuID) {
    setMenuID('')
  }
  if (setParentID) {
    setParentID('')
  }

  resetData()
  return
}

const FilterAction: FC<Props> = ({
  isLoading,
  resetData,
  setStatus,
  setUserID,
  setModuleID,
  setMenuID,
  setParentID,
  filterData,
}) => {
  const intl = useIntl()
  return (
    <>
      <div className='d-flex justify-content-end'>
        <button
          type='button'
          disabled={isLoading}
          onClick={() =>
            reset(setStatus, setUserID, setModuleID, setMenuID, setParentID, resetData)
          }
          className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
          data-kt-menu-dismiss='true'
          data-kt-user-list-table-filter='reset'
        >
          {intl.formatMessage({id: 'BUTTON_LABEL_RESET'})}
        </button>
        <button
          disabled={isLoading}
          type='button'
          onClick={filterData}
          className='btn btn-primary fw-bold px-6'
          data-kt-menu-dismiss='true'
          data-kt-user-list-table-filter='filter'
        >
          {intl.formatMessage({id: 'BUTTON_LABEL_APPLY'})}
        </button>
      </div>
    </>
  )
}

export {FilterAction}
